import { Add } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';
import { clearRecommendedPlans } from 'actions/clear';
import { SEARCH_PLANS_ACTION } from 'actions/marketplacePlan/searchMarketplacePlans';
import {
    SEARCH_OFF_EXCHANGE_RECOMMENDED_PLANS_ACTION,
    searchOffExchangeRecommendedPlans,
} from 'actions/marketplacePlan/searchOffExchangeRecommendedPlans';
import {
    SEARCH_ON_EXCHANGE_RECOMMENDED_PLANS_ACTION,
    searchOnExchangeRecommendedPlans,
} from 'actions/marketplacePlan/searchOnExchangeRecommendedPlans';
import { ICounty, IPathwayRecommendedPlan, PathwayRecommendedPlan } from 'api/generated/models';
import Button from 'components/Button';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextArea from 'components/TextArea';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { PLAN_LISTING_ITEMS } from 'pages/pathwayBlueprint/MarketplacePathwayInputs';
import { setPathwayInputs } from 'pages/pathwayBlueprint/pathwayModalActions';
import { schema } from 'pages/pathwayBlueprint/pathwayModalState';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { uniqueMarketplaceCarriersSelector } from 'selectors/marketplace';
import { arrayHasValue, hasValue } from 'utilities';
import { IFormErrors } from 'utilities/forms';
import {
    getCarrierNameFromId,
    getPlansByCarrier,
    getUniqueMarketplaceCarriers,
} from 'utilities/marketplacePlan';

const ONE = 1;
const ONE_HUNDRED_MILLISECONDS = 100;
const RECOMMENDED_PLAN_MAX_LENGTH = 3;

type IPathwayInputs = {
    county: string;
    errors?: IFormErrors<typeof schema>;
    ichraBenchmarkType: string;
    marketplaceCarrier: string;
    marketplacePlanId: string;
    pathwayRecommendedPlans?: IPathwayRecommendedPlan[];
    zipCode: string;
};

const IchraRecommendedPlanInputs = () => {
    const dispatch = useThunkDispatch();
    const {
        carriers,
        counties,
        isLoadingMarketplacePlans,
        marketplacePlans,
        pathwayBlueprint,
        pathwayInputs,
        recommendedPlans,
    } = useSelector((state: AppStore) => ({
        carriers: uniqueMarketplaceCarriersSelector(state),
        counties: state.counties,
        isLoadingMarketplacePlans: hasApiActivity(
            state,
            SEARCH_PLANS_ACTION,
            SEARCH_OFF_EXCHANGE_RECOMMENDED_PLANS_ACTION,
            SEARCH_ON_EXCHANGE_RECOMMENDED_PLANS_ACTION
        ),
        marketplacePlans: state.marketplacePlans,
        pathwayBlueprint: state.pathwayBlueprint,
        pathwayInputs: state.pathwayModalState.pathwayInputs,
        recommendedPlans: state.recommendedPlans,
    }));
    const marketplaceYear = pathwayBlueprint.isProjection
        ? pathwayBlueprint.year - 1
        : pathwayBlueprint.year;
    const {
        county,
        errors,
        ichraBenchmarkType,
        marketplaceCarrier,
        marketplacePlanId,
        pathwayRecommendedPlans,
        zipCode,
    } = pathwayInputs ?? {};

    const [pathwayInput, setPathwayInput] = useState<Partial<IPathwayInputs> | undefined>(
        pathwayInputs
    );
    const [recPlanPosition, setRecPlanPosition] = useState<React.RefObject<HTMLDivElement>[]>([]);

    const hasDataToSearchPlans =
        hasValue(zipCode) && hasValue(county) && hasValue(ichraBenchmarkType);

    const getFilteredRecommendedPlans = (benchmarkType: string) =>
        benchmarkType === '0' ? recommendedPlans.onExchange : recommendedPlans.offExchange;

    const getStateAbbreviation = (searchedCounties: ICounty[]) =>
        searchedCounties.find((x) => x.fips === county)?.stateAbbrev;

    const handleRecommendedPlanSearch = (benchmarkType: string) => {
        const state = getStateAbbreviation(counties);
        if (benchmarkType === '0') {
            dispatch(searchOnExchangeRecommendedPlans(state, zipCode, county, marketplaceYear));
        } else {
            dispatch(searchOffExchangeRecommendedPlans(state, zipCode, county, marketplaceYear));
        }
    };

    const getCarriers = (benchmarkType: string) => {
        const filteredPlans = getFilteredRecommendedPlans(benchmarkType);

        return getUniqueMarketplaceCarriers(filteredPlans);
    };

    // TODO 20092: Remove this logic once we complete the chore to convert carrier names with carrier ids
    const getCarrierId = (carrierName: string, isBenchmark: boolean, benchmarkType?: string) =>
        isBenchmark
            ? carriers.find((c) => c.name === carrierName)?.id
            : getCarriers(benchmarkType as string).find((c) => c.name === carrierName)?.id;

    const getPlans = (benchmarkType: string, carrierId: string) => {
        const filteredPlans = getFilteredRecommendedPlans(benchmarkType);
        const carrierName = getCarrierNameFromId(carrierId, getCarriers(benchmarkType));

        return getPlansByCarrier(filteredPlans, carrierName);
    };

    const plansByCarrier = useMemo(() => getPlansByCarrier(marketplacePlans, marketplaceCarrier), [
        marketplaceCarrier,
        marketplacePlans,
    ]);

    const scrollIntoView = (newPosition: React.RefObject<HTMLDivElement>) =>
        setTimeout(() => {
            newPosition.current?.scrollIntoView({ behavior: 'smooth' });
        }, ONE_HUNDRED_MILLISECONDS);

    const handleRecommendedPlanAdd = () => {
        const newRecommendedPlan: IPathwayRecommendedPlan = new PathwayRecommendedPlan();
        const updatedPlans = [
            ...(pathwayRecommendedPlans as IPathwayRecommendedPlan[]),
            newRecommendedPlan,
        ];
        const newPosition = React.createRef<HTMLDivElement>();

        setPathwayInput({ ...pathwayInput, pathwayRecommendedPlans: updatedPlans });
        dispatch(setPathwayInputs({ pathwayRecommendedPlans: updatedPlans }));
        setRecPlanPosition((prevPositions) => [...prevPositions, newPosition]);

        scrollIntoView(newPosition);
    };

    const handleRecommendedPlanRemove = (index: number) => {
        const updatedPlans = [...(pathwayRecommendedPlans as IPathwayRecommendedPlan[])];
        updatedPlans.splice(index, 1);
        setPathwayInput({ ...pathwayInput, pathwayRecommendedPlans: updatedPlans });
        dispatch(setPathwayInputs({ pathwayRecommendedPlans: updatedPlans }));
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        let isBenchmarkType = false;
        let isCarrierId = false;
        let isPlanId = false;
        if (event.target.name === 'benchmarkType') {
            isBenchmarkType = true;
            handleRecommendedPlanSearch(event.target.value);
        } else if (event.target.name === 'carrierId') {
            isCarrierId = true;
        } else if (event.target.name === 'planId') {
            isPlanId = true;
        }

        const updatedPathwayInputs = {
            ...pathwayInputs,
            pathwayRecommendedPlans: pathwayInputs?.pathwayRecommendedPlans?.map((plan, i) => {
                if (i === index) {
                    if (isBenchmarkType) {
                        return {
                            ...plan,
                            benchmarkType: event.target.value,
                            carrierId: '',
                            planDetails: undefined,
                            planId: '',
                            planName: '',
                        };
                    } else if (isCarrierId) {
                        return {
                            ...plan,
                            benchmarkType:
                                plan.benchmarkType ??
                                pathwayInput?.pathwayRecommendedPlans?.[i]?.benchmarkType,
                            carrierId: event.target.value,
                            planDetails: undefined,
                            planId: '',
                            planName: '',
                        };
                    } else {
                        return { ...plan, [event.target.name]: event.target.value };
                    }
                } else {
                    return plan;
                }
            }) as IPathwayRecommendedPlan[],
        };

        setPathwayInput(updatedPathwayInputs);

        if (isBenchmarkType || isCarrierId || isPlanId) {
            dispatch(setPathwayInputs(updatedPathwayInputs));
        }
    };

    const updatePathwayInputs = () => {
        dispatch(setPathwayInputs(pathwayInput));
    };

    useEffect(() => {
        // Update Benchmark Recommended Plan
        const planDetails = arrayHasValue(plansByCarrier)
            ? plansByCarrier.find((pbc) => pbc.id === marketplacePlanId)
            : pathwayRecommendedPlans?.[0]?.planDetails ?? null;

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const updatedPlan = {
            ...pathwayRecommendedPlans?.[0],
            planDetails,
            benchmarkType: ichraBenchmarkType,
            carrierId: getCarrierId(marketplaceCarrier as string, true),
            order: 1,
            planId: marketplacePlanId,
            planName: planDetails?.name,
        } as IPathwayRecommendedPlan;

        const updatedPlans = arrayHasValue(pathwayRecommendedPlans)
            ? [updatedPlan, ...pathwayRecommendedPlans.slice(1)]
            : [updatedPlan];

        setPathwayInput({ ...pathwayInput, pathwayRecommendedPlans: updatedPlans });
        dispatch(setPathwayInputs({ pathwayRecommendedPlans: updatedPlans }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ichraBenchmarkType, marketplaceCarrier, marketplacePlanId, carriers]);

    useEffect(() => {
        if (
            !arrayHasValue(recommendedPlans.offExchange) &&
            !arrayHasValue(recommendedPlans.onExchange) &&
            hasValue(pathwayRecommendedPlans) &&
            arrayHasValue(counties) &&
            pathwayRecommendedPlans?.length > ONE
        ) {
            const recommendedTypes = Array.from(
                new Set(pathwayRecommendedPlans.map((prp) => prp.benchmarkType))
            );
            recommendedTypes.forEach((rt) => handleRecommendedPlanSearch(rt?.toString() as string));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counties]);

    useEffect(
        () => () => {
            dispatch(clearRecommendedPlans());
        },
        [dispatch]
    );

    return (
        <Grid columnSpacing={2} container rowGap={2}>
            <Grid item width="100%">
                <Divider />
            </Grid>
            <Grid columnSpacing={2} container direction="row" item xs={12}>
                <Grid item md={6} xs={12}>
                    <Typography variant="h4">Recommended Plans</Typography>
                </Grid>
                <Grid container item justifyContent="flex-end" md={6} xs={12}>
                    <Tooltip title="You may add up to three recommended plans">
                        <Button
                            disabled={
                                arrayHasValue(pathwayRecommendedPlans) &&
                                pathwayRecommendedPlans?.length >= RECOMMENDED_PLAN_MAX_LENGTH
                            }
                            onClick={handleRecommendedPlanAdd}
                        >
                            <Add color="secondary" fontSize="small" />
                            {'  '}
                            Add New Recommended Plan
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            {pathwayInput?.pathwayRecommendedPlans?.map((plan, index) => (
                <React.Fragment key={plan.planName ?? '' + index}>
                    <Grid columnSpacing={2} container direction="row" item xs={12}>
                        <Grid item md={6} xs={12}>
                            <Typography variant="h5">
                                Recommended Plan{' '}
                                {arrayHasValue(pathwayInput?.pathwayRecommendedPlans) &&
                                pathwayInput?.pathwayRecommendedPlans?.length > ONE
                                    ? index + ONE
                                    : ''}
                            </Typography>
                        </Grid>
                        {index !== 0 && (
                            <Grid container item justifyContent="flex-end" md={6} xs={12}>
                                <Button onClick={() => handleRecommendedPlanRemove(index)}>
                                    Remove
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <ConditionalTooltip
                            isDisabled={index > 0}
                            placement="bottom-end"
                            title="This is always the benchmark type. To update this, update the benchmark type for this pathway"
                        >
                            <Select
                                data-cy="ichra-benchmark-type"
                                defaultText="Choose an ICHRA Type"
                                defaultValue=""
                                disabled={index === 0 || isLoadingMarketplacePlans}
                                errors={
                                    errors?.[
                                        (`pathwayRecommendedPlans[${index}].benchmarkType` as unknown) as keyof typeof errors
                                    ]
                                }
                                items={PLAN_LISTING_ITEMS}
                                label="Recommended Plan Type"
                                name="benchmarkType"
                                onBlurCapture={updatePathwayInputs}
                                onChange={(e) => handleChange(e, index)}
                                optionText="name"
                                optionValue="value"
                                placeholder="Choose an ICHRA Type"
                                value={plan.benchmarkType}
                            />
                        </ConditionalTooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container item rowGap={2} xs={12}>
                            <Grid item xs={12}>
                                <Skeleton
                                    count={1}
                                    height={55}
                                    isEnabled={isLoadingMarketplacePlans || !hasDataToSearchPlans}
                                >
                                    <ConditionalTooltip
                                        isDisabled={index > 0}
                                        placement="bottom-end"
                                        title="This is always the benchmark carrier. To update this, update the benchmark carrier for this pathway"
                                    >
                                        <Select
                                            data-cy="recommended-carrier"
                                            defaultText="Choose a Carrier"
                                            defaultValue=""
                                            disabled={index === 0}
                                            errors={
                                                errors?.[
                                                    (`pathwayRecommendedPlans[${index}].carrierId` as unknown) as keyof typeof errors
                                                ]
                                            }
                                            items={
                                                index === 0
                                                    ? carriers
                                                    : getCarriers(
                                                          plan.benchmarkType?.toString() as string
                                                      )
                                            }
                                            label="Recommended Plan Carrier"
                                            name="carrierId"
                                            onBlurCapture={updatePathwayInputs}
                                            onChange={(e) => handleChange(e, index)}
                                            onFocus={updatePathwayInputs}
                                            optionText="name"
                                            optionValue="id"
                                            value={plan.carrierId}
                                        />
                                    </ConditionalTooltip>
                                </Skeleton>
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton
                                    count={1}
                                    height={55}
                                    isEnabled={isLoadingMarketplacePlans || !hasDataToSearchPlans}
                                >
                                    <ConditionalTooltip
                                        isDisabled={index > 0}
                                        placement="bottom-end"
                                        title="This is always the benchmark plan. To update this, update the benchmark plan for this pathway"
                                    >
                                        <Select
                                            data-cy="recommended-plan"
                                            defaultText="Choose a Plan"
                                            defaultValue=""
                                            disabled={index === 0}
                                            errors={
                                                errors?.[
                                                    (`pathwayRecommendedPlans[${index}].planId` as unknown) as keyof typeof errors
                                                ]
                                            }
                                            items={
                                                index === 0
                                                    ? plansByCarrier
                                                    : getPlans(
                                                          plan.benchmarkType?.toString() as string,
                                                          plan.carrierId as string
                                                      )
                                            }
                                            label="Recommended Plan"
                                            name="planId"
                                            onBlurCapture={updatePathwayInputs}
                                            onChange={(e) => handleChange(e, index)}
                                            onFocus={updatePathwayInputs}
                                            optionText="name"
                                            optionValue="id"
                                            value={plan.planId}
                                        />
                                    </ConditionalTooltip>
                                </Skeleton>
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton
                                    count={1}
                                    height={55}
                                    isEnabled={isLoadingMarketplacePlans || !hasDataToSearchPlans}
                                >
                                    <Tooltip
                                        placement="bottom-end"
                                        title="This will be displayed above the plan as a way to add a short name that differentiates this option relative to the other recommended plans."
                                    >
                                        <TextArea
                                            data-cy="recommended-plan-label"
                                            isOptional
                                            label="Recommended Plan Label"
                                            name="planLabel"
                                            onBlurCapture={updatePathwayInputs}
                                            onChange={(e) => handleChange(e, index)}
                                            onFocus={updatePathwayInputs}
                                            value={plan.planLabel}
                                        />
                                    </Tooltip>
                                </Skeleton>
                            </Grid>
                            <Grid item ref={recPlanPosition[index]} xs={12}>
                                <Skeleton
                                    count={1}
                                    height={100}
                                    isEnabled={isLoadingMarketplacePlans || !hasDataToSearchPlans}
                                >
                                    <Tooltip
                                        placement="bottom-end"
                                        title="This description will be displayed in a tooltip that the member can hover over next to the Recommended Plan Label"
                                    >
                                        <TextArea
                                            data-cy="recommended-plan-description"
                                            isOptional
                                            label="Recommended Plan Description"
                                            maxLength={500}
                                            minRows={3}
                                            name="description"
                                            onBlurCapture={updatePathwayInputs}
                                            onChange={(e) => handleChange(e, index)}
                                            onFocus={updatePathwayInputs}
                                            value={plan.description}
                                        />
                                    </Tooltip>
                                </Skeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export default hot(module)(IchraRecommendedPlanInputs);
