import { ICcmEnrollmentInfo, ICcmEnrollmentInfoHouseholdMember } from 'api/generated/models';
import HeaderDropdown from 'components/headerDropdown/HeaderDropdown';
import React, { useCallback, useEffect, useState } from 'react';
import { Cell, HeaderProps } from 'react-table';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';

export const PROGRAM_TYPE_OPTIONS = ['Medi-Share', 'Other', 'None'];

export const ProgramTypeHeader = ({
    column: { id, filterValue, setFilter },
}: HeaderProps<ICcmEnrollmentInfo>) => (
    <HeaderDropdown
        columnId={id}
        id="program-type-filter-dropdown"
        options={PROGRAM_TYPE_OPTIONS}
        selected={filterValue}
        setSelected={setFilter}
        title="Program Type"
    />
);

const FIVE_THOUSAND_MILLISECOND = 5000;

export const SocialSecurityNumberCell = ({
    row: {
        original: { socialSecurityNumber },
    },
}: Cell<ICcmEnrollmentInfo>) => {
    const [delayTimeout, setDelayTimeout] = useState<NodeJS.Timeout | null>(null);
    const [isOver, setIsOver] = useState(false);
    const clearDelayTimeout = useCallback(() => {
        if (delayTimeout) {
            clearTimeout(delayTimeout);
            setDelayTimeout(null);
        }
    }, [delayTimeout]);
    useEffect(
        () => () => {
            clearDelayTimeout();
            setIsOver(true);
        },
        [clearDelayTimeout]
    );
    const onMouseEnter = useCallback(() => {
        clearDelayTimeout();
        setIsOver(true);
    }, [clearDelayTimeout]);
    const onMouseLeave = useCallback(() => {
        setDelayTimeout(
            setTimeout(() => {
                setIsOver(false);
            }, FIVE_THOUSAND_MILLISECOND)
        );
    }, []);
    const notOverContent = !hasValue(socialSecurityNumber) ? '' : 'XXX-XX-XXXX';
    return (
        <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {isOver ? socialSecurityNumber : notOverContent}
        </span>
    );
};

export const DateOfBirthCell = ({
    row: {
        original: { dateOfBirth },
    },
}: Cell<ICcmEnrollmentInfo | ICcmEnrollmentInfoHouseholdMember>) =>
    !hasValue(dateOfBirth) ? '' : `${formatDateForDisplay(dateOfBirth)} (${dateOfBirth?.getAge()})`;
