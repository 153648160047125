import { SAVE_COMPLETED_USER_TASK_ACTION } from 'actions/task/saveCompletedUserTask';
import { PATCH_YEARLY_USER_INFO_ACTION } from 'actions/user/patchYearlyUserInfo';
import { SET_USER_STATUS_ACTION } from 'actions/user/setUserStatus';
import Button from 'components/Button';
import SignableMemberTerms from 'components/SignableMemberTerms';
import React, { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities/index';
import PactSafe from 'utilities/pactSafe';

type IOptModalProps = {
    dataCyModal: string;
    dataCyNo: string;
    groupKey: string;
    isOptIn?: boolean;
    modalBody: JSX.Element | string;
    modalTitle: string;
    noText: JSX.Element | string;
    onHide: () => void;
    onNoClick: () => void;
    onYesClick: () => void;
    setSignature: (arg0: string) => void;
    signature: string;
    submitText: string;
};

const OptModal = ({
    dataCyModal,
    dataCyNo,
    groupKey,
    isOptIn = false,
    onNoClick,
    onYesClick,
    modalBody,
    modalTitle,
    noText,
    onHide,
    setSignature,
    signature,
    submitText,
}: IOptModalProps) => {
    const { isLoading } = useSelector((state: AppStore) => ({
        email: state.current.userProfile.user?.email,
        isLoading: hasApiActivity(
            state,
            SAVE_COMPLETED_USER_TASK_ACTION,
            SET_USER_STATUS_ACTION,
            PATCH_YEARLY_USER_INFO_ACTION
        ),
    }));
    const [hasAgreed, setHasAgreed] = useState(false);
    const hasConsented = hasAgreed && isOptIn;

    const handleClick = useCallback(() => {
        PactSafe.agreeToContract(groupKey);
        onYesClick();
    }, [groupKey, onYesClick]);

    const handleDisableSubmitButton = () => {
        if (hasConsented && hasValue(signature)) {
            return false;
        } else if (hasAgreed && !isOptIn) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <Modal data-cy={dataCyModal} onHide={onHide} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="text-justify">{modalBody}</div>
                <br />
                <SignableMemberTerms
                    canvasWidth={400}
                    groupKey={groupKey}
                    hasAgreed={hasAgreed}
                    isOptIn={isOptIn}
                    setHasAgreed={setHasAgreed}
                    setSignature={setSignature}
                />
            </Modal.Body>
            <Modal.Footer className="centered">
                <Row className="justify-content-center">
                    <Button
                        disabled={handleDisableSubmitButton()}
                        isLoading={isLoading}
                        onClick={handleClick}
                        type="submit"
                    >
                        {submitText}
                    </Button>
                    <div className="w-100" />
                    <Button
                        color="secondary"
                        data-cy={dataCyNo}
                        isLoading={isLoading}
                        onClick={onNoClick}
                        variant="text"
                    >
                        {noText}
                    </Button>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(OptModal);
