import { HouseholdMemberTypes, SurveyTypes } from 'api/generated/enums';
import { IUser } from 'api/generated/models';
import InformationIconTooltip from 'components/InformationIconTooltip';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import some from 'lodash/some';
import HouseholdMemberInfoSection from 'pages/profile/householdMemberInfo/HouseholdMemberInfoSection';
import HouseholdMemberModal from 'pages/profile/householdMemberInfo/HouseholdMemberModal';
import { showHouseholdIncomeModal } from 'pages/profile/profileActions';
import { getCanEditVerifiedInfo } from 'pages/profile/profileSelectors';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isTrue } from 'utilities';

type IHouseholdIncomeProps = {
    canViewVerifiedInfo: boolean;
    hasMemberVerifiedInfo: boolean | undefined;
    isCurrent: boolean;
    user: IUser | undefined;
};
const HouseholdMemberInfo = ({
    canViewVerifiedInfo,
    hasMemberVerifiedInfo,
}: IHouseholdIncomeProps) => {
    const dispatch = useThunkDispatch();
    const teamProps = useTeamProps();
    const userProps = useUserProps();
    const { canEditVerifiedInfo, disableAddSpouseButton } = useSelector((state: AppStore) => ({
        canEditVerifiedInfo: getCanEditVerifiedInfo(state, teamProps, userProps),
        disableAddSpouseButton: some(
            state.householdMembers,
            (h) => h.householdMemberTypeId === HouseholdMemberTypes.Spouse
        ),
    }));
    const [householdMemberModalTypeId, setHouseholdMemberModalTypeId] = useState<
        HouseholdMemberTypes
    >();
    const [showHouseholdMemberModal, setShowHouseholdMemberModal] = useState(false);
    const handleAdd = useCallback(
        (householdMemberTypeId) => () => {
            setShowHouseholdMemberModal(true);
            setHouseholdMemberModalTypeId(householdMemberTypeId);
        },
        []
    );
    const closeHouseholdMemberModal = useCallback(
        (shouldOpenIncomeModal) => {
            setShowHouseholdMemberModal(false);
            setHouseholdMemberModalTypeId(undefined);
            if (isTrue(shouldOpenIncomeModal)) {
                dispatch(showHouseholdIncomeModal());
            }
        },
        [dispatch]
    );
    const hasCompletedIchraSurvey =
        userProps.memberVerifiedInfo?.surveyTypeCompleted === SurveyTypes.ICHRA;
    if (hasMemberVerifiedInfo && canViewVerifiedInfo) {
        return (
            <React.Fragment>
                {showHouseholdMemberModal && (
                    <HouseholdMemberModal
                        handleClose={closeHouseholdMemberModal}
                        householdMemberTypeId={householdMemberModalTypeId}
                    />
                )}
                <HouseholdMemberInfoSection
                    householdMemberTypeId={HouseholdMemberTypes.Spouse}
                    label="Spouse"
                    onAddClick={handleAdd}
                    showAddButton={canEditVerifiedInfo && !disableAddSpouseButton}
                />
                {hasCompletedIchraSurvey ? (
                    <HouseholdMemberInfoSection
                        householdMemberTypeId={HouseholdMemberTypes.IchraChild}
                        label="Children"
                        onAddClick={handleAdd}
                        showAddButton={canEditVerifiedInfo}
                    />
                ) : (
                    <React.Fragment>
                        <HouseholdMemberInfoSection
                            householdMemberTypeId={HouseholdMemberTypes.Dependent}
                            label="Dependents"
                            onAddClick={handleAdd}
                            showAddButton={canEditVerifiedInfo}
                        />
                        <HouseholdMemberInfoSection
                            buttonLabel="Unclaimed Household Members"
                            householdMemberTypeId={HouseholdMemberTypes.Unclaimed}
                            label={
                                <span>
                                    Unclaimed Household Members{' '}
                                    <InformationIconTooltip title="Members of the household not claimed on your taxes." />
                                </span>
                            }
                            onAddClick={handleAdd}
                            showAddButton={canEditVerifiedInfo}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(HouseholdMemberInfo);
