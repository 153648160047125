import { UPDATE_SCROLL_GRADIENT_INDICATOR } from 'components/ScrollGradientIndicator';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { setTabBarIndex } from 'pages/survey/surveyActions';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import EventEmitter from 'utilities/eventEmitter';

export type IStepProps = {
    children: ReactNode;
    commonProps: {
        ignoreSubmit: boolean;
        onSubmit: () => void;
        overrideGoBack: () => void;
        submitText: string | undefined;
    };
};

const StepWrapper = ({
    Intro,
    onSubmit: parentOnSubmit,
    stepsToRender,
}: {
    Intro: (props: { onSkip?: () => void; onSubmit: () => void }) => JSX.Element;
    onSubmit?: (step: number) => void;
    stepsToRender: ((commonProps: IStepProps) => JSX.Element)[];
}) => {
    const dispatch = useThunkDispatch();
    const { isReviewStarted, tabIndex } = useSelector((state: AppStore) => ({
        isReviewStarted: state.surveyState.isReviewStarted,
        tabIndex: state.surveyState.tabIndex,
    }));
    const [step, setStep] = useState(isReviewStarted ? 1 : 0);

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [step]);

    const goBack = useCallback(() => setStep(step - 1), [step]);
    const onSkip = useCallback(async () => dispatch(setTabBarIndex(tabIndex + 1)), [
        dispatch,
        tabIndex,
    ]);
    const onSubmit = useCallback(() => {
        parentOnSubmit?.(step);
        if (step === stepsToRender.length) {
            onSkip();
        } else {
            setStep(step + 1);
        }
    }, [onSkip, parentOnSubmit, step, stepsToRender.length]);

    const FormToRender = useMemo(
        () => ({
            Form: stepsToRender[step - 1] as (commonProps: IStepProps) => JSX.Element,
            props: {
                commonProps: {
                    onSubmit,
                    ignoreSubmit: step !== stepsToRender.length,
                    overrideGoBack: goBack,
                    submitText:
                        isReviewStarted && step === stepsToRender.length ? undefined : 'Next',
                },
            },
        }),
        [goBack, isReviewStarted, onSubmit, step, stepsToRender]
    );
    if (step === 0) {
        return <Intro onSkip={onSkip} onSubmit={onSubmit} />;
    }
    return (
        <Col>
            <FormToRender.Form {...FormToRender.props}>
                <div className="text-center text-muted mb-1">
                    <small>
                        {step} of {stepsToRender.length}
                    </small>
                </div>
            </FormToRender.Form>
        </Col>
    );
};

export default hot(module)(StepWrapper);
