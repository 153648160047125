import { PlanStateIds, PlanTypeIds, TaskItems } from 'api/generated/enums';
import { IMemberVerifiedInfo } from 'api/generated/models';
import some from 'lodash/some';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

export const needSomeHouseholdSsnSelector = (
    state: AppStore,
    memberVerifiedInfo: IMemberVerifiedInfo | undefined
) => {
    const primarySsn = memberVerifiedInfo?.socialSecurityNumber;
    const membersNeedingCoverage = state.householdMembers.filter((x) => x.needsCoverage);
    return (
        !hasValue(primarySsn) ||
        some(membersNeedingCoverage, (h) => !hasValue(h.socialSecurityNumber))
    );
};

export const shouldShowSocialSecurityTaskSelector = (
    acceptRHServicesTask: TaskItems,
    state: AppStore,
    memberVerifiedInfo: IMemberVerifiedInfo | undefined,
    year: number | undefined
) => {
    const needSomeHouseholdSsn = needSomeHouseholdSsnSelector(state, memberVerifiedInfo);
    const acceptRHServicesComplete = state.userTasks.completedTasks.some(
        (x) => x.taskItemId === acceptRHServicesTask
    );
    return needSomeHouseholdSsn && year && acceptRHServicesComplete;
};

const PLAN_TYPES_FOR_KEEP_OR_CHANGE = [
    PlanTypeIds.Marketplace,
    PlanTypeIds.MediShare,
    PlanTypeIds.TermMedical,
    PlanTypeIds.SpouseEmployer,
    PlanTypeIds.ParentEmployer,
    PlanTypeIds.CustomStateBasedExchange,
    PlanTypeIds.CustomMajorMedical,
    PlanTypeIds.CustomOffExchange,
    PlanTypeIds.OffExchange,
];

export const getIsViewActiveBenefitsVisible = (state: AppStore) => {
    const hasEffectivePlans = state.selectedPlansForRenewal.hasEffectivePlans;
    return (
        hasEffectivePlans &&
        !(
            state.selectedPlansForRenewal?.effectiveMajorMedicalSelectedPlans?.some(
                (x) =>
                    PLAN_TYPES_FOR_KEEP_OR_CHANGE.includes(x.planTypeId) &&
                    x.planStateId === PlanStateIds.Effective
            ) ||
            state.selectedPlansForRenewal?.comparedMarketplaceAndOffExchangeSelectedPlans?.some(
                (x) => PLAN_TYPES_FOR_KEEP_OR_CHANGE.includes(x.planTypeId)
            )
        )
    );
};
