import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import MultiSelectButtons from 'pages/survey/multiSelectButtons/MultiSelectButtons';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { yesOrNo as yesOrNoOptions } from 'reducers/options';
import { isFalse, isTrue } from 'utilities';

type ISelectableYesOrNo = {
    isSelected?: boolean;
    text: string;
    value: boolean;
}[];

const YesOrNoSelect = ({
    onSelection: parentOnSelection,
    value,
}: {
    onSelection: (newValue: boolean | string) => void;
    value: boolean | string | undefined;
}) => {
    const [yesOrNo, setYesOrNo] = useState<ISelectableYesOrNo>(
        cloneDeep(orderBy(yesOrNoOptions, 'text')) as ISelectableYesOrNo
    );
    useEffect(() => {
        yesOrNo.forEach((x: ISelectableYesOrNo[number]) => {
            x.isSelected = false;
            if (
                x.value === value ||
                [x.value, value].every(isFalse) ||
                [x.value, value].every(isTrue)
            ) {
                x.isSelected = true;
            }
        });
        setYesOrNo([...yesOrNo]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    const onSelection = useCallback(
        (selection, index) => {
            parentOnSelection(selection.value);
            yesOrNo.forEach((x: any) => (x.isSelected = false)); // eslint-disable-line @typescript-eslint/no-explicit-any
            selection.isSelected = !selection.isSelected;
            yesOrNo[index] = selection;
            setYesOrNo([...yesOrNo]);
        },
        [parentOnSelection, yesOrNo]
    );
    return <MultiSelectButtons displayProp="text" onSelection={onSelection} options={yesOrNo} />;
};

export default hot(module)(YesOrNoSelect);
