import { IUserRoleStatus, User } from 'api/generated/models';
import NameTableCell from 'components/NameTableCell';
import PageHeader from 'components/PageHeader';
import ReactTable from 'components/reactTable/ReactTable';
import TextField from 'components/TextField';
import React, { useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { Cell, Column, FilterTypes } from 'react-table';
import { onChange } from 'utilities/forms';
import { DEFAULT_ACTIONS_COLUMN_PROPS } from 'utilities/reactTable';

const NAME_COLUMN_ID = 'name';
const NAME_FILTER_ID = 'nameFilter';

const filterTypes: FilterTypes<IUserRoleStatus> = {
    [NAME_FILTER_ID]: (rows, _, filterValue: string) =>
        rows.filter(
            ({ original: { firstName, lastName } }) =>
                firstName?.toLowerCase().includes(filterValue.toLowerCase()) ||
                lastName?.toLowerCase().includes(filterValue.toLowerCase())
        ),
};
const NameCell = ({ row }: Cell<IUserRoleStatus>) => (
    <NameTableCell userProfile={{ user: row.original as User }} />
);

const EditRoleUserTable = ({
    ActionsCell,
    title,
    userData,
}: {
    ActionsCell: (row: Cell<IUserRoleStatus>) => JSX.Element;
    title: string;
    userData: IUserRoleStatus[];
}) => {
    const [search, setSearch] = useState('');
    const columns = useMemo<Column<IUserRoleStatus>[]>(
        () => [
            {
                accessor: (u) => `${u.lastName} ${u.firstName}`,
                Cell: NameCell,
                filter: NAME_FILTER_ID,
                Header: 'Name',
                id: NAME_COLUMN_ID,
            },
            {
                accessor: (u) => u.email,
                Cell: ({ value }: Cell<IUserRoleStatus>) => value,
                Header: 'Email',
                id: 'email',
            },
            {
                ...DEFAULT_ACTIONS_COLUMN_PROPS,
                Cell: ActionsCell,
            },
        ],
        [ActionsCell]
    );

    const filters = useMemo(() => [{ id: NAME_COLUMN_ID, value: search }], [search]);

    return (
        <React.Fragment>
            <div className="tablehead text-center mt-2 mb-3 ml-2">
                <PageHeader variant="h5">{title}</PageHeader>
                <Row className="justify-content-center">
                    <TextField
                        name="search"
                        onChange={onChange(setSearch)}
                        placeholder="Search"
                        size="small"
                        type="search"
                        value={search}
                    />
                </Row>
            </div>
            <ReactTable
                columns={columns}
                data={userData}
                filters={filters}
                filterTypes={filterTypes}
            />
        </React.Fragment>
    );
};

export default hot(module)(EditRoleUserTable);
