import { HouseholdMemberTypes } from 'api/generated/enums';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveHouseholdMember } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isTrueOrFalse } from 'utilities';

const SpouseSameEmployerForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { spouse } = useSelector((state: AppStore) => ({
        spouse: state.surveyState.household.members?.find(
            (m) => m.householdMemberTypeId === HouseholdMemberTypes.Spouse
        ),
    }));
    const { hasSameEmployer } = spouse ?? {};
    const onSelection = useCallback(
        (isYes) =>
            dispatch(
                saveHouseholdMember({
                    ...spouse,
                    hasSameEmployer: isYes,
                })
            ),
        [dispatch, spouse]
    );
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={!isTrueOrFalse(hasSameEmployer)}>
            {children}
            <FormTitle>Does your spouse also work for your employer?</FormTitle>
            <YesOrNoSelect onSelection={onSelection} value={hasSameEmployer} />
        </SurveyFormWrapper>
    );
};

export default hot(module)(SpouseSameEmployerForm);
