import { Edit as EditIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CopyText from 'components/CopyText';
import { INLINE_EDIT_TOOLTIP_TITLE } from 'components/EditableAttribute';
import ProfileAttribute, { IProfileAttributeProps } from 'components/ProfileAttribute';
import Tooltip from 'components/Tooltip';
import isEmpty from 'lodash/isEmpty';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import { hasValue } from 'utilities';

const ContentContainer = styled.div<{ preserveNewLines: boolean }>`
    white-space: ${({ preserveNewLines }) => (preserveNewLines ? 'pre-line' : '')};
    overflow-wrap: ${({ preserveNewLines }) => (preserveNewLines ? 'break-word' : '')};
    min-width: 0;
`;

const EditableProfileAttribute = ({
    className = '',
    content,
    contentNote,
    'data-cy': dataCy,
    'data-private': dataPrivate,
    footer,
    hrClassName = '',
    infoTooltip = '',
    label,
    onClick,
    preserveNewLines = false,
    readonly = false,
    subtext = '',
    target = null,
    textToCopy = content?.toString(),
}: Omit<IProfileAttributeProps, 'children' | 'ColProps' | 'label'> & {
    'data-cy'?: string;
    content: ReactNode;
    contentNote?: ReactNode;
    label: ReactNode;
    onClick?: React.MouseEventHandler;
    preserveNewLines?: boolean;
    readonly?: boolean;
    subtext?: string;
    target?: React.Ref<HTMLDivElement>;
    textToCopy?: string;
}) => {
    let contentClassName = preserveNewLines ? 'text-left' : 'text-right';
    if ((content === null || content === undefined || isEmpty(content)) && !readonly) {
        contentClassName += ' text-muted ';
        content = `Set ${label}`;
    }

    return (
        <ProfileAttribute
            className={className}
            data-private={dataPrivate}
            footer={footer}
            hrClassName={hrClassName}
            infoTooltip={infoTooltip}
            label={label}
        >
            {readonly ? (
                <Stack>
                    <CopyText text={textToCopy ?? ''}>
                        <ContentContainer
                            className={contentClassName}
                            preserveNewLines={preserveNewLines}
                        >
                            {content}
                        </ContentContainer>
                    </CopyText>
                    {contentNote}
                </Stack>
            ) : (
                <div className="d-flex align-items-center justify-content-end flex-nowrap">
                    <Stack
                        alignItems="flex-end"
                        className="mr-1"
                        direction="column"
                        justifyContent="center"
                        spacing={0}
                    >
                        {hasValue(textToCopy) ? (
                            <CopyText text={textToCopy}>
                                <ContentContainer
                                    className={contentClassName}
                                    preserveNewLines={preserveNewLines}
                                    ref={target}
                                >
                                    {content}
                                    {contentNote}
                                </ContentContainer>
                            </CopyText>
                        ) : (
                            <Tooltip onClick={onClick} title={INLINE_EDIT_TOOLTIP_TITLE}>
                                <ContentContainer
                                    className={`clickable ${contentClassName}`}
                                    preserveNewLines={preserveNewLines}
                                    ref={target}
                                >
                                    {content}
                                    {contentNote}
                                </ContentContainer>
                            </Tooltip>
                        )}
                        {hasValue(subtext) && <div className="text-muted">{subtext}</div>}
                    </Stack>
                    <IconButton color="primary" data-cy={dataCy} onClick={onClick} size="small">
                        <EditIcon sx={{ height: 17, width: 17 }} />
                    </IconButton>
                </div>
            )}
        </ProfileAttribute>
    );
};
export default hot(module)(EditableProfileAttribute);
