import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Tasks } from 'api/generated/enums';
import { IYourJobDto, TaskDto } from 'api/generated/models';
import SummaryRowItem from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummaryRowItem';
import SummarySectionHeader from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummarySectionHeader';
import React from 'react';
import { hot } from 'react-hot-loader';

const YourJobSection = ({ tasks }: { tasks: TaskDto[] }) => {
    const task = tasks.find((t) => t.taskId === Tasks.YourJob);
    const taskId = task?.globalId as string;
    const chunk = task?.response as IYourJobDto;

    return (
        <Grid container direction="column" item rowGap={1}>
            <SummarySectionHeader label="Your Job" taskId={taskId} />
            <Divider sx={{ mb: 1, mt: -1 }} />
            <SummaryRowItem content={chunk.jobTitle} label="Job Title" />
            <SummaryRowItem content={chunk.hireDate} label="Hire Date" />
        </Grid>
    );
};

export default hot(module)(YourJobSection);
