import { GroupLevels } from 'api/generated/enums';
import { teamStateIds } from 'constants/teamStateIds';
import { AppStore } from 'reducers/appReducer';
import { createSelector } from 'reselect';
import { ITeamProps } from 'selectors';
import { isRhSelector } from 'selectors/role';

const teamSelector = (state: AppStore) => state.teamProfile.team;
const loginSelector = (state: AppStore) => state.login;
const teamProfilesSelector = (state: AppStore) => state.teamProfiles;
const teamTasksSelector = (state: AppStore) => state.teamTasks;
const userTasksSelector = (state: AppStore) => state.userTasks;
const currentTeamSelector = (state: AppStore) => state.current?.teamProfile?.team;

const isOnAnAdvisementTeamSelector = createSelector(currentTeamSelector, (team) => team?.isAdvisor);

export const getShouldRenderTeamStatuses = (state: AppStore, { hasTeamIdUrlParam }: ITeamProps) => {
    const isLoggedInUsersDashboard = !hasTeamIdUrlParam;
    const isRh = isRhSelector(state);
    return isLoggedInUsersDashboard && (isRh || isOnAnAdvisementTeamSelector(state));
};

export const isWaived = (state: AppStore) =>
    (state.current?.userProfile?.yearlyUserInfo?.majorMedicalCoverageLevel ?? -1) === GroupLevels.W;

export const isTeamAdvisorSelector = createSelector(
    teamSelector,
    loginSelector,
    (team, login) => login.teamId === team?.advisementTeamId
);

export const teamsReadyForLaunchSelector = createSelector(teamProfilesSelector, (teamProfiles) =>
    teamProfiles.filter(
        (tp) => tp.team?.teamStateId === teamStateIds.RhLaunch && !tp.team?.isArchived
    )
);

export const completedTasksSelector = createSelector(
    teamTasksSelector,
    userTasksSelector,
    (teamTasks, userTasks) => [
        ...(teamTasks?.completedTasks ?? []),
        ...(userTasks?.completedTasks ?? []),
    ]
);
