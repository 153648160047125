import { Card, CardContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import { getMonthAndYearOfMostRecentPayrollReport } from 'actions/payroll/getMonthAndYearOfMostRecentPayrollReport';
import { ViewTeamSharedPayrollReports } from 'api/generated/permissions';
import Button from 'components/Button';
import { MONTHS } from 'constants/date';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isEmpty from 'lodash/isEmpty';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { PAYROLL_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';

const QUERY_PARAM = {
    MONTH: 'month',
    YEAR: 'year',
} as const;

const MostRecentPayrollReportCard = () => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { canViewTeamSharedPayrollReports, mostRecentPayrollReportYearAndMonth } = useSelector(
        (state: AppStore) => ({
            canViewTeamSharedPayrollReports: hasSomePermissions(
                state,
                ViewTeamSharedPayrollReports
            ),
            mostRecentPayrollReportYearAndMonth: state.payrollReportYearMonth,
        })
    );
    const { year, month } = mostRecentPayrollReportYearAndMonth;
    const monthName = MONTHS[month - 1]?.name;
    const [query] = useQuery();
    query.set(QUERY_PARAM.YEAR, year?.toString());
    query.set(QUERY_PARAM.MONTH, month?.toString());

    useEffect(() => {
        if (canViewTeamSharedPayrollReports) {
            dispatch(getMonthAndYearOfMostRecentPayrollReport(teamId));
        }
    }, [canViewTeamSharedPayrollReports, dispatch, teamId]);

    return isEmpty(mostRecentPayrollReportYearAndMonth) ? (
        <React.Fragment />
    ) : (
        <Card>
            <CardContent>
                <DashboardCardHeader header={`Most recent Payroll Report: ${monthName} ${year}`} />
                <hr></hr>
                <Stack alignItems="center">
                    <Link
                        className="m-2"
                        data-cy="view-most-recent-report"
                        to={`${PAYROLL_PATH}?${query}`}
                    >
                        <Button size="small">View Payroll Report</Button>
                    </Link>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default hot(module)(MostRecentPayrollReportCard);
