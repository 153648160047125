import { InfoOutlined } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SuccessEvent, useFinchConnect } from '@tryfinch/react-connect';
import {
    CREATE_FINCH_ACCESS_TOKEN_ACTION,
    createFinchAccessToken,
} from 'actions/finch/createFinchAccessToken';
import {
    CREATE_FINCH_CONNECT_SESSION_ACTION,
    createFinchConnectSession,
} from 'actions/finch/createFinchConnectSession';
import { FinchIntegrationStatuses, HrsIntegrationProviders } from 'api/generated/enums';
import Button from 'components/Button';
import ProfileAttribute from 'components/ProfileAttribute';
import Toast from 'components/Toast';
import Tooltip from 'components/Tooltip';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import ErrorIcon from '@mui/icons-material/Error';
import { hasApiActivity } from 'selectors/activity';

const FinchConnect = ({
    sessionId,
    setLocalSessionId,
    setSubmitted,
}: {
    sessionId: string;
    setLocalSessionId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const dispatch = useThunkDispatch();
    const { team } = useTeamProps();

    const { open } = useFinchConnect({
        sessionId,
        onClose: () => {
            setLocalSessionId(undefined);
        },
        onError: () => Toast.error('Failed to connect with Finch'),
        onSuccess: (value: SuccessEvent) => {
            setSubmitted(true);
            if (team) {
                const code = value.code;
                const teamId = team.teamId;
                dispatch(createFinchAccessToken(code, teamId));
            }
        },
        zIndex: 2000,
    });

    useEffect(() => {
        open();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <React.Fragment />;
};

const FinchIntegrationCard = () => {
    const dispatch = useThunkDispatch();
    const { team } = useTeamProps();
    const { isSaving, sessionId } = useSelector((state: AppStore) => ({
        isSaving: hasApiActivity(
            state,
            CREATE_FINCH_CONNECT_SESSION_ACTION,
            CREATE_FINCH_ACCESS_TOKEN_ACTION
        ),
        sessionId: state.teamFinch.sessionId,
    }));

    const [localSessionId, setLocalSessionId] = useState<string | undefined>();
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => {
        setLocalSessionId(sessionId);
    }, [sessionId]);

    const openFinchConnect = async () => {
        if (!sessionId && team) {
            await dispatch(createFinchConnectSession(team.teamId));
        } else if (sessionId) {
            setLocalSessionId(sessionId);
        }
    };

    const OverrideActionButtons = useCallback(
        () =>
            team?.finchIntegrationStatus === FinchIntegrationStatuses.Reauth ? (
                <Tooltip title='Your Finch connection requires reauthentication. Click the "Connect to HRIS" button to reestablish the connection to your HRIS system.'>
                    <React.Fragment>
                        <Typography variant="caption">
                            Attention Required <ErrorIcon color="warning" />
                        </Typography>
                    </React.Fragment>
                </Tooltip>
            ) : (
                <Typography fontWeight={700} variant="h4">
                    <Tooltip title="Our HRIS integrations allow you to connect your HR information system for seamless exchange of employee and payroll data. Remodel Health uses Finch, an aggregator service, to securely integrate with various employment and payroll providers. Support for payroll data integration varies by HRIS provider.">
                        <InfoOutlined color="secondary" />
                    </Tooltip>
                </Typography>
            ),
        [team?.finchIntegrationStatus]
    );

    return team?.hrsIntegrationProvider !== HrsIntegrationProviders.Finch ? (
        <React.Fragment />
    ) : (
        <Card>
            <CardContent>
                <DashboardCardHeader
                    header="HRIS Integration"
                    OverrideActionButtons={OverrideActionButtons}
                />
                <hr></hr>
                {![
                    FinchIntegrationStatuses.NotConfigured,
                    FinchIntegrationStatuses.Reauth,
                ].contains(team?.finchIntegrationStatus) ? (
                    <React.Fragment>
                        {team?.finchProviderName && (
                            <ProfileAttribute label="Provider">
                                {startCase(team?.finchProviderName)}
                            </ProfileAttribute>
                        )}
                        <ProfileAttribute label="Connection Status">
                            {startCase(
                                FinchIntegrationStatuses[
                                    team?.finchIntegrationStatus ??
                                        FinchIntegrationStatuses.NotConfigured
                                ]
                            )}
                        </ProfileAttribute>
                    </React.Fragment>
                ) : (
                    <Stack alignItems="center">
                        <Button
                            className="m-2"
                            isLoading={isSaving}
                            onClick={openFinchConnect}
                            size="small"
                        >
                            Connect to HRIS
                        </Button>
                        {localSessionId && !submitted && (
                            <FinchConnect
                                sessionId={localSessionId}
                                setLocalSessionId={setLocalSessionId}
                                setSubmitted={setSubmitted}
                            />
                        )}
                    </Stack>
                )}
            </CardContent>
        </Card>
    );
};

export default hot(module)(FinchIntegrationCard);
