import Button from '@mui/material/Button';
import { GET_ROLES_ACTION, getRoles } from 'actions/role/getRoles';
import { IRole } from 'api/generated/models';
import { CreateRole, EditRole } from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import PageSectionWrapper from 'components/PageSectionWrapper';
import ReactTable from 'components/reactTable/ReactTable';
import TableHeader from 'components/TableHeader';
import { push } from 'connected-react-router';
import useQuery from 'hooks/useQuery';
import useThunkDispatch from 'hooks/useThunkDispatch';
import SystemRoleModal from 'pages/systemRoles/SystemRoleModal';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { SYS_ROLES_EDIT_PATH, SYS_ROLES_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';
import { DEFAULT_ACTIONS_COLUMN_PROPS } from 'utilities/reactTable';

const NAME_COLUMN_ID = 'roleName';

const ActionCell = ({ value }: Cell<IRole, string>) => {
    const { enableEditRole, enableRenameRole } = useSelector((state: AppStore) => ({
        enableEditRole: state.login.up.includes(EditRole),
        enableRenameRole: state.login.up.includes(EditRole),
    }));
    return (
        <div>
            {enableRenameRole && (
                <Button
                    component={Link}
                    to={`${SYS_ROLES_PATH}?renameRole&roleId=${value}`}
                    variant="outlined"
                >
                    Rename
                </Button>
            )}
            {enableEditRole && (
                <Button
                    component={Link}
                    sx={{ ml: 2 }}
                    to={SYS_ROLES_EDIT_PATH.replace(':roleId', value)}
                    variant="outlined"
                >
                    Edit Permissions
                </Button>
            )}
        </div>
    );
};

const columns = [
    {
        accessor: (row) => row.name,
        filter: 'text',
        Header: 'Role Name',
        id: NAME_COLUMN_ID,
    },
    {
        ...DEFAULT_ACTIONS_COLUMN_PROPS,
        accessor: (row) => row.roleId,
        Cell: ActionCell,
    },
] as Column<IRole>[];

const SystemRolesPage = () => {
    const dispatch = useThunkDispatch();
    const [query] = useQuery();
    const showModal = query.has('addRole') || query.has('renameRole');
    const { canEditRole, enableAddRole, roles, showActivity } = useSelector((state: AppStore) => ({
        canEditRole: hasSomePermissions(state, EditRole),
        enableAddRole: state.login.up.includes(CreateRole),
        roles: state.roles,
        showActivity: hasApiActivity(state, GET_ROLES_ACTION),
    }));

    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    if (!canEditRole) {
        dispatch(push('/'));
    }

    const handleModalClose = () => dispatch(push(SYS_ROLES_PATH));

    const filters = useMemo(() => [{ id: NAME_COLUMN_ID, value: search }], [search]);

    return (
        <PageSectionWrapper>
            {showModal && <SystemRoleModal handleClose={handleModalClose} />}
            <TableHeader
                items={
                    [
                        {
                            isVisible: enableAddRole,
                            link: { to: `${SYS_ROLES_PATH}?addRole` },
                            text: 'Add New Role',
                        },
                    ] as IActionButtonItems
                }
                onChange={onChange(setSearch)}
                search={search}
            />
            <ReactTable columns={columns} data={roles} filters={filters} loading={showActivity} />
        </PageSectionWrapper>
    );
};

export default hot(module)(SystemRolesPage);
