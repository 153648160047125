import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import { UPDATE_SCROLL_GRADIENT_INDICATOR } from 'components/ScrollGradientIndicator';
import GENDERS from 'constants/genders';
import useThunkDispatch from 'hooks/useThunkDispatch';
import filter from 'lodash/filter';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveHouseholdMember, saveMemberForm } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import { ISurveyUser } from 'pages/survey/surveyState';
import YesNoWho from 'pages/survey/YesNoWho';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isTrueOrFalse } from 'utilities';
import EventEmitter from 'utilities/eventEmitter';
import { getDisplayFirstName } from 'utilities/household';

const PregnantForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { householdMembers, isPrimaryFemale, isPrimaryPregnant, member } = useSelector(
        (state: AppStore) => ({
            householdMembers: filter(state.surveyState.household.members, [
                'gender',
                GENDERS.FEMALE,
            ]),
            isPrimaryFemale: state.surveyState.member.gender === GENDERS.FEMALE,
            isPrimaryPregnant: state.surveyState.member.isPregnant,
            member: state.surveyState.member,
        })
    );

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [householdMembers, isPrimaryPregnant]);

    const isOneFemale = useMemo(
        () =>
            (!isPrimaryFemale && householdMembers.length === 1) ||
            (isPrimaryFemale && !householdMembers.length),
        [isPrimaryFemale, householdMembers]
    );
    const initialDisabled = useMemo(() => {
        const isMemberPregnant = householdMembers[0]?.isPregnant;
        if (isOneFemale && (isTrueOrFalse(isPrimaryPregnant) || isTrueOrFalse(isMemberPregnant))) {
            return false;
        }
        return true;
    }, [householdMembers, isOneFemale, isPrimaryPregnant]);
    const [isDisabled, setIsDisabled] = useState(initialDisabled);
    const isPregnant = useMemo(
        () => (isPrimaryFemale ? isPrimaryPregnant : householdMembers[0]?.isPregnant),
        [householdMembers, isPrimaryFemale, isPrimaryPregnant]
    );
    const onYesNoSelection = useCallback(
        (isPregnant) => {
            if (isPrimaryFemale) {
                dispatch(saveMemberForm({ isPregnant }));
            } else {
                dispatch(saveHouseholdMember({ ...householdMembers[0], isPregnant }));
            }
            setIsDisabled(false);
        },
        [dispatch, householdMembers, isPrimaryFemale]
    );
    const onSelection = useCallback(
        ({
            isInvalid,
            options: newOptions,
        }: {
            isInvalid: boolean;
            isYes: boolean | undefined;
            options: (ISurveyHouseholdMemberDto | ISurveyUser)[];
        }) => {
            if (isPrimaryFemale) {
                const primary = newOptions.find((x) => (x as ISurveyUser).teamId);
                dispatch(saveMemberForm({ isPregnant: primary?.isPregnant }));
            }
            if (householdMembers.length) {
                const membersToSave = newOptions.filter(
                    (x) => !(x as ISurveyUser).teamId
                ) as ISurveyHouseholdMemberDto[];
                membersToSave.forEach((memberToSave) =>
                    dispatch(saveHouseholdMember(memberToSave))
                );
            }
            setIsDisabled(isInvalid);
        },
        [dispatch, householdMembers.length, isPrimaryFemale]
    );
    const options = useMemo(() => {
        const femaleMembers: (ISurveyHouseholdMemberDto | ISurveyUser)[] = [...householdMembers];
        if (isPrimaryFemale) {
            femaleMembers.unshift({ ...member, isPregnant: isPrimaryPregnant as boolean });
        }
        return femaleMembers;
    }, [householdMembers, isPrimaryFemale, isPrimaryPregnant, member]);
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled}>
            {children}
            {isOneFemale ? (
                <React.Fragment>
                    <FormTitle>
                        {isPrimaryFemale
                            ? 'Are you'
                            : `Is ${getDisplayFirstName(householdMembers[0])}`}{' '}
                        pregnant?
                    </FormTitle>
                    <YesOrNoSelect onSelection={onYesNoSelection} value={isPregnant} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FormTitle>Is anyone in your household pregnant?</FormTitle>
                    <YesNoWho
                        isSelectedProp="isPregnant"
                        onSelection={onSelection}
                        options={options}
                    />
                </React.Fragment>
            )}
        </SurveyFormWrapper>
    );
};

export default hot(module)(PregnantForm);
