import { SurveyTypes } from 'api/generated/enums';
import useUserProps from 'hooks/useUserProps';
import StepIntro from 'pages/survey/StepIntro';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const HouseholdIntro = ({ onSubmit }: { onSubmit: React.FormEventHandler }) => {
    const year = useSelector((state: AppStore) => state.surveyState.incomeYear);
    const { user } = useUserProps();
    const descriptionContent =
        user?.surveyTypeToSend === SurveyTypes.Standard
            ? `We will ask about the people in your household, including those that you will claim on your taxes in ${year}, and those that you will not claim but are covered by your current benefits.`
            : 'We will ask you to add information about members of your household that will need to be covered on your health insurance.';
    return (
        <StepIntro
            description={descriptionContent}
            onSubmit={onSubmit}
            title="Tell us about your household."
        />
    );
};

export default hot(module)(HouseholdIntro);
