import { AppStoreThunkDispatch } from 'actions/commonAction';
import {
    transitionToMemberLaunch,
    TRANSITION_TO_MEMBER_LAUNCH_ACTION
} from 'actions/team/transitionToMemberLaunch';
import ActivityIndicator from 'components/ActivityIndicator';
import { taskItemIds, taskListTypes } from 'constants/tasks';
import { completedTasksSelector } from 'pages/dashboard/dashboardSelectors';
import TaskListContainer from 'pages/dashboard/taskLists/TaskListContainer';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

type IRhTeamMemberReadyForMemberLaunchTaskListProps = DispatchProps &
    StateProps & {
        teamId: string;
    };

class RhTeamMemberReadyForMemberLaunchTaskList extends React.PureComponent<
    IRhTeamMemberReadyForMemberLaunchTaskListProps
> {
    renderTaskItems = () => [
        <TaskListItem
            buttonText="I'm Done"
            complete={this.props.reviewTeamForMemberLaunchComplete}
            data-cy="review-team"
            description="Ensure the team is ready for members to start creating accounts."
            enabled
            key={taskItemIds.ReviewTeamForMemberLaunch}
            markCompleteOnAction
            showButton
            taskId={taskItemIds.ReviewTeamForMemberLaunch}
            title="Review Team"
        />,
        <TaskListItem
            action={async () => this.props.transitionToMemberLaunch(this.props.teamId)}
            buttonText="Ready to Launch Members"
            complete={this.props.transitionTeamToMemberLaunchComplete}
            data-cy="transition-team-to-member-launch"
            description="Unlock ability to send account invites to members from the People page."
            enabled={this.props.reviewTeamForMemberLaunchComplete}
            key={taskItemIds.TransitionToMemberLaunch}
            showButton
            taskId={taskItemIds.TransitionToMemberLaunch}
            title="Transition Team to Member Launch"
        />,
    ];

    override render() {
        return (
            <React.Fragment>
                <TaskListContainer
                    completeMessage={<div>Great job! Member signup emails have been sent out!</div>}
                    description="Review team details to confirm team is ready to have members sign up."
                    taskListType={taskListTypes.Team}
                    teamId={this.props.teamId}
                    title="Tasks for Transitioning Team to Member Launch"
                >
                    {this.renderTaskItems()}
                </TaskListContainer>
                {this.props.showActivity && <ActivityIndicator />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: AppStore) => {
    const completedTasks = completedTasksSelector(state);

    return {
        reviewTeamForMemberLaunchComplete: completedTasks.some(
            (x) => x.taskItemId === taskItemIds.ReviewTeamForMemberLaunch
        ),
        showActivity: state.apiActivity[TRANSITION_TO_MEMBER_LAUNCH_ACTION],
        transitionTeamToMemberLaunchComplete: completedTasks.some(
            (x) => x.taskItemId === taskItemIds.TransitionToMemberLaunch
        ),
    };
};

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    transitionToMemberLaunch: async (teamId: string) => dispatch(transitionToMemberLaunch(teamId)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(
    connect(mapStateToProps, mapDispatchToProps)(RhTeamMemberReadyForMemberLaunchTaskList)
);
