import { ListItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { COMPLETE_TASK_ACTION, completeTask } from 'actions/taskFlows/completeTask';
import { ISelectedPlansDto } from 'api/generated/models';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { ICHRA_OPT_OUT_KEY } from 'constants/pactSafeGroups';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { DASHBOARD_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { onAllPactSafe } from 'utilities/forms';
import { IS_DEV_MODE } from 'utilities/index';
import { PACTSAFE_ACCESS_ID } from 'utilities/pactSafe';

type IWaiveIchraModalProps = {
    currentTaskGlobalId: string;
    onClose: () => void;
    redirectToDashboard?: boolean;
};
const WaiveIchraModal = ({
    redirectToDashboard = false,
    currentTaskGlobalId,
    onClose,
}: IWaiveIchraModalProps) => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, COMPLETE_TASK_ACTION),
    }));
    const [hasAgreed, setHasAgreed] = useState(false);
    const { user } = useUserProps();

    const handleWaive = () => {
        const response: ISelectedPlansDto = { isWaived: true };
        dispatch(
            completeTask(
                currentTaskGlobalId,
                response,
                redirectToDashboard ? DASHBOARD_PATH : undefined
            )
        );
    };

    const waivedBenefitsContent = (
        <List
            sx={{
                listStylePosition: 'inside',
                listStyleType: 'disc',
            }}
        >
            <ListItem style={{ display: 'list-item' }}>
                The Individual Coverage Health Reimbursement Arrangement (ICHRA) offered by your
                employer.
            </ListItem>
        </List>
    );

    return (
        <Dialog fullWidth maxWidth="md" onClose={onClose} open scroll="paper">
            <DialogTitle>
                <Typography textAlign="center" variant="h2">
                    Waive ICHRA Benefit
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>{`You will be waiving the following for ${user?.activeDate?.getYear()}:`}</Typography>
                <Stack spacing={1}>
                    {waivedBenefitsContent}
                    <Typography>Are you sure you want to waive this benefit?</Typography>
                    <br />
                    <PSClickWrap
                        accessId={PACTSAFE_ACCESS_ID}
                        disableSending
                        forceScroll
                        groupKey={ICHRA_OPT_OUT_KEY}
                        onAll={onAllPactSafe(setHasAgreed)}
                        signerId={user?.email}
                        testMode={IS_DEV_MODE}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ margin: 1.5 }}>
                <Stack direction="row" spacing={2}>
                    <Button onClick={onClose} variant="text">
                        Cancel
                    </Button>
                    <Button
                        color="warning"
                        disabled={!hasAgreed}
                        isLoading={isLoading}
                        onClick={handleWaive}
                        variant="outlined"
                    >
                        Waive ICHRA
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(WaiveIchraModal);
