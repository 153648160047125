import {
    HIDE_TEAM_CONFIRMATION_MODAL,
    SHOW_TEAM_CONFIRMATION_MODAL,
} from 'pages/teams/teamActions';
import basicReducer from 'reducers/basicReducer';

const initialState = {
    apiActions: [] as string[],
    modalBody: '',
    noClickAction: undefined as (() => void) | undefined,
    showConfirmationModal: false,
    yesClickAction: undefined as (() => void) | undefined,
};

export const teamState = basicReducer(
    initialState,
    HIDE_TEAM_CONFIRMATION_MODAL,
    SHOW_TEAM_CONFIRMATION_MODAL
);
