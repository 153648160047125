import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { addResource, ADD_RESOURCE_ACTION } from 'actions/resources/addResource';
import { editResource, EDIT_RESOURCE_ACTION } from 'actions/resources/editResource';
import { getTeamProfiles, GET_TEAM_PROFILES_ACTION } from 'actions/team/getTeamProfiles';
import { IResource, ITeamProfile, ITeamResource, IUser, TeamResource } from 'api/generated/models';
import Autocomplete, { IAutocompleteProps } from 'components/Autocomplete';
import AutoFocusTextField from 'components/AutoFocusTextField';
import Button from 'components/Button';
import { HighlightedSearchText } from 'components/creatableAutocomplete/CreatableAutocomplete';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import RadioGroup from 'components/RadioGroup';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import kebabCase from 'lodash/kebabCase';
import moment from 'moment';
import ResourceCard from 'pages/resourceManagement/ResourceCard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { hasValue, isFalse, isTrue, toUndefinedOrBoolean } from 'utilities';
import { formatDateForDisplay, formatUserOnDateAtTime } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { DISPLAY_DATE_FORMAT } from 'utilities/moment';
import { object, string } from 'yup';

const getTeamSearchRenderOptions: IAutocompleteProps<ITeamProfile>['renderOption'] = (
    props,
    { address, team },
    _state
) => (
    <li
        {...props}
        key={`${kebabCase(address?.city)}-${kebabCase(address?.state)}-${address?.zip}-${kebabCase(
            team?.name
        )}`}
    >
        <Stack borderBottom={0.5} pb={1} width="100%">
            <Box pb={1}>
                <HighlightedSearchText query={_state.inputValue} text={team?.name} />
            </Box>
            {address && (
                <Typography variant="caption">
                    {address.city}, {address.state}, {address.zip}
                </Typography>
            )}
        </Stack>
    </li>
);

const MAX_TITLE_LENGTH = 75;
const MAX_URL_LENGTH = 4000;
const schema = object({
    sharingEndDate: string()
        .isValidDate(false)
        .label('Sharing End Date'),
    title: string()
        .trim()
        .required()
        .max(MAX_TITLE_LENGTH)
        .label('Title'),
    url: string()
        .trim()
        .required()
        .url()
        .max(MAX_URL_LENGTH)
        .label('Content Url'),
});

const CONTENT_URL_TOOLTIP =
    'This is the specific URL link to the content to be shared, ' +
    'you should ensure this link is publicly accessible via your browser as a test. ' +
    'The format should be https://vimeo.com/specificURL for example.';

const createDateAndEmailString = (date?: string, user?: IUser) =>
    date && user ? formatUserOnDateAtTime(user, date) : '';

const yesNoOptions = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];

type IResourceModalProps = {
    onClose: () => void;
    resource?: IResource;
};

const ResourceModal = ({ resource, onClose }: IResourceModalProps) => {
    const dispatch = useThunkDispatch();
    const [title, setTitle] = useState(resource?.title ?? '');
    const [url, setUrl] = useState(resource?.url ?? '');
    const [sharingEndDate, setSharingEndDate] = useState(
        formatDateForDisplay(resource?.sharingEndDate ?? '')
    );
    const [shouldSendEmailNotice, setShouldSendEmailNotice] = useState(false);
    const [isPinnedToTop, setIsPinnedToTop] = useState(resource?.isPinnedToTop ?? false);
    const [isSharedToAllMembers, setIsSharedToAllMembers] = useState<boolean | string | null>(
        resource?.isSharedToAllMembers ?? ''
    );
    const [isSharedWithAllTeams, setIsSharedWithAllTeams] = useState<boolean | string | null>(
        resource?.isSharedWithAllTeams ?? ''
    );
    const [selectedTeamsOptions, setSelectedTeamsOptions] = useState<ITeamProfile[]>([]);
    const { errors, validate } = useForm(schema);
    const created = createDateAndEmailString(resource?.createdDate, resource?.createdByUser);
    const lastModified = createDateAndEmailString(
        resource?.lastModifiedDate,
        resource?.lastModifiedByUser
    );

    const { hasTeams, isLoadingTeams, isSaving, teamProfiles } = useSelector((state: AppStore) => ({
        hasTeams: hasCompletedRequest(state, GET_TEAM_PROFILES_ACTION),
        isLoadingTeams: hasApiActivity(state, GET_TEAM_PROFILES_ACTION),
        isSaving: hasApiActivity(state, ADD_RESOURCE_ACTION, EDIT_RESOURCE_ACTION),
        teamProfiles: state.teamProfiles,
    }));

    const compareInitialValuesToCurrentValues = useCallback(() => {
        const isSharedToAllMembersCompare =
            toUndefinedOrBoolean(isSharedToAllMembers) === resource?.isSharedToAllMembers;
        const isSharedWithAllTeamsCompare =
            toUndefinedOrBoolean(isSharedWithAllTeams) === resource?.isSharedWithAllTeams;
        const selectedTeamsCompare = selectedTeamsOptions.filter(
            (item1) =>
                !resource?.teamResources?.some((item2) => item2.teamId === item1.team?.teamId)
        );
        return (
            isSharedToAllMembersCompare &&
            isSharedWithAllTeamsCompare &&
            selectedTeamsCompare.length === 0
        );
    }, [isSharedToAllMembers, isSharedWithAllTeams, selectedTeamsOptions, resource]);

    const sendEmailCheckboxContent = useMemo(() => {
        const disableSendEmailIfNoChange = compareInitialValuesToCurrentValues();
        const isTodayAfterSharingEndDate = moment()
            .startOf('day')
            .isAfter(moment(sharingEndDate, DISPLAY_DATE_FORMAT));
        const disableSendEmail = disableSendEmailIfNoChange || isTodayAfterSharingEndDate;
        const sendEmailCheckbox = (
            <RadioGroup
                disabled={disableSendEmail}
                label="Send Email Notice"
                onChange={onChange(setShouldSendEmailNotice)}
                options={yesNoOptions}
                row
                value={shouldSendEmailNotice}
            />
        );
        if (disableSendEmail && shouldSendEmailNotice) {
            setShouldSendEmailNotice(false);
        }
        return isTodayAfterSharingEndDate ? (
            <Tooltip title="Cannot send email notification when sharing end date has passed">
                {sendEmailCheckbox}
            </Tooltip>
        ) : (
            sendEmailCheckbox
        );
    }, [sharingEndDate, shouldSendEmailNotice, compareInitialValuesToCurrentValues]);

    useEffect(() => {
        dispatch(getTeamProfiles());
    }, [dispatch]);

    useEffect(() => {
        if (resource?.teamResources && hasTeams) {
            setSelectedTeamsOptions(
                teamProfiles.filter((t) =>
                    resource.teamResources?.some((tr) => tr.teamId === t.team?.teamId)
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasTeams]);

    const submit = async () => {
        const { data, isValid } = await validate({ sharingEndDate, title, url });
        if (isValid) {
            const r: Partial<IResource> = {
                ...data,
                isPinnedToTop: toUndefinedOrBoolean(isPinnedToTop),
                isSharedToAllMembers: toUndefinedOrBoolean(isSharedToAllMembers),
                isSharedWithAllTeams: toUndefinedOrBoolean(isSharedWithAllTeams),
                shouldNotify: toUndefinedOrBoolean(shouldSendEmailNotice),
                teamResources: isTrue(isSharedWithAllTeams)
                    ? undefined
                    : selectedTeamsOptions.map(
                          (t) => new TeamResource({ teamId: t.team?.teamId } as ITeamResource)
                      ),
            };
            if (resource?.id) {
                await dispatch(editResource({ ...resource, ...r }));
            } else {
                await dispatch(addResource(r as IResource));
            }
            onClose();
        }
    };

    const onShareWithChange = (shareWithValue: boolean) => {
        if (isFalse(shareWithValue)) {
            setSelectedTeamsOptions([]);
        }
        setIsSharedWithAllTeams(shareWithValue);
    };

    return (
        <Modal onHide={onClose} scrollable show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Resources</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
                <Form className="flex-grow-1" id="resource-modal-form" onSubmit={submit}>
                    <Stack gap={2}>
                        <ResourceCard title={title} url={url} />

                        <TextField
                            autoFocus
                            errors={errors?.title}
                            helperText="A brief description of the content"
                            label="Title"
                            name="title"
                            onChange={onChange(setTitle)}
                            placeholder="Enter a title"
                            value={title}
                        />

                        <TextField
                            errors={errors?.url}
                            helperText={CONTENT_URL_TOOLTIP}
                            label="Content Url"
                            name="url"
                            onChange={onChange(setUrl)}
                            placeholder="Enter a content url"
                            value={url}
                        />

                        <RadioGroup
                            isOptional
                            label="Share To"
                            onChange={onChange(setIsSharedToAllMembers)}
                            options={[
                                {
                                    label: 'All Members (Includes Team Admins)',
                                    value: true,
                                },
                                {
                                    label: 'Team Admins',
                                    value: false,
                                },
                            ]}
                            value={isSharedToAllMembers}
                        />

                        <RadioGroup
                            isOptional
                            label="Share With"
                            onChange={onChange(onShareWithChange)}
                            options={[
                                {
                                    label: 'All Teams',
                                    value: true,
                                },
                                {
                                    label: 'Selected Teams',
                                    value: false,
                                },
                            ]}
                            value={isSharedWithAllTeams}
                        />
                        {isFalse(isSharedWithAllTeams) && (
                            <Box sx={{ ml: 2 }}>
                                <Autocomplete
                                    getOptionLabel={(x) => x?.team?.name ?? ''}
                                    loading={isLoadingTeams}
                                    multiple
                                    onChange={(_, selected: ITeamProfile[]) =>
                                        setSelectedTeamsOptions(selected ?? [])
                                    }
                                    options={teamProfiles ?? []}
                                    renderInput={(props) => (
                                        <AutoFocusTextField
                                            {...props}
                                            placeholder="Select one or more teams"
                                        />
                                    )}
                                    renderOption={getTeamSearchRenderOptions}
                                    value={selectedTeamsOptions}
                                />
                            </Box>
                        )}

                        <DateTextField
                            errors={errors?.sharingEndDate}
                            isOptional
                            label="Sharing End Date"
                            name="sharingEndDate"
                            onChange={onChange(setSharingEndDate)}
                            value={sharingEndDate}
                        />

                        {sendEmailCheckboxContent}

                        <RadioGroup
                            label="Pin to Top"
                            onChange={onChange(setIsPinnedToTop)}
                            options={yesNoOptions}
                            row
                            value={isPinnedToTop}
                        />

                        {hasValue(created) && (
                            <React.Fragment>
                                <label>Created By</label>
                                <p>{created}</p>
                            </React.Fragment>
                        )}
                        {hasValue(lastModified) && (
                            <React.Fragment>
                                <label>Last Modified By</label>
                                <p>{lastModified}</p>
                            </React.Fragment>
                        )}
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button disabled={isSaving} onClick={onClose}>
                    Cancel
                </Button>
                <Button form="resource-modal-form" isLoading={isSaving} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(ResourceModal);
