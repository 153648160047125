import { Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import { clearCounties, clearGetCountiesError, clearTeamApiErrors } from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { getStates } from 'actions/getStates';
import { ADD_TEAM_ACTION } from 'actions/team/addTeam';
import { EDIT_TEAM_ACTION } from 'actions/team/editTeam';
import { getTeamProfile } from 'actions/team/getTeamProfile';
import { getDefaultMemberDataYear } from 'actions/user/getDefaultMemberDataYear';
import { TeamStateIds } from 'api/generated/enums';
import AddressInput from 'components/addressInput/AddressInput';
import { validateAddressInputs } from 'components/addressInput/addressInputActions';
import { IAddressInputs } from 'components/addressInput/addressInputState';
import Form from 'components/Form';
import FormButton from 'components/FormButton';
import PageHeader from 'components/PageHeader';
import TextField from 'components/TextField';
import HTTP_STATUS from 'constants/responseStatuses';
import { teamStateIds } from 'constants/teamStateIds';
import trim from 'lodash/trim';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { getTeamProps, ITeamProps } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { formatErrors, IFormErrors, validate } from 'utilities/forms';
import { object, string, ValidationError } from 'yup';

export type ITeamFormHandleSubmit = (
    teamId: string,
    name: string,
    isCustomer: boolean | undefined,
    isPartner: boolean,
    isAdvisor: boolean | undefined,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    county: string,
    countyFips: string,
    email: string,
    teamStateId: TeamStateIds | undefined
) => Promise<void>;

const MAX_EMAIL_LENGTH = 256;
const MAX_TEAM_NAME_LENGTH = 100;
const schema = object({
    email: string()
        .trim()
        .email()
        .max(MAX_EMAIL_LENGTH)
        .label('Email'),
    name: string()
        .trim()
        .required()
        .max(MAX_TEAM_NAME_LENGTH)
        .label('Team Name'),
});

type TeamFormBaseProps = RouteComponentProps & {
    apiError: boolean;
    apiErrorMessage: string;
    apiErrorStatusCode: number;
    handleSubmit: ITeamFormHandleSubmit;
};

type ITeamFormProps = DispatchProps & StateProps & TeamFormBaseProps;

type ITeamFormState = Partial<ITeamProps> & {
    email: string;
    errors: IFormErrors<typeof schema>;
    isPartner: boolean;
    name: string;
    shouldPopulateForm: boolean;
};

class TeamForm extends React.PureComponent<ITeamFormProps> {
    override state: ITeamFormState = {
        email: '',
        errors: null,
        isAdvisor: false,
        isCustomer: true,
        isPartner: false,
        name: '',
        shouldPopulateForm: true,
        teamId: '',
        teamStateId: teamStateIds.Prospect,
    };

    static getDerivedStateFromProps(props: ITeamFormProps, state: ITeamFormState) {
        if (props.teamId !== state.teamId) {
            state.teamId = props.teamId;
            props.getTeamProfile(props.teamId, false);
        }

        if (props.apiError && props.apiErrorStatusCode === HTTP_STATUS.CONFLICT) {
            if (props.apiErrorMessage.toLowerCase() === 'adminemail') {
                state.errors = {
                    ...state.errors,
                    email: ['Email address already in use', ...(state.errors?.email ?? [])],
                };
            }

            if (props.apiErrorMessage.toLowerCase() === 'name') {
                state.errors = {
                    ...state.errors,
                    name: ['Team Name already in use.', ...(state.errors?.name ?? [])],
                };
            }
        }
        return state;
    }

    override componentDidMount() {
        this.props.getStates();
        this.props.getDefaultMemberDataYear();
    }

    override componentWillUnmount() {
        this.props.clearCounties();
    }

    onChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        switch (name) {
            case 'email':
                value = trim(value);
                this.clearStateErrors();
                break;
            case 'name':
                this.clearStateErrors();
                break;
        }
        this.setState({ [name]: value });
    };

    clearStateErrors = () => {
        if (this.props.apiError) {
            this.props.clearTeamApiErrors();
            this.setState({
                errors: null,
            });
        }
    };

    handleSubmit: React.FormEventHandler = async () => {
        try {
            const state = this.state;
            const addressInputs = this.props.addressInputs;
            const isValid = await this.props.validateAddressInputs(addressInputs);
            await validate(schema, { email: state.email, name: state.name });
            this.setState({ errors: null });
            if (isValid) {
                await this.props.handleSubmit(
                    this.props.teamId,
                    state.name,
                    state.isCustomer,
                    state.isPartner,
                    state.isAdvisor,
                    addressInputs.addressLine1,
                    addressInputs.addressLine2,
                    addressInputs.city,
                    addressInputs.state,
                    addressInputs.zip,
                    addressInputs.county,
                    addressInputs.countyFips,
                    state.email,
                    state.teamStateId
                );
            }
        } catch (_errors) {
            this.setState({ errors: formatErrors(_errors as ValidationError) });
        }
    };

    override render() {
        const { defaultMemberDataYear, showActivity } = this.props;
        const { email, errors, name } = this.state;
        return (
            <Card>
                <CardContent className="p-4">
                    <PageHeader variant="h4">Add New Team</PageHeader>
                    <hr />
                    <Form isLoading={showActivity} onSubmit={this.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    autoFocus
                                    data-cy="team-name"
                                    errors={errors?.name}
                                    label="Team Name"
                                    name="name"
                                    onChange={this.onChange}
                                    placeholder="Enter a team name"
                                    value={name}
                                />
                            </Grid>
                            <Grid item md={6} sx={{ display: { md: 'block', xs: 'none' } }} />
                            <Grid item xs={12}>
                                
                                    <AddressInput year={defaultMemberDataYear} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    data-cy="team-admin-email"
                                    errors={errors?.email}
                                    isOptional
                                    label="Team Administrator Email"
                                    name="email"
                                    onChange={this.onChange}
                                    placeholder="Enter an email address"
                                    value={email}
                                />
                            </Grid>
                        </Grid>
                        <FormButton
                            className="mt-2"
                            isLoading={showActivity}
                            label="Add New Team"
                        />
                    </Form>
                </CardContent>
            </Card>
        );
    }
}

const mapStateToProps = (state: AppStore, { match }: TeamFormBaseProps) => ({
    ...getTeamProps(state, match.params),
    addressInputs: state.addressInputState.addressInputs,
    counties: state.counties,
    defaultMemberDataYear: state.defaultMemberDataYear,
    pageHeader: '',
    showActivity: hasApiActivity(state, ADD_TEAM_ACTION, EDIT_TEAM_ACTION),
    states: state.states,
});

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    clearCounties: async () => dispatch(clearCounties()),
    clearCountyError: async () => dispatch(clearGetCountiesError()),
    clearTeamApiErrors: async () => dispatch(clearTeamApiErrors()),
    getDefaultMemberDataYear: async () => dispatch(getDefaultMemberDataYear()),
    getStates: async () => dispatch(getStates()),
    getTeamProfile: async (teamId: string, isCurrent: boolean) =>
        dispatch(getTeamProfile(teamId, isCurrent)),
    validateAddressInputs: async (addressInputs: IAddressInputs) =>
        dispatch(validateAddressInputs(addressInputs)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamForm)));
