import axios from 'axios';
import {
    SET_CURRENT_REPORT_GLOBAL_ID,
    SET_REIMBURSEMENT_REPORT_SELECTED_YEAR,
} from 'pages/reimbursementReports/reimbursementReportActions';
import basicReducer from 'reducers/basicReducer';

export const reimbursementReportState = basicReducer(
    {
        cancelTokenSource: axios.CancelToken.source(),
        currentReportGlobalId: '',
        reimbursementReportSelectedYear: '',
    },
    SET_CURRENT_REPORT_GLOBAL_ID,
    SET_REIMBURSEMENT_REPORT_SELECTED_YEAR
);
