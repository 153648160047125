import Button from '@mui/material/Button';
import CompletedTaskIndicator from 'components/CompletedTaskIndicator';
import Title from 'components/Title';
import React from 'react';
import { hot } from 'react-hot-loader';

export type ITaskListItemProps = {
    'data-cy'?: string;
    action?: (taskId: number | undefined) => void;
    buttonIcon?:React.ReactNode;
    buttonText?: string;
    complete?: boolean;
    description?: string;
    enabled?: boolean;
    hideTopSeparator?: boolean;
    markComplete?: (taskId: number | undefined, taskType: string | undefined) => void;
    markCompleteOnAction?: boolean;
    noPadding?: boolean;
    showButton?: boolean;
    showButtonWhenComplete?: boolean;
    taskId?: number;
    taskType?: string;
    title: string;
};

class TaskListItem extends React.PureComponent<ITaskListItemProps> {
    onActionExecuted = () => {
        const {
            action,
            complete,
            enabled,
            markComplete,
            markCompleteOnAction,
            showButton,
            showButtonWhenComplete,
            taskId,
            taskType,
        } = this.props;
        if (enabled && showButton && (!complete || showButtonWhenComplete)) {
            if (markCompleteOnAction) {
                markComplete?.(taskId, taskType);
            }
            if (action) {
                action(taskId);
            }
        }
    };

    override render() {
        const {
            buttonIcon,
            buttonText,
            children,
            complete,
            description,
            enabled,
            hideTopSeparator,
            noPadding = false,
            showButton,
            showButtonWhenComplete,
            title,
        } = this.props;

        return (
            <React.Fragment>
                {!hideTopSeparator && <hr />}
                <div className={`row align-items-center ${!noPadding && 'my-3'}`} data-cy={this.props['data-cy']}>
                    <div className="col-auto">
                        <div className="row align-items-center text-center">
                            <CompletedTaskIndicator
                                completed={complete}
                                enabled={enabled}
                                onClick={() => this.onActionExecuted()}
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <Title className={!enabled && !complete ? 'my-0 text-muted' : 'my-0'}>
                                {title}
                            </Title>
                            {description && <p className="my-0 text-muted">{description}</p>}
                        </div>
                    </div>
                    {showButton && (!complete || showButtonWhenComplete) && (
                        <div className="col-auto">
                            <Button
                                data-cy="task-button"
                                disabled={!enabled}
                                onClick={() => this.onActionExecuted()}
                                size="small"
                                variant="outlined"
                            >
                                {buttonIcon}
                                {buttonText}
                            </Button>
                        </div>
                    )}
                </div>
                {children}
            </React.Fragment>
        );
    }
}

export default hot(module)(TaskListItem);
