import { Create } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { clearApiActivity } from 'actions/clear';
import {
    GET_HOUSEHOLD_MEMBERS_ACTION,
    getHouseholdMembers,
} from 'actions/householdMember/getHouseholdMembers';
import { GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getOffExchangeMarketplacePlanRatesForUser';
import { GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getOnExchangeMarketplacePlanRatesForUser';
import { GET_MEDISHARE_PLANS_FOR_USER_ACTION } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import {
    GET_PATHWAY_DATA_FOR_USER_ACTION,
    getPathwayDataForUser,
} from 'actions/pathwayBlueprint/getPathwayDataForUser';
import {
    GET_SELECTED_PLANS_FOR_REVIEW_ACTION,
    getSelectedPlansForReview,
} from 'actions/selectedPlan/getSelectedPlansForReview';
import { getCurrentUserBudget } from 'actions/user/getCurrentUserBudget';
import {
    GET_HOUSEHOLD_ELIGIBILITY_ACTION,
    getHouseholdEligibility,
} from 'actions/user/getHouseholdEligibility';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import {
    EnrollmentStatuses,
    HouseholdMemberTypes,
    ShoppingConfigurationIds,
} from 'api/generated/enums';
import Button from 'components/Button';
import ShopHeaderContainer from 'components/ShopHeaderContainer';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import ScheduleAdvisementModalLink from 'components/helpComponents/ScheduleAdvisementModalLink';
import { push } from 'connected-react-router';
import useModalState from 'hooks/useModalState';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import ChangeHouseholdCoverageModal from 'pages/shop/ChangeHouseholdCoverageModal';
import { BenefitsMarket, QUERY_PARAM } from 'pages/shop/ShopFilters';
import {
    ADVISEMENT_SCHEDULED_MESSAGE,
    getDefaultBenefitsMarkets,
    isBenefitsMarketQueryValid,
    ShoppingContext,
} from 'pages/shop/shopPageUtilities';
import { clearShoppingState, updateShoppingMembersAndGetRates } from 'pages/shop/shoppingActions';
import {
    handleToolTip,
    IShoppingMemberSelection,
} from 'pages/shop/shoppingMemberSelectionUtilities';
import useShoppingConfiguration from 'pages/shop/useShoppingConfiguration';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { SELECTIONS_PATH, TEAMS_SELECTIONS_PATH } from 'routers/routes';
import { hasApiActivity, hasCompletedRequest, hasCompletedRequests } from 'selectors/activity';
import { hasAnyDisqualifyingEligibilities } from 'utilities/householdEligibility';
import { arrayHasValue, hasFlag, hasValue } from 'utilities/index';

const ShopHeader = ({
    flowTaskId,
    isIchraFlow = false,
    isTeamManagementPage,
    showPreviousButton,
}: {
    flowTaskId: string | undefined;
    isIchraFlow: boolean | undefined;
    isTeamManagementPage: boolean | undefined;
    showPreviousButton: boolean | undefined;
}) => {
    const dispatch = useThunkDispatch();
    const {
        ancillaryBenefits,
        hasCompletedInitialRequests,
        hasCompletedMedishareRequest,
        hasCompletedOffExchangeMarketplaceRequests,
        hasCompletedOnExchangeMarketplaceRequests,
        hasInitialRequestActivity,
        householdEligibility,
        householdMembers,
        members,
        numberOfPlansForReview,
        pathwayDataForUser,
    } = useSelector((state: AppStore) => ({
        ancillaryBenefits: state.ancillaryBenefits,
        hasCompletedInitialRequests: hasCompletedRequests(
            state,
            GET_USER_PROFILE_ACTION,
            GET_HOUSEHOLD_MEMBERS_ACTION,
            GET_PATHWAY_DATA_FOR_USER_ACTION,
            GET_HOUSEHOLD_ELIGIBILITY_ACTION,
            GET_SELECTED_PLANS_FOR_REVIEW_ACTION
        ),
        hasCompletedMedishareRequest: hasCompletedRequest(
            state,
            GET_MEDISHARE_PLANS_FOR_USER_ACTION
        ),
        hasCompletedOffExchangeMarketplaceRequests: hasCompletedRequest(
            state,
            GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION
        ),
        hasCompletedOnExchangeMarketplaceRequests: hasCompletedRequest(
            state,
            GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION
        ),
        hasInitialRequestActivity: hasApiActivity(
            state,
            GET_USER_PROFILE_ACTION,
            GET_HOUSEHOLD_MEMBERS_ACTION,
            GET_PATHWAY_DATA_FOR_USER_ACTION,
            GET_HOUSEHOLD_ELIGIBILITY_ACTION,
            GET_SELECTED_PLANS_FOR_REVIEW_ACTION
        ),
        householdEligibility: state.householdEligibility,
        householdMembers: state.householdMembers,
        members: state.shoppingState.members,
        numberOfPlansForReview: state.selectedPlansForReview.selectedPlans?.length,
        pathwayDataForUser: state.pathwayDataForUser,
    }));
    const {
        activeWageUp,
        address,
        isCurrent,
        isInIchraPathway,
        memberVerifiedInfo,
        user,
        userId,
        yearlyUserInfo,
    } = useUserProps();
    const { teamId } = useTeamProps();
    const [query, setQuery] = useQuery();
    const shoppingConfigurationId = useShoppingConfiguration();
    const {
        benefitsMarkets,
        hasCompletedInitialLoading,
        setBenefitsMarkets,
        setExcludedMembers,
        setHasFetchedPlans,
        setHasCompletedInitialLoading,
        setShoppingMemberSelections,
        shoppingMemberSelections,
    } = useContext(ShoppingContext);
    const isOffExchange = hasValue(benefitsMarkets)
        ? benefitsMarkets?.contains(BenefitsMarket.OffExchange)
        : undefined;
    const isUnclaimedAllowed = isInIchraPathway === true || isOffExchange === true;
    const hasCustomWagePlus = activeWageUp?.isCustom;
    const year = user?.activeDate?.getYear() ?? 0;
    const redirectToDashboard = !arrayHasValue(ancillaryBenefits);
    const [orderedShoppingMemberSelections, setOrderedShoppingMemberSelections] = useState<
        IShoppingMemberSelection[] | undefined
    >(shoppingMemberSelections);

    const {
        closeModal: closeChangeHouseholdCoverageModal,
        isVisible: isChangeHouseholdCoverageModalVisible,
        openModal: openChangeHouseholdCoverageModal,
    } = useModalState();

    const handleChangeHouseholdCoverageModalClose = () => {
        closeChangeHouseholdCoverageModal();
        dispatch(getUserProfile(userId, isCurrent));
        dispatch(getSelectedPlansForReview(userId));
    };

    const setMemberCheckedState = useCallback(() => {
        const newShoppingMemberSelections: IShoppingMemberSelection[] = [];
        const membersCheckedState = { ...members };

        const primaryEligibilities = householdEligibility.find((he) => he.memberId === userId);
        const isPrimaryDisabled = hasAnyDisqualifyingEligibilities(
            primaryEligibilities?.eligibilityStatus,
            primaryEligibilities?.otherCoverageEligibility,
            isInIchraPathway
        );
        const primaryShoppingMemberSelection: IShoppingMemberSelection = {
            age: user?.dateOfBirth?.getAge(user?.activeDate),
            entityId: userId,
            firstName: user?.firstName as string,
            gender: memberVerifiedInfo?.gender,
            isDisabled: (isPrimaryDisabled || isInIchraPathway) as boolean,
            isPrimary: true,
            isSelected: (!isPrimaryDisabled &&
                yearlyUserInfo?.needsMajorMedicalCoverage) as boolean,
            memberEligibility: primaryEligibilities,
            needsCoverage: !!yearlyUserInfo?.needsMajorMedicalCoverage,
            zipCode: address?.zip,
        };
        membersCheckedState[userId] = primaryShoppingMemberSelection.isSelected;
        newShoppingMemberSelections.push(primaryShoppingMemberSelection);

        householdMembers.forEach((hhm) => {
            const eligibilities = householdEligibility.find(
                (he) => he.memberId === hhm.householdMemberId
            );
            const isDisabled =
                hasAnyDisqualifyingEligibilities(
                    eligibilities?.eligibilityStatus,
                    eligibilities?.otherCoverageEligibility,
                    isInIchraPathway
                ) ||
                (hhm.householdMemberTypeId === HouseholdMemberTypes.Unclaimed &&
                    !isUnclaimedAllowed);
            const isSelected = !isDisabled && hhm.needsCoverage;
            const shoppingMemberSelection: IShoppingMemberSelection = {
                isDisabled,
                isSelected,
                age: hhm.dateOfBirth.getAge(user?.activeDate),
                entityId: hhm.householdMemberId,
                firstName: hhm.firstName as string,
                gender: hhm.gender,
                householdMemberType: hhm.householdMemberTypeId,
                isPrimary: false,
                memberEligibility: eligibilities,
                needsCoverage: hhm.needsCoverage,
                zipCode: hhm.address?.zip,
            };
            membersCheckedState[hhm.householdMemberId] = shoppingMemberSelection.isSelected;
            newShoppingMemberSelections.push(shoppingMemberSelection);
        });
        const excludedMembers = newShoppingMemberSelections
            .filter((sms) => !sms.isSelected)
            .map((sms) => sms.entityId);
        setExcludedMembers?.(excludedMembers);
        setShoppingMemberSelections?.(newShoppingMemberSelections);

        if (!isChangeHouseholdCoverageModalVisible) {
            dispatch(
                updateShoppingMembersAndGetRates(
                    userId,
                    membersCheckedState,
                    year,
                    shoppingConfigurationId
                )
            );
        }
    }, [
        address?.zip,
        dispatch,
        householdEligibility,
        householdMembers,
        isChangeHouseholdCoverageModalVisible,
        isInIchraPathway,
        isUnclaimedAllowed,
        memberVerifiedInfo?.gender,
        members,
        setExcludedMembers,
        setShoppingMemberSelections,
        shoppingConfigurationId,
        user?.activeDate,
        user?.dateOfBirth,
        user?.firstName,
        userId,
        year,
        yearlyUserInfo?.needsMajorMedicalCoverage,
    ]);

    const handleButtonClick = () => {
        dispatch(
            push(
                isTeamManagementPage
                    ? generatePath(TEAMS_SELECTIONS_PATH, { teamId, userId })
                    : SELECTIONS_PATH
            )
        );
    };

    useEffect(() => {
        if (
            hasValue(pathwayDataForUser) &&
            hasCompletedInitialRequests &&
            hasValue(isOffExchange) &&
            !isChangeHouseholdCoverageModalVisible
        ) {
            setMemberCheckedState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasCompletedInitialRequests, isOffExchange, pathwayDataForUser]);

    useEffect(() => {
        const smsIsSelected: IShoppingMemberSelection[] =
            shoppingMemberSelections?.filter((sms) => sms.isSelected) ?? [];
        const smsIsNotSelected: IShoppingMemberSelection[] =
            shoppingMemberSelections?.filter((sms) => !sms.isSelected) ?? [];

        setOrderedShoppingMemberSelections([...smsIsSelected, ...smsIsNotSelected]);
    }, [shoppingMemberSelections]);

    useEffect(() => {
        if (hasValue(pathwayDataForUser) && hasCompletedInitialRequests) {
            let isValid = true;
            if (query.has(QUERY_PARAM.MARKET)) {
                const markets = query.getAll(QUERY_PARAM.MARKET) as BenefitsMarket[];
                isValid = isBenefitsMarketQueryValid(markets, shoppingConfigurationId);
            }
            if (!isValid || !query.has(QUERY_PARAM.MARKET)) {
                query.delete(QUERY_PARAM.MARKET);
                const defaultMarkets = getDefaultBenefitsMarkets(
                    shoppingConfigurationId,
                    pathwayDataForUser?.pathwayType
                );
                defaultMarkets.forEach((dm) => query.append(QUERY_PARAM.MARKET, dm));
                setQuery(query);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasCompletedInitialRequests, pathwayDataForUser, query, shoppingConfigurationId]);

    // get the benefits market and fetch the appropriate plans
    useEffect(() => {
        const markets = query.getAll(QUERY_PARAM.MARKET) as BenefitsMarket[];
        if (
            hasValue(pathwayDataForUser) &&
            hasCompletedInitialRequests &&
            isBenefitsMarketQueryValid(markets, shoppingConfigurationId)
        ) {
            let hasPlans = arrayHasValue(benefitsMarkets);
            const queryBenefitsMarkets = query.getAll(QUERY_PARAM.MARKET) as BenefitsMarket[];
            if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.MediShare)) {
                hasPlans = hasPlans && hasCompletedMedishareRequest;
            }
            if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.OffExchange)) {
                hasPlans = hasPlans && hasCompletedOffExchangeMarketplaceRequests;
            }
            if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.Marketplace)) {
                hasPlans = hasPlans && hasCompletedOnExchangeMarketplaceRequests;
            }
            setHasFetchedPlans?.(hasPlans);
            setBenefitsMarkets?.(queryBenefitsMarkets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        hasCompletedInitialRequests,
        hasCompletedOffExchangeMarketplaceRequests,
        hasCompletedOnExchangeMarketplaceRequests,
        hasCompletedMedishareRequest,
        pathwayDataForUser,
        query,
        shoppingConfigurationId,
    ]);

    // send the initial requests needed for the shopping page
    useEffect(() => {
        const dispatchShoppingRequests = async () => {
            await dispatch(getUserProfile(userId, isCurrent));
            await dispatch(getHouseholdMembers(userId));
            await dispatch(getPathwayDataForUser(userId, year));
            await dispatch(getHouseholdEligibility(userId, year));
            await dispatch(getSelectedPlansForReview(userId));
            await dispatch(getCurrentUserBudget(teamId, userId));
        };
        if (
            !hasCompletedInitialRequests &&
            !hasInitialRequestActivity &&
            !hasCompletedInitialLoading
        ) {
            setHasCompletedInitialLoading?.(true);
            dispatchShoppingRequests();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        hasCompletedInitialLoading,
        hasCompletedInitialRequests,
        hasInitialRequestActivity,
        isCurrent,
        teamId,
        userId,
        year,
    ]);

    useEffect(
        () => () => {
            dispatch(clearShoppingState());
            dispatch(clearApiActivity());
        },
        [dispatch]
    );

    return (
        <React.Fragment>
            <ShopHeaderContainer
                leftSide={
                    <Grid container>
                        <Grid item>
                            <Typography fontSize={12}>
                                You&apos;re currently shopping for:
                            </Typography>
                            <Stack
                                alignItems="center"
                                direction="row"
                                flexWrap="wrap"
                                gap={1}
                                marginBottom={0}
                                marginTop={0.75}
                            >
                                {orderedShoppingMemberSelections?.map((sms, index) => (
                                    <React.Fragment key={sms.entityId}>
                                        <Tooltip
                                            data-cy={`household-member-${index}`}
                                            key={sms.firstName}
                                            title={handleToolTip(
                                                false,
                                                sms,
                                                isInIchraPathway,
                                                isUnclaimedAllowed,
                                                undefined,
                                                index
                                            )}
                                        >
                                            <Chip
                                                color={sms.isSelected ? 'secondary' : 'default'}
                                                label={sms.firstName}
                                                style={{
                                                    textDecoration: !sms.isSelected
                                                        ? 'line-through'
                                                        : '',
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                ))}
                                {arrayHasValue(orderedShoppingMemberSelections) &&
                                    !hasCustomWagePlus && (
                                        <Button
                                            color="primary"
                                            data-cy="open-change-household-modal-button"
                                            noPadding
                                            onClick={openChangeHouseholdCoverageModal}
                                            size="medium"
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                height: '32px',
                                                justifyContent: 'center',
                                                padding: '0 0.5rem',
                                            }}
                                            variant="text"
                                        >
                                            <Create /> Edit coverage
                                        </Button>
                                    )}
                            </Stack>
                        </Grid>
                        {hasCustomWagePlus && (
                            <Grid item marginTop={1} xs={12}>
                                <Typography fontSize={12} variant="body2">
                                    To change who you are shopping for, reach out to{' '}
                                    <a href="mailto:care@remodelhealth.com">
                                        care@remodelhealth.com
                                    </a>
                                    .
                                </Typography>
                            </Grid>
                        )}
                        {!isTeamManagementPage && (
                            <Grid item mb={{ lg: 0, xs: 2 }} mt={2} xs={12}>
                                {yearlyUserInfo?.enrollmentStatus?.value ===
                                EnrollmentStatuses.AdvisementScheduled ? (
                                    <span>{ADVISEMENT_SCHEDULED_MESSAGE}</span>
                                ) : (
                                    <span>
                                        Need help making a decision? You can{' '}
                                        <ScheduleAdvisementModalLink isIchraFlow={isIchraFlow} />.
                                    </span>
                                )}
                            </Grid>
                        )}
                    </Grid>
                }
                rightSide={
                    isIchraFlow ? (
                        <IchraFlowButtons
                            currentTaskId={flowTaskId as string}
                            displayAsRow
                            hideContinueButton
                            redirectToDashboard={redirectToDashboard}
                            showPreviousButton={showPreviousButton}
                            showWaiveButton
                        />
                    ) : (
                        <Grid item>
                            <Button
                                color="primary"
                                data-cy="review-selections"
                                disabled={!numberOfPlansForReview}
                                onClick={handleButtonClick}
                                variant="contained"
                            >
                                Review {isCurrent ? 'Your ' : ''}Selections
                                {hasValue(numberOfPlansForReview) && ` (${numberOfPlansForReview})`}
                            </Button>
                        </Grid>
                    )
                }
            />
            {isChangeHouseholdCoverageModalVisible && (
                <ChangeHouseholdCoverageModal
                    isIchraFlow={isIchraFlow}
                    isUnclaimedAllowed={isUnclaimedAllowed}
                    onClose={handleChangeHouseholdCoverageModalClose}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(ShopHeader);
