import { AppStoreThunkDispatch } from 'actions/commonAction';

export const SET_ICHRA_FLOW_HAS_ERRORS = 'SET_ICHRA_FLOW_HAS_ERRORS';
export const setIchraFlowHasErrors = (hasErrors: boolean) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            hasErrors,
        },
        type: SET_ICHRA_FLOW_HAS_ERRORS,
    });
};
