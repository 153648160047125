import { Card, CardContent } from '@mui/material';
import { patchTeamPlan } from 'actions/teamPlan/patchTeamPlan';
import { ITeamPlan } from 'api/generated/models';
import { EditTeam } from 'api/generated/permissions';
import Button from 'components/Button';
import { IValueType } from 'components/EditableAttribute';
import EditableCurrencyAttribute from 'components/EditableCurrencyAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import PageHeader from 'components/PageHeader';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type IGroupPlanProfileCardProps = ITeamPlan & {
    onRemove: () => void;
    showRemoveButton: boolean;
};

const GroupPlanProfileCard = ({
    carrier: initialCarrier,
    deductible: initialDeductible,
    familyDeductible: initialFamilyDeductible,
    familyOutOfPocketMax: initialFamilyOutOfPocketMax,
    genericDrugsCopay: initialGenericDrugsCopay,
    onRemove,
    outOfPocketMax: initialOutOfPocketMax,
    planName: initialPlanName,
    primaryVisitCopay: initialPrimaryVisitCopay,
    specialistVisitCopay: initialSpecialistVisitCopay,
    teamPlanId,
}: IGroupPlanProfileCardProps) => {
    const dispatch = useThunkDispatch();
    const { isSameTeam } = useTeamProps();
    const { canEditTeam } = useSelector((state: AppStore) => ({
        canEditTeam: isSameTeam || state.login.up.includes(EditTeam),
    }));

    const [carrier, setCarrier] = useState(initialCarrier);
    const [deductible, setDeductible] = useState((initialDeductible ?? 0).toString());
    const [familyDeductible, setFamilyDeductible] = useState(
        (initialFamilyDeductible ?? 0).toString()
    );
    const [familyOutOfPocketMax, setFamilyOutOfPocketMax] = useState(
        (initialFamilyOutOfPocketMax ?? 0).toString()
    );
    const [genericDrugsCopay, setGenericDrugsCopay] = useState(
        (initialGenericDrugsCopay ?? 0).toString()
    );
    const [outOfPocketMax, setOutOfPocketMax] = useState((initialOutOfPocketMax ?? 0).toString());
    const [planName, setPlanName] = useState(initialPlanName);
    const [primaryVisitCopay, setPrimaryVisitCopay] = useState(
        (initialPrimaryVisitCopay ?? 0).toString()
    );
    const [specialistVisitCopay, setSpecialistVisitCopay] = useState(
        (initialSpecialistVisitCopay ?? 0).toString()
    );

    const saveGroupPlan = async (name: keyof ITeamPlan, value: IValueType) => {
        await dispatch(patchTeamPlan(teamPlanId, { [name]: value }));
    };

    const getCommonProps = <T,>(setValue: React.Dispatch<React.SetStateAction<T>>) => ({
        onChange: onChange(setValue),
        readonly: !canEditTeam,
        save: saveGroupPlan,
    });
    return (
        <Card>
            <CardContent>
                <div className="d-flex align-items-center justify-content-between">
                    <PageHeader p={0} variant="h4">
                        Group Plan
                    </PageHeader>

                    {canEditTeam && (
                        <Button
                            ButtonClassName="p-0"
                            className="ml-1"
                            noPadding
                            onClick={onRemove}
                            variant="text"
                        >
                            Remove
                        </Button>
                    )}
                </div>
                <hr />

                <div className="row">
                    <div className="col">
                        <EditableTextAttribute
                            {...getCommonProps(setPlanName)}
                            label="Plan Name"
                            name="planName"
                            placeholder="Enter a plan name"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Plan Name')}
                            value={planName}
                        />

                        <EditableTextAttribute
                            {...getCommonProps(setCarrier)}
                            label="Carrier"
                            name="carrier"
                            placeholder="Enter a carrier"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Carrier')}
                            value={carrier}
                        />

                        <EditableCurrencyAttribute
                            {...getCommonProps(setDeductible)}
                            label="Deductible"
                            name="deductible"
                            placeholder="Enter a deductible"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Deductible')}
                            value={deductible}
                        />

                        <EditableCurrencyAttribute
                            {...getCommonProps(setFamilyDeductible)}
                            label="Family Deductible"
                            name="familyDeductible"
                            placeholder="Enter a family deductible"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Family Deductible')}
                            value={familyDeductible}
                        />
                    </div>

                    <div className="col">
                        <EditableCurrencyAttribute
                            {...getCommonProps(setOutOfPocketMax)}
                            label="Max Out of Pocket"
                            name="outOfPocketMax"
                            placeholder="Enter a max out of pocket"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Max Out of Pocket')}
                            value={outOfPocketMax}
                        />

                        <EditableCurrencyAttribute
                            {...getCommonProps(setFamilyOutOfPocketMax)}
                            label="Family Max Out of Pocket"
                            name="familyOutOfPocketMax"
                            placeholder="Enter a family max out of pocket"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Family Max Out of Pocket')}
                            value={familyOutOfPocketMax}
                        />

                        <EditableCurrencyAttribute
                            {...getCommonProps(setPrimaryVisitCopay)}
                            label="Primary Visit Copay"
                            name="primaryVisitCopay"
                            placeholder="Enter a copay amount"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Primary Visit Copay')}
                            value={primaryVisitCopay}
                        />

                        <EditableCurrencyAttribute
                            {...getCommonProps(setSpecialistVisitCopay)}
                            label="Specialist Visit Copay"
                            name="specialistVisitCopay"
                            placeholder="Enter a copay amount"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Specialist Visit Copay')}
                            value={specialistVisitCopay}
                        />

                        <EditableCurrencyAttribute
                            {...getCommonProps(setGenericDrugsCopay)}
                            label="Generic Drugs Copay"
                            name="genericDrugsCopay"
                            placeholder="Enter a copay amount"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Generic Drugs Copay')}
                            value={genericDrugsCopay}
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default hot(module)(GroupPlanProfileCard);
