import { Chip, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DELETE_PATHWAY_ACTION, deletePathway } from 'actions/pathwayBlueprint/deletePathway';
import { GET_USER_PROFILES_ACTION } from 'actions/user/getUserProfiles';
import { PathwayInputTypes, PathwayTypes } from 'api/generated/enums';
import { Address, IPathway, IUserProfile } from 'api/generated/models';
import { IActionButtonItems } from 'components/ActionButtons';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import ReactTable from 'components/reactTable/ReactTable';
import { PATHWAY_TYPE_ITEMS } from 'constants/pathways';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { PathwayFilterContext } from 'pages/pathwayBlueprint/PathwayBlueprintPage';
import { IPathwayRowData } from 'pages/pathwayBlueprint/PathwayTableCells';
import {
    NAME_COLUMN_ID,
    NAME_FILTER_ID,
    STATE_COLUMN_ID,
    STATE_FILTER_ID,
    commonColumns,
    getActiveReimbursementColumn,
    getActiveWageUpColumn,
    getAssignColumn,
    getEmployeeCostColumn,
    getPlanCostColumn,
    getProjectedReimbursementColumn,
    getProjectedWageUpColumn,
    getTagsColumn,
    getWageUpActionButtonsColumn,
} from 'pages/pathwayBlueprint/PathwayTableColumns';
import { setPathwayModalProps } from 'pages/pathwayBlueprint/pathwayModalActions';
import React, { useContext, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Column, Row, SortingRule } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue, isTrue } from 'utilities';
import {
    getPathwayInput,
    isIchraPathwayType,
    showActiveContributionByTeamState,
} from 'utilities/pathways';

export const hideTables = (
    tableData: IPathwayRowData[],
    search: string | undefined,
    filteredState: string | undefined,
    userProfiles: IUserProfile[]
) => {
    let shouldHide = false;
    if (hasValue(search)) {
        shouldHide = !arrayHasValue(
            tableData.filter((td) =>
                td.user?.displayName?.toLowerCase().includes(search?.toLowerCase())
            )
        );
    }
    if (!shouldHide && hasValue(filteredState)) {
        shouldHide = !tableData.some((td) =>
            userProfiles.some(
                (up) => up.user?.userId === td.userId && up.address?.state === filteredState
            )
        );
    }

    return shouldHide;
};

export type ITableFilters = {
    searchValue?: string;
    stateFilterValue?: string;
};

export const getFilters = (filters: ITableFilters) => [
    { id: NAME_COLUMN_ID, value: filters.searchValue },
    { id: STATE_COLUMN_ID, value: filters.stateFilterValue },
];

export const getFilterTypes = (userProfiles: IUserProfile[]) => ({
    [NAME_FILTER_ID]: (rows: Row<IPathwayRowData>[], _: unknown, filterValue: string) =>
        rows.filter(
            ({ original: { user } }) =>
                user?.firstName?.toLowerCase().includes(filterValue.toLowerCase()) ||
                user?.lastName?.toLowerCase().includes(filterValue.toLowerCase())
        ),
    [STATE_FILTER_ID]: (rows: Row<IPathwayRowData>[], _: unknown, filterValue: string) =>
        rows.filter(({ original: { user } }) =>
            getAddressForUser(user?.userId as string, userProfiles)?.state?.includes(filterValue)
        ),
});

export const getAddressForUser = (userId: string, userProfiles: IUserProfile[]) => {
    const [userProfile] = userProfiles.filter((up) => up.user?.userId === userId);
    const address: Partial<Address> = userProfile?.address ?? { state: '-' };
    return address;
};

export const pathwayInitialSortBy: SortingRule<IPathwayRowData>[] = [
    { desc: false, id: NAME_COLUMN_ID },
];
const PathwayTable = ({ pathway }: { pathway: IPathway }) => {
    const dispatch = useThunkDispatch();
    const { isLoading, isLoadingDeletePathway, pathwayBlueprint, userProfiles } = useSelector(
        (state: AppStore) => ({
            isLoading: hasApiActivity(state, GET_USER_PROFILES_ACTION),
            isLoadingDeletePathway: hasApiActivity(state, DELETE_PATHWAY_ACTION),
            pathwayBlueprint: state.pathwayBlueprint,
            userProfiles: state.userProfiles,
        })
    );
    const { teamStateId } = useTeamProps();
    const { filteredState, search } = useContext(PathwayFilterContext);
    const [selectedPathways, setSelectedPathways] = useState<string[]>([]);
    const showUseRestrategizedRenewalFlow = isTrue(
        getPathwayInput(pathway, PathwayInputTypes.UseRestrategizedRenewalFlow, 'false')
    );
    const pathwayData = useMemo(() => {
        const pathwayUsers =
            pathwayBlueprint.pathwayUsers?.filter((pu) => pu.pathwayId === pathway.id) ?? [];
        return pathwayUsers.reduce<IPathwayRowData[]>((rows, pu) => {
            const profile = userProfiles.find((up) => up.user?.userId === pu.userId);
            if (profile) {
                rows.push({
                    ...pu,
                    activeWageUp: profile?.user?.activeWageUps?.find(
                        (activeWageUp) => activeWageUp.year === pathwayBlueprint.year
                    ),
                    disabled: pathwayBlueprint?.isCalculating,
                    hasMemberVerifiedInfo: profile?.memberVerifiedInfo ? true : false,
                    majorMedicalCoverageLevel: profile?.majorMedicalCoverageLevel,
                    memberVerifiedInfoId: profile?.memberVerifiedInfo?.memberVerifiedInfoId ?? '',
                    pathwayTypeId: pathway?.pathwayTypeId,
                    pathwayYear: pathwayBlueprint.year,
                    teamId: profile?.user?.teamId,
                    user: profile?.user,
                    userId: profile?.user?.userId as string,
                });
            }
            return rows;
        }, []);
    }, [
        pathway.id,
        pathway.pathwayTypeId,
        pathwayBlueprint?.isCalculating,
        pathwayBlueprint.pathwayUsers,
        pathwayBlueprint.year,
        userProfiles,
    ]);

    const pathwayColumns = useMemo(() => {
        const isIchra = isIchraPathwayType(pathway.pathwayTypeId);
        const isFlatPathway = [PathwayTypes.IchraFlat, PathwayTypes.Flat].includes(
            pathway.pathwayTypeId
        );
        const columns: Column<IPathwayRowData>[] = [
            ...commonColumns,
            {
                ...getTagsColumn((row) => row.tags),
                Footer: 'Totals',
            },
            getPlanCostColumn(
                (row) => (isIchra ? row.totalCosts : row.taxedUpTotalCosts),
                pathway.pathwayTypeId === PathwayTypes.IchraCustom,
                isFlatPathway
            ),
        ];
        if (isIchra) {
            columns.push(
                getProjectedReimbursementColumn(
                    (row) => row.customProjectedReimbursement ?? row.projectedReimbursement ?? 0
                )
            );
        } else {
            columns.push(
                getProjectedWageUpColumn(
                    (row) => row.customProjectedWageUp ?? row.projectedWageUp ?? 0
                )
            );
        }
        columns.push(getWageUpActionButtonsColumn());
        if (showActiveContributionByTeamState(teamStateId)) {
            if (isIchra) {
                columns.push(
                    getActiveReimbursementColumn((row) => row.activeWageUp?.reimbursement ?? 0)
                );
            } else {
                columns.push(getActiveWageUpColumn((row) => row.activeWageUp?.wageUp ?? 0));
            }
        }
        columns.push(
            getEmployeeCostColumn((row) => row.employeeCost ?? 0, isIchra),
            getAssignColumn(pathway.id, selectedPathways, setSelectedPathways)
        );
        return columns;
    }, [pathway.id, pathway.pathwayTypeId, selectedPathways, teamStateId]);

    const actionButtonItems = [
        {
            isVisible: true,
            onClick: () =>
                dispatch(setPathwayModalProps({ isModalVisible: true, selectedItem: pathway })),
            text: 'Edit',
        },
        {
            isConfirm: true,
            isLoading: isLoadingDeletePathway,
            isVisible: true,
            onClick: async () => dispatch(deletePathway(pathwayBlueprint.id, pathway.id)),
            text: 'Delete',
        },
    ] as IActionButtonItems;

    const pathwayTableFilters = getFilters({
        searchValue: search,
        stateFilterValue: filteredState,
    });

    const pathwayTableFilterTypes = getFilterTypes(userProfiles);

    const isTableHidden = useMemo(
        () => hideTables(pathwayData, search, filteredState, userProfiles),
        [filteredState, pathwayData, search, userProfiles]
    );

    if (isTableHidden) {
        return <React.Fragment />;
    }
    return (
        <Paper>
            <TableHeader
                disableDropdown={pathwayBlueprint?.isCalculating}
                dropdownWhenLengthGreaterThan={0}
                items={actionButtonItems}
                OverrideLeftSide={
                    <Col>
                        <Typography m={0} textAlign="left" variant="h5">
                            {pathway.name}
                            {showUseRestrategizedRenewalFlow && (
                                <Chip
                                    color="secondary"
                                    label="Restrategized"
                                    size="small"
                                    sx={{ ml: 1 }}
                                    variant="outlined"
                                />
                            )}
                        </Typography>
                        <div className="text-muted">
                            {
                                PATHWAY_TYPE_ITEMS.find((x) => x.value === pathway.pathwayTypeId)?.[
                                    'name'
                                ]
                            }
                        </div>
                    </Col>
                }
            />
            <Skeleton count={3} height="50px" isEnabled={isLoading} sx={{ mt: 2 }}>
                <ReactTable
                    columns={pathwayColumns}
                    data={pathwayData}
                    filters={pathwayTableFilters}
                    filterTypes={pathwayTableFilterTypes}
                    initialSortBy={pathwayInitialSortBy}
                    isRowSelectionEnabled
                    pageSize={50}
                    rowsWhenNoData={1}
                />
            </Skeleton>
        </Paper>
    );
};

export default hot(module)(PathwayTable);
