import React from 'react';
import { hot } from 'react-hot-loader';

const ImmigrationStatusExamples = () => (
    <ul>
        <li>Permanent Resident Card, &quot;Green Card&quot; (I-551)</li>
        <li>Reentry Permit (I-327)</li>
        <li>Refugee Travel Document (I-571)</li>
        <li>Employment Authorization Card (I-766)</li>
        <li>Machine Readable Immigrant Visa (with temporary I-551 language)</li>
        <li>Temporary I-551 Stamp (on Passport or I-94/I-94A)</li>
        <li>Foreign passport</li>
        <li>Arrival/Departure Record (I-94/I-94A)</li>
        <li>Arrival/Departure Record in foreign passport (I-94)</li>
        <li>Certificate of Eligibility for Nonimmigrant Student Status (I-20)</li>
        <li>Certificate of Eligibility for Exchange Visitor Status (DS-2019)</li>
        <li>Notice of Action (I-797)</li>
        <li>
            Document indicating a member of a federally recognized Indian tribe or American Indian
            born in Canada
        </li>
        <li>
            Certification from U.S. Department of Health and Human Services (HHS) Office of Refugee
            Resettlement (ORR)
        </li>
        <li>Document indicating withholding of removal (or withholding of deportation)</li>
        <li>Office of Refugee Resettlement (ORR) eligibility letter (if under 18)</li>
        <li>Resident of American Samoa Card</li>
    </ul>
);

export default hot(module)(ImmigrationStatusExamples);
