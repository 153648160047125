import BackupIcon from '@mui/icons-material/Backup';
import ChatIcon from '@mui/icons-material/Chat';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import {
    PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION,
    patchPayrollReportUserSnapshot,
} from 'actions/payroll/patchPayrollReportUserSnapshot';
import { LineFourteenCodes, LineSixteenCodes } from 'api/generated/enums';
import { EditPayrollReports } from 'api/generated/permissions';
import Button, { IButtonProps } from 'components/Button';
import ConditionalTooltip from 'components/ConditionalTooltip';
import IconTooltip from 'components/IconTooltip';
import TextAreaModal from 'components/TextAreaModal';
import Tooltip from 'components/Tooltip';
import UnderlinedContentWithTooltip from 'components/UnderlinedContentWithTooltip';
import { MONTHS } from 'constants/date';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import ApplyDeductionsModal from 'pages/payrollReports/ApplyDeductionsModal';
import PayrollReportAdjustmentModal, {
    AdjustmentTypes,
    getNoteLastModifiedBySubText,
} from 'pages/payrollReports/PayrollReportAdjustmentModal';
import { getPayrollReportUserSnapshotChangeLogContent } from 'pages/payrollReports/PayrollReportChangeLogModal';
import PayrollReportCustomEmployerContributionModal, {
    EmployerContributionTypes,
} from 'pages/payrollReports/PayrollReportCustomEmployerContributionModal';
import { IPayrollReportRow } from 'pages/payrollReports/PayrollReportsPage';
import ReimbursementSummaryModal from 'pages/payrollReports/ReimbursementSummaryModel/ReimbursementSummaryModal';
import { useHrsForPayrollReport } from 'pages/payrollReports/useHrsForPayrollReport';
import React, { ReactNode, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { PEOPLE_PROFILE_PATH, TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { enumToNameValueArray, getUniqueListBy, hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { isNoteRequired } from 'utilities/payrollReports';
import { getPlanTypeName } from 'utilities/plans';
import { CustomColumnCell } from 'utilities/reactTable';

const LINE_FOURTEEN_CODES = (enumToNameValueArray(LineFourteenCodes, {
    formatName: (x: string) => x.replace('One', '1'),
    nameKey: 'label',
}) as unknown) as { label: string; value: LineFourteenCodes }[];

const LINE_SIXTEEN_CODES = (enumToNameValueArray(LineSixteenCodes, {
    formatName: (x: string) => x.replace('Two', '2'),
    nameKey: 'label',
}) as unknown) as { label: string; value: LineSixteenCodes }[];

const getFormattedValue = (value: number, defaultZero = false) =>
    formatCurrency(defaultZero ? value ?? 0 : value, { emptyIfNaN: true, preserveDecimal: true });

const getButtonCellColor = (hasNoteAndAdjustment: boolean): IButtonProps['color'] =>
    hasNoteAndAdjustment ? 'secondary' : 'primary';

export const CurrencyCell = ({ column, row, value }: Cell<IPayrollReportRow, number>) => (
    <span data-cy={`${row.id}-${column.id}`}>{getFormattedValue(value)}</span>
);

export const AdjustmentCell = ({
    row: { original: payrollReportRow },
    value,
    column: { adjustmentType, isLocked },
}: CustomColumnCell<
    IPayrollReportRow,
    number,
    { adjustmentType: AdjustmentTypes; isLocked: boolean }
>) => {
    const { canEditAdjustment } = useSelector((state: AppStore) => ({
        canEditAdjustment: hasSomePermissions(state, EditPayrollReports),
    }));

    const {
        closeModal: closeAdjustmentModal,
        isVisible: isAdjustmentModalVisible,
        openModal: openAdjustmentModal,
    } = useModalState();

    const hasNoteAndAdjustment = hasValue(payrollReportRow.note) && hasValue(value);
    const formattedAdjustment = getFormattedValue(value, true);
    const adjustmentText = useMemo(
        () => (
            <React.Fragment>
                {hasNoteAndAdjustment ? (
                    <u>{formattedAdjustment}</u>
                ) : (
                    <span>{formattedAdjustment}</span>
                )}
            </React.Fragment>
        ),
        [formattedAdjustment, hasNoteAndAdjustment]
    );
    const readonlyText = useMemo(
        () => <span className={hasNoteAndAdjustment ? 'text-primary' : ''}>{adjustmentText}</span>,
        [adjustmentText, hasNoteAndAdjustment]
    );

    return (
        <React.Fragment>
            <ConditionalTooltip
                isDisabled={!hasNoteAndAdjustment}
                title={payrollReportRow.note ?? ''}
            >
                {canEditAdjustment && !isLocked ? (
                    <Button
                        color={getButtonCellColor(hasNoteAndAdjustment)}
                        data-cy={`adjustment-button-${adjustmentType}`}
                        onClick={openAdjustmentModal}
                    >
                        {adjustmentText}
                    </Button>
                ) : (
                    readonlyText
                )}
            </ConditionalTooltip>
            {isAdjustmentModalVisible && (
                <PayrollReportAdjustmentModal
                    adjustmentType={adjustmentType}
                    adjustmentValue={value}
                    close={closeAdjustmentModal}
                    payrollReportUserSnapshot={payrollReportRow.payrollReportUserSnapshot}
                    reimbursablePremiumId={payrollReportRow.reimbursablePremiumId}
                />
            )}
        </React.Fragment>
    );
};

export const TotalWithSummaryCell = ({
    row: { original: payrollReportRow },
    value,
}: Cell<IPayrollReportRow, number>) => {
    const { isVisible, openModal, closeModal } = useModalState();
    const hasReimbursementSummary = hasValue(payrollReportRow.reimbursementSummary);
    return (
        <React.Fragment>
            <span>{getFormattedValue(value)}</span>
            {hasReimbursementSummary && (
                <IconButton onClick={openModal} size="small">
                    <InfoIcon fontSize="inherit"></InfoIcon>
                </IconButton>
            )}
            {isVisible && (
                <ReimbursementSummaryModal
                    close={closeModal}
                    reimbursementSummary={payrollReportRow.reimbursementSummary}
                />
            )}
        </React.Fragment>
    );
};

const ChangesIconButton = ({
    row: { original: payrollReportRow },
}: CustomColumnCell<IPayrollReportRow, string, { isLocked: boolean }>) => {
    if (payrollReportRow.changeLog) {
        return (
            <Tooltip
                title={
                    getPayrollReportUserSnapshotChangeLogContent(payrollReportRow.changeLog) ?? ''
                }
            >
                <CompareArrowsIcon color="warning" />
            </Tooltip>
        );
    }
    return <React.Fragment />;
};

const NOTE_MAX_LENGTH = 1000;
const NoteIconButton = ({
    row: { original: payrollReportRow },
    value,
    column: { isLocked },
}: CustomColumnCell<IPayrollReportRow, string, { isLocked: boolean }>) => {
    const dispatch = useThunkDispatch();
    const { canEditNote, showActivity } = useSelector((state: AppStore) => ({
        canEditNote: hasSomePermissions(state, EditPayrollReports),
        showActivity: hasApiActivity(state, PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION),
    }));

    const { closeModal, isVisible, openModal } = useModalState();

    const hasNote = hasValue(value);
    const readonlyContent = useMemo(
        () => (hasNote ? <ChatOutlinedIcon color="secondary" /> : <React.Fragment />),
        [hasNote]
    );
    const userSnapshot = payrollReportRow.payrollReportUserSnapshot;
    const save = async (_value: string | undefined) => {
        await dispatch(
            patchPayrollReportUserSnapshot(
                userSnapshot?.id,
                {
                    note: _value,
                },
                'Member Note'
            )
        );
        closeModal();
    };
    const { buttonColor, icon } = useMemo(
        (): {
            buttonColor: 'default' | 'secondary';
            icon: ReactNode;
        } =>
            hasNote
                ? {
                      buttonColor: 'secondary' as const,
                      icon: <ChatOutlinedIcon color="secondary" />,
                  }
                : {
                      buttonColor: 'default' as const,
                      icon: <ChatIcon />,
                  },
        [hasNote]
    );
    const subtext = getNoteLastModifiedBySubText(userSnapshot);
    const noteRequired = isNoteRequired(userSnapshot);

    return (
        <React.Fragment>
            <ConditionalTooltip data-cy="member-note-tooltip" isDisabled={!hasNote} title={value}>
                {canEditNote && !isLocked ? (
                    <IconButton
                        color={buttonColor}
                        data-cy="member-note-button"
                        onClick={openModal}
                    >
                        {icon}
                    </IconButton>
                ) : (
                    readonlyContent
                )}
            </ConditionalTooltip>
            {isVisible && (
                <TextAreaModal
                    data-cy="member-note-text-area"
                    isRequired={noteRequired}
                    label="Member Note"
                    maxCharacters={NOTE_MAX_LENGTH}
                    onHide={closeModal}
                    save={save}
                    showActivity={showActivity}
                    subtext={subtext}
                    title={`${hasNote ? 'Edit' : 'Add'} Member Note`}
                    value={value}
                />
            )}
        </React.Fragment>
    );
};

export const IndicatorCell = (
    data: CustomColumnCell<IPayrollReportRow, string, { isLocked: boolean }>
) => (
    <React.Fragment>
        <ChangesIconButton {...data} />
        <NoteIconButton {...data} />
    </React.Fragment>
);

export const NameCell = ({
    row: {
        original: { firstName, lastName, userId },
    },
}: Cell<IPayrollReportRow, string>) => {
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { teamId } = useTeamProps();
    const displayName = `${firstName} ${lastName}`;

    if (userId) {
        const profilePath = isTeamManagementPage
            ? generatePath(TEAMS_PEOPLE_PROFILE_PATH, { teamId, userId })
            : generatePath(PEOPLE_PROFILE_PATH, { userId });

        return (
            <Link data-cy="person-name" to={profilePath}>
                {displayName}
            </Link>
        );
    } else {
        return <span data-cy="person-name">{displayName}</span>;
    }
};

export const MajorMedicalBenefitTypeCell = ({
    row: {
        original: { majorMedicalPlans },
    },
}: Cell<IPayrollReportRow, string>) => {
    const uniqueMajorMedicalPlans = getUniqueListBy(majorMedicalPlans, 'planType');
    if (!hasValue(majorMedicalPlans) || majorMedicalPlans.length === 0) {
        return '';
    } else if ((uniqueMajorMedicalPlans?.length ?? 0) > 1) {
        const title = uniqueMajorMedicalPlans
            ?.map((x) => getPlanTypeName(x.planType))
            .joinSerialComma();
        return (
            <UnderlinedContentWithTooltip title={title as string}>
                Multiple
            </UnderlinedContentWithTooltip>
        );
    }
    return getPlanTypeName(uniqueMajorMedicalPlans?.[0]?.planType);
};

export const TaxCodeCell = ({
    row: { original: payrollReportRow },
    column: { taxCodeLine },
}: CustomColumnCell<IPayrollReportRow, string, { taxCodeLine: string }>) => {
    switch (taxCodeLine) {
        case '14':
            return (
                <React.Fragment>
                    {hasValue(payrollReportRow.lineFourteenCode)
                        ? LINE_FOURTEEN_CODES[payrollReportRow.lineFourteenCode]?.['label']
                        : ''}
                </React.Fragment>
            );
        case '16':
            return (
                <React.Fragment>
                    {hasValue(payrollReportRow.lineSixteenCode)
                        ? LINE_SIXTEEN_CODES[payrollReportRow.lineSixteenCode]?.['label']
                        : ''}
                </React.Fragment>
            );
        case '17':
            return <React.Fragment>{payrollReportRow.lineSeventeenCode}</React.Fragment>;

        default:
            return <React.Fragment />;
    }
};

export const ContributionCell = ({
    row: { id: rowId, original: payrollReportRow },
    value,
    column: { contributionType, id: columnId, isLocked },
}: CustomColumnCell<
    IPayrollReportRow,
    number,
    { contributionType: EmployerContributionTypes; id: string; isLocked: boolean }
>) => {
    const { canEditContribution } = useSelector((state: AppStore) => ({
        canEditContribution: hasSomePermissions(state, EditPayrollReports),
    }));

    const {
        closeModal: closeContributionModal,
        isVisible: isContributionModalVisible,
        openModal: openContributionModal,
    } = useModalState();

    const enrolledInBenefit = hasValue(
        contributionType === EmployerContributionTypes.Hsa
            ? payrollReportRow.hsaEmployerContribution
            : payrollReportRow.fsaEmployerContribution
    );
    const hasNoteAndCustomContribution =
        hasValue(payrollReportRow.note) &&
        hasValue(
            contributionType === EmployerContributionTypes.Hsa
                ? payrollReportRow.customHsaEmployerContribution
                : payrollReportRow.customFsaEmployerContribution
        );
    const formattedContribution = getFormattedValue(value);
    const contributionText = useMemo(
        () => (
            <React.Fragment>
                {hasNoteAndCustomContribution ? (
                    <u>{formattedContribution}</u>
                ) : (
                    <span>{formattedContribution}</span>
                )}
            </React.Fragment>
        ),
        [formattedContribution, hasNoteAndCustomContribution]
    );
    const readonlyText = useMemo(
        () => (
            <span className={hasNoteAndCustomContribution ? 'text-primary' : ''}>
                {contributionText}
            </span>
        ),
        [contributionText, hasNoteAndCustomContribution]
    );
    const content = useMemo(
        () =>
            canEditContribution && !isLocked ? (
                <Button
                    color={getButtonCellColor(hasNoteAndCustomContribution)}
                    data-cy={`${rowId}-${columnId}`}
                    onClick={openContributionModal}
                >
                    {contributionText}
                </Button>
            ) : (
                readonlyText
            ),
        [
            canEditContribution,
            columnId,
            contributionText,
            hasNoteAndCustomContribution,
            isLocked,
            openContributionModal,
            readonlyText,
            rowId,
        ]
    );

    return enrolledInBenefit ? (
        <React.Fragment>
            <ConditionalTooltip
                isDisabled={!hasNoteAndCustomContribution}
                title={payrollReportRow.note ?? ''}
            >
                {content}
            </ConditionalTooltip>
            {isContributionModalVisible && (
                <PayrollReportCustomEmployerContributionModal
                    close={closeContributionModal}
                    contributionType={contributionType}
                    contributionValue={value}
                    payrollReportUserSnapshot={payrollReportRow.payrollReportUserSnapshot}
                />
            )}
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
};

export const PayrollDeductionsActionButton = ({
    row: {
        original: { setWithholdingAndWageUp, month, payrollReportUserSnapshot, year },
    },
    column: { isLocked },
}: CustomColumnCell<IPayrollReportRow, unknown, { isLocked: boolean }>) => {
    const isHrsAssociateIdSet = payrollReportUserSnapshot?.isHrsAssociateIdSet;
    const { canApplyDeductions, isHrsIntegrationConnectedAndValid } = useHrsForPayrollReport({
        isLocked,
        month,
        year,
    });
    const showDeductionButton = isHrsAssociateIdSet && canApplyDeductions;
    const showWarning =
        isHrsAssociateIdSet &&
        !showDeductionButton &&
        !setWithholdingAndWageUp &&
        isHrsIntegrationConnectedAndValid;

    const { closeModal, isVisible, openModal } = useModalState();

    const fullName = `${payrollReportUserSnapshot?.firstName} ${payrollReportUserSnapshot?.lastName}`;
    const body = `This will update Withholding & Wage+ amounts in the team's payroll system for ${
        MONTHS[(month ?? 0) - 1]?.name
    }, ${year} for ${fullName}.
    Are you sure you want to apply Withholdings and Wage+ amounts?`;
    const setDeductionTooltipText = setWithholdingAndWageUp
        ? 'This will apply Withholdings & Wage+ amounts for the member.'
        : 'This member is set to be excluded from having their Withholding & Wage+ values applied. ' +
          'To change, go to the member\'s profile and change the "Set HRIS Withholding & Wage+" property.';
    return (
        <React.Fragment>
            {showDeductionButton && (
                <Tooltip title={setDeductionTooltipText}>
                    <IconButton
                        disabled={!setWithholdingAndWageUp}
                        onClick={openModal}
                        size="small"
                    >
                        <BackupIcon color={setWithholdingAndWageUp ? 'primary' : 'disabled'} />
                    </IconButton>
                </Tooltip>
            )}
            {showWarning && (
                <IconTooltip
                    className="ml-1"
                    icon="warning"
                    title="This member is set to be excluded from having their Withholding & Wage+ values set by the payroll system integration."
                    variant="warning"
                />
            )}
            {isVisible && (
                <ApplyDeductionsModal
                    body={body}
                    close={closeModal}
                    month={month}
                    payrollReportId={payrollReportUserSnapshot?.payrollReportId}
                    payrollReportUserSnapshotId={payrollReportUserSnapshot?.id}
                    year={year}
                />
            )}
        </React.Fragment>
    );
};
