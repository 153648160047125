import React from 'react';
import { hot } from 'react-hot-loader';

const IncarcerationStatusExamples = () => (
    <ul>
        <li>Official release papers from the institution or Department of Corrections</li>
        <li>Parole papers</li>
        <li>Unexpired state ID, driver’s license, work ID, or passport</li>
        <li>Pay stubs</li>
        <li>Federal, state, or local benefit letter</li>
        <li>Clinic, doctor, or hospital records for services provided</li>
        <li>Medical claim explanation of benefits provided</li>
        <li>School record/schedule showing enrollment (like for college students)</li>
        <li>
            Bank or credit card statement showing transaction history (showing only your name, but
            not a joint account)
        </li>
        <li>Military records</li>
        <li>Cell phone bill (showing only your name)</li>
        <li>Lease (must be an active lease where you’re currently residing)</li>
        <li>
            Signed notarized statement from the individual with alleged false incarceration
            inconsistency showing that you’re living in the community and includes your name, date
            of birth, and address
        </li>
        <li>
            Written statement from someone within the community which shows your name, date of
            birth, address, phone number, your relationship with the person with alleged false
            incarceration inconsistency (if it’s not you), and that you’re present and participating
            within the community
        </li>
        <li>Rent receipts (showing only your name)</li>
    </ul>
);

export default hot(module)(IncarcerationStatusExamples);
