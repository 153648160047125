import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Logo } from 'components/Logo';
import React from 'react';
import { hot } from 'react-hot-loader';

const BenefitsSurveyComplete = () => (
    <Grid container justifyContent="center" padding={3}>
        <Grid item md={6} xs={12}>
            <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                    <Logo height="60" />
                    <Typography my={3} textTransform="uppercase" variant="h3">
                        Health Benefits Analysis Complete!
                    </Typography>
                    <p className="text-center text-muted h4">
                        Thank you for taking the time to fill out our health benefits analysis.
                        We&apos;re working hard to evaluate the benefits for your organization, and
                        your participation is greatly appreciated!
                    </p>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
);

export default hot(module)(BenefitsSurveyComplete);
