import { AppStoreThunkDispatch } from 'actions/commonAction';
import { IPathwayUser } from 'api/generated/models';
import { IPathwayRowData } from 'pages/pathwayBlueprint/PathwayTableCells';

export const OPEN_CUSTOM_ACTIVE_WAGEUP_MODAL = 'SHOW_CUSTOM_ACTIVE_WAGEUP_MODAL';
export const openCustomActiveWageUpModal = (pathwayUser: IPathwayRowData | IPathwayUser) => (
    dispatch: AppStoreThunkDispatch
) => {
    dispatch({
        data: {
            pathwayUser,
            isCustomActiveWageUpModalVisible: true,
        },
        type: OPEN_CUSTOM_ACTIVE_WAGEUP_MODAL,
    });
};

export const CLOSE_CUSTOM_ACTIVE_WAGEUP_MODAL = 'CLOSE_CUSTOM_ACTIVE_WAGEUP_MODAL';
export const closeCustomActiveWageUpModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            isCustomActiveWageUpModalVisible: false,
            pathwayUser: null,
        },
        type: CLOSE_CUSTOM_ACTIVE_WAGEUP_MODAL,
    });
};
