import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import { EnrollmentStatuses, RenewalDecisions, TaskItems } from 'api/generated/enums';
import { push } from 'connected-react-router';
import { taskListTypes } from 'constants/tasks';
import { RH_TEAM_CARE_EMAIL } from 'constants/teams';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import TaskListItem, { ITaskListItemProps } from 'pages/dashboard/taskLists/TaskListItem';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { MY_BENEFITS_PATH } from 'routers/routes';
import { saveCompletedUserTask } from 'actions/task/saveCompletedUserTask';

const description = `You have active benefits that do not need to be renewed at this time. Please contact ${RH_TEAM_CARE_EMAIL} with any questions about your current benefits.`;

const ViewActiveBenefitsTask = (props: Partial<ITaskListItemProps>) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, userId, userProfile } = useUserProps();

    const action = useCallback(async () => {
        await dispatch(
            patchYearlyUserInfo(
                userId,
                userProfile.year,
                {
                    enrollmentStatus: {
                        value: EnrollmentStatuses.PendingApplication,
                    },
                    renewalDecision: RenewalDecisions.Keep,
                },
                isCurrent
            )
        );
        dispatch(push(MY_BENEFITS_PATH));
    }, [dispatch, isCurrent, userId, userProfile.year]);

    const markComplete = async (taskId: number | undefined, taskType: string | undefined) => {
        if (taskType === taskListTypes.User && taskId) {
            await dispatch(saveCompletedUserTask(taskId));
        }
    };
    return (
        <TaskListItem
            action={action}
            buttonText="My Benefits"
            data-cy="keep-change-benefits-task"
            description={description}
            markComplete={markComplete}
            markCompleteOnAction
            showButton={props.enabled}
            showButtonWhenComplete
            taskId={TaskItems.KeepOrChangeBenefits_Renewing}
            taskType={taskListTypes.User}
            title="View Active Benefits"
            {...props}
        ></TaskListItem>
    );
};

export default hot(module)(ViewActiveBenefitsTask);
