import { ITeamProfile } from 'api/generated/models';
import FavoriteTeamStar from 'components/FavoriteTeamStar';
import HeaderDropdown from 'components/headerDropdown/HeaderDropdown';
import { teamStateNames } from 'constants/teamStateIds';
import TeamTableActionButtons from 'pages/teams/TeamTableActionButtons';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Cell, HeaderProps } from 'react-table';
import { TEAMS_DASHBOARD_PATH } from 'routers/routes';

export const TeamStatusHeader = ({
    column: { id, filterValue, setFilter },
    setSortBy,
    state: { sortBy },
}: HeaderProps<ITeamProfile>) => (
    <HeaderDropdown
        columnId={id}
        id="status-filter-dropdown"
        options={teamStateNames as string[]}
        selected={filterValue}
        setSelected={setFilter}
        setSorted={setSortBy}
        sorted={sortBy}
        title="Status"
    />
);

export const LocationCell = ({
    row: {
        original: { address },
    },
}: Cell<ITeamProfile, string>) => (
    <div>
        <div>{address?.addressLine1}</div>
        <div>{address?.addressLine2}</div>
        <div>{`${address?.city}, ${address?.state} ${address?.zip}`}</div>
    </div>
);
export const TeamNameCell = ({
    row: {
        original: { team },
    },
}: Cell<ITeamProfile, string>) => (
    <FavoriteTeamStar teamId={team?.teamId}>
        <Link data-cy="team-name" to={generatePath(TEAMS_DASHBOARD_PATH, { teamId: team?.teamId })}>
            {team?.name}
            {team?.isArchived ? ' (Archived)' : ''}
        </Link>
    </FavoriteTeamStar>
);
export const TeamActionsCell = ({
    row: {
        original: { team },
    },
}: Cell<ITeamProfile, undefined>) => <TeamTableActionButtons team={team} />;
