import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
    deleteTeamBenefitRhFile,
    DELETE_TEAM_BENEFIT_RH_FILE_ACTION,
} from 'actions/rhFile/deleteTeamBenefitRhFile';
import { renameTeamBenefitRhFile } from 'actions/rhFile/renameTeamBenefitRhFile';
import {
    toggleTeamBenefitRhFileShared,
    TOGGLE_TEAM_BENEFIT_RH_FILE_SHARED_ACTION,
} from 'actions/rhFile/toggleTeamBenefitRhFileShared';
import { downloadTeamBenefitFile } from 'api/downloadTeamBenefitFile';
import { IRhFile } from 'api/generated/models';
import {
    DeleteTeamBenefitRhFile,
    DownloadTeamBenefitRhFile,
    EditTeamBenefitRhFile,
    ShareTeamBenefitRhFile,
    ViewTeamBenefit,
} from 'api/generated/permissions';
import ActionButtons from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import Icon from 'components/Icon';
import RenameRhFileModal from 'components/rhFile/RenameRhFileModal';
import RhFileDetailsModal from 'components/rhFile/RhFileDetailsModal';
import RhFilePreviewModal from 'components/rhFile/RhFilePreviewModal';
import Tooltip from 'components/Tooltip';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import kebabCase from 'lodash/kebabCase';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { getFileIconDataForExtension } from 'utilities/files';
import { downloadFileFromApi } from 'utilities/rhFile';

const TeamBenefitFile = ({
    file,
    teamBenefitId,
}: {
    file: IRhFile;
    teamBenefitId: string | undefined;
}) => {
    const dispatch = useThunkDispatch();
    const {
        canDeleteFiles,
        canDownloadFiles,
        canEditFiles,
        canShareFiles,
        canViewTeamBenefits,
        isDeletingFile,
        isTogglingSharing,
    } = useSelector((state: AppStore) => ({
        canDeleteFiles: hasSomePermissions(state, DeleteTeamBenefitRhFile),
        canDownloadFiles: hasSomePermissions(state, DownloadTeamBenefitRhFile),
        canEditFiles: hasSomePermissions(state, EditTeamBenefitRhFile),
        canShareFiles: hasSomePermissions(state, ShareTeamBenefitRhFile),
        canViewTeamBenefits: hasSomePermissions(state, ViewTeamBenefit),
        isDeletingFile: hasApiActivity(state, DELETE_TEAM_BENEFIT_RH_FILE_ACTION),
        isTogglingSharing: hasApiActivity(state, TOGGLE_TEAM_BENEFIT_RH_FILE_SHARED_ACTION),
    }));
    const [isDownloading, setIsDownloading] = useState(false);
    const {
        isVisible: isPreviewModalVisible,
        closeModal: closePreviewModal,
        openModal: openPreviewModal,
    } = useModalState();
    const {
        isVisible: isDetailsModalVisible,
        closeModal: closeDetailsModal,
        openModal: openDetailsModal,
    } = useModalState();
    const {
        isVisible: isRenameModalVisible,
        closeModal: closeRenameModal,
        openModal: openRenameModal,
    } = useModalState();
    const {
        isVisible: isDeleteModalVisible,
        closeModal: closeDeleteModal,
        openModal: openDeleteModal,
    } = useModalState();
    const iconData = getFileIconDataForExtension(file.fileExtension ?? '');
    const handleDownloadClick = async () => {
        setIsDownloading(true);
        await downloadFileFromApi(async () => downloadTeamBenefitFile(teamBenefitId, file.id));
        setIsDownloading(false);
    };
    const deleteFile = async () => {
        await dispatch(deleteTeamBenefitRhFile(teamBenefitId, file.id));
        closeDeleteModal();
    };

    const toggleSharing = async () => {
        await dispatch(toggleTeamBenefitRhFileShared(teamBenefitId, file.id, file.isShared));
    };

    const actionItems = [
        {
            isVisible: true,
            onClick: openDetailsModal,
            text: 'Details',
        },
        {
            isVisible: canEditFiles,
            onClick: openRenameModal,
            text: 'Rename',
        },
        {
            isLoading: isDownloading,
            isVisible: canDownloadFiles,
            onClick: handleDownloadClick,
            text: 'Download',
        },
        {
            isLoading: isTogglingSharing,
            isVisible: canShareFiles,
            onClick: toggleSharing,
            text: `${file.isShared ? 'Un-Share' : 'Share'} To Members`,
        },
        {
            isVisible: canDeleteFiles,
            onClick: openDeleteModal,
            text: 'Delete',
        },
    ];

    return (
        <React.Fragment>
            {isPreviewModalVisible && (
                <RhFilePreviewModal
                    canDownloadRhFiles={canDownloadFiles}
                    downloadRhFile={async () => downloadTeamBenefitFile(teamBenefitId, file.id)}
                    onClose={closePreviewModal}
                    rhFile={file}
                />
            )}
            {isDetailsModalVisible && (
                <RhFileDetailsModal onClose={closeDetailsModal} rhFile={file} />
            )}
            {isRenameModalVisible && (
                <RenameRhFileModal
                    onClose={closeRenameModal}
                    renameRhFile={(f) => renameTeamBenefitRhFile(teamBenefitId, f)}
                    rhFile={file}
                />
            )}
            {isDeleteModalVisible && (
                <ConfirmationModal
                    body="Are you sure you want to delete this file? This fully deletes the file from all places in the Remodel Health Platform."
                    onNoClick={closeDeleteModal}
                    onYesClick={deleteFile}
                    showActivity={isDeletingFile}
                    title={`Delete ${file.name}`}
                />
            )}
            <ListItem>
                <ListItemButton
                    className={`align-items-center flex-nowrap ${
                        canDownloadFiles ? 'clickable' : ''
                    }`}
                    onClick={canDownloadFiles ? openPreviewModal : undefined}
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                    <ListItemIcon>
                        <Icon prefix="mdi" size="2" variant={iconData.color}>
                            {iconData.icon}
                        </Icon>
                    </ListItemIcon>
                    <ListItemText
                        data-cy={`${kebabCase(file.name)}-file-name`}
                        primary={
                            <React.Fragment>
                                {file.name}
                                {canViewTeamBenefits && file.isShared && (
                                    <Tooltip title="This file is shared with all members of this team">
                                        <Icon className="ml-2" variant="primary">
                                            user-group
                                        </Icon>
                                    </Tooltip>
                                )}
                            </React.Fragment>
                        }
                    />
                </ListItemButton>
                <ActionButtons
                    className="mr-0"
                    dataCy={`${kebabCase(file.name)}-action-button`}
                    dropdownWhenLengthGreaterThan={0}
                    isThreeDotButton
                    items={actionItems}
                />
            </ListItem>
        </React.Fragment>
    );
};

export default hot(module)(TeamBenefitFile);
