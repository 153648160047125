import { EDIT_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/editSubmittedExpense';
import { GET_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/getSubmittedExpense';
import { ISubmittedExpenseDto } from 'api/generated/models';
import { EditLockedSubmittedExpense, EditSubmittedExpense } from 'api/generated/permissions';
import CurrencyTextField from 'components/CurrencyTextField';
import Typography from 'components/Typography';
import { ExpenseTypeIds } from 'constants/reimbursementProgramExpenseTypes';
import { getExpenseSourceLabel } from 'pages/benefits/SubmitExpenseModal';
import {
    SubmittedExpenseLabelAndValue,
    TwoColumns,
} from 'pages/reimbursementExpenseManagement/SubmittedExpenseModal';
import RecurringExpense from 'pages/reimbursementExpenseManagement/submittedExpenseModal/RecurringExpense';
import ReimburseToHsa from 'pages/reimbursementExpenseManagement/submittedExpenseModal/ReimburseToHsa';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { formatDateForDisplay } from 'utilities/format';
const ONE_CENT = 0.01;

type ISubmittedExpenseModalSectionOne = {
    IsRecurring: boolean | undefined;
    expenseAmount: number;
    expenseAmountError: string[] | undefined;
    handleExpenseAmountChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void;
    handleIsRecurringChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void;
    handleReimburseToHsaChange: ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => void;
    isRecurringError: string[] | undefined;
    reimburseToHsa: boolean | undefined;
    reimburseToHsaError: string[] | undefined;
    submittedExpense: ISubmittedExpenseDto | undefined;
};

const SubmittedExpenseModalSectionOne = ({
    expenseAmount,
    IsRecurring,
    handleExpenseAmountChange,
    handleIsRecurringChange,
    expenseAmountError,
    handleReimburseToHsaChange,
    isRecurringError,
    reimburseToHsa,
    reimburseToHsaError,
    submittedExpense,
}: ISubmittedExpenseModalSectionOne) => {
    const {
        canEditLockedSubmittedExpense,
        canEditSubmittedExpense,
        isLoading,
        isRh,
        loggedInUserId,
        recurringAllowed,
    } = useSelector((state: AppStore) => ({
        canEditLockedSubmittedExpense: hasSomePermissions(state, EditLockedSubmittedExpense),
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        isLoading: hasApiActivity(
            state,
            GET_SUBMITTED_EXPENSE_ACTION,
            EDIT_SUBMITTED_EXPENSE_ACTION
        ),
        isRh: isRhSelector(state),
        loggedInUserId: state.login.userId,
        recurringAllowed: state.submittedExpense.recurringAllowed,
    }));
    const canViewSensitiveExpenseData = submittedExpense?.userId === loggedInUserId || isRh;
    const showReimburseToHsa =
        (submittedExpense?.useHsaReimbursement || submittedExpense?.reimburseToHsa) &&
        submittedExpense?.isHsaEligible &&
        submittedExpense?.expenseTypeId === ExpenseTypeIds.Medical.valueOf();

    return (
        <TwoColumns gridTemplateColumns={{ md: '1fr 1fr' }}>
            <TwoColumns>
                <SubmittedExpenseLabelAndValue
                    isLoading={isLoading}
                    label="Member Name"
                    value={submittedExpense?.memberName}
                />
                <SubmittedExpenseLabelAndValue
                    isLoading={isLoading}
                    label="Expense For"
                    value={submittedExpense?.expenseFor}
                />
                <SubmittedExpenseLabelAndValue
                    isLoading={isLoading}
                    label="Team Name"
                    value={submittedExpense?.teamName}
                />
                {showReimburseToHsa && (
                    <ReimburseToHsa
                        handleReimburseToHsaChange={handleReimburseToHsaChange}
                        reimburseToHsa={reimburseToHsa as boolean}
                        reimburseToHsaError={reimburseToHsaError}
                        submittedExpense={submittedExpense}
                    />
                )}
                {(submittedExpense?.isRecurring || recurringAllowed) && (
                    <RecurringExpense
                        handleIsRecurringChange={handleIsRecurringChange}
                        IsRecurring={IsRecurring}
                        isRecurringError={isRecurringError}
                        submittedExpense={submittedExpense}
                    />
                )}
            </TwoColumns>
            <TwoColumns>
                <SubmittedExpenseLabelAndValue
                    isLoading={isLoading}
                    label="Date Submitted"
                    value={formatDateForDisplay(submittedExpense?.submittedDate)}
                />
                <SubmittedExpenseLabelAndValue
                    isLoading={isLoading}
                    label="Date of Service"
                    value={formatDateForDisplay(submittedExpense?.dateOfService)}
                />
                <SubmittedExpenseLabelAndValue
                    isLoading={isLoading}
                    label="Expense Type"
                    value={submittedExpense?.expenseType}
                />
                {canViewSensitiveExpenseData && (
                    <SubmittedExpenseLabelAndValue
                        isLoading={isLoading}
                        label={getExpenseSourceLabel(submittedExpense?.expenseType as string)}
                        value={submittedExpense?.expenseSource}
                    />
                )}
                {canEditSubmittedExpense ? (
                    <React.Fragment>
                        <Typography alignSelf="center" fontWeight="bold">
                            Expense Amount
                        </Typography>
                        <CurrencyTextField
                            disabled={submittedExpense?.isLocked && !canEditLockedSubmittedExpense}
                            errors={expenseAmountError}
                            min={ONE_CENT}
                            name="expenseAmount"
                            onChange={handleExpenseAmountChange}
                            placeholder="Enter expense amount"
                            required
                            value={expenseAmount}
                        />
                    </React.Fragment>
                ) : (
                    <SubmittedExpenseLabelAndValue
                        isCurrency
                        isLoading={isLoading}
                        label="Expense Amount"
                        value={submittedExpense?.expenseAmount}
                    />
                )}
            </TwoColumns>
        </TwoColumns>
    );
};

export default hot(module)(SubmittedExpenseModalSectionOne);
