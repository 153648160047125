import { Card, CardContent } from '@mui/material';
import {
    GET_TEAM_RELATIONSHIPS_ACTION,
    getTeamRelationships,
} from 'actions/team/getTeamRelationships';
import { RelationshipTypeIds, TeamStateIds } from 'api/generated/enums';
import { IUser, IUserTeamRelationship } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import { RH_TEAM_CARE_EMAIL, RH_TEAM_LAUNCH_EMAIL, RH_TEAM_PHONE_NUMBER } from 'constants/teams';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import ProfilePicture from 'pages/profile/ProfilePicture';
import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import { formatPhoneNumber } from 'utilities/format';

const getOwnerEmail = (ownerRelationship: IUserTeamRelationship | undefined) => {
    const teamOwnerType = ownerRelationship?.relationshipType?.teamRelationshipTypeId;
    if (teamOwnerType === RelationshipTypeIds.CustomerSuccessRepresentative) {
        return RH_TEAM_CARE_EMAIL;
    }
    if (teamOwnerType === RelationshipTypeIds.LaunchRepresentative) {
        return RH_TEAM_LAUNCH_EMAIL;
    }
    return ownerRelationship?.user?.email;
};

const TeamMemberProperty = styled.p`
    font-size: 16px;
    margin-bottom: 8px;
`;

const TeamMemberJobTitle = styled(TeamMemberProperty)`
    font-style: italic;
`;

const TeamMemberName = styled.p`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
`;

const TeamMemberInformation = ({
    defaultJobTitle,
    email,
    user,
}: {
    defaultJobTitle: string;
    email?: string;
    user: IUser;
}) => (
    <Col className="d-flex flex-column justify-content-center align-items-center text-center">
        <ProfilePicture size="150px" user={user} />
        <TeamMemberName>{user.displayName}</TeamMemberName>
        <TeamMemberJobTitle>
            {hasValue(user.jobTitle) ? user.jobTitle : defaultJobTitle}
        </TeamMemberJobTitle>
        <TeamMemberProperty>
            {formatPhoneNumber(user.phone) ?? RH_TEAM_PHONE_NUMBER}
        </TeamMemberProperty>
        <TeamMemberProperty>{hasValue(email) ? email : user.email}</TeamMemberProperty>
    </Col>
);

const MyTeamWidget = () => {
    const dispatch = useThunkDispatch();
    const { teamId, teamStateId } = useTeamProps();
    const { isLoading, teamRelationships } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_TEAM_RELATIONSHIPS_ACTION),
        teamRelationships: state.teamRelationships,
    }));

    useEffect(() => {
        dispatch(getTeamRelationships(teamId));
    }, [dispatch, teamId]);

    const engagement = teamRelationships.find(
        (x) =>
            x.relationshipType?.teamRelationshipTypeId ===
            RelationshipTypeIds.EngagementRepresentative
    )?.user;

    const accountManager = teamRelationships.find(
        (x) => x.relationshipType?.teamRelationshipTypeId === RelationshipTypeIds.AccountManager
    )?.user;

    const isPostLaunchStatus = [
        TeamStateIds.MemberLaunch,
        TeamStateIds.Customer,
        TeamStateIds.Renewing,
    ].contains(teamStateId);

    const ownerRelationship = teamRelationships.find((x) => x.isOwner);
    const ownerTitle = ownerRelationship?.relationshipType?.name ?? 'Team Owner';
    const owner = ownerRelationship?.user;
    const ownerEmail = getOwnerEmail(ownerRelationship);

    const isEngagementOwner = engagement?.userId === owner?.userId;
    const isAccountManagerOwner = accountManager?.userId === owner?.userId;

    return (
        <Card>
            <CardContent>
                <DashboardCardHeader header="My Remodel Health Team" />
                <hr />
                <Row className="justify-content-around">
                    <Skeleton count={2} height="300px" isEnabled={isLoading} width="300px">
                        {owner && (
                            <TeamMemberInformation
                                defaultJobTitle={ownerTitle}
                                email={ownerEmail}
                                user={owner}
                            />
                        )}
                        {!isEngagementOwner && hasValue(engagement) && !isPostLaunchStatus && (
                            <TeamMemberInformation
                                defaultJobTitle="Engagement Representative"
                                user={engagement}
                            />
                        )}
                        {!isAccountManagerOwner &&
                            hasValue(accountManager) &&
                            isPostLaunchStatus && (
                                <TeamMemberInformation
                                    defaultJobTitle="Account Manager"
                                    email={RH_TEAM_CARE_EMAIL}
                                    user={accountManager}
                                />
                            )}
                    </Skeleton>
                </Row>
            </CardContent>
        </Card>
    );
};

export default hot(module)(MyTeamWidget);
