import Typography from '@mui/material/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';

const ProfileSectionHeader = ({ headerText }: { headerText: string }) => (
    <Typography sx={{ marginBottom: 3 }} variant="h4">
        {headerText}
    </Typography>
);

export default hot(module)(ProfileSectionHeader);
