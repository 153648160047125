import { IAction } from 'actions/commonAction';
import { TeamStateIds } from 'api/generated/enums';
import { Ordering } from 'api/generated/models';
import {
    CLEAR_TEAMS_PAGE_PAGINATION_PARAMS,
    SET_TEAMS_PAGE_PAGINATION_PARAMS,
    SET_TEAMS_PAGE_SEARCH,
} from 'pages/teams/teamsActions';

export type ITeamsState = {
    includeArchived: boolean;
    orderings: Ordering[];
    page: number;
    pageSize: number;
    search: string;
    teamStateFilters: TeamStateIds[];
};

const initialState: ITeamsState = {
    includeArchived: false,
    orderings: [],
    page: 0,
    pageSize: 10,
    search: '',
    teamStateFilters: [],
};

export const teamsState = (state = initialState, action: IAction<ITeamsState>): ITeamsState => {
    switch (action.type) {
        case SET_TEAMS_PAGE_SEARCH:
        case SET_TEAMS_PAGE_PAGINATION_PARAMS:
            return { ...state, ...action.data };
        case CLEAR_TEAMS_PAGE_PAGINATION_PARAMS:
            return initialState;
        default:
            return state;
    }
};
