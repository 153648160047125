import Typography from 'components/Typography';
import { sortBy } from 'lodash';
import IchraClassCard from 'pages/pathwayBlueprint/ichraClasses/IchraClassCard';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { arrayHasValue } from 'utilities/index';

const IchraClasses = () => {
    const { pathwayBlueprint } = useSelector((state: AppStore) => ({
        pathwayBlueprint: state.pathwayBlueprint,
    }));
    const sortedIchraClasses = sortBy(pathwayBlueprint?.ichraClasses, (ic) => ic.name);

    const getPathwaysForIchraClass = useCallback(
        (ichraClassId: number) =>
            pathwayBlueprint?.pathways?.filter((p) => p.ichraClassId === ichraClassId),
        [pathwayBlueprint?.pathways]
    );

    return (
        <React.Fragment>
            {arrayHasValue(sortedIchraClasses) && (
                <Typography sx={{ marginBottom: 0 }} variant="h3">
                    ICHRA Classes
                </Typography>
            )}
            {sortedIchraClasses?.map((ic) => {
                const ichraClassPathways = getPathwaysForIchraClass(ic?.id);
                return (
                    <IchraClassCard ichraClass={ic} key={ic?.id} pathways={ichraClassPathways} />
                );
            })}
        </React.Fragment>
    );
};

export default hot(module)(IchraClasses);
