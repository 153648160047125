import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import MultiSelectButtons from 'pages/survey/multiSelectButtons/MultiSelectButtons';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveMemberCoverageSelection } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import { IMemberCoverageSelection } from 'pages/survey/surveyState';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const NeedsCoverageForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { householdMembers: savedHouseholdMembers, member } = useSelector((state: AppStore) => ({
        householdMembers: state.surveyState.household.members,
        member: { ...state.surveyState.member },
    }));

    const [memberCoverageSelections, setMembers] = useState<IMemberCoverageSelection[]>([
        { householdMemberId: member.userId, ...member },
        ...(savedHouseholdMembers?.map((m): IMemberCoverageSelection => ({ ...m })) ?? []),
    ]);
    const onSelection = useCallback(
        (selectedMember) => {
            const updatedMembers = memberCoverageSelections.map(
                (m): IMemberCoverageSelection => {
                    if (m?.householdMemberId === selectedMember?.householdMemberId) {
                        m.needsCoverage = !m.needsCoverage;
                    }
                    return m;
                }
            );
            setMembers(updatedMembers);
        },
        [memberCoverageSelections]
    );

    const onSubmit = useCallback(() => {
        dispatch(saveMemberCoverageSelection(memberCoverageSelections));

        commonProps.onSubmit();
    }, [commonProps, dispatch, memberCoverageSelections]);

    return (
        <SurveyFormWrapper {...commonProps} onSubmit={onSubmit}>
            {children}
            <FormTitle description="Select all that apply">Who needs health coverage?</FormTitle>
            <MultiSelectButtons
                isSelectedProp="needsCoverage"
                onSelection={onSelection}
                options={memberCoverageSelections}
            />
        </SurveyFormWrapper>
    );
};

export default hot(module)(NeedsCoverageForm);
