import { HouseholdMemberTypes } from 'api/generated/enums';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import FormTitle from 'pages/survey/FormTitle';
import HouseholdMemberCard from 'pages/survey/householdSteps/HouseholdMemberCard';
import HouseholdMemberModal from 'pages/survey/householdSteps/HouseholdMemberModal';
import PrepopulatedAlert from 'pages/survey/householdSteps/PrepopulatedAlert';
import { IStepProps } from 'pages/survey/StepWrapper';
import { removeHouseholdMember, saveHousehold } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { ichraCheckThroughRenewal, isTrueOrFalse } from 'utilities';
import { findMemberType, hasMemberType } from 'utilities/household';

const SpouseForm = ({ commonProps, children }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const { isInIchraPathway, user } = useUserProps();
    const {
        hasSpouse,
        householdMemberSpouse,
        isSpouseSelected,
        prepopulatedHouseholdMemberIds,
    } = useSelector((state: AppStore) => ({
        hasSpouse: hasMemberType(state.surveyState.household.members, HouseholdMemberTypes.Spouse),
        householdMemberSpouse: findMemberType(
            state.surveyState.household.members,
            HouseholdMemberTypes.Spouse
        ) ?? {
            householdMemberTypeId: HouseholdMemberTypes.Spouse,
        },
        isSpouseSelected: state.surveyState.household.hasSpouse,
        prepopulatedHouseholdMemberIds: state.surveyState.household.prepopulatedHouseholdMemberIds,
    }));
    const isIchra = ichraCheckThroughRenewal(history, isInIchraPathway, user?.surveyTypeToSend);
    const isPrepopulated = prepopulatedHouseholdMemberIds?.some(
        (x) => x === householdMemberSpouse.householdMemberId
    );
    useEffect(() => {
        if (isSpouseSelected && !hasSpouse) {
            dispatch(saveHousehold({ hasSpouse: undefined }));
        }
        if (hasSpouse) {
            dispatch(saveHousehold({ hasSpouse }));
        }
    }, [dispatch, hasSpouse, isSpouseSelected]);

    const onSelection = useCallback(
        (isYes) => {
            dispatch(saveHousehold({ hasSpouse: isYes }));
            if (isYes && !hasSpouse) {
                setShowModal(true);
            } else if (!isYes && hasSpouse) {
                dispatch(
                    removeHouseholdMember({
                        householdMemberId: householdMemberSpouse.householdMemberId,
                    })
                );
            }
        },
        [dispatch, hasSpouse, householdMemberSpouse]
    );
    const closeModal = () => setShowModal(false);
    const openModal = () => setShowModal(true);

    const descriptionContent = isIchra
        ? 'Answer "Yes" if you have a spouse that will need to be covered by your health insurance plan.'
        : 'Answer "Yes" if legally married. Answer "No" if divorced, legally separated, unmarried and living together, or widowed.';
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={!isTrueOrFalse(isSpouseSelected)}>
            {children}
            {showModal && (
                <HouseholdMemberModal
                    handleClose={closeModal}
                    householdMember={householdMemberSpouse}
                />
            )}
            <FormTitle description={descriptionContent}>
                {isIchra ? 'Do you have a spouse that needs medical coverage?' : 'Are you married?'}
            </FormTitle>
            <YesOrNoSelect onSelection={onSelection} value={isSpouseSelected} />
            {isPrepopulated && <PrepopulatedAlert sx={{ marginBottom: 2 }} />}
            {hasSpouse && <HouseholdMemberCard {...householdMemberSpouse} onClick={openModal} />}
        </SurveyFormWrapper>
    );
};

export default hot(module)(SpouseForm);
