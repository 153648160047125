import {
    HouseholdEligibilityStatus,
    HouseholdMemberTypes,
    OtherCoverageEligibilities,
} from 'api/generated/enums';
import { IMemberEligibility } from 'api/generated/models';
import React, { createContext } from 'react';
import { getEligibilityName } from 'utilities/householdEligibility';
import { hasValue } from 'utilities/index';

export type IShoppingMemberSelection = {
    age?: number;
    entityId: string;
    firstName: string;
    gender?: string;
    householdMemberType?: HouseholdMemberTypes;
    isDisabled: boolean;
    isPrimary: boolean;
    isSelected: boolean;
    memberEligibility?: IMemberEligibility;
    needsCoverage: boolean;
    zipCode?: string;
};

export type IShoppingMemberSelectContext = {
    setShoppingMemberSelections?: React.Dispatch<
        React.SetStateAction<IShoppingMemberSelection[] | undefined>
    >;
    shoppingMemberSelections?: IShoppingMemberSelection[];
};
export const ShoppingMemberSelectContext = createContext<IShoppingMemberSelectContext>({});

export const handleToolTip = (
    isModal: boolean,
    shoppingMemberSelection: IShoppingMemberSelection,
    isInIchraPathway?: boolean,
    isUnclaimedAllowed?: boolean,
    numSelectedMembers?: number,
    index?: number
) => {
    const needsCoverageAdjust1 = shoppingMemberSelection.needsCoverage ? '' : 'not ';
    const needsCoverageAdjust2 = shoppingMemberSelection.needsCoverage ? 'but' : 'and';
    const isSpouseCoverage =
        !isInIchraPathway &&
        shoppingMemberSelection.memberEligibility?.eligibilityStatus ===
            HouseholdEligibilityStatus.Other &&
        shoppingMemberSelection.memberEligibility?.otherCoverageEligibility ===
            OtherCoverageEligibilities.SpouseEmployer;

    if (
        !isUnclaimedAllowed &&
        shoppingMemberSelection.householdMemberType === HouseholdMemberTypes.Unclaimed
    ) {
        return `Since ${shoppingMemberSelection.firstName} is not claimed on your taxes, you cannot shop for them here. Please wait to speak with an advisor about their benefits, and feel free to reach out to your Remodel Health representative with any questions.`;
    }

    if (
        shoppingMemberSelection.memberEligibility?.eligibilityStatus !==
            HouseholdEligibilityStatus.None &&
        !isSpouseCoverage &&
        shoppingMemberSelection.householdMemberType !== HouseholdMemberTypes.Unclaimed
    ) {
        return (
            <span data-cy={`household-member-tooltip-${index}`}>
                {shoppingMemberSelection.firstName} is marked as {needsCoverageAdjust1}needing
                coverage {needsCoverageAdjust2} may be eligible for{' '}
                {getEligibilityName(
                    shoppingMemberSelection.memberEligibility
                        ?.eligibilityStatus as HouseholdEligibilityStatus
                )}
                , so you cannot shop for them here. Reach out to care@remodelhealth.com if this
                member needs to be changed.
            </span>
        );
    }

    if (isModal && shoppingMemberSelection.isSelected && numSelectedMembers === 1) {
        return (
            <span data-cy={`household-member-tooltip-${index}`}>
                We need at least one member checked to successfully get plans and programs.
            </span>
        );
    }

    if (!isModal && !shoppingMemberSelection.needsCoverage) {
        return (
            <span data-cy={`household-member-tooltip-${index}`}>
                This member is marked as not needing major medical coverage
            </span>
        );
    }

    return (
        <span data-cy={`household-member-tooltip-${index}`}>
            Age on Effective Date: {shoppingMemberSelection.age}
            <br />
            Gender: {shoppingMemberSelection.gender}
            <br />
            {hasValue(shoppingMemberSelection.zipCode) && `ZIP: ${shoppingMemberSelection.zipCode}`}
        </span>
    );
};
