import Stack from '@mui/material/Stack';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import TextArea from 'components/TextArea';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import {
    closeCustomTotalModal,
} from 'pages/pathwayBlueprint/pathwayTotalCostModalActions';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue, stringToFloat } from 'utilities';
import { onChange } from 'utilities/forms';
import { number, object, string } from 'yup';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IPathwayUser } from 'api/generated/models';
import { PATCH_PATHWAY_USER_ACTION, patchPathwayUser } from 'actions/pathwayBlueprint/patchPathwayUser';

const NOTE_MAX_LENGTH = 600;
const schema = object({
    cost: number()
        .transform(stringToFloat)
        .required()
        .label('Custom Plan Cost'),
    note: string()
        .trim()
        .required()
        .max(NOTE_MAX_LENGTH)
        .label('Note'),
});

const CustomPlanCostModal = () => {
    const dispatch = useThunkDispatch();
    const { isLoading, pathwayUser } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, PATCH_PATHWAY_USER_ACTION),
        pathwayUser: state.customPlanCostModalState.pathwayUser,
    }));
    const isCustom = hasValue(pathwayUser?.customPlanCost);

    const [cost, setCustomCost] = useState(pathwayUser?.customPlanCost);
    const [note, setNote] = useState(pathwayUser?.customPlanCostNote ?? '');
    const [shouldReset, setShouldReset] = useState(false);
    const { errors, validate } = useForm(schema);

    const onClose = useCallback(() => {
        dispatch(closeCustomTotalModal());
    }, [dispatch]);

    const save = useCallback(async () => {
        const { isValid } = await validate({ cost, note });
        if (isValid) {
            const patch: Partial<IPathwayUser> = {
                customPlanCost: cost,
                customPlanCostNote: note?.trim(),
            };
            await dispatch(patchPathwayUser(pathwayUser.id, patch, 'Custom Plan Cost'));
            onClose();
        }
    }, [validate, note, cost, dispatch, pathwayUser?.id, onClose]);
    const reset = useCallback(async () => {
        if (shouldReset) {
            const patch: Partial<IPathwayUser> = {
                customPlanCost: undefined,
                customPlanCostNote: undefined,
            };
            await dispatch(patchPathwayUser(pathwayUser.id, patch, 'Custom Plan Cost'));
            onClose();
        }
        setShouldReset(true);
    }, [shouldReset, dispatch, pathwayUser?.id, onClose]);

    return (
        <Dialog fullWidth maxWidth="sm" open sx={{ padding: 3, zIndex: 98 }}>
        <Form
                id="edit-plan-cost-modal-form"
                isLoading={isLoading}
                onSubmit={save}
            >
            <DialogTitle>
                 Edit Plan Cost
            </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ p: 2 }}>
                        <CurrencyTextField
                            autoFocus
                            errors={errors?.cost}
                            label="Cost"
                            name="cost"
                            onChange={onChange(setCustomCost)}
                            placeholder="Enter Plan Cost"
                            value={cost}
                        />
                        <TextArea
                            errors={errors?.note}
                            label="Notes"
                            maxLength={NOTE_MAX_LENGTH}
                            name="note"
                            onChange={onChange(setNote)}
                            placeholder="Add an explanation for why plan cost is required"
                            value={note}
                        />
                    </Stack>
                </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', margin: 1.5}}>
                <Stack alignItems="center" direction="column" spacing={2} width="100%">
                    <Stack direction="row" justifyContent="center" spacing={2}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            form="edit-plan-cost-modal-form"
                            isLoading={isLoading}
                            type="submit"
                        >
                            Save
                        </Button>
                    </Stack>
                    {isCustom && (
                        <Stack className="mt-1" direction="row" justifyContent="center" spacing={2}>
                            <Button
                                color="error"
                                data-cy="reset-plan-cost"
                                isLoading={isLoading}
                                onClick={reset}
                                onMouseLeave={() => setShouldReset(false)}
                                variant="text"
                            >
                                {shouldReset ? 'Are you sure?' : 'Reset'}
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </DialogActions>
            </Form>
        </Dialog>
    );
};

export default hot(module)(CustomPlanCostModal);
