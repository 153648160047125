import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import {
    EDIT_REIMBURSABLE_PREMIUM_ADJUSTMENT_ACTION,
    editReimbursablePremiumAdjustment,
} from 'actions/payroll/editReimbursablePremiumAdjustment';
import {
    PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION,
    patchPayrollReportUserSnapshot,
} from 'actions/payroll/patchPayrollReportUserSnapshot';
import { IPayrollReportUserSnapshot, IPayrollReportUserSnapshotDto } from 'api/generated/models';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import TextArea from 'components/TextArea';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue, stringToFloat } from 'utilities';
import { formatUserOnDateAtTime } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { isNoteRequired } from 'utilities/payrollReports';
import { number, object, string } from 'yup';

export const getNoteLastModifiedBySubText = (
    payrollReportUserSnapshot: IPayrollReportUserSnapshotDto | undefined
) =>
    hasValue(payrollReportUserSnapshot?.noteLastModifiedDate) &&
    hasValue(payrollReportUserSnapshot?.noteLastModifiedByUser)
        ? `Note last modified by ${formatUserOnDateAtTime(
              payrollReportUserSnapshot?.noteLastModifiedByUser,
              payrollReportUserSnapshot?.noteLastModifiedDate
          )}`
        : undefined;

const NOTE_MAX_LENGTH = 1000;
export enum AdjustmentTypes {
    WageUp,
    TaxFreeReimbursements,
    PostTaxWithholding,
    PreTaxWithholding,
    IchraReimbursement,
    ReimbursablePremium,
}

const getTitle = (adjustmentType: AdjustmentTypes) => {
    switch (adjustmentType) {
        case AdjustmentTypes.WageUp:
            return 'Wage+';
        case AdjustmentTypes.PreTaxWithholding:
            return 'Pre-Tax Withholding';
        case AdjustmentTypes.TaxFreeReimbursements:
            return 'Tax-Free Reimbursements';
        case AdjustmentTypes.PostTaxWithholding:
            return 'Post-Tax Withholding';
        case AdjustmentTypes.IchraReimbursement:
            return 'ICHRA Reimbursement';
        case AdjustmentTypes.ReimbursablePremium:
            return 'Reimbursable Premium';
    }
};

const getPatchProperty = (adjustmentType: AdjustmentTypes): keyof IPayrollReportUserSnapshot => {
    switch (adjustmentType) {
        case AdjustmentTypes.WageUp:
            return 'wageUpAdjustment';
        case AdjustmentTypes.PreTaxWithholding:
            return 'preTaxWithholdingAdjustment';
        case AdjustmentTypes.TaxFreeReimbursements:
            return 'taxFreeReimbursementAdjustment';
        case AdjustmentTypes.PostTaxWithholding:
            return 'postTaxWithholdingAdjustment';
        case AdjustmentTypes.IchraReimbursement:
            return 'ichraReimbursementAdjustment';
        case AdjustmentTypes.ReimbursablePremium:
            throw new Error('This should never happen');
    }
};

const getAdjustedSnapshot = (
    adjustmentType: AdjustmentTypes,
    adjustmentAmount: number | undefined,
    payrollReportUserSnapshot: IPayrollReportUserSnapshotDto | undefined,
    reimbursablePremiumId?: string
): Partial<IPayrollReportUserSnapshotDto> => {
    if (adjustmentType === AdjustmentTypes.ReimbursablePremium) {
        const reimbursablePremiums = payrollReportUserSnapshot?.reimbursablePremiums?.map((r) => {
            if (r.id === reimbursablePremiumId) {
                r.adjustment = adjustmentAmount;
            }
            return r;
        });
        return { ...payrollReportUserSnapshot, reimbursablePremiums };
    }

    return { ...payrollReportUserSnapshot, [getPatchProperty(adjustmentType)]: adjustmentAmount };
};

type IPayrollReportAdjustmentModal = {
    adjustmentType: AdjustmentTypes;
    adjustmentValue?: number;
    close: () => void;
    payrollReportUserSnapshot: IPayrollReportUserSnapshotDto | undefined;
    reimbursablePremiumId?: string;
};

const PayrollReportAdjustmentModal = ({
    adjustmentType,
    adjustmentValue: initialAdjustment,
    close,
    payrollReportUserSnapshot,
    reimbursablePremiumId,
}: IPayrollReportAdjustmentModal) => {
    const title = getTitle(adjustmentType);

    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION,
            EDIT_REIMBURSABLE_PREMIUM_ADJUSTMENT_ACTION
        ),
    }));

    const [adjustmentAmount, setAdjustmentAmount] = useState(initialAdjustment);
    const [note, setNote] = useState<string | undefined>(payrollReportUserSnapshot?.note ?? '');
    useEffect(() => {
        setNote(payrollReportUserSnapshot?.note);
    }, [payrollReportUserSnapshot?.note]);

    const noteRequired = isNoteRequired(
        getAdjustedSnapshot(
            adjustmentType,
            adjustmentAmount,
            payrollReportUserSnapshot,
            reimbursablePremiumId
        )
    );

    const schema = object({
        adjustmentAmount: number()
            .transform(stringToFloat)
            .label(title),
        note: string()
            .trim()
            .max(NOTE_MAX_LENGTH)
            .isRequiredWhen(noteRequired)
            .label('Member Note'),
    });
    const subtext = getNoteLastModifiedBySubText(payrollReportUserSnapshot);
    const { errors, validate } = useForm(schema);
    const onSubmit = async () => {
        const { isValid } = await validate({ adjustmentAmount, note });
        if (isValid) {
            let action;
            if (adjustmentType == AdjustmentTypes.ReimbursablePremium) {
                action = editReimbursablePremiumAdjustment(reimbursablePremiumId, {
                    note,
                    adjustment: adjustmentAmount,
                });
            } else {
                const patch: Partial<IPayrollReportUserSnapshot> = {
                    note,
                    [getPatchProperty(adjustmentType)]: adjustmentAmount,
                };

                action = patchPayrollReportUserSnapshot(
                    payrollReportUserSnapshot?.id,
                    patch,
                    `${title} Adjustment`
                );
            }

            await dispatch(action);
            close();
        }
    };

    return (
        <Dialog fullWidth onClose={close} open>
            <DialogTitle>{`${title} Adjustment & Note`}</DialogTitle>
            <DialogContent dividers>
                <Form id="edit-adjustment-modal-form" isLoading={isLoading} onSubmit={onSubmit}>
                    <Stack alignItems="center" gap={2}>
                        <CurrencyTextField
                            allowNegative
                            autoFocus
                            data-cy="adjustment-amount-field"
                            errors={errors?.adjustmentAmount}
                            label={title}
                            name="adjustmentAmount"
                            onChange={onChange(setAdjustmentAmount)}
                            placeholder={`Enter ${title} Adjustment`}
                            value={adjustmentAmount}
                        />
                        <TextArea
                            data-cy="adjustment-note-field"
                            errors={errors?.note}
                            isOptional={!noteRequired}
                            label="Member Note"
                            maxLength={NOTE_MAX_LENGTH}
                            name="note"
                            onChange={onChange(setNote)}
                            placeholder={`Add an explanation for why a ${title} adjustment is required`}
                            value={note}
                        />
                        {hasValue(subtext) && <div className="text-muted">{subtext}</div>}
                    </Stack>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="text">
                    Cancel
                </Button>
                <LoadingButton
                    form="edit-adjustment-modal-form"
                    loading={isLoading}
                    type="submit"
                    variant="text"
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default hot(module)(PayrollReportAdjustmentModal);
