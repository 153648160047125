import { GET_ACTIVE_QUESTIONS_ACTION } from 'actions/customQuestions/getActiveQuestions';
import TeamManagementContext from 'contexts/TeamManagementContext';
import StepIntro from 'pages/survey/StepIntro';
import React, { useContext, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const QualitativeIntro = ({
    onSkip,
    onSubmit,
}: {
    onSkip?: () => void;
    onSubmit: React.FormEventHandler;
}) => {
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { isLoadingQuestions } = useSelector((state: AppStore) => ({
        isLoadingQuestions: hasApiActivity(state, GET_ACTIVE_QUESTIONS_ACTION),
    }));

    const skipProps = useMemo(() => {
        if (isTeamManagementPage) {
            return {
                onSkip,
                skipText: 'Skip Qualitative Questions',
            };
        }
        return {};
    }, [isTeamManagementPage, onSkip]);
    return (
        <StepIntro
            {...skipProps}
            description="We've got a few more final questions for you to wrap up the analysis."
            isLoading={isLoadingQuestions}
            onSubmit={onSubmit}
            title="Just a few more benefits questions."
        />
    );
};

export default hot(module)(QualitativeIntro);
