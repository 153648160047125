import Typography from '@mui/material/Typography';
import {
    getEnrollmentInfo,
    GET_ENROLLMENT_INFO_ACTION,
} from 'actions/ccmController/getEnrollmentInfo';
import { clearTeam } from 'actions/clear';
import { getTeamProfile, GET_TEAM_PROFILE_ACTION } from 'actions/team/getTeamProfile';
import { downloadEnrollmentInfo } from 'api/downloadEnrollmentInfo';
import { EnrollmentStatuses, UserStatus } from 'api/generated/enums';
import { ICcmEnrollmentInfo, ICcmEnrollmentInfoHouseholdMember } from 'api/generated/models';
import Button from 'components/Button';
import {
    MemberStatusHeader,
    USER_STATUS_OPTIONS,
} from 'components/commonTableComponents/MemberStatusHeader';
import { COMMON_HEADER_DROPDOWN_TABLE_PROPS } from 'components/headerDropdown/HeaderDropdown';
import PageSectionWrapper from 'components/PageSectionWrapper';
import ReactTable from 'components/reactTable/ReactTable';
import Select from 'components/Select';
import TableHeader from 'components/TableHeader';
import { MINIMUM_DATE } from 'constants/date';
import { CCM_TEAM_ID } from 'constants/teams';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import {
    DateOfBirthCell,
    ProgramTypeHeader,
    PROGRAM_TYPE_OPTIONS,
    SocialSecurityNumberCell,
} from 'pages/enrollmentInfoPage/EnrollmentInfoCells';
import { EnrollmentStatusHeader, ENROLLMENT_STATUS_OPTIONS } from 'pages/people/PeopleTableCells';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Accessor, Cell, Column, Filters, FilterTypes, Row as RtRow } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasContents, hasValue } from 'utilities';
import {
    formatCurrency,
    formatDateForDisplay,
    formatPhoneNumber,
    formatYesOrNo,
} from 'utilities/format';
import { onChange } from 'utilities/forms';
import { getDateOrNull } from 'utilities/moment';
import { getColumnWidth } from 'utilities/reactTable';
import { downloadFileFromApi } from 'utilities/rhFile';
import { getYears } from 'utilities/year';

export const CENTER_COLUMN_PROPS = {
    className: 'justify-content-center',
    headerClassName: 'justify-content-center',
};

const MEMBER_STATUS_COLUMN_ID = 'memberStatus';
const MEMBER_STATUS_FILTER_ID = 'memberStatusFilter';
const ENROLLMENT_STATUS_COLUMN_ID = 'enrollmentStatus';
const ENROLLMENT_STATUS_FILTER_ID = 'enrollmentStatusFilter';
const PROGRAM_TYPE_COLUMN_ID = 'programTpe';
const PROGRAM_TYPE_FILTER_ID = 'programTypeFilter';
const GLOBAL_FILTER_ID = 'globalFilter';

const years = getYears(() => 1).map((x) => ({ name: `${x}` }));

const DateOfBirthAccessor: Accessor<ICcmEnrollmentInfo | ICcmEnrollmentInfoHouseholdMember> = ({
    dateOfBirth,
}) => getDateOrNull(dateOfBirth)?.toDate() ?? MINIMUM_DATE;

const filterTypes: FilterTypes<ICcmEnrollmentInfo> = {
    [ENROLLMENT_STATUS_FILTER_ID]: (rows, _, filterValue: string[]) =>
        rows.filter(
            ({ original: { enrollmentStatus } }) =>
                (enrollmentStatus === undefined && filterValue.includes('None')) ||
                (enrollmentStatus !== undefined &&
                    filterValue.includes(startCase(EnrollmentStatuses[enrollmentStatus])))
        ),
    [GLOBAL_FILTER_ID]: (rows, ids, filterValue: string) => {
        const searchParts = filterValue
            .split(',')
            .filter((x) => hasValue(x))
            .map((x) => x.toLowerCase());

        return rows.filter((row) =>
            ids.some((id) => {
                const value = String(row.values[id]).toLowerCase();
                return searchParts.some((s) => value.includes(s));
            })
        );
    },
    [MEMBER_STATUS_FILTER_ID]: (rows, _, filterValue: string[]) =>
        rows.filter(
            ({ original: { status } }) =>
                (status === undefined && filterValue.includes('None')) ||
                (status !== undefined && filterValue.includes(startCase(UserStatus[status])))
        ),
    [PROGRAM_TYPE_FILTER_ID]: (rows, _, filterValue: string[]) =>
        rows.filter(({ original: { programType } }) => filterValue.contains(programType)),
};

const initialFilters: Filters<ICcmEnrollmentInfo> = [
    {
        id: MEMBER_STATUS_COLUMN_ID,
        value: USER_STATUS_OPTIONS.filter((x) => x !== 'Inactive'),
    },
    {
        id: ENROLLMENT_STATUS_COLUMN_ID,
        value: ENROLLMENT_STATUS_OPTIONS,
    },
    { id: PROGRAM_TYPE_COLUMN_ID, value: PROGRAM_TYPE_OPTIONS },
];

const canRowExpand = ({ original }: RtRow<ICcmEnrollmentInfo>) =>
    hasContents(original.householdMembers);

const householdMemberColumns: Column<ICcmEnrollmentInfoHouseholdMember>[] = [
    {
        accessor: (x) => x.firstName,
        Header: 'First Name',
    },
    {
        accessor: (x) => x.lastName,
        Header: 'Last Name',
    },
    {
        Cell: SocialSecurityNumberCell,
        Header: 'SSN',
        id: 'socialSecurityNumber',
    },
    {
        accessor: DateOfBirthAccessor,
        Cell: DateOfBirthCell,
        Header: 'Date of Birth',
        id: 'dateOfBirth',
        sortType: 'datetime',
    },
    {
        accessor: (x) => x.gender,
        Header: 'Gender',
    },
];

const EnrollmentInfoPage = () => {
    const dispatch = useThunkDispatch();
    const [search, setSearch] = useState('');
    const [selectedYear, setSelectedYear] = useState(0);
    const [selection, setSelection] = useState<RtRow<ICcmEnrollmentInfo>[]>([]);
    const { data, isLoading, teamProfile } = useSelector((state: AppStore) => ({
        data: state.enrollmentInfo,
        isLoading: hasApiActivity(state, GET_TEAM_PROFILE_ACTION, GET_ENROLLMENT_INFO_ACTION),
        teamProfile: state.teamProfile,
    }));

    useEffect(() => {
        dispatch(getTeamProfile(CCM_TEAM_ID));
        return () => {
            dispatch(clearTeam());
        };
    }, [dispatch]);
    useEffect(() => {
        if (teamProfile?.team?.activeDate) {
            setSelectedYear(teamProfile.team.activeDate.getYear());
        }
    }, [teamProfile]);
    useEffect(() => {
        if (selectedYear > 0) {
            dispatch(getEnrollmentInfo(selectedYear));
        }
    }, [dispatch, selectedYear]);

    const onDownloadClick = useCallback(
        async () =>
            downloadFileFromApi(async () =>
                downloadEnrollmentInfo(
                    selectedYear,
                    selection.map(({ original }) => original.userId)
                )
            ),
        [selectedYear, selection]
    );

    const columns = useMemo(
        (): Column<ICcmEnrollmentInfo>[] => [
            {
                accessor: (x) => x.firstName,
                Header: 'First Name',
                width: 100,
            },
            {
                accessor: (x) => x.lastName,
                Header: 'Last Name',
                width: 100,
            },
            {
                ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
                Cell: ({
                    row: {
                        original: { status },
                    },
                }: Cell<ICcmEnrollmentInfo>) =>
                    hasValue(status) ? USER_STATUS_OPTIONS[status] : '',
                disableGlobalFilter: true,
                filter: MEMBER_STATUS_FILTER_ID,
                Header: MemberStatusHeader,
                id: MEMBER_STATUS_COLUMN_ID,
                width: 120,
            },
            {
                ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
                Cell: ({
                    row: {
                        original: { enrollmentStatus },
                    },
                }: Cell<ICcmEnrollmentInfo>) =>
                    hasValue(enrollmentStatus) ? ENROLLMENT_STATUS_OPTIONS[enrollmentStatus] : '',
                disableGlobalFilter: true,
                filter: ENROLLMENT_STATUS_FILTER_ID,
                Header: EnrollmentStatusHeader,
                id: ENROLLMENT_STATUS_COLUMN_ID,
                width: 140,
            },
            {
                accessor: (x) => getDateOrNull(x.activeDate)?.toDate() ?? MINIMUM_DATE,
                Cell: ({
                    row: {
                        original: { activeDate },
                    },
                }: Cell<ICcmEnrollmentInfo, string>) => formatDateForDisplay(activeDate) ?? '',
                disableGlobalFilter: true,
                Header: 'Active Date',
                id: 'activeDate',
                sortType: 'datetime',
                width: 80,
            },
            {
                Cell: SocialSecurityNumberCell,
                Header: 'SSN',
                id: 'socialSecurityNumber',
                width: 100,
            },
            {
                accessor: DateOfBirthAccessor,
                Cell: DateOfBirthCell,
                Header: 'Date of Birth',
                id: 'dateOfBirth',
                sortType: 'datetime',
                width: 110,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: (x) => x.gender,
                disableGlobalFilter: true,
                Header: 'Gender',
                width: 55,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: (row) => formatYesOrNo(row.isMarried),
                disableGlobalFilter: true,
                Header: 'Is Married?',
                id: 'isMarried',
                width: 80,
            },
            {
                ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
                accessor: (x) => x.programType,
                disableGlobalFilter: true,
                filter: PROGRAM_TYPE_FILTER_ID,
                Header: ProgramTypeHeader,
                id: PROGRAM_TYPE_COLUMN_ID,
                width: 120,
            },
            {
                accessor: (row) => formatYesOrNo(row.isPrimaryCovered),
                disableGlobalFilter: true,
                Header: 'Is Primary Covered?',
                id: 'isPrimaryCovered',
                width: 80,
            },
            {
                accessor: (row) => Number(row.ahp),
                Cell: ({ value }: Cell<ICcmEnrollmentInfo, number>) =>
                    formatCurrency(value, { emptyIfNaN: true }),
                Header: 'AHP',
                id: 'ahp',
                width: 60,
            },
            {
                accessor: (x) => getDateOrNull(x.programEffectiveDate)?.toDate() ?? MINIMUM_DATE,
                Cell: ({
                    row: {
                        original: { programEffectiveDate },
                    },
                }: Cell<ICcmEnrollmentInfo, string>) =>
                    formatDateForDisplay(programEffectiveDate) ?? '',
                disableGlobalFilter: true,
                Header: 'Program Effective Date',
                id: 'programEffectiveDate',
                sortType: 'datetime',
                width: 80,
            },
            {
                accessor: (row) => Number(row.premium),
                Cell: ({ value }: Cell<ICcmEnrollmentInfo, number>) =>
                    formatCurrency(value, { emptyIfNaN: true, preserveDecimal: true }),
                disableGlobalFilter: true,
                Header: 'Cost',
                id: 'premium',
                width: 70,
            },
            {
                accessor: (x) => x.email,
                Header: 'Email',
                width: getColumnWidth(data, 'email', 'Email'),
            },
            {
                accessor: (row) => formatPhoneNumber(row.phone),
                disableGlobalFilter: true,
                Header: 'Phone',
                id: 'phone',
                width: 110,
            },
            {
                accessor: (x) => x.addressLine1,
                disableGlobalFilter: true,
                Header: 'Address Line 1',
                width: 130,
            },
            {
                accessor: (x) => x.addressLine2,
                disableGlobalFilter: true,
                Header: 'Address Line 2',
                width: 80,
            },
            {
                accessor: (x) => x.city,
                disableGlobalFilter: true,
                Header: 'City',
                width: 90,
            },
            {
                accessor: (x) => x.state,
                disableGlobalFilter: true,
                Header: 'State',
                width: 45,
            },
            {
                accessor: (x) => x.zip,
                disableGlobalFilter: true,
                Header: 'Zip',
                width: 60,
            },
            {
                accessor: (x) => x.county,
                disableGlobalFilter: true,
                Header: 'County',
                width: 120,
            },
        ],
        [data]
    );

    const getHouseholdMemberTable = useCallback(
        ({ original }: RtRow<ICcmEnrollmentInfo>) => (
            <div className="pl-4">
                <Typography variant="h6">Household Members using Medi-Share</Typography>
                <ReactTable
                    columns={householdMemberColumns}
                    data={original.householdMembers ?? []}
                    isPaginationEnabled={false}
                    loading={isLoading}
                />
            </div>
        ),
        [isLoading]
    );

    return (
        <PageSectionWrapper>
            <Row>
                <TableHeader onChange={onChange(setSearch)} search={search}>
                    <Select
                        data-cy="selected-people-page-year"
                        items={years}
                        name="selectedYear"
                        onChange={onChange(setSelectedYear)}
                        optionText="name"
                        optionValue="name"
                        sx={{ ml: 2 }}
                        value={selectedYear}
                    />
                    <Button
                        color="secondary"
                        disabled={selection.length === 0}
                        onClick={onDownloadClick}
                        variant="text"
                    >
                        Download ({selection.length})
                    </Button>
                </TableHeader>
            </Row>
            <ReactTable
                canRowExpand={canRowExpand}
                columns={columns}
                data={data}
                filterTypes={filterTypes}
                getSubComponent={getHouseholdMemberTable}
                globalFilter={search}
                globalFilterType={GLOBAL_FILTER_ID}
                initialFilters={initialFilters}
                isRowExpandingEnabled
                isRowSelectionEnabled
                loading={isLoading}
                onSelectedRowsChanged={setSelection}
            />
        </PageSectionWrapper>
    );
};

export default hot(module)(EnrollmentInfoPage);
