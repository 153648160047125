import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import { sendBenefitsSelectedEmails } from 'actions/user/sendBenefitsSelectedEmail';
import {
    EnrollmentStatuses,
    PathwayTypes,
    RenewalDecisions,
    UserStatus,
} from 'api/generated/enums';
import { IPathwayUserDto, ISelectedPlanDto } from 'api/generated/models';
import { CreateSelectedPlans } from 'api/generated/permissions';
import Button from 'components/Button';
import CalendlyModalButton from 'components/calendly/CalendlyModalButton';
import ShopHeaderContainer from 'components/ShopHeaderContainer';
import { CCM_TEAM_ID } from 'constants/teams';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useCallback, useContext } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { MY_BENEFITS_PATH, TEAMS_MY_BENEFITS_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import { getIsStateBasedExchange, isMediShare } from 'utilities/selectedPlan';

type ISelectionHeaderProps = {
    shopPath: string;
};

const getPathwayDecisionYearlyInfo = (
    selectedPlans: ISelectedPlanDto[] | undefined,
    { pathwayType, planId, useRestrategizedRenewalFlow }: IPathwayUserDto,
    status: UserStatus | undefined
) => {
    if (pathwayType === PathwayTypes.Flat || pathwayType === PathwayTypes.IchraFlat) {
        return {};
    }
    const pathwayPlanIsSelectedPlan =
        selectedPlans?.every((p) => p.planId === planId) && selectedPlans?.length === 1;
    const renewalDecision = pathwayPlanIsSelectedPlan
        ? RenewalDecisions.AcceptedNewPathway
        : RenewalDecisions.ChoseNonPathwayPlan;
    return status === UserStatus.Renewing && useRestrategizedRenewalFlow
        ? { RenewalDecision: renewalDecision }
        : { acceptedPathway: pathwayPlanIsSelectedPlan };
};

const SelectionHeader = ({ shopPath }: ISelectionHeaderProps) => {
    const dispatch = useThunkDispatch();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { calendlyAdvisementLink, teamId, sbeEnrollmentCalendlyLink } = useTeamProps();
    const { isCurrent, user, userId } = useUserProps();
    const {
        canCreateSelectedPlans,
        pathwayUser,
        selectedPlansForShopping,
        shouldForceAdvisementForSbe,
    } = useSelector((state: AppStore) => ({
        canCreateSelectedPlans: hasSomePermissions(state, CreateSelectedPlans),
        pathwayUser: state.pathwayUser,
        selectedPlansForShopping: state.selectedPlansForReview.selectedPlans,
        shouldForceAdvisementForSbe: state.marketplaceConfig.shouldForceAdvisementForSbe,
    }));
    const setUserEnrollmentStatus = useCallback(
        (successMessage: JSX.Element | string, enrollmentStatus: EnrollmentStatuses) => {
            dispatch(
                patchYearlyUserInfo(
                    userId,
                    user?.activeDate?.getYear(),
                    {
                        ...getPathwayDecisionYearlyInfo(
                            selectedPlansForShopping,
                            pathwayUser,
                            user?.status
                        ),
                        enrollmentStatus: { value: enrollmentStatus },
                    },
                    isCurrent,
                    {
                        redirect: isTeamManagementPage
                            ? generatePath(TEAMS_MY_BENEFITS_PATH, { teamId, userId })
                            : MY_BENEFITS_PATH,
                        toastErrorMessage: 'Unable to select plan.',
                        toastSuccessMessage: successMessage,
                    }
                )
            );
        },
        [
            dispatch,
            isCurrent,
            isTeamManagementPage,
            pathwayUser,
            selectedPlansForShopping,
            teamId,
            user?.activeDate,
            user?.status,
            userId,
        ]
    );
    const handleConfirm = () =>
        setUserEnrollmentStatus(
            <div>
                <Typography variant="h5">Benefits Selections Confirmed!</Typography>
                <span>
                    The benefits selections for this member have been confirmed and are ready for
                    application.
                </span>
            </div>,
            EnrollmentStatuses.PendingApplication
        );
    const handleSubmitForAdvisement = useCallback(() => {
        const mediShareProgramsOnly = selectedPlansForShopping?.every(isMediShare);
        const isCcmMember = teamId.toLowerCase() === CCM_TEAM_ID.toLowerCase();
        setUserEnrollmentStatus(
            <div>
                <Typography variant="h5">Benefits Selections Submitted!</Typography>
                <span>
                    {isCcmMember && mediShareProgramsOnly
                        ? 'Your administrator will reach out to you on next steps.'
                        : 'Our licensed advisors will review your selection and notify you when your plan has been approved and formally submitted for enrollment.'}
                </span>
            </div>,
            EnrollmentStatuses.PendingApplication
        );
        dispatch(sendBenefitsSelectedEmails(userId));
    }, [dispatch, selectedPlansForShopping, setUserEnrollmentStatus, teamId, userId]);

    const handleAdvisementScheduled = () =>
        setUserEnrollmentStatus(
            <div>
                <Typography variant="h5">Benefits Enrollment Almost Complete!</Typography>
                <span>
                    Our licensed advisor will complete your enrollment at the scheduled time.
                </span>
            </div>,
            EnrollmentStatuses.AdvisementScheduled
        );

    const isShoppingForSomeone = isTeamManagementPage && canCreateSelectedPlans;
    const buttonText = isShoppingForSomeone ? 'Confirm' : 'Submit For Review';
    const hasSbe = selectedPlansForShopping?.some((x) => getIsStateBasedExchange(x));
    const handleButtonClick = isShoppingForSomeone ? handleConfirm : handleSubmitForAdvisement;

    let button;
    if (!isShoppingForSomeone && hasSbe && shouldForceAdvisementForSbe) {
        const calendlyLink = !hasValue(sbeEnrollmentCalendlyLink)
            ? calendlyAdvisementLink
            : sbeEnrollmentCalendlyLink;
        button = (
            <CalendlyModalButton
                buttonLabel={buttonText}
                calendlyLinkOverride={calendlyLink}
                color="secondary"
                onAdvisementScheduled={handleAdvisementScheduled}
                variant="outlined"
            ></CalendlyModalButton>
        );
    } else {
        button = (
            <Button
                className="text-white"
                color="primary"
                data-cy="selection-submit"
                onClick={handleButtonClick}
                variant="contained"
            >
                {buttonText}
            </Button>
        );
    }

    return (
        <ShopHeaderContainer
            leftSide={
                <Grid alignItems="center" container item>
                    <Button
                        className="mr-2"
                        data-cy="back-to-shopping"
                        link={{ to: shopPath }}
                        variant="outlined"
                    >
                        Back to Shopping
                    </Button>
                    <Header>
                        {isCurrent && 'My '}Selections ({selectedPlansForShopping?.length})
                    </Header>
                </Grid>
            }
            rightSide={
                <Grid
                    item
                >
                    {button}
                </Grid>
            }
        />
    );
};

const Header = styled.p.attrs(() => ({ className: 'p-0 m-0' }))`
    font-size: 16px;
    font-weight: 600;
`;

export default hot(module)(SelectionHeader);
