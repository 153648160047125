import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import {
    UPLOAD_SUBMITTED_EXPENSES_ACTION,
    uploadSubmittedExpenses,
} from 'actions/submittedExpenses/uploadSubmittedExpenses';
import { downloadImportExpensesTemplate } from 'api/downloadSubmittedExpensesTemplate';
import Button from 'components/Button';
import Form from 'components/Form';
import FileInput from 'components/fileInput/FileInput';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { downloadFileFromApi } from 'utilities/rhFile';

const UploadSubmittedExpensesModal = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, UPLOAD_SUBMITTED_EXPENSES_ACTION),
    }));
    const [submittedExpenseFiles, setSubmittedExpenseFiles] = useState<File | File[] | undefined>();
    const newFileData: File[] = (submittedExpenseFiles as File[]) ?? [];

    const onSubmit = useCallback(async () => {
        await dispatch(uploadSubmittedExpenses(submittedExpenseFiles as File));
        onClose();
    }, [onClose, submittedExpenseFiles, dispatch]);

    const downloadSubmittedExpensesTemplateFromApi = useCallback(
        async () => downloadFileFromApi(downloadImportExpensesTemplate),
        []
    );

    return (
        <Dialog fullWidth maxWidth="sm" onClose={onClose} open>
            <DialogTitle>Import Submitted Expenses</DialogTitle>
            <DialogContent dividers>
                <Form
                    id="upload-submitted-expenses-modal-form"
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                >
                    <Stack gap={2} spacing={4}>
                        <FileInput
                            accept={['.csv']}
                            buttonLabel="Select File"
                            disabled={isLoading}
                            fileData={newFileData}
                            onChange={setSubmittedExpenseFiles}
                        />
                        <a
                            className="text-primary"
                            download="SubmittedExpensesTemplate.csv"
                            onClick={downloadSubmittedExpensesTemplateFromApi}
                        >
                            Download Submitted Expenses Template
                        </a>
                    </Stack>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text">
                    Close
                </Button>
                <Button
                    disabled={!hasValue(submittedExpenseFiles)}
                    form="upload-submitted-expenses-modal-form"
                    isLoading={isLoading}
                    type="submit"
                    variant="text"
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(UploadSubmittedExpensesModal);
