import { Card, CardContent } from '@mui/material';
import { getSharedResources } from 'actions/resources/getSharedResources';
import { IResource } from 'api/generated/models';
import ResourceCards from 'components/ResourceCards';
import useThunkDispatch from 'hooks/useThunkDispatch';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { openNewTab } from 'utilities/route';

const ResourcesWidget = () => {
    const dispatch = useThunkDispatch();
    useEffect(() => {
        dispatch(getSharedResources());
    }, [dispatch]);
    const onViewContent = (resource: IResource | undefined) => () => openNewTab(resource?.url);
    return (
        <Card>
            <CardContent>
                <DashboardCardHeader header="Resources" />
                <hr />
                <ResourceCards onClick={onViewContent} xl="12" />
            </CardContent>
        </Card>
    );
};

export default hot(module)(ResourcesWidget);
