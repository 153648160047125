import { TeamStateIds } from 'api/generated/enums';
import { ViewPathwayBlueprints } from 'api/generated/permissions';
import useTeamProps from 'hooks/useTeamProps';
import isEmpty from 'lodash/isEmpty';
import AlertInfoCard, { IAlert } from 'pages/dashboard/AlertInfoCard';
import BeginRenewalPeriod from 'pages/dashboard/BeginRenewalPeriod';
import { archiveAlert } from 'pages/dashboard/DashboardPage';
import EndRenewalPeriod from 'pages/dashboard/EndRenewalPeriod';
import HandoffToCustomerSuccess from 'pages/dashboard/HandoffToCustomerSuccess';
import HandoffToLaunch from 'pages/dashboard/HandoffToLaunch';
import RhLaunchTaskList from 'pages/dashboard/taskLists/RhLaunchTaskList';
import RhTeamMemberReadyForMemberLaunchTaskList from 'pages/dashboard/taskLists/RhTeamMemberReadyForMemberLaunchTaskList';
import React, { ReactNode, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';

// eslint-disable-next-line react/display-name
const RhLaunchTaskListWithAlerts = ({ teamId }: { teamId: string }) => (alerts: IAlert[]) => (
    <div>
        {!isEmpty(alerts) && <AlertInfoCard alerts={alerts} />}
        <RhLaunchTaskList teamId={teamId} />
    </div>
);

// eslint-disable-next-line react/display-name
const ReadyForMemberLaunchWithAlerts = (teamId: string) => (alerts: IAlert[]) => (
    <div>
        {!isEmpty(alerts) && <AlertInfoCard alerts={alerts} />}
        <RhTeamMemberReadyForMemberLaunchTaskList teamId={teamId} />
    </div>
);

// eslint-disable-next-line react/display-name
const MemberLaunchWithAlerts = (teamId: string) => (alerts: IAlert[]) => {
    alerts.push({
        cardBody: 'Members are setting up their accounts and shopping for benefits.',
        cardHeader: 'Team Status: Member Launch',
    });
    return (
        <div>
            <AlertInfoCard alerts={alerts} />
            <HandoffToCustomerSuccess teamId={teamId} />
        </div>
    );
};

// eslint-disable-next-line react/display-name
const ProspectWithAlerts = (teamId: string, canViewPathwayBlueprints: boolean) => (
    alerts: IAlert[]
) => {
    alerts.push({
        cardBody: "We're gathering team information and working on a quote.",
        cardHeader: 'Team Status: Prospect',
    });
    return (
        <div>
            <AlertInfoCard alerts={alerts} />
            {canViewPathwayBlueprints && <HandoffToLaunch teamId={teamId} />}
        </div>
    );
};

// eslint-disable-next-line react/display-name
const EndRenewalWithAlerts = (teamId: string) => (alerts: IAlert[]) => {
    alerts.push({
        cardBody: 'The Team is going through the renewal process.',
        cardHeader: 'Team Status: Renewing',
    });
    return (
        <div>
            <AlertInfoCard alerts={alerts} />
            <EndRenewalPeriod teamId={teamId} />
        </div>
    );
};

// eslint-disable-next-line react/display-name
const CustomerWithAlerts = () => (alerts: IAlert[]) => {
    alerts.push({
        cardBody: 'The Team is an active customer of Remodel Health.',
        cardHeader: 'Team Status: Customer',
    });
    return (
        <div>
            <AlertInfoCard alerts={alerts} />
            <BeginRenewalPeriod />
        </div>
    );
};

type ITeamStateContent = {
    [key in keyof typeof TeamStateIds]?: (alerts: IAlert[]) => IAlert[] | ReactNode;
};
const TeamManagementContent = () => {
    const { isTeamArchived, teamId, teamStateId } = useTeamProps();
    const { canViewPathwayBlueprints } = useSelector((state: AppStore) => ({
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
    }));
    const teamStateContent = useMemo(
        (): ITeamStateContent => ({
            [TeamStateIds.Prospect]: ProspectWithAlerts(teamId, canViewPathwayBlueprints),
            [TeamStateIds.Proposal]: (alerts: IAlert[]) => {
                alerts.push({
                    cardBody: 'A proposal has been sent to the team admin for review.',
                    cardHeader: 'Team Status: Proposal',
                });
                return alerts;
            },
            [TeamStateIds.RhLaunch]: RhLaunchTaskListWithAlerts({
                teamId,
            }),
            [TeamStateIds.TeamAdminLaunch]: (alerts: IAlert[]) => {
                alerts.push({
                    cardBody:
                        'The Team Admin is reviewing the launch details and verifying team info.',
                    cardHeader: 'Team Status: Team Admin Launch',
                });
                return alerts;
            },
            [TeamStateIds.ReadyForMemberLaunch]: ReadyForMemberLaunchWithAlerts(teamId),
            [TeamStateIds.MemberLaunch]: MemberLaunchWithAlerts(teamId),
            [TeamStateIds.Customer]: CustomerWithAlerts(),
            [TeamStateIds.Renewing]: EndRenewalWithAlerts(teamId),
        }),
        [canViewPathwayBlueprints, teamId]
    );
    return useMemo(() => {
        const initialAlerts: IAlert[] = [];
        if (isTeamArchived) {
            initialAlerts.push(archiveAlert);
        }
        const alerts = teamStateContent[teamStateId]?.(initialAlerts);
        if (Array.isArray(alerts)) {
            return <AlertInfoCard alerts={alerts as IAlert[]} />;
        }
        return alerts;
    }, [isTeamArchived, teamStateContent, teamStateId]) as JSX.Element;
};

export default hot(module)(TeamManagementContent);
