import { AppStoreThunkDispatch } from 'actions/commonAction';
import { addUserNote } from 'actions/userNote/addUserNote';
import { deleteUserNote } from 'actions/userNote/deleteUserNote';
import { editUserNote } from 'actions/userNote/editUserNote';
import { getUserNotes } from 'actions/userNote/getUserNotes';
import { toggleArchiveUserNote } from 'actions/userNote/toggleArchiveUserNote';
import { INote, Note as NoteModel } from 'api/generated/models';
import {
    ArchiveUserNote,
    CreateUserNote,
    DeleteUserNote,
    EditUserNote,
} from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import Checkbox from 'components/Checkbox';
import Note from 'components/note/Note';
import NoteModal from 'components/note/NoteModal';
import { hideNoteModal, showNoteModal } from 'components/note/notesActions';
import PageSectionWrapper from 'components/PageSectionWrapper';
import TableHeader from 'components/TableHeader';
import UserNameEntityHeader from 'components/UserNameEntityHeader';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const UserNotesPage = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const {
        canArchiveNotes,
        canCreateNote,
        canDeleteNotes,
        canEditNotes,
        noteToEdit,
        userNotes,
    } = useSelector((state: AppStore) => ({
        canArchiveNotes: state.login.up.includes(ArchiveUserNote),
        canCreateNote: state.login.up.includes(CreateUserNote),
        canDeleteNotes: state.login.up.includes(DeleteUserNote),
        canEditNotes: state.login.up.includes(EditUserNote),
        noteToEdit: state.notesState.noteToEdit,
        userNotes: state.userNotes,
    }));
    const [showArchivedNotes, setShowArchivedNotes] = useState(false);
    useEffect(() => {
        dispatch(getUserNotes(userId));
    }, [dispatch, userId]);

    const closeNoteModal = useCallback(() => dispatch(hideNoteModal()), [dispatch]);
    const items = useMemo(
        () =>
            [
                {
                    isVisible: canCreateNote,
                    onClick: () => dispatch(showNoteModal({} as INote)),
                    text: 'Add Note',
                },
            ] as IActionButtonItems,
        [canCreateNote, dispatch]
    );
    const [totalArchivedNotes, totalActiveNotes] = useMemo(
        () => partition(userNotes, (x) => x.isArchived).map((x) => x.length),
        [userNotes]
    );
    const selectedNotes = useMemo(
        () => (showArchivedNotes ? totalArchivedNotes : totalActiveNotes),
        [showArchivedNotes, totalActiveNotes, totalArchivedNotes]
    );
    const notesToRender = useMemo(
        () =>
            orderBy(
                userNotes.filter(
                    (x) =>
                        (showArchivedNotes && x.isArchived) || (!showArchivedNotes && !x.isArchived)
                ),
                ['createdDate'],
                ['desc']
            ).map((note, index) => (
                <Note
                    canArchiveNotes={canArchiveNotes}
                    canDeleteNotes={canDeleteNotes}
                    deleteNote={deleteUserNote}
                    key={index}
                    note={note}
                    toggleArchiveNote={toggleArchiveUserNote}
                />
            )),
        [userNotes, showArchivedNotes, canArchiveNotes, canDeleteNotes]
    );
    const label = useMemo(() => `Showing ${selectedNotes} Note${selectedNotes !== 1 ? 's' : ''}`, [
        selectedNotes,
    ]);
    useEffect(() => {
        if (showArchivedNotes && totalArchivedNotes === 0) {
            setShowArchivedNotes(false);
        }
    }, [showArchivedNotes, totalArchivedNotes]);
    const addOrEditNote = useCallback(
        (
            apiMethod: (
                note: NoteModel
            ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>
        ) => (note: NoteModel) => apiMethod(new NoteModel({ ...note, userId })),
        [userId]
    );
    return (
        <React.Fragment>
            {noteToEdit && (
                <NoteModal
                    addNote={addOrEditNote(addUserNote)}
                    canEditNotes={canEditNotes}
                    editNote={addOrEditNote(editUserNote)}
                    note={noteToEdit}
                    onClose={closeNoteModal}
                />
            )}
            <PageSectionWrapper>
                <TableHeader
                    items={items}
                    label={
                        <span>
                            <Row className="align-items-center">
                                <Col className="text-left">
                                    <UserNameEntityHeader entity="Notes" />
                                    <span>{label}</span>
                                </Col>
                                {canArchiveNotes && Number(totalArchivedNotes) > 0 && (
                                    <Checkbox
                                        checked={showArchivedNotes}
                                        className="m-0 ml-3"
                                        label={`View Archived (${totalArchivedNotes})`}
                                        onClick={() => setShowArchivedNotes(!showArchivedNotes)}
                                    />
                                )}
                            </Row>
                        </span>
                    }
                />
            </PageSectionWrapper>

            <Row>{notesToRender}</Row>
        </React.Fragment>
    );
};

export default hot(module)(UserNotesPage);
