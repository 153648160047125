import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { GroupLevels, MedicalReimbursementTypes } from 'api/generated/enums';
import { IMemberReimbursementUtilizationDto } from 'api/generated/models';
import { EditSubmittedExpense } from 'api/generated/permissions';
import Typography from 'components/Typography';
import { ExpenseTypeIds } from 'constants/reimbursementProgramExpenseTypes';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { formatCurrency } from 'utilities/format';

const FAMILY_HSA_ELIGIBILITY = 2;

const UtilizationContainer = ({ children }: { children: React.ReactNode }) => (
    <Stack alignItems="center" direction="row" justifyContent="space-between" minWidth="400px">
        {children}
    </Stack>
);

const UtilizationField = ({
    name,
    value,
}: {
    name: string | undefined;
    value: number | undefined;
}) => (
    <UtilizationContainer>
        <Typography
            data-cy={`${name
                ?.split(' ')
                .join('-')
                .toLowerCase()}`}
            mb={0}
            mt={1}
            variant="h5"
        >
            {name}:
        </Typography>
        <Typography fontWeight="bold" mb={0} mt={1} variant="h5">
            {formatCurrency(value, { preserveDecimal: true })}
        </Typography>
    </UtilizationContainer>
);

const ExpenseTypeUtilization = ({
    utilization,
    title,
    index = 0,
}: {
    index?: number;
    title: string | undefined;
    utilization: IMemberReimbursementUtilizationDto;
}) => {
    const { canEditSubmittedExpense } = useSelector((state: AppStore) => ({
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
    }));
    const isEE = utilization.coverageLevelId === GroupLevels.EE;
    const isEmbedded = utilization.medicalReimbursementType === MedicalReimbursementTypes.Embedded;
    const isFamilyEmbedded = !isEE && isEmbedded;
    const showHsaFields = utilization.hasHsaUtilizations || utilization.useHsaReimbursement;
    const isFamilyHsaPlan =
        showHsaFields && utilization.hsaContributionLimitEligibility === FAMILY_HSA_ELIGIBILITY;

    switch (utilization.expenseTypeId) {
        case ExpenseTypeIds.Medical:
            return (
                <React.Fragment key={utilization.expenseTypeId}>
                    {index !== 0 && (
                        <Stack mb={1} mt={1}>
                            <Divider />
                        </Stack>
                    )}
                    <Typography mb={1} mt={1} variant="h4">
                        {title}
                    </Typography>
                    <Stack direction="row" justifyContent="flex-start" spacing={12}>
                        <Stack>
                            {(isEE || isFamilyEmbedded) && (
                                <React.Fragment>
                                    <UtilizationField
                                        name="Individual Responsibility"
                                        value={utilization.individualMedicalResponsibility}
                                    />
                                    <UtilizationField
                                        name="Individual Reimbursement Amount"
                                        value={utilization.individualMedicalReimbursementAmount}
                                    />
                                </React.Fragment>
                            )}
                            {isFamilyEmbedded && <br />}
                            {!isEE && (
                                <React.Fragment>
                                    <UtilizationField
                                        name="Family Responsibility"
                                        value={utilization.familyMedicalResponsibility}
                                    />
                                    <UtilizationField
                                        name="Family Reimbursement Amount"
                                        value={utilization.familyMedicalReimbursementAmount}
                                    />
                                </React.Fragment>
                            )}
                            {isFamilyHsaPlan && <br />}
                            {showHsaFields && (
                                <React.Fragment>
                                    <Stack mb={1} mt={1}>
                                        <Divider />
                                    </Stack>
                                    <UtilizationField
                                        name="Total HSA Reimbursements"
                                        value={utilization.totalHsaReimbursements}
                                    />
                                </React.Fragment>
                            )}
                            {showHsaFields && canEditSubmittedExpense && (
                                <React.Fragment>
                                    <UtilizationField
                                        name={`HSA Contribution Limit ${
                                            isFamilyHsaPlan ? '(Family)' : '(Individual)'
                                        }`}
                                        value={utilization.hsaContributionLimitValue}
                                    />
                                </React.Fragment>
                            )}
                        </Stack>
                        <Stack>
                            {isEE && (
                                <React.Fragment>
                                    <UtilizationField
                                        name="Individual Reimbursement Used"
                                        value={utilization.individualReimbursementUsed}
                                    />
                                    <UtilizationField
                                        name="Individual Reimbursement Remaining"
                                        value={utilization.individualReimbursementRemaining}
                                    />
                                </React.Fragment>
                            )}
                            {!isEE && (
                                <React.Fragment>
                                    <UtilizationField
                                        name="Family Reimbursement Used"
                                        value={utilization.familyReimbursementUsed}
                                    />
                                    <UtilizationField
                                        name="Family Reimbursement Remaining"
                                        value={utilization.familyReimbursementRemaining}
                                    />
                                </React.Fragment>
                            )}
                            {isFamilyEmbedded && (
                                <React.Fragment>
                                    <br />
                                    <Typography mb={0} mt={1} variant="h5">
                                        Household Member Reimbursement Used:
                                    </Typography>
                                    <Typography
                                        mb={0}
                                        mt={0}
                                        variant="h6"
                                    >{`The maximum individual reimbursement amount is ${formatCurrency(
                                        utilization.individualMedicalReimbursementAmount,
                                        { preserveDecimal: true }
                                    )}`}</Typography>
                                    {utilization.householdMemberReimbursementUtilizations?.map(
                                        (hhm) => (
                                            <React.Fragment key={hhm.name}>
                                                <UtilizationField
                                                    name={hhm.name}
                                                    value={hhm.reimbursementUsed}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </Stack>
                    </Stack>
                </React.Fragment>
            );
        case ExpenseTypeIds.Counseling:
        case ExpenseTypeIds.BiblicalAndMarriageCounseling:
            return (
                <React.Fragment key={utilization.expenseTypeId}>
                    {index !== 0 && (
                        <Stack mb={1} mt={1}>
                            <Divider />
                        </Stack>
                    )}
                    <Typography mb={1} mt={1} variant="h4">
                        {title}
                    </Typography>
                    <UtilizationContainer>
                        <UtilizationContainer>
                            <Typography mb={0} mt={1} variant="h5">
                                Visits Used:
                            </Typography>
                            <Typography mb={0} mt={1} variant="h5">
                                <b>{utilization.counselingUsedVisits}</b> out of{' '}
                                <b>{utilization.counselingMaximumVisits}</b>
                            </Typography>
                        </UtilizationContainer>
                        <Stack></Stack>
                    </UtilizationContainer>
                    <UtilizationContainer>
                        <UtilizationContainer>
                            <Typography mb={0} mt={1} variant="h5">
                                Maximum Reimbursement:
                            </Typography>
                            <Typography mb={0} mt={1} variant="h5">
                                {' '}
                                <b>
                                    {formatCurrency(
                                        utilization.counselingPerVisitReimbursementAmount,
                                        { preserveDecimal: true }
                                    )}
                                </b>{' '}
                                per visit
                            </Typography>
                        </UtilizationContainer>
                        <Stack></Stack>
                    </UtilizationContainer>
                </React.Fragment>
            );
        default:
            return (
                <React.Fragment key={utilization.expenseTypeId}>
                    {index !== 0 && (
                        <Stack mb={1} mt={1}>
                            <Divider />
                        </Stack>
                    )}
                    <Typography mb={1} mt={1} variant="h4">
                        {title}
                    </Typography>
                    <Stack direction="row" justifyContent="flex-start" spacing={12}>
                        <Stack>
                            <UtilizationField
                                name="Reimbursement Used"
                                value={utilization.defaultReimbursementUsed}
                            />
                            <UtilizationField
                                name="Maximum Reimbursement"
                                value={utilization.defaultMaximumReimbursement}
                            />
                        </Stack>
                    </Stack>
                    <Stack></Stack>
                </React.Fragment>
            );
    }
};

export default hot(module)(ExpenseTypeUtilization);
