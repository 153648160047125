import Stack from '@mui/material/Stack';
import {
    setActiveWageUpNote,
    SET_ACTIVE_WAGEUP_NOTE_ACTION,
} from 'actions/pathwayBlueprint/setActiveWageUpNote';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import TextArea from 'components/TextArea';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { closeCustomActiveWageUpModal } from 'pages/pathwayBlueprint/customActiveWageUpModalActions';
import { IPathwayRowData } from 'pages/pathwayBlueprint/PathwayTableCells';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

const NOTE_MAX_LENGTH = 600;
const schema = object({
    note: string()
        .trim()
        .required()
        .max(NOTE_MAX_LENGTH)
        .label('Comment'),
});

const CustomActiveWageUpModal = () => {
    const dispatch = useThunkDispatch();
    const { isLoading, pathwayUser } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, SET_ACTIVE_WAGEUP_NOTE_ACTION),
        pathwayUser: state.customActiveWageUpModalState.pathwayUser,
    }));
    const activeWageUp = (pathwayUser as IPathwayRowData).activeWageUp;

    const [note, setNote] = useState(activeWageUp?.note || '');
    const { errors, validate } = useForm(schema);

    const onClose = useCallback(() => {
        dispatch(closeCustomActiveWageUpModal());
    }, [dispatch]);
    const save = useCallback(async () => {
        const { isValid } = await validate({ note });
        if (isValid) {
            await dispatch(setActiveWageUpNote(activeWageUp?.id, note));
            onClose();
        }
    }, [validate, note, dispatch, activeWageUp?.id, onClose]);

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Edit Active Wage+</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="edit-active-wage-up-modal-form" isLoading={isLoading} onSubmit={save}>
                    <Stack gap={2}>
                        <CurrencyTextField
                            autoFocus
                            decimalScale={0}
                            disabled
                            label="Active Wage+"
                            placeholder="Enter Active Wage+"
                            value={activeWageUp?.wageUp}
                        />
                        <TextArea
                            errors={errors?.note}
                            label="Comment"
                            maxLength={NOTE_MAX_LENGTH}
                            name="note"
                            onChange={onChange(setNote)}
                            placeholder="Add an explanation for why a custom active Wage+ is required"
                            value={note}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Col>
                    <Row className="justify-content-center">
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            className="pl-2"
                            form="edit-active-wage-up-modal-form"
                            isLoading={isLoading}
                            type="submit"
                        >
                            Save
                        </Button>
                    </Row>
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(CustomActiveWageUpModal);
