import { CreatePathway } from 'api/generated/permissions';
import NoRowsMessage from 'components/reactTable/NoRowsMessage';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';

const NoPathwaysMessage = ({ onClick }: { onClick: () => void }) => {
    const { canAddPathway } = useSelector((state: AppStore) => ({
        canAddPathway: hasSomePermissions(state, CreatePathway),
    }));
    return (
        <NoRowsMessage
            actionText="Create some using the actions dropdown above or the button below."
            buttonText="Add Pathway"
            canTakeAction={canAddPathway}
            onAction={onClick}
            text="There are no Pathways to assign members to."
        />
    );
};
export default hot(module)(NoPathwaysMessage);
