import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { clearTeamUserRoles } from 'actions/clear';
import { calculatePathwayBlueprint } from 'actions/pathwayBlueprint/calculatePathwayBlueprint';
import { clearPathwayBlueprint } from 'actions/pathwayBlueprint/clearPathwayBlueprint';
import {
    GET_PATHWAY_BLUEPRINT_ACTION,
    getPathwayBlueprint,
} from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { getTeamUserRoles } from 'actions/user/getTeamUserRoles';
import { GET_USER_PROFILES_ACTION, getUserProfiles } from 'actions/user/getUserProfiles';
import { IPathway, IPathwayBlueprint, Pathway } from 'api/generated/models';
import { CalculatePathwayBlueprint } from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import { IActionButtonItems } from 'components/ActionButtons';
import PageSectionWrapper from 'components/PageSectionWrapper';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import Toast from 'components/Toast';
import { useGetPathwayBlueprintInterval } from 'hooks/useGetPathwayBlueprintInterval';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isEmpty from 'lodash/isEmpty';
import CustomProjectedReimbursementModal from 'pages/pathwayBlueprint/CustomProjectedReimbursementModal';
import CustomProjectedWageUpModal from 'pages/pathwayBlueprint/CustomProjectedWageUpModal';
import PathwayBlueprintNameHeader from 'pages/pathwayBlueprint/PathwayBlueprintNameHeader';
import { UNKNOWN } from 'pages/pathwayBlueprint/PathwayTableCells';
import AnnualEmployerCostBreakdown from 'pages/pathwayBlueprintCostComparison/AnnualEmployerCostBreakdown';
import CostBarChart from 'pages/pathwayBlueprintCostComparison/CostBarChart';
import PathwayCostComparison from 'pages/pathwayBlueprintCostComparison/PathwayCostComparison';
import React, { useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_PATHWAY_BLUEPRINT_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import CustomCostModal from 'pages/pathwayBlueprint/CustomPlanCostModal';

const checkSharedResponsibilityFeeYear = ({
    sharedResponsibilityFeeYear,
    year,
}: IPathwayBlueprint) => {
    if (year !== sharedResponsibilityFeeYear) {
        Toast.error(
            `Shared Responsibility Fees could not be found for ${year}, using fees from ${sharedResponsibilityFeeYear}.`
        );
    }
};

const hasValueAndIsGreaterThanZero = (cost: number) => hasValue(cost) && cost !== 0;

const PathwayBlueprintCostComparisonPage = () => {
    const dispatch = useThunkDispatch();
    const { pathwayBlueprintId } = useParams<{ pathwayBlueprintId?: string }>();
    const { teamId } = useTeamProps();
    const {
        canRecalculatePathwayBlueprint,
        isCustomCostModalVisible,
        isCustomReimbursementModalVisible,
        isCustomWageUpModalVisible,
        isLoading,
        pathwayBlueprint,
        userProfiles,
    } = useSelector((state: AppStore) => ({
        canRecalculatePathwayBlueprint: hasSomePermissions(state, CalculatePathwayBlueprint),
        isCustomCostModalVisible: state.customPlanCostModalState.isCustomPlanCostModalVisible,
        isCustomReimbursementModalVisible:
            state.pathwayCustomProjectedModalState.isCustomReimbursementModalVisible,
        isCustomWageUpModalVisible:
            state.pathwayCustomProjectedModalState.isCustomWageUpModalVisible,
        isLoading:
            hasApiActivity(state, GET_USER_PROFILES_ACTION, GET_PATHWAY_BLUEPRINT_ACTION) ||
            isEmpty(state.pathwayBlueprint),
        pathwayBlueprint: state.pathwayBlueprint,
        userProfiles: state.userProfiles,
    }));
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useGetPathwayBlueprintInterval(pathwayBlueprintId, pathwayBlueprint?.isCalculating);

    useEffect(() => {
        dispatch(clearPathwayBlueprint());
        dispatch(
            getPathwayBlueprint(pathwayBlueprintId, { onSuccess: checkSharedResponsibilityFeeYear })
        );
        return () => {
            dispatch(clearPathwayBlueprint());
        };
    }, [dispatch, pathwayBlueprintId]);

    useEffect(() => {
        const getData = async () => {
            await dispatch(getUserProfiles(teamId, pathwayBlueprint.year, false));
            setIsInitialLoad(false);
        };
        if (pathwayBlueprint.year) {
            getData();
        }
    }, [dispatch, pathwayBlueprint.year, teamId]);

    useEffect(() => {
        dispatch(getTeamUserRoles(teamId, ROLE_IDS.TEAM_ADMIN));
        return () => {
            dispatch(clearTeamUserRoles());
        };
    }, [dispatch, teamId]);

    const recalculate = () => {
        dispatch(calculatePathwayBlueprint(pathwayBlueprintId));
    };

    const actions: IActionButtonItems = [
        {
            isLoading: isLoading || pathwayBlueprint.isCalculating,
            isVisible: canRecalculatePathwayBlueprint,
            onClick: recalculate,
            text: 'Recalculate',
        },
    ];
    let pathways = pathwayBlueprint?.pathways ?? [];
    const hasUnknown = useMemo(() => {
        const pathwayUserIds = pathwayBlueprint?.pathwayUsers?.map((pu) => pu.userId);
        return (
            pathwayBlueprint?.pathwayUsers?.some((x) => !hasValue(x.pathwayId)) ||
            userProfiles?.some((profile) => !pathwayUserIds?.contains(profile.user?.userId))
        );
    }, [pathwayBlueprint, userProfiles]);
    if (hasUnknown) {
        const unknownPathway = new Pathway({ id: UNKNOWN, name: UNKNOWN } as IPathway);
        pathways = [unknownPathway, ...pathways];
    }
    const shouldShowSkeleton = isLoading && isInitialLoad;

    const annualOldRenewalCost = pathwayBlueprint?.annualOldRenewalCost;
    const showRenewalSavings = hasValueAndIsGreaterThanZero(annualOldRenewalCost);

    const annualOldCost = pathwayBlueprint?.annualOldCost;
    const showSavings = hasValueAndIsGreaterThanZero(annualOldCost);

    return (
        <React.Fragment>
            {isCustomWageUpModalVisible && <CustomProjectedWageUpModal />}
            {isCustomReimbursementModalVisible && <CustomProjectedReimbursementModal />}
            {isCustomCostModalVisible && <CustomCostModal />}
            <PageSectionWrapper>
                <TableHeader
                    disableDropdown={pathwayBlueprint?.isCalculating}
                    dropdownWhenLengthGreaterThan={0}
                    items={actions}
                >
                    <PathwayBlueprintNameHeader appendToName="Comparison" isLoading={isLoading}>
                        <Link
                            to={generatePath(TEAMS_PATHWAY_BLUEPRINT_PATH, {
                                pathwayBlueprintId,
                                teamId,
                            })}
                        >
                            {' '}
                            &larr; Back to Blueprint
                        </Link>
                    </PathwayBlueprintNameHeader>
                </TableHeader>
            </PageSectionWrapper>
            <Row>
                <Col sm="6">
                    <Skeleton count={1} height="458px" isEnabled={shouldShowSkeleton}>
                        <AnnualEmployerCostBreakdown pathwayBlueprint={pathwayBlueprint} />
                    </Skeleton>
                </Col>
                <Col sm="6">
                    <Skeleton count={1} height="458px" isEnabled={shouldShowSkeleton}>
                        <Card>
                            <CardContent>
                                <Col xs="auto">
                                    {showSavings && (
                                        <Typography
                                            color={(theme) => theme.palette.secondary.main}
                                            m={0}
                                            textAlign="right"
                                            variant="h5"
                                        >
                                            <span>
                                                Projected Annual Savings Against Current Plan:{' '}
                                                {formatCurrency(pathwayBlueprint.projectedSavings)}{' '}
                                            </span>
                                        </Typography>
                                    )}
                                    <br />
                                    {showRenewalSavings && (
                                        <Typography
                                            color={(theme) => theme.palette.secondary.main}
                                            m={0}
                                            textAlign="right"
                                            variant="h5"
                                        >
                                            <span>
                                                Projected Annual Savings Against Renewal Plan:{' '}
                                                {formatCurrency(
                                                    pathwayBlueprint.projectedRenewalSavings
                                                )}{' '}
                                            </span>
                                        </Typography>
                                    )}
                                </Col>
                                <br />
                                <br />
                                <CostBarChart pathwayBlueprint={pathwayBlueprint} />
                            </CardContent>
                        </Card>
                    </Skeleton>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Skeleton count={1} height="458px" isEnabled={shouldShowSkeleton}>
                        {pathways.map((p) => (
                            <PathwayCostComparison key={p.id} pathway={p} />
                        ))}
                    </Skeleton>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default hot(module)(PathwayBlueprintCostComparisonPage);
