import { clearTeamBenefitTypeCarriers } from 'actions/clear';
import { getTeamBenefitCarriersByType } from 'actions/teamBenefitType/getTeamBenefitCarriersByType';
import { ITeamBenefitType } from 'api/generated/models';
import Button from 'components/Button';
import useThunkDispatch from 'hooks/useThunkDispatch';
import AddTeamBenefitTypeCarriersModal from 'pages/teamBenefitsManagement/AddTeamBenefitTypeCarriersModal';
import React, { useCallback, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const TeamBenefitTypes = () => {
    const dispatch = useThunkDispatch();
    const [
        selectedTeamBenefitType,
        setSelectedTeamBenefitType,
    ] = useState<ITeamBenefitType | null>();
    const { teamBenefitTypes } = useSelector((state: AppStore) => ({
        teamBenefitTypes: state.teamBenefitTypes,
    }));

    const onAddTeamBenefitTypeCarriersClose = () => {
        dispatch(clearTeamBenefitTypeCarriers());
        setSelectedTeamBenefitType(null);
    };

    const openAddCarriersModalForType = useCallback(
        (teamBenefitType: ITeamBenefitType) => () => {
            dispatch(getTeamBenefitCarriersByType(teamBenefitType.id));
            setSelectedTeamBenefitType(teamBenefitType);
        },
        [dispatch]
    );

    return (
        <React.Fragment>
            <Row>
                {teamBenefitTypes.map((teamBenefitType, index) => (
                    <Col className="mb-3" key={index} md="3">
                        <Button
                            onClick={openAddCarriersModalForType(teamBenefitType)}
                            size="small"
                            variant="outlined"
                        >
                            {teamBenefitType.name}
                        </Button>
                    </Col>
                ))}
            </Row>
            {selectedTeamBenefitType && (
                <AddTeamBenefitTypeCarriersModal
                    onClose={onAddTeamBenefitTypeCarriersClose}
                    teamBenefitType={selectedTeamBenefitType}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(TeamBenefitTypes);
