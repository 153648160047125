import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import { UPDATE_SCROLL_GRADIENT_INDICATOR } from 'components/ScrollGradientIndicator';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveHouseholdMembers, saveMemberForm } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import { ISurveyUser } from 'pages/survey/surveyState';
import YesNoWho from 'pages/survey/YesNoWho';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasContents, isTrueOrFalse } from 'utilities';
import EventEmitter from 'utilities/eventEmitter';

const TobaccoForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { householdMembers, member, usesTobacco } = useSelector((state: AppStore) => ({
        householdMembers: state.surveyState.household.members,
        member: state.surveyState.member,
        usesTobacco: state.surveyState.member.usesTobacco,
    }));

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [householdMembers, usesTobacco]);

    const initialDisabled = useMemo(
        () => !(!hasContents(householdMembers) && isTrueOrFalse(usesTobacco)),
        [householdMembers, usesTobacco]
    );
    const [isDisabled, setIsDisabled] = useState(initialDisabled);
    const onSelection = useCallback(
        ({
            isInvalid,
            options: newOptions,
        }: {
            isInvalid: boolean;
            isYes: boolean | undefined;
            options: (ISurveyHouseholdMemberDto | ISurveyUser)[];
        }) => {
            const primary = newOptions.find((x) => (x as ISurveyUser).teamId);
            const newHouseholdMembers = newOptions.filter(
                (x) => !(x as ISurveyUser).teamId
            ) as ISurveyHouseholdMemberDto[];
            dispatch(saveMemberForm({ usesTobacco: primary?.usesTobacco }));
            dispatch(saveHouseholdMembers(newHouseholdMembers));
            setIsDisabled(isInvalid);
        },
        [dispatch]
    );
    const onYesNoSelection = useCallback(
        (usesTobacco) => {
            dispatch(saveMemberForm({ usesTobacco }));
            setIsDisabled(false);
        },
        [dispatch]
    );
    const options = useMemo(() => [{ ...member, usesTobacco }, ...(householdMembers ?? [])], [
        householdMembers,
        member,
        usesTobacco,
    ]);
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled}>
            {children}
            {householdMembers?.length ? (
                <React.Fragment>
                    <FormTitle>Does anyone in your household use tobacco?</FormTitle>
                    <YesNoWho
                        isSelectedProp="usesTobacco"
                        onSelection={onSelection}
                        options={options}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FormTitle>Do you use tobacco?</FormTitle>
                    <YesOrNoSelect onSelection={onYesNoSelection} value={usesTobacco} />
                </React.Fragment>
            )}
        </SurveyFormWrapper>
    );
};

export default hot(module)(TobaccoForm);
