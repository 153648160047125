import {
    IMemberQuoteInfo,
    IMemberVerifiedInfo,
    IUser,
    YearlyUserInfoDto,
} from 'api/generated/models';
import {
    EditSubmittedExpense,
    EditUserMajorMedicalCoverageLevel,
    ViewUserVerifiedInfo,
    ViewUsers,
} from 'api/generated/permissions';
import EditableCurrencyAttribute from 'components/EditableCurrencyAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import PageHeader from 'components/PageHeader';
import useUserProps from 'hooks/useUserProps';
import CoverageQuoteInfo from 'pages/profile/CoverageQuoteInfo';
import CoverageVerifiedInfo from 'pages/profile/CoverageVerifiedInfo';
import PrimaryOtherCoverageSourceInfo from 'pages/profile/PrimaryOtherCoverageSourceInfo';
import { ICommonProps, ISaveYearlyUserInfo } from 'pages/profile/ProfilePage';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { nameof } from 'utilities';
import { formatCoverageLevel } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

const MAJOR_MEDICAL_COVERAGE_LEVEL_TOOLTIP =
    "This is the household's coverage level for major medical benefits via Remodel Health. \
It's a representation of who in the household we are helping find, pay for, and/or manage major medical benefits.";

type ICoverageInfoProps = {
    basicSave: ISaveEditableTextField<IUser>;
    canViewVerifiedInfo: boolean;
    hasMemberQuoteInfo: boolean | undefined;
    hasMemberVerifiedInfo: boolean | undefined;
    quoteInfoCommonProps: ICommonProps<IMemberQuoteInfo>;
    saveYearlyUserInfo: ISaveYearlyUserInfo;
    verifiedInfoCommonProps: ICommonProps<IMemberVerifiedInfo>;
};
const CoverageInfo = ({
    basicSave,
    canViewVerifiedInfo,
    hasMemberQuoteInfo,
    hasMemberVerifiedInfo,
    quoteInfoCommonProps,
    saveYearlyUserInfo,
    verifiedInfoCommonProps,
}: ICoverageInfoProps) => {
    const { isCurrent, memberQuoteInfo, memberVerifiedInfo, user, yearlyUserInfo } = useUserProps();
    const {
        canEditMajorMedicalCoverageLevel,
        canViewUsers,
        canViewUserVerifiedInfo,
        groupLevels,
    } = useSelector((state: AppStore) => ({
        canEditMajorMedicalCoverageLevel: hasSomePermissions(
            state,
            EditUserMajorMedicalCoverageLevel
        ),
        canViewUsers: hasSomePermissions(state, ViewUsers),
        canViewUserVerifiedInfo: hasSomePermissions(state, ViewUserVerifiedInfo),
        groupLevels: state.options.groupLevels,
    }));
    const { canEditSubmittedExpense } = useSelector((state: AppStore) => ({
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
    }));

    const [hasHsa, setHasHsa] = useState(user?.hasHsa?.toString());
    const [hsaContributionLimitOverride, setHsaContributionLimitOverride] = useState(
        yearlyUserInfo?.hsaContributionLimitOverride
    );
    const [needsMajorMedicalCoverage, setNeedsMajorMedicalCoverage] = useState(
        yearlyUserInfo?.needsMajorMedicalCoverage?.toString()
    );
    const [majorMedicalCoverageLevel, setMajorMedicalCoverageLevel] = useState(
        yearlyUserInfo?.majorMedicalCoverageLevel.toString()
    );

    useEffect(() => {
        setMajorMedicalCoverageLevel(yearlyUserInfo?.majorMedicalCoverageLevel.toString());
    }, [yearlyUserInfo?.majorMedicalCoverageLevel]);

    useEffect(() => {
        setHsaContributionLimitOverride(yearlyUserInfo?.hsaContributionLimitOverride);
    }, [yearlyUserInfo?.hsaContributionLimitOverride]);

    const WrapperWithHeader = useCallback(
        ({ children }) => (
            <React.Fragment>
                <PageHeader variant="h4">Coverage</PageHeader>
                <hr />
                {children}
            </React.Fragment>
        ),
        []
    );

    return (
        <WrapperWithHeader>
            {canViewUsers && (
                <EditableSelectAttribute
                    data-cy="major-medical-coverage-level-input"
                    defaultText="Select a Major Medical Coverage Level"
                    formatter={(x) => formatCoverageLevel(groupLevels)(x as string)}
                    infoTooltip={MAJOR_MEDICAL_COVERAGE_LEVEL_TOOLTIP}
                    items={groupLevels}
                    label="Major Medical Coverage Level"
                    name="majorMedicalCoverageLevel"
                    onChange={onChange(setMajorMedicalCoverageLevel)}
                    optionText="fulltext"
                    optionValue="groupLevelId"
                    readonly={!canEditMajorMedicalCoverageLevel || hasMemberVerifiedInfo}
                    save={saveYearlyUserInfo}
                    validationSchema={string()
                        .required()
                        .label('Major Medical Coverage Level')}
                    value={majorMedicalCoverageLevel}
                />
            )}
            {canViewVerifiedInfo && (
                <React.Fragment>
                    <EditableYesNoSelectAttribute
                        infoTooltip="Whether this member of the household needs major medical benefits managed via Remodel Health."
                        label="Needs Major Medical Coverage?"
                        name={nameof<YearlyUserInfoDto>('needsMajorMedicalCoverage')}
                        onChange={onChange(setNeedsMajorMedicalCoverage)}
                        readonly={verifiedInfoCommonProps.readonly}
                        save={saveYearlyUserInfo}
                        value={needsMajorMedicalCoverage}
                    />
                    <PrimaryOtherCoverageSourceInfo readonly={verifiedInfoCommonProps.readonly} />
                    {canViewUserVerifiedInfo && (
                        <EditableYesNoSelectAttribute
                            isOptional
                            label="Has HSA"
                            name="hasHsa"
                            onChange={onChange(setHasHsa)}
                            readonly={verifiedInfoCommonProps.readonly}
                            save={basicSave}
                            value={hasHsa}
                        />
                    )}
                    {canEditSubmittedExpense && (
                        <EditableCurrencyAttribute
                            decimalScale={2}
                            infoTooltip="If this value is set, the platform will use this number's HSA Contribution Limit when processing reimbursements."
                            label="HSA Contribution Limit Override"
                            name="hsaContributionLimitOverride"
                            onChange={onChange(setHsaContributionLimitOverride)}
                            save={saveYearlyUserInfo}
                            validationSchema={string()
                                .trim()
                                .label('HSA Contribution Limit Override')}
                            value={hsaContributionLimitOverride}
                        />
                    )}
                    {hasMemberVerifiedInfo && (
                        <CoverageVerifiedInfo
                            isCurrent={isCurrent}
                            memberVerifiedInfo={memberVerifiedInfo}
                            user={user}
                            verifiedInfoCommonProps={verifiedInfoCommonProps}
                        />
                    )}
                </React.Fragment>
            )}
            {!hasMemberVerifiedInfo && hasMemberQuoteInfo && (
                <CoverageQuoteInfo
                    memberQuoteInfo={memberQuoteInfo}
                    quoteInfoCommonProps={quoteInfoCommonProps}
                />
            )}
        </WrapperWithHeader>
    );
};

export default hot(module)(CoverageInfo);
