import { AppStoreThunkDispatch } from 'actions/commonAction';
import { IPathwayUser, IPathwayUserDto } from 'api/generated/models';
import { IPathwayRowData } from 'pages/pathwayBlueprint/PathwayTableCells';

export const OPEN_CUSTOM_REIMBURSEMENT_MODAL = 'OPEN_CUSTOM_REIMBURSEMENT_MODAL';
export const openCustomReimbursementModal = (
    pathwayUser: IPathwayRowData | IPathwayUser | IPathwayUserDto
) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            pathwayUser,
            isCustomReimbursementModalVisible: true,
        },
        type: OPEN_CUSTOM_REIMBURSEMENT_MODAL,
    });
};

export const CLOSE_CUSTOM_REIMBURSEMENT_MODAL = 'CLOSE_CUSTOM_REIMBURSEMENT_MODAL';
export const closeCustomReimbursementModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            isCustomReimbursementModalVisible: false,
            pathwayUser: null,
        },
        type: CLOSE_CUSTOM_REIMBURSEMENT_MODAL,
    });
};

export const OPEN_CUSTOM_WAGEUP_MODAL = 'OPEN_CUSTOM_WAGEUP_MODAL';
export const openCustomWageUpModal = (
    pathwayUser: IPathwayRowData | IPathwayUser | IPathwayUserDto
) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            pathwayUser,
            isCustomWageUpModalVisible: true,
        },
        type: OPEN_CUSTOM_WAGEUP_MODAL,
    });
};

export const CLOSE_CUSTOM_WAGEUP_MODAL = 'CLOSE_CUSTOM_WAGEUP_MODAL';
export const closeCustomWageUpModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            isCustomWageUpModalVisible: false,
            pathwayUser: null,
        },
        type: CLOSE_CUSTOM_WAGEUP_MODAL,
    });
};
