import CheckIcon from '@mui/icons-material/Check';
import Button, { ButtonProps } from '@mui/material/Button';
import { Item } from 'pages/survey/multiSelectButtons/MultiSelectButtons';
import React, { ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const GrabbaleDiv = styled.div`
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
`;

type IMultiSelectButtonProps<T extends Item> = {
    ColProps?: React.ComponentProps<typeof Col>;
    children: ReactNode;
    enableDragAndDrop?: boolean;
    index: number;
    isInRow?: boolean;
    isSelectedProp: keyof T;
    onSelection?: (item: T, index: number) => void;
    option: T;
    showCheckmark?: boolean;
};
const MultiSelectButton = <T extends Item>({
    children,
    ColProps,
    enableDragAndDrop = false,
    index,
    isInRow = false,
    isSelectedProp,
    onSelection,
    option,
    showCheckmark = false,
}: IMultiSelectButtonProps<T>) => {
    const isSelected = option[isSelectedProp];
    let classNames = 'text-left d-flex h-100 w-100 justify-content-start';
    if (option.isRequired) {
        classNames = `${classNames} bg-primary-25-lighten disabled`;
    } else if (isSelected) {
        classNames = `${classNames} text-white bg-primary`;
    } else {
        classNames = `${classNames} bg-white text-dark border-secondary-70-percent-transparent bg-secondary-65-lighten-hover`;
    }
    let color: ButtonProps['color'] = 'secondary';
    if (option.isRequired) {
        color = 'neutral';
    } else if (isSelected) {
        color = 'primary';
    }
    let component: React.ElementType = 'button';
    if (enableDragAndDrop) {
        component = GrabbaleDiv;
    }
    return (
        <Col
            className="px-1 mb-2"
            md={isInRow ? '6' : '12'}
            xs="12"
            {...((ColProps as unknown) as object)}
        >
            <Button
                autoFocus={option.autoFocus}
                className={classNames}
                color={color}
                component={component}
                data-cy={`multi-select-button-${index}`}
                onClick={() => !option.isRequired && onSelection?.(option, index)}
                startIcon={showCheckmark ? <CheckIcon /> : undefined}
                variant="outlined"
            >
                {children}
            </Button>
        </Col>
    );
};

export default hot(module)(MultiSelectButton);
