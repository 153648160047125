import { ChangeTypes, SelectedPlanComparisonTypes } from 'api/generated/enums';
import { ISelectedPlanComparison } from 'api/generated/models';
import Icon from 'components/Icon';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue, stringToFloatAbs } from 'utilities';
import { formatCurrency } from 'utilities/format';

export const UpArrow = () => (
    <Icon prefix="mdi" variant="danger">
        menu-up
    </Icon>
);
export const DownArrow = () => (
    <Icon prefix="mdi" variant="success">
        menu-down
    </Icon>
);
export const RightArrow = () => (
    <Icon prefix="mdi" variant="info">
        menu-right
    </Icon>
);

const Comparison = ({
    comparison: { changeType, difference, name, comparisonType },
}: {
    comparison: ISelectedPlanComparison;
}) => {
    if (comparisonType === SelectedPlanComparisonTypes.Household) {
        return (
            <span style={{ fontWeight: 600 }}>
                <RightArrow /> {difference}
            </span>
        );
    } else {
        switch (changeType) {
            case ChangeTypes.Bad:
                return (
                    <span>
                        <UpArrow /> {name} has increased by{' '}
                        {formatCurrency(stringToFloatAbs(difference))}
                    </span>
                );
            case ChangeTypes.Good:
                return (
                    <span>
                        <DownArrow /> {name} has decreased by{' '}
                        {formatCurrency(stringToFloatAbs(difference))}
                    </span>
                );
            case ChangeTypes.Neutral:
                return (
                    <span>
                        <RightArrow /> {hasValue(name) && `${name} has changed`} {difference}
                    </span>
                );
            default:
                return <React.Fragment></React.Fragment>;
        }
    }
};

export default hot(module)(Comparison);
