import Stack from '@mui/material/Stack';
import { IReimbursableLineItem } from 'api/generated/models';
import LabelAndValue from 'components/LabelAndValue';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

const LineItemSummary = ({
    isContribution = false,
    lineItem,
    superscriptIdGenerator,
    title,
}: {
    isContribution?: boolean;
    lineItem: IReimbursableLineItem;
    superscriptIdGenerator: Generator<string>;
    title: string;
}) => {
    const premium = formatCurrency(lineItem.premium, { preserveDecimal: true });
    const hasPremiumAdjustment = lineItem.premiumAdjustment !== 0;
    const premiumAdjustment = formatCurrency(lineItem.premiumAdjustment, { preserveDecimal: true });
    const reimbursement = formatCurrency(lineItem.appliedReimbursement, { preserveDecimal: true });
    const total = formatCurrency(lineItem.amount, { preserveDecimal: true });
    let premiumSuperscript: string | undefined;
    let premiumAdjustmentSuperscript: string | undefined;
    let reimbursementSupserscript: string | undefined;
    let equation: JSX.Element | string = reimbursement;
    if (!isContribution) {
        premiumSuperscript = superscriptIdGenerator.next().value;
        if (hasPremiumAdjustment) {
            premiumAdjustmentSuperscript = superscriptIdGenerator.next().value;
        }
        reimbursementSupserscript = superscriptIdGenerator.next().value;
        equation = (
            <span>
                {premium}
                <sup>{premiumSuperscript}</sup>
                {hasPremiumAdjustment && (
                    <React.Fragment>
                        {' '}
                        + {premiumAdjustment}
                        <sup>{premiumAdjustmentSuperscript}</sup>
                    </React.Fragment>
                )}{' '}
                - {reimbursement}
                <sup>{reimbursementSupserscript}</sup> = {total}
            </span>
        );
    }

    return (
        <Stack>
            <LabelAndValue label={lineItem.title} value={total} variant="h4" />
            <LabelAndValue
                isIndented
                label="Premium"
                labelSuperscript={premiumSuperscript}
                value={premium}
                variant="subtitle1"
            />
            {hasPremiumAdjustment && (
                <LabelAndValue
                    isIndented
                    label="Premium Adjustment"
                    labelSuperscript={premiumAdjustmentSuperscript}
                    value={premiumAdjustment}
                    variant="subtitle1"
                />
            )}
            <LabelAndValue
                isIndented
                label="Reimbursement Amount"
                labelSuperscript={reimbursementSupserscript}
                value={reimbursement}
                variant="subtitle1"
            />
            <LabelAndValue isIndented label={title} value={equation} variant="subtitle1" />
        </Stack>
    );
};

export default hot(module)(LineItemSummary);
