import { AppStoreThunkDispatch } from 'actions/commonAction';
import { saveCompletedTeamTask } from 'actions/task/saveCompletedTeamTask';
import { patchTeam } from 'actions/team/patchTeam';
import { ADD_TEAM_PLAN_ACTION } from 'actions/teamPlan/addTeamPlan';
import { TaskItems } from 'api/generated/enums';
import { ITeam, ITeamProfile } from 'api/generated/models';
import Button from 'components/Button';
import { INLINE_EDIT_TOOLTIP_TITLE } from 'components/EditableAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import Form from 'components/Form';
import ProfileAttribute from 'components/ProfileAttribute';
import Tooltip from 'components/Tooltip';
import { teamStateIds } from 'constants/teamStateIds';
import get from 'lodash/get';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { string } from 'yup';

const inputNumberProps = {
    min: 0,
    placeholder: 'Enter a number',
    type: 'number',
};

type ITeamInfoModalBaseProps = {
    handleClose: () => void;
    handleEditAddressClick: () => void;
    handleIncorrectClick: () => void;
    teamProfile: ITeamProfile;
};

type ITeamInfoModalProps = DispatchProps & ITeamInfoModalBaseProps & StateProps;

class TeamInfoModal extends React.PureComponent<ITeamInfoModalProps> {
    override state = {
        fullTimeEmployeeCount: '',
        partTimeEmployeeCount: '',
        showActivity: false,
        teamName: '',
    };

    override componentDidMount() {
        this.setState({
            fullTimeEmployeeCount: get(
                this.props.teamProfile,
                'team.fullTimeEmployeeCount',
                '0'
            ).toString(),
            partTimeEmployeeCount: get(
                this.props.teamProfile,
                'team.partTimeEmployeeCount',
                '0'
            ).toString(),
            teamName: get(this.props.teamProfile, 'team.name'),
        });
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    save: ISaveEditableTextField<ITeam> = async (name, value) => {
        await this.props.patchTeam(this.props.teamProfile.team?.teamId, { [name]: value }, true);
    };

    renderAddress = () => {
        const address = this.props.teamProfile.address;
        return (
            <div className="d-flex align-items-center">
                <div>
                    <p className="my-0 text-right">{address?.addressLine1}</p>
                    {address?.addressLine2 && (
                        <p className="my-0 text-right">{address?.addressLine2}</p>
                    )}
                    <p className="my-0 text-right">
                        {address?.city}, {address?.state} {address?.zip}
                    </p>
                    <p className="my-0 text-right">{address?.county}</p>
                </div>
                {!this.props.disableTeamPropertyEdits && (
                    <i className="dripicons-document-edit ml-2" />
                )}
            </div>
        );
    };

    handleSubmit = async () => {
        this.setState({ showActivity: true });
        await this.props.saveCompletedTeamTask(
            TaskItems.VerifyTeamInfo,
            this.props.teamProfile.team?.teamId as string
        );
        this.setState({ showActivity: false });
        this.props.handleClose();
    };

    override render() {
        const {
            disableTeamPropertyEdits,
            handleClose,
            handleEditAddressClick,
            handleIncorrectClick,
            teamProfile,
        } = this.props;
        const { teamName, fullTimeEmployeeCount, partTimeEmployeeCount, showActivity } = this.state;
        const commonProps = {
            onChange: this.onChange,
            readonly: disableTeamPropertyEdits,
            save: this.save,
        };
        return (
            <Modal onHide={handleClose} scrollable show>
                <Modal.Header closeButton>
                    <Modal.Title>Verify Team Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="team-info-modal"
                        isLoading={showActivity}
                        onSubmit={this.handleSubmit}
                    >
                        <EditableTextAttribute
                            {...commonProps}
                            label="Name"
                            name="name"
                            placeholder="Enter a team name"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Team Name')}
                            value={teamName}
                        />

                        {teamProfile.address && (
                            <ProfileAttribute label="Address">
                                {!disableTeamPropertyEdits ? (
                                    <Tooltip title={INLINE_EDIT_TOOLTIP_TITLE}>
                                        <div className="clickable" onClick={handleEditAddressClick}>
                                            {this.renderAddress()}
                                        </div>
                                    </Tooltip>
                                ) : (
                                    this.renderAddress()
                                )}
                            </ProfileAttribute>
                        )}

                        <EditableTextAttribute
                            {...commonProps}
                            {...inputNumberProps}
                            label="Full Time Employees"
                            name="fullTimeEmployeeCount"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Full Time Employees count')}
                            value={fullTimeEmployeeCount}
                        />

                        <EditableTextAttribute
                            {...commonProps}
                            {...inputNumberProps}
                            label="Part Time Employees"
                            name="partTimeEmployeeCount"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Part Time Employees count')}
                            value={partTimeEmployeeCount}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer className="centered">
                    <Button
                        data-cy="somethings-not-right"
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            handleIncorrectClick();
                        }}
                    >
                        Something is incorrect
                    </Button>
                    <Button
                        data-cy="looks-good"
                        form="team-info-modal"
                        isLoading={showActivity}
                        type="submit"
                    >
                        Looks good!
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state: AppStore, ownProps: ITeamInfoModalBaseProps) => {
    const teamStateId = get(ownProps, 'teamProfile.team.teamStateId');
    return {
        apiError: !!state.apiErrors[ADD_TEAM_PLAN_ACTION.statusCode],
        apiErrorMessage: state.apiErrors[ADD_TEAM_PLAN_ACTION.errorMessage],
        disableTeamPropertyEdits:
            teamStateId !== teamStateIds.Prospect &&
            teamStateId !== teamStateIds.Proposal &&
            teamStateId !== teamStateIds.RhLaunch,
        showActivity: hasApiActivity(state, ADD_TEAM_PLAN_ACTION),
    };
};

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    patchTeam: async (teamId: string | undefined, team: Partial<ITeam>, isCurrent: boolean) =>
        dispatch(patchTeam(teamId, team, isCurrent)),
    saveCompletedTeamTask: async (taskId: TaskItems, teamId: string) =>
        dispatch(saveCompletedTeamTask(taskId, teamId)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(TeamInfoModal));
