import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { GET_HOUSEHOLD_ACTION, getHousehold } from 'actions/user/getHousehold';
import { Flows, HouseholdMemberTypes } from 'api/generated/enums';
import { HouseholdMemberInfoDto, IHouseholdMembersDto } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import { clearAddressInputs } from 'components/addressInput/addressInputActions';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import {
    getSupportedEnrollmentUserFlow,
    handleNeedsCoverage,
    HhmContext,
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import HouseholdMemberContent from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdMembersPage/HouseholdMemberContent';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatDateForDisplay } from 'utilities/format';
import { arrayHasValue, hasValue } from 'utilities/index';

const HouseholdMembersPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { userId, isCurrent } = useUserProps();
    const { hhms, isLoading, userFlows } = useSelector((state: AppStore) => ({
        hhms: state.householdMembers,
        isLoading: hasApiActivity(state, GET_HOUSEHOLD_ACTION, LIST_VISIBLE_FLOWS_ACTION),
        userFlows: state.userFlows,
    }));
    const [initialChunk, setInitialChunk] = useState<IHouseholdMembersDto>(currentTask.response);
    const [chunk, setChunk] = useState<IHouseholdMembersDto>(
        currentTask.response ?? { householdMembers: [] }
    );
    const [hhmExpanded, setHhmExpanded] = useState<number | undefined>(undefined);
    const [hhmsNeedsMedicalCoverage, setHhmsNeedsMedicalCoverage] = useState<string[] | undefined>(
        undefined
    );
    const [isAddingNewHhm, setIsAddingNewHhm] = useState<boolean>(false);
    const [newHhmType, setNewHhmType] = useState<HouseholdMemberTypes>();

    const isRenewalFlow = getSupportedEnrollmentUserFlow(userFlows)?.flowId === Flows.IchraRenewal;
    const introText = isRenewalFlow ? (
        <Typography variant="body1">
            Verify which members of your household you want to have coverage. Add or update any
            information that has changed.
            <br />
            <br />
            Your reimbursement amount may change depending on which family members you’re seeking to
            cover.
        </Typography>
    ) : (
        <Typography variant="body1">
            Add a spouse and any children for which you would like to see coverage options. If your
            spouse or children do not need coverage, you do not need to add them.
            <br />
            <br />
            Your reimbursement amount may change depending on who you are seeking coverage for.
        </Typography>
    );

    const handleSubmit = async () => {
        const hhmsToSubmit: HouseholdMemberInfoDto[] =
            chunk?.householdMembers?.map(
                (h) =>
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    ({
                        ...h,
                        needsCoverage: handleNeedsCoverage(
                            h,
                            isRenewalFlow,
                            hhmsNeedsMedicalCoverage
                        ),
                    } as HouseholdMemberInfoDto)
            ) ?? [];
        dispatch(completeTask(currentTask.globalId, { householdMembers: hhmsToSubmit }));
        dispatch(clearAddressInputs());
    };

    const editChunk = (hhm: HouseholdMemberInfoDto, hhmIndex: number) => {
        const newHhms = chunk?.householdMembers ?? ([] as HouseholdMemberInfoDto[]);
        if (hhmIndex === -1) {
            newHhms.push(hhm);
        } else {
            newHhms[hhmIndex] = hhm;
        }

        setChunk({ householdMembers: newHhms });
    };

    const removeHhmFromChunk = (hhmIndex: number) => {
        const newHhms = chunk?.householdMembers as HouseholdMemberInfoDto[];
        if (hhmIndex !== -1) {
            newHhms.splice(hhmIndex, 1);
        }

        setChunk({ householdMembers: newHhms });
    };

    const contextProviderValue = useMemo(
        () => ({
            hhmExpanded,
            hhmsNeedsMedicalCoverage,
            isAddingNewHhm,
            isRenewalFlow,
            newHhmType,
            setHhmExpanded,
            setHhmsNeedsMedicalCoverage,
            setIsAddingNewHhm,
            setNewHhmType,
        }),
        [hhmExpanded, hhmsNeedsMedicalCoverage, isAddingNewHhm, isRenewalFlow, newHhmType]
    );

    useEffect(() => {
        if (!hasValue(initialChunk) && arrayHasValue(hhms)) {
            const householdMembers: HouseholdMemberInfoDto[] = [];

            hhms.forEach((hhm) =>
                householdMembers.push({
                    address: hhm.address,
                    birthdate: formatDateForDisplay(hhm.dateOfBirth),
                    firstName: hhm.firstName,
                    gender: hhm.gender,
                    householdMemberId: hhm.householdMemberId,
                    householdMemberTypeId: hhm.householdMemberTypeId,
                    lastName: hhm.lastName,
                    needsCoverage: hhm.needsCoverage,
                    sameEmployer: hhm.hasSameEmployer,
                    usesTobacco: hhm.usesTobacco,
                } as HouseholdMemberInfoDto)
            );
            const newChunk: IHouseholdMembersDto = { householdMembers };
            setInitialChunk(newChunk);
            setChunk(newChunk);
        }
    }, [hhms, initialChunk]);

    useEffect(() => {
        if (
            !hasValue(hhmsNeedsMedicalCoverage) &&
            (arrayHasValue(chunk.householdMembers) || arrayHasValue(hhms))
        ) {
            const newHhmsNeedsCoverage: string[] = [];
            if (arrayHasValue(chunk.householdMembers)) {
                chunk.householdMembers.forEach((h) => {
                    if (h.needsCoverage ?? true) {
                        newHhmsNeedsCoverage.push(h.householdMemberId as string);
                    }
                });
                setHhmsNeedsMedicalCoverage(newHhmsNeedsCoverage);
            } else if (arrayHasValue(hhms)) {
                hhms.forEach((h) => {
                    if (h.needsCoverage) {
                        newHhmsNeedsCoverage.push(h.householdMemberId);
                    }
                });
            }
            setHhmsNeedsMedicalCoverage(newHhmsNeedsCoverage);
        }
    }, [chunk, hhms, hhmsNeedsMedicalCoverage]);

    useEffect(() => {
        dispatch(getHousehold(userId, isCurrent));
    }, [dispatch, isCurrent, userId]);

    useEffect(
        () => () => {
            dispatch(clearAddressInputs());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Stack direction="column" gap={4} maxWidth={MAX_WIDTH} width="100%">
            <IchraFlowTitle title="Household Members" />
            {introText}
            <Divider />
            <HhmContext.Provider value={contextProviderValue}>
                <Stack gap={3}>
                    <Typography marginBottom={0} variant="h4">
                        Spouse
                    </Typography>
                    <Skeleton height={105} isEnabled={isLoading}>
                        {hasValue(
                            chunk?.householdMembers?.find((hhm) =>
                                [HouseholdMemberTypes.Spouse].includes(
                                    Number(hhm.householdMemberTypeId)
                                )
                            )
                        ) ? (
                            chunk?.householdMembers?.map(
                                (hhm, index) =>
                                    [HouseholdMemberTypes.Spouse].includes(
                                        Number(hhm.householdMemberTypeId)
                                    ) && (
                                        <HouseholdMemberContent
                                            editHhms={editChunk}
                                            hhmIndex={index}
                                            hhmType={HouseholdMemberTypes.Spouse}
                                            householdMember={hhm}
                                            key={`${hhm.birthdate}-${hhm.firstName}`}
                                            removeHhm={removeHhmFromChunk}
                                        />
                                    )
                            )
                        ) : (
                            <HouseholdMemberContent
                                editHhms={editChunk}
                                hhmType={HouseholdMemberTypes.Spouse}
                                householdMember={undefined}
                                removeHhm={removeHhmFromChunk}
                            />
                        )}
                    </Skeleton>
                </Stack>
                <Stack gap={3}>
                    <Typography marginBottom={0} variant="h4">
                        Children
                    </Typography>
                    <Skeleton height={50} isEnabled={isLoading} width={140}>
                        <HouseholdMemberContent
                            editHhms={editChunk}
                            hhmIndex={undefined}
                            hhmType={HouseholdMemberTypes.IchraChild}
                            householdMember={undefined}
                            removeHhm={removeHhmFromChunk}
                        />
                    </Skeleton>
                    <Skeleton count={3} height={105} isEnabled={isLoading}>
                        {hasValue(
                            chunk?.householdMembers?.find((hhm) =>
                                [
                                    HouseholdMemberTypes.IchraChild,
                                    HouseholdMemberTypes.Dependent,
                                    HouseholdMemberTypes.Unclaimed,
                                ].includes(Number(hhm.householdMemberTypeId))
                            )
                        ) &&
                            chunk?.householdMembers?.map(
                                (hhm, index) =>
                                    [
                                        HouseholdMemberTypes.IchraChild,
                                        HouseholdMemberTypes.Dependent,
                                        HouseholdMemberTypes.Unclaimed,
                                    ].includes(Number(hhm.householdMemberTypeId)) && (
                                        <HouseholdMemberContent
                                            editHhms={editChunk}
                                            hhmIndex={index}
                                            hhmType={HouseholdMemberTypes.IchraChild}
                                            householdMember={hhm}
                                            key={`${hhm.birthdate}-${hhm.firstName}`}
                                            removeHhm={removeHhmFromChunk}
                                        />
                                    )
                            )}
                    </Skeleton>
                </Stack>
            </HhmContext.Provider>
            <Divider />
            <IchraFlowButtons
                currentTaskId={currentTask.globalId}
                handleNext={handleSubmit}
                isLoading={isLoading}
                showPreviousButton
            />
        </Stack>
    );
};

export default hot(module)(HouseholdMembersPage);
