import { getAllEnrollmentVerificationForUser } from 'actions/enrollmentVerification/getAllEnrollmentVerificationForUser';
import { getRhFilesForUser, GET_RH_FILES_FOR_USER_ACTION } from 'actions/rhFile/getRhFilesForUser';
import { CategoryIds } from 'api/generated/enums';
import UserRhFiles from 'components/rhFile/UserRhFiles';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const UserRhFilesPage = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { rhFiles, isLoading } = useSelector((state: AppStore) => ({
        isLoading: state.apiActivity[GET_RH_FILES_FOR_USER_ACTION],
        rhFiles: state.rhFiles,
    }));

    useEffect(() => {
        dispatch(getRhFilesForUser(userId, CategoryIds.General, false));
        dispatch(getAllEnrollmentVerificationForUser(userId));
    }, [dispatch, userId]);
    return isLoading ? (
        <Row>
            <Skeleton
                count={1}
                height="105px"
                Wrapper={({ children }) => <div className="col-12 mb-3">{children}</div>}
            />
            <div className="w-100"></div>
            <Skeleton
                count={4}
                height="214px"
                Wrapper={({ children }) => <div className="col-sm-6 col-lg-3 mb-2">{children}</div>}
            />
        </Row>
    ) : (
        <UserRhFiles data={rhFiles} />
    );
};

export default hot(module)(UserRhFilesPage);
