import { TRANSITION_TO_CUSTOMER_ACTION } from 'actions/team/transitionToTeamCustomer';
import Button from 'components/Button';
import EndRenewalPeriodModal from 'pages/dashboard/EndRenewalPeriodModal';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const EndRenewalPeriod = ({ teamId }: { teamId: string }) => {
    const [showModal, setShowModal] = useState(false);

    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, TRANSITION_TO_CUSTOMER_ACTION),
    }));

    const handleCloseModal = useCallback(() => setShowModal(false), []);
    const handleOpenModal = useCallback(() => setShowModal(true), []);

    return (
        <React.Fragment>
            {showModal && <EndRenewalPeriodModal onClose={handleCloseModal} teamId={teamId} />}
            <Button
                data-cy="end-renewal-period"
                isLoading={isLoading}
                onClick={handleOpenModal}
                xsClassName="mb-4"
            >
                End renewal period for team
            </Button>
        </React.Fragment>
    );
};

export default hot(module)(EndRenewalPeriod);
