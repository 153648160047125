import Grid from '@mui/material/Grid';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import { IPersonalInfoDto } from 'api/generated/models';
import { SingleDateMask } from 'components/DateTextField';
import Form from 'components/Form';
import { PhoneTextMask } from 'components/PhoneTextField';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { personalInfoSchema } from 'pages/dashboard/ichraTaskFlow/ichraFlowSchema';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
    ichraFlowSkeletonRowProps,
    personalInfoChunkHasValue,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { formatDateForDisplay, formatPhoneNumber } from 'utilities/format';
import { hasValue } from 'utilities/index';

const PersonalInfoPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, user, userId, memberVerifiedInfo } = useUserProps();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_USER_PROFILE_ACTION, LIST_VISIBLE_FLOWS_ACTION),
    }));
    const { errors, validate } = useForm(personalInfoSchema);
    const [initialChunk, setInitialChunk] = useState<IPersonalInfoDto>(currentTask.response);
    const [chunk, setChunk] = useState<IPersonalInfoDto>(currentTask.response);

    const { genderList } = useSelector((state: AppStore) => ({
        genderList: state.options.genderList,
    }));

    useEffect(() => {
        if (!personalInfoChunkHasValue(initialChunk)) {
            if (!hasValue(user)) {
                dispatch(getUserProfile(userId, isCurrent));
            } else {
                const newChunk = {
                    birthdate: formatDateForDisplay(user?.dateOfBirth) ?? '',
                    firstName: user?.firstName,
                    gender: memberVerifiedInfo?.gender ?? '',
                    lastName: user?.lastName,
                    phone: formatPhoneNumber(user?.phone),
                    preferredName: user?.preferredName ?? '',
                    usesTobacco: memberVerifiedInfo?.usesTobacco,
                };
                setInitialChunk(newChunk as IPersonalInfoDto);
                setChunk(newChunk as IPersonalInfoDto);
            }
        }
    }, [chunk, memberVerifiedInfo, user, dispatch, userId, isCurrent, initialChunk]);

    const handleChunkChange = (field: string, value: boolean | string) => {
        let newChunk;
        switch (field) {
            case 'firstName':
                newChunk = { ...chunk, firstName: value as string };
                break;
            case 'lastName':
                newChunk = { ...chunk, lastName: value as string };
                break;
            case 'preferredName':
                newChunk = { ...chunk, preferredName: value as string };
                break;
            case 'gender':
                newChunk = { ...chunk, gender: value as string };
                break;
            case 'birthdate':
                newChunk = { ...chunk, birthdate: value as string };
                break;
            case 'phone':
                newChunk = { ...chunk, phone: value as string };
                break;
            case 'usesTobacco':
                newChunk = { ...chunk, usesTobacco: value as boolean };
                break;
            default:
                return;
        }
        setChunk(newChunk);
    };

    const handleSubmit = async () => {
        const { data, isValid } = await validate({
            birthdate: chunk.birthdate,
            firstName: chunk.firstName,
            gender: chunk.gender,
            lastName: chunk.lastName,
            phone: chunk.phone,
            preferredName: chunk.preferredName,
            usesTobacco: chunk.usesTobacco,
        });

        if (isValid) {
            dispatch(completeTask(currentTask.globalId, data as IPersonalInfoDto));
        }
    };

    return (
        <Grid container direction="column" gap={3} maxWidth={MAX_WIDTH}>
            <IchraFlowTitle title="Personal Info" />
            <Skeleton
                isEnabled={isLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 3,
                            height: 55,
                            sx: { mb: 3 },
                        },
                        {
                            columnProps: { flex: 1 },
                            count: 3,
                            height: 55,
                            sx: { mb: 3 },
                        },
                    ],
                ]}
            >
                <Form>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item lg={6} xl={4} xs={12}>
                            <TextField
                                errors={errors?.firstName}
                                label="First Name"
                                name="firstName"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                value={chunk?.firstName ?? ''}
                            />
                        </Grid>
                        <Grid item lg={6} xl={4} xs={12}>
                            <TextField
                                errors={errors?.lastName}
                                label="Last Name"
                                name="lastName"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                value={chunk?.lastName ?? ''}
                            />
                        </Grid>
                        <Grid item lg={6} xl={4} xs={12}>
                            <TextField
                                errors={errors?.preferredName}
                                isOptional
                                label="Preferred Name"
                                name="preferredName"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                value={chunk?.preferredName ?? ''}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Select
                                defaultText="Select a gender"
                                defaultValue=""
                                errors={errors?.gender}
                                items={genderList}
                                label="Gender"
                                name="gender"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                optionText="value"
                                optionValue="id"
                                value={chunk?.gender ?? ''}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <TextField
                                errors={errors?.birthdate}
                                InputProps={{ inputComponent: SingleDateMask }}
                                label="Date of Birth"
                                name="birthdate"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                value={chunk?.birthdate ?? ''}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <TextField
                                errors={errors?.phone}
                                InputProps={{ inputComponent: PhoneTextMask }}
                                label="Phone Number"
                                name="phone"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                value={chunk?.phone ?? ''}
                            />
                        </Grid>
                        <Grid item lg={12} xl={6}>
                            <Select
                                defaultText="Select Yes or No"
                                defaultValue=""
                                errors={errors?.usesTobacco}
                                helperText="Select yes if you've used tobacco products 4 or more times per week on average during the past 6 months. This can impact your plan premiums."
                                items={yesOrNo}
                                label="Tobacco Use"
                                name="usesTobacco"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                optionText="text"
                                optionValue="value"
                                value={chunk?.usesTobacco ?? ''}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Skeleton>
            <IchraFlowButtons
                currentTaskId={currentTask.globalId}
                handleNext={handleSubmit}
                isLoading={isLoading}
            />
        </Grid>
    );
};

export default hot(module)(PersonalInfoPage);
