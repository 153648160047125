import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { ReactNode } from 'react';
import Alert from 'react-bootstrap/Alert';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const CustomAlert = styled((
    { isTransparent, ...rest } // eslint-disable-line @typescript-eslint/no-unused-vars
) => <Alert {...rest} />)(
    ({ isTransparent }) => `
    ${isTransparent ? 'opacity: 0.5' : ''}`
);

export type IAlert = { cardBody?: ReactNode; cardHeader?: ReactNode; isTransparent?: boolean };
type IAlertInfoCardProps = { alerts?: IAlert[]; cardBody?: string; cardHeader?: string };
const AlertInfoCard = ({ alerts = [], cardHeader = '', cardBody = '' }: IAlertInfoCardProps) => {
    let alertsToRender = alerts;
    if (isEmpty(alerts)) {
        alertsToRender = [{ cardBody, cardHeader }];
    }
    return (
        <Card>
            <CardContent>
                {alertsToRender.map((alert, index) => (
                    <CustomAlert isTransparent={alert.isTransparent} key={index} variant="info">
                        <Alert.Heading className="my-1">{alert.cardHeader}</Alert.Heading>
                        {alert.cardBody}
                    </CustomAlert>
                ))}
            </CardContent>
        </Card>
    );
};

export default hot(module)(AlertInfoCard);
