import { Card, CardContent } from '@mui/material';
import { TaskItems, UserStatus } from 'api/generated/enums';
import useModalState from 'hooks/useModalState';
import useUserProps from 'hooks/useUserProps';
import AncillarySelectionModal from 'pages/dashboard/ancillaryBenefitSelection/AncillarySelectionModal';
import { completedTasksSelector } from 'pages/dashboard/dashboardSelectors';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const AncillarySelectionContent = () => {
    const { user } = useUserProps();
    const { isAncillaryBenefitSelectionComplete } = useSelector((state: AppStore) => {
        const isRenewing = user?.status === UserStatus.Renewing;
        return {
            isAncillaryBenefitSelectionComplete: completedTasksSelector(state).some((x) =>
                isRenewing
                    ? x.taskItemId === TaskItems.AncillaryBenefitsSelection_Renewing
                    : x.taskItemId === TaskItems.AncillaryBenefitsSelection
            ),
        };
    });
    const {
        closeModal: closeAncillarySelectionModal,
        isVisible: isAncillarySelectionModalVisible,
        openModal: openAncillarySelectionModal,
    } = useModalState();
    const buttonText = isAncillaryBenefitSelectionComplete ? 'View Selections' : 'Start';

    return (
        <Card sx={{ paddingY: 0 }}>
            <CardContent>
                <TaskListItem
                    action={openAncillarySelectionModal}
                    buttonText={buttonText}
                    complete={isAncillaryBenefitSelectionComplete}
                    data-cy="ancillary-benefit-selection-task"
                    description="Select your ancillary benefits for the year."
                    enabled
                    hideTopSeparator
                    showButton
                    showButtonWhenComplete
                    title="Select Ancillary Benefits"
                >
                    {isAncillarySelectionModalVisible && (
                        <AncillarySelectionModal
                            isReadonly={isAncillaryBenefitSelectionComplete}
                            onClose={closeAncillarySelectionModal}
                        />
                    )}
                </TaskListItem>
            </CardContent>
        </Card>
    );
};

export default hot(module)(AncillarySelectionContent);
