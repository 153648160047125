import { Grid, Stack, Typography } from '@mui/material';
import { completeTask } from 'actions/taskFlows/completeTask';
import {
    GET_SSN_TASK_CONTENT_ACTION,
    getSsnTaskContent,
} from 'actions/taskFlows/getSsnTaskContent';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { getHousehold } from 'actions/user/getHousehold';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { Tasks } from 'api/generated/enums';
import { TaskDto } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { ICHRA_LAUNCH_FLOW_INDEX } from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskList';
import {
    IchraFlowResponseType,
    MAX_WIDTH,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import CoverageSection from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/CoverageSection';
import HouseholdMembersSection from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/HouseholdMembersSection';
import PersonalInfoSection from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/PersonalInfoSection';
import PrimaryAddressSection from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/PrimaryAddressSection';
import YourJobSection from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/YourJobSection';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const HouseholdSummaryPage = () => {
    const dispatch = useThunkDispatch();
    const { isLoading, userFlows } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            LIST_VISIBLE_FLOWS_ACTION,
            GET_USER_PROFILE_ACTION,
            GET_SSN_TASK_CONTENT_ACTION
        ),
        userFlows: state.userFlows,
    }));
    const { userId, isCurrent } = useUserProps();
    const ichraLaunchFlow = userFlows[ICHRA_LAUNCH_FLOW_INDEX];
    const tasks = ichraLaunchFlow?.tasks?.find((t) => t.taskId === Tasks.EnterHouseholdInfo)
        ?.childTasks as TaskDto[];
    const currentTaskId = tasks.find((t) => t.taskId === Tasks.HouseholdSummary)
        ?.globalId as string;
    const ssnTaskId = tasks.find((t) => t.taskId === Tasks.SocialSecurityNumbers)
        ?.globalId as string;

    useEffect(() => {
        dispatch(getSsnTaskContent(ssnTaskId));
    }, [dispatch, ssnTaskId]);

    const handleSubmit = async () => {
        const response: IchraFlowResponseType = undefined;
        await dispatch(completeTask(currentTaskId, response));
        await dispatch(getHousehold(userId, isCurrent));
    };

    return (
        <Stack maxWidth={MAX_WIDTH} rowGap={6} width="100%">
            <Grid item textAlign="center" xs={12}>
                <IchraFlowTitle title="Summary" />
                <Typography variant="body2">
                    Please review the information and submit below.
                </Typography>
            </Grid>
            <Skeleton
                isEnabled={isLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 375,
                            sx: { mb: 3 },
                            width: '100%',
                        },
                        {
                            columnProps: { flex: 1 },
                            count: 2,
                            height: 175,
                            sx: { mb: 3 },
                            width: '100%',
                        },
                    ],
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 275,
                            sx: { mb: 3 },
                            width: '100%',
                        },
                    ],
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 225,
                            sx: { mb: 3 },
                            width: '100%',
                        },
                    ],
                ]}
            >
                <Grid columnSpacing={12} container item rowGap={6} xs={12}>
                    <Grid container item md={6} xs={12}>
                        <PersonalInfoSection tasks={tasks} />
                    </Grid>
                    <Grid container item md={6} rowSpacing={6} xs={12}>
                        <YourJobSection tasks={tasks} />
                        <PrimaryAddressSection tasks={tasks} />
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <HouseholdMembersSection tasks={tasks} />
                </Grid>
                <Grid container item xs={12}>
                    <CoverageSection tasks={tasks} />
                </Grid>
            </Skeleton>
            <IchraFlowButtons
                currentTaskId={currentTaskId}
                forwardButtonTextOverride="Submit"
                handleNext={handleSubmit}
                isLoading={isLoading}
                showPreviousButton
            />
        </Stack>
    );
};

export default hot(module)(HouseholdSummaryPage);
