import Chip from '@mui/material/Chip';
import { PathwayUserTags } from 'api/generated/enums';
import { IUser } from 'api/generated/models';
import { ROLE_IDS } from 'api/generated/roleIds';
import AdminChip from 'components/chip/AdminChip';
import ChipChip from 'components/chip/ChipChip';
import MedicaidChip from 'components/chip/MedicaidChip';
import MedicareChip from 'components/chip/MedicareChip';
import UnsupportedStateBasedExchangeChip from 'components/chip/StateBasedExchangeChip';
import TaxCreditsChip from 'components/chip/TaxCreditsChip';
import VipChip from 'components/chip/VipChip';
import IneligibleRecommendedPlansBadge from 'pages/pathwayBlueprint/PathwayRecommendedPlans/IneligibleRecommendedPlansBadge';
import { default as React } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasFlag } from 'utilities';

export const PathwayTagsCell = ({
    row: {
        original: { user },
    },
    value: tags,
}: Cell<{ user?: IUser }, PathwayUserTags>) => {
    const { isTeamAdmin, pathwayBlueprint } = useSelector((state: AppStore) => ({
        isTeamAdmin: state.teamUserRoles
            .filter((u) => u.roleIds?.some((x) => x === ROLE_IDS.TEAM_ADMIN))
            .some((u) => u.userId === user?.userId),
        pathwayBlueprint: state.pathwayBlueprint,
    }));

    const badges = [];
    if (user?.isVip) {
        badges.push(<VipChip sx={{ mb: 0.5, ml: 1 }} />);
    }
    if (isTeamAdmin) {
        badges.push(<AdminChip key="teamAdmin" sx={{ mb: 0.5, ml: 1 }} />);
    }
    if (hasFlag(tags, PathwayUserTags.TaxCredits)) {
        badges.push(<TaxCreditsChip sx={{ mb: 0.5, ml: 1 }} />);
    }
    if (hasFlag(tags, PathwayUserTags.LostTaxCredits)) {
        badges.push(
            <Chip
                color="error"
                key="lostTaxCredits"
                label="Lost Tax Credits"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.IneligiblePlans)) {
        badges.push(
            <IneligibleRecommendedPlansBadge pathwayBlueprint={pathwayBlueprint} user={user} />
        );
    }
    if (hasFlag(tags, PathwayUserTags.CHIP)) {
        badges.push(<ChipChip sx={{ mb: 0.5, ml: 1 }} />);
    }
    if (hasFlag(tags, PathwayUserTags.LostCHIP)) {
        badges.push(
            <Chip
                color="error"
                key="lostChip"
                label="Lost CHIP"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.CSR)) {
        badges.push(
            <Chip color="success" key="csr" label="CSR" size="small" sx={{ mb: 0.5, ml: 1 }} />
        );
    }
    if (hasFlag(tags, PathwayUserTags.LostCSR)) {
        badges.push(
            <Chip
                color="error"
                key="lostCsr"
                label="Lost CSR"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.Ineligible)) {
        badges.push(
            <Chip
                color="warning"
                key="ineligible"
                label="Ineligible"
                size="small"
                sx={{
                    backgroundColor: (theme) => theme.palette.warning.light,
                    color: 'grey.900',
                    mb: 0.5,
                    ml: 1,
                }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.MarriedFilingSeparately)) {
        badges.push(
            <Chip
                color="default"
                key="marriedFilingSeparately"
                label="Married Filing Separately"
                size="small"
                sx={{
                    backgroundColor: (theme) => theme.palette.neutral.main,
                    color: 'grey.900',
                    mb: 0.5,
                    ml: 1,
                }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.Medicaid)) {
        badges.push(<MedicaidChip sx={{ mb: 0.5, ml: 1 }} />);
    }
    if (
        hasFlag(tags, PathwayUserTags.Medicare) ||
        hasFlag(tags, PathwayUserTags.MedicareHousehold)
    ) {
        badges.push(<MedicareChip sx={{ mb: 0.5, ml: 1 }} />);
    }
    if (hasFlag(tags, PathwayUserTags.Parent)) {
        badges.push(
            <Chip color="dark" key="parent" label="Parent" size="small" sx={{ mb: 0.5, ml: 1 }} />
        );
    }
    if (hasFlag(tags, PathwayUserTags.SpouseEmployer)) {
        badges.push(
            <Chip color="dark" key="spouse" label="Spouse" size="small" sx={{ mb: 0.5, ml: 1 }} />
        );
    }
    if (hasFlag(tags, PathwayUserTags.UnsupportedStateBasedExchangeIneligible)) {
        badges.push(<UnsupportedStateBasedExchangeChip sx={{ mb: 0.5, ml: 1 }} />);
    }
    if (hasFlag(tags, PathwayUserTags.Unclaimed)) {
        badges.push(
            <Chip
                color="dark"
                key="unclaimed"
                label="Unclaimed"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.MedicaidGap)) {
        badges.push(
            <Chip
                color="error"
                key="medicaidGap"
                label="Medicaid Gap"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.LeftMedicaidGap)) {
        badges.push(
            <Chip
                color="error"
                key="leftMedicaidGap"
                label="Left Medicaid Gap"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.WaivingTaxCredits)) {
        badges.push(
            <Chip
                color="warning"
                key="waivingTaxCredits"
                label="Waiving Tax Credits"
                size="small"
                sx={{
                    backgroundColor: (theme) => theme.palette.warning.light,
                    color: 'grey.900',
                    mb: 0.5,
                    ml: 1,
                }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.DependentOver25)) {
        badges.push(
            <Chip
                color="dark"
                key="dependentOver25"
                label="Dependent Over 25"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.WageUpFloor)) {
        badges.push(
            <Chip
                color="dark"
                key="wageUpFloor"
                label="Wage+ Floor"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.LostMedicaid)) {
        badges.push(
            <Chip
                color="error"
                key="lostMedicaid"
                label="Lost Medicaid"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.UnsupportedOffExchange)) {
        badges.push(
            <Chip
                color="primary"
                key="unsupportedOffExchange"
                label="Unsupported Off-Exchange"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.TobaccoUse)) {
        badges.push(
            <Chip
                color="dark"
                key="tobaccoUse"
                label="Tobacco Use"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.IchraUnaffordable)) {
        badges.push(
            <Chip
                color="warning"
                key="ichraUnaffordable"
                label="ICHRA Unaffordable"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }
    if (hasFlag(tags, PathwayUserTags.AffordabilityUnknown)) {
        badges.push(
            <Chip
                color="primary"
                key="ichraUnaffordable"
                label="Affordability Unknown"
                size="small"
                sx={{ mb: 0.5, ml: 1 }}
            />
        );
    }

    return <div>{...badges}</div>;
};

export default hot(module)(PathwayTagsCell);
