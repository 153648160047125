import { GET_SELECTED_PLANS_FOR_RENEWAL_ACTION } from 'actions/selectedPlan/getSelectedPlansForRenewal';
import { TaskItems } from 'api/generated/enums';
import { ITaskItem } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import useUserProps from 'hooks/useUserProps';
import EnterSocialSecurityTask from 'pages/dashboard/taskLists/member/tasks/EnterSocialSecurityTask';
import KeepOrChangeBenefitsTask from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitsTask';
import ReviewMemberInfoTask from 'pages/dashboard/taskLists/member/tasks/ReviewMemberInfoTask';
import ViewActiveBenefitsTask from 'pages/dashboard/taskLists/member/tasks/ViewActiveBenefitsTask';
import { ITaskListItemProps } from 'pages/dashboard/taskLists/TaskListItem';
import { getIsViewActiveBenefitsVisible } from 'pages/dashboard/taskListSelectors';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

type IMemberRenewalStandardSubtaskList = {
    completedTasks: ITaskItem[];
    enableAcceptServices: boolean;
    enableKeepOrChangeOrViewActive: boolean;
    isAcceptRHServicesComplete: boolean;
    isEnterSsnComplete: boolean;
    isKeepOrChangeBenefitsComplete: boolean;
    isReviewMemberInfoComplete: boolean;
    reviewDescription: string;
    reviewInfoTitle: string;
    showKeepOrChange: boolean;
    teamId: string;
};

const KeepChangeOrViewActive = (props: Partial<ITaskListItemProps>) => {
    const { isViewActiveBenefitsVisible } = useSelector((state: AppStore) => ({
        isViewActiveBenefitsVisible: getIsViewActiveBenefitsVisible(state),
    }));

    if (isViewActiveBenefitsVisible) {
        return <ViewActiveBenefitsTask {...props} />;
    }
    return <KeepOrChangeBenefitsTask complete={props.complete} enabled={props.enabled} />;
};

const MemberRenewalStandardSubtaskList = ({
    completedTasks,
    enableKeepOrChangeOrViewActive,
    isAcceptRHServicesComplete,
    isEnterSsnComplete,
    isKeepOrChangeBenefitsComplete,
    isReviewMemberInfoComplete,
    reviewDescription,
    reviewInfoTitle,
    showKeepOrChange,
}: IMemberRenewalStandardSubtaskList) => {
    const { isInIchraPathway } = useUserProps();
    const { isLoadingPlans } = useSelector((state: AppStore) => ({
        isLoadingPlans: hasApiActivity(state, GET_SELECTED_PLANS_FOR_RENEWAL_ACTION),
    }));

    return (
        <React.Fragment>
            <ReviewMemberInfoTask
                acceptRHServicesTask={TaskItems.AcceptRHServices_Renewing}
                actionButtonText="Start"
                complete={isReviewMemberInfoComplete}
                completedTasks={completedTasks}
                description={reviewDescription}
                enabled={isAcceptRHServicesComplete}
                isIchraPathway={isInIchraPathway}
                isRenewal
                key={TaskItems.ReviewMemberInfo_Renewing}
                title={reviewInfoTitle}
            />

            <EnterSocialSecurityTask
                acceptRHServicesTask={TaskItems.AcceptRHServices_Renewing}
                complete={isEnterSsnComplete}
                completedTasks={completedTasks}
                enabled={isReviewMemberInfoComplete}
                key={TaskItems.EnterSocialSecurityNumbers_Renewing}
                taskItemId={TaskItems.EnterSocialSecurityNumbers_Renewing}
            />
            <Skeleton count={1} height="51px" isEnabled={isLoadingPlans} key="LoadingKeepOrChange">
                {showKeepOrChange && (
                    <KeepChangeOrViewActive
                        complete={isKeepOrChangeBenefitsComplete}
                        enabled={enableKeepOrChangeOrViewActive}
                        key={TaskItems.KeepOrChangeBenefits_Renewing}
                    />
                )}
            </Skeleton>
        </React.Fragment>
    );
};

export default hot(module)(MemberRenewalStandardSubtaskList);
