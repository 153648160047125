import { getSingleFilerStandardDeduction } from 'actions/taxData/getSingleFilerStandardDeduction';
import { SurveyTypes } from 'api/generated/enums';
import { IMemberVerifiedInfo } from 'api/generated/models';
import EditableCurrencyAttribute from 'components/EditableCurrencyAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import ProfileAttribute from 'components/ProfileAttribute';
import useContributionProps from 'hooks/useContributionProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import find from 'lodash/find';
import get from 'lodash/get';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import EditHouseholdIncomeModal from 'pages/profile/EditHouseholdIncomeModal';
import { PerLabel } from 'pages/profile/IncomeInfo';
import MayIncludeAdditionalIncomeMessage from 'pages/profile/MayIncludeAdditionalIncomeMessage';
import { hideHouseholdIncomeModal, showHouseholdIncomeModal } from 'pages/profile/profileActions';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { calculateHouseholdIncome } from 'utilities/household';
import { API_DATE_FORMAT } from 'utilities/moment';
import { isIchraPathwayType } from 'utilities/pathways';
import { string } from 'yup';

const MONTHS_IN_A_YEAR = 12;

const PerYearLabel = ({ children }: { children: ReactNode }) =>
    PerLabel({ children, unit: 'year' });

const handleShouldDisplayHouseholdIncomeWithWageup = (
    hasProjectedWageUpButNoActiveWageUp: boolean,
    shouldDisplayActiveContributionProfileAttribute: boolean,
    isIchraPathway: boolean,
    hasCompletedStandardSurvey: boolean
) =>
    !hasProjectedWageUpButNoActiveWageUp &&
    shouldDisplayActiveContributionProfileAttribute &&
    !isIchraPathway &&
    hasCompletedStandardSurvey;

type IIncomeInfoProps = RouteComponentProps & {
    verifiedInfoCommonProps: ICommonProps<IMemberVerifiedInfo>;
};
const IncomeVerifiedInfo = ({ verifiedInfoCommonProps }: IIncomeInfoProps) => {
    const dispatch = useThunkDispatch();
    const { memberVerifiedInfo, user } = useUserProps();
    const {
        activeContribution,
        projectedWageUp,
        shouldDisplayActiveContributionProfileAttribute,
    } = useContributionProps();
    const activeWageUpAmount = activeContribution?.wageUp;
    const {
        filingStatusOptions,
        householdMembers,
        isIchraPathway,
        isModalVisible,
        selectedYear,
        singleFilerStandardDeduction,
        userBudget,
    } = useSelector((state: AppStore) => ({
        filingStatusOptions: state.options.filingStatusOptions,
        householdMembers: state.householdMembers,
        isIchraPathway: isIchraPathwayType(state.pathwayUser?.pathwayType),
        isModalVisible: state.profileState.isHouseholdIncomeModalVisible,
        selectedYear: +state.profileState.selectedYear,
        singleFilerStandardDeduction: state.singleFilerStandardDeduction,
        userBudget: state.userBudget,
    }));
    const [filingStatusId, setFilingStatusId] = useState(
        memberVerifiedInfo?.filingStatusId.toString() ?? ''
    );
    const [employeeIncome, setEmployeeIncome] = useState(
        memberVerifiedInfo?.employeeIncome.toString() ?? ''
    );
    const [additionalIncome, setAdditionalIncome] = useState(
        memberVerifiedInfo?.additionalIncome?.toString() ?? ''
    );

    useEffect(() => {
        if (selectedYear > 0) {
            dispatch(getSingleFilerStandardDeduction(selectedYear));
        }
    }, [dispatch, selectedYear]);

    useEffect(() => {
        if (memberVerifiedInfo?.employeeIncome) {
            setEmployeeIncome(memberVerifiedInfo.employeeIncome.toString());
        }
    }, [memberVerifiedInfo]);
    useEffect(() => {
        setAdditionalIncome(memberVerifiedInfo?.additionalIncome?.toString() ?? '');
    }, [memberVerifiedInfo]);
    const filingStatusFormatter = useCallback(
        (value) =>
            get(
                find(filingStatusOptions, (o) => o.filingStatusId.toString() === value),
                'name',
                'N/A'
            ),
        [filingStatusOptions]
    );
    const close = useCallback(() => dispatch(hideHouseholdIncomeModal()), [dispatch]);
    const open = useCallback(() => dispatch(showHouseholdIncomeModal()), [dispatch]);
    const numberOfActiveMonths = useMemo(() => {
        const activeDate = user?.activeDate?.toMomentDate(API_DATE_FORMAT, false);
        if (activeDate?.year() === memberVerifiedInfo?.year) {
            return MONTHS_IN_A_YEAR - (activeDate?.month() as number);
        }
        return MONTHS_IN_A_YEAR;
    }, [memberVerifiedInfo?.year, user?.activeDate]);
    const householdIncome = useMemo(
        () =>
            calculateHouseholdIncome(
                memberVerifiedInfo,
                householdMembers,
                singleFilerStandardDeduction
            ),
        [householdMembers, memberVerifiedInfo, singleFilerStandardDeduction]
    );
    const householdIncomeWithWageIncrease = useMemo(
        () =>
            formatCurrency(
                householdIncome + userBudget.supplementalWageIncrease * numberOfActiveMonths
            ),
        [householdIncome, numberOfActiveMonths, userBudget.supplementalWageIncrease]
    );
    const householdIncomeWithWageUp = useMemo(
        () => formatCurrency(householdIncome + (activeWageUpAmount ?? 0) * numberOfActiveMonths),
        [activeWageUpAmount, householdIncome, numberOfActiveMonths]
    );
    const hasActiveWageUp = hasValue(activeWageUpAmount);
    const hasProjectedWageUpButNoActiveWageUp = !hasActiveWageUp && hasValue(projectedWageUp);
    const hasCompletedStandardSurvey =
        memberVerifiedInfo?.surveyTypeCompleted === SurveyTypes.Standard;
    const shouldDisplayHouseholdIncomeWithWageUp = handleShouldDisplayHouseholdIncomeWithWageup(
        hasProjectedWageUpButNoActiveWageUp,
        shouldDisplayActiveContributionProfileAttribute,
        isIchraPathway,
        hasCompletedStandardSurvey
    );

    return (
        <React.Fragment>
            {hasCompletedStandardSurvey && (
                <EditableSelectAttribute
                    {...verifiedInfoCommonProps}
                    formatter={filingStatusFormatter}
                    items={filingStatusOptions}
                    label="Filing Status"
                    name="filingStatusId"
                    onChange={onChange(setFilingStatusId)}
                    optionText="name"
                    optionValue="filingStatusId"
                    validationSchema={string().label('Filing Status')}
                    value={filingStatusId}
                />
            )}
            <EditableCurrencyAttribute
                {...verifiedInfoCommonProps}
                footer={<MayIncludeAdditionalIncomeMessage />}
                label="Employee Salary"
                name="employeeIncome"
                onChange={onChange(setEmployeeIncome)}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Employee Salary')}
                value={employeeIncome}
            />
            {hasCompletedStandardSurvey && (
                <React.Fragment>
                    <EditableCurrencyAttribute
                        {...verifiedInfoCommonProps}
                        infoTooltip="Income earned outside of primary employment"
                        isOptional
                        label="Additional Income"
                        name="additionalIncome"
                        onChange={onChange(setAdditionalIncome)}
                        validationSchema={string()
                            .trim()
                            .label('Additional Income')}
                        value={additionalIncome}
                    />
                    <EditableProfileAttribute
                        content={formatCurrency(householdIncome)}
                        label={<PerYearLabel>Household Income</PerYearLabel>}
                        onClick={open}
                        readonly={verifiedInfoCommonProps.readonly}
                    />
                </React.Fragment>
            )}

            {shouldDisplayHouseholdIncomeWithWageUp && (
                <ProfileAttribute
                    infoTooltip={`Active year is based on number of months the member is active. This member will be active for ${numberOfActiveMonths} month${
                        numberOfActiveMonths > 1 ? 's' : ''
                    } this year.`}
                    label={
                        <span>
                            Household Income with {hasActiveWageUp ? 'Wage+ ' : 'Wage Increase '}
                            <small>for active year</small>
                        </span>
                    }
                >
                    {hasActiveWageUp ? householdIncomeWithWageUp : householdIncomeWithWageIncrease}
                </ProfileAttribute>
            )}
            {isModalVisible && <EditHouseholdIncomeModal close={close} />}
        </React.Fragment>
    );
};

export default hot(module)(withRouter(IncomeVerifiedInfo));
