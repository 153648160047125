import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    HealthCareTypeIds,
    PayrollReportChangeDiffType,
    PlanTypeIds,
    TeamBenefitTypes,
} from 'api/generated/enums';
import {
    IPayrollReportDto,
    IPayrollReportUserChange,
    IPayrollReportUserChangeLog,
    IPayrollReportUserSnapshotDto,
} from 'api/generated/models';
import { MONTHS } from 'constants/date';
import startCase from 'lodash/startCase';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';
import {
    MAJOR_MEDICAL_BENEFIT_TYPES_PROPERTY_NAME,
    getIsMajorMedicalProperty,
} from 'utilities/payrollReports';
import { getPlanTypeName } from 'utilities/plans';

const checkValueAndReplace = (
    value: string,
    textToBeReplaced: string,
    textToReplaceWith: string
) => {
    if (value.includes(textToBeReplaced)) {
        value = value.replace(textToBeReplaced, textToReplaceWith);
    }
    return value;
};

const getPropertyText = ({
    benefitType,
    healthCareType,
    planType,
    property,
}: IPayrollReportUserChange) => {
    let value = startCase(property);
    if (planType === PlanTypeIds.Ancillary) {
        value = startCase(HealthCareTypeIds[healthCareType as HealthCareTypeIds]);
    }
    if (hasValue(benefitType)) {
        value = startCase(TeamBenefitTypes[benefitType]);
    }
    if (hasValue(planType)) {
        value = getPlanTypeName(planType);
    }
    if (property === 'reimbursablePremiumAdjustment') {
        value += ' Adjustment';
    }
    value = checkValueAndReplace(value, 'Wage Up', 'Wage+');
    value = checkValueAndReplace(value, 'Wage Plus', 'Wage+');
    value = checkValueAndReplace(value, 'Post Tax', 'Post-Tax');
    value = checkValueAndReplace(value, 'Pre Tax', 'Pre-Tax');
    value = checkValueAndReplace(value, 'Medi Share', 'Medi-Share');
    value = checkValueAndReplace(value, 'Hsa', 'HSA');
    value = checkValueAndReplace(value, 'Tax Free', 'Tax-Free');

    return value;
};
const getChangeText = (change: IPayrollReportUserChange) => {
    const { diffType, newValue, oldValue } = change;
    switch (diffType) {
        case PayrollReportChangeDiffType.Added:
            return `${getPropertyText(change)} with value of ${newValue} was added`;
        case PayrollReportChangeDiffType.Changed:
            return `${getPropertyText(change)} changed from ${oldValue} to ${newValue}`;
        case PayrollReportChangeDiffType.Removed:
            return `${getPropertyText(change)} with value of ${oldValue} was removed`;
        default:
            return '';
    }
};

const getTitleText = (change: IPayrollReportUserChange) => {
    const { diffType, newValue, oldValue, property } = change;
    const isAdded = diffType === PayrollReportChangeDiffType.Added;
    const isMultiple = oldValue?.includes(',');
    const oldValueDisplay = !hasValue(oldValue) ? 'None' : oldValue;
    const newValueDisplay = !hasValue(newValue) ? 'None' : newValue;
    const value = isAdded
        ? `Total ${getPropertyText(change)} is ${newValue}`
        : `Total ${getPropertyText(change)} changed from ${oldValue} to ${newValue}`;
    switch (property?.toLowerCase()) {
        case 'member':
            return 'Member values have changed';
        case 'hsa':
            return isAdded ? 'HSA values were added' : 'HSA values have changed';
        case 'fsa':
            return isAdded ? 'FSA values were added' : 'FSA values have changed';
        case MAJOR_MEDICAL_BENEFIT_TYPES_PROPERTY_NAME:
            return isMultiple
                ? `Major Medical Benefit Types have changed from ${oldValueDisplay} to ${newValueDisplay}`
                : `Major Medical Benefit Type has changed from ${oldValueDisplay} to ${newValueDisplay}`;
        default:
            return value;
    }
};

const renderUserChangeLog = (userChangeLog: IPayrollReportUserChangeLog) =>
    userChangeLog.changes?.map((userChanges) => (
        <div key={`${userChangeLog.payrollReportUserSnapshotId}-${userChanges.property}`}>
            <Typography variant="h4">{getTitleText(userChanges)}</Typography>
            {getIsMajorMedicalProperty(userChanges.property) ? (
                <React.Fragment />
            ) : (
                <ul>
                    {userChanges.changes?.map((nestedUserChanges, index) => (
                        <li
                            key={`${userChangeLog.payrollReportUserSnapshotId}-${userChanges.property}-${nestedUserChanges.property}-${index}`}
                        >
                            {getChangeText(nestedUserChanges)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    ));

export const getPayrollReportUserSnapshotChangeLogContent = (
    userChangeLog: IPayrollReportUserChangeLog
) => {
    const { diffType, firstName, lastName } = userChangeLog;
    switch (diffType) {
        case PayrollReportChangeDiffType.Added:
            return (
                <React.Fragment>
                    <Typography variant="h4">
                        {firstName} {lastName} was added
                    </Typography>
                    {renderUserChangeLog(userChangeLog)}
                </React.Fragment>
            );
        case PayrollReportChangeDiffType.Changed:
            return renderUserChangeLog(userChangeLog);
        case PayrollReportChangeDiffType.Removed:
            return (
                <Typography variant="h4">
                    {firstName} {lastName} was removed
                </Typography>
            );
        default:
            return <React.Fragment />;
    }
};

type ChangeLogWithSnapshot = {
    changeLog: IPayrollReportUserChangeLog;
    userSnapshot?: IPayrollReportUserSnapshotDto;
};

type IPayrollReportChangeLogModal = {
    close: () => void;
    payrollReport: IPayrollReportDto | undefined;
};

const PayrollReportChangeLogModal = ({ close, payrollReport }: IPayrollReportChangeLogModal) => {
    const currentMonthIndex = (payrollReport?.month ?? NaN) - 1;
    const previousMonthIndex = currentMonthIndex - 1;
    const previousMonth = MONTHS.slice(previousMonthIndex)[0]?.name;
    const previousYear = previousMonthIndex === -1 ? ` ${(payrollReport?.year ?? NaN) - 1}` : '';
    const currentMonth = MONTHS[currentMonthIndex]?.name;
    const snapshotsWithChanges = useMemo(() => {
        const changes =
            payrollReport?.changes?.map<ChangeLogWithSnapshot>((changeLog) => ({
                changeLog,
                userSnapshot: payrollReport?.payrollReportUserSnapshots?.find(
                    (p) => p.id === changeLog.payrollReportUserSnapshotId
                ),
            })) ?? [];
        const snapshotWithNoteAndNoChanges =
            payrollReport?.payrollReportUserSnapshots
                ?.filter(
                    (x) => hasValue(x.note) && changes?.every((c) => c.userSnapshot?.id !== x.id)
                )
                .map<ChangeLogWithSnapshot>((x) => ({
                    changeLog: ({} as unknown) as IPayrollReportUserChangeLog,
                    userSnapshot: x,
                })) ?? [];
        const getLastName = (change: ChangeLogWithSnapshot) =>
            change?.changeLog?.lastName?.toLowerCase() ??
            change?.userSnapshot?.lastName?.toLowerCase();
        return [...changes, ...snapshotWithNoteAndNoChanges].sort(
            (a, b) => getLastName(a)?.localeCompare(getLastName(b) as string) as number
        );
    }, [payrollReport]);
    const changeLogs = useMemo(
        () =>
            snapshotsWithChanges.map(({ changeLog, userSnapshot }) => {
                const firstName = userSnapshot?.firstName ?? changeLog.firstName;
                const lastName = userSnapshot?.lastName ?? changeLog.lastName;
                return (
                    <Card key={`${userSnapshot?.id}-${firstName}-${lastName}`}>
                        <CardHeader
                            title={`${firstName} ${lastName}`}
                            titleTypographyProps={{
                                fontSize: '1.5rem',
                                fontWeight: '400',
                                margin: '0',
                            }}
                        />
                        <CardContent>
                            {getPayrollReportUserSnapshotChangeLogContent(changeLog)}
                            {hasValue(userSnapshot?.note) && (
                                <React.Fragment>
                                    <Divider sx={{ mb: 3 }} />
                                    <Box sx={{ whiteSpace: 'pre-wrap' }}>{userSnapshot?.note}</Box>
                                </React.Fragment>
                            )}
                        </CardContent>
                    </Card>
                );
            }),
        [snapshotsWithChanges]
    );
    return (
        <Dialog fullWidth onClose={close} open>
            <DialogTitle>
                Change Log ({previousMonth}
                {previousYear} - {currentMonth} {payrollReport?.year})
            </DialogTitle>
            <DialogContent dividers>
                <Stack gap={2} pt={1}>
                    {snapshotsWithChanges.length === 0 && (
                        <Typography align="center" variant="h6">
                            There are no changes
                        </Typography>
                    )}
                    {changeLogs}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="text">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(PayrollReportChangeLogModal);
