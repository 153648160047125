import { editUser, EDIT_USER_ACTION } from 'actions/user/editUser';
import { IMemberQuoteInfo, IUser, IUserProfile } from 'api/generated/models';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import UserForm from 'pages/addPerson/UserForm';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const EditPersonPage = () => {
    const dispatch = useThunkDispatch();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { userId } = useUserProps();
    const { apiError, apiErrorStatusCode } = useSelector((state: AppStore) => ({
        apiError: !!state.apiErrors[EDIT_USER_ACTION.statusCode],
        apiErrorStatusCode: state.apiErrors[EDIT_USER_ACTION.statusCode],
    }));
    const handleSubmit = async (
        { user, address, memberQuoteInfo, yearlyUserInfo }: IUserProfile,
        isCustomer: boolean
    ) => {
        (user as IUser).userId = userId;
        if (isCustomer) {
            (memberQuoteInfo as IMemberQuoteInfo).userId = userId;
            await dispatch(
                editUser(
                    { address, memberQuoteInfo, user, yearlyUserInfo } as IUserProfile,
                    isTeamManagementPage
                )
            );
        } else {
            await dispatch(editUser({ user } as IUserProfile, isTeamManagementPage));
        }
    };

    return (
        <UserForm
            apiError={apiError}
            apiErrorStatusCode={apiErrorStatusCode as number}
            handleSubmit={handleSubmit}
            isEditMode
        />
    );
};

export default hot(module)(EditPersonPage);
