import Typography from '@mui/material/Typography';
import { saveCompletedTeamTask } from 'actions/task/saveCompletedTeamTask';
import { transitionToReadyForMemberLaunch } from 'actions/team/transitionToReadyForMemberLaunch';
import { GET_USER_PROFILES_ACTION } from 'actions/user/getUserProfiles';
import { TaskItems } from 'api/generated/enums';
import { IUserProfile } from 'api/generated/models';
import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import NameTableCell from 'components/NameTableCell';
import ReactTable from 'components/reactTable/ReactTable';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Cell, Column } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const NameCell = ({ row }: Cell<IUserProfile>) => <NameTableCell userProfile={row.original} />;

type ITeamRosterModalProps = {
    handleIncorrectClick: () => void;
    onHide: () => void;
    userProfiles: IUserProfile[];
};

const TeamRosterModal = ({ handleIncorrectClick, onHide, userProfiles }: ITeamRosterModalProps) => {
    const dispatch = useThunkDispatch();
    const { isLoadingUserProfiles } = useSelector((state: AppStore) => ({
        isLoadingUserProfiles: hasApiActivity(state, GET_USER_PROFILES_ACTION),
    }));

    const [showActivity, setShowActivity] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const teamId = userProfiles[0]?.user?.teamId;
        setShowActivity(true);
        await dispatch(saveCompletedTeamTask(TaskItems.VerifyTeamRoster, teamId as string));
        await dispatch(transitionToReadyForMemberLaunch(teamId as string));
        setShowActivity(false);
        onHide();
    };

    const columns = useMemo<Column<IUserProfile>[]>(
        () => [
            {
                accessor: (up) => `${up.user?.lastName} ${up.user?.firstName}`,
                Cell: NameCell,
                Header: 'Name',
                width: 200,
            },
            {
                accessor: (up) => up.user?.email,
                Cell: ({ value }: Cell<IUserProfile>) => value,
                Header: 'Email',
                width: 400,
            },
        ],
        []
    );

    return (
        <Modal onHide={onHide} show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Verify Roster</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Typography textAlign="center" variant="h4">
                    Total Employees: {userProfiles.length}
                </Typography>
                <ReactTable columns={columns} data={userProfiles} loading={isLoadingUserProfiles} />
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button data-cy="somethings-not-right" onClick={handleIncorrectClick}>
                    Something is incorrect
                </Button>
                <Button data-cy="looks-good" onClick={handleSubmit} type="submit">
                    Looks good!
                </Button>
            </Modal.Footer>
            {showActivity && <ActivityIndicator />}
        </Modal>
    );
};

export default hot(module)(TeamRosterModal);
