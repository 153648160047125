import CurrencyTextField from 'components/CurrencyTextField';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import CalculateIncomeInformationModal from 'pages/survey/informationModals/CalculateIncomeInformationModal';
import InfoText from 'pages/survey/InfoText';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveIncomeForm } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';

type IIncomeFormProps = Omit<IStepProps, 'userProfile'> & {
    'data-cy': string;
    ModalToShow?: FunctionComponent<{ close: () => void }>;
    description?: ReactNode;
    hideInfoText?: boolean;
    incomeProp: string;
    infoText?: string;
    infoTextClassName?: string;
    isInfoTextAbove?: boolean;
    label: string;
    title: ReactNode;
};

const IncomeForm = ({
    children,
    commonProps,
    'data-cy': dataCy,
    description,
    hideInfoText = false,
    incomeProp,
    infoText = 'How do I calculate income?',
    infoTextClassName,
    isInfoTextAbove,
    ModalToShow = CalculateIncomeInformationModal,
    label,
    title,
}: IIncomeFormProps) => {
    const dispatch = useThunkDispatch();
    const { initialIncome } = useSelector((state: AppStore) => ({
        initialIncome: state.surveyState.income[incomeProp],
    }));
    const { openModal, closeModal, isVisible } = useModalState();
    const [income, setIncome] = useState(initialIncome);

    const onSubmit = () => {
        dispatch(saveIncomeForm({ [incomeProp]: income }));
        commonProps.onSubmit();
    };
    const infoTextComponent = !hideInfoText && (
        <InfoText className={infoTextClassName} onClick={openModal}>
            {infoText}
        </InfoText>
    );
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={!hasValue(income)} onSubmit={onSubmit}>
            {isVisible && <ModalToShow close={closeModal} />}
            {children}
            <FormTitle description={description}>{title}</FormTitle>
            <Row className="justify-content-center">
                <Col md="7">
                    {isInfoTextAbove && infoTextComponent}
                    <CurrencyTextField
                        autoFocus
                        className="mb-1"
                        data-cy={dataCy}
                        label={label}
                        onChange={onChange(setIncome)}
                        value={income}
                    />
                    {!isInfoTextAbove && infoTextComponent}
                </Col>
            </Row>
        </SurveyFormWrapper>
    );
};

export default hot(module)(IncomeForm);
