import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { clearYearlyReimbursementReport } from 'actions/clear';
import {
    GET_YEARLY_REIMBURSEMENT_REPORT_ACTION,
    getYearlyReimbursementReport,
} from 'actions/reimbursementReport/getYearlyReimbursementReport';
import { downloadYearlyReimbursementReportRhFile } from 'api/downloadReimbursementReportRhFile';
import {
    DownloadReimbursementReportRhFile,
    ViewSubmittedExpenses,
} from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import PageHeader from 'components/PageHeader';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import TextField from 'components/TextField';
import { push } from 'connected-react-router';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import moment from 'moment';
import ReimbursementYearlyReportTable from 'pages/reimbursementReports/ReimbursementYearlyReportTable';
import { setReimbursementReportSelectedYear } from 'pages/reimbursementReports/reimbursementReportActions';
import { default as React, useCallback, useContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { REIMBURSEMENT_REPORTS_PATH, TEAMS_REIMBURSEMENT_REPORTS_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { downloadFileFromApi } from 'utilities/rhFile';
import { years } from 'utilities/year';

const ReimbursementYearlyReportPage = () => {
    const dispatch = useThunkDispatch();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const {
        canDownloadReimbursementReportRhFile,
        canViewTermButton,
        isLoading,
        selectedYear,
    } = useSelector((state: AppStore) => ({
        canDownloadReimbursementReportRhFile: hasSomePermissions(
            state,
            DownloadReimbursementReportRhFile
        ),
        canViewTermButton: hasSomePermissions(state, ViewSubmittedExpenses),
        isLoading: hasApiActivity(state, GET_YEARLY_REIMBURSEMENT_REPORT_ACTION),
        selectedYear: hasValue(state.reimbursementReportState.reimbursementReportSelectedYear)
            ? state.reimbursementReportState.reimbursementReportSelectedYear
            : moment()
                  .year()
                  .toString(),
    }));

    const { teamId } = useTeamProps();
    const [search, setSearch] = useState('');
    const [
        isReimbursementReportRhFileDownloading,
        setIsReimbursementReportRhFileDownloading,
    ] = useState(false);

    useEffect(() => {
        dispatch(getYearlyReimbursementReport(teamId, Number(selectedYear)));
    }, [dispatch, selectedYear, teamId]);

    useEffect(
        () => () => {
            dispatch(setReimbursementReportSelectedYear(''));
            dispatch(clearYearlyReimbursementReport());
        },
        [dispatch]
    );

    const onSelectedYearChange = useCallback(
        ({ target: { value } }) => {
            dispatch(setReimbursementReportSelectedYear(value));
        },
        [dispatch]
    );

    const navigateToTermReports = () =>
        isTeamManagementPage
            ? dispatch(
                  push(
                      generatePath(TEAMS_REIMBURSEMENT_REPORTS_PATH, {
                          teamId,
                      })
                  )
              )
            : dispatch(push(generatePath(REIMBURSEMENT_REPORTS_PATH)));

    const onReimbursementReportRhFileDownloadClick = useCallback(async () => {
        setIsReimbursementReportRhFileDownloading(true);
        await downloadFileFromApi(async () =>
            downloadYearlyReimbursementReportRhFile(teamId, Number(selectedYear))
        );
        setIsReimbursementReportRhFileDownloading(false);
    }, [teamId, selectedYear]);

    const actions: IActionButtonItems = [
        {
            isVisible: canViewTermButton,
            onClick: navigateToTermReports,
            text: 'Show Term Reports',
        },
        {
            isLoading: isReimbursementReportRhFileDownloading,
            isVisible: canDownloadReimbursementReportRhFile,
            onClick: onReimbursementReportRhFileDownloadClick,
            text: 'Download',
        },
    ];

    return (
        <React.Fragment>
            <Paper sx={{ p: 2 }}>
                <TableHeader
                    dropdownWhenLengthGreaterThan={1}
                    items={actions}
                    OverrideLeftSide={
                        <React.Fragment>
                            <Stack
                                alignItems="center"
                                direction={{ md: 'row', xs: 'column' }}
                                justifyContent="center"
                                spacing={2}
                            >
                                <PageHeader variant="h4">
                                    Reimbursement Report Yearly Summary
                                </PageHeader>
                                <Select
                                    data-cy="selected-year"
                                    items={years}
                                    name="selectedYear"
                                    onChange={onSelectedYearChange}
                                    optionText="name"
                                    optionValue="name"
                                    sx={{ maxWidth: 100, minWidth: 100 }}
                                    value={selectedYear}
                                />
                            </Stack>
                        </React.Fragment>
                    }
                />
            </Paper>
            <Paper sx={{ my: 1, p: 1 }}>
                <TextField
                    data-cy="search"
                    name="search"
                    onChange={onChange(setSearch)}
                    placeholder={"Filter Yearly Reimbursement Report by a member's first/last name"}
                    type="search"
                    value={search}
                />
            </Paper>
            <Paper sx={{ p: 2 }}>
                <Skeleton count={4} height="400px" isEnabled={isLoading} sx={{ mb: 2 }}>
                    <ReimbursementYearlyReportTable globalFilter={search} />
                </Skeleton>
            </Paper>
        </React.Fragment>
    );
};

export default hot(module)(ReimbursementYearlyReportPage);
