import Typography from '@mui/material/Typography';
import useUserProps from 'hooks/useUserProps';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getReimbursement, getReimbursementTotalPlanCosts, getWageUp } from 'selectors/shopping';
import { formatCurrency } from 'utilities/format';
import { isIchraPathwayType } from 'utilities/pathways';

const SelectionPagePriceBreakdown = () => {
    const { isCurrent } = useUserProps();
    const {
        hasMoreThanOneSelectedPlans,
        majorMedicalBenefitCost,
        pathwayUser,
        reimbursement,
        reimbursementTotalPlanCosts,
        wageUp,
    } = useSelector((state: AppStore) => ({
        hasMoreThanOneSelectedPlans: (state.selectedPlansForReview.selectedPlans?.length ?? 0) > 1,
        majorMedicalBenefitCost: state.selectedPlansForReview.majorMedicalBenefitCost,
        pathwayUser: state.pathwayUser,
        reimbursement: getReimbursement(state, isCurrent),
        reimbursementTotalPlanCosts: getReimbursementTotalPlanCosts(state),
        wageUp: getWageUp(state, isCurrent),
    }));
    const isInIchraPathway = isIchraPathwayType(pathwayUser.pathwayType);
    let content = (
        <React.Fragment>
            <Typography paragraph>
                Your employer is giving you a monthly Wage+ of {formatCurrency(wageUp)}, which will
                be added to your wages before taxes on your paycheck.
            </Typography>
            <Typography paragraph>
                Your total monthly benefit cost is{' '}
                {formatCurrency(majorMedicalBenefitCost?.postTaxTotalPlanCost)}, which will be
                deducted from your paycheck after taxes.
            </Typography>
            <Typography paragraph>
                After factoring in taxes paid and the Wage+ amount provided, your cost ends up being{' '}
                {formatCurrency(majorMedicalBenefitCost?.preTaxTotalMajorMedicalBenefitCost)} per
                month.
            </Typography>
        </React.Fragment>
    );
    if (isInIchraPathway) {
        const yourCost = Math.max(reimbursementTotalPlanCosts - reimbursement, 0);
        content = (
            <React.Fragment>
                <p>
                    The{hasMoreThanOneSelectedPlans && ' total'} listed price of your benefit
                    {hasMoreThanOneSelectedPlans && 's'} is{' '}
                    {formatCurrency(reimbursementTotalPlanCosts, { preserveDecimal: true })} per
                    month.
                </p>
                <p>
                    Your employer will reimburse up to{' '}
                    {formatCurrency(reimbursement, { preserveDecimal: true })}
                </p>
                <p>
                    This makes the final cost to you{' '}
                    {formatCurrency(yourCost, { preserveDecimal: true })}
                </p>
            </React.Fragment>
        );
    }
    return content;
};

export default hot(module)(SelectionPagePriceBreakdown);
