import {
    editTeamRelationship,
    EDIT_TEAM_RELATIONSHIP_ACTION,
} from 'actions/team/editTeamRelationship';
import {
    transitionToCustomer,
    TRANSITION_TO_CUSTOMER_ACTION,
} from 'actions/team/transitionToTeamCustomer';
import { getTeamRepresentativeOptions } from 'actions/user/getTeamRepresentativeOptions';
import { RelationshipTypeIds } from 'api/generated/enums';
import Button from 'components/Button';
import Form from 'components/Form';
import Select from 'components/Select';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';

const HandoffToCustomerSuccessModal = ({
    onClose,
    teamId,
}: {
    onClose: () => void;
    teamId: string;
}) => {
    const dispatch = useThunkDispatch();
    const [customerSuccessRepId, setCustomerSuccessRepId] = useState('');
    const { isLoading, representativeOptions } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            TRANSITION_TO_CUSTOMER_ACTION,
            EDIT_TEAM_RELATIONSHIP_ACTION
        ),
        representativeOptions: state.teamRepresentativeOptions,
    }));
    useEffect(() => {
        dispatch(getTeamRepresentativeOptions(teamId));
    }, [dispatch, teamId]);
    const isInvalid = useMemo(() => !hasValue(customerSuccessRepId), [customerSuccessRepId]);
    const onSubmit = useCallback(async () => {
        if (!isInvalid) {
            await dispatch(
                editTeamRelationship(
                    teamId,
                    customerSuccessRepId,
                    RelationshipTypeIds.CustomerSuccessRepresentative
                )
            );
            await dispatch(transitionToCustomer(teamId));
        }
    }, [customerSuccessRepId, dispatch, isInvalid, teamId]);
    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Hold Up ✋</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <Form
                    id="handoff-to-customer-success-modal"
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                >
                    <div>
                        Before we hand off to the Customer Success team, let&apos;s first set who
                        will be the designated Customer Success Representative
                    </div>
                    <div className="pt-3">
                        <Select
                            autoFocus
                            data-cy="customer-success-rep-dropdown"
                            defaultText="Choose a Customer Success Rep"
                            defaultValue=""
                            items={representativeOptions}
                            name="customerSuccessRep"
                            onChange={onChange(setCustomerSuccessRepId)}
                            optionText="name"
                            optionValue="userId"
                            value={customerSuccessRepId}
                        />
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={isInvalid}
                    form="handoff-to-customer-success-modal"
                    isLoading={isLoading}
                    type="submit"
                >
                    Let&apos;s Go!
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(HandoffToCustomerSuccessModal);
