import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GET_TEAM_PROFILE_ACTION } from 'actions/team/getTeamProfile';
import { getToken } from 'actions/token/getToken';
import { INTERMEDIATE_TOKEN_ACTION, TOKEN_ACTION } from 'actions/token/tokenActionUtility';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { TeamStateIds, UserStatus } from 'api/generated/enums';
import { IUser } from 'api/generated/models';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import UnauthenticatedPageHeader from 'components/UnauthenticatedPageHeader';
import Version from 'components/Version';
import useForm from 'hooks/useForm';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import has from 'lodash/has';
import queryString from 'query-string';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { DASHBOARD_PATH, FORGOT_PASSWORD_PATH, MEMBER_SURVEY_INTRO_PATH } from 'routers/routes';
import { ITeamProps, IUserProps } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector, isTeamAdminSelector } from 'selectors/role';
import { onChange as onFormChange } from 'utilities/forms';
import { hasValue } from 'utilities/index';
import { object, string } from 'yup';

const schema = object({
    password: string()
        .trim()
        .required()
        .label('Password'),
    username: string()
        .trim()
        .required()
        .label('Email'),
});

type IRedirectData = {
    hasTeam: boolean;
    hasUser: boolean | undefined;
    isAuthenticated: boolean;
    isInIchraLaunchFlow?: boolean;
    isTeamAdmin: boolean;
    needsVerifiedInfo: boolean;
    user: IUser | undefined;
};

export const loginFormSelector = (
    { hasTeam, teamStateId }: ITeamProps,
    { hasUser, hasMemberVerifiedInfo }: IUserProps,
    tokenAction: string
) => (state: AppStore) => {
    const props = {
        apiError:
            state.apiErrors[tokenAction.statusCode] ??
            state.apiErrors[INTERMEDIATE_TOKEN_ACTION.statusCode],
        apiErrorMessage:
            state.apiErrors[tokenAction.errorMessage] ??
            state.apiErrors[INTERMEDIATE_TOKEN_ACTION.errorMessage],
        isAuthenticated: state.login.isAuthenticated,
        isLoading: hasApiActivity(
            state,
            TOKEN_ACTION,
            GET_USER_PROFILE_ACTION,
            GET_TEAM_PROFILE_ACTION
        ),
        isTeamAdmin: false,
        needsVerifiedInfo: false,
    };
    if (hasUser && hasTeam) {
        props.needsVerifiedInfo =
            !isRhSelector(state) &&
            [TeamStateIds.MemberLaunch, TeamStateIds.Customer, TeamStateIds.Renewing].includes(
                teamStateId
            ) &&
            !hasMemberVerifiedInfo;
        props.isTeamAdmin = isTeamAdminSelector(state);
    }

    return props;
};

export const handleRedirect = (data: IRedirectData) => {
    if (data.isAuthenticated && data.hasUser && data.hasTeam) {
        const q = queryString.parse(window.location.search);
        if (has(q, 'redirect')) {
            return decodeURIComponent(q['redirect'] as string);
        }
        if (
            data.needsVerifiedInfo &&
            !data.isTeamAdmin &&
            data.user?.status !== UserStatus.Waived &&
            !data.isInIchraLaunchFlow
        ) {
            return MEMBER_SURVEY_INTRO_PATH;
        }
        return DASHBOARD_PATH;
    }
    return '';
};

const LoginPage = () => {
    const dispatch = useThunkDispatch();
    const teamProps = useTeamProps();
    const { hasTeam, useIchraLaunchFlow } = teamProps;
    const userProps = useUserProps();
    const { hasUser, isInIchraPathway, user } = userProps;
    const {
        apiError,
        apiErrorMessage,
        isAuthenticated,
        needsVerifiedInfo,
        isTeamAdmin,
    } = useSelector(loginFormSelector(teamProps, userProps, TOKEN_ACTION));

    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const { errors, setErrors, validate } = useForm(schema);
    const isInIchraLaunchFlow = useIchraLaunchFlow && isInIchraPathway;

    const onChange = <T,>(setValue: React.Dispatch<React.SetStateAction<T>>) => (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setErrors(null);
        onFormChange(setValue)(e);
    };
    const handleSubmit = async () => {
        const { isValid } = await validate({ password, username });
        if (isValid) {
            await dispatch(getToken(username, password));
        }
    };

    const redirectData = {
        hasTeam,
        hasUser,
        isAuthenticated,
        isInIchraLaunchFlow,
        isTeamAdmin,
        needsVerifiedInfo,
        user,
    };

    const redirectPath = handleRedirect(redirectData);

    return (
        <React.Fragment>
            {hasValue(redirectPath) ? (
                <Redirect to={redirectPath} />
            ) : (
                <Stack alignItems="center" my={7}>
                    <Box px={2} width={500}>
                        <Card sx={{ padding: 0 }}>
                            <UnauthenticatedPageHeader />
                            <CardContent sx={{ p: 4 }}>
                                <Box sx={{ mb: 4 }} textAlign="center">
                                    <Typography color="grey.700" fontWeight={500} variant="h4">
                                        Log In
                                    </Typography>
                                    <Typography color="grey.500" variant="caption">
                                        Enter your email and password.
                                    </Typography>
                                </Box>
                                {apiError && (
                                    <Alert data-cy="api-error" severity="error" sx={{ mb: 2 }}>
                                        {apiErrorMessage}
                                    </Alert>
                                )}
                                <Form onSubmit={handleSubmit}>
                                    <Stack spacing={2}>
                                        <TextField
                                            autoFocus
                                            data-cy="username"
                                            errors={errors?.username}
                                            fullWidth
                                            label="Email"
                                            name="username"
                                            onChange={onChange(setUsername)}
                                            value={username}
                                        />
                                        <TextField
                                            data-cy="password"
                                            errors={errors?.password}
                                            fullWidth
                                            label="Password"
                                            name="password"
                                            onChange={onChange(setPassword)}
                                            type="password"
                                            value={password}
                                        />
                                        <Grid container justifyContent="center">
                                            <Button
                                                sx={{ mx: 'auto', my: 3 }}
                                                type="submit"
                                                variant="contained"
                                            >
                                                Log In
                                            </Button>
                                        </Grid>
                                    </Stack>
                                </Form>
                                <hr />
                                <Grid container justifyContent="center">
                                    <Link to={FORGOT_PASSWORD_PATH}>Forgot password?</Link>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Version />
                    </Box>
                </Stack>
            )}
        </React.Fragment>
    );
};

export default hot(module)(LoginPage);
