import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const JobBasedCoverageStatusExamples = () => (
    <ul>
        <li>
            Completed{' '}
            <ExternalLink href="https://marketplace.cms.gov/applications-and-forms/employer-coverage-tool.pdf">
                Employer Coverage Tool (PDF)
            </ExternalLink>{' '}
            and a cover letter signed by the employer
        </li>
        <li>
            Letter or other documentation from an employer or other documentation with this
            information: Statement that the employer doesn’t currently offer you (or your family
            member) coverage Statement that the employer doesn’t provide coverage that isn’t
            qualifying health coverage Statement showing the cost of your share of the premium for
            the lowest-cost self-only plan that meets the minimum value standard (factoring in
            wellness incentives), if offered
        </li>
        <li>
            Health insurance letter that contains confirmation of health coverage and expiration
            dates for coverage received outside of the Marketplace
        </li>
    </ul>
);

export default hot(module)(JobBasedCoverageStatusExamples);
