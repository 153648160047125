import { HouseholdMemberTypes } from 'api/generated/enums';
import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import GENDERS from 'constants/genders';
import {
    doesMeetDependentOtherCoverageAgeMinimum,
    doesMeetParentOtherCoverageAgeThreshold,
    PARENT_OTHER_COVERAGE_AGE_THRESHOLD,
} from 'constants/otherCoverageEligibilities';
import useUserProps from 'hooks/useUserProps';
import CoverageIntro from 'pages/survey/coverageSteps/CoverageIntro';
import EmployerNameForm from 'pages/survey/coverageSteps/EmployerNameForm';
import EmployerOtherCoverageForm from 'pages/survey/coverageSteps/EmployerOtherCoverageForm';
import FaithBasedForm from 'pages/survey/coverageSteps/FaithBasedForm';
import HealthConditionsForm from 'pages/survey/coverageSteps/HealthConditionsForm';
import IsEmployedForm from 'pages/survey/coverageSteps/IsEmployedForm';
import NeedsCoverageForm from 'pages/survey/coverageSteps/NeedsCoverageForm';
import OtherCoverageSourceForm from 'pages/survey/coverageSteps/OtherCoverageSourceForm';
import ParentOtherCoverageForm from 'pages/survey/coverageSteps/ParentOtherCoverageForm';
import PregnantForm from 'pages/survey/coverageSteps/PregnantForm';
import PrescriptionsForm from 'pages/survey/coverageSteps/PrescriptionsForm';
import ProvidersForm from 'pages/survey/coverageSteps/ProvidersForm';
import SpouseSameEmployerForm from 'pages/survey/coverageSteps/SpouseSameEmployerForm';
import TobaccoForm from 'pages/survey/coverageSteps/TobaccoForm';
import StepWrapper from 'pages/survey/StepWrapper';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasContents, ichraCheckThroughRenewal } from 'utilities';
import { hasMemberType, isEmployedAndNotSameEmployer } from 'utilities/household';

const shouldIncludeEmployedForm = (householdMembers: ISurveyHouseholdMemberDto[] | undefined) => {
    const hasDependentsAge16AndOlder =
        hasMemberType(
            householdMembers,
            HouseholdMemberTypes.Dependent,
            doesMeetDependentOtherCoverageAgeMinimum
        ) ||
        hasMemberType(
            householdMembers,
            HouseholdMemberTypes.Unclaimed,
            doesMeetDependentOtherCoverageAgeMinimum
        );
    const hasSpouse = hasMemberType(householdMembers, HouseholdMemberTypes.Spouse);
    return hasSpouse || hasDependentsAge16AndOlder;
};

const getStepsToRender = (
    householdMembers: ISurveyHouseholdMemberDto[] | undefined,
    includeFaithBasedQuestion: boolean | undefined,
    isParentOtherCoverageVisible: boolean,
    isPrimaryFemale: boolean,
    isPrimaryNeedCoverage: boolean | undefined,
    isIchra: boolean
) => {
    const includeEmployedForm = shouldIncludeEmployedForm(householdMembers);
    const femaleHouseholdMembers = householdMembers?.filter((h) => h.gender === GENDERS.FEMALE);
    const isSpouseEmployed = householdMembers?.some(
        (h) => h.isEmployed && h.householdMemberTypeId === HouseholdMemberTypes.Spouse
    );
    const hasMembersNotNeedingCoverage =
        householdMembers?.some((h) => !h.needsCoverage) || !isPrimaryNeedCoverage;
    const forms = [];
    if (!isIchra) {
        if (hasContents(householdMembers)) {
            forms.push(NeedsCoverageForm);
        }
        if (hasMembersNotNeedingCoverage) {
            forms.push(OtherCoverageSourceForm);
        }
        if (includeEmployedForm) {
            forms.push(IsEmployedForm);
        }
        if (isSpouseEmployed) {
            forms.push(SpouseSameEmployerForm);
        }
        if (householdMembers?.some(isEmployedAndNotSameEmployer)) {
            forms.push(EmployerNameForm);
            forms.push(EmployerOtherCoverageForm);
        }
        if (isParentOtherCoverageVisible) {
            forms.push(ParentOtherCoverageForm);
        }
        if (includeFaithBasedQuestion) {
            forms.push(FaithBasedForm);
        }
    }
    forms.push(TobaccoForm);
    if ((isPrimaryFemale || hasContents(femaleHouseholdMembers)) && !isIchra) {
        forms.push(PregnantForm);
    }
    forms.push(ProvidersForm);
    forms.push(PrescriptionsForm);
    forms.push(HealthConditionsForm);
    return forms;
};

const CoverageWrapper = () => {
    const {
        dateOfBirth,
        householdMembers,
        includeFaithBasedQuestion,
        isPrimaryFemale,
        isPrimaryNeedCoverage,
    } = useSelector((state: AppStore) => ({
        dateOfBirth: state.surveyState.member.dateOfBirth,
        householdMembers: state.surveyState.household.members,
        includeFaithBasedQuestion: state.surveyState.includeFaithBasedQuestionInSurvey,
        isPrimaryFemale: state.surveyState.member.gender === GENDERS.FEMALE,
        isPrimaryNeedCoverage: state.surveyState.member.needsCoverage,
    }));
    const { isInIchraPathway, user } = useUserProps();
    const history = useHistory();
    const isIchra = ichraCheckThroughRenewal(history, isInIchraPathway, user?.surveyTypeToSend);

    const isParentOtherCoverageVisible = useMemo(
        () =>
            (dateOfBirth?.getAge() ?? NaN) < PARENT_OTHER_COVERAGE_AGE_THRESHOLD ||
            hasMemberType(
                householdMembers,
                HouseholdMemberTypes.Spouse,
                doesMeetParentOtherCoverageAgeThreshold
            ),
        [dateOfBirth, householdMembers]
    );
    const stepsToRender = useMemo(
        () =>
            getStepsToRender(
                householdMembers,
                includeFaithBasedQuestion,
                isParentOtherCoverageVisible,
                isPrimaryFemale,
                isPrimaryNeedCoverage,
                isIchra
            ),
        [
            householdMembers,
            includeFaithBasedQuestion,
            isParentOtherCoverageVisible,
            isPrimaryFemale,
            isPrimaryNeedCoverage,
            isIchra,
        ]
    );
    return <StepWrapper Intro={CoverageIntro} stepsToRender={stepsToRender} />;
};

export default hot(module)(CoverageWrapper);
