import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { getAdvisementTeams } from 'actions/team/getAdvisementTeams';
import { patchTeam } from 'actions/team/patchTeam';
import { AccessOtherTeams, EditTeam } from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import FavoriteTeamStar from 'components/FavoriteTeamStar';
import PageHeader from 'components/PageHeader';
import { teamStateIds } from 'constants/teamStateIds';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FirstSection from 'pages/teamProfile/FirstSection';
import FourthSection from 'pages/teamProfile/FourthSection';
import SecondSection from 'pages/teamProfile/SecondSection';
import TeamOperationsInfoSection from 'pages/teamProfile/TeamOperationsInfoSection';
import ThirdSection from 'pages/teamProfile/ThirdSection';
import React, { useCallback, useEffect, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { isAdvisorSelector, isInRoleSelector, isRhSelector } from 'selectors/role';

const TeamProfilePage = () => {
    const dispatch = useThunkDispatch();
    const {
        hasTeam,
        hasTeamIdUrlParam,
        isSameTeam,
        teamId,
        teamName,
        teamStateId,
    } = useTeamProps();
    const { canAccessOtherTeams, readonly, showTeamOperationsInfoSection } = useSelector(
        (state: AppStore) => {
            const isRhOrPartnerAdmin =
                isRhSelector(state) || isInRoleSelector(state, ROLE_IDS.PARTNER_ADMIN);
            const canEditTeam = isSameTeam || hasSomePermissions(state, EditTeam);
            const isTeamStateAfterProposal =
                teamStateId !== teamStateIds.Prospect && teamStateId !== teamStateIds.Proposal;
            const disableTeamPropertyEdits =
                isTeamStateAfterProposal &&
                teamStateId !== teamStateIds.RhLaunch &&
                !isRhOrPartnerAdmin;
            return {
                canAccessOtherTeams: hasSomePermissions(state, AccessOtherTeams),
                readonly: !canEditTeam || disableTeamPropertyEdits,
                showTeamOperationsInfoSection:
                    isTeamStateAfterProposal &&
                    (isRhOrPartnerAdmin || isAdvisorSelector(state)) &&
                    hasTeamIdUrlParam,
            };
        }
    );
    useEffect(() => {
        if (canAccessOtherTeams) {
            dispatch(getAdvisementTeams());
        }
    }, [canAccessOtherTeams, dispatch]);

    const save = useCallback(
        async (name, value) => {
            await dispatch(patchTeam(teamId, { [name]: value }, !hasTeamIdUrlParam, true));
        },
        [dispatch, hasTeamIdUrlParam, teamId]
    );
    const pageHeader = useMemo(() => `${teamName}`, [teamName]);

    return (
        <Paper>
            <Col>
                <FavoriteTeamStar teamId={teamId}>
                    {hasTeam ? <PageHeader variant="h4">{pageHeader}</PageHeader> : <Skeleton />}
                </FavoriteTeamStar>
                <hr />

                {!hasTeam ? (
                    <Skeleton count={5} />
                ) : (
                    <React.Fragment>
                        <Grid columnSpacing={6} container direction="row" xs={12}>
                            <Grid container item md={6} rowSpacing={6} xs={12}>
                                <Grid item xs={12}>
                                    <FirstSection readonly={readonly} save={save} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ThirdSection readonly={readonly} save={save} />
                                </Grid>
                                {showTeamOperationsInfoSection && (
                                    <Grid item xs={12}>
                                        <TeamOperationsInfoSection teamId={teamId} />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                alignItems="flex-start"
                                container
                                item
                                md={6}
                                rowSpacing={6}
                                xs={12}
                            >
                                <Grid item xs={12}>
                                    <SecondSection readonly={readonly} save={save} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FourthSection readonly={readonly} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Col>
        </Paper>
    );
};

export default hot(module)(TeamProfilePage);
