import { MedicaidRenewalStatuses } from 'api/generated/enums';
import { IMedicaidRenewalDetails } from 'api/generated/models';
import EditableDateAttribute from 'components/EditableDateAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import PageHeader from 'components/PageHeader';
import Select from 'components/Select';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { startCase } from 'lodash';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { enumToNameValueArray } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { getYears } from 'utilities/year';
import { string } from 'yup';

const RENEWAL_STATUS_ITEMS = enumToNameValueArray(MedicaidRenewalStatuses, {
    formatName: startCase,
    nameKey: 'text',
});
const YEAR_ITEMS = getYears(() => 1).map((x) => ({ name: `${x}` }));

type IRenewalSection = RouteComponentProps & {
    readonly: boolean;
    save: (name: string, value: string, year: number) => Promise<void>;
};

const RenewalSection = ({ readonly, save }: IRenewalSection) => {
    const dispatch = useThunkDispatch();
    const { currentRenewalDetails, renewalDetails, selectedYear } = useSelector(
        (state: AppStore) => {
            const year = +state.profileState.selectedYear;
            return {
                currentRenewalDetails: state.medicaid?.renewalDetails?.find((x) => x.year === year),
                renewalDetails: state.medicaid.renewalDetails,
                selectedYear: year,
            };
        }
    );
    const [renewalSentDate, setRenewalSentDate] = useState(
        formatDateForDisplay(currentRenewalDetails?.renewalSentDate)
    );
    const [renewalStatus, setRenewalStatus] = useState(currentRenewalDetails?.renewalStatus);
    useEffect(() => {
        if (selectedYear > 0) {
            const currentDetails = renewalDetails?.find((x) => x.year === selectedYear);
            setRenewalSentDate(formatDateForDisplay(currentDetails?.renewalSentDate));
            setRenewalStatus(currentDetails?.renewalStatus);
        }
    }, [renewalDetails, selectedYear]);
    const onSelectedYearChange = useCallback(
        ({ target: { value } }) => dispatch(setSelectedYear(value)),
        [dispatch]
    );
    const onSave: ISaveEditableTextField<IMedicaidRenewalDetails> = useCallback(
        async (name, value) => save(name, value as string, selectedYear),
        [save, selectedYear]
    );
    const commonProps = useMemo(
        () => ({
            readonly,
            isOptional: true,
            save: onSave,
        }),
        [onSave, readonly]
    );
    return (
        <Row>
            <Col md="6">
                <Row className="align-items-center justify-content-between my-2" noGutters>
                    <Row className="align-items-center" noGutters>
                        <PageHeader mr={2} variant="h4">
                            Renewal Details
                        </PageHeader>
                        <span>
                            <Select
                                data-cy="selected-profile-year"
                                items={YEAR_ITEMS}
                                name="selectedYear"
                                onChange={onSelectedYearChange}
                                optionText="name"
                                optionValue="name"
                                value={selectedYear}
                            />
                        </span>
                    </Row>
                </Row>
                <hr />
                <EditableDateAttribute
                    {...commonProps}
                    infoTooltip="The date the Medicaid renewal email was sent to the member"
                    label="Renewal Email Sent Date"
                    name="renewalSentDate"
                    onChange={onChange(setRenewalSentDate)}
                    placeholder="Enter renewal email sent date"
                    validationSchema={string()
                        .isValidDate(false)
                        .label('Renewal Email Sent Date')}
                    value={renewalSentDate}
                />
                <EditableSelectAttribute
                    {...commonProps}
                    formatter={(x) => startCase(MedicaidRenewalStatuses[x as number])}
                    items={RENEWAL_STATUS_ITEMS}
                    label="Renewal Status"
                    name="renewalStatus"
                    onChange={onChange(setRenewalStatus)}
                    optionText="text"
                    optionValue="value"
                    validationSchema={string().label('Renewal Status')}
                    value={renewalStatus}
                />
            </Col>
        </Row>
    );
};

export default hot(module)(withRouter(RenewalSection));
