import { IMemberQuoteInfo } from 'api/generated/models';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type ICoverageQuoteInfoProps = {
    memberQuoteInfo: IMemberQuoteInfo | undefined;
    quoteInfoCommonProps: ICommonProps<IMemberQuoteInfo>;
};
const CoverageQuoteInfo = ({ memberQuoteInfo, quoteInfoCommonProps }: ICoverageQuoteInfoProps) => {
    const [dependentsUnder21, setDependentsUnder21] = useState(
        memberQuoteInfo?.dependentsUnder21?.toString()
    );
    const [dependentsOver20, setDependentsOver20] = useState(
        memberQuoteInfo?.dependentsOver20?.toString()
    );
    return (
        <React.Fragment>
            <EditableTextAttribute
                {...quoteInfoCommonProps}
                inputProps={{ min: 0 }}
                label="# of Dependents Under 21"
                name="dependentsUnder21"
                onChange={onChange(setDependentsUnder21)}
                placeholder="Enter a number"
                type="number"
                validationSchema={string()
                    .trim()
                    .required()
                    .min(0)
                    .label('Dependents Under 21')}
                value={dependentsUnder21}
            />

            <EditableTextAttribute
                {...quoteInfoCommonProps}
                inputProps={{ min: 0 }}
                label="# of Dependents Over 20"
                name="dependentsOver20"
                onChange={onChange(setDependentsOver20)}
                placeholder="Enter a number"
                type="number"
                validationSchema={string()
                    .trim()
                    .required()
                    .min(0)
                    .label('Dependents Over 20')}
                value={dependentsOver20}
            />
        </React.Fragment>
    );
};

export default hot(module)(CoverageQuoteInfo);
