import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import { SurveyTypes } from 'api/generated/enums';
import TextAreaModal from 'components/TextAreaModal';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';

type IPrimaryOtherCoverageSourceInfoProps = {
    readonly: boolean;
};

const PrimaryOtherCoverageSourceInfo = ({ readonly }: IPrimaryOtherCoverageSourceInfoProps) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, memberVerifiedInfo, user, userId, year, yearlyUserInfo } = useUserProps();
    const {
        closeModal: closeModal,
        isVisible: isModalVisible,
        openModal: openModal,
    } = useModalState();
    const [isModalActivity, setIsModalActivity] = useState(false);
    const saveChanges = async (text: string | undefined) => {
        setIsModalActivity(true);
        await dispatch(patchYearlyUserInfo(userId, year, { otherCoverageSource: text }, isCurrent));
        setIsModalActivity(false);
        closeModal();
    };
    const hasCompletedStandardSurvey =
        memberVerifiedInfo?.surveyTypeCompleted === SurveyTypes.Standard;

    return (
        <React.Fragment>
            {isModalVisible && (
                <TextAreaModal
                    label={`How does ${user?.firstName} have coverage?`}
                    maxCharacters={500}
                    onHide={closeModal}
                    placeholder="Enter coverage source"
                    save={saveChanges}
                    showActivity={isModalActivity}
                    title="Primary Other Coverage Source"
                    value={yearlyUserInfo?.otherCoverageSource}
                />
            )}
            {hasCompletedStandardSurvey && (
                <EditableProfileAttribute
                    content={yearlyUserInfo?.otherCoverageSource}
                    label="Other Coverage Source"
                    onClick={openModal}
                    readonly={readonly}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(PrimaryOtherCoverageSourceInfo);
