import { EnrollmentVerificationTypes } from 'api/generated/enums';
import Button from 'components/Button';
import AmericanIndianOrAlaskanNativeExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/AmericanIndianOrAlaskanNativeExamples';
import CitizenshipVerificationExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/CitizenshipVerificationExamples';
import CostToJoinOtherCoverageExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/CostToJoinOtherCoverageExamples';
import ImmigrationStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/ImmigrationStatusExamples';
import IncarcerationStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/IncarcerationStatusExamples';
import IncomeVerificationExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/IncomeVerificationExamples';
import JobBasedCoverageStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/JobBasedCoverageStatusExamples';
import LossOfHealthCoverageExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/LossOfHealthCoverageExamples';
import MarriageStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/MarriageStatusExamples';
import MedicaidOrChipStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/MedicaidOrChipStatusExamples';
import MedicareCoverageStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/MedicareCoverageStatusExamples';
import PeaceCorpsCoverageStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/PeaceCorpsCoverageStatusExamples';
import SsnVerificationExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/SsnVerificationExamples';
import TricareCoverageStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/TricareCoverageStatusExamples';
import VaCoverageStatusExamples from 'pages/dashboard/verificationDocuments/exampleDocuments/VaCoverageStatusExamples';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import { getEnrollmentVerificationTypeText } from 'utilities/verificationDocuments';

const getValidDocumentExamples = (verificationType: EnrollmentVerificationTypes | undefined) => {
    switch (verificationType) {
        case EnrollmentVerificationTypes.AmericanIndianOrAlaskanNativeStatus:
            return <AmericanIndianOrAlaskanNativeExamples />;
        case EnrollmentVerificationTypes.CitizenshipVerification:
            return <CitizenshipVerificationExamples />;
        case EnrollmentVerificationTypes.ImmigrationStatus:
            return <ImmigrationStatusExamples />;
        case EnrollmentVerificationTypes.IncarcerationStatus:
            return <IncarcerationStatusExamples />;
        case EnrollmentVerificationTypes.IncomeVerification:
            return <IncomeVerificationExamples />;
        case EnrollmentVerificationTypes.JobBasedCoverageStatus:
            return <JobBasedCoverageStatusExamples />;
        case EnrollmentVerificationTypes.LossOfHealthCoverage:
            return <LossOfHealthCoverageExamples />;
        case EnrollmentVerificationTypes.MarriageStatus:
            return <MarriageStatusExamples />;
        case EnrollmentVerificationTypes.MedicaidOrChipStatus:
            return <MedicaidOrChipStatusExamples />;
        case EnrollmentVerificationTypes.MedicareCoverageStatus:
            return <MedicareCoverageStatusExamples />;
        case EnrollmentVerificationTypes.PeaceCorpsCoverageStatus:
            return <PeaceCorpsCoverageStatusExamples />;
        case EnrollmentVerificationTypes.SsnVerification:
            return <SsnVerificationExamples />;
        case EnrollmentVerificationTypes.TricareCoverageStatus:
            return <TricareCoverageStatusExamples />;
        case EnrollmentVerificationTypes.VaCoverageStatus:
            return <VaCoverageStatusExamples />;
        case EnrollmentVerificationTypes.CostToJoinOtherCoverage:
            return <CostToJoinOtherCoverageExamples />;
        default:
            return <React.Fragment />;
    }
};

const StyledModalBody = styled(Modal.Body)`
    font-size: 16px;
`;

const ValidDocumentsModal = ({
    onHide,
    verificationType,
}: {
    onHide: () => void;
    verificationType: EnrollmentVerificationTypes | undefined;
}) => (
    <Modal onHide={onHide} scrollable show size="lg">
        <Modal.Header closeButton>
            <Modal.Title>
                Valid Documents For {getEnrollmentVerificationTypeText(verificationType)}
            </Modal.Title>
        </Modal.Header>
        <StyledModalBody>{getValidDocumentExamples(verificationType)}</StyledModalBody>
        <Modal.Footer className="centered">
            <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
    </Modal>
);

export default hot(module)(ValidDocumentsModal);
