import { Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import { UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import { IAncillaryBenefitDto } from 'api/generated/models';
import React from 'react';
import { hot } from 'react-hot-loader';
import { enumToString, hasValue } from 'utilities/index';

const UnacceptedAncillary = ({ ancillary }: { ancillary: IAncillaryBenefitDto }) => (
    <Grid item key={ancillary.teamBenefitId} lg={5.8} md={12} xs={12}>
        <Card variant="outlined">
            <CardContent>
                <Grid container direction="column" height={80} paddingTop={0}>
                    <Grid container direction="row" item>
                        <Grid alignSelf="start" item marginTop={0}>
                            <Typography color="GrayText" marginTop={0} variant="h4">
                                {ancillary.teamBenefitType}
                            </Typography>
                            {hasValue(ancillary.planName) && (
                                <Typography variant="body2">{ancillary.planName}</Typography>
                            )}
                        </Grid>
                        <Grid alignSelf="start" item marginLeft="auto">
                            <Chip
                                label={enumToString(
                                    UserTeamBenefitTermDetailStatuses,
                                    ancillary.statusId
                                )}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
);

export default hot(module)(UnacceptedAncillary);
