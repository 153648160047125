import MediShareDisclaimerLabel from 'components/MediShareDisclaimerLabel';
import PlanCardProviders from 'pages/shop/PlanCardProviders';
import { IShoppingPlan } from 'pages/shop/shopping';
import React from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';

const PlanCardLeftFooter = ({
    isIchraFlow,
    plan,
}: {
    isIchraFlow?: boolean;
    plan: Partial<IShoppingPlan>;
}) => {
    let footerLeftContent = null;
    if (plan.isMarketplace) {
        footerLeftContent = (
            <Col>
                <PlanCardProviders isIchraFlow={isIchraFlow} plan={plan} />
            </Col>
        );
    } else if (plan.isMediShare) {
        footerLeftContent = (
            <Col>
                <MediShareDisclaimerLabel noTopMargin />
            </Col>
        );
    }
    return footerLeftContent;
};

export default hot(module)(PlanCardLeftFooter);
