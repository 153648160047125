import SingleButtonModal, { ISingleButtonModalProps } from 'components/SingleButtonModal';
import React from 'react';
import { hot } from 'react-hot-loader';

const SomethingIsIncorrectModal = (
    props: Partial<ISingleButtonModalProps> & {
        buttonClickHandler: () => void;
        contactEmail: string;
    }
) => (
    <SingleButtonModal
        body={
            <span>
                Please contact <strong>{props.contactEmail}</strong> and let them know.
            </span>
        }
        buttonText="Close"
        title="Is Something Incorrect?"
        {...props}
    />
);

export default hot(module)(SomethingIsIncorrectModal);
