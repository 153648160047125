import { EditLockedSubmittedExpense, EditSubmittedExpense } from 'api/generated/permissions';
import TextArea from 'components/TextArea';
import Typography from 'components/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';

const DECISION_SUMMARY_MAX_LENGTH = 4_000;

type IDecisionSummary = {
    decisionSummary: string | undefined;
    decisionSummaryErrors: string[] | undefined;
    handleDecisionSummary: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void;
    isLoading: boolean | undefined;
    isLocked: boolean | undefined;
};

const DecisionSummary = ({
    decisionSummary,
    decisionSummaryErrors,
    handleDecisionSummary,
    isLoading,
    isLocked,
}: IDecisionSummary) => {
    const { canEditLockedSubmittedExpense, canEditSubmittedExpense } = useSelector(
        (state: AppStore) => ({
            canEditLockedSubmittedExpense: hasSomePermissions(state, EditLockedSubmittedExpense),
            canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        })
    );

    return (
        <React.Fragment>
            {!canEditSubmittedExpense ? (
                <React.Fragment>
                    <Typography fontWeight="bold">Decision Summary</Typography>
                    <Typography isLoading={isLoading} sx={{ whiteSpace: 'pre-wrap' }}>
                        {decisionSummary}
                    </Typography>
                </React.Fragment>
            ) : (
                <TextArea
                    data-cy="decision-summary-field"
                    disabled={isLoading || (isLocked && !canEditLockedSubmittedExpense)}
                    errors={decisionSummaryErrors}
                    label="Decision Summary"
                    maxLength={DECISION_SUMMARY_MAX_LENGTH}
                    minRows={5}
                    onChange={handleDecisionSummary}
                    placeholder="Enter a Decision Summary"
                    value={decisionSummary}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(DecisionSummary);
