import { HouseholdMemberTypes } from 'api/generated/enums';
import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import {
    doesMeetParentOtherCoverageAgeThreshold,
    PARENT_OTHER_COVERAGE_AGE_THRESHOLD,
} from 'constants/otherCoverageEligibilities';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isUndefined from 'lodash/isUndefined';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveCoverageForm, saveHouseholdMember } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import { ISurveyHouseholdMember, ISurveyUser } from 'pages/survey/surveyState';
import YesNoWho from 'pages/survey/YesNoWho';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { findMemberType } from 'utilities/household';

const getTitleAndDescription = (moreThanOne: boolean, spouse: ISurveyHouseholdMemberDto) => {
    const moreThanOneOrOnlyPrimary = moreThanOne || !spouse;
    let titleText = 'you';
    if (moreThanOne) {
        titleText = 'you or your spouse';
    } else if (spouse) {
        titleText = 'your spouse';
    }
    return {
        description: `If ${titleText} ${moreThanOneOrOnlyPrimary ? 'are' : 'is'} eligible for or ${
            moreThanOneOrOnlyPrimary ? 'have' : 'has'
        } health coverage from a parent’s plan.`,
        title: `${
            moreThanOneOrOnlyPrimary ? 'Do' : 'Does'
        } ${titleText} have health coverage through
    a parent?`,
    };
};

const ParentOtherCoverageForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { dateOfBirth, hasParentOtherCoverage, householdMembers, member } = useSelector(
        (state: AppStore) => ({
            dateOfBirth: state.surveyState.member.dateOfBirth,
            hasParentOtherCoverage: state.surveyState.coverage.hasParentOtherCoverage,
            householdMembers: state.surveyState.household.members,
            member: state.surveyState.member,
        })
    );
    const spouse = useMemo(
        () =>
            findMemberType(
                householdMembers ?? [],
                HouseholdMemberTypes.Spouse,
                doesMeetParentOtherCoverageAgeThreshold
            ),
        [householdMembers]
    );
    const initialDisabled = useMemo(
        () =>
            (spouse && isUndefined(spouse.hasParentOtherCoverage)) ||
            hasParentOtherCoverage === null,
        [spouse, hasParentOtherCoverage]
    );
    const [isDisabled, setIsDisabled] = useState(initialDisabled);
    const onSelection = useCallback(
        ({
            isInvalid,
            options: newOptions,
        }: {
            isInvalid: boolean;
            isYes: boolean | undefined;
            options: (ISurveyHouseholdMemberDto | ISurveyUser)[];
        }) => {
            const primary = newOptions.find((x) => (x as ISurveyUser).teamId) as ISurveyUser;
            const newHouseholdMembers = newOptions.filter(
                (x) => !(x as ISurveyUser).teamId
            ) as ISurveyHouseholdMember[];
            dispatch(saveCoverageForm({ hasParentOtherCoverage: primary.hasParentOtherCoverage }));
            newHouseholdMembers.forEach((memberToSave) =>
                dispatch(saveHouseholdMember(memberToSave))
            );
            setIsDisabled(isInvalid);
        },
        [dispatch]
    );
    const onYesNoSelection = useCallback(
        (isYes) => {
            if (spouse) {
                dispatch(saveHouseholdMember({ ...spouse, hasParentOtherCoverage: isYes }));
            } else {
                dispatch(saveCoverageForm({ hasParentOtherCoverage: isYes }));
            }
            setIsDisabled(false);
        },
        [dispatch, spouse]
    );
    const options = useMemo(() => {
        const members = [];
        if ((dateOfBirth?.getAge() ?? NaN) < PARENT_OTHER_COVERAGE_AGE_THRESHOLD) {
            members.push({ ...member, hasParentOtherCoverage });
        }
        if (spouse) {
            members.push(spouse);
        }
        return members;
    }, [dateOfBirth, hasParentOtherCoverage, member, spouse]);
    const moreThanOne = useMemo(() => options.length > 1, [options.length]);
    const { description, title } = getTitleAndDescription(moreThanOne, spouse);
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled}>
            {children}
            <FormTitle description={description}>{title}</FormTitle>
            {moreThanOne ? (
                <YesNoWho
                    isSelectedProp="hasParentOtherCoverage"
                    onSelection={onSelection}
                    options={options}
                />
            ) : (
                <YesOrNoSelect onSelection={onYesNoSelection} value={hasParentOtherCoverage} />
            )}
        </SurveyFormWrapper>
    );
};

export default hot(module)(ParentOtherCoverageForm);
