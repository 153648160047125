import { IPathwayRowData } from 'pages/pathwayBlueprint/PathwayTableCells';
import { IPathwayUser, IPathwayUserDto } from 'api/generated/models';
import { AppStoreThunkDispatch } from 'actions/commonAction';

export const OPEN_CUSTOM_PLAN_COST_MODAL = 'OPEN_CUSTOM_PLAN_COST_MODAL';
export const openCustomTotalCostModal = (
    pathwayUser: IPathwayRowData | IPathwayUser | IPathwayUserDto
) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            pathwayUser,
            isCustomPlanCostModalVisible: true,
        },
        type: OPEN_CUSTOM_PLAN_COST_MODAL,
    });
};


export const CLOSE_CUSTOM_PLAN_COST_MODAL = 'CLOSE_CUSTOM_PLAN_COST_MODAL';
export const closeCustomTotalModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            isCustomPlanCostModalVisible: false,
            pathwayUser: null,
        },
        type: CLOSE_CUSTOM_PLAN_COST_MODAL,
    });
};