import { IMemberQuoteInfo } from 'api/generated/models';
import EditableCurrencyAttribute from 'components/EditableCurrencyAttribute';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type IPreviousCostsInfoProps = {
    memberQuoteInfo: IMemberQuoteInfo | undefined;
    quoteInfoCommonProps: ICommonProps<IMemberQuoteInfo>;
};
const PreviousCostsInfo = ({ memberQuoteInfo, quoteInfoCommonProps }: IPreviousCostsInfoProps) => {
    const [previousEmployeePremium, setPreviousEmployeePremium] = useState(
        memberQuoteInfo?.previousEmployeePremium?.toString()
    );
    const [previousEmployerPremium, setPreviousEmployerPremium] = useState(
        memberQuoteInfo?.previousEmployerPremium?.toString()
    );
    const [previousRenewalEmployeePremium, setPreviousRenewalEmployeePremium] = useState(
        memberQuoteInfo?.previousRenewalEmployeePremium?.toString()
    );
    const [previousRenewalEmployerPremium, setPreviousRenewalEmployerPremium] = useState(
        memberQuoteInfo?.previousRenewalEmployerPremium?.toString()
    );
    return (
        <React.Fragment>
            <EditableCurrencyAttribute
                {...quoteInfoCommonProps}
                label="Previous Employee Premium"
                name="previousEmployeePremium"
                onChange={onChange(setPreviousEmployeePremium)}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Previous Employee Premium')}
                value={previousEmployeePremium}
            />

            <EditableCurrencyAttribute
                {...quoteInfoCommonProps}
                label="Previous Employer Premium"
                name="previousEmployerPremium"
                onChange={onChange(setPreviousEmployerPremium)}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Previous Employer Premium')}
                value={previousEmployerPremium}
            />
            <EditableCurrencyAttribute
                {...quoteInfoCommonProps}
                label="Previous Renewal Employee Premium"
                name="previousRenewalEmployeePremium"
                onChange={onChange(setPreviousRenewalEmployeePremium)}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Previous Renewal Employee Premium')}
                value={previousRenewalEmployeePremium}
            />

            <EditableCurrencyAttribute
                {...quoteInfoCommonProps}
                label="Previous Renewal Employer Premium"
                name="previousRenewalEmployerPremium"
                onChange={onChange(setPreviousRenewalEmployerPremium)}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Previous Renewal Employer Premium')}
                value={previousRenewalEmployerPremium}
            />
        </React.Fragment>
    );
};

export default hot(module)(PreviousCostsInfo);
