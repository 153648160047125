import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Link } from 'react-router-dom';
const NoSelectedPlansMessage = ({ shopPath }: { shopPath: string }) => (
    <Card sx={{ textAlign: 'center' }}>
        <CardContent>
            <Typography variant="h3">
                No Plans Selected.&nbsp;
                <Link data-cy="go-shopping" to={shopPath}>
                    Go Shopping!
                </Link>
            </Typography>
        </CardContent>
    </Card>
);
export default hot(module)(NoSelectedPlansMessage);
