import { addTeamBenefitCarriersByType } from 'actions/teamBenefitType/addTeamBenefitCarriersByType';
import { GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION } from 'actions/teamBenefitType/getTeamBenefitCarriersByType';
import { ITeamBenefitCarrier, ITeamBenefitType } from 'api/generated/models';
import Button from 'components/Button';
import Form from 'components/Form';
import Icon from 'components/Icon';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { array, object, string } from 'yup';

const MAX_CARRIER_NAME_LENGTH = 50;
const getSchema = (teamBenefitCarriers: (ITeamBenefitCarrier | undefined)[]) =>
    object({
        addedBenefitCarriers: array()
            .of(
                string()
                    .trim()
                    .required()
                    .max(MAX_CARRIER_NAME_LENGTH)
                    .label('Carrier name')
            )
            .unique({ transform: (x) => x.map((y) => y?.toLowerCase()) })
            .uniqueOf(
                'Carrier already exists',
                teamBenefitCarriers.map((x) => x?.name?.toLowerCase()),
                (x) => x.map((y) => y?.toLowerCase()).filter((z) => hasValue(z))
            ),
    });

type IAddTeamBenefitTypeCarriersModalProps = {
    onClose: () => void;
    teamBenefitType: ITeamBenefitType;
};

const AddTeamBenefitTypeCarriersModal = ({
    onClose,
    teamBenefitType,
}: IAddTeamBenefitTypeCarriersModalProps) => {
    const dispatch = useThunkDispatch();
    const [addedBenefitCarriers, setAddedBenefitCarriers] = useState<string[]>([]);
    const { isLoading, teamBenefitCarriers } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION),
        teamBenefitCarriers: state.teamBenefitTypeCarriers.map(
            (typeCarrier) => typeCarrier.teamBenefitCarrier
        ),
    }));
    const schema = useMemo(() => getSchema(teamBenefitCarriers), [teamBenefitCarriers]);
    const { errors, setErrors, validate } = useForm(schema);

    const save = async () => {
        const { isValid } = await validate({ addedBenefitCarriers });
        if (isValid) {
            await dispatch(addTeamBenefitCarriersByType(teamBenefitType.id, addedBenefitCarriers));
            onClose();
        }
    };

    const setAddedBenefitCarrierValue = useCallback(
        (index: number) => ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            const newAddedBenefitCarrierValues = [...addedBenefitCarriers];
            newAddedBenefitCarrierValues[index] = value;
            setAddedBenefitCarriers(newAddedBenefitCarrierValues);
        },
        [addedBenefitCarriers]
    );

    const addRowClicked = () => setAddedBenefitCarriers([...addedBenefitCarriers, '']);

    const removeBenefitCarrier = useCallback(
        (index) => () => {
            const newAddedBenefitCarrierValues = [...addedBenefitCarriers];
            newAddedBenefitCarrierValues.splice(index, 1);
            setAddedBenefitCarriers(newAddedBenefitCarrierValues);
            setErrors(null);
        },
        [addedBenefitCarriers, setErrors]
    );

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>{teamBenefitType.name} Carriers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-team-benefit-carriers-modal-form" onSubmit={save}>
                    {teamBenefitCarriers.map((teamBenefitCarrier, index) => (
                        <Col key={`existing-team-benefit-carrier-${index}`}>
                            {teamBenefitCarrier?.name}
                            <hr />
                        </Col>
                    ))}
                    {addedBenefitCarriers.map((addedBenefitType, index) => (
                        <Col key={`added-team-benefit-carrier-${index}`}>
                            <Row className="align-items-center">
                                <Col>
                                    <TextField
                                        className="mb-0"
                                        errors={
                                            errors?.[
                                                (`addedBenefitCarriers[${index}]` as unknown) as keyof typeof errors
                                            ]
                                        }
                                        name={`addedBenefitCarriers[${index}]`}
                                        onChange={setAddedBenefitCarrierValue(index)}
                                        value={addedBenefitType}
                                    />
                                </Col>
                                <Button
                                    noPadding
                                    onClick={removeBenefitCarrier(index)}
                                    size="small"
                                    variant="text"
                                >
                                    <Icon>cross</Icon>
                                </Button>
                            </Row>
                            <hr />
                        </Col>
                    ))}
                    <Col>
                        <Button onClick={addRowClicked} variant="outlined">
                            Add
                        </Button>
                    </Col>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    form="add-team-benefit-carriers-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(AddTeamBenefitTypeCarriersModal);
