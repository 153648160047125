import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Stack, Step, StepIconProps, StepLabel, Stepper, Typography } from '@mui/material';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { Flows, TaskStatuses, Tasks } from 'api/generated/enums';
import { IUserFlowDto, TaskDto } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import {
    ICHRA_LAUNCH_FLOW_INDEX,
    TASK_FLOW_ENUM_EXCEPTIONS,
} from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskList';
import { NO_TASK_FOUND_INDEX } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, enumToString, hasValue } from 'utilities/index';

const FINAL_PARENT_TASK_INDEX = 5;

const stepIcon = (props: StepIconProps) => {
    if (props.completed) {
        return <CheckCircleOutlineIcon color="secondary" />;
    } else if (props.active) {
        return <RadioButtonUncheckedIcon color="action" />;
    } else {
        return <RadioButtonUncheckedIcon color="disabled" />;
    }
};

type IIchraEnrollmentTaskFlow = {
    currentFlow?: IUserFlowDto;
    currentParentTask: TaskDto;
};
const IchraEnrollmentTaskFlow = ({ currentFlow, currentParentTask }: IIchraEnrollmentTaskFlow) => {
    const { isLoading, userFlows } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, LIST_VISIBLE_FLOWS_ACTION),
        userFlows: state.userFlows,
    }));
    const [parentActiveStep, setParentActiveStep] = useState<number>(0);
    const [childActiveStep, setChildActiveStep] = useState<number>(0);
    const ichraTasks = userFlows[ICHRA_LAUNCH_FLOW_INDEX]?.tasks;

    const parentOnlyTaskList = ichraTasks?.filter(
        (task) => ![TaskStatuses.Skipped, TaskStatuses.Overridden].contains(task.taskStatusId)
    );

    const nextTaskIndex = useCallback(
        (taskFlow: TaskDto[], isChildTaskList?: boolean) => {
            if (
                isChildTaskList &&
                hasValue(currentParentTask) &&
                currentParentTask.taskStatusId !== TaskStatuses.Acknowledged
            ) {
                return NO_TASK_FOUND_INDEX;
            } else {
                return taskFlow?.findIndex((task) =>
                    [TaskStatuses.Incomplete, TaskStatuses.Acknowledged].includes(task.taskStatusId)
                );
            }
        },
        [currentParentTask]
    );

    const childrenTaskList = useCallback(
        () =>
            currentParentTask?.childTasks?.filter(
                (task) =>
                    ![TaskStatuses.Skipped, TaskStatuses.Overridden].contains(task.taskStatusId)
            ) ?? [],
        [currentParentTask]
    );

    useEffect(() => {
        const parentTaskIndex = arrayHasValue(parentOnlyTaskList)
            ? nextTaskIndex(parentOnlyTaskList)
            : 0;
        if (parentTaskIndex === NO_TASK_FOUND_INDEX) {
            setParentActiveStep(FINAL_PARENT_TASK_INDEX);
        } else {
            setParentActiveStep(parentTaskIndex);
        }
    }, [nextTaskIndex, parentOnlyTaskList]);

    useEffect(() => {
        const childTaskIndex = nextTaskIndex(childrenTaskList(), true);
        setChildActiveStep(childTaskIndex);
    }, [childrenTaskList, nextTaskIndex]);

    return (
        <Stack direction="column">
            <Typography variant="h4">Your task list</Typography>
            <Skeleton count={5} height="2.25rem" isEnabled={isLoading} sx={{ mb: 4 }} width="100%">
                <Stepper activeStep={parentActiveStep} orientation="vertical">
                    {parentOnlyTaskList?.map((task, index) => (
                        <Step
                            key={enumToString(
                                Tasks,
                                task.taskId as number,
                                TASK_FLOW_ENUM_EXCEPTIONS(currentFlow?.flowId === Flows.IchraLaunch)
                            )}
                        >
                            <StepLabel StepIconComponent={stepIcon}>
                                <Typography>
                                    {enumToString(
                                        Tasks,
                                        task.taskId,
                                        TASK_FLOW_ENUM_EXCEPTIONS(
                                            currentFlow?.flowId === Flows.IchraLaunch
                                        )
                                    )}
                                </Typography>
                            </StepLabel>
                            {parentActiveStep === index && (
                                <Stepper
                                    activeStep={childActiveStep}
                                    connector={null}
                                    orientation="vertical"
                                    style={{
                                        marginLeft: 25,
                                    }}
                                >
                                    {childrenTaskList().map((childTask) => (
                                        <Step
                                            key={enumToString(
                                                Tasks,
                                                childTask.taskId as number,
                                                TASK_FLOW_ENUM_EXCEPTIONS(
                                                    currentFlow?.flowId === Flows.IchraLaunch
                                                )
                                            )}
                                            style={{
                                                alignContent: 'center',
                                                height: 40,
                                                marginBottom: 15,
                                            }}
                                        >
                                            <StepLabel StepIconComponent={stepIcon}>
                                                <Typography>
                                                    {enumToString(
                                                        Tasks,
                                                        childTask.taskId as number,
                                                        TASK_FLOW_ENUM_EXCEPTIONS(
                                                            currentFlow?.flowId ===
                                                                Flows.IchraLaunch
                                                        )
                                                    )}
                                                </Typography>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            )}
                        </Step>
                    ))}
                </Stepper>
            </Skeleton>
        </Stack>
    );
};

export default hot(module)(IchraEnrollmentTaskFlow);
