import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PathwayUserTags } from 'api/generated/enums';
import InformationIconTooltip from 'components/InformationIconTooltip';
import ReactTable from 'components/reactTable/ReactTable';
import TableHeader from 'components/TableHeader';
import { PathwayFilterContext } from 'pages/pathwayBlueprint/PathwayBlueprintPage';
import {
    getFilters,
    getFilterTypes,
    hideTables,
    pathwayInitialSortBy,
} from 'pages/pathwayBlueprint/PathwayTable';
import { IPathwayRowData, UNKNOWN } from 'pages/pathwayBlueprint/PathwayTableCells';
import {
    commonColumns,
    getAssignColumn,
    getTagsColumn,
} from 'pages/pathwayBlueprint/PathwayTableColumns';
import React, { useContext, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { arrayHasValue, hasContents } from 'utilities';

const UnknownPathwayTable = () => {
    const { pathwayBlueprint, userProfiles } = useSelector((state: AppStore) => ({
        pathwayBlueprint: state.pathwayBlueprint,
        userProfiles: state.userProfiles,
    }));

    const { filteredState, search } = useContext(PathwayFilterContext);
    const [selectedUnknowns, setSelectedUnknowns] = useState<string[]>([]);

    const unknownData = useMemo(() => {
        const rowData: IPathwayRowData[] = [];
        for (const profile of userProfiles) {
            const pathwayUser = pathwayBlueprint?.pathwayUsers?.find(
                (pu) => pu.userId === profile.user?.userId
            );
            if (!pathwayUser || !pathwayUser.pathwayId) {
                rowData.push({
                    disabled: pathwayBlueprint?.isCalculating,
                    hasMemberVerifiedInfo: profile?.memberVerifiedInfo ? true : false,
                    isStale: pathwayUser?.isStale ?? false,
                    lastCalculatedDate: pathwayUser?.lastCalculatedDate ?? '',
                    majorMedicalCoverageLevel: profile?.majorMedicalCoverageLevel,
                    memberVerifiedInfoId: profile?.memberVerifiedInfo?.memberVerifiedInfoId ?? '',
                    pathwayYear: pathwayBlueprint?.year,
                    tags: pathwayUser?.tags ?? PathwayUserTags.None,
                    teamId: profile?.user?.teamId,
                    user: profile?.user,
                    userId: profile.user?.userId,
                } as IPathwayRowData);
            }
        }
        return rowData;
    }, [pathwayBlueprint, userProfiles]);

    const unknownColumns = useMemo(() => {
        const columns: Column<IPathwayRowData>[] = [
            ...commonColumns,
            getTagsColumn((row) => row.tags),
        ];
        if (hasContents(pathwayBlueprint?.pathways)) {
            columns.push(getAssignColumn(UNKNOWN, selectedUnknowns, setSelectedUnknowns));
        }
        return columns;
    }, [pathwayBlueprint, selectedUnknowns]);

    const unknownTableFilters = getFilters({
        searchValue: search,
        stateFilterValue: filteredState,
    });
    const unknownTableFilterTypes = getFilterTypes(userProfiles);
    const isTableHidden = useMemo(
        () => hideTables(unknownData, search, filteredState, userProfiles),
        [filteredState, unknownData, search, userProfiles]
    );

    if (!arrayHasValue(unknownData) || isTableHidden) {
        return <React.Fragment />;
    }
    return (
        <React.Fragment>
            <Typography sx={{ marginBottom: 0 }} variant="h3">
                Unassigned Members
            </Typography>
            <Paper data-cy="unknown-pathway-table" sx={{ padding: 3 }}>
                <TableHeader
                    label={
                        <Typography variant="h5">
                            Unknown
                            <InformationIconTooltip
                                className="ml-1"
                                title={
                                    'These Members have not been assigned a pathway yet. ' +
                                    'To assign use the assign action at the end of each row or in the header (for assigning multiple).'
                                }
                            />
                        </Typography>
                    }
                ></TableHeader>
                <ReactTable
                    columns={unknownColumns}
                    data={unknownData}
                    filters={unknownTableFilters}
                    filterTypes={unknownTableFilterTypes}
                    initialSortBy={pathwayInitialSortBy}
                    isRowSelectionEnabled
                    pageSize={50}
                    rowsWhenNoData={1}
                />
            </Paper>
        </React.Fragment>
    );
};
export default hot(module)(UnknownPathwayTable);
