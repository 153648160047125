import Stack from '@mui/material/Stack';
import { GET_PAYROLL_REPORTS_FOR_YEAR_ACTION } from 'actions/payroll/getPayrollReportsForYear';
import { REFRESH_PAYROLL_REPORT_ACTION } from 'actions/payroll/refreshPayrollReport';
import { IPayrollReportDto } from 'api/generated/models';
import InformationIconTooltip from 'components/InformationIconTooltip';
import Skeleton from 'components/Skeleton';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';

const InvoiceSummary = ({ payrollReport }: { payrollReport: IPayrollReportDto | undefined }) => {
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            GET_PAYROLL_REPORTS_FOR_YEAR_ACTION,
            REFRESH_PAYROLL_REPORT_ACTION
        ),
    }));
    return (
        <React.Fragment>
            <Stack alignItems="center" direction="column" justifyContent="center">
                <span>
                    Individual Benefits Invoice Total{' '}
                    <InformationIconTooltip title="Total non-Medicare reimbursable premiums and adjustments + total post-tax withholding for non-reimbursable plans" />
                </span>
                <span>
                    <Skeleton count={1} isEnabled={isLoading} width="200px">
                        <b>
                            {formatCurrency(payrollReport?.individualBenefitsTotal, {
                                preserveDecimal: true,
                            })}
                        </b>
                    </Skeleton>
                </span>
            </Stack>
            <Stack alignItems="center" direction="column" justifyContent="center">
                <span>
                    Team Benefits Invoice Total{' '}
                    <InformationIconTooltip title="Total pre-tax withholdings Team Benefits + Total Team Benefits Cost in the Monthly Expense Summary" />
                </span>
                <span>
                    <Skeleton count={1} isEnabled={isLoading} width="200px">
                        <b>
                            {formatCurrency(payrollReport?.teamBenefitsTotal, {
                                preserveDecimal: true,
                            })}
                        </b>
                    </Skeleton>
                </span>
            </Stack>
        </React.Fragment>
    );
};

export default hot(module)(InvoiceSummary);
