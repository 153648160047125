import { Card, CardContent } from '@mui/material';
import { GET_TEAM_PROFILES_ACTION } from 'actions/team/getTeamProfiles';
import Button from 'components/Button';
import HeaderTitle from 'components/HeaderTitle';
import { teamsReadyForLaunchSelector } from 'pages/dashboard/dashboardSelectors';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_DASHBOARD_PATH } from 'routers/routes';

const TeamStatuses = () => {
    const { showActivity, teamsReadyForLaunch } = useSelector((state: AppStore) => ({
        showActivity: state.apiActivity[GET_TEAM_PROFILES_ACTION],
        teamsReadyForLaunch: teamsReadyForLaunchSelector(state),
    }));
    return (
        <Card>
            <CardContent>
                <HeaderTitle>Teams Ready for Launch ({teamsReadyForLaunch.length})</HeaderTitle>
                <hr />
                {showActivity && <Skeleton />}
                {!showActivity &&
                    teamsReadyForLaunch.map((teamProfile, index) => (
                        <React.Fragment key={index}>
                            <Col>
                                <Row
                                    className="d-flex flex-wrap align-items-center"
                                    data-cy="team-ready-for-launch"
                                >
                                    <strong className="flex-fill text-muted mr-2">
                                        {teamProfile.team?.name}
                                    </strong>
                                    <Link
                                        data-cy="launch-button"
                                        to={generatePath(TEAMS_DASHBOARD_PATH, {
                                            teamId: teamProfile.team?.teamId,
                                        })}
                                    >
                                        <Button size="small">Ready for Launch</Button>
                                    </Link>
                                </Row>
                            </Col>
                            <hr />
                        </React.Fragment>
                    ))}
            </CardContent>
        </Card>
    );
};

export default hot(module)(TeamStatuses);
