import { EnrollmentVerificationStatuses, EnrollmentVerificationTypes } from 'api/generated/enums';
import { IEnrollmentVerification, IUser } from 'api/generated/models';
import Button from 'components/Button';
import CompletedTaskIndicator from 'components/CompletedTaskIndicator';
import Title from 'components/Title';
import React, { useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { formatDateForDisplay } from 'utilities/format';
import { getMemberNamesString } from 'utilities/household';
import { getEnrollmentVerificationTypeText } from 'utilities/verificationDocuments';

const EnrollmentVerificationItem = ({
    enrollmentVerification: {
        dueDate,
        householdMembers,
        id,
        includePrimary,
        rhFiles,
        status,
        type,
        year,
    },
    navigateToVerificationDocuments,
    showUploadModal,
    showValidDocumentsModal,
    user,
}: {
    enrollmentVerification: IEnrollmentVerification;
    navigateToVerificationDocuments: () => void;
    showUploadModal: (id: string) => void;
    showValidDocumentsModal: (type: EnrollmentVerificationTypes) => void;
    user: IUser | undefined;
}) => {
    const shouldUpload = rhFiles?.length === 0;
    const uploadButtonText = shouldUpload ? 'Upload' : 'View/Manage Documents';

    const onUploadClick = useCallback(
        () => (shouldUpload ? showUploadModal(id) : navigateToVerificationDocuments()),
        [id, navigateToVerificationDocuments, shouldUpload, showUploadModal]
    );

    const onWhatDoINeedClick = useCallback(() => showValidDocumentsModal(type), [
        showValidDocumentsModal,
        type,
    ]);

    return (
        <React.Fragment>
            <hr />
            <Row className="align-items-center">
                <Col xs="auto">
                    <Row className="align-items-center text-center">
                        <CompletedTaskIndicator
                            completed={status !== EnrollmentVerificationStatuses.Needed}
                            onClick={onUploadClick}
                        />
                    </Row>
                </Col>
                <Col>
                    <div>
                        <Title>{`${year} - ${getEnrollmentVerificationTypeText(type)}`}</Title>
                        <p className="my-0 text-muted">
                            For: {getMemberNamesString(includePrimary, user, householdMembers)}
                        </p>
                        <p className="my-0 text-muted">Due: {formatDateForDisplay(dueDate)}</p>
                    </div>
                </Col>
                <Col xs="auto">
                    <Button onClick={onUploadClick} size="small">
                        {uploadButtonText}
                    </Button>
                </Col>
                <Col xs="auto">
                    <a onClick={onWhatDoINeedClick}>
                        <i className="dripicons-information"></i> What do I need?
                    </a>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default hot(module)(EnrollmentVerificationItem);
