import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { EDIT_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/editSubmittedExpense';
import { GET_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/getSubmittedExpense';
import { ISubmittedExpenseDto } from 'api/generated/models';
import { EditLockedSubmittedExpense, EditSubmittedExpense } from 'api/generated/permissions';
import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';

type IRecurringExpense = {
    IsRecurring: boolean | undefined;
    handleIsRecurringChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void;
    isRecurringError: string[] | undefined;
    submittedExpense: ISubmittedExpenseDto | undefined;
};

const RecurringExpense = ({
    IsRecurring,
    handleIsRecurringChange,
    isRecurringError,
    submittedExpense,
}: IRecurringExpense) => {
    const { canEditLockedSubmittedExpense, canEditSubmittedExpense, isLoading } = useSelector(
        (state: AppStore) => ({
            canEditLockedSubmittedExpense: hasSomePermissions(state, EditLockedSubmittedExpense),
            canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
            isLoading: hasApiActivity(
                state,
                GET_SUBMITTED_EXPENSE_ACTION,
                EDIT_SUBMITTED_EXPENSE_ACTION
            ),
            recurringAllowed: state.submittedExpense.recurringAllowed,
        })
    );

    return (
        <React.Fragment>
            {canEditSubmittedExpense ? (
                <React.Fragment>
                    <Typography alignSelf="center" fontWeight="bold">
                        Recurring Expense{' '}
                        <Tooltip title="If yes is selected and this is approved, the expense will be reimbursed on a monthly basis.">
                            <InfoOutlinedIcon color="success" />
                        </Tooltip>
                    </Typography>
                    <Select
                        disabled={submittedExpense?.isLocked && !canEditLockedSubmittedExpense}
                        errors={isRecurringError}
                        isLoading={isLoading}
                        items={yesOrNo}
                        onChange={handleIsRecurringChange}
                        optionText="text"
                        optionValue="value"
                        value={IsRecurring}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography fontWeight="bold">
                        Recurring Expense{' '}
                        <Tooltip title="If yes is selected and this is approved, the expense will be reimbursed on a monthly basis.">
                            <InfoOutlinedIcon color="success" />
                        </Tooltip>
                    </Typography>
                    <Typography isLoading={isLoading}>
                        {submittedExpense?.isRecurring ? 'Yes' : 'No'}
                    </Typography>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default hot(module)(RecurringExpense);
