import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';

const BenefitSectionHeader = ({ children }: { children: ReactNode }) => (
    <Typography
        color={(theme) => theme.palette.muted.main}
        mb={2}
        pb={1}
        sx={{ borderBottom: 1, borderColor: '#98a6ad' }}
        variant="h3"
    >
        {children}
    </Typography>
);

export default hot(module)(BenefitSectionHeader);
