import { Autorenew } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { IPathwayBlueprint } from 'api/generated/models';
import { EditPathway } from 'api/generated/permissions';
import DoubleBangIcon from 'components/DoubleBangIcon';
import IconTooltip from 'components/IconTooltip';
import Tooltip from 'components/Tooltip';
import { AT_LEAST_ONE_ACTIVE_AND_PROJECTED_DIFFERENT_TEXT } from 'constants/contribution';
import TeamManagementContext from 'contexts/TeamManagementContext';
import { STALE_ICHRA_CLASS_TEXT } from 'pages/pathwayBlueprint/PathwayBlueprintNameHeader';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { PATHWAY_BLUEPRINT_PATH, TEAMS_PATHWAY_BLUEPRINT_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { formatDateForDisplay } from 'utilities/format';
import { hasValue } from 'utilities/index';

export const StaleDataIndicatorCell = ({
    row: {
        original: {
            hasMismatchedContributionsUsers,
            ichraClasses,
            isStale,
            lastBulkCalculatedDate,
        },
    },
}: Cell<IPathwayBlueprint>) => {
    const { canEditPathway } = useSelector((state: AppStore) => ({
        canEditPathway: hasSomePermissions(state, EditPathway),
    }));
    return (
        <Stack alignItems="center" direction="row">
            {hasMismatchedContributionsUsers && canEditPathway && (
                <Tooltip title={AT_LEAST_ONE_ACTIVE_AND_PROJECTED_DIFFERENT_TEXT}>
                    <DoubleBangIcon className="mr-1" />
                </Tooltip>
            )}
            {hasValue(ichraClasses?.find((ic) => ic.isStale)) && (
                <Tooltip title={STALE_ICHRA_CLASS_TEXT}>
                    <Autorenew sx={{ color: (theme) => theme.palette.orange.main }} />
                </Tooltip>
            )}
            {isStale && (
                <Tooltip
                    title={
                        <span>
                            There have been changes to member data that will likely change the costs
                            for this pathway blueprint
                            <br />
                            <br />
                            Last Bulk Calculation Date -{' '}
                            {formatDateForDisplay(lastBulkCalculatedDate) ?? 'Never'}
                        </span>
                    }
                >
                    <Autorenew sx={{ color: (theme) => theme.palette.yellow.main }} />
                </Tooltip>
            )}
        </Stack>
    );
};

export const IsActiveIndicatorCell = ({
    row: {
        original: { isActive, year },
    },
}: Cell<IPathwayBlueprint>) => (
    <React.Fragment>
        {isActive && (
            <IconTooltip
                icon="checkmark"
                title={`This is the active blueprint for ${year} that is used for recalculating Wage+ values for each member`}
                variant="primary"
            ></IconTooltip>
        )}
    </React.Fragment>
);

export const NameCell = ({
    row: {
        original: { id, isProjection, name, teamId },
    },
}: Cell<IPathwayBlueprint>) => {
    const { isTeamManagementPage } = useContext(TeamManagementContext);

    return (
        <Link
            data-cy="pathway-blueprint-name"
            to={
                isTeamManagementPage
                    ? generatePath(TEAMS_PATHWAY_BLUEPRINT_PATH, { teamId, pathwayBlueprintId: id })
                    : generatePath(PATHWAY_BLUEPRINT_PATH, { pathwayBlueprintId: id })
            }
        >
            {name} {isProjection && '(Projection)'}
        </Link>
    );
};
