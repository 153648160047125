import useTheme from '@mui/material/styles/useTheme';
import {
    ADD_USER_PROFILE_PICTURE_ACTION,
    addUserProfilePicture,
} from 'actions/rhFile/addUserProfilePicture';
import { downloadUserProfilePicture } from 'api/downloadUserProfilePicture';
import { FileResponse, IUser } from 'api/generated/models';
import { EditProfilePicture } from 'api/generated/permissions';
import { AxiosResponse } from 'axios';
import FileUploadModal from 'components/FileUploadModal';
import Icon from 'components/Icon';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';
import { isRhTeamMemberSelector } from 'selectors/role';
import styled from 'styled-components';

const getBlobUrl = async (downloadFileApiResponse: Promise<AxiosResponse<FileResponse>>) => {
    const {
        data: { data: blob },
    } = await downloadFileApiResponse;
    return URL.createObjectURL(blob);
};

const ProfilePic = styled.img<{ primaryColor: string; size: string }>`
    height: ${(props) => props.size};
    width: ${(props) => props.size};
    background-color: ${({ primaryColor }) => primaryColor};
    object-fit: contain;
`;

const ProfilePicContainer = styled.div<{ size: string }>`
    position: relative;
    height: ${(props) => props.size};
    width: ${(props) => props.size};

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        color: #fff;
    }

    &:hover .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.8);
    }
`;

const DEFAULT_PROFILE_URL = 'assets/images/logo_sm.png';

type IProfilePictureProps = {
    isCurrent?: boolean;
    size?: string;
    user: IUser | undefined;
};

const ProfilePicture = ({ isCurrent = false, size = '100px', user }: IProfilePictureProps) => {
    const theme = useTheme();
    const { canUpload, isProfilePictureLoading } = useSelector((state: AppStore) => ({
        canUpload:
            hasSomePermissions(state, EditProfilePicture) ||
            (isRhTeamMemberSelector(state) && isCurrent),
        isProfilePictureLoading: hasApiActivity(state, ADD_USER_PROFILE_PICTURE_ACTION),
    }));

    const [profileUrl, setProfileUrl] = useState(DEFAULT_PROFILE_URL);
    const [isProfileUploadVisible, setIsProfileUploadVisible] = useState(false);
    useEffect(() => {
        if (user?.profilePictureRhFileId) {
            getBlobUrl(downloadUserProfilePicture(user?.userId)).then(setProfileUrl);
        }
    }, [user?.profilePictureRhFileId, user?.userId]);

    const showProfileUploadModal = () => setIsProfileUploadVisible(true);
    const closeProfileUploadModal = () => setIsProfileUploadVisible(false);

    const onProfilePictureSubmit = useCallback(
        (files, successTooltipMessage) =>
            addUserProfilePicture(user?.userId, files, isCurrent, successTooltipMessage),
        [isCurrent, user?.userId]
    );

    return (
        <React.Fragment>
            {isProfileUploadVisible && (
                <FileUploadModal
                    acceptSingle
                    isLoading={isProfilePictureLoading}
                    onHide={closeProfileUploadModal}
                    onSubmit={onProfilePictureSubmit}
                />
            )}
            <ProfilePicContainer size={size}>
                <ProfilePic
                    className="border border-secondary rounded-circle"
                    primaryColor={theme.palette.primary.main}
                    size={size}
                    src={profileUrl}
                />
                {canUpload && (
                    <div className="overlay rounded-circle" onClick={showProfileUploadModal}>
                        <Icon size={2} variant="light">
                            upload
                        </Icon>
                    </div>
                )}
            </ProfilePicContainer>
        </React.Fragment>
    );
};

export default hot(module)(ProfilePicture);
