import { AppStoreThunkDispatch } from 'actions/commonAction';

export const SHOW_HOUSEHOLD_INCOME_MODAL = 'SHOW_HOUSEHOLD_INCOME_MODAL';
export const showHouseholdIncomeModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { isHouseholdIncomeModalVisible: true },
        type: SHOW_HOUSEHOLD_INCOME_MODAL,
    });
};
export const HIDE_HOUSEHOLD_INCOME_MODAL = 'HIDE_HOUSEHOLD_INCOME_MODAL';
export const hideHouseholdIncomeModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { isHouseholdIncomeModalVisible: false },
        type: HIDE_HOUSEHOLD_INCOME_MODAL,
    });
};

export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR';
export const setSelectedYear = (selectedYear: string | undefined) => (
    dispatch: AppStoreThunkDispatch
) => {
    dispatch({
        data: { selectedYear },
        type: SET_SELECTED_YEAR,
    });
};
