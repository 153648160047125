import {
    addEnrollmentVerificationRhFile,
    ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION,
} from 'actions/enrollmentVerification/addEnrollmentVerificationRhFile';
import {
    deleteEnrollmentVerification,
    DELETE_ENROLLMENT_VERIFICATION_ACTION,
} from 'actions/enrollmentVerification/deleteEnrollmentVerification';
import {
    unlinkEnrollmentVerificationRhFile,
    UNLINK_ENROLLMENT_VERIFICATION_RH_FILE_ACTION,
} from 'actions/enrollmentVerification/unlinkEnrollmentVerificationRhFile';
import { EnrollmentVerificationStatuses, EnrollmentVerificationTypes } from 'api/generated/enums';
import { IEnrollmentVerification, IRhFile } from 'api/generated/models';
import {
    AddEnrollmentVerificationFile,
    DeleteEnrollmentVerification,
    EditEnrollmentVerification,
    RemoveEnrollmentVerificationFile,
} from 'api/generated/permissions';
import FileUploadModal from 'components/FileUploadModal';
import { DefaultRhFileActions, IRhFileItems } from 'components/rhFile/RhFile';
import UserRhFiles from 'components/rhFile/UserRhFiles';
import ThingCard, { IThingItems } from 'components/ThingCard';
import WrappableH4 from 'components/WrappableH4';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import startCase from 'lodash/startCase';
import React, { useCallback, useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { getMemberNamesString } from 'utilities/household';

const RhFileContainer = styled(Col).attrs(() => ({ lg: '6' }))``;
const NoOverflowDiv = styled.div`
    overflow: hidden;
`;

const EnrollmentVerification = ({
    data: {
        rhFiles,
        dueDate,
        householdMembers,
        id,
        includePrimary,
        status,
        submittedDate,
        type,
        year,
    },
    onEditClick,
}: {
    data: IEnrollmentVerification;
    onEditClick: () => void;
}) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, user } = useUserProps();
    const {
        canAddFile,
        canDelete,
        canEdit,
        canRemoveFile,
        isDeleteLoading,
        isLoading,
        isRemoveFileLoading,
    } = useSelector((state: AppStore) => ({
        canAddFile: state.login.up.includes(AddEnrollmentVerificationFile) || isCurrent,
        canDelete: state.login.up.includes(DeleteEnrollmentVerification),
        canEdit: state.login.up.includes(EditEnrollmentVerification),
        canRemoveFile: state.login.up.includes(RemoveEnrollmentVerificationFile) || isCurrent,
        isDeleteLoading: hasApiActivity(state, DELETE_ENROLLMENT_VERIFICATION_ACTION),
        isLoading: hasApiActivity(state, ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION),
        isRemoveFileLoading: hasApiActivity(state, UNLINK_ENROLLMENT_VERIFICATION_RH_FILE_ACTION),
    }));
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const openFileModal = useCallback(() => setShowFileUploadModal(true), []);
    const closeFileModal = useCallback(() => setShowFileUploadModal(false), []);
    const onFileSubmit = useCallback(
        (rhFilesToAdd, successTooltipMessage) =>
            addEnrollmentVerificationRhFile(id, rhFilesToAdd, successTooltipMessage),
        [id]
    );
    const rhFileItems = useMemo(
        () =>
            [
                DefaultRhFileActions.Details,
                DefaultRhFileActions.Rename,
                DefaultRhFileActions.Download,
                {
                    confirmText: (
                        <React.Fragment>
                            <span>Are you sure?</span>
                            <div>
                                <small>This will not delete the file</small>
                            </div>
                        </React.Fragment>
                    ),
                    isConfirm: true,
                    isLoading: isRemoveFileLoading,
                    isVisible: canRemoveFile,
                    label: 'Remove',
                    onClick: async (_: React.MouseEvent, { id: documentId }: IRhFile) =>
                        dispatch(unlinkEnrollmentVerificationRhFile(id, documentId)),
                },
            ] as IRhFileItems,
        [canRemoveFile, dispatch, id, isRemoveFileLoading]
    );
    const items = useMemo(
        () =>
            [
                {
                    isVisible: canAddFile,
                    label: 'Upload Files',
                    onClick: openFileModal,
                },
                {
                    isVisible: canEdit,
                    label: 'Edit',
                    onClick: onEditClick,
                },
                {
                    confirmText: (
                        <React.Fragment>
                            <span>Are you sure?</span>
                            <div>
                                <small>This will not delete any associated file(s)</small>
                            </div>
                        </React.Fragment>
                    ),
                    isConfirm: true,
                    isLoading: isDeleteLoading,
                    isVisible: canDelete,
                    label: 'Delete',
                    onClick: async () => dispatch(deleteEnrollmentVerification(id)),
                },
            ] as IThingItems,
        [canAddFile, canDelete, canEdit, dispatch, id, isDeleteLoading, onEditClick, openFileModal]
    );
    return (
        <React.Fragment>
            {showFileUploadModal && (
                <FileUploadModal
                    isLoading={isLoading}
                    onHide={closeFileModal}
                    onSubmit={onFileSubmit}
                />
            )}
            <ThingCard
                bottomLeftContent={
                    <div>
                        <div>
                            For:{' '}
                            <strong>
                                {getMemberNamesString(includePrimary, user, householdMembers)}
                            </strong>
                        </div>
                        {hasValue(submittedDate) && (
                            <div>
                                Submitted: <strong>{formatDateForDisplay(submittedDate)}</strong>
                            </div>
                        )}
                    </div>
                }
                items={items}
                topLeftContent={
                    <NoOverflowDiv>
                        <WrappableH4 mb={0} pr={3}>
                            {year} {startCase(EnrollmentVerificationTypes[type])}
                        </WrappableH4>
                        <small>
                            {startCase(EnrollmentVerificationStatuses[status])} - Due:{' '}
                            {formatDateForDisplay(dueDate)}
                        </small>
                    </NoOverflowDiv>
                }
            >
                <UserRhFiles
                    addRhFiles={(files, toastSuccessMessage) =>
                        addEnrollmentVerificationRhFile(id, files, toastSuccessMessage)
                    }
                    canCreateRhFiles={canAddFile}
                    data={rhFiles}
                    disableFullScreenDropZone
                    hideHeader
                    isCentered
                    items={rhFileItems}
                    RhFileContainer={RhFileContainer}
                />
            </ThingCard>
        </React.Fragment>
    );
};

export default hot(module)(EnrollmentVerification);
