import Stack from '@mui/material/Stack';
import {
    setCustomProjectedReimbursement,
    SET_CUSTOM_PROJECTED_REIMBURSEMENT_ACTION,
} from 'actions/pathwayBlueprint/setCustomProjectedReimbursement';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import TextArea from 'components/TextArea';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { closeCustomReimbursementModal } from 'pages/pathwayBlueprint/pathwayCustomProjectedModalActions';
import React, { useCallback, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue, stringToFloat } from 'utilities';
import { onChange } from 'utilities/forms';
import { number, object, string } from 'yup';

const NOTE_MAX_LENGTH = 600;
const schema = object({
    note: string()
        .trim()
        .required()
        .max(NOTE_MAX_LENGTH)
        .label('Comment'),
    reimbursement: number()
        .transform(stringToFloat)
        .required()
        .label('Projected Reimbursement'),
});

const CustomProjectedReimbursementModal = () => {
    const dispatch = useThunkDispatch();
    const { isLoading, pathwayUser } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, SET_CUSTOM_PROJECTED_REIMBURSEMENT_ACTION),
        pathwayUser: state.pathwayCustomProjectedModalState.pathwayUser,
    }));
    const isCustom = hasValue(pathwayUser?.customProjectedReimbursement);

    const [reimbursement, setReimbursement] = useState(pathwayUser?.customProjectedReimbursement);
    const [note, setNote] = useState(pathwayUser?.customProjectedReimbursementNote || '');
    const [shouldReset, setShouldReset] = useState(false);
    const { errors, validate } = useForm(schema);

    const onClose = useCallback(() => {
        dispatch(closeCustomReimbursementModal());
    }, [dispatch]);
    const save = useCallback(async () => {
        const { isValid } = await validate({ note, reimbursement });
        if (isValid) {
            await dispatch(setCustomProjectedReimbursement(pathwayUser?.id, reimbursement, note));
            onClose();
        }
    }, [validate, note, reimbursement, dispatch, pathwayUser?.id, onClose]);
    const reset = useCallback(async () => {
        if (shouldReset) {
            await dispatch(setCustomProjectedReimbursement(pathwayUser?.id, undefined, undefined));
            onClose();
        }
        setShouldReset(true);
    }, [shouldReset, dispatch, pathwayUser?.id, onClose]);

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Edit Projected Reimbursement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="edit-projected-reimbursement-modal-form"
                    isLoading={isLoading}
                    onSubmit={save}
                >
                    <Stack gap={2}>
                        <CurrencyTextField
                            autoFocus
                            errors={errors?.reimbursement}
                            label="Projected Reimbursement"
                            name="reimbursement"
                            onChange={onChange(setReimbursement)}
                            placeholder="Enter Projected Reimbursement"
                            value={reimbursement}
                        />
                        <TextArea
                            errors={errors?.note}
                            label="Comment"
                            maxLength={NOTE_MAX_LENGTH}
                            name="note"
                            onChange={onChange(setNote)}
                            placeholder="Add an explanation for why a custom projected Reimbursement is required"
                            value={note}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Col>
                    <Row className="justify-content-center">
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            className="pl-2"
                            form="edit-projected-reimbursement-modal-form"
                            isLoading={isLoading}
                            type="submit"
                        >
                            Save
                        </Button>
                    </Row>
                    {isCustom && (
                        <Row className="justify-content-center mt-1">
                            <Button
                                color="error"
                                data-cy="reset-reimbursement"
                                isLoading={isLoading}
                                onClick={reset}
                                onMouseLeave={() => setShouldReset(false)}
                                variant="text"
                            >
                                {shouldReset ? 'Are you sure?' : 'Reset'}
                            </Button>
                        </Row>
                    )}
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(CustomProjectedReimbursementModal);
