import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { downloadSubmittedExpenseFile } from 'api/downloadSubmittedExpenseFile';
import { IRhFileDto, ISubmittedExpenseDto } from 'api/generated/models';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import RhFilePreviewModal from 'components/rhFile/RhFilePreviewModal';
import useModalState from 'hooks/useModalState';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { hot } from 'react-hot-loader';
import { getFileIconDataForExtension } from 'utilities/files';

const SubmittedExpenseFile = ({
    enableIconButton,
    file,
    isDeleting = false,
    isLoading,
    isNewFile = false,
    onIconButtonClick,
    showIconButton,
    submittedExpense,
}: {
    enableIconButton: boolean;
    file: File | IRhFileDto;
    isDeleting?: boolean;
    isLoading: boolean;
    isNewFile?: boolean;
    onIconButtonClick: (rhFile: File | IRhFileDto) => void;
    showIconButton: boolean;
    submittedExpense: ISubmittedExpenseDto;
}) => {
    const {
        isVisible: isPreviewModalVisible,
        closeModal: closePreviewModal,
        openModal: openPreviewModal,
    } = useModalState();
    const iconData = getFileIconDataForExtension(
        (file as IRhFileDto)?.fileExtension ?? file?.name?.split('.').pop() ?? ''
    );
    const getIconButtonTooltipText = () => {
        let text = 'Click to delete this file on save';
        if ((file as IRhFileDto)?.createdByUser?.userId === submittedExpense.userId) {
            text = 'Cannot delete file uploaded by member';
        } else if (isDeleting) {
            text = 'Click to keep file';
        } else if (isNewFile) {
            text = 'Click to remove file from upload';
        }
        return text;
    };
    const getFileListItemTooltipText = () => {
        let text = 'Click to preview file';
        if (isNewFile) {
            text = 'File will be uploaded when Save is clicked';
        } else if (isDeleting) {
            text = 'File will be deleted when Save is clicked';
        }
        return text;
    };
    const buildFileListItem = () => {
        let component: JSX.Element = (
            <React.Fragment>
                <ListItemIcon>
                    <Icon prefix="mdi" size="2" variant={iconData.color}>
                        {iconData.icon}
                    </Icon>
                </ListItemIcon>
                <ListItemText
                    data-cy={`${kebabCase(file.name)}-file-name`}
                    primary={<React.Fragment>{file.name}</React.Fragment>}
                />
            </React.Fragment>
        );
        if (isNewFile) {
            component = (
                <ListItem
                    sx={{
                        opacity: isDeleting ? '.3' : '1',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {component}
                </ListItem>
            );
        } else {
            component = (
                <ListItemButton
                    disabled={isLoading || isDeleting}
                    onClick={openPreviewModal}
                    sx={{
                        opacity: isDeleting ? '.3' : '1',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {component}
                </ListItemButton>
            );
        }

        return component;
    };

    return (
        <React.Fragment>
            {isPreviewModalVisible && (
                <RhFilePreviewModal
                    canDownloadRhFiles
                    downloadRhFile={async () =>
                        downloadSubmittedExpenseFile(
                            submittedExpense.globalId,
                            (file as IRhFileDto).id
                        )
                    }
                    onClose={closePreviewModal}
                    rhFile={file as IRhFileDto}
                />
            )}
            <ListItem sx={{ justifyContent: 'space-between' }}>
                <Tooltip title={getFileListItemTooltipText()}>{buildFileListItem()}</Tooltip>
                {showIconButton && (
                    <Tooltip title={getIconButtonTooltipText()}>
                        <IconButton
                            disabled={isLoading || !enableIconButton}
                            onClick={() => onIconButtonClick(file)}
                        >
                            {isDeleting ? <AddIcon /> : <CloseIcon />}
                        </IconButton>
                    </Tooltip>
                )}
            </ListItem>
        </React.Fragment>
    );
};

export default hot(module)(SubmittedExpenseFile);
