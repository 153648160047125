import { editSocialSecurityNumbers } from 'actions/user/editSocialSecurityNumbers';
import { EntityType } from 'api/generated/enums';
import { IMemberVerifiedInfo } from 'api/generated/models';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableSocialSecurityAttribute from 'components/EditableSocialSecurityAttribute';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import HeightProfileAttribute from 'components/HeightProfileAttribute';
import WeightProfileAttribute from 'components/WeightProfileAttribute';
import GENDERS from 'constants/genders';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { genderList } from 'reducers/options';
import { formatGender } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type IBasicVerifiedInfoProps = {
    memberVerifiedInfo: IMemberVerifiedInfo | undefined;
    userId: string | undefined;
    verifiedInfoCommonProps: ICommonProps<IMemberVerifiedInfo>;
};

const BasicVerifiedInfo = ({
    memberVerifiedInfo,
    userId,
    verifiedInfoCommonProps,
}: IBasicVerifiedInfoProps) => {
    const dispatch = useThunkDispatch();
    const { selectedYear } = useSelector((state: AppStore) => ({
        selectedYear: +state.profileState.selectedYear,
    }));
    const [socialSecurityNumber, setSocialSecurityNumber] = useState(
        memberVerifiedInfo?.socialSecurityNumber
    );
    const [gender, setGender] = useState(memberVerifiedInfo?.gender);
    const [usCitizen, setUsCitizen] = useState(memberVerifiedInfo?.usCitizen?.toString() || '');
    const [naturalizedCitizen, setNaturalizedCitizen] = useState(
        memberVerifiedInfo?.naturalizedCitizen?.toString() || ''
    );
    const [americanIndian, setAmericanIndian] = useState(
        memberVerifiedInfo?.americanIndian?.toString() || ''
    );
    const [alaskanNative, setAlaskanNative] = useState(
        memberVerifiedInfo?.alaskanNative?.toString() || ''
    );
    const [fullTimeStudent, setFullTimeStudent] = useState(
        memberVerifiedInfo?.fullTimeStudent?.toString() || ''
    );
    const [fosterCareAt18OrOlder, setFosterCareAt18OrOlder] = useState(
        memberVerifiedInfo?.fosterCareAt18OrOlder?.toString() || ''
    );
    const [specialNeeds, setSpecialNeeds] = useState(
        memberVerifiedInfo?.specialNeeds?.toString() || ''
    );
    const saveSocialSecurityNumber = useCallback(
        async (_name, socialSecurityNumber) => {
            if (userId !== undefined) {
                await dispatch(
                    editSocialSecurityNumbers(
                        userId,
                        selectedYear,
                        {
                            [userId]: {
                                socialSecurityNumber,
                                entityId: userId,
                                entityType: EntityType.User,
                            },
                        },
                        true
                    )
                );
            }
        },
        [dispatch, selectedYear, userId]
    );
    return (
        <React.Fragment>
            <EditableSocialSecurityAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="Social Security Number"
                name="socialSecurityNumber"
                onChange={onChange(setSocialSecurityNumber)}
                save={saveSocialSecurityNumber}
                userId={userId}
                value={socialSecurityNumber}
            />

            <EditableSelectAttribute
                {...verifiedInfoCommonProps}
                defaultText="Select a gender"
                formatter={(x) => formatGender(x as GENDERS)}
                items={genderList}
                label="Gender"
                name="gender"
                onChange={onChange(setGender)}
                optionText="value"
                optionValue="id"
                validationSchema={string()
                    .required()
                    .label('Gender')}
                value={gender}
            />

            <HeightProfileAttribute
                {...verifiedInfoCommonProps}
                heightInInches={memberVerifiedInfo?.heightInInches}
            />

            <WeightProfileAttribute
                {...verifiedInfoCommonProps}
                weightInPounds={memberVerifiedInfo?.weightInPounds}
            />

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="U.S. Citizen"
                name="usCitizen"
                onChange={onChange(setUsCitizen)}
                value={usCitizen}
            />

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="Naturalized Citizen"
                name="naturalizedCitizen"
                onChange={onChange(setNaturalizedCitizen)}
                value={naturalizedCitizen}
            />

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="American Indian"
                name="americanIndian"
                onChange={onChange(setAmericanIndian)}
                value={americanIndian}
            />

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="Alaskan Native"
                name="alaskanNative"
                onChange={onChange(setAlaskanNative)}
                value={alaskanNative}
            />

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="Full-Time Student"
                name="fullTimeStudent"
                onChange={onChange(setFullTimeStudent)}
                value={fullTimeStudent}
            />

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="Foster Care at 18 or Older"
                name="fosterCareAt18OrOlder"
                onChange={onChange(setFosterCareAt18OrOlder)}
                value={fosterCareAt18OrOlder}
            />

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                isOptional
                label="Special Needs"
                name="specialNeeds"
                onChange={onChange(setSpecialNeeds)}
                value={specialNeeds}
            />
        </React.Fragment>
    );
};

export default hot(module)(BasicVerifiedInfo);
