import { Card, CardContent } from '@mui/material';
import { ISurveyHouseholdMemberDto, IUser } from 'api/generated/models';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import titleCase from 'titleize';
import { getMemberNames } from 'utilities/household';

export type IListItem = {
    entityIds?: string[];
    name?: string;
};
type IHouseholdListCardsProps<T extends IListItem> = {
    keyProperty: keyof T;
    members: (ISurveyHouseholdMemberDto | IUser)[];
    onClick: (listItem: T, index: number) => React.MouseEventHandler;
    things: T[];
};
const HouseholdListCards: <T extends IListItem>(
    props: IHouseholdListCardsProps<T>
) => JSX.Element = <T extends IListItem>({
    keyProperty,
    members,
    onClick,
    things,
}: IHouseholdListCardsProps<T>) => (
    <div className="mt-3">
        {things.map((x: T, index) => (
            <Card
                className="bg-secondary-65-lighten-hover clickable"
                data-cy={`list-card-${index}`}
                key={`${x[keyProperty] ?? index}`}
                onClick={onClick(x, index)}
                sx={{ padding: 0 }}
            >
                <CardContent sx={{ padding: 2 }}>
                    <Row className="align-items-center">
                        <Col>
                            <strong>{titleCase(x.name ?? '')}</strong>
                        </Col>
                        <Col className="text-grey text-right">
                            {getMemberNames(x.entityIds, members)?.join(', ')}
                        </Col>
                    </Row>
                </CardContent>
            </Card>
        ))}
    </div>
);

export default hot(module)(HouseholdListCards);
