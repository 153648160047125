import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FONT_SEMI_BOLD } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';

const SummaryRowItem = ({
    content,
    label,
    useMask,
}: {
    content?: string;
    label: string;
    useMask?: boolean;
}) => {
    const [isOver, setIsOver] = useState<boolean>(false);

    return (
        <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">{label}</Typography>
            <span onMouseEnter={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)}>
                <Typography fontWeight={FONT_SEMI_BOLD} variant="body1">
                    {!useMask || isOver ? content : 'XXX-XX-XXXX'}
                </Typography>
            </span>
        </Stack>
    );
};

export default hot(module)(SummaryRowItem);
