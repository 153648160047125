import { Card, CardContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { signUp, SIGNUP_ACTION } from 'actions/user/signUp';
import ActivityIndicator from 'components/ActivityIndicator';
import Form from 'components/Form';
import FormButton from 'components/FormButton';
import PasswordInputs from 'components/passwordInputs/PasswordInputs';
import TextField from 'components/TextField';
import UnauthenticatedPageHeader from 'components/UnauthenticatedPageHeader';
import { USER_GROUP_KEY } from 'constants/pactSafeGroups';
import useForm from 'hooks/useForm';
import useQuery from 'hooks/useQuery';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { LOGIN_PATH, WELCOME_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { onAllPactSafe, onChange as onChangeGlobal } from 'utilities/forms';
import { IS_DEV_MODE } from 'utilities/index';
import PactSafe, { PACTSAFE_ACCESS_ID } from 'utilities/pactSafe';
import { object, string } from 'yup';

const ONE_HUNDRED = 100;
const schema = object({
    firstName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('First Name'),
    lastName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('Last Name'),
});

const QUERY_PARAM_COMPLETE = 'complete';

const SignUpPage = () => {
    const dispatch = useThunkDispatch();
    const [query] = useQuery();
    const email = query.get('email') ?? '';
    const token = query.get('t');
    const isSignUpComplete = query.has(QUERY_PARAM_COMPLETE);

    const {
        apiError,
        apiErrorMessage,
        isAuthenticated,
        loaded,
        passwordInputs,
        showActivity,
    } = useSelector((state: AppStore) => ({
        apiError: !!state.apiErrors[SIGNUP_ACTION.statusCode],
        apiErrorMessage: state.apiErrors[SIGNUP_ACTION.errorMessage],
        isAuthenticated: state.login.isAuthenticated,
        loaded: state.current.loaded,
        passwordInputs: state.passwordInputsState,
        showActivity: hasApiActivity(state, SIGNUP_ACTION),
    }));

    const [firstName, setFirstName] = useState('');
    const [hasAgreed, setHasAgreed] = useState(false);
    const [lastName, setLastName] = useState('');
    const [pactSafeRenderData, setPactSafeRenderData] = useState({
        email: '',
        firstName: '',
        lastName: '',
    });
    const { errors, validate } = useForm(schema);

    const redirectUrl = isSignUpComplete && isAuthenticated && loaded ? WELCOME_PATH : '';

    const updateRenderData = () => {
        setPactSafeRenderData({
            email,
            firstName,
            lastName,
        });
    };
    const onChange = <T,>(setValue: React.Dispatch<React.SetStateAction<T>>) => (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        onChangeGlobal(setValue)(e);
        updateRenderData();
    };
    const handleSubmit = async () => {
        const { isValid } = await validate({ firstName, lastName });
        if (isValid && passwordInputs.isValid) {
            PactSafe.agreeToContract(USER_GROUP_KEY);
            await dispatch(
                signUp(firstName, lastName, email, passwordInputs.password, token as string)
            );
        }
    };

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
    } else {
        return (
            <div className="mt-7 mb-7">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="6">
                            <Card sx={{ padding: 0 }}>
                                <UnauthenticatedPageHeader />
                                <CardContent className="p-4">
                                    <div className="text-center">
                                        <Typography
                                            color="grey.900"
                                            fontWeight="bold"
                                            mt={0}
                                            textAlign="center"
                                            variant="h4"
                                        >
                                            Sign Up
                                        </Typography>
                                        <p className="text-muted mb-0">
                                            Enter your information to sign up for an account.
                                        </p>
                                        <p className="text-muted mt-0">
                                            If you already have an account, you can&nbsp;
                                            <a href={LOGIN_PATH}>login here.</a>.
                                        </p>
                                    </div>
                                    {apiError && (
                                        <Alert
                                            className="border-0 mt-0"
                                            data-cy="login-alert"
                                            variant="danger"
                                        >
                                            {apiErrorMessage}
                                        </Alert>
                                    )}
                                    <Form isLoading={showActivity} onSubmit={handleSubmit}>
                                        <Stack gap={2}>
                                            <TextField
                                                data-cy="first-name"
                                                errors={errors?.firstName}
                                                label="First Name"
                                                name="firstName"
                                                onChange={onChange(setFirstName)}
                                                placeholder="Enter your first name"
                                                value={firstName}
                                            />
                                            <TextField
                                                data-cy="last-name"
                                                errors={errors?.lastName}
                                                label="Last Name"
                                                name="lastName"
                                                onChange={onChange(setLastName)}
                                                placeholder="Enter your last name"
                                                value={lastName}
                                            />
                                            <TextField
                                                data-cy="email"
                                                disabled
                                                label="Email"
                                                name="email"
                                                type="email"
                                                value={email}
                                            />
                                            <PasswordInputs />
                                        </Stack>
                                        {!showActivity && (
                                            <div className="mt-3">
                                                <PSClickWrap
                                                    accessId={PACTSAFE_ACCESS_ID}
                                                    disableSending
                                                    dynamic
                                                    groupKey={USER_GROUP_KEY}
                                                    onAll={onAllPactSafe(setHasAgreed)}
                                                    renderData={pactSafeRenderData}
                                                    signerId={email}
                                                    testMode={IS_DEV_MODE}
                                                />
                                                <FormButton
                                                    data-cy="submit"
                                                    disabled={!hasAgreed || !passwordInputs.isValid}
                                                    label="Sign Up"
                                                />
                                            </div>
                                        )}
                                    </Form>
                                </CardContent>
                                {showActivity && <ActivityIndicator top={122} />}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
};

export default hot(module)(SignUpPage);
