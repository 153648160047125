import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GroupIcon from '@mui/icons-material/Group';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { GET_REIMBURSEMENT_REPORT_ACTION } from 'actions/reimbursementReport/getReimbursementReport';
import { LIST_REIMBURSEMENT_REPORTS_ACTION } from 'actions/reimbursementReport/listReimbursementReports';
import {
    TOGGLE_REIMBURSEMENT_REPORT_SHARED_ACTION,
    toggleReimbursementReportShared,
} from 'actions/reimbursementReport/toggleReimbursementReportShared';
import { downloadReimbursementReportRhFile } from 'api/downloadReimbursementReportRhFile';
import {
    DownloadReimbursementReportRhFile,
    EditSubmittedExpense,
    ViewSubmittedExpenses,
} from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import { IActionButtonItems } from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import PageHeader from 'components/PageHeader';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import Tooltip from 'components/Tooltip';
import { push } from 'connected-react-router';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useModalState from 'hooks/useModalState';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { upperFirst } from 'lodash';
import { REIMBURSEMENT_REPORTS_QUERY_PARAM } from 'pages/reimbursementReports/ReimbursementReportsPage';
import { setReimbursementReportSelectedYear } from 'pages/reimbursementReports/reimbursementReportActions';
import { default as React, useCallback, useContext, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import {
    REIMBURSEMENT_YEARLY_SUMMARY_PATH,
    TEAMS_REIMBURSEMENT_YEARLY_SUMMARY_PATH,
} from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isInRoleSelector } from 'selectors/role';
import { onChange } from 'utilities/forms';
import { downloadFileFromApi } from 'utilities/rhFile';

const ReimbursementReportsPageHeader = ({
    reportGlobalId,
    setReportGlobalId,
}: {
    reportGlobalId: string;
    setReportGlobalId: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const dispatch = useThunkDispatch();
    const {
        canDownloadReimbursementReportRhFile,
        canEditExpenses,
        canEditReport,
        canViewYearlyButton,
        isLoadingReports,
        isLoadingToggleReportShared,
        isRhTeamMember,
        reimbursementReports,
        reimbursementReportYears,
        selectedYear,
    } = useSelector((state: AppStore) => ({
        canDownloadReimbursementReportRhFile: hasSomePermissions(
            state,
            DownloadReimbursementReportRhFile
        ),
        canEditExpenses: hasSomePermissions(state, EditSubmittedExpense),
        canEditReport: hasSomePermissions(state, EditSubmittedExpense),
        canViewYearlyButton: hasSomePermissions(state, ViewSubmittedExpenses),
        isLoading: hasApiActivity(
            state,
            LIST_REIMBURSEMENT_REPORTS_ACTION,
            GET_REIMBURSEMENT_REPORT_ACTION
        ),
        isLoadingReports: hasApiActivity(state, LIST_REIMBURSEMENT_REPORTS_ACTION),
        isLoadingToggleReportShared: hasApiActivity(
            state,
            TOGGLE_REIMBURSEMENT_REPORT_SHARED_ACTION
        ),
        isRhTeamMember: isInRoleSelector(state, ROLE_IDS.RH_TEAM_MEMBER),
        reimbursementReports: state.reimbursementReports,
        reimbursementReportYears: state.reimbursementReportYears.map((y) => ({ name: y })),
        selectedYear: state.reimbursementReportState.reimbursementReportSelectedYear,
    }));
    const { teamId } = useTeamProps();
    const [
        isReimbursementReportRhFileDownloading,
        setIsReimbursementReportRhFileDownloading,
    ] = useState(false);
    const {
        closeModal: closeToggleReportSharedModal,
        isVisible: isToggleReportSharedModalVisible,
        openModal: openToggleReportSharedModal,
    } = useModalState();
    const { isTeamManagementPage } = useContext(TeamManagementContext);

    const navigateToYearlySummary = () =>
        isTeamManagementPage
            ? dispatch(
                  push(
                      generatePath(TEAMS_REIMBURSEMENT_YEARLY_SUMMARY_PATH, {
                          teamId,
                      })
                  )
              )
            : dispatch(push(generatePath(REIMBURSEMENT_YEARLY_SUMMARY_PATH)));

    const [query, setQuery] = useQuery();
    const currentIndex = reimbursementReports.findIndex((x) => x.globalId === reportGlobalId);
    const currentReport = reimbursementReports[currentIndex];
    const sharingPrefix = currentReport?.isShared ? 'un-' : '';
    const showShareActionButton = canEditExpenses && !!currentReport;

    const onReimbursementReportRhFileDownloadClick = useCallback(async () => {
        setIsReimbursementReportRhFileDownloading(true);
        await downloadFileFromApi(async () =>
            downloadReimbursementReportRhFile(currentReport?.globalId)
        );
        setIsReimbursementReportRhFileDownloading(false);
    }, [currentReport?.globalId]);

    const actions: IActionButtonItems = [
        {
            isVisible: canViewYearlyButton,
            onClick: navigateToYearlySummary,
            text: 'View Yearly Summary',
        },
        {
            isVisible: showShareActionButton,
            onClick: openToggleReportSharedModal,
            text: `${upperFirst(sharingPrefix)}Share`,
        },
        {
            isLoading: isReimbursementReportRhFileDownloading,
            isVisible: canDownloadReimbursementReportRhFile,
            onClick: onReimbursementReportRhFileDownloadClick,
            text: 'Download',
        },
    ];

    const setCurrentReportGlobalId = (newReportGlobalId: string) => {
        setReportGlobalId(newReportGlobalId);
        query.set(REIMBURSEMENT_REPORTS_QUERY_PARAM.REIMBURSEMENT_REPORT_ID, newReportGlobalId);
        setQuery(query);
    };

    const navigateToNewYear = (newYear: string, indexOffset: number) => {
        setCurrentReportGlobalId('');
        dispatch(setReimbursementReportSelectedYear(newYear));
        query.delete(REIMBURSEMENT_REPORTS_QUERY_PARAM.REIMBURSEMENT_REPORT_ID);
        const newReportGlobalId = reimbursementReports[currentIndex + indexOffset]?.globalId ?? '';
        query.set(REIMBURSEMENT_REPORTS_QUERY_PARAM.REIMBURSEMENT_REPORT_ID, newReportGlobalId);
        setQuery(query);
        setReportGlobalId(newReportGlobalId);
    };

    const decrementTerm = () => {
        const nextReportYear =
            reimbursementReports[currentIndex - 1]?.startDate.getYear().toString() ?? '';
        if (nextReportYear?.toString() !== selectedYear) {
            navigateToNewYear(nextReportYear, -1);
        } else {
            const newGlobalId = reimbursementReports[currentIndex - 1]?.globalId ?? '';
            setCurrentReportGlobalId(newGlobalId);
        }
    };

    const incrementTerm = () => {
        const yearOfNextReport =
            reimbursementReports[currentIndex + 1]?.startDate.getYear().toString() ?? '';
        if (yearOfNextReport !== selectedYear) {
            navigateToNewYear(yearOfNextReport, 1);
        } else {
            const newGlobalId = reimbursementReports[currentIndex + 1]?.globalId ?? '';
            setCurrentReportGlobalId(newGlobalId);
        }
    };

    const setCurrentYear = (newYear: string) => {
        dispatch(setReimbursementReportSelectedYear(newYear));
        query.delete(REIMBURSEMENT_REPORTS_QUERY_PARAM.REIMBURSEMENT_REPORT_ID);
        const newReportGlobalId =
            reimbursementReports.filter((x) => x.startDate.getYear().toString() === newYear)[0]
                ?.globalId ?? '';
        query.set(REIMBURSEMENT_REPORTS_QUERY_PARAM.REIMBURSEMENT_REPORT_ID, newReportGlobalId);
        setQuery(query);
        setReportGlobalId(newReportGlobalId);
    };

    const toggleReportShared = async () => {
        await dispatch(toggleReimbursementReportShared(currentReport?.globalId, sharingPrefix));
        closeToggleReportSharedModal();
    };

    const toggleReportSharedText = (
        <div>
            <p>
                {currentReport?.isShared
                    ? 'This Reimbursement Report will be un-shared with the team admins and payroll admins.'
                    : 'This Reimbursement Report will be shared with the team admins and payroll admins. An email including the link to this report will be sent to the team admins and payroll admins.'}
            </p>
            <p>{`Are you sure you want to ${sharingPrefix}share this Reimbursement Report?`}</p>
        </div>
    );

    return (
        <React.Fragment>
            {isToggleReportSharedModalVisible && (
                <ConfirmationModal
                    body={toggleReportSharedText}
                    onNoClick={closeToggleReportSharedModal}
                    onYesClick={toggleReportShared}
                    showActivity={isLoadingToggleReportShared}
                    title={`${upperFirst(sharingPrefix)}Share Reimbursement Report?`}
                />
            )}
            <TableHeader
                dropdownWhenLengthGreaterThan={1}
                items={actions}
                OverrideLeftSide={
                    <Stack
                        alignItems="center"
                        direction={{ md: 'row', xs: 'column' }}
                        justifyContent="center"
                        spacing={2}
                    >
                        <PageHeader variant="h4">Reimbursement Reports</PageHeader>
                        {reimbursementReports.length > 0 && (
                            <React.Fragment>
                                <Select
                                    items={reimbursementReportYears}
                                    name="selectedYear"
                                    onChange={onChange(setCurrentYear)}
                                    optionText="name"
                                    optionValue="name"
                                    sx={{ maxWidth: 100, minWidth: 100 }}
                                    value={selectedYear}
                                />
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="center"
                                    spacing={2}
                                >
                                    <Skeleton count={2} isEnabled={isLoadingReports}>
                                        <IconButton
                                            aria-label="back term"
                                            disabled={currentIndex === 0}
                                            onClick={decrementTerm}
                                        >
                                            <ArrowBackIosNewIcon />
                                        </IconButton>
                                        <Select
                                            items={reimbursementReports.filter(
                                                (x) =>
                                                    x.startDate.getYear() === Number(selectedYear)
                                            )}
                                            name="Term"
                                            onChange={onChange(setCurrentReportGlobalId)}
                                            optionText="termLabel"
                                            optionValue="globalId"
                                            sx={{ minWidth: 130 }}
                                            value={reportGlobalId}
                                        />
                                        <IconButton
                                            aria-label="forward term"
                                            disabled={
                                                currentIndex === reimbursementReports.length - 1
                                            }
                                            onClick={incrementTerm}
                                        >
                                            <ArrowForwardIosIcon />
                                        </IconButton>
                                    </Skeleton>
                                    {currentReport?.isShared && (canEditReport || isRhTeamMember) && (
                                        <Tooltip title="This reimbursement report has been shared with the Team Admin(s) and Payroll Admin(s).">
                                            <GroupIcon color="secondary" />
                                        </Tooltip>
                                    )}
                                </Stack>
                            </React.Fragment>
                        )}
                    </Stack>
                }
            />
        </React.Fragment>
    );
};

export default hot(module)(ReimbursementReportsPageHeader);
