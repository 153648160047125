import { clearRole, clearTeamUserRoles } from 'actions/clear';
import { getRole } from 'actions/role/getRole';
import { ADD_USER_ROLE_ACTION, addUserRole } from 'actions/user/addUserRole';
import { GET_TEAM_USER_ROLES_ACTION, getTeamUserRoles } from 'actions/user/getTeamUserRoles';
import { REMOVE_USER_ROLE_ACTION, removeUserRole } from 'actions/user/removeUserRole';
import { IUserRoleStatus } from 'api/generated/models';
import { EditUserRoles } from 'api/generated/permissions';
import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import PageHeader from 'components/PageHeader';
import PageSectionWrapper from 'components/PageSectionWrapper';
import { push } from 'connected-react-router';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import EditRoleUserTable from 'pages/editRole/EditRoleUserTable';
import React, { useCallback, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { IAppRouterProps } from 'routers/AppRouter';
import { hasSomePermissions } from 'selectors';

type IActionOnClick = (user: IUserRoleStatus) => () => Promise<void>;

// eslint-disable-next-line react/display-name
const RemoveActionsCell = (canEditUserRoles: boolean, onClick: IActionOnClick) => ({
    row,
}: Cell<IUserRoleStatus>) => (
    <div>
        {canEditUserRoles && (
            <Button color="error" onClick={onClick(row.original)}>
                Remove
            </Button>
        )}
    </div>
);

// eslint-disable-next-line react/display-name
const AddActionsCell = (canEditUserRoles: boolean, onClick: IActionOnClick) => ({
    row,
}: Cell<IUserRoleStatus>) => (
    <div>{canEditUserRoles && <Button onClick={onClick(row.original)}>Add</Button>}</div>
);

const EditRolePage = ({ match }: IAppRouterProps) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { canEditUserRoles, role, roleId, showActivity, teamUserRoles } = useSelector(
        (state: AppStore) => ({
            canEditUserRoles: hasSomePermissions(state, EditUserRoles),
            role: state.role,
            roleId: get(match, 'params.roleId'),
            showActivity:
                state.apiActivity[GET_TEAM_USER_ROLES_ACTION] ||
                state.apiActivity[ADD_USER_ROLE_ACTION] ||
                state.apiActivity[REMOVE_USER_ROLE_ACTION],
            teamUserRoles: state.teamUserRoles,
        })
    );

    const usersInRole = sortBy(
        teamUserRoles.filter((u) => u.roleIds?.some((x) => x === roleId)),
        (u) => u.firstName
    );

    const usersNotInRole = sortBy(
        teamUserRoles.filter((u) => !u.roleIds?.some((x) => x === roleId)),
        (u) => u.firstName
    );

    if (!canEditUserRoles) {
        dispatch(push('/'));
    }

    useEffect(() => {
        dispatch(getTeamUserRoles(teamId, roleId));
        dispatch(getRole(roleId));
        return () => {
            dispatch(clearRole());
            dispatch(clearTeamUserRoles());
        };
    }, [dispatch, roleId, teamId]);

    const removeUser = useCallback(
        (user) => async () => dispatch(removeUserRole(teamId, user.userId, roleId)),
        [dispatch, roleId, teamId]
    );
    const addUser = useCallback(
        (user) => async () => dispatch(addUserRole(teamId, user.userId, roleId)),
        [dispatch, roleId, teamId]
    );

    return (
        <PageSectionWrapper>
            {!role.name ? (
                <Skeleton />
            ) : (
                <PageHeader variant="h4">{`${role.name} Role`}</PageHeader>
            )}
            <hr />

            <Row>
                <Col sm="6">
                    <EditRoleUserTable
                        ActionsCell={RemoveActionsCell(canEditUserRoles, removeUser)}
                        title="Users in Role"
                        userData={usersInRole}
                    />
                </Col>

                <Col sm="6">
                    <EditRoleUserTable
                        ActionsCell={AddActionsCell(canEditUserRoles, addUser)}
                        title="Users Not in Role"
                        userData={usersNotInRole}
                    />
                </Col>
            </Row>

            {showActivity && <ActivityIndicator />}
        </PageSectionWrapper>
    );
};

export default hot(module)(EditRolePage);
