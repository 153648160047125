import { Card, CardContent, Grid, Paper } from '@mui/material';
import { patchTeam } from 'actions/team/patchTeam';
import { HrsIntegrationProviders } from 'api/generated/enums';
import { GenerateHrsIntegrationKey, ViewHrsIntegrationKey } from 'api/generated/permissions';
import { IValueType } from 'components/EditableAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import PageHeader from 'components/PageHeader';
import Skeleton from 'components/Skeleton';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { startCase } from 'lodash';
import HrsIntegrationSection from 'pages/integrations/HrsIntegrationSection';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { onChange } from 'utilities/forms';
import { enumToNameValueArray, getEnumElementByIndex } from 'utilities/index';
import { string } from 'yup';

const hrsIntegrationTypeFormatter = (x: string | undefined) => startCase(x);

const HRS_INTEGRATION_TYPE_OPTIONS = (enumToNameValueArray(HrsIntegrationProviders, {
    formatName: hrsIntegrationTypeFormatter,
    nameKey: 'label',
}) as unknown) as { label: string; value: HrsIntegrationProviders }[];

type IIntegrationsLoadingContext = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const IntegrationsLoadingContext = React.createContext<IIntegrationsLoadingContext>({
    loading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLoading: () => {},
});

const IntegrationsPage = () => {
    const dispatch = useThunkDispatch();
    const { hasTeam, hasTeamIdUrlParam, team, teamId } = useTeamProps();
    const { canGenerateHrsIntegrationKey, canViewHrsIntegrationKey } = useSelector(
        (state: AppStore) => ({
            canGenerateHrsIntegrationKey: hasSomePermissions(state, GenerateHrsIntegrationKey),
            canViewHrsIntegrationKey: hasSomePermissions(state, ViewHrsIntegrationKey),
        })
    );

    const [hrsIntegrationProvider, setHrsIntegrationProvider] = useState<
        HrsIntegrationProviders | undefined
    >(team?.hrsIntegrationProvider);
    const [loading, setLoading] = useState<boolean>(true);

    const save = useCallback(
        async (name, value) => {
            await dispatch(patchTeam(teamId, { [name]: value }, !hasTeamIdUrlParam, true));
        },
        [dispatch, hasTeamIdUrlParam, teamId]
    );

    const saveHrsIntegrationType = useCallback(
        async (name, value) => {
            await save(name, value);
        },
        [save]
    );

    const handleHrsIntegrationProviderChange = (value: number) => {
        const enumValue = getEnumElementByIndex(HrsIntegrationProviders, value);
        setHrsIntegrationProvider(enumValue);
    };

    useEffect(() => {
        if (team) {
            if (team.hrsIntegrationProvider === undefined) {
                setLoading(false);
            } else {
                setHrsIntegrationProvider(team.hrsIntegrationProvider);
            }
        }
    }, [team]);

    const showSkeleton = !hasTeam || loading;

    const loadingContextValue = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);

    return (
        <React.Fragment>
            <Paper sx={{ mb: 3, p: 2 }}>
                <PageHeader variant="h4">Integrations</PageHeader>
            </Paper>
            <IntegrationsLoadingContext.Provider value={loadingContextValue}>
                <Skeleton count={4} height="400px" isEnabled={showSkeleton}>
                    <Grid columnSpacing={4} container rowSpacing={3}>
                        <Grid item xl={6} xs={12}>
                            <Card>
                                <CardContent>
                                    {canViewHrsIntegrationKey && (
                                        <EditableSelectAttribute
                                            formatter={(x: IValueType) =>
                                                hrsIntegrationTypeFormatter(
                                                    HrsIntegrationProviders[x as number]
                                                )
                                            }
                                            hrClassName="d-none"
                                            items={HRS_INTEGRATION_TYPE_OPTIONS}
                                            label="HRS Provider"
                                            name="hrsIntegrationProvider"
                                            onChange={onChange(handleHrsIntegrationProviderChange)}
                                            optionText="label"
                                            optionValue="value"
                                            readonly={!canGenerateHrsIntegrationKey}
                                            save={saveHrsIntegrationType}
                                            validationSchema={string()
                                                .label('HRS Integration Provider to Send')
                                                .required()}
                                            value={hrsIntegrationProvider}
                                        />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Skeleton>
                <div style={{ visibility: showSkeleton ? 'hidden' : 'visible' }}>
                    {team?.hrsIntegrationProvider !== undefined && (
                        <HrsIntegrationSection save={save} team={team} />
                    )}
                </div>
            </IntegrationsLoadingContext.Provider>
        </React.Fragment>
    );
};

export default hot(module)(IntegrationsPage);
