import { ISurveyHouseholdMemberDto, IUser } from 'api/generated/models';
import Button from 'components/Button';
import Form from 'components/Form';
import isEmpty from 'lodash/isEmpty';
import MultiSelectButtons from 'pages/survey/multiSelectButtons/MultiSelectButtons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';

export type ISelectableMember = { isSelected?: boolean } & (ISurveyHouseholdMemberDto | IUser);
type ISelectWhoModalProps = {
    isEdit: boolean;
    members: ISelectableMember[];
    onClose: () => void;
    onRemove: () => void;
    onSubmit: (members: ISelectableMember[]) => void;
    title: string;
};
const SelectWhoModal = ({
    isEdit,
    members: parentMembers,
    onClose,
    onRemove,
    onSubmit: parentOnSubmit,
    title,
}: ISelectWhoModalProps) => {
    const clearMembers = useCallback(() => parentMembers.forEach((x) => (x.isSelected = false)), [
        parentMembers,
    ]);
    useEffect(() => clearMembers, [clearMembers]);
    const [members, setMembers] = useState(parentMembers);
    const onSelection = useCallback(
        (member, index) => {
            if (members[index]) {
                (members[index] as ISelectableMember).isSelected = !member.isSelected;
            }
            setMembers([...members]);
        },
        [members]
    );
    const onSubmit = useCallback(() => {
        parentOnSubmit(members);
    }, [members, parentOnSubmit]);
    const isDisabled = useMemo(() => isEmpty(members.filter((x) => x.isSelected)), [members]);
    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="select-who-modal" onSubmit={onSubmit}>
                    <MultiSelectButtons onSelection={onSelection} options={members} />
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button
                    data-cy="select-who-submit"
                    disabled={isDisabled}
                    form="select-who-modal"
                    type="submit"
                >
                    Save
                </Button>
                {isEdit && (
                    <React.Fragment>
                        <div className="w-100" />
                        <Button
                            ButtonClassName="text-danger"
                            data-cy="select-who-remove"
                            onClick={onRemove}
                            variant="text"
                        >
                            Remove
                        </Button>
                    </React.Fragment>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(SelectWhoModal);
