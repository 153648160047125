import {
    GLOBAL_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
    TEAM_REIMBURSEMENT_EXPENSE_PATH,
} from 'routers/routes';

const useReimbursementManagementPageContext = (
    locationPath: string,
    hasTeamIdUrlParam: boolean,
    hasUserIdUrlParam: boolean
) => {
    const isGlobal = locationPath === GLOBAL_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH;
    const isTeamPage =
        locationPath === TEAM_REIMBURSEMENT_EXPENSE_PATH ||
        (hasTeamIdUrlParam && !hasUserIdUrlParam);
    const isMemberPage = !isGlobal && !isTeamPage;

    let reimbursementPageContext: 'global' | 'team' | 'user' = 'user';
    if (isGlobal) {
        reimbursementPageContext = 'global';
    } else if (isTeamPage) {
        reimbursementPageContext = 'team';
    }

    return { isGlobal, isMemberPage, isTeamPage, reimbursementPageContext };
};

export default useReimbursementManagementPageContext;
