import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Tasks } from 'api/generated/enums';
import { IPersonalInfoDto, TaskDto } from 'api/generated/models';
import useUserProps from 'hooks/useUserProps';
import SummaryRowItem from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummaryRowItem';
import SummarySectionHeader from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummarySectionHeader';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatDateForDisplay, formatPhoneNumber } from 'utilities/format';
import { hasValue } from 'utilities/index';

const PersonalInfoSection = ({ tasks }: { tasks: TaskDto[] }) => {
    const { userId } = useUserProps();
    const { ssnTask } = useSelector((state: AppStore) => ({
        ssnTask: state.socialSecurityNumbers,
    }));
    const task = tasks.find((t) => t.taskId === Tasks.PersonalInfo);
    const taskId = task?.globalId as string;
    const chunk = task?.response as IPersonalInfoDto;
    const primarySsn = ssnTask.socialSecurityNumbers?.find((ssn) => ssn.entityKeyId === userId);

    return (
        <Grid container direction="column" item rowGap={1}>
            <SummarySectionHeader label="Personal Info" taskId={taskId} />
            <Divider sx={{ mb: 1, mt: -1 }} />
            <SummaryRowItem content={chunk.firstName} label="Legal First Name" />
            <SummaryRowItem content={chunk.lastName} label="Last Name" />
            {hasValue(chunk.preferredName) && (
                <SummaryRowItem content={chunk.preferredName} label="Preferred First Name" />
            )}
            <SummaryRowItem content={chunk.gender === 'M' ? 'Male' : 'Female'} label="Gender" />
            <SummaryRowItem content={formatDateForDisplay(chunk.birthdate)} label="Date of Birth" />
            <SummaryRowItem content={chunk.usesTobacco ? 'Yes' : 'No'} label="Tobacco Use" />
            <SummaryRowItem content={formatPhoneNumber(chunk.phone)} label="Phone Number" />
            <SummaryRowItem content={primarySsn?.socialSecurityNumber} label="SSN" useMask />
        </Grid>
    );
};

export default hot(module)(PersonalInfoSection);
