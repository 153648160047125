import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IAddressDto } from 'api/generated/models';
import { FONT_SEMI_BOLD } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import SummaryRowItem from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummaryRowItem';
import SummarySectionHeader from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummarySectionHeader';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities/index';

const AddressComponent = ({
    address,
    hideHeader,
    taskId,
}: {
    address: IAddressDto;
    hideHeader?: boolean;
    taskId?: string;
}) => (
    <Grid container direction="column" item rowGap={1}>
        {!hideHeader && (
            <React.Fragment>
                <SummarySectionHeader
                    hideEdit={!hasValue(taskId)}
                    label="Primary Address"
                    taskId={taskId}
                />
                <Divider sx={{ mb: 1, mt: -1 }} />
            </React.Fragment>
        )}
        <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">Address</Typography>
            <Stack textAlign="right">
                <Typography fontWeight={FONT_SEMI_BOLD} variant="body1">
                    {address.addressLine1}
                </Typography>
                {hasValue(address.addressLine2) && (
                    <Typography fontWeight={FONT_SEMI_BOLD} variant="body1">
                        {address.addressLine2}
                    </Typography>
                )}
                <Typography fontWeight={FONT_SEMI_BOLD} variant="body1">
                    {address.city}, {address.state} {address.zip}
                </Typography>
            </Stack>
        </Stack>
        <SummaryRowItem content={address.county} label="County" />
    </Grid>
);

export default hot(module)(AddressComponent);
