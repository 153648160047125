import { Card, CardContent } from '@mui/material';
import { copyTeamOperationsInfo } from 'actions/team/copyTeamOperationsInfo';
import {
    CREATE_OR_PATCH_TEAM_OPERATIONS_INFO_ACTION,
    createOrPatchOperationsInfo,
} from 'actions/team/createOrPatchTeamOperationsInfo';
import {
    GET_TEAM_OPERATIONS_INFO_ACTION,
    getTeamOperationsInfo,
} from 'actions/team/getTeamOperationsInfo';
import { IchraOfferLevels, MonthlyFeeTypes } from 'api/generated/enums';
import { ITeamOperationsInfo } from 'api/generated/models';
import Button from 'components/Button';
import CopyDataYearToYearModal from 'components/CopyDataYearToYearModal';
import EditableCurrencyAttribute, {
    IEditableCurrencyAttributeProps,
} from 'components/EditableCurrencyAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import PageHeader from 'components/PageHeader';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextAreaModal from 'components/TextAreaModal';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import React, { useCallback, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { enumToNameValueArray } from 'utilities';
import { onChange } from 'utilities/forms';
import { getYears } from 'utilities/year';
import { string } from 'yup';

const MONTHLY_FEE_TYPE_ITEMS = enumToNameValueArray(MonthlyFeeTypes, {
    formatName: startCase,
    nameKey: 'text',
});
const ICHRA_OFFER_LEVEL_ITEMS = enumToNameValueArray(IchraOfferLevels, {
    formatName: (x: string) => startCase(x).replace(/\s/g, ' + '),
    nameKey: 'text',
});

const MAX_YEAR_MODIFIER = 4;
const YEAR_ITEMS = getYears(() => MAX_YEAR_MODIFIER).map((x) => ({ name: `${x}` }));
const MAX_NOTE_LENGTH = 4000;

type INoteAttribute = {
    name: keyof ITeamOperationsInfo;
    note: string | undefined;
    title: string;
};

type ITeamOperationsInfoSectionProps = { teamId: string };

const TeamOperationsInfoSection = ({ teamId }: ITeamOperationsInfoSectionProps) => {
    const dispatch = useThunkDispatch();
    const { activeDate } = useTeamProps();
    const { isLoading, isLoadingSave, operationsInfo } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_TEAM_OPERATIONS_INFO_ACTION),
        isLoadingSave: hasApiActivity(state, CREATE_OR_PATCH_TEAM_OPERATIONS_INFO_ACTION),
        operationsInfo: state.teamOperationsInfo,
    }));
    const [year, setYear] = useState(
        activeDate?.getYear().toString() ?? new Date().getUTCFullYear().toString()
    );
    const [monthlyServiceFeeTypeId, setMonthlyServiceFeeTypeId] = useState<MonthlyFeeTypes>();
    const [employeePerMonthRate, setEmployeePerMonthRate] = useState<number>();
    const [monthlyServiceFee, setMonthlyServiceFee] = useState<number>();
    const [annualServiceFee, setAnnualServiceFee] = useState<number>();
    const [hraAdminFee, setHraAdminFee] = useState<number>();
    const [estimatedWithholding, setEstimatedWithholding] = useState<number>();
    const [pathwayNote, setPathwayNote] = useState<string>();
    const [spouseParentContributionNote, setSpouseParentContributionNote] = useState<string>();
    const [medicaidChipContributionNote, setMedicaidChipContributionNote] = useState<string>();
    const [medicareContributionNote, setMedicareContributionNote] = useState<string>();
    const [hsaHraContributionNote, setHsaHraContributionNote] = useState<string>();
    const [payrollDeduct, setPayrollDeduct] = useState<string>();
    const [rhCares, setRhCares] = useState<string>();
    const [isIchra, setIsIchra] = useState<string>();
    const [ichraOfferLevelId, setIchraOfferLevelId] = useState<IchraOfferLevels>();
    const [isAle, setIsAle] = useState<string>();
    const {
        closeModal: closeCopyModal,
        isVisible: isCopyModalVisible,
        openModal: openCopyModal,
    } = useModalState();
    const {
        closeModal: closeNoteModal,
        isVisible: isNoteModalVisible,
        openModalWithData: openNoteModalWithData,
        selectedItem: selectedNote,
    } = useModalState<INoteAttribute>();

    useEffect(() => {
        setMonthlyServiceFeeTypeId(operationsInfo?.monthlyServiceFeeTypeId);
        setEmployeePerMonthRate(operationsInfo?.employeePerMonthRate);
        setMonthlyServiceFee(operationsInfo?.monthlyServiceFee);
        setAnnualServiceFee(operationsInfo?.annualServiceFee);
        setHraAdminFee(operationsInfo?.hraAdminFee);
        setEstimatedWithholding(operationsInfo?.estimatedWithholding);
        setPayrollDeduct(operationsInfo?.payrollDeduct?.toString() || '');
        setRhCares(operationsInfo?.rhCares?.toString() || '');
        setPathwayNote(operationsInfo?.pathwayNote || '');
        setSpouseParentContributionNote(operationsInfo?.spouseParentContributionNote || '');
        setMedicaidChipContributionNote(operationsInfo?.medicaidChipContributionNote || '');
        setMedicareContributionNote(operationsInfo?.medicareContributionNote || '');
        setHsaHraContributionNote(operationsInfo?.hsaHraContributionNote || '');
        setIsIchra(operationsInfo?.isIchra?.toString() || '');
        setIchraOfferLevelId(operationsInfo?.ichraOfferLevelId);
        setIsAle(operationsInfo?.isAle?.toString() || '');
    }, [operationsInfo]);

    useEffect(() => {
        dispatch(getTeamOperationsInfo(teamId, +year));
    }, [dispatch, teamId, year]);

    const save = useCallback(
        async (name, value) => {
            const newOperationsInfo = { ...operationsInfo, [name]: value };
            await dispatch(createOrPatchOperationsInfo(teamId, +year, newOperationsInfo));
        },
        [dispatch, operationsInfo, teamId, year]
    );
    const onSaveNote = (name: string | undefined) => async (value: string | undefined) => {
        await save(name, value);
        closeNoteModal();
    };
    const onYesClick = useCallback(
        async (sourceYear, targetYear) => {
            await dispatch(copyTeamOperationsInfo(teamId, sourceYear, targetYear));
            setYear(targetYear.toString());
        },
        [dispatch, teamId]
    );

    type ICommonCurrencyProps = 'isOptional' | 'label' | 'name' | 'save' | 'validationSchema';
    const getCommonCurrencyProps = useCallback(
        (
            name: keyof ITeamOperationsInfo,
            label: string
        ): Pick<IEditableCurrencyAttributeProps<ITeamOperationsInfo>, ICommonCurrencyProps> => ({
            label,
            name,
            save,
            isOptional: true,
            validationSchema: string().label(label),
        }),
        [save]
    );

    return (
        <Card>
            {isCopyModalVisible && (
                <CopyDataYearToYearModal
                    allowCopyBackwards
                    modalText="Operations Info"
                    onClose={closeCopyModal}
                    onYesClick={onYesClick}
                    yearItems={YEAR_ITEMS}
                />
            )}
            {isNoteModalVisible && (
                <TextAreaModal
                    label={selectedNote?.title as string}
                    maxCharacters={MAX_NOTE_LENGTH}
                    onHide={closeNoteModal}
                    placeholder={`Enter ${selectedNote?.title}`}
                    save={onSaveNote(selectedNote?.name)}
                    showActivity={isLoadingSave}
                    title={selectedNote?.title as string}
                    value={selectedNote?.note}
                />
            )}
            <CardContent>
                <Row className="m-0 p-0 align-items-center flex-column flex-sm-row justify-content-sm-end">
                    <Col>
                        <PageHeader variant="h4">Yearly Operations Info</PageHeader>
                    </Col>
                    <div className="mt-sm-0 mr-1 mt-1">
                        <Button onClick={openCopyModal} variant="outlined">
                            Copy
                        </Button>
                    </div>
                    <div className="mt-sm-0 mt-1">
                        <Select
                            items={YEAR_ITEMS}
                            name="selectedYear"
                            onChange={onChange(setYear)}
                            optionText="name"
                            optionValue="name"
                            value={year}
                        />
                    </div>
                </Row>
                <hr />
                <Skeleton count={6} isEnabled={isLoading} sx={{ mb: 2 }}>
                    <Row>
                        <Col md="12">
                            <EditableSelectAttribute
                                defaultText="Select a Monthly Subscription Fee Type"
                                formatter={(x) => startCase(MonthlyFeeTypes[x as number])}
                                isOptional
                                items={MONTHLY_FEE_TYPE_ITEMS}
                                label="Monthly Subscription Fee Type"
                                name="monthlyServiceFeeTypeId"
                                onChange={onChange(setMonthlyServiceFeeTypeId)}
                                optionText="text"
                                optionValue="value"
                                save={save}
                                validationSchema={string().label('Monthly Subscription Fee Type')}
                                value={monthlyServiceFeeTypeId}
                            />
                            <EditableCurrencyAttribute
                                {...getCommonCurrencyProps('employeePerMonthRate', 'PEPM Rate')}
                                onChange={onChange(setEmployeePerMonthRate)}
                                value={employeePerMonthRate}
                            />
                            <EditableCurrencyAttribute
                                {...getCommonCurrencyProps(
                                    'monthlyServiceFee',
                                    'Monthly Subscription Fee'
                                )}
                                onChange={onChange(setMonthlyServiceFee)}
                                value={monthlyServiceFee}
                            />
                            <EditableCurrencyAttribute
                                {...getCommonCurrencyProps(
                                    'annualServiceFee',
                                    'Yearly Subscription Fee'
                                )}
                                onChange={onChange(setAnnualServiceFee)}
                                value={annualServiceFee}
                            />
                            <EditableCurrencyAttribute
                                {...getCommonCurrencyProps('hraAdminFee', 'HRA Admin Fee')}
                                onChange={onChange(setHraAdminFee)}
                                value={hraAdminFee}
                            />
                            <EditableCurrencyAttribute
                                {...getCommonCurrencyProps(
                                    'estimatedWithholding',
                                    'Estimated Withholding'
                                )}
                                onChange={onChange(setEstimatedWithholding)}
                                value={estimatedWithholding}
                            />
                            <EditableYesNoSelectAttribute
                                isOptional
                                label="Payroll Deduct"
                                name="payrollDeduct"
                                onChange={onChange(setPayrollDeduct)}
                                save={save}
                                value={payrollDeduct}
                            />
                            <EditableYesNoSelectAttribute
                                isOptional
                                label="RH Cares"
                                name="rhCares"
                                onChange={onChange(setRhCares)}
                                save={save}
                                value={rhCares}
                            />
                            <EditableYesNoSelectAttribute
                                isOptional
                                label="ICHRA"
                                name="isIchra"
                                onChange={onChange(setIsIchra)}
                                save={save}
                                value={isIchra}
                            />
                            <EditableSelectAttribute
                                defaultText="Select an ICHRA Offer Level"
                                formatter={(x) =>
                                    startCase(IchraOfferLevels[x as number]).replace(/\s/g, ' + ')
                                }
                                infoTooltip="This indicates who in an employee's household would be able to participate in the ICHRA. This field informs the recommended 1095-C coding the platform shows."
                                isOptional
                                items={ICHRA_OFFER_LEVEL_ITEMS}
                                label="ICHRA Offer Level"
                                name="ichraOfferLevelId"
                                onChange={onChange(setIchraOfferLevelId)}
                                optionText="text"
                                optionValue="value"
                                save={save}
                                validationSchema={string().label('ICHRA Offer Level')}
                                value={ichraOfferLevelId}
                            />
                            <EditableYesNoSelectAttribute
                                infoTooltip="Whether the Team is an Applicable Large Employer (50 Full-Time Equivalent employees or more)"
                                isOptional
                                label="ALE"
                                name="isAle"
                                onChange={onChange(setIsAle)}
                                save={save}
                                value={isAle}
                            />
                            <EditableProfileAttribute
                                content={pathwayNote}
                                label="Pathway Notes"
                                onClick={openNoteModalWithData({
                                    name: 'pathwayNote',
                                    note: pathwayNote,
                                    title: 'Pathway Notes',
                                })}
                                preserveNewLines
                            />
                            <EditableProfileAttribute
                                content={spouseParentContributionNote}
                                label="Spouse/Parent Contribution Notes"
                                onClick={openNoteModalWithData({
                                    name: 'spouseParentContributionNote',
                                    note: spouseParentContributionNote,
                                    title: 'Spouse/Parent Contribution Notes',
                                })}
                                preserveNewLines
                            />
                            <EditableProfileAttribute
                                content={medicaidChipContributionNote}
                                label="Medicaid/CHIP Contribution Notes"
                                onClick={openNoteModalWithData({
                                    name: 'medicaidChipContributionNote',
                                    note: medicaidChipContributionNote,
                                    title: 'Medicaid/CHIP Contribution Notes',
                                })}
                                preserveNewLines
                            />
                            <EditableProfileAttribute
                                content={medicareContributionNote}
                                label="Medicare Contribution Notes"
                                onClick={openNoteModalWithData({
                                    name: 'medicareContributionNote',
                                    note: medicareContributionNote,
                                    title: 'Medicare Contribution Notes',
                                })}
                                preserveNewLines
                            />
                            <EditableProfileAttribute
                                content={hsaHraContributionNote}
                                label="HSA/HRA Contribution Notes"
                                onClick={openNoteModalWithData({
                                    name: 'hsaHraContributionNote',
                                    note: hsaHraContributionNote,
                                    title: 'HSA/HRA Contribution Notes',
                                })}
                                preserveNewLines
                            />
                        </Col>
                    </Row>
                </Skeleton>
            </CardContent>
        </Card>
    );
};

export default hot(module)(TeamOperationsInfoSection);
