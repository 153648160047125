import { useTheme } from '@mui/material';
import { IPathwayBlueprint } from 'api/generated/models';
import React from 'react';
import { hot } from 'react-hot-loader';
import {
    Bar,
    Cell as ChartCell,
    LabelList,
    BarChart as RBarChart,
    ResponsiveContainer,
    XAxis,
} from 'recharts';
import { formatCurrency } from 'utilities/format';
import { hasValue } from 'utilities/index';

type IData = {
    employeeCost: number;
    employerCost: number;
    name: string;
    totalCost: number;
};

type ICostBarChartProps = {
    pathwayBlueprint: IPathwayBlueprint;
};

const CostBarChart = ({ pathwayBlueprint }: ICostBarChartProps) => {
    const theme = useTheme();
    let data: IData[] = [
        {
            employeeCost: pathwayBlueprint.annualOldEmployeeCost,
            employerCost: pathwayBlueprint.annualOldEmployerCost,
            name: 'Current Group Plan',
            totalCost: pathwayBlueprint.annualOldCost,
        },
        {
            employeeCost: pathwayBlueprint.annualOldRenewalEmployeeCost,
            employerCost: pathwayBlueprint.annualOldRenewalEmployerCost,
            name: 'Renewal Group Plan',
            totalCost: pathwayBlueprint.annualOldRenewalCost,
        },
        {
            employeeCost: pathwayBlueprint.annualEmployeeCost,
            employerCost: pathwayBlueprint.annualEmployerCost,
            name: 'Remodel Health',
            totalCost: pathwayBlueprint.annualCost,
        },
    ];

    data = data.filter((x) => hasValue(x?.totalCost) && x?.totalCost !== 0);

    const annualOldCost = pathwayBlueprint?.annualOldCost;
    const showSavings = hasValue(annualOldCost) && annualOldCost !== 0;

    const annualOldRenewalCost = pathwayBlueprint?.annualOldRenewalCost;
    const showRenewalSavings = hasValue(annualOldRenewalCost) && annualOldRenewalCost !== 0;

    return (
        <ResponsiveContainer height={298}>
            <RBarChart data={data} margin={{ top: 20 }} reverseStackOrder>
                <XAxis dataKey="name" />
                <Bar dataKey="employerCost" isAnimationActive={false} stackId="a">
                    {showSavings && <ChartCell fill={theme.palette.dark.main} />}
                    {showRenewalSavings && <ChartCell fill={theme.palette.primary.main} />}
                    <ChartCell fill={theme.palette.secondary.main} />
                    <LabelList
                        // ! The following is a hack to appease typescript because of issue here: https://github.com/recharts/recharts/issues/2472
                        {...{ fill: '#ffffff' }}
                        dataKey="employerCost"
                        formatter={(x) => `ER ${formatCurrency(x)}`}
                        position="inside"
                    />
                    <LabelList
                        {...{ fill: '#666666' }}
                        dataKey="totalCost"
                        formatter={formatCurrency}
                        position="top"
                    />
                </Bar>
                <Bar dataKey="employeeCost" fillOpacity={0.7} isAnimationActive={false} stackId="a">
                    {showSavings && <ChartCell fill={theme.palette.dark.main} />}
                    {showRenewalSavings && <ChartCell fill={theme.palette.primary.main} />}
                    <ChartCell fill={theme.palette.secondary.main} />
                    <LabelList
                        {...{ fill: '#ffffff' }}
                        dataKey="employeeCost"
                        formatter={(x) => `EE ${formatCurrency(x)}`}
                        position="inside"
                    />
                </Bar>
            </RBarChart>
        </ResponsiveContainer>
    );
};

export default hot(module)(CostBarChart);
