import IncomeForm from 'pages/survey/incomeSteps/IncomeForm';
import CalculateDeductionsInformationModal from 'pages/survey/informationModals/CalculateDeductionsInformationModal';
import { IStepProps } from 'pages/survey/StepWrapper';
import React from 'react';
import { hot } from 'react-hot-loader';

const SpouseIncomeForm = ({ children, commonProps }: IStepProps) => (
    <IncomeForm
        commonProps={commonProps}
        data-cy="household-deductions"
        incomeProp="deductions"
        infoText="What can I deduct?"
        label="Total Deductions"
        ModalToShow={CalculateDeductionsInformationModal}
        title="Do you have deductions such as a housing allowance?"
    >
        {children}
    </IncomeForm>
);

export default hot(module)(SpouseIncomeForm);
