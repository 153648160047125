import {
    DELETE_PATHWAY_BLUEPRINT_ACTION,
    deletePathwayBlueprint,
} from 'actions/pathwayBlueprint/deletePathwayBlueprint';
import {
    SET_ACTIVE_PATHWAY_BLUEPRINT,
    setActivePathwayBlueprint,
} from 'actions/pathwayBlueprint/setActivePathwayBlueprint';
import { IPathwayBlueprint } from 'api/generated/models';
import {
    CreatePathwayBlueprints,
    DeletePathwayBlueprints,
    EditPathwayBlueprints,
} from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import ApplyingStaleContributionsWarning from 'components/ApplyingStaleContributionsWarning';
import ConfirmationModal from 'components/ConfirmationModal';
import TypedConfirmationModal from 'components/TypedConfirmationModal';
import { teamStateIds } from 'constants/teamStateIds';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { kebabCase } from 'lodash';
import PathwayBlueprintModal from 'pages/pathwayBlueprints/PathwayBlueprintModal';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivityWithParams } from 'selectors/activity';

const PathwayBlueprintRecordActionButtons = ({ row: { original } }: Cell<IPathwayBlueprint>) => {
    const dispatch = useThunkDispatch();
    const {
        activePathwayBlueprint,
        canCreatePathwayBlueprint,
        canDeletePathwayBlueprints,
        canEditPathwayBlueprints,
        isLoadingDelete,
        isLoadingSetActive,
        latestYearForRates,
        teamInProspectState,
    } = useSelector((state: AppStore) => ({
        activePathwayBlueprint: state.pathwayBlueprints.find(
            (pathwayBlueprint) =>
                pathwayBlueprint.isActive && pathwayBlueprint.year === original.year
        ),
        canCreatePathwayBlueprint: hasSomePermissions(state, CreatePathwayBlueprints),
        canDeletePathwayBlueprints: hasSomePermissions(state, DeletePathwayBlueprints),
        canEditPathwayBlueprints: hasSomePermissions(state, EditPathwayBlueprints),
        isLoadingDelete: state.apiActivity[DELETE_PATHWAY_BLUEPRINT_ACTION],
        isLoadingSetActive: hasApiActivityWithParams(state, SET_ACTIVE_PATHWAY_BLUEPRINT, {
            pathwayBlueprintId: original.id,
        }),
        latestYearForRates: state.marketplaceConfig.latestYearForRates,
        teamInProspectState: state.teamProfile?.team?.teamStateId === teamStateIds.Prospect,
    }));

    const [isDuplicate, setIsDuplicate] = useState(false);
    const [isUpdateLiveRates, setIsUpdateLiveRates] = useState(false);

    const {
        closeModal: closeModal,
        isVisible: isEditModalVisible,
        openModal: openEditModal,
    } = useModalState();
    const {
        closeModal: closeSetActiveModal,
        isVisible: isSetActiveModalVisible,
        openModal: openSetActiveModal,
    } = useModalState();
    const {
        closeModal: closeConfirmSetActiveModal,
        isVisible: isConfirmSetActiveModalVisible,
        openModal: openConfirmSetActiveModal,
    } = useModalState();

    const setPathwayBlueprintToActive = useCallback(async () => {
        await dispatch(setActivePathwayBlueprint(original.id));
        closeSetActiveModal();
        closeConfirmSetActiveModal();
    }, [closeSetActiveModal, closeConfirmSetActiveModal, dispatch, original.id]);
    const handleSetActiveOnClick = useCallback(async () => {
        if (!teamInProspectState) {
            openConfirmSetActiveModal();
        } else if (activePathwayBlueprint) {
            openSetActiveModal();
        } else {
            setPathwayBlueprintToActive();
        }
    }, [
        activePathwayBlueprint,
        teamInProspectState,
        openSetActiveModal,
        openConfirmSetActiveModal,
        setPathwayBlueprintToActive,
    ]);

    const deleteBlueprint = useCallback(async () => dispatch(deletePathwayBlueprint(original.id)), [
        dispatch,
        original.id,
    ]);

    const closeEditModal = useCallback(() => {
        setIsDuplicate(false);
        setIsUpdateLiveRates(false);
        closeModal();
    }, [closeModal]);

    const duplicateOnClick = useCallback(() => {
        setIsDuplicate(true);
        openEditModal();
    }, [openEditModal]);

    const updateToLiveRates = useCallback(() => {
        setIsUpdateLiveRates(true);
        openEditModal();
    }, [openEditModal]);

    const items = useMemo(
        () =>
            [
                {
                    dataCy: 'edit-pathway-blueprint',
                    isVisible: canEditPathwayBlueprints,
                    onClick: openEditModal,
                    text: 'Edit Blueprint',
                },
                {
                    dataCy: 'duplicate-pathway-blueprint',
                    isVisible: canCreatePathwayBlueprint,
                    onClick: duplicateOnClick,
                    text: 'Duplicate',
                },
                {
                    dataCy: 'set-active-pathway-blueprint',
                    isLoading: isLoadingSetActive,
                    isVisible: canEditPathwayBlueprints && !original.isActive,
                    onClick: handleSetActiveOnClick,
                    text: 'Set Active',
                },
                {
                    dataCy: 'delete-pathway-blueprint',
                    isConfirm: true,
                    isLoading: isLoadingDelete,
                    isVisible: canDeletePathwayBlueprints && !original.isActive,
                    onClick: deleteBlueprint,
                    text: 'Delete',
                },
                {
                    dataCy: 'use-live-rates-pathway-blueprint',
                    isVisible: original.isProjection && original.year === latestYearForRates,
                    onClick: updateToLiveRates,
                    text: 'Update to Live Rates',
                },
            ] as IActionButtonItems,
        [
            canCreatePathwayBlueprint,
            canDeletePathwayBlueprints,
            canEditPathwayBlueprints,
            deleteBlueprint,
            duplicateOnClick,
            handleSetActiveOnClick,
            isLoadingDelete,
            isLoadingSetActive,
            latestYearForRates,
            openEditModal,
            original.isActive,
            original.isProjection,
            original.year,
            updateToLiveRates,
        ]
    );
    const modalText = (
        <React.Fragment>
            {!teamInProspectState ? (
                <ApplyingStaleContributionsWarning pathwayBlueprint={original} />
            ) : null}
            {!teamInProspectState ? (
                <React.Fragment>
                    <span>
                        Setting this Pathway Blueprint to active will set all Projected Wage+
                        amounts and Reimbursements in this Pathway Blueprint to active.
                    </span>
                    <br />
                    <br />
                    <span>
                        If there are members in this Blueprint that show errors for the Projected
                        Wage+ or Reimbursement, or they are in the Unknown pathway designation,
                        their existing Active Wage+ or Reimbursement will be removed. Please
                        double-check that your Blueprint is in good standing.
                    </span>
                    <br />
                    <br />
                </React.Fragment>
            ) : null}

            {activePathwayBlueprint ? (
                <React.Fragment>
                    Setting this Pathway Blueprint to active will set the currently active Pathway
                    Blueprint <b>{activePathwayBlueprint.name}</b> to inactive.
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {isEditModalVisible && (
                <PathwayBlueprintModal
                    initialYear={original?.year}
                    isDuplicate={isDuplicate}
                    isUpdateLiveRates={isUpdateLiveRates}
                    onClose={closeEditModal}
                    pathwayBlueprintId={original?.id}
                />
            )}
            {isConfirmSetActiveModalVisible && (
                <TypedConfirmationModal
                    body={modalText}
                    confirmationTextFieldDataCy="confirm-set-active"
                    onNoClick={closeConfirmSetActiveModal}
                    onYesClick={setPathwayBlueprintToActive}
                    showActivity={isLoadingSetActive}
                    textToMatch="Update all Active Contributions"
                    title="Set Active Pathway Blueprint"
                    yesButtonText="Set Active"
                />
            )}
            {isSetActiveModalVisible && (
                <ConfirmationModal
                    body={modalText}
                    noButtonText="Cancel"
                    onNoClick={closeSetActiveModal}
                    onYesClick={setPathwayBlueprintToActive}
                    showActivity={isLoadingSetActive}
                    title="Set Active Pathway Blueprint"
                    yesButtonText="Set Active"
                />
            )}
            <ActionButtons
                ButtonProps={{ xsClassName: 'mb-2' }}
                dataCy={kebabCase(original.name)}
                disabled={original.isCalculating}
                items={items}
            />
        </React.Fragment>
    );
};

export default hot(module)(PathwayBlueprintRecordActionButtons);
