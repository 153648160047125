import { patchHouseholdMember } from 'actions/householdMember/patchHouseholdMember';
import { IHouseholdMemberDto } from 'api/generated/models';
import TextAreaModal from 'components/TextAreaModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';

type IOtherCoverageSourceInfoProps = {
    householdMember: IHouseholdMemberDto;
    readonly: boolean;
};

const OtherCoverageSourceInfo = ({
    householdMember: { firstName, householdMemberId, otherCoverageSource, year },
    readonly,
}: IOtherCoverageSourceInfoProps) => {
    const dispatch = useThunkDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalActivity, setIsModalActivity] = useState(false);
    const saveChanges = async (text: string | undefined) => {
        setIsModalActivity(true);
        await dispatch(
            patchHouseholdMember(householdMemberId, year, { otherCoverageSource: text })
        );
        setIsModalActivity(false);
        setIsModalVisible(false);
    };
    const hideModal = () => setIsModalVisible(false);
    const showModal = () => setIsModalVisible(true);
    return (
        <React.Fragment>
            {isModalVisible && (
                <TextAreaModal
                    label={`How does ${firstName} have coverage?`}
                    maxCharacters={500}
                    onHide={hideModal}
                    placeholder="Enter coverage source"
                    save={saveChanges}
                    showActivity={isModalActivity}
                    title="Household Member Coverage Source"
                    value={otherCoverageSource}
                />
            )}
            <EditableProfileAttribute
                content={otherCoverageSource}
                label="Coverage Source"
                onClick={showModal}
                readonly={readonly}
            />
        </React.Fragment>
    );
};

export default hot(module)(OtherCoverageSourceInfo);
