import { FamilyMemberTypes, MedicaidApplicationStatuses } from 'api/generated/enums';
import { IMedicaidDetails } from 'api/generated/models';
import EditableDateAttribute from 'components/EditableDateAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import PageHeader from 'components/PageHeader';
import startCase from 'lodash/startCase';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { enumToNameValueArray } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

const APPLICATION_STATUS_ITEMS = enumToNameValueArray(MedicaidApplicationStatuses, {
    formatName: startCase,
    nameKey: 'text',
});
const ELIGIBLE_FAMILY_MEMBER_ITEMS = enumToNameValueArray(FamilyMemberTypes, {
    formatName: startCase,
    nameKey: 'text',
});

type IDetailsSection = RouteComponentProps & {
    readonly: boolean;
    save: ISaveEditableTextField<IMedicaidDetails>;
};

const DetailsSection = ({ readonly, save }: IDetailsSection) => {
    const {
        medicaidDetails: {
            applicationStatus: initialApplicationStatus,
            applicationDate: initialApplicationDate,
            acceptDenyDate: initialAcceptDenyDate,
            lastTouchPointDate: initialLastTouchPointDate,
            eligibleFamilyMembers: initialEligibleFamilyMembers,
            isAuthFormSubmitted: initialIsAuthFormSubmitted,
        },
    } = useSelector((state: AppStore) => ({ medicaidDetails: state.medicaid }));
    const [applicationStatus, setApplicationStatus] = useState(initialApplicationStatus);
    const [applicationDate, setApplicationDate] = useState(
        formatDateForDisplay(initialApplicationDate)
    );
    const [acceptDenyDate, setAcceptDenyDate] = useState(
        formatDateForDisplay(initialAcceptDenyDate)
    );
    const [lastTouchPointDate, setLastTouchPointDate] = useState(
        formatDateForDisplay(initialLastTouchPointDate)
    );
    const [eligibleFamilyMembers, setEligibleFamilyMembers] = useState(
        initialEligibleFamilyMembers
    );
    const [isAuthFormSubmitted, setIsAuthFormSubmitted] = useState(
        initialIsAuthFormSubmitted?.toString() || ''
    );
    const commonProps = useMemo(
        () => ({
            readonly,
            save,
            isOptional: true,
        }),
        [readonly, save]
    );
    return (
        <React.Fragment>
            <PageHeader variant="h4">Medicaid & CHIP Details</PageHeader>
            <hr />
            <Row>
                <Col md="6">
                    <EditableDateAttribute
                        {...commonProps}
                        infoTooltip="Date the Medicaid application was completed"
                        label="Application Date"
                        name="applicationDate"
                        onChange={onChange(setApplicationDate)}
                        placeholder="Enter application date"
                        validationSchema={string()
                            .isValidDate(false)
                            .label('Application Date')}
                        value={applicationDate}
                    />
                    <EditableSelectAttribute
                        {...commonProps}
                        formatter={(x) => startCase(MedicaidApplicationStatuses[x as number])}
                        infoTooltip="The status fo the Medicaid application"
                        items={APPLICATION_STATUS_ITEMS}
                        label="Application Status"
                        name="applicationStatus"
                        onChange={onChange(setApplicationStatus)}
                        optionText="text"
                        optionValue="value"
                        validationSchema={string().label('Application Status')}
                        value={applicationStatus}
                    />
                    <EditableDateAttribute
                        {...commonProps}
                        infoTooltip="The date we hear back from the member on whether they were accepted or denied for Medicaid"
                        label="Acceptance/Denial Date"
                        name="acceptDenyDate"
                        onChange={onChange(setAcceptDenyDate)}
                        placeholder="Enter acceptance/denial date"
                        validationSchema={string()
                            .isValidDate(false)
                            .label('Acceptance/Denial Date')}
                        value={acceptDenyDate}
                    />
                </Col>
                <Col md="6">
                    <EditableDateAttribute
                        {...commonProps}
                        infoTooltip="The most recent touch point date"
                        label="Last Touch Point Date"
                        name="lastTouchPointDate"
                        onChange={onChange(setLastTouchPointDate)}
                        placeholder="Enter touch point date"
                        validationSchema={string()
                            .isValidDate(false)
                            .label('Last Touch Point Date')}
                        value={lastTouchPointDate}
                    />
                    <EditableSelectAttribute
                        {...commonProps}
                        formatter={(x) => startCase(FamilyMemberTypes[x as number])}
                        infoTooltip="Who in the family is eligible"
                        items={ELIGIBLE_FAMILY_MEMBER_ITEMS}
                        label="Eligible Family Members"
                        name="eligibleFamilyMembers"
                        onChange={onChange(setEligibleFamilyMembers)}
                        optionText="text"
                        optionValue="value"
                        validationSchema={string().label('Eligible Family Members')}
                        value={eligibleFamilyMembers}
                    />
                    <EditableYesNoSelectAttribute
                        {...commonProps}
                        infoTooltip="Has the member completed and submitted an Authorized Representative Form for Medicaid use"
                        label="Is Auth Rep Form Submitted"
                        name="isAuthFormSubmitted"
                        onChange={onChange(setIsAuthFormSubmitted)}
                        value={isAuthFormSubmitted}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default hot(module)(withRouter(DetailsSection));
