import { Dialog, DialogContent } from '@mui/material';
import { Flows } from 'api/generated/enums';
import { TaskDto } from 'api/generated/models';
import AgreeToMemberTermsPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/AgreeToMemberTermsPage';
import React from 'react';
import { hot } from 'react-hot-loader';

type IAgreeToTermsModalProps = {
    currentTask?: TaskDto;
    flowId: Flows;
    introText?: string;
    onClose: () => void;
};
const AgreeToTermsModal = ({
    currentTask,
    flowId,
    introText,
    onClose,
}: IAgreeToTermsModalProps) => (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open scroll="paper">
        <DialogContent sx={{ px: 12, py: 4 }}>
            <AgreeToMemberTermsPage
                currentTask={currentTask as TaskDto}
                flowId={flowId}
                introText={introText}
                isModal
                onClose={onClose}
            />
        </DialogContent>
    </Dialog>
);

export default hot(module)(AgreeToTermsModal);
