import { addUserNote } from 'actions/user/addUserNote';
import { deleteUserNote } from 'actions/user/deleteUserNote';
import { editUserNote } from 'actions/user/editUserNote';
import { getHousehold } from 'actions/user/getHousehold';
import { IMemberVerifiedInfo, INote } from 'api/generated/models';
import TextAreaModal from 'components/TextAreaModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isEmpty from 'lodash/isEmpty';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isTrue } from 'utilities';

const PreExistingConditionsInfo = ({
    hasHouseholdPreExistingConditions,
    isCurrent,
    memberVerifiedInfo,
    readonly,
    userId,
}: {
    hasHouseholdPreExistingConditions: string | undefined;
    isCurrent: boolean;
    memberVerifiedInfo: IMemberVerifiedInfo | undefined;
    readonly: boolean;
    userId: string | undefined;
}) => {
    const dispatch = useThunkDispatch();
    const { selectedYear } = useSelector((state: AppStore) => ({
        selectedYear: +state.profileState.selectedYear,
    }));
    const [
        isEditPreExistingConditionsModalVisible,
        setIsEditPreExistingConditionsModalVisible,
    ] = useState(false);
    const hideModal = useCallback(() => setIsEditPreExistingConditionsModalVisible(false), []);
    const showModal = useCallback(() => setIsEditPreExistingConditionsModalVisible(true), []);
    const [isModalActivity, setIsModalActivity] = useState(false);
    const [householdPreExistingConditions, setHouseholdPreExistingConditions] = useState<
        Partial<INote>
    >((memberVerifiedInfo?.householdPreExistingConditions as INote) ?? {});
    const savePreExistingConditions = useCallback(
        async (text) => {
            const existingNote: Partial<INote> = householdPreExistingConditions ?? {};

            if (text === existingNote.noteText) {
                hideModal();
                return;
            }
            setIsModalActivity(true);
            if (isEmpty(existingNote)) {
                await dispatch(addUserNote(userId, `preExistingConditions:${text}`));
            } else if (isEmpty(text)) {
                await dispatch(deleteUserNote(existingNote.noteId));
            } else {
                await dispatch(
                    editUserNote(userId, `preExistingConditions:${text}`, existingNote.noteId)
                );
            }
            setIsModalActivity(false);
            hideModal();
            setHouseholdPreExistingConditions({ noteText: text });
            dispatch(getHousehold(userId, isCurrent, selectedYear));
        },
        [dispatch, hideModal, householdPreExistingConditions, isCurrent, selectedYear, userId]
    );
    const content = useMemo(() => {
        let innerContent = readonly ? 'None Added' : undefined;
        if (!isEmpty(householdPreExistingConditions?.noteText)) {
            innerContent = householdPreExistingConditions.noteText;
        }
        return innerContent;
    }, [householdPreExistingConditions, readonly]);
    if (!isTrue(hasHouseholdPreExistingConditions)) {
        return <React.Fragment />;
    }
    return (
        <React.Fragment>
            {isEditPreExistingConditionsModalVisible && (
                <TextAreaModal
                    data-private
                    label="Please describe any pre-existing conditions."
                    onHide={hideModal}
                    placeholder="Enter pre-existing conditions"
                    save={savePreExistingConditions}
                    showActivity={isModalActivity}
                    title="Household Pre-Existing Conditions"
                    value={householdPreExistingConditions.noteText}
                />
            )}
            <EditableProfileAttribute
                content={content}
                data-private
                label="Pre-Existing Conditions"
                onClick={showModal}
                readonly={readonly}
            />
        </React.Fragment>
    );
};

export default hot(module)(PreExistingConditionsInfo);
