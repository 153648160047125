import { IPathwayUser } from 'api/generated/models';
import {
    CLOSE_CUSTOM_REIMBURSEMENT_MODAL,
    CLOSE_CUSTOM_WAGEUP_MODAL,
    OPEN_CUSTOM_REIMBURSEMENT_MODAL,
    OPEN_CUSTOM_WAGEUP_MODAL,
} from 'pages/pathwayBlueprint/pathwayCustomProjectedModalActions';
import { IPathwayRowData } from 'pages/pathwayBlueprint/PathwayTableCells';
import basicReducer from 'reducers/basicReducer';

export type IPathwayModalState = {
    isCustomReimbursementModalVisible: boolean;
    isCustomWageUpModalVisible: boolean;
    pathwayUser: IPathwayRowData | IPathwayUser;
};
const initialState: IPathwayModalState = {
    isCustomReimbursementModalVisible: false,
    isCustomWageUpModalVisible: false,
    pathwayUser: ({} as unknown) as IPathwayRowData | IPathwayUser,
};
export const pathwayCustomProjectedModalState = basicReducer(
    initialState,
    OPEN_CUSTOM_WAGEUP_MODAL,
    CLOSE_CUSTOM_WAGEUP_MODAL,
    OPEN_CUSTOM_REIMBURSEMENT_MODAL,
    CLOSE_CUSTOM_REIMBURSEMENT_MODAL
);
