import {
    HIDE_HOUSEHOLD_INCOME_MODAL,
    SET_SELECTED_YEAR,
    SHOW_HOUSEHOLD_INCOME_MODAL,
} from 'pages/profile/profileActions';
import basicReducer from 'reducers/basicReducer';

export const profileState = basicReducer(
    { isHouseholdIncomeModalVisible: false, selectedYear: '' },
    HIDE_HOUSEHOLD_INCOME_MODAL,
    SET_SELECTED_YEAR,
    SHOW_HOUSEHOLD_INCOME_MODAL
);
