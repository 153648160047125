import { Typography } from '@mui/material';
import PathwayTable from 'pages/pathwayBlueprint/PathwayTable';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { arrayHasValue, hasValue } from 'utilities/index';

const PathwayTables = () => {
    const { pathwayBlueprint } = useSelector((state: AppStore) => ({
        pathwayBlueprint: state.pathwayBlueprint,
    }));
    const hasIchraClass = arrayHasValue(pathwayBlueprint?.ichraClasses);
    const unassignedPathways = pathwayBlueprint?.pathways?.filter(
        (p) => !hasValue(p?.ichraClassId)
    );
    const hasUnassignedPathways = arrayHasValue(unassignedPathways);
    return (
        <React.Fragment>
            {hasUnassignedPathways && (
                <Typography sx={{ marginBottom: 0 }} variant="h3">
                    {hasIchraClass && 'Other '}Pathways
                </Typography>
            )}
            {hasUnassignedPathways &&
                unassignedPathways?.map((pathway) => (
                    <PathwayTable key={pathway?.id} pathway={pathway} />
                ))}
        </React.Fragment>
    );
};

export default hot(module)(PathwayTables);
