import WelcomeMemberPage from 'pages/welcome/WelcomeMemberPage';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { DASHBOARD_PATH } from 'routers/routes';
import { isAdvisorSelector, isTeamAdminSelector } from 'selectors/role';

const WelcomePage = () => {
    const { isAdvisor, isTeamAdmin, loaded } = useSelector((state: AppStore) => ({
        isAdvisor: isAdvisorSelector(state),
        isTeamAdmin: isTeamAdminSelector(state),
        loaded: state.current.loaded,
    }));
    if (!loaded) {
        return <React.Fragment />;
    } else {
        if (!isTeamAdmin && !isAdvisor) {
            return <WelcomeMemberPage />;
        } else {
            return <Redirect to={DASHBOARD_PATH} />;
        }
    }
};

export default hot(module)(WelcomePage);
