import useIdeonProviderAutocomplete from 'components/creatableAutocomplete/useIdeonProviderAutocomplete';
import { UPDATE_SCROLL_GRADIENT_INDICATOR } from 'components/ScrollGradientIndicator';
import useQuery from 'hooks/useQuery';
import useThunkDispatch from 'hooks/useThunkDispatch';
import ListForm from 'pages/survey/coverageSteps/ListForm';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveCoverageForm } from 'pages/survey/surveyActions';
import React, { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import EventEmitter from 'utilities/eventEmitter';

const FALLBACK_PROVIDER_SEARCH_ZIP = '46037';
const ProvidersForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const [query] = useQuery();
    const { options, selectedProviders, surveyYear, zipCode } = useSelector((state: AppStore) => ({
        options: state.surveyIdeonProviders,
        selectedProviders: { ...state.surveyState.coverage }.selectedProviders,
        surveyYear: state.surveyState.incomeYear,
        zipCode: hasValue(state.surveyState.contact.zip)
            ? state.surveyState.contact.zip
            : FALLBACK_PROVIDER_SEARCH_ZIP,
    }));
    const description = (
        <p>
            Include doctors, specialists, or hospitals that are important to your household so that
            we can help find a plan that meets your needs.
            <br />
            <br />
            Whether specific doctors or hospitals are covered changes often, so we cannot guarantee
            their coverage. As best practice, we recommend calling your doctor to see if they accept
            the plan.
        </p>
    );

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [selectedProviders]);

    const save = useCallback(
        (newSelectedProviders) => {
            dispatch(
                saveCoverageForm({
                    selectedProviders: newSelectedProviders,
                })
            );
        },
        [dispatch]
    );
    const token = query.get('t');
    const creatableAutocompleteProps = useIdeonProviderAutocomplete({
        options,
        token,
        zipCode,
        year: Number(surveyYear),
    });
    return (
        <ListForm
            commonProps={commonProps}
            CreatableAutocompleteProps={creatableAutocompleteProps}
            description={description}
            keyProperty="nationalProviderId"
            save={save}
            selectedData={selectedProviders}
            title={
                <span>
                    Add preferred <span className="text-primary">doctors or hospitals</span>
                </span>
            }
        >
            {children}
        </ListForm>
    );
};

export default hot(module)(ProvidersForm);
