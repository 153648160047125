import Box from '@mui/material/Box';
import { HouseholdMemberTypes, UserStatus } from 'api/generated/enums';
import {
    HouseholdMemberDto,
    IHouseholdMemberDto,
    ISurveyHouseholdMemberDto,
} from 'api/generated/models';
import BoldHeaderText from 'components/BoldHeaderText';
import { doesMeetDependentOtherCoverageAgeMinimum } from 'constants/otherCoverageEligibilities';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import AdditionalIncomeForm from 'pages/survey/incomeSteps/AdditionalIncomeForm';
import AnnualIncomeForm from 'pages/survey/incomeSteps/AnnualIncomeForm';
import DeductionsForm from 'pages/survey/incomeSteps/DeductionsForm';
import FilingStatusForm from 'pages/survey/incomeSteps/FilingStatusForm';
import IncomeConfirmationForm from 'pages/survey/incomeSteps/IncomeConfirmationForm';
import IncomeForm from 'pages/survey/incomeSteps/IncomeForm';
import IncomeIntro from 'pages/survey/incomeSteps/IncomeIntro';
import StepWrapper, { IStepProps } from 'pages/survey/StepWrapper';
import { saveHouseholdMembers } from 'pages/survey/surveyActions';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getAllMembersOfType, getDisplayFirstName } from 'utilities/household';

const INCOME_DESCRIPTION = (
    <React.Fragment>
        <Box mb={2}>
            This should be just their base salary, do not include expected commission or bonuses.
        </Box>
        <Box>
            <em>
                You may qualify for savings on your monthly premium based on your taxable household.
                Therefore, we need the income of everyone in your taxable household in order to find
                the plan that best fits your needs. We will not share this income with your
                employer.
            </em>
        </Box>
    </React.Fragment>
);

const getIncomeForms = (
    householdMembers: ISurveyHouseholdMemberDto[] | undefined,
    incomeYear: number | undefined,
    householdMemberType: HouseholdMemberTypes,
    isRenewal: boolean
) => {
    const isUnclaimed = householdMemberType === HouseholdMemberTypes.Unclaimed;
    return getAllMembersOfType(
        householdMembers,
        householdMemberType,
        isRenewal ? doesMeetDependentOtherCoverageAgeMinimum : undefined
        // eslint-disable-next-line react/display-name
    ).map((member, index) => ({ children, commonProps }: IStepProps) => {
        const displayName = getDisplayFirstName(member);
        const title = (
            <React.Fragment>
                {householdMemberType === HouseholdMemberTypes.Unclaimed ? (
                    <React.Fragment>
                        What is {displayName}&apos;s projected annual income before taxes for{' '}
                        {incomeYear}?
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        What is {displayName}&apos;s annual <BoldHeaderText>base</BoldHeaderText>{' '}
                        salary from their employer for {incomeYear}?
                    </React.Fragment>
                )}
            </React.Fragment>
        );
        return (
            <IncomeForm
                commonProps={commonProps}
                data-cy={`${HouseholdMemberTypes[householdMemberType]?.toLowerCase()}-income`}
                description={isUnclaimed ? null : INCOME_DESCRIPTION}
                hideInfoText={!isUnclaimed}
                incomeProp={member.householdMemberId}
                key={index}
                label={`${displayName}'s Income`}
                title={title}
            >
                {children}
            </IncomeForm>
        );
    });
};

const IncomeWrapper = () => {
    const dispatch = useThunkDispatch();
    const { user } = useUserProps();
    const { householdMembers, income, incomeYear } = useSelector((state: AppStore) => ({
        householdMembers: state.surveyState.household.members,
        income: state.surveyState.income,
        incomeYear: state.surveyState.incomeYear,
    }));

    const stepsToRender = useMemo(() => {
        const isRenewal = user?.status === UserStatus.Renewing;
        const householdMembersToUse = isRenewal
            ? householdMembers
            : householdMembers?.filter((x) => x.isEmployed);

        let forms = [FilingStatusForm, AnnualIncomeForm];
        forms = forms.concat(
            getIncomeForms(
                householdMembersToUse,
                incomeYear,
                HouseholdMemberTypes.Spouse,
                isRenewal
            )
        );
        forms = forms.concat(
            getIncomeForms(
                householdMembersToUse,
                incomeYear,
                HouseholdMemberTypes.Dependent,
                isRenewal
            )
        );
        forms.push(AdditionalIncomeForm);
        forms.push(DeductionsForm);
        forms.push(IncomeConfirmationForm);
        forms = forms.concat(
            getIncomeForms(householdMembers, incomeYear, HouseholdMemberTypes.Unclaimed, isRenewal)
        );
        return forms;
    }, [householdMembers, incomeYear, user?.status]);

    const onSubmit = useCallback(
        (step: number) => {
            if (step === stepsToRender.length) {
                const householdMembersWithIncome =
                    householdMembers?.map(
                        (member) =>
                            new HouseholdMemberDto(({
                                ...member,
                                income: Number(income[member.householdMemberId]),
                            } as unknown) as IHouseholdMemberDto)
                    ) ?? [];
                dispatch(saveHouseholdMembers(householdMembersWithIncome));
            }
        },
        [dispatch, householdMembers, income, stepsToRender.length]
    );

    return <StepWrapper Intro={IncomeIntro} onSubmit={onSubmit} stepsToRender={stepsToRender} />;
};

export default hot(module)(IncomeWrapper);
