import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PathwayInputTypes, PathwayTypes } from 'api/generated/enums';
import {
    BasicMarketplacePlanDto,
    IBasicMarketplacePlanDto,
    IchraClass,
    IPathwayMarketplacePlanMigration,
    Pathway,
} from 'api/generated/models';
import IconTooltip from 'components/IconTooltip';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { FEDERAL_EXCHANGE } from 'constants/selectedPlans';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import {
    addPathwayToPathwayBlueprintModalState,
    setPathwayBlueprintInput,
} from 'pages/pathwayBlueprints/pathwayBlueprintModalActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getEnumNames, hasContents, hasValue } from 'utilities';
import { getPlansByCarrier, getUniqueMarketplaceCarriers } from 'utilities/marketplacePlan';

export const PATHWAY_TYPE_NAMES = getEnumNames(PathwayTypes, (x) =>
    startCase(x.replace('Ichra', 'ICHRA'))
);

const CrosswalkIcon = ({
    crosswalkPlanName,
    planName,
    year,
    federalExchange,
}: {
    crosswalkPlanName: string | undefined;
    federalExchange: boolean;
    planName: string | undefined;
    year: number | undefined;
}) => {
    const crossWalkText = hasValue(crosswalkPlanName)
        ? `Crosswalk plan: ${crosswalkPlanName}.`
        : `${planName} does not have a crosswalk plan.`;
    return (
        <IconTooltip
            className="ml-1"
            icon="alert"
            prefix="mdi"
            title={
                <span>
                    {planName} is not available in {year}.
                    <br />
                    {federalExchange && crossWalkText}
                </span>
            }
            variant="danger"
        />
    );
};

const getPlanNameFromPlanId = (
    planId: string | undefined,
    plans: IBasicMarketplacePlanDto[] | undefined
) => (hasContents(plans) ? plans.find((x) => x.id === planId)?.name : '');

export const PathwayMarketplacePlanMigrationInputs = ({
    crosswalk,
    currentPlan,
    hasMultipleRecommendedPlans = false,
    ichraClass,
    index,
    nextYearPlans,
    pathway,
    pathwayRecommendedPlan,
}: IPathwayMarketplacePlanMigration & {
    hasMultipleRecommendedPlans?: boolean;
    index?: number;
}) => {
    const dispatch = useThunkDispatch();

    const { year, errors, pathwayBlueprint } = useSelector((state: AppStore) => ({
        errors: state.pathwayBlueprintModalState.errors,
        pathwayBlueprint: state.pathwayBlueprintModalState,
        year: state.pathwayBlueprintModalState.year,
    }));

    const [marketplaceCarrier, setMarketplaceCarrier] = useState(currentPlan?.issuer?.name);
    const [marketplacePlanId, setMarketplacePlanId] = useState(crosswalk?.newPlanId);

    const showPickNewPlanTooltip = crosswalk?.isDiscontinued || crosswalk?.reason !== '0';
    const isIchraClass = hasValue(ichraClass);
    const isRecommendedPlan = hasValue(pathwayRecommendedPlan);
    const isPathwayBenchmark = hasValue(pathway) && !isRecommendedPlan;

    const carriers = getUniqueMarketplaceCarriers(nextYearPlans);
    const plansByCarrier = useMemo(() => getPlansByCarrier(nextYearPlans, marketplaceCarrier), [
        marketplaceCarrier,
        nextYearPlans,
    ]);
    const crosswalkPlanName = useMemo(
        () => getPlanNameFromPlanId(crosswalk?.newPlanId, nextYearPlans),
        [crosswalk, nextYearPlans]
    );

    const updateIchraClasses = useCallback(
        (field: 'benchmarkCarrier' | 'benchmarkPlanId', value: string) =>
            pathwayBlueprint.ichraClasses?.map((ic) => {
                if (ic.id === ichraClass?.id) {
                    ic[field] = value;
                    return ic;
                }
                return ic;
            }),
        [ichraClass?.id, pathwayBlueprint.ichraClasses]
    );

    const updateRecommendedPlansInPathway = useCallback(
        (value: string, isCarrier: boolean) => {
            let carrierId = '';
            let plan: BasicMarketplacePlanDto | undefined;
            if (isCarrier) {
                carrierId = carriers.find((c) => c.name === value)?.id as string;
            } else {
                plan = plansByCarrier.find((pbc) => pbc.id === value) as BasicMarketplacePlanDto;
            }
            return pathwayBlueprint.pathways?.map((p) => {
                if (p.id === pathway?.id) {
                    p.pathwayRecommendedPlans?.map((prp) => {
                        if (prp.id === pathwayRecommendedPlan?.id) {
                            if (isCarrier) {
                                prp.carrierId = carrierId;
                                prp.planId = undefined;
                                prp.planName = undefined;
                                prp.planDetails = undefined;
                            } else {
                                prp.planId = value;
                                prp.planName = plan?.name;
                                prp.planDetails = plan;
                            }
                        }
                        return prp;
                    });
                }
                return p;
            });
        },
        [
            carriers,
            pathway?.id,
            pathwayBlueprint.pathways,
            pathwayRecommendedPlan?.id,
            plansByCarrier,
        ]
    );

    const setPathwayInput = useCallback(
        (pathwayInputType: PathwayInputTypes, value: string | undefined) => {
            const pathwayInput = isPathwayBenchmark
                ? pathwayBlueprint?.pathways
                      ?.find((p) => p.id === pathway?.id)
                      ?.pathwayInputs?.find((x) => x.pathwayInputTypeId === pathwayInputType)
                : undefined;
            if (hasValue(pathwayInput)) {
                pathwayInput.value = value;
            } else if (
                isIchraClass &&
                hasValue(pathwayBlueprint.ichraClasses?.find((ic) => ic.id === ichraClass.id))
            ) {
                let updatedIchraClasses: IchraClass[] | undefined = undefined;
                switch (pathwayInputType) {
                    case PathwayInputTypes.MarketplaceCarrier: {
                        updatedIchraClasses = updateIchraClasses(
                            'benchmarkCarrier',
                            value as string
                        );
                        break;
                    }
                    case PathwayInputTypes.MarketplacePlanId: {
                        updatedIchraClasses = updateIchraClasses(
                            'benchmarkPlanId',
                            value as string
                        );
                        break;
                    }
                    default:
                        break;
                }
                dispatch(
                    setPathwayBlueprintInput({
                        ...pathwayBlueprint,
                        ichraClasses: updatedIchraClasses,
                    })
                );
            } else if (
                isRecommendedPlan &&
                pathway?.pathwayRecommendedPlans?.some(
                    (prp) => prp.id === pathwayRecommendedPlan.id
                )
            ) {
                let updatedPathways: Pathway[] | undefined = undefined;
                switch (pathwayInputType) {
                    case PathwayInputTypes.MarketplaceCarrier: {
                        updatedPathways = updateRecommendedPlansInPathway(value as string, true);
                        break;
                    }
                    case PathwayInputTypes.MarketplacePlanId: {
                        updatedPathways = updateRecommendedPlansInPathway(value as string, false);
                        break;
                    }
                    default:
                        break;
                }

                dispatch(
                    setPathwayBlueprintInput({
                        ...pathwayBlueprint,
                        pathways: updatedPathways,
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            dispatch,
            ichraClass,
            pathway?.id,
            pathway?.pathwayRecommendedPlans,
            pathwayBlueprint,
            pathwayRecommendedPlan,
        ]
    );

    const handleCarrierData = (value: string, retainedPlanId?: string) => {
        setMarketplaceCarrier(value);
        setMarketplacePlanId(retainedPlanId ?? '');
        setPathwayInput(PathwayInputTypes.MarketplaceCarrier, value);
        setPathwayInput(PathwayInputTypes.MarketplacePlanId, retainedPlanId ?? '');
        if (!isIchraClass) {
            dispatch(setPathwayBlueprintInput(pathwayBlueprint));
        }
    };

    const handlePlanData = (value: string) => {
        setMarketplacePlanId(value);
        setPathwayInput(PathwayInputTypes.MarketplacePlanId, value);
        if (!isIchraClass) {
            dispatch(setPathwayBlueprintInput(pathwayBlueprint));
        }
    };

    const onCarrierChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        handleCarrierData(value);
    };
    const onPlanIdChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        handlePlanData(value);
    };

    const getName = () => {
        if (isIchraClass) {
            return <Typography variant="h4">{ichraClass?.name}</Typography>;
        } else if (hasMultipleRecommendedPlans) {
            const planType = isPathwayBenchmark
                ? 'Benchmark Plan'
                : `Recommended Plan ${pathwayRecommendedPlan?.order}`;
            const planLabel = isPathwayBenchmark
                ? pathway.pathwayRecommendedPlans?.[0]?.planLabel
                : pathwayRecommendedPlan?.planLabel ?? undefined;
            return (
                <Typography variant="h5">
                    {pathway?.name}
                    {' - '}
                    {planType}
                    {hasValue(planLabel) && ` - ${planLabel}`}
                </Typography>
            );
        } else {
            return (
                <Typography variant="h4">
                    {pathway?.name}
                    {' - '}
                    <span style={{ fontStyle: 'italic' }}>
                        {PATHWAY_TYPE_NAMES?.[pathway?.pathwayTypeId as number]}
                    </span>
                </Typography>
            );
        }
    };

    const getErrorName = () => {
        if (isIchraClass) {
            return 'ichraClassPlans';
        } else if (isRecommendedPlan) {
            return 'recommendedPlans';
        } else {
            return 'plans';
        }
    };

    const planMigrationLabel = (
        <Typography
            color={(theme) => (showPickNewPlanTooltip ? theme.palette.error.main : '')}
            variant="h5"
        >
            {getName()}
            {showPickNewPlanTooltip && (
                <CrosswalkIcon
                    crosswalkPlanName={crosswalkPlanName}
                    federalExchange={currentPlan?.exchange === FEDERAL_EXCHANGE}
                    planName={currentPlan?.name}
                    year={year}
                />
            )}
        </Typography>
    );

    const handleCarrierNameChange = useCallback(() => {
        let carrierName: string | undefined;
        if (
            hasValue(currentPlan?.issuer?.name) &&
            !nextYearPlans?.some((nyp) => nyp.issuer?.name === currentPlan?.issuer?.name)
        ) {
            if (nextYearPlans?.some((nyp) => nyp.id === crosswalk?.newPlanId)) {
                carrierName = nextYearPlans?.find((nyp) => nyp.id === crosswalk?.newPlanId)?.issuer
                    ?.name;
            }

            if (carriers.some((c) => c.name === carrierName)) {
                return carrierName;
            } else {
                return undefined;
            }
        }
        return carrierName;
    }, [carriers, crosswalk?.newPlanId, currentPlan?.issuer?.name, nextYearPlans]);

    useEffect(() => {
        const carrierName = handleCarrierNameChange();
        if (crosswalk?.newPlanId !== currentPlan?.id || hasValue(carrierName)) {
            if (pathwayBlueprint.pathways?.some((p) => p.id === pathway?.id) || isIchraClass) {
                if (hasValue(carrierName)) {
                    setPathwayInput(PathwayInputTypes.MarketplaceCarrier, carrierName);
                } else {
                    setPathwayInput(PathwayInputTypes.MarketplacePlanId, crosswalk?.newPlanId);
                }
                if (!isIchraClass) {
                    dispatch(setPathwayBlueprintInput(pathwayBlueprint));
                }
            } else {
                const pathwayInput = pathway?.pathwayInputs?.find(
                    (x) => x.pathwayInputTypeId === PathwayInputTypes.MarketplacePlanId
                );
                if (hasValue(pathwayInput)) {
                    pathwayInput.value = crosswalk?.newPlanId;
                }
                if (!isIchraClass) {
                    dispatch(addPathwayToPathwayBlueprintModalState(pathway));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crosswalk?.newPlanId, currentPlan?.id, dispatch, pathway]);

    useEffect(() => {
        if (
            hasValue(currentPlan?.issuer?.name) &&
            !nextYearPlans?.some((nyp) => nyp.issuer?.name === currentPlan?.issuer?.name)
        ) {
            const carrierName = handleCarrierNameChange();
            const planId = nextYearPlans?.find((nyp) => nyp.id === crosswalk?.newPlanId)?.id ?? '';

            handleCarrierData(carrierName ?? '');
            setMarketplaceCarrier(carrierName ?? '');
            handlePlanData(planId);
            setMarketplacePlanId(planId);
        }

        const sameCarrierByName = nextYearPlans?.find(
            (nyp) => nyp.issuer?.name === currentPlan?.issuer?.name
        );

        if (hasValue(sameCarrierByName)) {
            handleCarrierData(sameCarrierByName.issuer?.name as string, crosswalk?.newPlanId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container direction="column" gap={2} mt={1.5}>
            {planMigrationLabel}
            <Grid container direction="row" item spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label={`${(year as number) - 1} ${
                            isRecommendedPlan ? 'Recommended' : 'Benchmark'
                        } Carrier`}
                        select
                        value={currentPlan?.issuer?.name}
                    >
                        <option value={currentPlan?.issuer?.name}>
                            {currentPlan?.issuer?.name}
                        </option>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Select
                        defaultText="Choose a Carrier"
                        defaultValue=""
                        errors={
                            errors?.[
                                (`${getErrorName()}[${index}].marketplaceCarrier` as unknown) as keyof typeof errors
                            ]
                        }
                        items={carriers}
                        label={`${year} ${isRecommendedPlan ? 'Recommended' : 'Benchmark'} Carrier`}
                        name={`${getErrorName()}[${index}].marketplaceCarrier`}
                        onChange={onCarrierChange}
                        optionText="name"
                        optionValue="name"
                        value={marketplaceCarrier}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" item spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label={`${(year as number) - 1} ${
                            isRecommendedPlan ? 'Recommended' : 'Benchmark'
                        } Plan`}
                        select
                        value={currentPlan?.name}
                    >
                        <option value={currentPlan?.name}>{currentPlan?.name}</option>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Select
                        defaultText="Choose a Plan"
                        defaultValue=""
                        errors={
                            errors?.[
                                (`${getErrorName()}[${index}].planId` as unknown) as keyof typeof errors
                            ]
                        }
                        items={plansByCarrier}
                        label={`${year} ${isRecommendedPlan ? 'Recommended' : 'Benchmark'} Plan`}
                        name={`${getErrorName()}[${index}].planId`}
                        onChange={onPlanIdChange}
                        optionText="name"
                        optionValue="id"
                        value={marketplacePlanId}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default hot(module)(PathwayMarketplacePlanMigrationInputs);
