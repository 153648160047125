import { Card, CardContent } from '@mui/material';
import { GET_SELECTED_PLANS_ACTION, getSelectedPlans } from 'actions/selectedPlan/getSelectedPlans';
import Button from 'components/Button';
import Title from 'components/Title';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { SHOP_PATH } from 'routers/routes';

const ShopPlansCard = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: state.apiActivity[GET_SELECTED_PLANS_ACTION],
    }));

    useEffect(() => {
        dispatch(getSelectedPlans(userId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <React.Fragment />;
    }
    return (
        <Card>
            <CardContent>
                <Row className="align-items-center justify-content-space-between px-2">
                    <Col>
                        <Title>Shop Benefits</Title>
                        <p className="my-0 text-muted">
                            Find plans or programs that fit your needs.
                        </p>
                    </Col>
                    <Col md="auto">
                        <Link to={SHOP_PATH}>
                            <Button data-cy="shop-plans" size="small">
                                <i className="dripicons-cart mr-2" />
                                Shop
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};

export default hot(module)(ShopPlansCard);
