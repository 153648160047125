import { IContentColumn, ISummarySection } from 'api/generated/models';
import PageHeader from 'components/PageHeader';
import { default as React } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const MAX_BREAKPOINTS = 12;

const FormattedText = styled.p`
    margin: 0px;
    padding: 0px;
    white-space: pre-line;
`;

const getColumns = (columns: IContentColumn[] | undefined) => {
    const breakPoint = MAX_BREAKPOINTS / (columns?.length ?? 0);
    return (
        <React.Fragment>
            {columns?.map((column, index) => (
                <Col key={index} md={breakPoint}>
                    {column.sections?.map((section, sectionIndex) => (
                        <div className="mt-1" key={sectionIndex}>
                            {section.title && (
                                <Row>
                                    <label>{section.title}</label>
                                </Row>
                            )}
                            <Row className="mt-0">
                                <Col>
                                    {section.content?.map((content, contentIndex) => (
                                        <Row
                                            data-private={section.title === 'Address' ? true : null}
                                            key={contentIndex}
                                        >
                                            <FormattedText>{content}</FormattedText>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Col>
            ))}
        </React.Fragment>
    );
};

const CalculationSummarySection = ({ data }: { data: ISummarySection }) => (
    <Row className="mb-2 mx-0">
        <Col>
            <Row>
                <PageHeader variant="h5">{data.title}</PageHeader>
            </Row>
            <Row>{getColumns(data.columns)}</Row>
        </Col>
    </Row>
);

export default hot(module)(CalculationSummarySection);
