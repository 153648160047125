import { AppStoreThunkDispatch } from 'actions/commonAction';
import axios from 'axios';
import { AppStore } from 'reducers/appReducer';

export const SET_PAYROLL_REPORT_SELECTED_YEAR = 'SET_PAYROLL_REPORT_SELECTED_YEAR';
export const setPayrollReportSelectedYear = (payrollReportSelectedYear: string) => (
    dispatch: AppStoreThunkDispatch,
    getState: () => AppStore
) => {
    const { payrollReportState } = getState();
    payrollReportState?.cancelTokenSource?.cancel();
    dispatch({
        data: { payrollReportSelectedYear, cancelTokenSource: axios.CancelToken.source() },
        type: SET_PAYROLL_REPORT_SELECTED_YEAR,
    });
};
