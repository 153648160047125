import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SurveyTypes } from 'api/generated/enums';
import Button from 'components/Button';
import { Logo } from 'components/Logo';
import Skeleton from 'components/Skeleton';
import {
    CHARLESTON_SOUTHERN_UNIVERSITY_TEAM_ID,
    UNIVERSITY_OF_SAINT_FRANCIS_TEAM_ID,
} from 'constants/teams';
import useUserProps from 'hooks/useUserProps';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import ReactLoadingSkeleton from 'react-loading-skeleton';
import { hasValue } from 'utilities';
import { getDisplayFirstName } from 'utilities/household';

const LOGO_HEIGHT_IN_PIXELS = 100;
const UNIVERSITY_OF_SAINT_FRANCIS_INTRO_TEXT = (
    <React.Fragment>
        <Typography color={(theme) => theme.palette.primary.main} mb={3} variant="h2">
            Dear USF employees,
        </Typography>
        <p className="pb-4">
            As part of USF’s Strategic Plan many options are being considered to provide employees
            with a comprehensive and affordable benefit package. New offerings and ideas are being
            taken under consideration. As part of that effort, USF has partnered with Remodel Health
            to conduct a short, 10-minute, health assessment to determine employee needs with
            regards to medical coverage. The assessment will be taken through a secure website and
            will ask personal information including household income - this information is necessary
            in order to determine if the medical offerings we are looking at will be a cost-savings
            to USF employees. This data will be stored in a HIPPA compliant manner. While the
            overall outcome of the assessment will be shared with USF, the individual responses will
            be kept confidential and not shared.
        </p>
    </React.Fragment>
);
const BenefitsSurveyIntro = ({ onClick }: { onClick: () => void }) => {
    const { hasUser, user } = useUserProps();
    const teamId = user?.teamId;
    const logoHeight = [
        CHARLESTON_SOUTHERN_UNIVERSITY_TEAM_ID,
        UNIVERSITY_OF_SAINT_FRANCIS_TEAM_ID,
    ].contains(teamId)
        ? LOGO_HEIGHT_IN_PIXELS
        : undefined;
    const firstNameContent = hasUser ? (
        getDisplayFirstName(user)
    ) : (
        <ReactLoadingSkeleton height="33px" width="100px" />
    );
    return (
        <Container>
            <Row className="align-items-center justify-content-center">
                <Col>
                    <Row className="justify-content-center mt-5 mb-1">
                        <Skeleton
                            count={1}
                            height="50px"
                            isEnabled={!hasValue(teamId)}
                            width="50px"
                        >
                            <Logo height={logoHeight} teamId={teamId} />
                        </Skeleton>
                    </Row>
                    <Row className="justify-content-center mb-4">
                        <Typography color={(theme) => theme.palette.secondary.main} variant="h3">
                            Health Benefits Analysis
                        </Typography>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="8" xs="12">
                            <Card className="w-100 pb-2 pt-4 px-sm-5 px-lg-7">
                                <CardContent>
                                    {teamId === UNIVERSITY_OF_SAINT_FRANCIS_TEAM_ID ? (
                                        UNIVERSITY_OF_SAINT_FRANCIS_INTRO_TEXT
                                    ) : (
                                        <React.Fragment>
                                            <Typography
                                                color={(theme) => theme.palette.primary.main}
                                                mb={3}
                                                variant="h2"
                                            >
                                                Hello, {firstNameContent}.
                                            </Typography>
                                            <div>
                                                Remodel Health partners with organizations across
                                                the United States to assess the personal healthcare
                                                needs of each employee.
                                            </div>
                                            <hr className="my-3" />
                                            <div>
                                                We&apos;ll ask you some questions about you, your
                                                household,{' '}
                                                {user?.surveyTypeToSend === SurveyTypes.Standard &&
                                                    'income, '}
                                                and coverage preferences to help determine the best
                                                plan or program for your family.
                                            </div>
                                            <div className="my-4">
                                                <strong>
                                                    Accuracy is important when answering these
                                                    questions.
                                                </strong>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div className="text-center">
                                        <Row className="justify-content-center">
                                            <Col md="8">
                                                <Button autoFocus onClick={onClick} type="submit">
                                                    Get Started
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default hot(module)(BenefitsSurveyIntro);
