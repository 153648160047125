import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const MedicareCoverageStatusExamples = () => (
    <React.Fragment>
        <p>
            Submit this document if you need to confirm that you don’t have coverage through
            Medicare:
        </p>
        <ul>
            <li>
                Letter or statement from Medicare or the Social Security Administration stating that
                you or your family members are:
                <ul>
                    <li>Not eligible for or enrolled in premium-free Medicare Part A.</li>
                    <li>
                        Eligible for (but not enrolled in) Part A coverage that requires premium
                        payments. <strong>Important</strong>: A Social Security document that shows
                        you don’t pay a premium for “Medical Insurance” refers to Part B. It’s not
                        acceptable for verifying eligibility for Part A.
                    </li>
                    <li>
                        No longer eligible for Social Security Disability Insurance (SSDI) benefits,
                        and your coverage has ended or will end in the next 90 days.
                    </li>
                </ul>
            </li>
        </ul>
        <p>
            <strong>If you don’t have this document</strong>, you can submit a letter describing why
            you&apos;re not eligible for premium-free Medicare Part A. The Marketplace will take
            your letter into consideration.{' '}
            <ExternalLink href="https://www.healthcare.gov/downloads/letter-of-explanation-application-info.pdf">
                You can use this template
            </ExternalLink>
            .
        </p>
    </React.Fragment>
);

export default hot(module)(MedicareCoverageStatusExamples);
