import MyTeamWidget from 'pages/dashboard/widgets/MyTeamWidget';
import ResourcesWidget from 'pages/dashboard/widgets/ResourcesWidget';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
    width: 100%;
    height: 1520px;
    border-radius: 0.25rem;
`;

const ResourcesAndHelpPage = () => (
    <React.Fragment>
        <Row>
            <Col xl="6" xs="12">
                <ResourcesWidget />
            </Col>
            <Col xl="6" xs="12">
                <MyTeamWidget />
            </Col>
        </Row>
        <Row>
            <StyledIframe
                referrerPolicy="no-referrer"
                src="https://help.remodelhealth.com/"
            ></StyledIframe>
        </Row>
    </React.Fragment>
);

export default hot(module)(ResourcesAndHelpPage);
