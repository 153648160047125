import {
    setHrsBenefitsEligibilityClassCodes,
    SET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES,
} from 'actions/hrsIntegrations/setHrsBenefitsEligibilityClassCodes';
import Button from 'components/Button';
import Form from 'components/Form';
import Icon from 'components/Icon';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { array, object, string } from 'yup';

const MAX_CLASS_CODE_NAME_LENGTH = 100;
const getSchema = () =>
    object({
        newClassCodes: array()
            .of(
                string()
                    .trim()
                    .required()
                    .max(MAX_CLASS_CODE_NAME_LENGTH)
                    .label('Benefits Eligibility Class Code name')
            )
            .unique({ transform: (x) => x.map((y) => y?.toLowerCase()) }),
    });

type IAdpBenefitsEligibilityClassCodesModalProps = {
    classCodes: string[];
    hrsProvider: number;
    hrsProviderName: string | undefined,
    onClose: () => void;
};

const HrsBenefitsEligibilityClassCodesModal = ({classCodes, hrsProvider, hrsProviderName, onClose,
}: IAdpBenefitsEligibilityClassCodesModalProps) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const [newClassCodes, setNewClassCodes] = useState<string[]>(classCodes);
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, SET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES),
    }));

    const schema = useMemo(() => getSchema(), []);
    const { errors, setErrors, validate } = useForm(schema);

    const save = async () => {
        const { isValid } = await validate({ newClassCodes });
        if (isValid) {
            await dispatch(setHrsBenefitsEligibilityClassCodes(teamId, hrsProvider, newClassCodes));
            onClose();
        }
    };
    const setNewClassCodesValue = useCallback(
        (index: number) => ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            const newClassCodesValues = [...newClassCodes];
            newClassCodesValues[index] = value;
            setNewClassCodes(newClassCodesValues);
        },
        [newClassCodes]
    );
    const addRowClicked = () => setNewClassCodes([...newClassCodes, '']);
    const removeClassCode = useCallback(
        (index) => () => {
            const newClassCodesValues = [...newClassCodes];
            newClassCodesValues.splice(index, 1);
            setNewClassCodes(newClassCodesValues);
            setErrors(null);
        },
        [newClassCodes, setErrors]
    );

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>{hrsProviderName} Benefits Eligibility Class Codes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-adp-benefits-eligibility-class-codes-modal-form" onSubmit={save}>
                    {newClassCodes.map((classCode, index) => (
                        <Col key={`class-code-${index}`}>
                            <Row className="align-items-center">
                                <Col>
                                    <TextField
                                        className="mb-0"
                                        errors={
                                            errors?.[
                                                (`newClassCodes[${index}]` as unknown) as keyof typeof errors
                                            ]
                                        }
                                        name={`newClassCodes[${index}]`}
                                        onChange={setNewClassCodesValue(index)}
                                        value={classCode}
                                    />
                                </Col>
                                <Button
                                    noPadding
                                    onClick={removeClassCode(index)}
                                    size="small"
                                    variant="text"
                                >
                                    <Icon>cross</Icon>
                                </Button>
                            </Row>
                            <hr />
                        </Col>
                    ))}
                    <Col>
                        <Button onClick={addRowClicked} variant="outlined">
                            Add
                        </Button>
                    </Col>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    form="add-adp-benefits-eligibility-class-codes-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(HrsBenefitsEligibilityClassCodesModal);
