import React from 'react';
import { hot } from 'react-hot-loader';

const InfoText = ({ children, className = '', ...props }: React.ComponentProps<'div'>) => (
    <div className={`text-info clickable ${className}`} {...props}>
        <i className="dripicons-information mr-1" />
        {children}
    </div>
);

export default hot(module)(InfoText);
