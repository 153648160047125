import { TeamStateIds } from 'api/generated/enums';
import CurrencyTextField from 'components/CurrencyTextField';
import DateTextField from 'components/DateTextField';
import NumberTextField from 'components/NumberTextField';
import Select from 'components/Select';
import { IUserFormState } from 'pages/addPerson/UserForm';
import React from 'react';
import { hot } from 'react-hot-loader';
import { NumberFormatProps } from 'react-number-format';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';

type IMemberQuoteInfoFormUIProps = {
    activeDate: string | undefined;
    dependentsOver20: string | undefined;
    dependentsUnder21: string | undefined;
    errors: IUserFormState['memberQuoteInfoErrors'];
    isInProspect: boolean;
    majorMedicalCoverageLevel: string | undefined;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    previousEmployeePremium: number | string | undefined;
    previousEmployerPremium: number | string | undefined;
    previousGroupLevelId: string | undefined;
    teamStateId: TeamStateIds;
};
const MAX_DEPENDENTS = 999;
const DEPENDENT_NUMBER_INPUT_PROPS: NumberFormatProps<unknown> = {
    isAllowed: ({ floatValue, value }) => !hasValue(value) || (floatValue ?? 0) <= MAX_DEPENDENTS,
};
const MemberQuoteInfoFormUI = ({
    activeDate,
    dependentsOver20,
    dependentsUnder21,
    errors,
    isInProspect,
    majorMedicalCoverageLevel,
    onChange,
    previousEmployeePremium,
    previousEmployerPremium,
    previousGroupLevelId,
    teamStateId,
}: IMemberQuoteInfoFormUIProps) => {
    const isNotCustomerOrRenewing = ![TeamStateIds.Customer, TeamStateIds.Renewing].includes(
        teamStateId
    );
    const { groupLevels } = useSelector((state: AppStore) => ({
        groupLevels: state.options.groupLevels,
    }));
    return (
        <React.Fragment>
            <DateTextField
                data-cy="active-date"
                errors={errors?.activeDate}
                helperText="The date on which benefits are expected to start for this member (usually the first of the month)"
                isOptional={isInProspect}
                label="Active Date"
                name="activeDate"
                onChange={onChange}
                value={formatDateForDisplay(activeDate)}
            />
            <Select
                data-cy="group-level"
                defaultText="Choose a Major Medical Coverage Level"
                defaultValue=""
                errors={errors?.majorMedicalCoverageLevel}
                helperText="The members in your family that will need coverage"
                items={groupLevels}
                label="Major Medical Coverage Level"
                name="majorMedicalCoverageLevel"
                onChange={onChange}
                optionText="fulltext"
                optionValue="groupLevelId"
                value={majorMedicalCoverageLevel}
            />
            <Select
                data-cy="previous-group-level"
                defaultText="Choose a Coverage Level"
                defaultValue=""
                errors={errors?.previousGroupLevelId}
                helperText="If this member was on the company’s group plan, please add their level of coverage from that plan here (EE, ES, EC, EF, W)"
                isOptional
                items={groupLevels}
                label="Previous Group Coverage Level"
                name="previousGroupLevelId"
                onChange={onChange}
                optionText="fulltext"
                optionValue="groupLevelId"
                value={previousGroupLevelId}
            />
            {isNotCustomerOrRenewing && (
                <React.Fragment>
                    <NumberTextField
                        data-cy="dependents-under21"
                        errors={errors?.dependentsUnder21}
                        inputProps={DEPENDENT_NUMBER_INPUT_PROPS}
                        label="Dependents Under 21"
                        name="dependentsUnder21"
                        onChange={onChange}
                        placeholder="Enter a number for dependents under 21"
                        value={dependentsUnder21}
                    />
                    <NumberTextField
                        data-cy="dependents-over20"
                        errors={errors?.dependentsOver20}
                        inputProps={DEPENDENT_NUMBER_INPUT_PROPS}
                        label="Dependents 21 and Over"
                        name="dependentsOver20"
                        onChange={onChange}
                        placeholder="Enter a number for dependents 21 and over"
                        value={dependentsOver20}
                    />
                    <CurrencyTextField
                        errors={errors?.previousEmployeePremium}
                        label="Previous Employee Premium"
                        name="previousEmployeePremium"
                        onChange={onChange}
                        placeholder="Enter a previous employee premium"
                        value={previousEmployeePremium}
                    />
                    <CurrencyTextField
                        errors={errors?.previousEmployerPremium}
                        label="Previous Employer Premium"
                        name="previousEmployerPremium"
                        onChange={onChange}
                        placeholder="Enter a previous employer premium"
                        value={previousEmployerPremium}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default hot(module)(MemberQuoteInfoFormUI);
