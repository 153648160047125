import { Card, CardContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import { getBenefits } from 'actions/benefits/getBenefits';
import { TeamBenefitTypes, UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import Button from 'components/Button';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import SubmitExpenseModal from 'pages/benefits/SubmitExpenseModal';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH } from 'routers/routes';
import { isTodaySameOrAfter, maxDateString } from 'utilities/moment';

const ReimbursementWidget = () => {
    const dispatch = useThunkDispatch();
    const {
        isVisible: isSubmitExpenseModalVisible,
        openModal: openSubmitExpenseModal,
        closeModal: closeSubmitExpenseModal,
    } = useModalState();
    const { useReimbursementProgramManagement } = useTeamProps();
    const { currentUserId } = useUserProps();
    const { shouldRender } = useSelector((state: AppStore) => {
        const reimbursementProgram = state.memberBenefits.userTeamBenefitTermDetails?.find(
            (x) =>
                x.teamBenefit?.teamBenefitType === TeamBenefitTypes.ReimbursementProgram &&
                x.statusId === UserTeamBenefitTermDetailStatuses.Enrolled &&
                isTodaySameOrAfter((x.coverageStartDate as string) ?? maxDateString)
        );
        return {
            shouldRender: useReimbursementProgramManagement && !!reimbursementProgram,
        };
    });
    useEffect(() => {
        if (currentUserId) {
            dispatch(getBenefits(currentUserId, new Date().getFullYear()));
        }
    }, [dispatch, currentUserId]);

    return shouldRender ? (
        <Card>
            {isSubmitExpenseModalVisible && (
                <SubmitExpenseModal onClose={closeSubmitExpenseModal} />
            )}
            <CardContent>
                <DashboardCardHeader header="Reimbursement Program" />
                <hr />
                <Stack alignItems="center" direction="row">
                    <Button onClick={openSubmitExpenseModal}>Submit Expense</Button>
                    <Link
                        className="m-2"
                        data-cy="dashboard-widget-view-submitted-expenses"
                        to={REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH}
                    >
                        <Button>View Submitted Expenses</Button>
                    </Link>
                </Stack>
            </CardContent>
        </Card>
    ) : (
        <React.Fragment />
    );
};

export default hot(module)(ReimbursementWidget);
