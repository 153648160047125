import CurrencyTextField from 'components/CurrencyTextField';
import DateTextField from 'components/DateTextField';
import PhoneTextField from 'components/PhoneTextField';
import TextField from 'components/TextField';
import { IUserFormState } from 'pages/addPerson/UserForm';
import React from 'react';
import { hot } from 'react-hot-loader';

const BasicInfoFormUI = ({
    email,
    employeeAnnualIncome,
    employeeDateOfBirth,
    firstName,
    errors,
    lastName,
    onChange,
    phone,
    spouseDateOfBirth,
}: {
    email: string | undefined;
    employeeAnnualIncome: string | undefined;
    employeeDateOfBirth: string | undefined;
    errors: IUserFormState['userErrors'];
    firstName: string | undefined;
    lastName: string | undefined;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    phone: string | undefined;
    spouseDateOfBirth: string | undefined;
}) => (
    <React.Fragment>
        <TextField
            autoFocus
            data-cy="first-name"
            errors={errors?.firstName}
            label="Legal First Name"
            name="firstName"
            onChange={onChange}
            placeholder="Enter a first name"
            value={firstName}
        />
        <TextField
            data-cy="last-name"
            errors={errors?.lastName}
            label="Last Name"
            name="lastName"
            onChange={onChange}
            placeholder="Enter a last name"
            value={lastName}
        />
        <TextField
            data-cy="email"
            errors={errors?.email}
            label="Email"
            name="email"
            onChange={onChange}
            placeholder="Enter an email address"
            value={email}
        />
        <PhoneTextField
            data-cy="phone"
            errors={errors?.phone}
            label="Phone"
            name="phone"
            onChange={onChange}
            placeholder="Enter an phone number"
            value={phone}
        />

        <DateTextField
            errors={errors?.employeeDateOfBirth}
            label="Employee Date of Birth"
            name="employeeDateOfBirth"
            onChange={onChange}
            value={employeeDateOfBirth}
        />
        <DateTextField
            errors={errors?.spouseDateOfBirth}
            isOptional
            label="Spouse Date of Birth"
            name="spouseDateOfBirth"
            onChange={onChange}
            value={spouseDateOfBirth}
        />
        <CurrencyTextField
            errors={errors?.employeeAnnualIncome}
            label="Employee Annual Income"
            name="employeeAnnualIncome"
            onChange={onChange}
            placeholder="Enter an Employee Annual Income"
            value={employeeAnnualIncome}
        />
    </React.Fragment>
);

export default hot(module)(BasicInfoFormUI);
