import React from 'react';
import { hot } from 'react-hot-loader';

const SsnVerificationExamples = () => (
    <React.Fragment>
        <p>Documents must include your first name, last name, and SSN.</p>
        <ul>
            <li>Social Security card</li>
            <li>1040 Tax Return (federal or state versions acceptable)</li>
            <li>
                W2 and/or 1099s (includes 1099 MISC, 1099G, 1099R, 1099SSA, 1099DIV, 1099S, 1099INT)
            </li>
            <li>W4 Withholding Allowance Certificate (federal or state versions acceptable)</li>
            <li>1095 (includes 1095A, 1095B, 1095C)</li>
            <li>Pay stub documentation</li>
            <li>Social Security Administration documentation (includes 4029)</li>
            <li>Military record</li>
            <li>U.S. Military ID card</li>
            <li>Military dependent’s ID card</li>
            <li>Unemployment Benefits (Unemployment Benefits Letter)</li>
            <li>
                Court Order Granting a Name Change, that must have your <strong>original</strong>{' '}
                first and last name, <strong>new</strong> first and last name, and SSN
            </li>
            <li>Divorce decree</li>
        </ul>
    </React.Fragment>
);

export default hot(module)(SsnVerificationExamples);
