import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const VaCoverageStatusExamples = () => (
    <React.Fragment>
        <p>
            Submit this document if you need to confirm that you don’t have coverage through the VA:
        </p>
        <ul>
            <li>Letter from the VA that shows the expiration date of previous health coverage</li>
        </ul>
        <p>
            <strong>If you don’t have this document</strong>, you can submit a letter describing
            that you’re not enrolled in health coverage through the VA. The Marketplace will take
            your letter into consideration.{' '}
            <ExternalLink href="https://www.healthcare.gov/downloads/letter-of-explanation-application-info.pdf">
                You can use this template
            </ExternalLink>
            .
        </p>
    </React.Fragment>
);

export default hot(module)(VaCoverageStatusExamples);
