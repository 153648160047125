import React from 'react';
import { hot } from 'react-hot-loader';

const AmericanIndianOrAlaskanNativeExamples = () => (
    <ul>
        <li>Tribal Enrollment/Membership card from a federally recognized tribe</li>
        <li>
            Document issued by Bureau of Indian Affairs (BIA) recognizing you as American
            Indian/Alaska Native
        </li>
        <li>Authentic document from a federally recognized tribe declaring your membership</li>
        <li>Certificate of Degree of Indian Blood</li>
        <li>Certificate of Indian status card</li>
        <li>
            I-872 American Indian Card (Texas and Oklahoma Kickapoo American and Mexican members)
        </li>
        <li>
            Document issued by Indian Health Service (IHS) showing that you were/are eligible for
            IHS services as an American Indian/Alaska Native
        </li>
        <li>U.S. American Indian/Alaska Native tribal enrollment or shareholder documentation</li>
        <li>
            Letter from the Marketplace granting a tribal exemption based on tribal membership or
            Alaska Native shareholder status
        </li>
    </ul>
);

export default hot(module)(AmericanIndianOrAlaskanNativeExamples);
