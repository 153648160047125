import { clearPathwayBlueprints } from 'actions/pathwayBlueprint/clearPathwayBlueprints';
import {
    GET_TEAM_PATHWAY_BLUEPRINTS_ACTION,
    getTeamPathwayBlueprints,
} from 'actions/pathwayBlueprint/getTeamPathwayBlueprints';
import { IPathwayBlueprint, IPathwayBlueprintDto } from 'api/generated/models';
import ReactTable from 'components/reactTable/ReactTable';
import { MINIMUM_DATE } from 'constants/date';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import PathwayBlueprintRecordActionButtons from 'pages/pathwayBlueprints/PathwayBlueprintRecordActionButtons';
import {
    IsActiveIndicatorCell,
    NameCell,
    StaleDataIndicatorCell,
} from 'pages/pathwayBlueprints/PathwayBlueprintsCells';
import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Cell, Column } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatCurrency, formatDateForDisplay } from 'utilities/format';
import { getDateOrNull } from 'utilities/moment';
import { DEFAULT_ACTIONS_COLUMN_PROPS } from 'utilities/reactTable';

const NAME_COLUMN_ID = 'name';
const IS_ACTIVE_COLUMN_ID = 'is-active-indicator';
const YEAR_COLUMN_ID = 'year';
const JUSTIFY_CONTENT_CENTER = 'justify-content-center';
const CENTER_COLUMN_PROPS = {
    className: JUSTIFY_CONTENT_CENTER,
    headerClassName: JUSTIFY_CONTENT_CENTER,
};

const initialSortBy = [
    { desc: true, id: IS_ACTIVE_COLUMN_ID },
    { desc: true, id: YEAR_COLUMN_ID },
];

type IPathwayBlueprintsTableProps = {
    search?: string;
};

const PathwayBlueprintsTable = ({ search }: IPathwayBlueprintsTableProps) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();

    const { pathwayBlueprints, showActivity } = useSelector((state: AppStore) => ({
        pathwayBlueprints: state.pathwayBlueprints,
        showActivity: hasApiActivity(state, GET_TEAM_PATHWAY_BLUEPRINTS_ACTION),
    }));

    useEffect(() => {
        dispatch(clearPathwayBlueprints());
        dispatch(getTeamPathwayBlueprints(teamId));
        return () => {
            dispatch(clearPathwayBlueprints());
        };
    }, [dispatch, teamId]);

    const columns = useMemo<Column<IPathwayBlueprintDto>[]>(
        () => [
            {
                accessor: ({ lastBulkCalculatedDate }) => getDateOrNull(lastBulkCalculatedDate),
                Cell: StaleDataIndicatorCell,
                disableResizing: true,
                disableSortBy: true,
                id: 'stale-data-indicator',
                width: 65,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ isActive }) => isActive,
                Cell: IsActiveIndicatorCell,
                disableResizing: true,
                Header: 'Active',
                id: IS_ACTIVE_COLUMN_ID,
                sortType: 'basic',
                width: 55,
            },
            {
                accessor: ({ name }) => name,
                Cell: NameCell,
                filter: 'text',
                Header: 'Name',
                id: NAME_COLUMN_ID,
                width: 250,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ year }) => year,
                Header: 'Year',
                id: YEAR_COLUMN_ID,
                width: 55,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ numberOfUnassignedUsers }) => numberOfUnassignedUsers,
                Header: '# of Unassigned Members',
                id: 'unassigned-members',
                width: 85,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ projectedSavings }) => formatCurrency(projectedSavings),
                Header: 'Savings',
                id: 'savings',
                width: 85,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ lastBulkCalculatedByUser }) => lastBulkCalculatedByUser?.displayName,
                Header: 'Last Bulk Calculation By',
                id: 'last-bulk-calculation-by',
                width: 105,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ lastBulkCalculatedDate }) =>
                    getDateOrNull(lastBulkCalculatedDate)?.toDate() ?? MINIMUM_DATE,
                Cell: ({
                    row: {
                        original: { lastBulkCalculatedDate },
                    },
                }: Cell<IPathwayBlueprint>) => formatDateForDisplay(lastBulkCalculatedDate) ?? '',
                Header: 'Last Bulk Calculated Date',
                id: 'last-bulk-calculated-date',
                sortType: 'datetime',
                width: 85,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ lastModifiedByUser }) => lastModifiedByUser?.displayName,
                Header: 'Last Modified By',
                id: 'last-modified-by',
                width: 105,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ lastModifiedDate }) =>
                    getDateOrNull(lastModifiedDate)?.toDate() ?? MINIMUM_DATE,
                Cell: ({
                    row: {
                        original: { lastModifiedDate },
                    },
                }: Cell<IPathwayBlueprint>) => formatDateForDisplay(lastModifiedDate) ?? '',
                Header: 'Last Modified Date',
                id: 'last-modified-date',
                sortType: 'datetime',
                width: 85,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ createdByUser }) => createdByUser?.displayName,
                Header: 'Created By',
                id: 'created-by',
                width: 105,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ createdDate }) => getDateOrNull(createdDate)?.toDate() ?? MINIMUM_DATE,
                Cell: ({
                    row: {
                        original: { createdDate },
                    },
                }: Cell<IPathwayBlueprint>) => formatDateForDisplay(createdDate) ?? '',
                Header: 'Created Date',
                id: 'created-date',
                sortType: 'datetime',
                width: 85,
            },
            {
                ...DEFAULT_ACTIONS_COLUMN_PROPS,
                Cell: PathwayBlueprintRecordActionButtons,
                headerClassName: JUSTIFY_CONTENT_CENTER,
                width: 150,
            },
        ],
        []
    );
    const filters = useMemo(() => [{ id: NAME_COLUMN_ID, value: search }], [search]);

    return (
        <React.Fragment>
            <ReactTable
                columns={columns}
                data={pathwayBlueprints}
                filters={filters}
                initialSortBy={initialSortBy}
                loading={showActivity}
            />
        </React.Fragment>
    );
};

export default hot(module)(PathwayBlueprintsTable);
