import { MEMBER_TERMS_GROUP_KEY } from 'constants/pactSafeGroups';
import OptModal from 'pages/dashboard/taskLists/member/tasks/OptModal';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import styled from 'styled-components';

type IOptInModalProps = {
    isOptIn?: boolean;
    onHide: () => void;
    onNoClick: () => void;
    onYesClick: () => void;
    setSignature: (arg0: string) => void;
    signature: string;
};

const CheckMarkList = styled('ul')`
    list-style-type: none;
    margin-top: 20px;
`;

const CheckMark = styled('li')`
    margin: 5px;

    &::before {
        content: '\\2714\\0020';
        color: #5cc2a7;
        margin-right: 10px;
    }
`;

const OptInModal = (props: IOptInModalProps) => {
    const { teamName } = useSelector((state: AppStore) => ({
        teamName: state.current.teamProfile?.team?.name ?? 'Your employer',
    }));
    return (
        <OptModal
            {...props}
            dataCyModal="opt-in-modal"
            dataCyNo="change-to-opt-out"
            groupKey={MEMBER_TERMS_GROUP_KEY}
            modalBody={
                <div>
                    <div>
                        {teamName} has asked us to help you with your personal health benefits. By
                        agreeing to work with us, you&apos;re not committing to buying anything, but
                        rather allowing us to help you navigate all of your options. You&apos;ll
                        get:
                    </div>
                    <CheckMarkList>
                        <CheckMark>Year-round support from personal advisors</CheckMark>
                        <CheckMark>24/7 access to a doctor via phone or computer</CheckMark>
                        <CheckMark>Eligible premiums deducted from your paycheck</CheckMark>
                        <CheckMark>Many other great benefits</CheckMark>
                    </CheckMarkList>
                </div>
            }
            modalTitle="Agree to Use Remodel Health"
            noText="I prefer to not use Remodel Health."
            submitText="Submit"
        />
    );
};

export default hot(module)(OptInModal);
