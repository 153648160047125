import TeamManagementWrapper from 'components/TeamManagementWrapper';
import UnderConstructionModal from 'components/underConstructionModal/UnderConstructionModal';
import { openUnderConstructionModal } from 'components/underConstructionModal/underConstructionAction';
import DesktopContext from 'contexts/DesktopContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import ShopContent from 'pages/shop/ShopContent';
import React, { useContext, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { IAppRouterProps } from 'routers/AppRouter';

const ShopPage = ({ isTeamManagementPage }: IAppRouterProps) => {
    const isDesktop = useContext(DesktopContext);
    const dispatch = useThunkDispatch();
    const { user } = useUserProps();

    useEffect(() => {
        if (!isDesktop) {
            dispatch(openUnderConstructionModal());
        }
    }, [dispatch, isDesktop]);

    return (
        <TeamManagementWrapper isTeamManageMentPage={isTeamManagementPage}>
            <React.Fragment>
                <UnderConstructionModal />
                <ShopContent
                    isTeamManagementPage={isTeamManagementPage}
                    year={user?.activeDate?.getYear() as number}
                />
            </React.Fragment>
        </TeamManagementWrapper>
    );
};

export default hot(module)(ShopPage);
