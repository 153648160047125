import { editTeamRelationship } from 'actions/team/editTeamRelationship';
import { getTeamRelationships } from 'actions/team/getTeamRelationships';
import { getTeamRepresentativeOptions } from 'actions/user/getTeamRepresentativeOptions';
import { RelationshipTypeIds } from 'api/generated/enums';
import { IUserTeamRelationship } from 'api/generated/models';
import { ROLE_IDS } from 'api/generated/roleIds';
import { IValueType } from 'components/EditableAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import ProfileSectionHeader from 'pages/teamProfile/ProfileSectionHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isInRoleSelector, isRhSelector } from 'selectors/role';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

const findRelationshipUserId = (
    teamRelationships: IUserTeamRelationship[],
    relationshipTypeId: RelationshipTypeIds
) =>
    teamRelationships.find(
        (x) => x?.relationshipType?.teamRelationshipTypeId === relationshipTypeId
    )?.user?.userId;

const FourthSection = ({ readonly }: { readonly: boolean }) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { canEditTeamRelationships, representativeOptions, teamRelationships } = useSelector(
        (state: AppStore) => ({
            canEditTeamRelationships:
                isRhSelector(state) || isInRoleSelector(state, ROLE_IDS.PARTNER_ADMIN),
            representativeOptions: state.teamRepresentativeOptions,
            teamRelationships: state.teamRelationships,
        })
    );

    useEffect(() => {
        if (canEditTeamRelationships) {
            dispatch(getTeamRepresentativeOptions(teamId));
            dispatch(getTeamRelationships(teamId));
        }
    }, [dispatch, canEditTeamRelationships, teamId]);

    const [engagementRepId, setEngagementRepId] = useState(
        findRelationshipUserId(teamRelationships, RelationshipTypeIds.EngagementRepresentative)
    );
    const [launchRepId, setLaunchRepId] = useState(
        findRelationshipUserId(teamRelationships, RelationshipTypeIds.LaunchRepresentative)
    );
    const [customerSuccessRepId, setCustomerSuccessRepId] = useState(
        findRelationshipUserId(teamRelationships, RelationshipTypeIds.CustomerSuccessRepresentative)
    );
    const [accountManagerId, setAccountManagerId] = useState(
        findRelationshipUserId(teamRelationships, RelationshipTypeIds.AccountManager)
    );

    useEffect(() => {
        setEngagementRepId(
            findRelationshipUserId(teamRelationships, RelationshipTypeIds.EngagementRepresentative)
        );
        setLaunchRepId(
            findRelationshipUserId(teamRelationships, RelationshipTypeIds.LaunchRepresentative)
        );
        setCustomerSuccessRepId(
            findRelationshipUserId(
                teamRelationships,
                RelationshipTypeIds.CustomerSuccessRepresentative
            )
        );
        setAccountManagerId(
            findRelationshipUserId(teamRelationships, RelationshipTypeIds.AccountManager)
        );
    }, [teamRelationships]);

    const getRepresentativeOptionName = useCallback(
        (userId) => representativeOptions.find((u) => u.userId === userId)?.name,
        [representativeOptions]
    );
    const save = useCallback(
        (relationshipTypeId: RelationshipTypeIds) => async (_name: string, userId: IValueType) => {
            await dispatch(editTeamRelationship(teamId, userId as string, relationshipTypeId));
        },
        [dispatch, teamId]
    );
    if (canEditTeamRelationships) {
        return (
            <React.Fragment>
                <ProfileSectionHeader headerText="Team Representatives" />
                <EditableSelectAttribute
                    data-cy="engagement-rep"
                    defaultText=" "
                    formatter={getRepresentativeOptionName}
                    isOptional
                    items={representativeOptions}
                    label="Engagement Representative"
                    name="engagementRep"
                    onChange={onChange(setEngagementRepId)}
                    optionText="name"
                    optionValue="userId"
                    readonly={readonly}
                    save={save(RelationshipTypeIds.EngagementRepresentative)}
                    validationSchema={string().label('Engagement Representative')}
                    value={engagementRepId}
                />
                <EditableSelectAttribute
                    data-cy="launch-rep"
                    defaultText=" "
                    formatter={getRepresentativeOptionName}
                    isOptional
                    items={representativeOptions}
                    label="Launch Representative"
                    name="launchRep"
                    onChange={onChange(setLaunchRepId)}
                    optionText="name"
                    optionValue="userId"
                    readonly={readonly}
                    save={save(RelationshipTypeIds.LaunchRepresentative)}
                    validationSchema={string().label('Launch Representative')}
                    value={launchRepId}
                />
                <EditableSelectAttribute
                    data-cy="customer-success-rep"
                    defaultText=" "
                    formatter={getRepresentativeOptionName}
                    isOptional
                    items={representativeOptions}
                    label="Customer Success Representative"
                    name="customerSuccessRep"
                    onChange={onChange(setCustomerSuccessRepId)}
                    optionText="name"
                    optionValue="userId"
                    readonly={readonly}
                    save={save(RelationshipTypeIds.CustomerSuccessRepresentative)}
                    validationSchema={string().label('Customer Success Representative')}
                    value={customerSuccessRepId}
                />
                <EditableSelectAttribute
                    data-cy="account-manager"
                    defaultText=" "
                    formatter={getRepresentativeOptionName}
                    isOptional
                    items={representativeOptions}
                    label="Account Manager"
                    name="accountManager"
                    onChange={onChange(setAccountManagerId)}
                    optionText="name"
                    optionValue="userId"
                    readonly={readonly}
                    save={save(RelationshipTypeIds.AccountManager)}
                    validationSchema={string().label('Account Manager')}
                    value={accountManagerId}
                />
            </React.Fragment>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(FourthSection);
