import { DISCONNECT_FINCH_ACTION, disconnectFinch } from 'actions/finch/disconnectFinch';
import {
    REGISTER_DEDUCTIONS_WITH_FINCH_ACTION,
    registerDeductionsWithFinch,
} from 'actions/finch/registerDeductionsWithFinch';
import { DeductionStatus, FinchIntegrationStatuses } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { DisconnectFinch, SetPayrollSystemDeduction } from 'api/generated/permissions';
import ActionButtons from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';

const FinchActionButtons = ({ team }: { team: ITeam | undefined }) => {
    const { canDisconnectFinch, canRegisterDeductions, showActivity } = useSelector(
        (state: AppStore) => ({
            canDisconnectFinch: hasSomePermissions(state, DisconnectFinch),
            canRegisterDeductions: hasSomePermissions(state, SetPayrollSystemDeduction),
            showActivity: hasApiActivity(
                state,
                DISCONNECT_FINCH_ACTION || REGISTER_DEDUCTIONS_WITH_FINCH_ACTION
            ),
        })
    );

    const dispatch = useThunkDispatch();
    const [deductionsHaveBeenRegistered, setDeductionsHaveBeenRegistered] = useState(false);
    const [showRegisterDeductionsModal, setShowRegisterDeductionsModal] = useState(false);
    const [showDisconnectFinchModal, setShowDisconnectFinchModal] = useState(false);

    const onRegisterDeductionsYesClick = useCallback(async () => {
        await dispatch(
            registerDeductionsWithFinch(team?.teamId ?? '', () =>
                setDeductionsHaveBeenRegistered(true)
            )
        );
        setShowRegisterDeductionsModal(false);
    }, [dispatch, team?.teamId]);

    const onDisconnectFinchYesClick = useCallback(async () => {
        await dispatch(disconnectFinch(team?.teamId ?? ''));
        setShowDisconnectFinchModal(false);
    }, [dispatch, team?.teamId]);

    const aDeductionIsNotConfiguredOrError =
        team?.finchPostTaxDeductionStatus === undefined ||
        team?.finchPostTaxDeductionStatus === DeductionStatus.NotConfigured ||
        team?.finchPostTaxDeductionStatus === DeductionStatus.Error ||
        team?.finchPreTaxDeductionStatus === undefined ||
        team?.finchPreTaxDeductionStatus === DeductionStatus.NotConfigured ||
        team?.finchPreTaxDeductionStatus === DeductionStatus.Error;

    const actions = [
        {
            isVisible:
                team?.finchIntegrationStatus !== FinchIntegrationStatuses.NotConfigured &&
                canRegisterDeductions &&
                aDeductionIsNotConfiguredOrError &&
                !deductionsHaveBeenRegistered,
            onClick: () => setShowRegisterDeductionsModal(true),
            text: 'Register Deductions',
        },
        {
            isVisible:
                team?.finchIntegrationStatus !== FinchIntegrationStatuses.NotConfigured &&
                canDisconnectFinch,
            onClick: () => setShowDisconnectFinchModal(true),
            text: 'Disconnect Finch',
        },
    ];

    return (
        <React.Fragment>
            <ActionButtons isAlwaysDropdown items={actions} />
            {showRegisterDeductionsModal && (
                <ConfirmationModal
                    body={
                        <React.Fragment>
                            You&apos;re about to register deductions with Finch. Please note that
                            with some providers, processing could take up to 7 business days. An
                            email will be sent to the care and launch teams once processing is
                            complete. These are the deduction codes that will be used:
                            <br />
                            <br />
                            <strong>Post-tax:</strong> {team?.postTaxDeductionCode ?? ''}
                            <br />
                            <strong>Pre-tax:</strong> {team?.preTaxDeductionCode ?? ''}
                            <br />
                            <br />
                            Would you like to proceed with registering deductions?
                        </React.Fragment>
                    }
                    onNoClick={() => setShowRegisterDeductionsModal(false)}
                    onYesClick={onRegisterDeductionsYesClick}
                    showActivity={showActivity}
                    title="Register Deductions"
                />
            )}
            {showDisconnectFinchModal && (
                <ConfirmationModal
                    body="Are you sure you want to disconnect Finch?"
                    onNoClick={() => setShowDisconnectFinchModal(false)}
                    onYesClick={onDisconnectFinchYesClick}
                    showActivity={showActivity}
                    title="Disconnect Finch"
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(FinchActionButtons);
