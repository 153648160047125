import ForgotPasswordForm from 'pages/forgotPassword/ForgotPasswordForm';
import React from 'react';
import { hot } from 'react-hot-loader';

const ForgotPasswordPage = () => (
    <div className="mt-7 mb-7">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <ForgotPasswordForm />
                </div>
            </div>
        </div>
    </div>
);

export default hot(module)(ForgotPasswordPage);
