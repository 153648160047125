import HomeIcon from '@mui/icons-material/Home';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Logo } from 'components/Logo';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Link } from 'react-router-dom';
import { DASHBOARD_PATH } from 'routers/routes';

const MemberSurveyComplete = () => (
    <Paper sx={{ textAlign: 'center' }}>
        <Logo height="30" />
        <Typography my={3} textTransform="uppercase" variant="h5">
            Survey Complete!
        </Typography>
        <p className="text-center">
            Thank you for taking our health benefits survey. Now you are ready to head to your
            personal dashboard in the Remodel Health platform!
        </p>
        <Button
            component={Link}
            data-cy="survey-go-to-dashboard"
            startIcon={<HomeIcon />}
            sx={{ m: 2 }}
            to={DASHBOARD_PATH}
            variant="outlined"
        >
            Go to Dashboard
        </Button>
    </Paper>
);

export default hot(module)(MemberSurveyComplete);
