import {
    APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT,
    applyCalculationForPathwayBlueprint,
} from 'actions/pathwayBlueprint/applyCalculationForPathwayBlueprint';
import {
    GET_PATHWAY_BLUEPRINT_ACTION,
    getPathwayBlueprint,
} from 'actions/pathwayBlueprint/getPathwayBlueprint';
import ApplyingStaleContributionsWarning from 'components/ApplyingStaleContributionsWarning';
import ConfirmationModal from 'components/ConfirmationModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import CustomActiveWageUpModal from 'pages/pathwayBlueprint/CustomActiveWageUpModal';
import CustomProjectedReimbursementModal from 'pages/pathwayBlueprint/CustomProjectedReimbursementModal';
import CustomProjectedWageUpModal from 'pages/pathwayBlueprint/CustomProjectedWageUpModal';
import IchraClassModal from 'pages/pathwayBlueprint/ichraClasses/IchraClassModal';
import PathwayModal from 'pages/pathwayBlueprint/PathwayModal';
import PathwayBlueprintModal from 'pages/pathwayBlueprints/PathwayBlueprintModal';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasApiActivityWithParams } from 'selectors/activity';
import CustomCostModal from 'pages/pathwayBlueprint/CustomPlanCostModal';

const PathwayBlueprintModals = ({
    closeApplyContributionsModal,
    closeEditModal,
    isApplyContributionsModalVisible,
    isEditModalVisible,
    isIchraClassModalVisible,
    isUpdateLiveRates,
    setIsUpdateLiveRates,
}: {
    closeApplyContributionsModal: () => void;
    closeEditModal: () => void;
    isApplyContributionsModalVisible: boolean;
    isEditModalVisible: boolean;
    isIchraClassModalVisible: boolean;
    isUpdateLiveRates: boolean;
    setIsUpdateLiveRates: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const dispatch = useThunkDispatch();
    const { pathwayBlueprintId } = useParams<{ pathwayBlueprintId?: string }>();
    const {
        isCustomActiveWageUpModalVisible,
        isCustomCostModalVisible,
        isCustomReimbursementModalVisible,
        isCustomWageUpModalVisible,
        isLoading,
        isModalVisible,
        pathwayBlueprint,
    } = useSelector((state: AppStore) => ({
        isCustomActiveWageUpModalVisible:
        state.customActiveWageUpModalState.isCustomActiveWageUpModalVisible,
        isCustomCostModalVisible: state.customPlanCostModalState.isCustomPlanCostModalVisible,
        isCustomReimbursementModalVisible:
        state.pathwayCustomProjectedModalState.isCustomReimbursementModalVisible,
        isCustomWageUpModalVisible:
        state.pathwayCustomProjectedModalState.isCustomWageUpModalVisible,
        isLoading:
            hasApiActivity(state, GET_PATHWAY_BLUEPRINT_ACTION) ||
            hasApiActivityWithParams(state, APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT, {
                isBulk: 'true',
            }),
        isModalVisible: state.pathwayModalState.isModalVisible,
        pathwayBlueprint: state.pathwayBlueprint,
    }));
    const applyContributions = async () => {
        await dispatch(
            applyCalculationForPathwayBlueprint(
                pathwayBlueprintId,
                pathwayBlueprint.pathwayUsers?.map((pathwayUser) => pathwayUser.id) ?? []
            )
        );
        await dispatch(getPathwayBlueprint(pathwayBlueprintId));
        closeApplyContributionsModal();
    };

    const onCloseEditModal = useCallback(() => {
        setIsUpdateLiveRates(false);
        closeEditModal();
    }, [closeEditModal, setIsUpdateLiveRates]);
    return (
        <React.Fragment>
            {isCustomReimbursementModalVisible && <CustomProjectedReimbursementModal />}
            {isCustomCostModalVisible && <CustomCostModal />}
            {isCustomWageUpModalVisible && <CustomProjectedWageUpModal />}
            {isCustomActiveWageUpModalVisible && <CustomActiveWageUpModal />}

            {isEditModalVisible && (
                <PathwayBlueprintModal
                    initialYear={pathwayBlueprint?.year}
                    isUpdateLiveRates={isUpdateLiveRates}
                    onClose={onCloseEditModal}
                    pathwayBlueprintId={pathwayBlueprint?.id}
                />
            )}
            {isApplyContributionsModalVisible && (
                <ConfirmationModal
                    body={
                        <div>
                            <p>
                                Are you sure you want to set the current Projected Wage+ or
                                Reimbursement to <strong>Active</strong> for each member in this
                                Pathway Blueprint?
                            </p>
                            <ApplyingStaleContributionsWarning
                                pathwayBlueprint={pathwayBlueprint}
                            />
                        </div>
                    }
                    onNoClick={closeApplyContributionsModal}
                    onYesClick={applyContributions}
                    showActivity={isLoading}
                    title="Apply Contributions?"
                />
            )}
            {isModalVisible && <PathwayModal />}
            {isIchraClassModalVisible && <IchraClassModal />}
        </React.Fragment>
    );
};

export default hot(module)(PathwayBlueprintModals);
