import { AppStoreThunkDispatch } from 'actions/commonAction';
import { transitionToTeamAdminLaunch } from 'actions/team/transitionToTeamAdminLaunch';
import LaunchDetailsModal from 'components/launchDetails/LaunchDetailsModal';
import { taskItemIds, taskListTypes } from 'constants/tasks';
import { completedTasksSelector } from 'pages/dashboard/dashboardSelectors';
import TaskListContainer from 'pages/dashboard/taskLists/TaskListContainer';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_DASHBOARD_PATH } from 'routers/routes';
const completeMessage = <div>Great job! All tasks for RH Launch have been completed.</div>;

type IRhLaunchTaskListBaseProps = {
    teamId: string;
};

type IRhLaunchTaskListProps = DispatchProps & IRhLaunchTaskListBaseProps & StateProps;

class RhLaunchTaskList extends React.PureComponent<IRhLaunchTaskListProps> {
    override state = {
        showLaunchDetailsModal: false,
    };

    closeLaunchDetailsModal = () => {
        this.setState({ showLaunchDetailsModal: false });
    };

    renderTaskItems = () => {
        const items = [];
        items.push(
            <TaskListItem
                buttonText="I'm Done"
                complete={this.props.scheduleTeamAdminSetupCallComplete}
                data-cy="schedule-call"
                description="Go over next steps, launch dates, deadlines, etc."
                enabled
                key={taskItemIds.ScheduleTeamAdminSetupCall}
                markCompleteOnAction
                showButton
                taskId={taskItemIds.ScheduleTeamAdminSetupCall}
                title="Schedule a Call With the Team Admin"
            />
        );
        items.push(
            <TaskListItem
                action={() => this.setState({ showLaunchDetailsModal: true })}
                buttonText="Enter Details"
                complete={this.props.launchDetailsComplete}
                data-cy="enter-launch-details"
                description="Fill out the details the team needs for the upcoming launch"
                enabled
                key={taskItemIds.CompleteLaunchDetails}
                showButton
                taskId={taskItemIds.CompleteLaunchDetails}
                title="Enter Launch Details"
            />
        );
        if (this.props.taskListComplete) {
            items.push(
                <TaskListItem
                    action={async () => this.props.transitionToTeamAdminLaunch(this.props.teamId)}
                    buttonText="Ready for Team Review"
                    complete={this.props.rhLaunchStateComplete}
                    data-cy="team-ready-for-review"
                    description="Notify Team Admin that details are ready for their review"
                    enabled
                    key={taskItemIds.SetRhLaunchComplete}
                    showButton
                    taskId={taskItemIds.SetRhLaunchComplete}
                    title="Team Ready For Review"
                />
            );
        }

        return items;
    };

    override render() {
        return (
            <React.Fragment>
                {this.state.showLaunchDetailsModal && (
                    <LaunchDetailsModal
                        handleSubmit={this.closeLaunchDetailsModal}
                        onNoClick={this.closeLaunchDetailsModal}
                        teamId={this.props.teamId}
                    />
                )}

                <TaskListContainer
                    completeMessage={completeMessage}
                    description="Complete these tasks to help the team admin get set up for launch."
                    taskListType={taskListTypes.Team}
                    teamId={this.props.teamId}
                    title="Tasks for RH Launch"
                >
                    {this.renderTaskItems()}
                </TaskListContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: AppStore, ownProps: IRhLaunchTaskListBaseProps) => {
    const completedTasks = completedTasksSelector(state);

    const props = {
        launchDetailsComplete: completedTasks.some(
            (x) => x.taskItemId === taskItemIds.CompleteLaunchDetails
        ),
        launchDetailsPath: `${TEAMS_DASHBOARD_PATH.replace(
            ':teamId',
            ownProps.teamId
        )}?editLaunchDetails`,
        rhLaunchStateComplete: completedTasks.some(
            (x) => x.taskItemId === taskItemIds.SetRhLaunchComplete
        ),
        scheduleTeamAdminSetupCallComplete: completedTasks.some(
            (x) => x.taskItemId === taskItemIds.ScheduleTeamAdminSetupCall
        ),
        taskListComplete: false,
    };

    props.taskListComplete =
        props.scheduleTeamAdminSetupCallComplete && props.launchDetailsComplete;

    return props;
};

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    transitionToTeamAdminLaunch: async (teamId: string) =>
        dispatch(transitionToTeamAdminLaunch(teamId)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(RhLaunchTaskList));
