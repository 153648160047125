import { doesMeetDependentOtherCoverageAgeMinimum } from 'constants/otherCoverageEligibilities';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import MultiSelectButtons from 'pages/survey/multiSelectButtons/MultiSelectButtons';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveHouseholdMember } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { nameof } from 'utilities';

const IsEmployedForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { householdMembers: savedHouseholdMembers, member } = useSelector((state: AppStore) => ({
        householdMembers: state.surveyState.household.members?.filter((m) =>
            doesMeetDependentOtherCoverageAgeMinimum(m.dateOfBirth?.getAge())
        ),
        member: { ...state.surveyState.member, isRequired: true },
    }));
    const [householdMembers, setHouseholdMembers] = useState(savedHouseholdMembers ?? []);
    const onSelection = useCallback(
        (selectedMember) => {
            const index = householdMembers.findIndex(
                (x) => x.householdMemberId === selectedMember.householdMemberId
            );
            selectedMember.isEmployed = !selectedMember.isEmployed;
            householdMembers[index] = selectedMember;
            setHouseholdMembers([...householdMembers]);
        },
        [householdMembers]
    );
    const onSubmit = useCallback(() => {
        householdMembers.forEach((memberToSave) => {
            if (!memberToSave.isEmployed) {
                memberToSave.isEmployed = false;
            }
            dispatch(saveHouseholdMember(memberToSave));
        });
        commonProps.onSubmit();
    }, [commonProps, dispatch, householdMembers]);
    const members = useMemo(() => [member, ...householdMembers], [householdMembers, member]);
    return (
        <SurveyFormWrapper {...commonProps} onSubmit={onSubmit}>
            {children}
            <FormTitle description="Regardless of where they work, select all that apply">
                Who in your household has a job?
            </FormTitle>
            <MultiSelectButtons
                isSelectedProp={nameof<typeof members[number]>('isEmployed')}
                onSelection={onSelection}
                options={members}
            />
        </SurveyFormWrapper>
    );
};

export default hot(module)(IsEmployedForm);
