import { AddCircleOutline } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';

type IAddHouseholdMemberContent = {
    form: JSX.Element;
    handleAddButtonClick: () => void;
    hhmLabel: string;
    setIsAddingNewHhm: ((arg0: boolean) => void) | undefined;
    showForm: boolean;
};

const AddHouseholdMemberContent = ({
    form,
    handleAddButtonClick,
    hhmLabel,
    setIsAddingNewHhm,
    showForm,
}: IAddHouseholdMemberContent) => (
    <Grid container>
        {!showForm && (
            <Button color="secondary" onClick={handleAddButtonClick} variant="contained">
                <AddCircleOutline />
                &nbsp;&nbsp;Add {hhmLabel}
            </Button>
        )}
        <Collapse in={showForm}>
            <Card sx={{ marginBottom: 0, paddingBottom: 3, paddingTop: 2, paddingX: 2 }}>
                <Grid container>
                    <Grid
                        container
                        direction="row"
                        item
                        justifyContent="space-between"
                        marginBottom={2}
                    >
                        <Typography variant="h5">New {hhmLabel}</Typography>
                        <Button onClick={() => setIsAddingNewHhm?.(false)} variant="text">
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ marginBottom: 4 }} />
                    </Grid>
                    <Grid item>{form}</Grid>
                </Grid>
            </Card>
        </Collapse>
    </Grid>
);

export default hot(module)(AddHouseholdMemberContent);
