import {
    PATCH_USER_TEAM_BENEFIT_TERM_DETAIL_ACTION,
    addOrPatchUserTeamBenefitTermDetail,
} from 'actions/userTeamBenefitTermDetail/addOrPatchUserTeamBenefitTermDetail';
import { pageUserTermDetails } from 'actions/userTeamBenefitTermDetail/pageUserTermDetails';
import {
    GroupLevels,
    TeamBenefitTypes,
    UserTeamBenefitTermDetailStatuses,
} from 'api/generated/enums';
import {
    IManageMemberUserDto,
    ITeamBenefit,
    ITeamBenefitDto,
    ITeamBenefitTermDetail,
    ITeamBenefitTermDetailDto,
    ITeamBenefitWithTermDetailsDto,
    IUserTeamBenefitTermDetail,
    IUserTeamBenefitTermDetailHouseholdMember,
    IUserTermDetailManageMemberDto,
    UserTeamBenefitTermDetailHouseholdMember,
} from 'api/generated/models';
import { EditTeamBenefit } from 'api/generated/permissions';
import ConfirmationModal from 'components/ConfirmationModal';
import DateTextRangeField from 'components/DateTextRangeField';

import IconTooltip from 'components/IconTooltip';
import InformationIconTooltip from 'components/InformationIconTooltip';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import HeaderDropdown from 'components/headerDropdown/HeaderDropdown';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { isEqual } from 'lodash';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { Cell, HeaderProps } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivityWithParams } from 'selectors/activity';
import { enumToNameValueArray, getEnumNames, hasValue } from 'utilities';
import { formatCurrency, formatDateForApi, formatDateForDisplay } from 'utilities/format';
import { onChange, onEnterPress } from 'utilities/forms';
import { getDisplayFirstNameWithAge } from 'utilities/household';
import { DISPLAY_DATE_FORMAT, toMomentOrUndefined } from 'utilities/moment';
import { CustomColumnCell } from 'utilities/reactTable';
import { array, object, string } from 'yup';

export const MEMBER_STATUS_OPTIONS = getEnumNames(UserTeamBenefitTermDetailStatuses, startCase);

type ITooltipProps = {
    teamBenefit: ITeamBenefit | ITeamBenefitDto | undefined;
    userTeamBenefit: IUserTermDetailManageMemberDto | undefined;
};

export type ITeamBenefitMemberRowData = {
    benefit: IUserTermDetailManageMemberDto | undefined;
    teamBenefit: ITeamBenefitWithTermDetailsDto | undefined;
    teamBenefitTermDetailId: string | undefined;
};

const isDisabledByStatus = (statusId: UserTeamBenefitTermDetailStatuses | undefined) =>
    [UserTeamBenefitTermDetailStatuses.Waived, UserTeamBenefitTermDetailStatuses.Unknown].contains(
        statusId
    ) || !hasValue(statusId);

export const StatusHeader = ({
    column: { id, filterValue, setFilter },
    setSortBy,
    state: { sortBy },
}: HeaderProps<ITeamBenefitMemberRowData>) => (
    <HeaderDropdown
        columnId={id}
        id="status-filter-dropdown"
        infoTooltip={
            <ul className="text-left pl-3 pr-1 my-2">
                <li>
                    <strong>Waived:</strong> The member has declined this benefit.
                </li>
                <li>
                    <strong>Submitted:</strong> Application submitted to the carrier, the member is
                    not yet enrolled in the plan.
                </li>
                <li>
                    <strong>Enrolled:</strong> The member is enrolled and covered in this plan.
                </li>
                <li>
                    <strong>Offered:</strong> This member is being offered the benefit, but they
                    have not made a decision to enroll in it or not.
                </li>
                <li>
                    <strong>Unknown:</strong> This member has not yet declared their intent.
                </li>
            </ul>
        }
        options={MEMBER_STATUS_OPTIONS}
        selected={filterValue}
        setSelected={setFilter}
        setSorted={setSortBy}
        sorted={sortBy}
        title="Status"
    />
);

const USER_TEAM_BENEFIT_STATUS_OPTIONS = (enumToNameValueArray(UserTeamBenefitTermDetailStatuses, {
    formatName: startCase,
}) as unknown) as { name: string; value: number }[];

const getIsWithinEligibilityWaitingDays = (
    hireDate: string | undefined,
    eligibilityWaitingDays: number | undefined
) => {
    const isHireDateAndEligibilityWaitingDaysValid =
        hasValue(hireDate) && hasValue(eligibilityWaitingDays);

    if (isHireDateAndEligibilityWaitingDaysValid) {
        const hireDateMoment = toMomentOrUndefined(formatDateForDisplay(hireDate));
        const currentDate = moment();
        return currentDate.diff(hireDateMoment, 'days') <= eligibilityWaitingDays;
    }
    return false;
};

const getPatchUserTermDetailSelector = (user: IManageMemberUserDto | undefined) => (
    state: AppStore
) => ({
    canEditTeamBenefits: hasSomePermissions(state, EditTeamBenefit),
    isLoading: hasApiActivityWithParams(state, PATCH_USER_TEAM_BENEFIT_TERM_DETAIL_ACTION, {
        userId: user?.userId as string,
    }),
    paginationParams: state.manageMembersState,
});

export const IndicatorCell = ({
    row: {
        original: { teamBenefit, benefit },
    },
}: Cell<ITeamBenefitMemberRowData, unknown>) => {
    const user = benefit?.user;
    const hireDate = user?.hireDate;
    const eligibilityWaitingDays = teamBenefit?.eligibilityWaitingDays;
    const isWithinEligibilityWaitingDays = useMemo(
        () => getIsWithinEligibilityWaitingDays(hireDate, eligibilityWaitingDays),
        [eligibilityWaitingDays, hireDate]
    );

    return (
        <React.Fragment>
            {isWithinEligibilityWaitingDays && (
                <IconTooltip
                    icon="warning"
                    title="Member is within the benefit eligibility waiting period."
                    variant="warning"
                ></IconTooltip>
            )}
        </React.Fragment>
    );
};

export const NameCell = ({
    row: {
        original: { benefit },
    },
}: CustomColumnCell<ITeamBenefitMemberRowData, string>) => {
    const user = benefit?.user;
    const { teamId, userId } = user as IManageMemberUserDto;
    const profilePath = generatePath(TEAMS_PEOPLE_PROFILE_PATH, { teamId, userId });

    return (
        <Link data-cy="person-name" to={profilePath}>
            <div>{`${user?.firstName} ${user?.lastName}`}</div>
        </Link>
    );
};

export const StatusCell = ({
    row: {
        original: { benefit, teamBenefit, teamBenefitTermDetailId },
    },
}: Cell<ITeamBenefitMemberRowData, unknown>) => {
    const dispatch = useThunkDispatch();
    const user = benefit?.user;
    const { canEditTeamBenefits, isLoading, paginationParams } = useSelector(
        getPatchUserTermDetailSelector(user)
    );

    const eligibilityWaitingDays = teamBenefit?.eligibilityWaitingDays;
    const userTeamBenefitStatusId = benefit?.statusId ?? UserTeamBenefitTermDetailStatuses.Unknown;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [newStatusId, setNewStatusId] = useState(userTeamBenefitStatusId);

    useEffect(() => {
        setNewStatusId(benefit?.statusId ?? UserTeamBenefitTermDetailStatuses.Unknown);
    }, [benefit]);

    const isWithinEligibilityWaitingDays = useMemo(
        () => getIsWithinEligibilityWaitingDays(user?.hireDate, eligibilityWaitingDays),
        [eligibilityWaitingDays, user]
    );

    const save = useCallback(
        async (newUserTeamBenefitStatusId = newStatusId) => {
            try {
                await dispatch(
                    addOrPatchUserTeamBenefitTermDetail(teamBenefitTermDetailId, user?.userId, {
                        statusId: newUserTeamBenefitStatusId,
                    })
                );
            } catch (error) {
                setNewStatusId(userTeamBenefitStatusId);
            }
            await dispatch(
                pageUserTermDetails(
                    benefit?.teamBenefitTermDetailId ?? '',
                    user?.teamId ?? '',
                    paginationParams
                )
            );
            setShowConfirmationModal(false);
        },
        [
            benefit?.teamBenefitTermDetailId,
            dispatch,
            newStatusId,
            paginationParams,
            teamBenefitTermDetailId,
            user?.teamId,
            user?.userId,
            userTeamBenefitStatusId,
        ]
    );

    const onStatusChange = useCallback(
        async (newUserTeamBenefitStatusId: UserTeamBenefitTermDetailStatuses) => {
            const newStatusValue = Number(newUserTeamBenefitStatusId);
            setNewStatusId(newStatusValue);
            if (
                newStatusValue === UserTeamBenefitTermDetailStatuses.Enrolled &&
                isWithinEligibilityWaitingDays
            ) {
                setShowConfirmationModal(true);
            } else {
                await save(newUserTeamBenefitStatusId);
            }
        },
        [isWithinEligibilityWaitingDays, save]
    );

    const onNoClick = () => {
        setShowConfirmationModal(false);
        setNewStatusId(userTeamBenefitStatusId);
    };
    return (
        <Skeleton count={1} height="56px" isEnabled={isLoading} width="120px">
            {showConfirmationModal && (
                <ConfirmationModal
                    body="This person is still in the waiting period, are you sure?"
                    onNoClick={onNoClick}
                    onYesClick={save}
                    showActivity={isLoading}
                    title="Enroll member?"
                />
            )}
            <Select
                data-cy="status-dropdown"
                disabled={!canEditTeamBenefits}
                id={`status-dropdown-${user?.userId}`}
                items={USER_TEAM_BENEFIT_STATUS_OPTIONS}
                onChange={onChange(onStatusChange)}
                optionText="name"
                optionValue="value"
                placeholder="None"
                value={newStatusId}
            />
        </Skeleton>
    );
};

export const CoverageLevelCell = ({
    row: {
        original: { benefit },
    },
}: Cell<ITeamBenefitMemberRowData, unknown>) => (
    <Col className="text-center">{GroupLevels[benefit?.coverageLevelId ?? GroupLevels.UK]}</Col>
);

const getCostPeriodById = (
    teamBenefitTermDetailId: string | undefined,
    teamBenefit: ITeamBenefit | ITeamBenefitWithTermDetailsDto | undefined
) =>
    teamBenefit?.teamBenefitTermDetails?.find(
        (c: { id: string | undefined }) => c.id === teamBenefitTermDetailId
    );

const getDateForAgeCalculation = (
    userTeamBenefit: IUserTermDetailManageMemberDto | undefined,
    costPeriod: ITeamBenefitTermDetail | ITeamBenefitTermDetailDto | undefined
) =>
    userTeamBenefit?.coverageStartDate
        ?.toMomentDate()
        .isAfter(costPeriod?.startDate?.toMomentDate())
        ? userTeamBenefit?.coverageStartDate
        : costPeriod?.startDate;

const getCostCalculationFormattedDate = (
    userTeamBenefit: IUserTermDetailManageMemberDto,
    teamBenefit: ITeamBenefit | ITeamBenefitDto
) =>
    formatDateForDisplay(
        getDateForAgeCalculation(
            userTeamBenefit,
            getCostPeriodById(userTeamBenefit?.teamBenefitTermDetailId, teamBenefit)
        )
    );

export const HouseholdMembersCoveredCell = ({
    row: {
        original: { benefit, teamBenefit, teamBenefitTermDetailId },
    },
}: Cell<ITeamBenefitMemberRowData, unknown>) => {
    const dispatch = useThunkDispatch();
    const user = benefit?.user;
    const householdMembers = benefit?.allHouseholdMembers;
    const { canEditTeamBenefits, isLoading, paginationParams } = useSelector(
        getPatchUserTermDetailSelector(user)
    );
    const [selectedHouseholdMembers, setSelectedHouseholdMembers] = useState<string[]>([]);
    useEffect(() => {
        setSelectedHouseholdMembers(
            benefit?.coveredHouseholdMembers?.map((x) => x.householdMemberId) ?? []
        );
    }, [benefit?.coveredHouseholdMembers, benefit?.allHouseholdMembers]);
    const sortedHouseholdMembersWithAge = useMemo(() => {
        let relativeDate: string | undefined;
        if (
            teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId ===
            TeamBenefitTypes.LifeInsurance
        ) {
            const costPeriod = getCostPeriodById(benefit?.teamBenefitTermDetailId, teamBenefit);
            relativeDate = getDateForAgeCalculation(benefit, costPeriod);
        }
        return orderBy(
            householdMembers?.map((member) => ({
                ...member,
                age: member?.dateOfBirth?.getAge(relativeDate),
                id: member.householdMemberId,
                nameAndAge: getDisplayFirstNameWithAge(member, relativeDate),
            })),
            'age',
            'desc'
        );
    }, [benefit, householdMembers, teamBenefit]);
    const save = useCallback(async () => {
        if (
            !isEqual(
                benefit?.coveredHouseholdMembers?.map((x) => x.householdMemberId),
                selectedHouseholdMembers
            )
        ) {
            await dispatch(
                addOrPatchUserTeamBenefitTermDetail(teamBenefitTermDetailId, user?.userId, {
                    userTeamBenefitTermDetailHouseholdMembers: selectedHouseholdMembers.map<
                        UserTeamBenefitTermDetailHouseholdMember
                    >(
                        (member) =>
                            new UserTeamBenefitTermDetailHouseholdMember({
                                householdMemberId: member,
                                userTeamBenefitTermDetailId: teamBenefit?.id as string,
                            } as IUserTeamBenefitTermDetailHouseholdMember)
                    ),
                } as IUserTeamBenefitTermDetail)
            );
            await dispatch(
                pageUserTermDetails(
                    benefit?.teamBenefitTermDetailId ?? '',
                    user?.teamId ?? '',
                    paginationParams
                )
            );
        }
    }, [
        benefit?.coveredHouseholdMembers,
        benefit?.teamBenefitTermDetailId,
        dispatch,
        paginationParams,
        selectedHouseholdMembers,
        teamBenefit?.id,
        teamBenefitTermDetailId,
        user?.teamId,
        user?.userId,
    ]);
    if (!sortedHouseholdMembersWithAge.length) {
        return <span data-cy="household-members-covered-dropdown">None</span>;
    }
    return (
        <Skeleton count={1} height="56px" isEnabled={isLoading} width="120px">
            <Select
                data-cy="household-members-covered-dropdown"
                disabled={!canEditTeamBenefits || isDisabledByStatus(benefit?.statusId)}
                items={sortedHouseholdMembersWithAge}
                onBlur={save}
                onChange={onChange(setSelectedHouseholdMembers)}
                optionText="nameAndAge"
                optionValue="id"
                placeholder="None"
                SelectProps={{ multiple: true, native: false, onClose: save }}
                value={selectedHouseholdMembers}
            />
        </Skeleton>
    );
};

export const CoverageStartAndEndDatesCell = ({
    row: {
        original: { benefit, teamBenefit, teamBenefitTermDetailId },
    },
}: Cell<ITeamBenefitMemberRowData, unknown>) => {
    const dispatch = useThunkDispatch();
    const user = benefit?.user;
    const { canEditTeamBenefits, isLoading, paginationParams } = useSelector(
        getPatchUserTermDetailSelector(user)
    );
    const [coverageStartAndEndDates, setCoverageStartAndEndDates] = useState<
        string[] | undefined
    >();
    const teamBenefitTermDetail = teamBenefit?.teamBenefitTermDetails?.find(
        (x) => x.id === teamBenefitTermDetailId
    );

    const DATE_RANGE_ARR_LENGTH = 2;
    const schema = object({
        coverageStartAndEndDates: array()
            .of(string())
            .length(DATE_RANGE_ARR_LENGTH)
            .test('isBefore', 'Start Date must be before End Date', (dates) => {
                const firstDate = new Date(dates?.[0] as string);
                const secondDate = new Date(dates?.[1] as string);

                return firstDate < secondDate;
            })
            .test('inTerm', 'Dates must be within the selected term', (dates) => {
                const firstDate = new Date(dates?.[0] as string);
                const secondDate = new Date(dates?.[1] as string);

                return (
                    firstDate >= new Date(teamBenefitTermDetail?.startDate as string) &&
                    secondDate <= new Date(teamBenefitTermDetail?.endDate as string)
                );
            }),
    });
    const { errors, validate } = useForm(schema);

    useEffect(() => {
        setCoverageStartAndEndDates([
            formatDateForDisplay(benefit?.coverageStartDate) ?? '',
            formatDateForDisplay(benefit?.coverageEndDate) ?? '',
        ]);
    }, [benefit?.coverageEndDate, benefit?.coverageStartDate]);

    const save = async () => {
        const coverageStartDate = coverageStartAndEndDates?.[0] || '';
        const coverageEndDate = coverageStartAndEndDates?.[1] || '';

        if (
            formatDateForDisplay(benefit?.coverageEndDate) !== coverageEndDate ||
            formatDateForDisplay(benefit?.coverageStartDate) !== coverageStartDate
        ) {
            const { isValid } = await validate({
                coverageStartAndEndDates,
                context: {
                    termEnd: formatDateForDisplay(teamBenefitTermDetail?.endDate),
                    termStart: formatDateForDisplay(teamBenefitTermDetail?.startDate),
                },
            });
            if (isValid) {
                await dispatch(
                    addOrPatchUserTeamBenefitTermDetail(teamBenefitTermDetailId, user?.userId, {
                        coverageEndDate: formatDateForApi(coverageEndDate),
                        coverageStartDate: formatDateForApi(coverageStartDate),
                    })
                );
                await dispatch(
                    pageUserTermDetails(
                        benefit?.teamBenefitTermDetailId ?? '',
                        user?.teamId ?? '',
                        paginationParams
                    )
                );
            }
        }
    };

    const displayedStartDateText = hasValue(coverageStartAndEndDates?.[0])
        ? `${moment(coverageStartAndEndDates?.[0]).format(DISPLAY_DATE_FORMAT)}`
        : '';

    const displayedEndDateText = hasValue(coverageStartAndEndDates?.[1])
        ? `${moment(coverageStartAndEndDates?.[1]).format(DISPLAY_DATE_FORMAT)}`
        : '';

    const onDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const dateTimeRangeArray = e.target.value.split('-');

        if (
            toMomentOrUndefined(dateTimeRangeArray[0]) &&
            toMomentOrUndefined(dateTimeRangeArray[1])
        ) {
            setCoverageStartAndEndDates(dateTimeRangeArray);
        }
    };

    return (
        <React.Fragment>
            <DateTextRangeField
                className="mb-0"
                data-cy="coverage-start-and-end-date"
                disabled={!canEditTeamBenefits || isDisabledByStatus(benefit?.statusId)}
                errors={errors?.coverageStartAndEndDates}
                id={`coverage-start-and-end-date-${user?.userId}`}
                isLoading={isLoading}
                name="coverageStartAndEndDates"
                onBlur={save}
                onChange={onDateChange}
                onKeyPress={onEnterPress(save)}
                value={`${displayedStartDateText}-${displayedEndDateText}`}
            />
        </React.Fragment>
    );
};
export const EmployerCostTooltipContent = ({ userTeamBenefit, teamBenefit }: ITooltipProps) => (
    <div>
        <p>{userTeamBenefit?.employerCostVariableEquation}</p>
        <p>{userTeamBenefit?.employerCostEquation}</p>
        {teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId ===
            TeamBenefitTypes.LifeInsurance && (
            <p>
                Ages calculated based on this date:{' '}
                {getCostCalculationFormattedDate(
                    userTeamBenefit as IUserTermDetailManageMemberDto,
                    teamBenefit
                )}
            </p>
        )}
    </div>
);

export const EmployeeCostTooltipContent = ({ userTeamBenefit }: ITooltipProps) => (
    <div>
        <p>{userTeamBenefit?.employeeCostVariableEquation}</p>
        <p>{userTeamBenefit?.employeeCostEquation}</p>
    </div>
);

export const UserTeamBenefitCostCell = ({
    column: { TooltipContent },
    row: {
        original: { benefit, teamBenefit },
    },
    value,
}: CustomColumnCell<
    ITeamBenefitMemberRowData,
    number,
    { TooltipContent: React.ComponentType<ITooltipProps> }
>) => {
    const showTooltip =
        value !== undefined &&
        TooltipContent &&
        ![GroupLevels.UK, GroupLevels.W].contains(benefit?.coverageLevelId) &&
        benefit?.statusId !== UserTeamBenefitTermDetailStatuses.Unknown;

    return (
        <div>
            {benefit?.lastErrorMessage ? (
                <InformationIconTooltip
                    className="dripicons-warning text-warning"
                    title={benefit.lastErrorMessage}
                ></InformationIconTooltip>
            ) : (
                <React.Fragment>
                    {formatCurrency(value, { emptyIfNaN: true, preserveDecimal: true })}
                    {showTooltip && (
                        <InformationIconTooltip
                            className="ml-1"
                            title={
                                <TooltipContent
                                    teamBenefit={teamBenefit}
                                    userTeamBenefit={benefit}
                                />
                            }
                        />
                    )}
                </React.Fragment>
            )}
        </div>
    );
};
