import { Card, CardContent } from '@mui/material';
import { useRhEmail } from 'hooks/useRhEmail';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const EnrollmentDeadlinePassedCard = () => {
    const email = useRhEmail();
    return (
        <Card>
            <CardContent>
                <Row className="align-items-center justify-content-space-between px-2">
                    <Col>
                        <p className="my-0">
                            The deadline to make your benefits selection has passed. Please reach
                            out to <a href={`mailto:${email}`}>{email}</a> if you need help.
                        </p>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};

export default hot(module)(EnrollmentDeadlinePassedCard);
