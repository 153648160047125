import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Stack, Typography } from '@mui/material';
import { PlanTypeIds } from 'api/generated/enums';
import { IComparedSelectedPlan, ISelectedPlan } from 'api/generated/models';
import InformationIconTooltip from 'components/InformationIconTooltip';
import useUserProps from 'hooks/useUserProps';
import { getAreMarketplaceRatesAvailable } from 'pages/dashboard/taskLists/member/tasks/keepOrChange/keepOrChangeSelectors';
import { LabelValue } from 'pages/shop/PlanCard';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';
import { isIchraPathwayType } from 'utilities/pathways';
import { getCostLabel, isMediShare } from 'utilities/selectedPlan';

const PLAN_COST_INFO_TOOLTIP =
    "These rates are quotes from the marketplace and aren't guaranteed to be your final premium; your final premium could be higher or lower";

const KeepOrChangeBenefitCardCost = ({
    isMedicare = false,
    selectedPlan,
}: {
    isMedicare?: boolean;
    selectedPlan: IComparedSelectedPlan | ISelectedPlan;
}) => {
    const { planTypeId, planPremiumWithCredits } = selectedPlan;
    const { year } = useUserProps();
    const { areMarketplaceRatesAvailable, isIchraPathway } = useSelector((state: AppStore) => ({
        areMarketplaceRatesAvailable: getAreMarketplaceRatesAvailable(state, year),
        isIchraPathway: isIchraPathwayType(state.pathwayDataForUser?.pathwayType),
    }));
    if (planPremiumWithCredits) {
        const isMarketplace = planTypeId === PlanTypeIds.Marketplace;
        const isMediSharePlanType = isMediShare(selectedPlan);
        const label = [PlanTypeIds.Ancillary, PlanTypeIds.CustomAncillary].includes(planTypeId)
            ? 'Estimated Cost'
            : `Listed ${getCostLabel(isMediSharePlanType)}`;
        return (
            <React.Fragment>
                <Stack alignItems="end" gap={1}>
                    <LabelValue
                        label={
                            <React.Fragment>
                                {label}{' '}
                                {!isIchraPathway && isMarketplace && areMarketplaceRatesAvailable && (
                                    <InformationIconTooltip title={PLAN_COST_INFO_TOOLTIP} />
                                )}
                            </React.Fragment>
                        }
                    >
                        <Typography fontSize="small" fontWeight="bold">
                            <React.Fragment>
                                {formatCurrency(planPremiumWithCredits, {
                                    preserveDecimal: isIchraPathway,
                                })}
                                /mo
                            </React.Fragment>
                        </Typography>
                        {isMarketplace && !areMarketplaceRatesAvailable && (
                            <HelpOutlineIcon color="secondary" />
                        )}
                    </LabelValue>
                    {isMedicare && isIchraPathway && (
                        <React.Fragment>
                            <Typography fontSize="small">
                                This is the monthly premium we currently have on file for your
                                Medicare plan.
                            </Typography>
                            <Typography fontSize="small">
                                If your monthly premium has changed, please reach out to
                                medicare@remodelhealth.com.
                            </Typography>
                        </React.Fragment>
                    )}
                </Stack>
            </React.Fragment>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(KeepOrChangeBenefitCardCost);
