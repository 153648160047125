import { Paper } from '@mui/material';
import { clearCustomQualitativeAnswers } from 'actions/user/clearCustomQualitativeAnswers';
import { clearQualitativeAnswers } from 'actions/user/clearQualitativeAnswers';
import { getCustomQuestionAnswers } from 'actions/user/getCustomQuestionAnswers';
import { getQualitativeAnswers } from 'actions/user/getQualitativeAnswers';
import { HealthInsuranceParts, OtherBenefits } from 'api/generated/enums';
import PageHeader from 'components/PageHeader';
import ProfileAttribute from 'components/ProfileAttribute';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import orderBy from 'lodash/orderBy';
import React, { ReactNode, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { enumArrayToNameArray } from 'utilities';

const QualitativeProfileAttribute = ({
    content,
    label,
    textToCopy = content,
}: {
    content: ReactNode;
    label: string | undefined;
    textToCopy?: ReactNode;
}) => (
    <ProfileAttribute ColProps={{ xs: 4 }} label={label} textToCopy={textToCopy}>
        <span className="text-left">{content}</span>
    </ProfileAttribute>
);

const UserQualitativePage = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const {
        customQuestionAnswers,
        qualitativeAnswers: {
            anyFeedbackAnswer,
            healthInsuranceAnswers,
            improveCurrentInsuranceAnswer,
            likeAboutCurrentInsuranceAnswer,
            otherBenefitsAnswers,
        },
    } = useSelector((state: AppStore) => ({
        customQuestionAnswers: state.customQuestionAnswers,
        qualitativeAnswers: state.qualitativeAnswers,
    }));
    useEffect(() => {
        dispatch(getQualitativeAnswers(userId));
        dispatch(getCustomQuestionAnswers(userId));
        return () => {
            dispatch(clearQualitativeAnswers());
            dispatch(clearCustomQualitativeAnswers());
        };
    }, [dispatch, userId]);

    const rankedInsurance = enumArrayToNameArray(
        orderBy(healthInsuranceAnswers, (h) => h.rank).map((h) => h.healthInsurancePartId),
        HealthInsuranceParts
    );
    const otherBenefitsString = enumArrayToNameArray(
        otherBenefitsAnswers?.map((o) => o.otherBenefitId),
        OtherBenefits,
        {
            nameMap: {
                Offers401k403b: '401(k)/403(b)',
            },
        }
    )?.join(', ');

    const answerContentContainer = (stringContent: string | undefined) => (
        <Container>{stringContent}</Container>
    );

    const customQuestionAttributes = customQuestionAnswers.map((a) => (
        <QualitativeProfileAttribute
            content={answerContentContainer(a.answer)}
            key={a.customQuestionId}
            label={a.customQuestion?.text}
        />
    ));

    const getRankedInsuranceString = (rankedInsurance: string[] | undefined) => {
        let rankedInsuranceString = '';
        rankedInsurance?.forEach(
            (name, index) => (rankedInsuranceString += `${index + 1}. ${name} `)
        );
        return rankedInsuranceString;
    };

    return (
        <Paper>
            <PageHeader variant="h4">Benefits Questions</PageHeader>
            <hr />
            <QualitativeProfileAttribute
                content={rankedInsurance?.map((name, index) => (
                    <div key={index}>{`${index + 1}. ${name}`}</div>
                ))}
                label="Rank Importance of Insurance"
                textToCopy={getRankedInsuranceString(rankedInsurance)}
            />
            <QualitativeProfileAttribute
                content={answerContentContainer(otherBenefitsString)}
                label="Other Benefits Would Like"
                textToCopy={otherBenefitsString}
            />
            <QualitativeProfileAttribute
                content={answerContentContainer(likeAboutCurrentInsuranceAnswer)}
                label="What do you like about your current health insurance?"
                textToCopy={likeAboutCurrentInsuranceAnswer}
            />
            <QualitativeProfileAttribute
                content={answerContentContainer(improveCurrentInsuranceAnswer)}
                label="What would you like to see improved about your current health insurance?"
                textToCopy={improveCurrentInsuranceAnswer}
            />
            <QualitativeProfileAttribute
                content={answerContentContainer(anyFeedbackAnswer)}
                label="Please provide any feedback you think would be helpful."
                textToCopy={anyFeedbackAnswer}
            />
            {customQuestionAttributes}
        </Paper>
    );
};

export default hot(module)(UserQualitativePage);
