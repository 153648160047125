import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import HeaderTitle from 'components/HeaderTitle';
import Select from 'components/Select';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { getYears } from 'utilities/year';

const items = getYears(() => 1).map((x) => ({ name: `${x}` }));

const DashboardCardHeader = ({
    actionItems,
    description,
    header,
    includeYearDropDown,
    onChange,
    OverrideActionButtons,
    selectedYear,
}: {
    OverrideActionButtons?: React.ComponentType;
    actionItems?: IActionButtonItems;
    description?: string;
    header: string;
    includeYearDropDown?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    selectedYear?: string;
}) => {
    const showActionButtons = actionItems || OverrideActionButtons;

    return (
        <React.Fragment>
            <Row className="justify-content-between">
                <Col
                    className={`align-items-center ${
                        showActionButtons ? 'flex flex-column flex-sm-row' : ''
                    }`}
                    sm="auto"
                    xs="12"
                >
                    <HeaderTitle>{header}</HeaderTitle>
                    {description && <p className="text-muted m-0">{description}</p>}
                    {includeYearDropDown && (
                        <Select
                            data-cy="selected-admin-widget-year"
                            items={items}
                            name="selectedYear"
                            onChange={onChange}
                            optionText="name"
                            optionValue="name"
                            value={selectedYear}
                        />
                    )}
                </Col>
                {showActionButtons && (
                    <Col className="flex flex-column flex-sm-row" sm="auto" xs="12">
                        {OverrideActionButtons ? (
                            <OverrideActionButtons />
                        ) : (
                            <ActionButtons
                                ButtonProps={{
                                    xsClassName: 'mb-2',
                                }}
                                dropdownWhenLengthGreaterThan={0}
                                items={actionItems as IActionButtonItems}
                            />
                        )}
                    </Col>
                )}
            </Row>
        </React.Fragment>
    );
};

export default hot(module)(DashboardCardHeader);
