import { PathwayTypes, PathwayUserTags, UserInfoStatus } from 'api/generated/enums';
import { IUser } from 'api/generated/models';
import classNames from 'classnames';
import IconTooltip from 'components/IconTooltip';
import { COMMON_HEADER_DROPDOWN_TABLE_PROPS } from 'components/headerDropdown/HeaderDropdown';
import { sumBy } from 'lodash';
import { PathwayTagsCell } from 'pages/pathwayBlueprint/PathwayTableCellTags';
import {
    ActiveReimbursementCell,
    ActiveWageUpCell,
    AssignCell,
    AssignHeader,
    CustomPlanCostCell,
    EmployeeCostCell,
    IPathwayRowData,
    IndicatorsCell,
    NameCell,
    PathwayTableInfoCell,
    PathwayWageUpActionButtons,
    PlanCostCell,
    PlanCostHeader,
    ProjectedReimbursementCell,
    ProjectedWageUpCell,
    StateCell,
    TierCell,
} from 'pages/pathwayBlueprint/PathwayTableCells';
import React, { ReactNode } from 'react';
import { Cell, Column } from 'react-table';
import { roundTwoDecimals } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { CustomColumn } from 'utilities/reactTable';

const OVERFLOW_VISIBLE_CLASS = 'overflow-visible';

export const CENTER_COLUMN_PROPS = {
    className: 'justify-content-center',
    headerClassName: 'justify-content-center',
};

export const NAME_COLUMN_ID = 'name';
export const NAME_FILTER_ID = 'nameFilter';
export const STATE_COLUMN_ID = 'state';
export const STATE_FILTER_ID = 'stateFilter';

const CalculationErrorIcon = ({ errorMessage }: { errorMessage: string }) => (
    <IconTooltip className="ml-1" icon="warning" title={errorMessage} variant="danger" />
);

export const getCellBasedOnInfoStatusAndPathwayType = <
    T extends { pathwayTypeId?: PathwayTypes; user?: IUser },
    U
>(
    CellComponent: React.FC<Cell<T, U>> | ((cellData: Cell<T, U>) => ReactNode),
    hideWithBasicInfo = true
) => (cellData: Cell<T, U>): ReactNode =>
    cellData.row.original.user?.userInfoStatus === UserInfoStatus.Basic &&
    (hideWithBasicInfo ||
        ![PathwayTypes.Waived, PathwayTypes.Custom, PathwayTypes.IchraCustom].contains(
            cellData.row.original.pathwayTypeId
        ))
        ? ''
        : CellComponent(cellData);

export const getCellBasedOnHasError = <T,>(
    CellComponent:
        | React.FC<Cell<IPathwayRowData, T>>
        | ((cellData: Cell<IPathwayRowData, T>) => ReactNode)
    // eslint-disable-next-line react/display-name
) => (cellData: Cell<IPathwayRowData, T>): ReactNode =>
    cellData.row.original.hasError ? (
        <CalculationErrorIcon errorMessage={cellData.row.original.errorMessage as string} />
    ) : (
        CellComponent(cellData)
    );

export const getAssignColumn = (
    pathwayId: string,
    selection: string[],
    setSelection: (newSelection: string[]) => void
): CustomColumn<
    IPathwayRowData,
    { pathwayId: string; selection: string[]; setSelection: (newSelection: string[]) => void }
> => ({
    ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
    pathwayId,
    selection,
    setSelection,
    Cell: AssignCell,
    className: OVERFLOW_VISIBLE_CLASS,
    disableResizing: true,
    Header: AssignHeader,
    id: 'assign',
    width: 115,
});

export const commonColumns: Column<IPathwayRowData>[] = [
    {
        Cell: IndicatorsCell,
        disableResizing: true,
        id: 'indicators',
        width: 30,
    },
    {
        accessor: (up) => `${up.user?.lastName} ${up.user?.firstName}`,
        Cell: NameCell,
        filter: NAME_FILTER_ID,
        Header: 'Name',
        id: NAME_COLUMN_ID,
        width: 100,
    },
    {
        ...CENTER_COLUMN_PROPS,
        Cell: getCellBasedOnInfoStatusAndPathwayType(StateCell),
        className: classNames(OVERFLOW_VISIBLE_CLASS, 'justify-content-center'),
        filter: STATE_FILTER_ID,
        Header: 'State',
        id: STATE_COLUMN_ID,
        width: 110,
    },
    {
        ...CENTER_COLUMN_PROPS,
        Cell: PathwayTableInfoCell,
        Header: 'Info Level', // TODO: Use header from people table
        id: 'infoLevel',
        width: 70,
    },
    {
        ...CENTER_COLUMN_PROPS,
        Cell: getCellBasedOnInfoStatusAndPathwayType(TierCell),
        className: classNames(OVERFLOW_VISIBLE_CLASS, 'justify-content-center'),
        Header: 'Tier',
        id: 'tier',
        width: 110,
    },
];

export const getTagsColumn = <T extends object>(
    accessor: (row: T) => PathwayUserTags
): Column<T> => ({
    ...CENTER_COLUMN_PROPS,
    accessor,
    Cell: getCellBasedOnInfoStatusAndPathwayType(PathwayTagsCell),
    Header: 'Tags',
    id: 'tags',
    width: 160,
});

export const getPlanCostColumn = <T extends object>(
    accessor: (row: T) => number,
    isIchraCustom: boolean,
    isFlat?: boolean
): CustomColumn<T, { isFlat?: boolean; isIchraCustom: boolean }> => ({
    ...CENTER_COLUMN_PROPS,
    accessor,
    isFlat,
    isIchraCustom,
    Cell: getCellBasedOnHasError(isIchraCustom ? CustomPlanCostCell : PlanCostCell),
    Footer: isFlat
        ? ''
        : ({ data }) =>
              formatCurrency(
                  sumBy(data, (x) => roundTwoDecimals(accessor(x))),
                  { preserveDecimal: true }
              ),
    Header: isFlat ? '' : PlanCostHeader,
    id: 'planCost',
    width: 125,
});

export const getProjectedReimbursementColumn = <T extends object>(
    accessor: (row: T) => number
): Column<T> => ({
    ...CENTER_COLUMN_PROPS,
    accessor,
    Cell: getCellBasedOnHasError(ProjectedReimbursementCell),
    Footer: ({ data }) => formatCurrency(sumBy(data, accessor), { preserveDecimal: true }),
    Header: 'Projected Reimbursement',
    width: 110,
});

export const getProjectedWageUpColumn = <T extends object>(
    accessor: (row: T) => number
): Column<T> => ({
    ...CENTER_COLUMN_PROPS,
    accessor,
    Cell: getCellBasedOnHasError(ProjectedWageUpCell),
    Footer: ({ data }) => formatCurrency(sumBy(data, accessor)),
    Header: 'Projected Wage+',
    width: 110,
});

export const getActiveWageUpColumn = (
    accessor: (row: IPathwayRowData) => number
): Column<IPathwayRowData> => ({
    ...CENTER_COLUMN_PROPS,
    accessor,
    Cell: getCellBasedOnInfoStatusAndPathwayType(ActiveWageUpCell, false),
    Footer: ({ data }) => formatCurrency(sumBy(data, accessor)),
    Header: 'Active Wage+',
    width: 125,
});

export const getActiveReimbursementColumn = (
    accessor: (row: IPathwayRowData) => number
): Column<IPathwayRowData> => ({
    ...CENTER_COLUMN_PROPS,
    accessor,
    Cell: getCellBasedOnInfoStatusAndPathwayType(ActiveReimbursementCell, false),
    Footer: ({ data }) => formatCurrency(sumBy(data, accessor), { preserveDecimal: true }),
    Header: 'Active Reimbursement',
    width: 125,
});

export const getWageUpActionButtonsColumn = <T extends object>(
    isCostComparison = false
): CustomColumn<T, { isCostComparison?: boolean }> => ({
    isCostComparison,
    Cell: getCellBasedOnInfoStatusAndPathwayType(PathwayWageUpActionButtons, false),
    className: OVERFLOW_VISIBLE_CLASS,
    disableSortBy: false,
    id: 'wageUpActions',
    width: 120,
});

export const getEmployeeCostColumn = <T extends object>(
    accessor: (row: T) => number,
    isIchra: boolean
): Column<T> => ({
    ...CENTER_COLUMN_PROPS,
    accessor,
    Cell: getCellBasedOnHasError(EmployeeCostCell),
    Footer: ({ data }) => formatCurrency(sumBy(data, accessor), { preserveDecimal: isIchra }),
    Header: 'Employee Cost',
    width: 100,
});
