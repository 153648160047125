import { HouseholdMemberTypes, PlanTypeIds } from 'api/generated/enums';
import { ISelectedPlan } from 'api/generated/models';
import { ViewSelectedPlans } from 'api/generated/permissions';
import { PREMIUM } from 'constants/selectedPlans';
import isUndefined from 'lodash/isUndefined';
import { ColumnRowNameValue, IColumnRowNameValue } from 'pages/benefits/ColumnRowNameValue';
import React, { useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { formatCurrency, formatDateForDisplay, formatYesOrNo } from 'utilities/format';
import { getPlanTypeName } from 'utilities/plans';

type IOtherCoverageBenefitCardContentProps = {
    benefit: ISelectedPlan;
};

const buildLeftColumnFields = (
    { comment, individualDeductibleCost, planPremiumWithCredits, planTypeId }: ISelectedPlan,
    isAdvisor: boolean,
    isRh: boolean
) => {
    const leftColumn = [];
    if (hasValue(planPremiumWithCredits)) {
        leftColumn.unshift({
            name: PREMIUM,
            value: formatCurrency(planPremiumWithCredits, { preserveDecimal: true }),
        });
    }
    if (hasValue(individualDeductibleCost)) {
        leftColumn.unshift({
            name: 'Deductible',
            value: formatCurrency(individualDeductibleCost),
        });
    }
    if (isAdvisor || isRh) {
        leftColumn.unshift({
            'data-cy': 'plan-type',
            name: 'Plan Type',
            value: getPlanTypeName(planTypeId),
        });
    }
    if (comment) {
        leftColumn.push({
            name: 'Comment',
            value: comment,
        });
    }
    return leftColumn;
};

const OtherCoverageBenefitCardContent = ({ benefit }: IOtherCoverageBenefitCardContentProps) => {
    const { householdMembers, isRh, isAdvisor } = useSelector((state: AppStore) => ({
        householdMembers: state.householdMembers,
        isAdvisor: state.login.up.includes(ViewSelectedPlans),
        isRh: isRhSelector(state),
    }));
    const spouseName = useMemo(() => {
        let name: string | undefined = 'spouse';
        householdMembers.forEach((m) => {
            if (m.householdMemberTypeId === HouseholdMemberTypes.Spouse && m.haveDataForYear) {
                name = m.firstName;
            }
        });
        return name;
    }, [householdMembers]);
    const data: IColumnRowNameValue[] = useMemo(
        () => buildLeftColumnFields(benefit, isAdvisor, isRh),
        [benefit, isAdvisor, isRh]
    );
    const dateData: IColumnRowNameValue[] = useMemo(() => {
        const dateColumns = [];
        if (isRh && !isUndefined(benefit.isHsaEligible)) {
            dateColumns.push({
                name: 'Is HSA Eligible',
                value: formatYesOrNo(benefit.isHsaEligible),
            });
        }
        if (benefit.coverageStartDate) {
            dateColumns.push({
                'data-cy': 'effective-date',
                name: 'Effective Date',
                value: formatDateForDisplay(benefit.coverageStartDate),
            });
        }
        if (benefit.coverageEndDate) {
            dateColumns.push({
                'data-cy': 'termination-date',
                name: 'Termination Date',
                value: formatDateForDisplay(benefit.coverageEndDate),
            });
        }
        return dateColumns;
    }, [isRh, benefit.isHsaEligible, benefit.coverageEndDate, benefit.coverageStartDate]);
    if (!isRh && !isAdvisor) {
        let text;
        if (benefit.planTypeId === PlanTypeIds.SpouseEmployer) {
            text = `Spouse employer plans are not managed by Remodel Health. Please check with ${spouseName}'s employer for more details about the plan.`;
        } else {
            text =
                'Parent plans are not managed by Remodel Health. Please check with the policy holder for more details about the plan';
        }
        return (
            <React.Fragment>
                {dateData.length > 0 && (
                    <React.Fragment>
                        <ColumnRowNameValue data={[dateData]} />
                        <hr />
                    </React.Fragment>
                )}
                <Row className="mx-3 text-muted">{text}</Row>
                <hr />
            </React.Fragment>
        );
    }
    const rhViewData: IColumnRowNameValue[][] = [data, dateData];
    return (
        <React.Fragment>
            {rhViewData.length > 0 && (
                <React.Fragment>
                    <ColumnRowNameValue data={rhViewData} />
                    <hr />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default hot(module)(OtherCoverageBenefitCardContent);
