import Typography from 'components/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';

const IchraFlowTitle = ({ title }: { title: string }) => (
    <Typography marginX="auto" variant="h3">
        {title}
    </Typography>
);

export default hot(module)(IchraFlowTitle);
