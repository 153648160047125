import { AppStoreThunkDispatch, IAction } from 'actions/commonAction';
import { PathwayInputTypes } from 'api/generated/enums';
import { IMarketplacePlanDto, ISelectedPlan } from 'api/generated/models';
import { IBenefitSelectionState } from 'pages/dashboard/medicalBenefitSelection/benefitSelectionState';
import { AppStore } from 'reducers/appReducer';

export const SET_PLAN_FOR_BENEFIT_SELECTION = 'SET_PLAN_FOR_BENEFIT_SELECTION';
export const setPlanForBenefitSelection = (
    plan: IMarketplacePlanDto | ISelectedPlan | undefined
) => (dispatch: AppStoreThunkDispatch) =>
    dispatch<IAction<Partial<IBenefitSelectionState>>>({
        data: {
            plan,
        },
        type: SET_PLAN_FOR_BENEFIT_SELECTION,
    });

export const SET_REQUESTED_WAGE_UP = 'SET_REQUESTED_WAGE_UP';
export const setRequestedWageUp = (requestedWageUp: string) => (
    dispatch: AppStoreThunkDispatch,
    getState: () => AppStore
) => {
    const state = getState();
    const automaticApprovalCap = state.pathway?.pathwayInputs?.find(
        (x) => x.pathwayInputTypeId === PathwayInputTypes.AutomaticApprovalCap
    )?.value;
    return dispatch<IAction<Partial<IBenefitSelectionState>>>({
        data: {
            requestedWageUp,
            isAboveApprovalCap: Number(automaticApprovalCap) < Number(requestedWageUp),
        },
        type: SET_REQUESTED_WAGE_UP,
    });
};

export const CLEAR_BENEFITS_SELECTION_STATE = 'CLEAR_BENEFITS_SELECTION_STATE';
export const clearBenefitsSelectionState = (): IAction<IBenefitSelectionState> => ({
    data: {
        isAboveApprovalCap: false,
        plan: undefined,
        requestedWageUp: '',
    },
    type: CLEAR_BENEFITS_SELECTION_STATE,
});
