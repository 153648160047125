import { Divider, Link, Stack, Typography } from '@mui/material';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React from 'react';
import { hot } from 'react-hot-loader';

const WelcomeToRemodelHealthPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => (
    <Stack gap={3} maxWidth={MAX_WIDTH}>
        <IchraFlowTitle title="What is Remodel Health?" />
        <Typography>
            Your organization has partnered with Remodel Health to help facilitate your health
            benefits. What does this mean exactly?
        </Typography>
        <Typography>
            With the benefit of our online portal and our support teams you will be able to:
            <ul>
                <li>
                    See what your organization is offering to go towards the cost of your medical
                    insurance premiums.
                </li>
                <li>Choose a plan that fits your individual needs.</li>
                <li>
                    Talk to a benefits advisor if you need more personalized help in choosing your
                    health plan.
                </li>
                <li>
                    Access our ongoing Customer Success support team (once you are enrolled) who can
                    assist with your insurance related questions and concerns.
                </li>
            </ul>
        </Typography>
        <Typography>
            After you make your selection, our team will get you enrolled in your plan. In most
            cases, we will handle paying your plan premiums for you with automatic payroll
            deductions.
        </Typography>
        <Divider />
        <IchraFlowTitle title="What is an ICHRA?" />
        <Typography>
            The specific benefit your organization is offering is called an ICHRA (Individual
            Coverage Health Reimbursement Arrangement). An ICHRA is a type of HRA that allows your
            organization to reimburse your health care costs tax free. Most often this means your
            monthly premiums but in some cases can also include other health expenses as well. This
            is dependent on your employer.
        </Typography>
        <Typography>
            For more information on ICHRA in general and Remodel Health, check out our{' '}
            <Link href="https://help.remodelhealth.com/article/183-welcome-faqs">FAQ page</Link>.
        </Typography>
        <Typography fontWeight="bold">
            In the next step, we&apos;ll collect more information about you and your household. This
            will allow us to determine the maximum amount you will be able to get reimbursed and
            show you plan options, including what your cost would be. You&apos;ll have the
            opportunity to waive this benefit after reviewing your plan options.
        </Typography>
        <IchraFlowButtons currentTaskId={currentTask.globalId} />
    </Stack>
);

export default hot(module)(WelcomeToRemodelHealthPage);
