import { EnrollmentStatuses, RenewalDecisions, TaskItems, UserStatus } from 'api/generated/enums';
import { push } from 'connected-react-router';
import { taskListTypes } from 'constants/tasks';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import KeepOrChangeBenefitsModal from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitsModal';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { SHOP_PATH } from 'routers/routes';

type IKeepOrChangeBenefitsTaskProps = {
    complete: boolean | undefined;
    enabled: boolean | undefined;
};
const KeepOrChangeBenefitsTask = ({ complete, enabled }: IKeepOrChangeBenefitsTaskProps) => {
    const [showKeepOrChangeModal, setShowKeepOrChangeModal] = useState(false);
    const { user, yearlyUserInfo } = useUserProps();
    const isInRenewalUserStatus = user?.status === UserStatus.Renewing;
    const closeKeepOrChangeModal = useCallback(() => setShowKeepOrChangeModal(false), []);
    const openKeepOrChangeModal = useCallback(() => setShowKeepOrChangeModal(true), []);
    const dispatch = useThunkDispatch();

    const isBenefitSelectionAndChangeDecision =
        yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.BenefitsSelection &&
        yearlyUserInfo?.renewalDecision === RenewalDecisions.Change;
    const showShopButton = !complete && isBenefitSelectionAndChangeDecision;
    const hideButton =
        (complete && isBenefitSelectionAndChangeDecision) ||
        yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.PendingApplication;

    const action = () => (showShopButton ? dispatch(push(SHOP_PATH)) : openKeepOrChangeModal());

    let buttonText = 'Decide';
    if (showShopButton) {
        buttonText = 'Shop';
    }
    return (
        <TaskListItem
            action={action}
            buttonIcon={showShopButton ? <i className="dripicons-cart mr-2" /> : undefined}
            buttonText={buttonText}
            complete={complete}
            data-cy="keep-change-benefits-task"
            description="Choose to keep or change your benefits."
            enabled={enabled}
            showButton={enabled && isInRenewalUserStatus && !hideButton}
            showButtonWhenComplete
            taskId={TaskItems.KeepOrChangeBenefits_Renewing}
            taskType={taskListTypes.User}
            title="Select Major Medical Benefits"
        >
            {showKeepOrChangeModal && <KeepOrChangeBenefitsModal onHide={closeKeepOrChangeModal} />}
        </TaskListItem>
    );
};

export default hot(module)(KeepOrChangeBenefitsTask);
