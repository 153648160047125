import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const FormTitle = ({
    children,
    description = null,
}: {
    children: ReactNode;
    description?: ReactNode;
}) => (
    <Row className="justify-content-center pb-sm-5">
        <Col className="text-center">
            <Typography
                className="d-sm-block d-none"
                color={(theme) => theme.palette.primary.main}
                variant="h2"
            >
                {children}
            </Typography>
            <Typography
                className="d-sm-none d-block"
                color={(theme) => theme.palette.primary.main}
                variant="h3"
            >
                {children}
            </Typography>
            {description && <Row className="justify-content-center mt-3 mb-2">{description}</Row>}
        </Col>
    </Row>
);

export default hot(module)(FormTitle);
