import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import MultiSelectButtons from 'pages/survey/multiSelectButtons/MultiSelectButtons';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveQualitative } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const OtherBenefitsForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { options } = useSelector((state: AppStore) => ({
        options: state.surveyState.qualitative.otherBenefits,
    }));
    const onSelection = useCallback(
        (option) => {
            const otherBenefits = Array.from(options);
            const index = otherBenefits.findIndex((x) => x.text === option.text);
            option.isSelected = !option.isSelected;
            otherBenefits[index] = option;
            dispatch(saveQualitative({ otherBenefits }));
        },
        [dispatch, options]
    );
    return (
        <SurveyFormWrapper {...commonProps}>
            {children}
            <FormTitle>
                What other benefits would you like to see offered that you currently do not have
                access to?
            </FormTitle>
            <MultiSelectButtons
                displayProp="text"
                isInRow
                onSelection={onSelection}
                options={options}
            />
        </SurveyFormWrapper>
    );
};

export default hot(module)(OtherBenefitsForm);
