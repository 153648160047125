import { Card, CardContent } from '@mui/material';
import Button from 'components/Button';
import FormTitle from 'pages/survey/FormTitle';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { ReactNode } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const StepIntro = ({
    description,
    isLoading,
    onSkip,
    onSubmit,
    skipText = 'Skip',
    title,
}: {
    description: ReactNode;
    isLoading?: boolean;
    onSkip?: () => void;
    onSubmit?: ((event: React.FormEvent) => boolean) | ((event: React.FormEvent) => void);
    skipText?: string;
    title: ReactNode;
}) => (
    <SurveyFormWrapper hideFooter ignoreSubmit onSubmit={onSubmit}>
        <Card className="mt-3 mt-sm-0">
            <CardContent className="pt-sm-6">
                <FormTitle>{title}</FormTitle>
                <div className="text-center px-sm-7 pb-2 pb-sm-5">{description}</div>
                <Row
                    className={`align-items-center justify-content-center pb-sm-3${
                        onSkip ? ' flex-column' : ''
                    }`}
                >
                    <Button
                        autoFocus
                        className="col col-sm-8 col-md-7 mt-2"
                        isLoading={isLoading}
                        type="submit"
                    >
                        Let&apos;s Begin
                    </Button>
                    {onSkip && (
                        <Button
                            className="col col-sm-8 col-md-7 mt-2"
                            onClick={onSkip}
                            variant="text"
                        >
                            {skipText}
                        </Button>
                    )}
                </Row>
            </CardContent>
        </Card>
    </SurveyFormWrapper>
);

export default hot(module)(StepIntro);
