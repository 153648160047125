import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { confirmMfaVerification } from 'actions/token/confirmMfaVerification';
import { sendNewMfaVerification } from 'actions/token/sendNewMfaVerification';
import {
    INTERMEDIATE_TOKEN_ACTION,
    VERIFICATION_CODE_LENGTH,
} from 'actions/token/tokenActionUtility';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Form from 'components/Form';
import InformationIconTooltip from 'components/InformationIconTooltip';
import TextField from 'components/TextField';
import UnauthenticatedPageHeader from 'components/UnauthenticatedPageHeader';
import useForm from 'hooks/useForm';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { handleRedirect, loginFormSelector } from 'pages/login/LoginPage';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { TokenService } from 'security/TokenService';
import { onChange as onFormChange } from 'utilities/forms';
import { hasValue } from 'utilities/index';
import { number, object } from 'yup';

const schema = object({
    verificationCode: number().required(),
});

const MfaVerificationPage = () => {
    const dispatch = useThunkDispatch();
    const teamProps = useTeamProps();
    const { hasTeam } = teamProps;
    const userProps = useUserProps();
    const { hasUser, user } = userProps;
    const {
        apiError,
        apiErrorMessage,
        isAuthenticated,
        needsVerifiedInfo,
        isTeamAdmin,
    } = useSelector(loginFormSelector(teamProps, userProps, INTERMEDIATE_TOKEN_ACTION));
    const allowRememberDevice = useSelector(
        (state: AppStore) => state.appRouterReducer.allowRememberDevice
    );

    const [verificationCode, setVerificationCode] = useState<string>('');
    const [isVerificationResent, setIsVerificationResent] = useState<boolean>(false);
    const [rememberDevice, setRememberDevice] = useState<boolean>(false);

    const { errors, setErrors, validate } = useForm(schema);
    const isSubmitButtonDisabled = verificationCode?.toString().length !== VERIFICATION_CODE_LENGTH;

    const redirectData = {
        hasTeam,
        hasUser,
        isAuthenticated,
        isTeamAdmin,
        needsVerifiedInfo,
        user,
    };
    const redirectPath = handleRedirect(redirectData);

    const onChange = <T,>(setValue: React.Dispatch<React.SetStateAction<T>>) => (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setErrors(null);
        onFormChange(setValue)(e);
    };
    const handleSubmit = async () => {
        const { isValid } = await validate({ verificationCode: +verificationCode });
        if (isValid) {
            await dispatch(confirmMfaVerification(verificationCode, rememberDevice));
        }
    };

    const handleCodeResend = async () => {
        setIsVerificationResent(false);
        await dispatch(sendNewMfaVerification());
        setIsVerificationResent(true);
    };

    const openBeacon = useCallback(() => {
        window.Beacon('navigate', '/ask/message/');
        window.Beacon('open');
    }, []);

    if (hasValue(redirectPath)) {
        return <Redirect to={redirectPath} />;
    } else {
        return (
            <Stack alignItems="center" my={7}>
                <Box px={2} width={500}>
                    <Card sx={{ padding: 0 }}>
                        <UnauthenticatedPageHeader />
                        <CardContent sx={{ p: 4 }}>
                            <Box sx={{ mb: 4 }} textAlign="center">
                                <Typography color="grey.700" fontWeight={500} variant="h4">
                                    Verification Required{' '}
                                    <InformationIconTooltip title="Multi-Factor Authentication has been turned on for your organization to help keep your account secure." />
                                </Typography>
                                <Typography color="grey.500" variant="caption">
                                    Just to be safe, we need to confirm it&apos;s really you.
                                </Typography>
                            </Box>
                            {apiError && (
                                <Alert data-cy="api-error" severity="error" sx={{ mb: 2 }}>
                                    {apiErrorMessage}
                                </Alert>
                            )}
                            {!apiError && isVerificationResent && (
                                <Alert data-cy="api-error" severity="success" sx={{ mb: 2 }}>
                                    Verification successfully resent. Please enter the new
                                    verification code from your email.
                                </Alert>
                            )}
                            <Form onSubmit={handleSubmit}>
                                <Typography>
                                    Enter the verification code we sent to the email associated with
                                    your account.
                                </Typography>
                                <br />
                                <Stack spacing={2}>
                                    <TextField
                                        autoFocus
                                        data-cy="verification-code"
                                        errors={errors?.verificationCode}
                                        fullWidth
                                        name="verificationCode"
                                        onChange={onChange(setVerificationCode)}
                                        placeholder="6-digit code"
                                        type="number"
                                        value={verificationCode}
                                    />
                                    {allowRememberDevice && (
                                        <Checkbox
                                            label="Remember this device"
                                            onChange={({ target: { checked } }) =>
                                                setRememberDevice(checked)
                                            }
                                            value={rememberDevice}
                                        />
                                    )}

                                    <Grid container justifyContent="center">
                                        <Button
                                            disabled={isSubmitButtonDisabled}
                                            sx={{ mx: 'auto', my: 3 }}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Log In Securely
                                        </Button>
                                    </Grid>
                                </Stack>
                            </Form>
                            <Grid container justifyContent="center">
                                <Link
                                    color="secondary"
                                    component="button"
                                    onClick={() => TokenService.clearIntermediateTokenFromCache()}
                                    underline="none"
                                >
                                    Cancel
                                </Link>
                            </Grid>
                            <hr />
                            <Grid container height={0} justifyContent="flex-start" marginBottom={2}>
                                <Link
                                    color="secondary"
                                    component="button"
                                    onClick={handleCodeResend}
                                    underline="none"
                                >
                                    Send me a new code
                                </Link>
                            </Grid>
                            <br />
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                width={200}
                            >
                                <Grid alignItems="flex-end">Need help logging in?</Grid>
                                <Link
                                    color="secondary"
                                    component="button"
                                    onClick={openBeacon}
                                    underline="none"
                                >
                                    Email us
                                </Link>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Stack>
        );
    }
};

export default hot(module)(MfaVerificationPage);
