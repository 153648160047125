import Grid from '@mui/material/Grid';
import { IMediSharePlan } from 'api/generated/models';
import { LabelValue } from 'pages/shop/PlanCard';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

const MediSharePlanCardContent = ({ plan }: { plan: IMediSharePlan }) => (
    <Grid container justifyContent="space-evenly" spacing={2}>
        <Grid item md={4} xs={6}>
            <LabelValue label="Annual Household Portion (AHP)">
                {`${formatCurrency(plan.ahp)} per household`}
            </LabelValue>
        </Grid>
        <Grid item md={4} xs={6}>
            <LabelValue label="ER Fee">$200 after AHP</LabelValue>
        </Grid>
        <Grid item md={4} xs={6}>
            <LabelValue label="Network Type">PPO</LabelValue>
        </Grid>
        <Grid item md={4} xs={6}>
            <LabelValue label="Primary Care Visit">$35 after AHP</LabelValue>
        </Grid>
        <Grid item md={4} xs={6}>
            <LabelValue label="Specialist Visit">$35 after AHP</LabelValue>
        </Grid>
        <Grid item md={4} xs={6}></Grid>
    </Grid>
);

export default hot(module)(MediSharePlanCardContent);
