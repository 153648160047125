import { DeductionStatus, HrsIntegrationProviders } from 'api/generated/enums';
import { IPayrollReportDto } from 'api/generated/models';
import { SetPayrollSystemDeduction } from 'api/generated/permissions';
import useTeamProps from 'hooks/useTeamProps';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';

export const useHrsForPayrollReport = (
    activeReport: Pick<Partial<IPayrollReportDto>, 'isLocked' | 'month' | 'year'> | undefined
) => {
    const {
        finchPostTaxDeductionStatus,
        finchPreTaxDeductionStatus,
        hrsIntegrationProvider,
        isHrsIntegrationConnected,
    } = useTeamProps();

    const isFinchProvider = hrsIntegrationProvider === HrsIntegrationProviders.Finch;

    let isHrsIntegrationConnectedAndValid = isHrsIntegrationConnected;
    if (isFinchProvider) {
        const hasValidDeductionStatus =
            finchPostTaxDeductionStatus === DeductionStatus.Valid ||
            finchPreTaxDeductionStatus === DeductionStatus.Valid;
        isHrsIntegrationConnectedAndValid = hasValidDeductionStatus;
    }

    const { canSetPayrollSystemDeductions } = useSelector((state: AppStore) => ({
        canSetPayrollSystemDeductions:
            hasSomePermissions(state, SetPayrollSystemDeduction) &&
            isHrsIntegrationConnectedAndValid,
    }));

    const activeReportDate = moment()
        .month((activeReport?.month ?? NaN) - 1)
        .year(activeReport?.year ?? NaN);

    const isActiveReportDateSameOrInTheFuture =
        moment().isSameOrBefore(activeReportDate, 'month') &&
        moment().isSameOrBefore(activeReportDate, 'year');

    const canApplyDeductions =
        canSetPayrollSystemDeductions &&
        (activeReport?.isLocked ?? false) &&
        isActiveReportDateSameOrInTheFuture;

    return { canApplyDeductions, isHrsIntegrationConnectedAndValid };
};
