import { deleteTeamPlan } from 'actions/teamPlan/deleteTeamPlan';
import useThunkDispatch from 'hooks/useThunkDispatch';
import GroupPlanProfileCard from 'pages/teamProfile/GroupPlanProfileCard';
import React, { useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const TeamPlans = () => {
    const dispatch = useThunkDispatch();
    const { teamPlans } = useSelector((state: AppStore) => ({
        teamPlans: state.teamPlans,
    }));
    const onRemoveGroupPlan = useCallback(
        async (teamPlan) => {
            await dispatch(deleteTeamPlan(teamPlan.teamPlanId));
        },
        [dispatch]
    );
    return (
        <Row>
            {teamPlans.map((teamPlan, index) => (
                <Col key={index} md="6">
                    <GroupPlanProfileCard
                        {...teamPlan}
                        onRemove={async () => onRemoveGroupPlan(teamPlan)}
                        showRemoveButton
                    />
                </Col>
            ))}
        </Row>
    );
};

export default hot(module)(TeamPlans);
