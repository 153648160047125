import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SUBMIT_SURVEY_ACTION } from 'actions/survey/submitSurvey';
import { TeamStateIds, UserStatus } from 'api/generated/enums';
import ActivityIndicator from 'components/ActivityIndicator';
import { Logo } from 'components/Logo';
import ScrollGradientIndicator, {
    UPDATE_SCROLL_GRADIENT_INDICATOR,
} from 'components/ScrollGradientIndicator';
import { SurveyTabs } from 'constants/surveyTabs';
import useTeamProps from 'hooks/useTeamProps';
import useUserProps from 'hooks/useUserProps';
import isEmpty from 'lodash/isEmpty';
import ContactForm from 'pages/survey/ContactForm';
import MemberForm from 'pages/survey/MemberForm';
import SurveyReview from 'pages/survey/SurveyReview';
import CoverageWrapper from 'pages/survey/coverageSteps/CoverageWrapper';
import HouseholdWrapper from 'pages/survey/householdSteps/HouseholdWrapper';
import IchraHouseholdWrapper from 'pages/survey/householdSteps/IchraHouseholdWrapper';
import IncomeWrapper from 'pages/survey/incomeSteps/IncomeWrapper';
import QualitativeWrapper from 'pages/survey/qualitativeSteps/QualitativeWrapper';
import React, { useEffect, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import EventEmitter from 'utilities/eventEmitter';
import { ichraCheckThroughRenewal } from 'utilities/index';

export type ISurveyFormProps = {
    isIchraPathway?: boolean;
    redirectUrl: string;
    subTitle: JSX.Element;
    title: string;
};

const handleSurveyTab = (
    tabIndex: SurveyTabs,
    hideQualitativeQuestions: boolean,
    SurveyReviewWrapper: () => JSX.Element
) => {
    switch (tabIndex) {
        case SurveyTabs.Member:
            return MemberForm;
        case SurveyTabs.Contact:
            return ContactForm;
        case SurveyTabs.Household:
            return HouseholdWrapper;
        case SurveyTabs.Coverage:
            return CoverageWrapper;
        case SurveyTabs.Income:
            return IncomeWrapper;
        case SurveyTabs.Qualitative:
            return hideQualitativeQuestions ? SurveyReviewWrapper : QualitativeWrapper;
        default:
            return SurveyReviewWrapper;
    }
};

const handleIchraSurveyTab = (
    tabIndex: SurveyTabs,
    hideQualitativeQuestions: boolean,
    SurveyReviewWrapper: () => JSX.Element
) => {
    switch (tabIndex) {
        case SurveyTabs.Member:
            return MemberForm;
        case SurveyTabs.Contact:
            return ContactForm;
        case SurveyTabs.Household:
            return IchraHouseholdWrapper;
        case SurveyTabs.Coverage:
            return CoverageWrapper;
        case SurveyTabs.Income:
            return hideQualitativeQuestions ? SurveyReviewWrapper : QualitativeWrapper;
        default:
            return SurveyReviewWrapper;
    }
};

const Survey = ({ redirectUrl, title, subTitle, isIchraPathway = false }: ISurveyFormProps) => {
    const { userProfile, user } = useUserProps();
    const pathHistory = useHistory();
    const { team } = useTeamProps();
    const { showActivity, survey } = useSelector((state: AppStore) => ({
        showActivity: state.apiActivity[SUBMIT_SURVEY_ACTION],
        survey: state.surveyState,
    }));
    const isRenewal =
        team?.teamStateId === TeamStateIds.Renewing &&
        [UserStatus.Renewing, UserStatus.Waived].contains(user?.status);
    const isIchra = ichraCheckThroughRenewal(pathHistory, isIchraPathway, user?.surveyTypeToSend);

    const CurrentTab = useMemo(() => {
        const includeQualitativeQuestions = survey.includeQualitativeQuestionsInSurvey;
        const hideQualitativeQuestions = isRenewal || !includeQualitativeQuestions;
        const SurveyReviewWrapper = () => (
            <SurveyReview
                isIchraPathway={isIchraPathway}
                redirectUrl={redirectUrl}
                subTitle={subTitle}
                title={title}
            />
        );
        return isIchra
            ? handleIchraSurveyTab(survey.tabIndex, hideQualitativeQuestions, SurveyReviewWrapper)
            : handleSurveyTab(survey.tabIndex, hideQualitativeQuestions, SurveyReviewWrapper);
    }, [isIchra, isIchraPathway, isRenewal, redirectUrl, subTitle, survey, title]);

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [survey.tabIndex]);

    return (
        <React.Fragment>
            <div className="mb-3">
                <Card className="rounded-0">
                    <CardContent>
                        <Row className="justify-content-between align-items-center px-1 px-sm-7 text-center">
                            <div>
                                <Typography
                                    color={(theme) => theme.palette.muted.main}
                                    variant="h4"
                                >
                                    Health Benefits Analysis
                                </Typography>
                            </div>
                            <div>
                                <Logo teamId={userProfile?.user?.teamId} />
                            </div>
                        </Row>
                    </CardContent>
                </Card>
                <ScrollGradientIndicator>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="10">{!isEmpty(userProfile) && <CurrentTab />}</Col>
                        </Row>
                    </Container>
                </ScrollGradientIndicator>
            </div>
            {showActivity && <ActivityIndicator />}
        </React.Fragment>
    );
};

export default hot(module)(Survey);
