import React from 'react';
import { hot } from 'react-hot-loader';

const DoNotInclude = () => (
    <p className="text-danger">
        <i className="dripicons-cross" /> Do not Include
    </p>
);

export default hot(module)(DoNotInclude);
