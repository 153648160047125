import { AppStoreThunkDispatch } from 'actions/commonAction';
import { SET_DRUGS_FOR_HOUSEHOLD_MEMBER_ACTION } from 'actions/drug/setDrugsForHouseholdMember';
import { SET_DRUGS_FOR_USER_ACTION } from 'actions/drug/setDrugsForUser';
import { IDrug } from 'api/generated/models';
import CreatableAutocomplete from 'components/creatableAutocomplete/CreatableAutocomplete';
import useDrugAutocomplete from 'components/creatableAutocomplete/useDrugAutocomplete';
import SingleButtonModal from 'components/SingleButtonModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

type IEditDrugsModalProps = {
    close: () => void;
    drugs: IDrug[];
    setDrugs: (
        options: IDrug[]
    ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;
};

const EditDrugsModal = ({ close, drugs, setDrugs }: IEditDrugsModalProps) => {
    const dispatch = useThunkDispatch();
    const { showActivity } = useSelector((state: AppStore) => ({
        showActivity: hasApiActivity(
            state,
            SET_DRUGS_FOR_USER_ACTION,
            SET_DRUGS_FOR_HOUSEHOLD_MEMBER_ACTION
        ),
    }));
    const [selectedOptions, setSelectedOptions] = useState<IDrug[]>(drugs);
    const save = useCallback(async () => {
        await dispatch(setDrugs(selectedOptions));
        close();
    }, [close, dispatch, selectedOptions, setDrugs]);
    const creatableAutocompleteProps = useDrugAutocomplete({
        onChange: (_, values) => setSelectedOptions(values),
        value: selectedOptions,
    });
    return (
        <SingleButtonModal
            body={<CreatableAutocomplete {...creatableAutocompleteProps} />}
            buttonClickHandler={save}
            buttonText="Save"
            onHide={close}
            showActivity={showActivity}
            title="Edit Prescription(s)"
        />
    );
};

export default hot(module)(EditDrugsModal);
