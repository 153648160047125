import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { defaultHandlePrevious } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import React from 'react';
import { hot } from 'react-hot-loader';

const SummarySectionHeader = ({
    hideEdit,
    label,
    taskId,
    textVariantOverride,
}: {
    hideEdit?: boolean;
    label: string;
    taskId?: string;
    textVariantOverride?: Variant;
}) => {
    const dispatch = useThunkDispatch();
    return (
        <Stack alignItems="center" direction="row" justifyContent="flex-start">
            <Typography fontWeight={700} variant={textVariantOverride ?? 'h4'}>
                {label}
            </Typography>
            {!hideEdit && (
                <Button
                    color="secondary"
                    onClick={async () => defaultHandlePrevious(dispatch, taskId as string)}
                    sx={{ ml: 1 }}
                    variant="text"
                >
                    Edit
                </Button>
            )}
        </Stack>
    );
};

export default hot(module)(SummarySectionHeader);
