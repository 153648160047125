import Grid from '@mui/material/Grid';
import { PlanTypeIds } from 'api/generated/enums';
import orderBy from 'lodash/orderBy';
import BenefitCards from 'pages/benefits/BenefitCards';
import BenefitSectionHeader from 'pages/benefits/BenefitSectionHeader';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const IndividualAncillaryBenefits = () => {
    const { selectedPlans } = useSelector((state: AppStore) => ({
        selectedPlans: orderBy(state.memberBenefits.selectedPlans, [
            'planStateId',
            'planTypeId',
            'coverageStartDate',
        ]).filter((x) =>
            [PlanTypeIds.Ancillary, PlanTypeIds.CustomAncillary].contains(x.planTypeId)
        ),
    }));
    if (selectedPlans.length) {
        return (
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <BenefitSectionHeader>Individual Ancillary Benefits</BenefitSectionHeader>
                </Grid>
                <BenefitCards selectedPlans={selectedPlans} />
            </Grid>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(IndividualAncillaryBenefits);
