import { GroupLevels } from 'api/generated/enums';
import { IMemberYearlyReimbursementDto } from 'api/generated/models';
import classNames from 'classnames';
import IconTooltip from 'components/IconTooltip';
import ReactTable from 'components/reactTable/ReactTable';
import { CENTER_AND_ALIGN, CENTER_CONTENT } from 'constants/styleConstants';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';

const DEFAULT_PAGE_SIZE = 50;

const commonProps = {
    className: CENTER_CONTENT,
    headerClassName: classNames(CENTER_AND_ALIGN),
};

const ReimbursementYearlyReportTable = ({ globalFilter }: { globalFilter: string | undefined }) => {
    const { data, useHsaReimbursement } = useSelector((state: AppStore) => ({
        data: state.reimbursementReportsYearly.members ?? ([] as IMemberYearlyReimbursementDto[]),
        useHsaReimbursement: state.reimbursementReportsYearly?.useHsaReimbursement,
    }));

    const formatColumnNumberValue = (value: number) => {
        if (Number(value) < 0) {
            return (
                <IconTooltip
                    className="ml-1"
                    icon="warning"
                    title="Member has submitted expenses in this report year but there was an error calculating the amount."
                    variant="danger"
                />
            );
        }
        return formatCurrency(value, { preserveDecimal: true });
    };

    const yearlyReportColumns = useMemo<Column<IMemberYearlyReimbursementDto>[]>(() => {
        const cols: Column<IMemberYearlyReimbursementDto>[] = [
            {
                accessor: (x) => x.name,
                Header: 'Name',
            },
            {
                accessor: (x) =>
                    hasValue(x.reimbursementCoverageLevel)
                        ? GroupLevels[x.reimbursementCoverageLevel]
                        : GroupLevels[GroupLevels.UK],
                Header: 'Reimbursement Coverage Level',
                ...commonProps,
            },
            {
                accessor: (x) => formatColumnNumberValue(x.medicalAmountEligibleForReimbursement),
                Header: 'Medical Amount Eligible for Reimbursement',
                ...commonProps,
            },
            {
                accessor: (x) => formatColumnNumberValue(x.medicalReimbursementLimit),
                Header: 'Medical Reimbursement Limit',
                ...commonProps,
            },
            {
                accessor: (x) => formatColumnNumberValue(x.totalAmountEligibleForReimbursement),
                Header: 'Total Amount Eligible for Reimbursement',
                ...commonProps,
            },
            {
                accessor: (x) => formatColumnNumberValue(x.totalReimbursementLimit),
                Header: 'Total Reimbursement Limit (Pre-Tax)',
                ...commonProps,
            },
        ];
        if (useHsaReimbursement) {
            cols.push({
                accessor: (x) => formatColumnNumberValue(x.totalReimbursementToHsa),
                Header: 'Total Reimbursement to HSA',
                ...commonProps,
            });
        }
        cols.push({
            accessor: (x) => formatColumnNumberValue(x.totalReimbursementToWages),
            Header: 'Total Reimbursement to Wages',
            ...commonProps,
        });
        if (useHsaReimbursement) {
            cols.push({
                accessor: (x) => formatColumnNumberValue(x.grandTotalReimbursement),
                Header: 'Grand Total Reimbursement',
                ...commonProps,
            });
        }
        return cols;
    }, [useHsaReimbursement]);

    return (
        <ReactTable
            columns={yearlyReportColumns}
            data={data}
            globalFilter={globalFilter}
            pageSize={DEFAULT_PAGE_SIZE}
        />
    );
};

export default hot(module)(ReimbursementYearlyReportTable);
