import Stack from '@mui/material/Stack';
import {
    PATCH_PATHWAY_USER_ACTION,
    patchPathwayUser,
} from 'actions/pathwayBlueprint/patchPathwayUser';
import { IPathwayUser } from 'api/generated/models';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import TextArea from 'components/TextArea';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';

const NOTE_MAX_LENGTH = 500;
type IOtherCostsModal = {
    onClose: () => void;
    pathwayUser: IPathwayUser;
};

const OtherCostsModal = ({ onClose, pathwayUser }: IOtherCostsModal) => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, PATCH_PATHWAY_USER_ACTION),
    }));

    const [medicareCost, setMedicareCost] = useState(pathwayUser.customMedicareCost);
    const [chipCost, setChipCost] = useState(pathwayUser.customChipCost);
    const [unclaimedHouseholdMemberCost, setUnclaimedHouseholdMemberCost] = useState(
        pathwayUser.unclaimedHouseholdMemberCost
    );
    const [additionalCost, setAdditionalCost] = useState(pathwayUser.additionalCost);
    const [note, setNote] = useState(pathwayUser.otherCostsNote);

    const onSubmit = async () => {
        const patch: Partial<IPathwayUser> = {
            additionalCost: additionalCost,
            customChipCost: chipCost,
            customMedicareCost: medicareCost,
            otherCostsNote: note?.trim(),
            unclaimedHouseholdMemberCost: unclaimedHouseholdMemberCost,
        };

        await dispatch(patchPathwayUser(pathwayUser.id, patch, 'Other Costs'));
        onClose();
    };

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Edit Other Costs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="other-costs-modal-form" onSubmit={onSubmit}>
                    <Stack gap={2}>
                        <CurrencyTextField
                            label="Per Member Medicare Cost"
                            onChange={onChange(setMedicareCost)}
                            placeholder="Enter Per Member Medicare Cost"
                            value={medicareCost}
                        />
                        <CurrencyTextField
                            label="Chip Cost"
                            onChange={onChange(setChipCost)}
                            placeholder="Enter CHIP Cost"
                            value={chipCost}
                        />
                        <CurrencyTextField
                            label="Unclaimed Household Member Cost"
                            onChange={onChange(setUnclaimedHouseholdMemberCost)}
                            placeholder="Enter Unclaimed Household Member Cost"
                            value={unclaimedHouseholdMemberCost}
                        />
                        <CurrencyTextField
                            label="Additional Cost"
                            onChange={onChange(setAdditionalCost)}
                            placeholder="Enter Additional Cost"
                            value={additionalCost}
                        />
                        <TextArea
                            inputProps={{
                                maxLength: NOTE_MAX_LENGTH,
                            }}
                            label="Other Costs Note"
                            maxLength={NOTE_MAX_LENGTH}
                            onChange={onChange(setNote)}
                            placeholder="Consider providing an explanation as to why these other costs have been set."
                            value={note}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Close</Button>
                <Button form="other-costs-modal-form" isLoading={isLoading} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(OtherCostsModal);
