import { GroupLevels } from 'api/generated/enums';
import { IUserTeamBenefitTermDetailDto } from 'api/generated/models';
import TeamBenefitCardBase, {
    eligibilityWaitingDaysField,
    groupPlanIdField,
    ITeamBenefitField,
    nextMemberEnrollmentPeriodField,
} from 'components/TeamBenefitCardBase';
import useUserProps from 'hooks/useUserProps';
import MemberTeamBenefitActions from 'pages/benefits/MemberTeamBenefitActions';
import React from 'react';
import { hot } from 'react-hot-loader';
import { RouteComponentProps, withRouter } from 'react-router';
import { getDisplayFirstName } from 'utilities/household';

type IMemberTeamBenefitCard = RouteComponentProps & {
    userTeamBenefitTermDetail: IUserTeamBenefitTermDetailDto;
};

const MemberTeamBenefitCard = ({ userTeamBenefitTermDetail }: IMemberTeamBenefitCard) => {
    const { user } = useUserProps();

    const primaryName = getDisplayFirstName(user);
    const hhmNames =
        userTeamBenefitTermDetail.householdMembers?.map((h) => getDisplayFirstName(h)) ?? [];
    const membersCovered = [primaryName, ...hhmNames].joinSerialComma();

    const fields: ITeamBenefitField[] = [
        groupPlanIdField(userTeamBenefitTermDetail.teamBenefit),
        nextMemberEnrollmentPeriodField(userTeamBenefitTermDetail.teamBenefit),
        eligibilityWaitingDaysField(userTeamBenefitTermDetail.teamBenefit),
        {
            isVisible: true,
            label: 'Coverage Level',
            value: GroupLevels[userTeamBenefitTermDetail?.coverageLevelId ?? GroupLevels.UK],
        },
    ];

    return (
        <TeamBenefitCardBase
            actions={
                <MemberTeamBenefitActions userTeamBenefitTermDetail={userTeamBenefitTermDetail} />
            }
            endDate={userTeamBenefitTermDetail.coverageEndDate}
            fields={fields}
            membersCovered={membersCovered}
            showSubmitExpenseUrl
            startDate={userTeamBenefitTermDetail.coverageStartDate}
            teamBenefit={userTeamBenefitTermDetail.teamBenefit}
        />
    );
};

export default hot(module)(withRouter(MemberTeamBenefitCard));
