import { ICustomQuestion } from 'api/generated/models';
import TextArea from 'components/TextArea';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveQualitative } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

type ICustomQuestionFormProps = IStepProps & {
    customQuestion: ICustomQuestion;
};

const CustomQuestionForm = ({
    children,
    commonProps,
    customQuestion,
}: ICustomQuestionFormProps) => {
    const dispatch = useThunkDispatch();
    const { customQuestionAnswers } = useSelector((state: AppStore) => ({
        customQuestionAnswers: state.surveyState.qualitative.customQuestionAnswers,
    }));

    const onChange = useCallback(
        async ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) =>
            dispatch(
                saveQualitative({
                    customQuestionAnswers: { ...customQuestionAnswers, [customQuestion.id]: value },
                })
            ),
        [dispatch, customQuestionAnswers, customQuestion.id]
    );

    const answer = customQuestionAnswers[customQuestion.id] ?? '';
    const isDisabled = !hasValue(answer);

    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled}>
            {children}
            <FormTitle>{customQuestion.text}</FormTitle>
            <Row className="justify-content-center">
                <Col md="7">
                    <TextArea
                        autoFocus
                        className="px-1 mt-4"
                        data-cy="custom-question-answer"
                        maxLength={4000}
                        minRows={3}
                        onChange={onChange}
                        placeholder="Your response"
                        value={answer}
                    />
                </Col>
            </Row>
        </SurveyFormWrapper>
    );
};

export default hot(module)(CustomQuestionForm);
