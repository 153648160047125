import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IReimbursementSummarySection } from 'api/generated/models';
import LabelAndValue from 'components/LabelAndValue';
import LineItemSummary from 'pages/payrollReports/ReimbursementSummaryModel/LineItemSummary';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

const SectionSummary = ({
    isContribution = false,
    section,
    superscriptIdGenerator,
    title,
}: {
    isContribution?: boolean;
    section: IReimbursementSummarySection | undefined;
    superscriptIdGenerator: Generator<string>;
    title: string;
}) => (
    <Stack spacing={1}>
        <Typography variant="h3">{title}</Typography>
        {section?.reimbursableLineItems?.map((li, i) => (
            <LineItemSummary
                isContribution={isContribution}
                key={i}
                lineItem={li}
                superscriptIdGenerator={superscriptIdGenerator}
                title={title}
            />
        ))}
        {section?.nonReimbursableLineItems?.map((li, i) => (
            <LabelAndValue
                key={i}
                label={li.title}
                value={formatCurrency(li.amount, { preserveDecimal: true })}
                variant="h4"
            />
        ))}
        <LabelAndValue
            label={`${title} Adjustment`}
            value={formatCurrency(section?.adjustment, { preserveDecimal: true })}
            variant="h4"
        />
        <LabelAndValue
            label={`Total ${title}`}
            value={formatCurrency(section?.total, { preserveDecimal: true })}
            variant="h4"
        />
        <LabelAndValue
            label="Reimbursement Remaining"
            value={formatCurrency(section?.remainingReimbursement, { preserveDecimal: true })}
            variant="h4"
        />
    </Stack>
);

export default hot(module)(SectionSummary);
