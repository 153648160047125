import { BinderStatuses, PayTypeIds, PlanStateIds } from 'api/generated/enums';
import { ISelectedPlan } from 'api/generated/models';
import { ViewSelectedPlans } from 'api/generated/permissions';
import { MONTHLY_SHARE_AMOUNT } from 'constants/selectedPlans';
import isUndefined from 'lodash/isUndefined';
import startCase from 'lodash/startCase';
import { ColumnRowNameValue, IColumnRowNameValue } from 'pages/benefits/ColumnRowNameValue';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { formatCurrency, formatDateForDisplay, formatYesOrNo } from 'utilities/format';
import { getPlanTypeName } from 'utilities/plans';

const buildRightColumnFields = (
    { coverageStartDate, coverageEndDate, memberId, planStateId }: ISelectedPlan,
    showTerminationDate: boolean
) => {
    const fields = [];
    if (coverageStartDate) {
        fields.push({
            'data-cy': 'effective-date',
            name: 'Member Since',
            value: formatDateForDisplay(coverageStartDate) || '',
        });
    }
    if (coverageEndDate && showTerminationDate) {
        fields.push({
            'data-cy': 'termination-date',
            name: 'Termination Date',
            value: formatDateForDisplay(coverageEndDate),
        });
    }
    if ([PlanStateIds.Enrolled, PlanStateIds.Effective].includes(planStateId) && memberId) {
        fields.push({
            'data-cy': 'household-id',
            name: 'Household ID',
            value: memberId,
        });
    }
    return fields;
};

const buildRHFields = ({ binderStatus, payTypeId, isPassiveRenewal }: ISelectedPlan) => {
    const fields = [];
    if (!isUndefined(binderStatus)) {
        fields.push({ name: 'Binder Status', value: startCase(BinderStatuses[binderStatus]) });
    }
    if (!isUndefined(payTypeId)) {
        fields.push({ name: 'Pay Type', value: startCase(PayTypeIds[payTypeId]) });
    }
    if (isPassiveRenewal) {
        fields.push({ name: 'Is Passive Renewal?', value: 'Yes' });
    }
    return fields;
};

export const buildCommonRhOrAdvisorFields = ({
    isAddedToPaymentTracking,
    isAddedToPayrollReport,
    comment,
}: ISelectedPlan) => {
    const fields = [];
    if (!isUndefined(isAddedToPaymentTracking)) {
        fields.push({
            name: 'Is Added To Payment Tracking?',
            value: formatYesOrNo(isAddedToPaymentTracking),
        });
    }
    if (!isUndefined(isAddedToPayrollReport)) {
        fields.push({
            name: 'Is Added To Payroll Report?',
            value: formatYesOrNo(isAddedToPayrollReport),
        });
    }
    if (hasValue(comment)) {
        fields.push({
            name: 'Comment',
            value: comment,
        });
    }
    return fields;
};

const buildViewSelectedPlansFields = ({ applicationDate }: ISelectedPlan) => {
    const fields = [];
    if (!isUndefined(applicationDate)) {
        fields.push({
            name: 'Application Date',
            value: formatDateForDisplay(applicationDate),
        });
    }
    return fields;
};

const MediShareBenefitCardContent = ({ benefit }: { benefit: ISelectedPlan }) => {
    const { canViewSelectedPlans, isRh, isAdvisor } = useSelector((state: AppStore) => ({
        canViewSelectedPlans: state.login.up.includes(ViewSelectedPlans),
        isAdvisor: isAdvisorSelector(state),
        isRh: isRhSelector(state),
    }));
    const leftColumn: IColumnRowNameValue[] = [
        {
            name: 'Annual Household Portion (AHP)',
            value: `${formatCurrency(benefit.planId)} / family`,
        },
        { name: 'Network Type', value: 'PPO' },
        { 'data-cy': 'primary-care', name: 'Primary Care', value: '$35 after AHP' },
        { 'data-cy': 'specialist-visit', name: 'Specialist Visit', value: '$35 after AHP' },
    ];
    const rightColumn: IColumnRowNameValue[] = buildRightColumnFields(benefit, isRh || isAdvisor);
    if (isRh) {
        rightColumn.push(...buildRHFields(benefit));
    }
    if (isRh || isAdvisor) {
        leftColumn.unshift({
            'data-cy': 'plan-type',
            name: 'Plan Type',
            value: getPlanTypeName(benefit.planTypeId),
        });
        rightColumn.push(...buildCommonRhOrAdvisorFields(benefit));
    }
    if (canViewSelectedPlans) {
        rightColumn.push(...buildViewSelectedPlansFields(benefit));
    }
    if (hasValue(benefit.planPremium)) {
        rightColumn.push({
            name: MONTHLY_SHARE_AMOUNT,
            value: formatCurrency(benefit.planPremium, { preserveDecimal: true }),
        });
    }
    const data: IColumnRowNameValue[][] = [leftColumn];
    if (rightColumn.length > 0) {
        data.push(rightColumn);
    }
    return (
        <React.Fragment>
            <ColumnRowNameValue data={data} />
            <hr />
        </React.Fragment>
    );
};

export default hot(module)(MediShareBenefitCardContent);
