import { Flows, TaskStatuses, Tasks } from 'api/generated/enums';
import { TaskDto, UserFlowDto } from 'api/generated/models';
import { taskListTypes } from 'constants/tasks';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import AgreeToTermsModal from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/AgreeToTermsModal';
import TaskListContainer from 'pages/dashboard/taskLists/TaskListContainer';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const MemberTermsFlowTaskList = () => {
    const { memberTermsFlow } = useSelector((state: AppStore) => ({
        memberTermsFlow: state.userFlows.find(
            (uf) => uf.flowId === Flows.SignUpdatedMemberTerms
        ) as UserFlowDto,
    }));
    const memberTermTask = memberTermsFlow.tasks?.find(
        (x) => x.taskId === Tasks.MemberTerms
    ) as TaskDto;
    const { teamId } = useTeamProps();
    const { closeModal, isVisible, openModal } = useModalState();

    return (
        <React.Fragment>
            <TaskListContainer
                completeMessage="You have signed the updated member terms."
                description=""
                isCompletedOverride={memberTermTask?.taskStatusId === TaskStatuses.Completed}
                taskListType={taskListTypes.User}
                teamId={teamId}
                title="Sign Member Terms"
            >
                <TaskListItem
                    action={() => openModal()}
                    buttonText="Start"
                    complete={memberTermTask?.taskStatusId === TaskStatuses.Completed}
                    enabled
                    key={memberTermTask.taskId}
                    showButton
                    title="Member Terms"
                />
            </TaskListContainer>
            {isVisible && (
                <AgreeToTermsModal
                    currentTask={memberTermTask}
                    flowId={Flows.SignUpdatedMemberTerms}
                    onClose={closeModal}
                />
            )}
        </React.Fragment>
    );
};
export default hot(module)(MemberTermsFlowTaskList);
