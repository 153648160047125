import Grid from '@mui/material/Grid';
import NumberTextField from 'components/NumberTextField';
import {
    SSN_INDEX_BEFORE_FIRST_DASH,
    SSN_INDEX_BEFORE_SECOND_DASH,
} from 'pages/dashboard/ichraTaskFlow/taskFlowPages/socialSecurityNumbersPage/SocialSecurityNumberPage';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';
import { hasValue } from 'utilities/index';
type ISocialSecurityTaskFieldProps = {
    entityKeyId: string;
    errors: string[] | undefined;
    firstName: string;
    isReadonly: boolean;
    socialSecurityNumber: string;
    updateSocialSecurityNumber: (ssn: string, entityKeyId: string) => void;
};

const SocialSecurityTaskField = ({
    entityKeyId,
    errors,
    firstName,
    isReadonly,
    socialSecurityNumber,
    updateSocialSecurityNumber,
}: ISocialSecurityTaskFieldProps) => {
    const INDEXES_BEFORE_DASHES = [SSN_INDEX_BEFORE_FIRST_DASH, SSN_INDEX_BEFORE_SECOND_DASH];

    const [isSsnVisible, setIsSsnVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [ssn, setSsn] = useState(socialSecurityNumber);
    const [displayedValue, setDisplayedValue] = useState(
        isReadonly || hasValue(ssn) ? 'XXX-XX-XXXX' : ''
    );
    const getMask = (count: number) => {
        let mask = '';
        for (let i = 0; i < count; i++) {
            mask = mask.concat(INDEXES_BEFORE_DASHES.includes(i) ? '-X' : 'X');
        }
        return mask;
    };

    const onFocus = () => {
        setIsFocused(true);
        displaySsn();
    };
    const onBlur = () => {
        setIsFocused(false);
        if (!isMouseOver) {
            hideSsn();
        }
    };
    const onMouseEnter = () => {
        setIsMouseOver(true);
        displaySsn();
    };
    const onMouseLeave = () => {
        setIsMouseOver(false);
        if (!isFocused) {
            hideSsn();
        }
    };
    const displaySsn = () => {
        setIsSsnVisible(true);
        setDisplayedValue(ssn);
    };
    const hideSsn = () => {
        setIsSsnVisible(false);
        setDisplayedValue(getMask(ssn.length));
    };
    const handleSsnChange = (value: string) => {
        if (isSsnVisible) {
            setSsn(value);
            updateSocialSecurityNumber(value, entityKeyId);
            setDisplayedValue(value);
        }
    };

    return (
        <Grid item justifyContent="center" width="100%">
            <span
                onBlur={() => onBlur()}
                onFocus={() => onFocus()}
                onMouseEnter={() => onMouseEnter()}
                onMouseLeave={() => onMouseLeave()}
            >
                <NumberTextField
                    allowNegative={false}
                    data-private="lipsum"
                    disabled={isReadonly}
                    errors={errors}
                    format={isSsnVisible ? '###-##-####' : '###########'}
                    label={`${firstName}'s Social Security Number`}
                    name={entityKeyId}
                    onChange={onChange(handleSsnChange)}
                    placeholder="XXX-XX-XXXX"
                    thousandSeparator={false}
                    value={displayedValue}
                />
            </span>
        </Grid>
    );
};

export default hot(module)(SocialSecurityTaskField);
