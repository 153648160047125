import { Card, CardContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { RESET_PASSWORD_ACTION, resetPassword } from 'actions/user/resetPassword';
import ActivityIndicator from 'components/ActivityIndicator';
import Form from 'components/Form';
import FormButton from 'components/FormButton';
import PasswordInputs from 'components/passwordInputs/PasswordInputs';
import UnauthenticatedPageHeader from 'components/UnauthenticatedPageHeader';
import useQuery from 'hooks/useQuery';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { LOGIN_PATH } from 'routers/routes';

const QUERY_PARAM_COMPLETE = 'complete';
const QUERY_PARAM_TOKEN = 't';

const ResetPasswordHeader = (props: TypographyProps) => (
    <Typography
        color="grey.900"
        fontWeight="bold"
        mt={0}
        textAlign="center"
        variant="h4"
        {...props}
    />
);

const ResetPasswordPage = () => {
    const dispatch = useThunkDispatch();
    const [query] = useQuery();
    const { passwordInputs, apiError, apiErrorMessage, showActivity } = useSelector(
        (state: AppStore) => ({
            apiError: !!state.apiErrors[RESET_PASSWORD_ACTION.statusCode],
            apiErrorMessage: state.apiErrors[RESET_PASSWORD_ACTION.errorMessage],
            passwordInputs: state.passwordInputsState,
            showActivity: state.apiActivity[RESET_PASSWORD_ACTION],
        })
    );
    const isComplete = query.has(QUERY_PARAM_COMPLETE);
    const token = query.get(QUERY_PARAM_TOKEN);

    const handleSubmit = async () => {
        if (passwordInputs.isValid) {
            await dispatch(resetPassword(passwordInputs.password, token as string));
        }
    };

    return (
        <div className="mt-7 mb-7">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="6">
                        {isComplete ? (
                            <Card>
                                <UnauthenticatedPageHeader />
                                <CardContent className="p-4">
                                    <div className="text-center">
                                        <ResetPasswordHeader>
                                            Reset Password Complete
                                        </ResetPasswordHeader>
                                        <p className="text-muted">
                                            Your password has been updated.
                                            <br />
                                            You can now <Link to={LOGIN_PATH}>login</Link> with your
                                            new password.
                                        </p>
                                    </div>
                                </CardContent>
                            </Card>
                        ) : (
                            <Card>
                                <UnauthenticatedPageHeader />
                                <CardContent className="p-4">
                                    <div className="text-center">
                                        <ResetPasswordHeader>Reset Password</ResetPasswordHeader>
                                        <p className="text-muted">
                                            Enter and confirm a new password for your login.
                                        </p>
                                    </div>
                                    {apiError && (
                                        <Alert className="border-0 mt-0" variant="danger">
                                            {apiErrorMessage}
                                        </Alert>
                                    )}
                                    <Form onSubmit={handleSubmit}>
                                        <Stack gap={2}>
                                            <PasswordInputs />
                                        </Stack>
                                        <FormButton
                                            className="mt-3"
                                            disabled={!passwordInputs.isValid}
                                            label="Reset Password"
                                        />
                                    </Form>
                                </CardContent>
                                {showActivity && <ActivityIndicator />}
                            </Card>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default hot(module)(ResetPasswordPage);
