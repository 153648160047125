import { Card, CardContent } from '@mui/material';
import useUserProps from 'hooks/useUserProps';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

const WaivedCard = () => {
    const { user } = useUserProps();
    const year = user?.activeDate?.getYear();
    let headerText = "You've chosen not to use Remodel Health";
    if (hasValue(year)) {
        headerText += ` for ${year}`;
    }

    return (
        <Card>
            <CardContent>
                <Row className="align-items-center justify-content-space-between px-2">
                    <Col>
                        <DashboardCardHeader header={headerText} />
                        <p className="my-0 text-muted">
                            If you have questions about switching to Remodel Health&apos;s personal
                            benefits services, please contact your team&apos;s administrator.
                        </p>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};

export default hot(module)(WaivedCard);
