import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PathwayTypes } from 'api/generated/enums';
import { IPathway, IPathwayUser } from 'api/generated/models';
import ReactTable from 'components/reactTable/ReactTable';
import TableHeader from 'components/TableHeader';
import sumBy from 'lodash/sumBy';
import { IndicatorsCell, NameCell, UNKNOWN } from 'pages/pathwayBlueprint/PathwayTableCells';
import {
    CENTER_COLUMN_PROPS,
    getCellBasedOnInfoStatusAndPathwayType,
    getEmployeeCostColumn,
    getPlanCostColumn,
    getProjectedReimbursementColumn,
    getProjectedWageUpColumn,
    getTagsColumn,
    getWageUpActionButtonsColumn,
} from 'pages/pathwayBlueprint/PathwayTableColumns';
import {
    CurrentEmployeeCostCell,
    CurrentEmployerCostCell,
    IPathwayComparisonRowData,
    RenewalEmployeeCostCell,
    RenewalEmployerCostCell,
} from 'pages/pathwayBlueprintCostComparison/PathwayCostComparisonCells';
import React, { useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Column, FilterTypes } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { isIchraPathwayType } from 'utilities/pathways';

export const NAME_COLUMN_ID = 'name';
export const NAME_FILTER_ID = 'nameFilter';

export const filterTypes: FilterTypes<IPathwayComparisonRowData> = {
    [NAME_FILTER_ID]: (rows, _, filterValue: string) =>
        rows.filter(({ original: { user } }) =>
            user?.displayName?.toLowerCase().includes(filterValue.toLowerCase())
        ),
};

type IPathwayCostComparisonProps = {
    pathway: IPathway;
};

const PathwayCostComparison = ({ pathway }: IPathwayCostComparisonProps) => {
    const { pathwayBlueprint, userProfiles } = useSelector((state: AppStore) => ({
        pathwayBlueprint: state.pathwayBlueprint,
        userProfiles: state.userProfiles,
    }));
    const isUnknownPathway = pathway.id === UNKNOWN;
    const isIchra = isIchraPathwayType(pathway.pathwayTypeId);

    const [search, setSearch] = useState('');

    const columns = useMemo(() => {
        const columnsArray: Column<IPathwayComparisonRowData>[] = [
            {
                Cell: IndicatorsCell,
                id: 'indicators',
                maxWidth: 45,
            },
            {
                accessor: (up) => `${up.user?.lastName} ${up.user?.firstName}`,
                Cell: NameCell,
                filter: NAME_FILTER_ID,
                Header: 'Name',
                id: NAME_COLUMN_ID,
                width: 125,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ currentEmployerCost }) => currentEmployerCost,
                Cell: getCellBasedOnInfoStatusAndPathwayType(CurrentEmployerCostCell),
                Footer: ({ data }: { data: IPathwayComparisonRowData[] }) =>
                    formatCurrency(sumBy(data, (x) => x.currentEmployerCost ?? 0)),
                Header: 'Current Employer Cost',
                id: 'currentEmployerCost',
                width: 90,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ currentEmployeeCost }) => currentEmployeeCost,
                Cell: getCellBasedOnInfoStatusAndPathwayType(CurrentEmployeeCostCell),
                Footer: ({ data }: { data: IPathwayComparisonRowData[] }) =>
                    formatCurrency(sumBy(data, (x) => x.currentEmployeeCost ?? 0)),
                Header: 'Current Employee Cost',
                id: 'currentEmployeeCost',
                width: 90,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ renewalEmployerCost }) => renewalEmployerCost,
                Cell: getCellBasedOnInfoStatusAndPathwayType(RenewalEmployerCostCell),
                Footer: ({ data }: { data: IPathwayComparisonRowData[] }) =>
                    formatCurrency(sumBy(data, (x) => x.renewalEmployerCost ?? 0)),
                Header: 'Renewal Employer Cost',
                id: 'renewalEmployerCost',
                width: 90,
            },
            {
                ...CENTER_COLUMN_PROPS,
                accessor: ({ renewalEmployeeCost }) => renewalEmployeeCost,
                Cell: getCellBasedOnInfoStatusAndPathwayType(RenewalEmployeeCostCell),
                Footer: ({ data }: { data: IPathwayComparisonRowData[] }) =>
                    formatCurrency(sumBy(data, (x) => x.renewalEmployeeCost ?? 0)),
                Header: 'Renewal Employee Cost',
                id: 'renewalEmployeeCost',
                width: 90,
            },
            getTagsColumn((row) => row.fullYearTags),
        ];
        if (!isUnknownPathway) {
            columnsArray.push(
                getPlanCostColumn(
                    (row) => (isIchra ? row.totalFullYearCosts : row.fullYearTaxedUpTotalCosts),
                    pathway.pathwayTypeId === PathwayTypes.IchraCustom
                ),
                isIchra
                    ? getProjectedReimbursementColumn(
                          (row) =>
                              row.customProjectedReimbursement ??
                              row.fullYearProjectedReimbursement ??
                              0
                      )
                    : getProjectedWageUpColumn(
                          (row) => row.customProjectedWageUp ?? row.fullYearProjectedWageUp ?? 0
                      ),
                getWageUpActionButtonsColumn(true),
                getEmployeeCostColumn((row) => row.fullYearEmployeeCost ?? 0, false)
            );
        }
        return columnsArray;
    }, [isIchra, isUnknownPathway, pathway.pathwayTypeId]);

    const pathwayData = useMemo(
        () =>
            userProfiles.reduce<IPathwayComparisonRowData[]>((rows, profile) => {
                const pathwayUser = pathwayBlueprint?.pathwayUsers?.find(
                    (pu) => pu.userId === profile?.user?.userId
                );
                const isUnknown = !pathwayUser?.pathwayId && isUnknownPathway;
                const isInPathway =
                    (pathwayUser?.pathwayId === pathway.id && !isUnknownPathway) || isUnknown;
                if (pathwayBlueprint?.teamId && isInPathway) {
                    rows.push({
                        ...(pathwayUser as IPathwayUser),
                        activeWageUp: profile?.user?.activeWageUps?.find(
                            (activeWageUp) => activeWageUp.year === pathwayBlueprint?.year
                        ),
                        currentEmployeeCost: profile.memberQuoteInfo?.previousEmployeePremium,
                        currentEmployerCost: profile.memberQuoteInfo?.previousEmployerPremium,
                        disabled: pathwayBlueprint?.isCalculating,
                        pathwayBlueprintId: pathwayBlueprint?.id,
                        pathwayTypeId: pathway?.pathwayTypeId,
                        renewalEmployeeCost:
                            profile.memberQuoteInfo?.previousRenewalEmployeePremium,
                        renewalEmployerCost:
                            profile.memberQuoteInfo?.previousRenewalEmployerPremium,
                        teamId: pathwayBlueprint?.teamId,
                        user: profile?.user,
                        userId: profile?.user?.userId as string,
                        year: pathwayBlueprint?.year,
                    });
                }
                return rows;
            }, []),
        [
            isUnknownPathway,
            pathway.id,
            pathway?.pathwayTypeId,
            pathwayBlueprint?.id,
            pathwayBlueprint?.isCalculating,
            pathwayBlueprint?.pathwayUsers,
            pathwayBlueprint?.teamId,
            pathwayBlueprint?.year,
            userProfiles,
        ]
    );

    const filters = useMemo(() => [{ id: NAME_COLUMN_ID, value: search }], [search]);

    return (
        <Paper sx={{ marginBottom: 4 }}>
            <TableHeader
                disableDropdown={pathwayBlueprint?.isCalculating}
                label={<Typography variant="h5">{pathway.name}</Typography>}
                onChange={onChange(setSearch)}
                search={search}
            />
            <ReactTable
                columns={columns}
                data={pathwayData}
                filters={filters}
                filterTypes={filterTypes}
                pageSize={50}
                rowsWhenNoData={1}
            />
        </Paper>
    );
};

export default hot(module)(PathwayCostComparison);
