import { Collapse, Divider, Grid, Typography } from '@mui/material';
import { HouseholdMemberInfoDto } from 'api/generated/models';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { SingleDateMask } from 'components/DateTextField';
import Form from 'components/Form';
import Select from 'components/Select';
import TextField from 'components/TextField';
import AddressInput from 'components/addressInput/AddressInput';
import { clearAddressInputs } from 'components/addressInput/addressInputActions';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { genderList, yesOrNo } from 'reducers/options';
import { IFormErrors } from 'utilities/forms';
import { StringSchema } from 'yup';
import { RequiredBooleanSchema } from 'yup/lib/boolean';
import { OptionalObjectSchema } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';

type IHouseholdMemberForm = {
    errors?: IFormErrors<
        OptionalObjectSchema<{
            birthdate: StringSchema<string | undefined, AnyObject, string | undefined>;
            firstName: RequiredStringSchema<string | undefined>;
            gender: RequiredStringSchema<string | undefined>;
            lastName: RequiredStringSchema<string | undefined>;
            preferredName: StringSchema;
            usesTobacco: RequiredBooleanSchema<boolean | undefined>;
        }>
    >;
    handleHhmChange: (field: string, value: boolean | string) => void;
    hasSameAddress: boolean;
    hhm: HouseholdMemberInfoDto;
    hhmIndex?: number;
    hhmLabel: string;
    isNewHhm: boolean;
    isSpouse: boolean;
    saveChanges: () => void;
};

const HouseholdMemberForm = ({
    errors,
    handleHhmChange,
    hasSameAddress,
    hhm,
    hhmIndex,
    hhmLabel,
    isNewHhm,
    isSpouse,
    saveChanges,
}: IHouseholdMemberForm) => {
    const { year } = useUserProps();
    const dispatch = useThunkDispatch();

    useEffect(
        () => () => {
            dispatch(clearAddressInputs());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Form>
            <Grid container justifyContent="center" spacing={4}>
                <Grid item lg={6} xl={4} xs={12}>
                    <TextField
                        errors={errors?.firstName}
                        label="First Name"
                        name="firstName"
                        onChange={async (e) => handleHhmChange(e.target.name, e.target.value)}
                        value={hhm.firstName ?? ''}
                    />
                </Grid>
                <Grid item lg={6} xl={4} xs={12}>
                    <TextField
                        errors={errors?.lastName}
                        label="Last Name"
                        name="lastName"
                        onChange={async (e) => handleHhmChange(e.target.name, e.target.value)}
                        value={hhm.lastName ?? ''}
                    />
                </Grid>
                <Grid item lg={6} xl={4} xs={12}>
                    <TextField
                        errors={errors?.preferredName}
                        isOptional
                        label="Preferred Name"
                        name="preferredName"
                        onChange={async (e) => handleHhmChange(e.target.name, e.target.value)}
                        value={hhm.preferredName ?? ''}
                    />
                </Grid>
                <Grid item lg={6} xl={4} xs={12}>
                    <Select
                        defaultText="Select a gender"
                        defaultValue=""
                        errors={errors?.gender}
                        items={genderList}
                        label="Gender"
                        name="gender"
                        onChange={async (e) => handleHhmChange(e.target.name, e.target.value)}
                        optionText="value"
                        optionValue="id"
                        value={hhm.gender ?? ''}
                    />
                </Grid>
                <Grid item lg={6} xl={4} xs={12}>
                    <TextField
                        errors={errors?.birthdate}
                        InputProps={{ inputComponent: SingleDateMask }}
                        label="Date of Birth"
                        name="birthdate"
                        onChange={async (e) => handleHhmChange(e.target.name, e.target.value)}
                        value={hhm.birthdate ?? ''}
                    />
                </Grid>
                <Grid item lg={6} xl={4} xs={12}>
                    <Select
                        defaultText="Select Yes or No"
                        defaultValue=""
                        errors={errors?.usesTobacco}
                        helperText="Select yes if they've used tobacco products 4 or more times per week on average during the past 6 months. This can impact your plan premiums."
                        items={yesOrNo}
                        label="Tobacco Use"
                        name="usesTobacco"
                        onChange={async (e) => handleHhmChange(e.target.name, e.target.value)}
                        optionText="text"
                        optionValue="value"
                        value={hhm.usesTobacco ?? ''}
                    />
                </Grid>
                <Grid container direction="column" item xs={12}>
                    {isSpouse && (
                        <Checkbox
                            checked={[true, 'true'].includes(hhm.sameEmployer ?? false)}
                            label="Has Same Employer as Primary"
                            name="sameEmployer"
                            onChange={async (e) => handleHhmChange(e.target.name, e.target.checked)}
                        />
                    )}
                    <Checkbox
                        checked={hasSameAddress}
                        label="Has Same Address as Primary"
                        name="sameAddress"
                        onChange={async (e) => handleHhmChange(e.target.name, e.target.value)}
                    />
                </Grid>
                {!hasSameAddress && (
                    <Grid item xs={12}>
                        <Collapse in={!hasSameAddress}>
                            <Grid item xs={12}>
                                <Divider sx={{ marginBottom: 2 }} />
                            </Grid>
                            <Grid container gap={2} item xs={12}>
                                <Typography variant="h5">Address</Typography>
                                <AddressInput
                                    address={{ ...hhm.address }}
                                    extraSpacing
                                    fieldId={`addressLine1-${hhmIndex}`}
                                    year={year}
                                />
                            </Grid>
                        </Collapse>
                    </Grid>
                )}
                <Grid container item justifyContent="center" xs={12}>
                    <Button onClick={saveChanges} type="submit" variant="contained">
                        {isNewHhm ? `Add ${hhmLabel}` : 'Save'}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default hot(module)(HouseholdMemberForm);
