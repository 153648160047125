import { clearRole } from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { addRole, ADD_ROLE_ACTION } from 'actions/role/addRole';
import { editRole } from 'actions/role/editRole';
import { getRole } from 'actions/role/getRole';
import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import isUndefined from 'lodash/isUndefined';
import queryString from 'query-string';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { formatErrors, IFormErrors, validate } from 'utilities/forms';
import { object, string, ValidationError } from 'yup';

const MAX_ROLE_NAME_LENGTH = 50;
const schema = object({
    roleName: string()
        .trim()
        .required()
        .max(MAX_ROLE_NAME_LENGTH)
        .label('Role Name'),
});

type ISystemRoleModalProps = DispatchProps &
    RouteComponentProps &
    StateProps & { handleClose: () => void };

type ISystemRoleModalState = {
    errors: IFormErrors<typeof schema>;
    roleId?: string;
    roleName: string;
    shouldPopulateForm: boolean;
};

class SystemRoleModal extends React.PureComponent<ISystemRoleModalProps> {
    override state: ISystemRoleModalState = {
        errors: null,
        roleId: undefined as string | undefined,
        roleName: '',
        shouldPopulateForm: true,
    };

    static getDerivedStateFromProps(props: ISystemRoleModalProps, state: ISystemRoleModalState) {
        if (props.isRenameMode) {
            if (props.roleId !== state.roleId) {
                state.roleId = props.roleId;
                props.getRole(props.roleId);
            }

            if (state.shouldPopulateForm && !isUndefined(props.role?.name)) {
                state.shouldPopulateForm = false;
                state.roleName = props.role.name;
            }
        }
        return state;
    }

    override componentWillUnmount() {
        this.props.clearRole();
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const roleName = this.state.roleName;
            await validate(schema, { roleName });
            this.setState({ errors: null });
            if (this.props.isRenameMode) {
                await this.props.editRole(this.props.roleId, roleName);
            } else {
                await this.props.addRole(roleName);
            }
        } catch (_errors) {
            this.setState({ errors: formatErrors(_errors as ValidationError) });
        }
    };

    override render() {
        const { handleClose, isRenameMode, showActivity } = this.props;
        const { roleName, errors } = this.state;
        return (
            <Modal onHide={handleClose} scrollable show>
                <Form noValidate onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isRenameMode ? 'Rename Role' : 'Add Role'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TextField
                            autoFocus
                            className="col-12"
                            errors={errors?.roleName}
                            label="Role Name"
                            name="roleName"
                            onChange={this.onChange}
                            placeholder="Enter a role name"
                            value={roleName}
                        />
                    </Modal.Body>
                    <Modal.Footer className="centered">
                        <Button onClick={handleClose}>Close</Button>
                        <Button type="submit">{isRenameMode ? 'Save Changes' : 'Create'}</Button>
                    </Modal.Footer>
                </Form>
                {showActivity && <ActivityIndicator />}
            </Modal>
        );
    }
}

const mapStateToProps = (state: AppStore, ownProps: RouteComponentProps) => {
    const q = queryString.parse(ownProps.location.search);
    return {
        apiError: !!state.apiErrors[ADD_ROLE_ACTION.statusCode],
        apiErrorMessage: state.apiErrors[ADD_ROLE_ACTION.errorMessage],
        isRenameMode: q['renameRole'] !== undefined,
        role: state.role,
        roleId: q['roleId'] as string,
        showActivity: state.apiActivity[ADD_ROLE_ACTION],
    };
};

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    addRole: async (name: string) => dispatch(addRole(name)),
    clearRole: async () => dispatch(clearRole()),
    editRole: async (roleId: string, name: string) => dispatch(editRole(roleId, name)),
    getRole: async (roleId: string) => dispatch(getRole(roleId)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(SystemRoleModal))
);
