import ConfirmationModal from 'components/ConfirmationModal';
import { UPDATE_SCROLL_GRADIENT_INDICATOR } from 'components/ScrollGradientIndicator';
import TextArea from 'components/TextArea';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { ISurveyCoverage, saveCoverageForm } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isTrue, isTrueOrFalse } from 'utilities';
import EventEmitter from 'utilities/eventEmitter';

const HealthConditionsForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const {
        hasHouseholdPreExistingConditions,
        householdPreExistingConditionsNote,
        hasSelectedDrugs,
    } = useSelector((state: AppStore) => ({
        hasHouseholdPreExistingConditions:
            state.surveyState.coverage.hasHouseholdPreExistingConditions,
        hasSelectedDrugs: { ...state.surveyState.coverage }.selectedDrugs.length > 0,
        householdPreExistingConditionsNote:
            state.surveyState.coverage.householdPreExistingConditionsNote,
    }));

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [hasHouseholdPreExistingConditions]);

    const onSelection = useCallback(
        (hasHouseholdPreExistingConditions) => {
            const data: ISurveyCoverage = { hasHouseholdPreExistingConditions };
            if (!hasHouseholdPreExistingConditions) {
                data.householdPreExistingConditionsNote = '';
            }
            dispatch(saveCoverageForm(data));
        },
        [dispatch]
    );
    const onChange = useCallback(
        async ({ target: { value } }) =>
            dispatch(saveCoverageForm({ householdPreExistingConditionsNote: value })),
        [dispatch]
    );
    const onNoClick = useCallback(() => {
        const data: ISurveyCoverage = {
            hasHouseholdPreExistingConditions: 'true',
        };
        if (!hasHouseholdPreExistingConditions) {
            data.householdPreExistingConditionsNote = '';
        }
        dispatch(saveCoverageForm(data));
        setShowModal(false);
    }, [dispatch, hasHouseholdPreExistingConditions]);
    const onYesClick = useCallback(() => {
        setShowModal(false);
        commonProps.onSubmit();
    }, [commonProps]);
    const hasPrescriptionAndNoCondition = useMemo(
        () => hasSelectedDrugs && !hasHouseholdPreExistingConditions,
        [hasHouseholdPreExistingConditions, hasSelectedDrugs]
    );
    const handleSubmit = useCallback(() => {
        if (hasPrescriptionAndNoCondition) {
            setShowModal(true);
        } else {
            commonProps.onSubmit();
        }
    }, [commonProps, hasPrescriptionAndNoCondition]);
    const isDisabled = useMemo(
        () =>
            !isTrueOrFalse(hasHouseholdPreExistingConditions) ||
            (isTrue(hasHouseholdPreExistingConditions) && !householdPreExistingConditionsNote),
        [hasHouseholdPreExistingConditions, householdPreExistingConditionsNote]
    );
    return (
        <SurveyFormWrapper
            {...commonProps}
            ignoreSubmit={hasPrescriptionAndNoCondition}
            isDisabled={isDisabled}
            onSubmit={handleSubmit}
        >
            {showModal && (
                <ConfirmationModal
                    body="You added prescriptions, but did not list any current health conditions. Is this correct?"
                    onHide={onYesClick}
                    onNoClick={onNoClick}
                    onYesClick={onYesClick}
                    title="Is this correct?"
                />
            )}
            {children}
            <FormTitle description="It’s important you add this information so we can find a plan that meets your needs.">
                Does your household need coverage for current health conditions?
            </FormTitle>
            <YesOrNoSelect onSelection={onSelection} value={hasHouseholdPreExistingConditions} />
            {isTrue(hasHouseholdPreExistingConditions) && (
                <Row className="justify-content-center">
                    <Col md="7">
                        <TextArea
                            autoFocus
                            className="px-1 mt-4"
                            data-cy="household-pre-existing-conditions-note"
                            data-private
                            label="Describe the condition and who it’s for"
                            minRows={3}
                            onChange={onChange}
                            placeholder="I have high blood pressure."
                            value={householdPreExistingConditionsNote}
                        />
                    </Col>
                </Row>
            )}
        </SurveyFormWrapper>
    );
};

export default hot(module)(HealthConditionsForm);
