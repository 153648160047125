import { clearPathwayBlueprint } from 'actions/pathwayBlueprint/clearPathwayBlueprint';
import { clearPathwayUser } from 'actions/pathwayBlueprint/clearPathwayUser';
import useThunkDispatch from 'hooks/useThunkDispatch';
import ActiveContributionProfileAttribute from 'pages/profile/contributionProfileAttributes/ActiveContributionProfileAttribute';
import ProjectedContributionProfileAttribute from 'pages/profile/contributionProfileAttributes/ProjectedContributionProfileAttribute';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';

const ContributionProfileAttributes = () => {
    const dispatch = useThunkDispatch();

    useEffect(
        () => () => {
            dispatch(clearPathwayBlueprint());
            dispatch(clearPathwayUser());
        },
        [dispatch]
    );

    return (
        <React.Fragment>
            <ActiveContributionProfileAttribute />
            <ProjectedContributionProfileAttribute />
        </React.Fragment>
    );
};

export default hot(module)(ContributionProfileAttributes);
