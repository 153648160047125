import { listDrugsForHousehold } from 'actions/drug/listDrugsForHousehold';
import { setDrugsForUser } from 'actions/drug/setDrugsForUser';
import { listProvidersForHousehold } from 'actions/provider/listProvidersForHousehold';
import { setProvidersForUser } from 'actions/provider/setProvidersForUser';
import { SurveyTypes } from 'api/generated/enums';
import { IDrug, IMemberVerifiedInfo, IProvider, IUser } from 'api/generated/models';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import GENDERS from 'constants/genders';
import {
    getOtherCoverageData,
    otherCoverageEligibilities,
} from 'constants/otherCoverageEligibilities';
import useThunkDispatch from 'hooks/useThunkDispatch';
import CommonNotesInfo from 'pages/profile/CommonNotesInfo';
import EditDrugsModal from 'pages/profile/EditDrugsModal';
import EditProvidersModal from 'pages/profile/EditProvidersModal';
import PreExistingConditionsInfo from 'pages/profile/PreExistingConditionsInfo';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { onChange } from 'utilities/forms';

type ICoverageVerifiedInfoProps = {
    isCurrent: boolean;
    memberVerifiedInfo: IMemberVerifiedInfo | undefined;
    user: IUser | undefined;
    verifiedInfoCommonProps: ICommonProps<
        IMemberVerifiedInfo & {
            hasParentOtherCoverage?: boolean;
            hasSpouseOtherCoverage?: boolean;
        }
    >;
};
const CoverageVerifiedInfo = ({
    isCurrent,
    memberVerifiedInfo,
    user,
    verifiedInfoCommonProps,
}: ICoverageVerifiedInfoProps) => {
    const dispatch = useThunkDispatch();
    const { userId } = user ?? {};
    const { userDrugs, userProviders, canSetHasUnclaimedHouseholdMember } = useSelector(
        (state: AppStore) => ({
            canSetHasUnclaimedHouseholdMember: isRhSelector(state) || isAdvisorSelector(state),
            groupLevels: state.options.groupLevels,
            userDrugs: state.householdDrugs.filter((d) => d.entityId === userId),
            userProviders: state.householdProviders.filter((p) => p.entityId === userId),
        })
    );
    useEffect(() => {
        dispatch(listDrugsForHousehold(userId, memberVerifiedInfo?.year));
        dispatch(listProvidersForHousehold(userId, memberVerifiedInfo?.year));
    }, [dispatch, memberVerifiedInfo?.year, userId]);
    const otherCoverage = useMemo(
        () => getOtherCoverageData(memberVerifiedInfo?.otherCoverageEligibilities),
        [memberVerifiedInfo?.otherCoverageEligibilities]
    );
    const getOtherCoverageBoolValue = useCallback(
        (otherCoverageEligibility) =>
            !!otherCoverage.find((x) => x['status'] === otherCoverageEligibility)?.['status'],
        [otherCoverage]
    );
    const [isPregnant, setIsPregnant] = useState(memberVerifiedInfo?.isPregnant?.toString());
    const [usesTobacco, setUsesTobacco] = useState(memberVerifiedInfo?.usesTobacco.toString());
    const [hasUnclaimedHouseholdMember, setHasUnclaimedHouseholdMember] = useState(
        memberVerifiedInfo?.hasUnclaimedHouseholdMember?.toString() || ''
    );
    const [hasSpouseOtherCoverage, setHasSpouseOtherCoverage] = useState(
        getOtherCoverageBoolValue(otherCoverageEligibilities.SpouseEmployer)?.toString() || 'false'
    );
    const [hasParentOtherCoverage, setHasParentOtherCoverage] = useState(
        getOtherCoverageBoolValue(otherCoverageEligibilities.Parent)?.toString() || 'false'
    );
    const [
        hasInterestInFaithBasedSharingOptions,
        setHasInterestInFaithBasedSharingOptions,
    ] = useState(memberVerifiedInfo?.hasInterestInFaithBasedSharingOptions?.toString());
    const [hasHouseholdPreExistingConditions, setHasHouseholdPreExistingConditions] = useState(
        memberVerifiedInfo?.hasHouseholdPreExistingConditions.toString()
    );
    const setDrugs = useCallback((options: IDrug[]) => setDrugsForUser(userId, options), [userId]);
    const setProviders = useCallback(
        (options: IProvider[]) => setProvidersForUser(userId, options),
        [userId]
    );
    const hasCompletedStandardSurvey =
        memberVerifiedInfo?.surveyTypeCompleted === SurveyTypes.Standard;
    return (
        <React.Fragment>
            {canSetHasUnclaimedHouseholdMember && hasCompletedStandardSurvey && (
                <EditableYesNoSelectAttribute
                    {...verifiedInfoCommonProps}
                    isOptional
                    label="Has Unclaimed Household Member"
                    name="hasUnclaimedHouseholdMember"
                    onChange={onChange(setHasUnclaimedHouseholdMember)}
                    value={hasUnclaimedHouseholdMember}
                />
            )}

            {memberVerifiedInfo?.gender === GENDERS.FEMALE && hasCompletedStandardSurvey && (
                <EditableYesNoSelectAttribute
                    {...verifiedInfoCommonProps}
                    label="Pregnant"
                    name="isPregnant"
                    onChange={onChange(setIsPregnant)}
                    value={isPregnant}
                />
            )}

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                label="Tobacco User"
                name="usesTobacco"
                onChange={onChange(setUsesTobacco)}
                value={usesTobacco}
            />

            {hasCompletedStandardSurvey && (
                <React.Fragment>
                    <EditableYesNoSelectAttribute
                        {...verifiedInfoCommonProps}
                        label="Spouse Other Coverage"
                        name="hasSpouseOtherCoverage"
                        onChange={onChange(setHasSpouseOtherCoverage)}
                        value={hasSpouseOtherCoverage}
                    />

                    <EditableYesNoSelectAttribute
                        {...verifiedInfoCommonProps}
                        label="Parent Other Coverage"
                        name="hasParentOtherCoverage"
                        onChange={onChange(setHasParentOtherCoverage)}
                        value={hasParentOtherCoverage}
                    />

                    <EditableYesNoSelectAttribute
                        {...verifiedInfoCommonProps}
                        label="Interest in Faith-Based Sharing Options"
                        name="hasInterestInFaithBasedSharingOptions"
                        onChange={onChange(setHasInterestInFaithBasedSharingOptions)}
                        value={hasInterestInFaithBasedSharingOptions}
                    />
                </React.Fragment>
            )}

            <EditableYesNoSelectAttribute
                {...verifiedInfoCommonProps}
                label="Has Household Pre-Existing Conditions"
                name="hasHouseholdPreExistingConditions"
                onChange={onChange(setHasHouseholdPreExistingConditions)}
                value={hasHouseholdPreExistingConditions}
            />

            <PreExistingConditionsInfo
                hasHouseholdPreExistingConditions={hasHouseholdPreExistingConditions}
                isCurrent={isCurrent}
                memberVerifiedInfo={memberVerifiedInfo}
                readonly={verifiedInfoCommonProps.readonly}
                userId={userId}
            />
            <CommonNotesInfo
                data={userProviders}
                EditNotesModal={(close) => (
                    <EditProvidersModal
                        close={close}
                        providers={userProviders}
                        setProviders={setProviders}
                    />
                )}
                label="Preferred Providers"
                readonly={verifiedInfoCommonProps.readonly}
            />
            <CommonNotesInfo
                data={userDrugs}
                EditNotesModal={(close) => (
                    <EditDrugsModal close={close} drugs={userDrugs} setDrugs={setDrugs} />
                )}
                label="Prescriptions"
                readonly={verifiedInfoCommonProps.readonly}
            />
        </React.Fragment>
    );
};

export default hot(module)(CoverageVerifiedInfo);
