import { getActiveQuestions } from 'actions/customQuestions/getActiveQuestions';
import {
    LIST_DRUGS_FOR_HOUSEHOLD_ACTION,
    listDrugsForHousehold,
} from 'actions/drug/listDrugsForHousehold';
import {
    GET_HOUSEHOLD_MEMBERS_ACTION,
    getHouseholdMembers,
} from 'actions/householdMember/getHouseholdMembers';
import {
    LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION,
    listProvidersForHousehold,
} from 'actions/provider/listProvidersForHousehold';
import {
    GET_SINGLE_FILER_STANDARD_DEDUCTION_ACTION,
    getSingleFilerStandardDeduction,
} from 'actions/taxData/getSingleFilerStandardDeduction';
import { getSurveyInfoForUser } from 'actions/survey/getSurveyInfoForUser';
import {
    GET_CUSTOM_QUESTION_ANSWERS_ACTION,
    getCustomQuestionAnswers,
} from 'actions/user/getCustomQuestionAnswers';
import {
    GET_QUALITATIVE_ANSWERS_ACTION,
    getQualitativeAnswers,
} from 'actions/user/getQualitativeAnswers';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import ActivityIndicator from 'components/ActivityIndicator';
import { SurveyTabs } from 'constants/surveyTabs';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import Survey from 'pages/survey/Survey';
import {
    saveContactForm,
    saveCoverageForm,
    saveHousehold,
    saveMemberForm,
    saveQualitative,
    saveSurveyUserProfile,
    setIsReviewStarted,
    setTabBarIndex,
} from 'pages/survey/surveyActions';
import { verifiedInfoSelector } from 'pages/survey/surveySelectors';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { DASHBOARD_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';

const MemberRenewalSurveyPage = () => {
    const dispatch = useThunkDispatch();
    const {
        hasUser,
        hasMemberVerifiedInfo,
        isCurrent,
        isInIchraPathway,
        userId,
        userProfile,
        yearlyUserInfo,
    } = useUserProps();
    const { teamId } = useTeamProps();
    const { isLoading, survey } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            GET_USER_PROFILE_ACTION,
            GET_HOUSEHOLD_MEMBERS_ACTION,
            LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION,
            LIST_DRUGS_FOR_HOUSEHOLD_ACTION,
            GET_SINGLE_FILER_STANDARD_DEDUCTION_ACTION,
            GET_CUSTOM_QUESTION_ANSWERS_ACTION,
            GET_QUALITATIVE_ANSWERS_ACTION
        ),
        survey: verifiedInfoSelector(state),
    }));

    const activeYear = survey.incomeYear ?? yearlyUserInfo?.year;
    const title = `Verify Your Information for ${activeYear}`;
    useEffect(() => {
        if (hasValue(activeYear) && activeYear > 0) {
            dispatch(setTabBarIndex(SurveyTabs.Income));
            dispatch(setIsReviewStarted(true));
            dispatch(getUserProfile(userId, isCurrent));
            dispatch(getHouseholdMembers(userId, activeYear));
            dispatch(listProvidersForHousehold(userId, activeYear));
            dispatch(listDrugsForHousehold(userId, activeYear));
            dispatch(getSingleFilerStandardDeduction(activeYear));
            dispatch(getActiveQuestions(teamId));
            dispatch(getCustomQuestionAnswers(userId));
            dispatch(getQualitativeAnswers(userId));
        }
    }, [activeYear, dispatch, userId, isCurrent, teamId]);

    useEffect(() => {
        dispatch(getSurveyInfoForUser(userId));
    }, [dispatch, userId]);

    useEffect(() => {
        if (hasMemberVerifiedInfo) {
            dispatch(saveSurveyUserProfile(userProfile));
            dispatch(saveMemberForm(survey.member));
            dispatch(saveCoverageForm(survey.coverage));
            dispatch(saveContactForm(survey.contact));
            dispatch(saveHousehold(survey.household));
            dispatch(saveQualitative(survey.qualitative));
        }
    }, [dispatch, hasMemberVerifiedInfo, survey, userId, userProfile]);

    return !isLoading && hasUser ? (
        <Survey
            isIchraPathway={isInIchraPathway as boolean}
            redirectUrl={DASHBOARD_PATH}
            subTitle={
                <span>
                    Confirm your information is correct or update to reflect changes for{' '}
                    {activeYear}.
                    <br />
                    It&apos;s important to report changes as they may affect savings and coverage
                    options.
                </span>
            }
            title={title}
        />
    ) : (
        <ActivityIndicator />
    );
};

export default hot(module)(MemberRenewalSurveyPage);
