import { AssignableReportOptions, ReimbursementReportFrequencies } from 'api/generated/enums';
import { IAssignableReportDto } from 'api/generated/models';
import React from 'react';
import { hot } from 'react-hot-loader';

type ISubmittedExpenseDisclaimerText = {
    isHsaEligible: boolean | undefined;
    reimburseToHsa: boolean | undefined;
    reimbursementReportFrequency: number | undefined;
    reportOptions: IAssignableReportDto[];
    selectedReimbursementReportOption?: AssignableReportOptions;
};

const SubmittedExpenseDisclaimerText = ({
    isHsaEligible,
    reimburseToHsa,
    reimbursementReportFrequency,
    reportOptions,
    selectedReimbursementReportOption,
}: ISubmittedExpenseDisclaimerText) => {
    const reimburseToHsaBoolean =
        typeof reimburseToHsa === 'string' ? reimburseToHsa === 'true' : reimburseToHsa;

    const isSemiMonthlyReimbursementReportOption = reportOptions
        .find((x) => x.assignableReportOption === Number(selectedReimbursementReportOption))
        ?.termLabel?.includes('Part');
    const isSemiMonthly =
        reimbursementReportFrequency === ReimbursementReportFrequencies.SemiMonthly ||
        isSemiMonthlyReimbursementReportOption
            ? 'and 25th of each month. '
            : 'of each month. ';
    const isHsa =
        reimburseToHsaBoolean && isHsaEligible
            ? 'If you have chosen to utilize a pre-tax HSA (Health Savings Account), your administrator will deposit this amount into your HSA on your behalf. If you have not chosen to utilize an HSA, this reimbursement amount may be taxable. '
            : 'This reimbursement amount is taxable. ';

    const disclaimerContent =
        "Your Remodel Health reimbursement request has been approved and will be submitted to your organization's administrator for processing. Approved requests are submitted to your administrator on the 10th " +
        isSemiMonthly +
        isHsa +
        'Please be aware this payment is income to you and will be reported on your W-2 as wages. ' +
        'Your reimbursement has been supplemented with additional wages to account for the applicable taxes. ' +
        'Please review your personal tax situation to ensure you contribute enough during the year to cover the additional income tax liability. ' +
        'If you do not receive this reimbursement or if you have any processing questions, we encourage you to contact your payroll administrator.';

    return <React.Fragment>{disclaimerContent}</React.Fragment>;
};

export default hot(module)(SubmittedExpenseDisclaimerText);
