import { OPT_OUT_GROUP_KEY } from 'constants/pactSafeGroups';
import OptModal from 'pages/dashboard/taskLists/member/tasks/OptModal';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

type IOptOutModalProps = {
    isOptIn?: boolean;
    onHide: () => void;
    onNoClick: () => void;
    onYesClick: () => void;
    setSignature: (arg0: string) => void;
    signature: string;
};

const OptOutModal = ({ onYesClick, ...props }: IOptOutModalProps) => {
    const { teamName } = useSelector((state: AppStore) => ({
        teamName: state.current.teamProfile.team?.name,
    }));
    return (
        <OptModal
            dataCyModal="opt-out-modal"
            dataCyNo="change-to-opt-in"
            groupKey={OPT_OUT_GROUP_KEY}
            modalBody={
                <span>
                    You&apos;re electing <strong>not</strong> to utilize the services provided to
                    you by&nbsp;
                    {teamName ?? 'your organization'} from Remodel Health. You can always change
                    your mind later, however at this point you&apos;re choosing to opt-out of
                    Remodel Health.
                </span>
            }
            modalTitle="I prefer to not use Remodel Health"
            noText={
                <span>
                    Oops, I <strong>do</strong> want to use Remodel Health.
                </span>
            }
            onYesClick={onYesClick}
            submitText="Opt-out"
            {...props}
        />
    );
};

export default hot(module)(OptOutModal);
