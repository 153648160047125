import { Grid, Typography } from '@mui/material';
import {
    GroupLevels,
    HouseholdMemberTypes,
    TeamBenefitTypes,
    UserTeamBenefitTermDetailStatuses,
} from 'api/generated/enums';
import { IAncillaryBenefitDto, ISelectAncillaryBenefitRequest } from 'api/generated/models';
import { ALLOWED_ANCILLARY_SELECTION_TYPE_VALUES } from 'constants/ancillarySelection';
import { startCase } from 'lodash';
import AncillaryDisplayItem from 'pages/dashboard/ancillaryBenefitSelection/AncillaryDisplayItem';
import React from 'react';
import { IHouseholdMemberWithAge } from 'reducers/householdMembers';
import { enumToNameValueArray } from 'utilities/index';

type IGetAncillaryDisplayItems = {
    ancillaries: IAncillaryBenefitDto[];
    ancillaryDecisions: ISelectAncillaryBenefitRequest[];
    handleAncillaryDecisionsChange: (
        ancillaryDecisions: ISelectAncillaryBenefitRequest[],
        ancillaryId: string,
        type: TeamBenefitTypes,
        newStatus: UserTeamBenefitTermDetailStatuses
    ) => void;
    isReadonly: boolean;
};
export const getAncillaryDisplayItems = (
    {
        ancillaries,
        ancillaryDecisions,
        handleAncillaryDecisionsChange,
        isReadonly,
    }: IGetAncillaryDisplayItems,
    type: { label: string; value: TeamBenefitTypes }
) =>
    ancillaries
        .filter((a) => a.teamBenefitTypeId === type.value)
        .map((a, index) => (
            <React.Fragment key={a.userTermDetailId}>
                {index === 0 && (
                    <Grid item marginBottom={0} marginTop={4} xs={12}>
                        <Typography variant="h3">{type.label}</Typography>
                    </Grid>
                )}
                <Grid item mx={2} xs={12}>
                    <AncillaryDisplayItem
                        ancillary={a}
                        ancillaryDecisions={ancillaryDecisions}
                        handleAncillaryDecisionsChange={handleAncillaryDecisionsChange}
                        isReadonly={isReadonly}
                    />
                </Grid>
            </React.Fragment>
        ));

export const areAllSelectionsMade = (ancillaryDecisions: ISelectAncillaryBenefitRequest[]) =>
    ancillaryDecisions?.every((r) =>
        [
            UserTeamBenefitTermDetailStatuses.Submitted,
            UserTeamBenefitTermDetailStatuses.Waived,
            UserTeamBenefitTermDetailStatuses.Enrolled,
        ].contains(r.statusId)
    );

export const ANCILLARY_TYPES = enumToNameValueArray(TeamBenefitTypes, {
    formatName: startCase,
    nameKey: 'label',
}).filter((x) => ALLOWED_ANCILLARY_SELECTION_TYPE_VALUES.contains(x.value)) as {
    label: string;
    value: TeamBenefitTypes;
}[];

export const descriptionText = (isReadonly: boolean) =>
    isReadonly ? (
        <React.Fragment>
            <Typography textAlign="center">
                Here are the ancillary selections you&apos;ve submitted.
            </Typography>
            <Typography textAlign="center">
                If you need to make changes, please reach out to{' '}
                <a href="mailto:care@remodelhealth.com">care@remodelhealth.com</a>.{' '}
            </Typography>
        </React.Fragment>
    ) : (
        <Typography textAlign="center">
            For each benefit type, choose whether to <strong>Select</strong> or{' '}
            <strong>Waive</strong> the benefit
        </Typography>
    );

export const getStandardAncillaryCost = (
    costData: Record<string, number>,
    coveredMembers: IHouseholdMemberWithAge[]
) => {
    let coverageLevel = GroupLevels.EE;

    if (coveredMembers.length > 0) {
        const hasSpouse = coveredMembers.some(
            (x) => x.householdMemberTypeId === HouseholdMemberTypes.Spouse
        );
        const hasDependents = coveredMembers.some((x) =>
            [
                HouseholdMemberTypes.Dependent,
                HouseholdMemberTypes.Unclaimed,
                HouseholdMemberTypes.IchraChild,
            ].includes(x.householdMemberTypeId)
        );

        if (hasSpouse && hasDependents) {
            coverageLevel = GroupLevels.EF;
        } else if (hasSpouse) {
            coverageLevel = GroupLevels.ES;
        } else if (hasDependents) {
            coverageLevel = GroupLevels.EC;
        }
    }

    return costData[coverageLevel];
};
