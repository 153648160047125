import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const MedicaidOrChipStatusExamples = () => (
    <React.Fragment>
        <p>
            Submit one of the documents below if you need to confirm that you don&apos;t have
            coverage through Medicaid or CHIP.
        </p>
        <p>
            <strong>Note</strong>: Some Medicaid and CHIP programs are known by names specific to
            that state.{' '}
            <ExternalLink href="https://www.healthcare.gov/help/medicaid-and-chip-names">
                Find the name of Medicaid & CHIP programs in your state.
            </ExternalLink>
        </p>
        <ul>
            <li>
                Letter or statement from a Medicaid or CHIP agency that shows that you or your
                family members aren&apos;t enrolled in or eligible for Medicaid or CHIP
            </li>
            <li>
                Letter or statement from a Medicaid agency showing that you or a family member are
                enrolled in a Medicaid program that&apos;s not considered qualifying health coverage
            </li>

            <li>
                You can find more detailed information about{' '}
                <ExternalLink href="https://www.healthcare.gov/medicaid-limited-benefits">
                    Medicaid programs that don&apos;t provide qualifying coverage
                </ExternalLink>
                . If you send document(s) verifying enrollment in one of these programs, you may be
                able to continue your financial help for your Marketplace coverage:
                <ul>
                    <li>Medicaid coverage only for pregnancy-related services</li>
                    <li>Medicaid coverage only for family planning services</li>
                    <li>Medicaid coverage only for tuberculosis coverage</li>
                    <li>Medicaid coverage only for emergency treatment</li>
                    <li>Medicaid Demonstration Projects that cover a limited range of benefits</li>
                    <li>
                        Medicaid coverage for &quot;medically needy&quot; individuals whose income
                        is too high for traditional Medicaid and cover a limited range of benefits.
                        These programs are sometimes known as &quot;Share of Cost&quot; or
                        &quot;Spend Down&quot; programs.
                    </li>
                </ul>
            </li>

            <li>
                A letter describing your recent health coverage including:
                <ul>
                    <li>
                        The name of the Medicaid/CHIP program you were enrolled in and when your
                        coverage ended, or
                    </li>
                    <li>That you were never enrolled in Medicaid/CHIP coverage, or</li>
                    <li>
                        The name of the Medicaid/CHIP program with limited benefits that you&apos;re
                        enrolled in that would still allow you to enroll in the Marketplace with
                        help paying for coverage
                    </li>
                </ul>
            </li>
        </ul>
    </React.Fragment>
);

export default hot(module)(MedicaidOrChipStatusExamples);
