import { AppStoreThunkDispatch } from 'actions/commonAction';
import { SET_PROVIDERS_FOR_HOUSEHOLD_MEMBER_ACTION } from 'actions/provider/setProvidersForHouseholdMember';
import { SET_PROVIDERS_FOR_USER_ACTION } from 'actions/provider/setProvidersForUser';
import { IProvider } from 'api/generated/models';
import CreatableAutocomplete from 'components/creatableAutocomplete/CreatableAutocomplete';
import useIdeonProviderAutocomplete from 'components/creatableAutocomplete/useIdeonProviderAutocomplete';
import SingleButtonModal from 'components/SingleButtonModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IIdeonProviderWithSource } from 'reducers/ideonProviders';
import { hasApiActivity } from 'selectors/activity';
import {
    convertIdeonProviderToProvider,
    convertProviderToIdeonProvider,
} from 'utilities/providerSearch';

type IEditProvidersModalProps = {
    close: () => void;
    providers: IProvider[];
    setProviders: (
        options: IProvider[]
    ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;
};

const EditProvidersModal = ({ close, providers, setProviders }: IEditProvidersModalProps) => {
    const dispatch = useThunkDispatch();
    const { address } = useUserProps();
    const { selectedYear, showActivity } = useSelector((state: AppStore) => ({
        selectedYear: state.profileState.selectedYear,
        showActivity: hasApiActivity(
            state,
            SET_PROVIDERS_FOR_USER_ACTION,
            SET_PROVIDERS_FOR_HOUSEHOLD_MEMBER_ACTION
        ),
    }));
    const [selectedOptions, setSelectedOptions] = useState<IIdeonProviderWithSource[]>(
        providers.map(convertProviderToIdeonProvider)
    );
    const save = useCallback(async () => {
        await dispatch(
            setProviders(selectedOptions.map(convertIdeonProviderToProvider) as IProvider[])
        );
        close();
    }, [close, dispatch, selectedOptions, setProviders]);
    const creatableAutocompleteProps = useIdeonProviderAutocomplete({
        onChange: (_, values) => setSelectedOptions(values),
        value: selectedOptions as IIdeonProviderWithSource[],
        year: Number(selectedYear),
        zipCode: address?.zip as string,
    });
    return (
        <SingleButtonModal
            body={<CreatableAutocomplete {...creatableAutocompleteProps} />}
            buttonClickHandler={save}
            buttonText="Save"
            onHide={close}
            showActivity={showActivity}
            title="Preferred Providers"
        />
    );
};

export default hot(module)(EditProvidersModal);
