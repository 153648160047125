import { Grid } from '@mui/material';
import { IDrug, IHouseholdMemberInfoDto, IProvider, IUser } from 'api/generated/models';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Typography from 'components/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { arrayHasValue, hasValue } from 'utilities/index';

type IEntityHhmAssignmentForm = {
    entity: IDrug | IProvider;
    handleRecipientChange: (recipientIds: string[]) => void;
    hhms: IHouseholdMemberInfoDto[];
    prompt: string;
    user?: IUser;
};

const EntityHhmAssignmentForm = ({
    entity,
    handleRecipientChange,
    prompt,
    hhms,
    user,
}: IEntityHhmAssignmentForm) => {
    const [recipients, setRecipients] = useState<string[]>(entity?.entityIds ?? []);

    const handleClick = useCallback(
        (id: string) => {
            let recipientsToAdd: string[] = [];
            if (recipients.includes(id)) {
                recipientsToAdd = recipients.filter((r) => r !== id);
            } else {
                recipientsToAdd = [...recipients, id];
            }
            setRecipients(recipientsToAdd);
        },
        [recipients]
    );

    const handleSave = () => handleRecipientChange(recipients);

    const getCheckbox = useCallback(
        (id?: string, name?: string) => (
            <Grid item key={id}>
                <Checkbox
                    checked={recipients.includes(id as string)}
                    label={hasValue(name) ? name : 'label'}
                    name="memberName"
                    onChange={() => handleClick(id as string)}
                />
            </Grid>
        ),
        [handleClick, recipients]
    );

    const userAndHhmCheckboxes = useMemo(
        () => (
            <React.Fragment>
                {getCheckbox(user?.userId, user?.firstName)}
                {arrayHasValue(hhms) &&
                    hhms.map((hhm) => getCheckbox(hhm.householdMemberId as string, hhm.firstName))}
            </React.Fragment>
        ),
        [getCheckbox, hhms, user?.firstName, user?.userId]
    );

    return (
        <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            marginLeft={0}
            rowSpacing={4}
            width="100%"
        >
            <Grid item>
                <Typography>{prompt}</Typography>
            </Grid>
            <Grid container direction="row" item justifyContent="center" spacing={2}>
                {userAndHhmCheckboxes}
            </Grid>
            <Grid item marginTop={2}>
                <Button
                    disabled={!arrayHasValue(recipients)}
                    onClick={handleSave}
                    variant="contained"
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

export default hot(module)(EntityHhmAssignmentForm);
