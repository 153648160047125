import { addTeamRhFiles } from 'actions/rhFile/addTeamRhFiles';
import { GET_TEAM_PROFILE_ACTION, getTeamProfile } from 'actions/team/getTeamProfile';
import { GET_TEAM_BENEFITS_ACTION, getTeamBenefits } from 'actions/teamBenefit/getTeamBenefits';
import { ADD_TEAM_PLAN_ACTION, addTeamPlan } from 'actions/teamPlan/addTeamPlan';
import { DELETE_TEAM_PLAN_ACTION } from 'actions/teamPlan/deleteTeamPlan';
import { GET_TEAM_PLANS_ACTION, getTeamPlans } from 'actions/teamPlan/getTeamPlans';
import { CreateTeamBenefit, CreateTeamRhFile, EditTeam } from 'api/generated/permissions';
import Button from 'components/Button';
import PageHeader from 'components/PageHeader';
import PageSectionWrapper from 'components/PageSectionWrapper';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import TeamBenefitModal from 'components/teamBenefitActionButtons/TeamBenefitModal';
import TeamGroupPlanModal from 'components/TeamGroupPlanModal';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import TeamBenefitManagementCard from 'pages/teamBenefits/TeamBenefitManagementCard';
import TeamPlans from 'pages/teamBenefits/TeamPlans';
import React, { useCallback, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isTeamPayrollAdminSelector } from 'selectors/role';

const TeamBenefitsPage = () => {
    const dispatch = useThunkDispatch();
    const { hasTeamIdUrlParam, isSameTeam, teamId } = useTeamProps();
    const {
        canAddGroupPlan,
        canCreateTeamBenefits,
        canEditTeam,
        isLoadingTeamBenefits,
        isTeamPayrollAdmin,
        showActivity,
        teamBenefits,
    } = useSelector((state: AppStore) => ({
        canAddGroupPlan: hasSomePermissions(state, CreateTeamRhFile),
        canCreateTeamBenefits: hasSomePermissions(state, CreateTeamBenefit),
        canEditTeam: isSameTeam || hasSomePermissions(state, EditTeam),
        isLoadingTeamBenefits: hasApiActivity(state, GET_TEAM_BENEFITS_ACTION),
        isTeamPayrollAdmin: isTeamPayrollAdminSelector(state),
        showActivity: hasApiActivity(
            state,
            GET_TEAM_PROFILE_ACTION,
            GET_TEAM_PLANS_ACTION,
            ADD_TEAM_PLAN_ACTION,
            DELETE_TEAM_PLAN_ACTION
        ),
        teamBenefits: state.teamBenefits,
    }));
    const {
        closeModal: closeGroupPlanModal,
        isVisible: isGroupPlanModalVisible,
        openModal: openGroupPlanModal,
    } = useModalState();
    const {
        closeModal: closeTeamBenefitModal,
        isVisible: isTeamBenefitModalVisible,
        openModal: openTeamBenefitModal,
    } = useModalState();
    useEffect(() => {
        const isCurrent = !hasTeamIdUrlParam;
        dispatch(getTeamProfile(teamId, isCurrent));
        dispatch(getTeamPlans(teamId));
    }, [dispatch, hasTeamIdUrlParam, teamId]);
    const onGroupPlanSubmit = useCallback(
        async (teamPlan, summaryOfBenefitsFile) => {
            const rhFile = {
                file: summaryOfBenefitsFile,
                name: `${teamPlan.planName} Summary of Benefits`,
            };
            await dispatch(addTeamPlan(teamPlan));
            await dispatch(addTeamRhFiles(teamId, [rhFile]));
        },
        [dispatch, teamId]
    );

    useEffect(() => {
        dispatch(getTeamBenefits(teamId));
    }, [dispatch, teamId]);

    return (
        <PageSectionWrapper>
            <TableHeader
                items={[
                    {
                        dataCy: 'add-team-benefit',
                        isVisible: canCreateTeamBenefits,
                        onClick: openTeamBenefitModal,
                        text: 'Add',
                    },
                ]}
                label={`Showing ${teamBenefits.length} Total Team Benefits`}
            />
            <Col>
                <Row className="mt-2">
                    <Skeleton
                        count={4}
                        height="170px"
                        isEnabled={isLoadingTeamBenefits}
                        Wrapper={({ children }) => (
                            <Col className="p-2" lg={12} xl={6}>
                                {children}
                            </Col>
                        )}
                    >
                        {teamBenefits.map((teamBenefit) => (
                            <Col key={teamBenefit.id} lg={12} xl={6}>
                                <TeamBenefitManagementCard teamBenefit={teamBenefit} />
                            </Col>
                        ))}
                    </Skeleton>
                </Row>
                {isTeamBenefitModalVisible && (
                    <TeamBenefitModal onClose={closeTeamBenefitModal} teamId={teamId} />
                )}
                {!isTeamPayrollAdmin && (
                    <React.Fragment>
                        <PageHeader mt={2} variant="h4">
                            Previous Group Plans
                        </PageHeader>
                        <hr />
                    </React.Fragment>
                )}
                {!isTeamPayrollAdmin && (
                    <React.Fragment>
                        {canEditTeam && canAddGroupPlan && (
                            <div className="d-flex justify-content-center mt-1 mb-2">
                                <Button
                                    data-cy="add-group-plan"
                                    onClick={openGroupPlanModal}
                                    size="small"
                                    variant="outlined"
                                >
                                    Add Group Plan
                                </Button>
                            </div>
                        )}

                        {isGroupPlanModalVisible && (
                            <TeamGroupPlanModal
                                onClose={closeGroupPlanModal}
                                onSubmit={onGroupPlanSubmit}
                                teamId={teamId}
                            />
                        )}
                        {showActivity ? (
                            <Row>
                                <Col md={6}>
                                    <Skeleton count={1} height="329px" />
                                </Col>
                                <Col md={6}>
                                    <Skeleton count={1} height="329px" />
                                </Col>
                            </Row>
                        ) : (
                            <TeamPlans />
                        )}
                    </React.Fragment>
                )}
            </Col>
        </PageSectionWrapper>
    );
};

export default hot(module)(TeamBenefitsPage);
