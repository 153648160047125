import { AppStoreThunkDispatch } from 'actions/commonAction';
import { TableState } from 'react-table';
import { IUserProfile } from 'api/generated/models';
import { transformFiltersToEnumArray, transformSortByToOrderingArray } from 'utilities/people';
import { dispatchWrapper } from 'actions/clear';

export const SET_PEOPLE_PAGE_SEARCH = 'SET_PEOPLE_PAGE_SEARCH';
export const setPeoplePageSearch = (search: string) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { search },
        type: SET_PEOPLE_PAGE_SEARCH,
    });
};

export const SET_PEOPLE_PAGE_PAGINATION_PARAMS = 'SET_PEOPLE_PAGE_PAGINATION_PARAMS';
export const setPeoplePagePaginationParams = (state: TableState<IUserProfile>) => (
    dispatch: AppStoreThunkDispatch
) => {
    const {
        enrollmentStatusFilters,
        userInfoStatusFilters,
        userStatusFilters,
    } = transformFiltersToEnumArray(state.filters);
    const orderings = transformSortByToOrderingArray(state.sortBy ?? []);
    dispatch({
        data: {
            enrollmentStatusFilters,
            orderings,
            userInfoStatusFilters,
            userStatusFilters,
            page: state.pageIndex,
            pageSize: state.pageSize,
        },
        type: SET_PEOPLE_PAGE_PAGINATION_PARAMS,
    });
};

export const CLEAR_PEOPLE_PAGE_PAGINATION_PARAMS = 'CLEAR_PEOPLE_PAGE_PAGINATION_PARAMS';
export const clearPeoplePagePaginationParams = () =>
    dispatchWrapper(CLEAR_PEOPLE_PAGE_PAGINATION_PARAMS);
