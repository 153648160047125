import { clearUserBudget } from 'actions/clear';
import { addOrEditUserBudget } from 'actions/user/addOrEditUserBudget';
import { IUser } from 'api/generated/models';
import { EditUserBudget, ViewUserBudget, ViewUserWageIncrease } from 'api/generated/permissions';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import EditBudgetModal from 'pages/profile/EditBudgetModal';
import { PerLabel } from 'pages/profile/IncomeInfo';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';

export const PerMonthLabel = ({ children }: { children: ReactNode }) =>
    PerLabel({ children, unit: 'month' });

type IUserBudgetProfileAttributeProps = {
    readonly: boolean;
    user: IUser | undefined;
};
const UserBudgetProfileAttribute = ({ readonly, user }: IUserBudgetProfileAttributeProps) => {
    const dispatch = useThunkDispatch();
    const { isCurrent } = useUserProps();
    const {
        canEditUserBudget,
        canViewUserBudget,
        canViewUserWageIncrease,
        selectedYear,
        userBudget,
    } = useSelector((state: AppStore) => ({
        canEditUserBudget: state.login.up.includes(EditUserBudget),
        canViewUserBudget: isCurrent || state.login.up.includes(ViewUserBudget),
        canViewUserWageIncrease: isCurrent || state.login.up.includes(ViewUserWageIncrease),
        selectedYear: +state.profileState.selectedYear,
        userBudget: state.userBudget,
    }));

    const [showBudgetModal, setShowBudgetModal] = useState(false);
    const [isNetAmount, setIsNetAmount] = useState(false);

    useEffect(
        () => () => {
            dispatch(clearUserBudget());
        },
        [dispatch]
    );
    const save = useCallback(
        async ({ note, value }) => {
            const newUserBudget = {
                ...userBudget,
                note,
                userId: userBudget.userId ?? user?.userId,
                year: userBudget.year ?? selectedYear,
            };
            if (isNetAmount) {
                newUserBudget.amount = value;
            } else {
                newUserBudget.supplementalWageIncrease = value;
            }
            await dispatch(addOrEditUserBudget(isNetAmount, newUserBudget));
            setShowBudgetModal(false);
        },
        [dispatch, isNetAmount, selectedYear, user?.userId, userBudget]
    );
    const isReadOnly = useMemo(() => readonly || !canEditUserBudget, [canEditUserBudget, readonly]);
    const showCustom = useMemo(() => userBudget.isCustom && !isCurrent && canEditUserBudget, [
        canEditUserBudget,
        isCurrent,
        userBudget.isCustom,
    ]);
    const openBudgetModalForBudget = useCallback(() => {
        setIsNetAmount(true);
        setShowBudgetModal(true);
    }, []);
    const openBudgetModalForWageIncrease = useCallback(() => {
        setIsNetAmount(false);
        setShowBudgetModal(true);
    }, []);
    const closeBudgetModal = useCallback(() => setShowBudgetModal(false), []);
    return (
        <React.Fragment>
            {canViewUserWageIncrease && (
                <EditableProfileAttribute
                    content={
                        <span>
                            {formatCurrency(userBudget.supplementalWageIncrease)}
                            {showCustom && <span className="text-danger">*</span>}
                        </span>
                    }
                    contentNote={
                        showCustom && <div className="text-muted text-right">{userBudget.note}</div>
                    }
                    data-cy="profile-wage-increase"
                    footer={
                        <span className="text-muted">
                            The gross or before-tax amount given to the employee.
                        </span>
                    }
                    label={<PerMonthLabel>Wage+</PerMonthLabel>}
                    onClick={openBudgetModalForWageIncrease}
                    readonly={isReadOnly}
                />
            )}
            {canViewUserBudget && (
                <EditableProfileAttribute
                    content={
                        <span>
                            {formatCurrency(userBudget.amount)}
                            {showCustom && <span className="text-danger">*</span>}
                        </span>
                    }
                    contentNote={
                        showCustom && <div className="text-muted text-right">{userBudget.note}</div>
                    }
                    footer={
                        <span className="text-muted">
                            The net or after-tax amount the employee has to shop with.
                        </span>
                    }
                    label={<PerMonthLabel>Budget</PerMonthLabel>}
                    onClick={openBudgetModalForBudget}
                    readonly={isReadOnly}
                />
            )}
            {showBudgetModal && (
                <EditBudgetModal
                    close={closeBudgetModal}
                    isNetAmount={isNetAmount}
                    save={save}
                    user={user}
                    userBudget={userBudget}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(UserBudgetProfileAttribute);
