import { Stack } from '@mui/material';
import {
    getLatestYearForRates,
    GET_LATEST_YEAR_FOR_RATES_ACTION,
} from 'actions/marketplacePlan/getLatestYearForRates';
import { CreatePathwayBlueprints } from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import PageHeader from 'components/PageHeader';
import PageSectionWrapper from 'components/PageSectionWrapper';
import TableHeader from 'components/TableHeader';
import TextField from 'components/TextField';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import PathwayBlueprintModal from 'pages/pathwayBlueprints/PathwayBlueprintModal';
import PathwayBlueprintsTable from 'pages/pathwayBlueprints/PathwayBlueprintsTable';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';

const PathwayBlueprintsPage = () => {
    const dispatch = useThunkDispatch();
    const { canCreatePathwayBlueprint, isLoading } = useSelector((state: AppStore) => ({
        canCreatePathwayBlueprint: hasSomePermissions(state, CreatePathwayBlueprints),
        isLoading: hasApiActivity(state, GET_LATEST_YEAR_FOR_RATES_ACTION),
    }));

    const {
        closeModal: closeAddModal,
        isVisible: isAddModalVisible,
        openModal: openAddModal,
    } = useModalState();
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(getLatestYearForRates());
    }, [dispatch]);

    const actionItems = useMemo(
        () =>
            [
                {
                    isLoading,
                    dataCy: 'add-pathway-blueprint',
                    isVisible: canCreatePathwayBlueprint,
                    onClick: openAddModal,
                    text: 'Add',
                },
            ] as IActionButtonItems,
        [canCreatePathwayBlueprint, isLoading, openAddModal]
    );

    return (
        <PageSectionWrapper>
            <TableHeader
                items={actionItems}
                OverrideLeftSide={
                    <Stack
                        alignItems="center"
                        direction={{ md: 'row', xs: 'column' }}
                        justifyContent="center"
                        spacing={2}
                    >
                        <PageHeader variant="h4">Pathway Blueprints</PageHeader>
                        <TextField
                            className="mb-sm-0 mb-2 mt-1"
                            data-cy="search"
                            fullWidth={false}
                            name="search"
                            onChange={onChange(setSearch)}
                            placeholder="Search"
                            size="small"
                            type="search"
                            value={search}
                        />
                    </Stack>
                }
            />
            <PathwayBlueprintsTable search={search} />
            {isAddModalVisible && <PathwayBlueprintModal onClose={closeAddModal} />}
        </PageSectionWrapper>
    );
};

export default hot(module)(PathwayBlueprintsPage);
