import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import { IStringResponseDto } from 'api/generated/models';
import Form from 'components/Form';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import isUndefined from 'lodash/isUndefined';
import {
    IIchraEnrollmentTaskFlowContent,
    SLIM_MAX_WIDTH,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities/index';
import { object, string } from 'yup';

const MAX_NOTE_LENGTH = 4000;

const schema = object({
    value: string()
        .trim()
        .max(MAX_NOTE_LENGTH)
        .label('Current Health Conditions'),
});

const HealthConditionsPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, user, userId, memberVerifiedInfo } = useUserProps();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_USER_PROFILE_ACTION, LIST_VISIBLE_FLOWS_ACTION),
    }));
    const { errors, validate } = useForm(schema);
    const [initialChunk, setInitialChunk] = useState<IStringResponseDto>(currentTask.response);
    const [chunk, setChunk] = useState<IStringResponseDto>(currentTask.response);
    useEffect(() => {
        if (!hasValue(user)) {
            dispatch(getUserProfile(userId, isCurrent));
        }
        if (hasValue(user) && isUndefined(initialChunk?.value)) {
            const newChunk = {
                value: memberVerifiedInfo?.householdPreExistingConditions?.noteText ?? '',
            };
            setInitialChunk(newChunk as IStringResponseDto);
            setChunk(newChunk as IStringResponseDto);
        }
    }, [
        chunk,
        dispatch,
        initialChunk,
        isCurrent,
        memberVerifiedInfo?.householdPreExistingConditions,
        user,
        userId,
    ]);

    const handleSubmit = async () => {
        const { data, isValid } = await validate({ value: chunk.value });
        if (isValid) {
            dispatch(completeTask(currentTask.globalId, data as IStringResponseDto));
        }
    };

    return (
        <Stack direction="column" gap={3} maxWidth={SLIM_MAX_WIDTH} width="100%">
            <IchraFlowTitle title="Current Health Conditions" />

            <Skeleton
                isEnabled={isLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 2,
                            height: 40,
                            sx: { mb: 2 },
                            width: '100%',
                        },
                    ],
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 175,
                            sx: { mb: 3, mt: 2 },
                            width: '100%',
                        },
                    ],
                ]}
            >
                <Typography>
                    List current health conditions you want covered for you or anyone in your
                    household.
                </Typography>
                <Typography>
                    We ask about current conditions to help provide ongoing support and advisement
                    services.
                </Typography>
                <Form>
                    <TextField
                        errors={errors?.value}
                        fullWidth
                        label={"Describe the condition and who it's for"}
                        minRows={7}
                        multiline
                        onChange={(e) => setChunk({ value: e.target.value })}
                        value={chunk?.value}
                    ></TextField>
                </Form>
            </Skeleton>
            <IchraFlowButtons
                currentTaskId={currentTask.globalId}
                handleNext={handleSubmit}
                isLoading={isLoading}
                showPreviousButton
                showWaiveButton={false}
            />
        </Stack>
    );
};

export default hot(module)(HealthConditionsPage);
