import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { GET_FAVORITE_TEAMS_ACTION } from 'actions/favoriteTeams/getFavoriteTeams';
import { TeamStateIds } from 'api/generated/enums';
import FavoriteTeamStar from 'components/FavoriteTeamStar';
import HeaderTitle from 'components/HeaderTitle';
import Skeleton from 'components/Skeleton';
import { startCase } from 'lodash';
import { FADED_OPACITY } from 'pages/teams/TeamsPage';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_DASHBOARD_PATH, TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { enumToNameValueArray } from 'utilities/index';

const TEAM_STATE_OPTIONS = (enumToNameValueArray(TeamStateIds, {
    formatName: startCase,
    nameKey: 'label',
}) as unknown) as { label: string; value: TeamStateIds }[];

const FavoriteTeamsWidget = () => {
    const { favoriteTeams, isLoading } = useSelector((state: AppStore) => ({
        favoriteTeams: state.favoriteTeams,
        isLoading: hasApiActivity(state, GET_FAVORITE_TEAMS_ACTION),
    }));

    return (
        <Paper sx={{ marginBottom: 4, paddingTop: 2 }}>
            <Grid alignItems="center" container direction="row" justifyContent="space-between">
                <Grid item xs={5}>
                    <HeaderTitle>Your Favorite Teams</HeaderTitle>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="h5">Team Admins</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h5">Team Status</Typography>
                </Grid>
            </Grid>
            <Divider />
            <Skeleton isEnabled={isLoading}>
                {favoriteTeams.map((ft, idx, arr) => {
                    const isLastTeam = idx === arr.length - 1;
                    const path = generatePath(TEAMS_DASHBOARD_PATH, { teamId: ft.teamId });
                    return (
                        <Box
                            key={ft.teamId}
                            sx={ft.isArchived ? { opacity: FADED_OPACITY } : { opacity: 1 }}
                        >
                            <Grid
                                alignItems="center"
                                columnSpacing={0.5}
                                container
                                item
                                justifyContent="space-between"
                                paddingBottom={isLastTeam ? '0' : '16px'}
                                paddingTop="16px"
                                xs={12}
                            >
                                <Grid item justifyContent="flex-start" textAlign="left" xs={5}>
                                    <FavoriteTeamStar teamId={ft.teamId}>
                                        <Link to={path}>
                                            {ft.teamName}
                                            {ft.isArchived && ' (Archived)'}
                                        </Link>
                                    </FavoriteTeamStar>
                                </Grid>
                                <Grid item xs={5}>
                                    {ft.adminUsers?.map((adminUser, index, array) => {
                                        let text = adminUser.displayName;
                                        const adminPath = generatePath(TEAMS_PEOPLE_PROFILE_PATH, {
                                            teamId: ft.teamId,
                                            userId: adminUser.userId,
                                        });
                                        if (index !== array.length - 1) {
                                            text += ', ';
                                        }
                                        return (
                                            <Link key={ft.teamId} to={adminPath}>
                                                {text}
                                            </Link>
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        {
                                            TEAM_STATE_OPTIONS.find(
                                                (status) => status.value === ft.teamStateId
                                            )?.label
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!isLastTeam ? <Divider /> : <React.Fragment />}
                        </Box>
                    );
                })}
            </Skeleton>
        </Paper>
    );
};

export default hot(module)(FavoriteTeamsWidget);
