import { RH_TEAM_PHONE_NUMBER } from 'constants/teams';
import { useRhEmail } from 'hooks/useRhEmail';
import React from 'react';
import { hot } from 'react-hot-loader';

const IfYouHaveQuestionsFooter = () => {
    const emailAddress = useRhEmail();
    return (
        <p>
            If you have any questions, please email us at{' '}
            <a href={`mailto:${emailAddress}`}>{emailAddress}</a> or call our office at{' '}
            {RH_TEAM_PHONE_NUMBER}.
        </p>
    );
};

export default hot(module)(IfYouHaveQuestionsFooter);
