import { Card, CardContent } from '@mui/material';
import { clearPermissions, clearRole } from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { GET_PERMISSIONS_ACTION, getPermissions } from 'actions/permission/getPermissions';
import { getRole } from 'actions/role/getRole';
import { IPermission, Permission } from 'api/generated/models';
import { EditRole } from 'api/generated/permissions';
import PermissionsApi from 'api/generated/PermissionsApi';
import Checkbox from 'components/Checkbox';
import ConfirmationModal from 'components/ConfirmationModal';
import PageHeader from 'components/PageHeader';
import { PermissionSections } from 'constants/permissions';
import HTTP_STATUS from 'constants/responseStatuses';
import every from 'lodash/every';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';
import startCase from 'lodash/startCase';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import { hasValue } from 'utilities';

type IEditRolePermissionsPageProps = DispatchProps & StateProps;
type IEditRolePermissionsPageState = {
    [key: string]: boolean | string | (() => void) | undefined;
    modalBody: string;
    onYesClick: () => void;
    roleId?: string;
    shouldPopulatePermissions: boolean;
    showConfirmationModal: boolean;
    showModalActivity: boolean;
};

type IPermissionSectionName = keyof typeof PermissionSections;

class EditRolePermissionsPage extends React.PureComponent<IEditRolePermissionsPageProps> {
    override state: IEditRolePermissionsPageState = {
        modalBody: '',
        onYesClick: () => ({}),
        roleId: undefined,
        shouldPopulatePermissions: true,
        showConfirmationModal: false,
        showModalActivity: false,
    };

    static getDerivedStateFromProps(
        props: IEditRolePermissionsPageProps,
        state: IEditRolePermissionsPageState
    ) {
        if (props.roleId !== state.roleId) {
            state.roleId = props.roleId;
            props.getRole(props.roleId);
            props.getPermissions(props.roleId);
        }

        if (props.permissions.length > 0 && state.shouldPopulatePermissions) {
            state.shouldPopulatePermissions = false;
            props.permissions.forEach((permission) => {
                if (hasValue(permission.permissionId)) {
                    state[permission.permissionId] = permission.hasPermission;
                }
            });
        }

        return state;
    }

    override componentWillUnmount() {
        this.props.clearRole();
        this.props.clearPermissions();
    }

    handleCheckboxChange = (label: string) => ({
        target: { checked, name },
    }: React.ChangeEvent<HTMLInputElement>) => {
        const permissionId = name;
        this.setState({
            modalBody: checked
                ? `Are you sure you want to add the permission ${label}?`
                : `Are you sure you want to remove the permission ${label}?`,
            onYesClick: () => {
                this.onYesClick(permissionId, checked);
            },
            showConfirmationModal: true,
        });
    };

    onNoClick = () => {
        this.setState({ showConfirmationModal: false });
    };

    onYesClick = async (permissionId: string, hasPermission: boolean) =>
        this.onYesClickForMultiplePermissions([{ hasPermission, permissionId }], hasPermission);

    onYesClickForMultiplePermissions = async (
        permissionsToChange: IPermission[],
        isChecked: boolean
    ) => {
        this.setState({ showModalActivity: true });
        const promises = permissionsToChange.map(async (x) =>
            this.setPermission(x.permissionId, isChecked)
        );
        await Promise.all(promises);
        this.setState({
            showConfirmationModal: false,
            showModalActivity: false,
        });
    };

    setAllInSection = (
        sectionName: IPermissionSectionName,
        { permissions, stringsToRemove }: typeof PermissionSections[IPermissionSectionName],
        isAllChecked: boolean,
        isSomeChecked: boolean
    ) => () => {
        const permissionsToChange = this.props.permissions.filter((x) =>
            permissions.contains(x.permissionId)
        );
        const permissionsLabels = permissionsToChange
            .map((x) => this.getPermissionLabel(x.name, stringsToRemove))
            .join(', ');
        const shouldCheck = !(isAllChecked || isSomeChecked);
        this.setState({
            modalBody: shouldCheck
                ? `Are you sure you want to add the following ${sectionName} permissions: ${permissionsLabels}?`
                : `Are you sure you want to remove the following ${sectionName} permissions: ${permissionsLabels}?`,
            onYesClick: async () =>
                this.onYesClickForMultiplePermissions(permissionsToChange, shouldCheck),
            showConfirmationModal: true,
        });
    };

    async setPermission(permissionId: string | undefined, hasPermission: boolean) {
        const response = await new PermissionsApi().setPermissions(
            this.props.roleId,
            new Permission({ hasPermission, permissionId })
        );
        type IHttpStatus = typeof HTTP_STATUS[keyof typeof HTTP_STATUS];

        if (
            ([
                HTTP_STATUS.OK,
                HTTP_STATUS.CREATED,
                HTTP_STATUS.NO_CONTENT,
            ] as IHttpStatus[]).includes(response.status as IHttpStatus) &&
            hasValue(permissionId)
        ) {
            this.setState({
                [permissionId]: hasPermission,
            });
        }
    }

    getPermissionLabel = (name: string | undefined, stringsToRemove: string[]) => {
        let result = startCase(name);
        stringsToRemove.forEach((x) => (result = result.replace(x, '').trim()));
        return result;
    };

    override render() {
        if(!this.props.canEditRole) {
            return <div>You do not have permission to view this page</div>;
        }
        return (
            <React.Fragment>
                {this.state.showConfirmationModal && (
                    <ConfirmationModal
                        body={this.state.modalBody}
                        onNoClick={this.onNoClick}
                        onYesClick={this.state.onYesClick}
                        showActivity={this.state.showModalActivity}
                        title="Confirm"
                    />
                )}

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardContent className="p-4">
                                {this.props.hasRole ? (
                                    <PageHeader variant="h4">
                                        {this.props.role.name} Role Permissions
                                    </PageHeader>
                                ) : (
                                    <Col className="px-0" xs="3">
                                        <PageHeader variant="h4">
                                            <Skeleton />
                                        </PageHeader>
                                    </Col>
                                )}
                                <hr />
                                {this.props.hasApiActivity ? (
                                    <Col className="px-0" xs="3">
                                        <Skeleton count={45} />
                                    </Col>
                                ) : (
                                    <Row>
                                        {(Object.keys(
                                            PermissionSections
                                        )).map(
                                            (permissionSectionName, sectionIndex) => {
                                                const section =
                                                    PermissionSections[permissionSectionName] as typeof PermissionSections[string];
                                                const permissionsForSection = section.permissions;
                                                const isAllChecked = every(
                                                    permissionsForSection,
                                                    (x) => this.state[x]
                                                );
                                                const isSomeChecked = some(
                                                    permissionsForSection,
                                                    (x) => this.state[x]
                                                );
                                                return (
                                                    <Col
                                                        className="mb-4"
                                                        key={sectionIndex}
                                                        lg="4"
                                                        md="5"
                                                        xl="3"
                                                        xs="12"
                                                    >
                                                        <Checkbox
                                                            checked={isAllChecked}
                                                            className="mb-1"
                                                            indeterminate={isSomeChecked && !isAllChecked}
                                                            label={permissionSectionName}
                                                            onClick={this.setAllInSection(
                                                                permissionSectionName,
                                                                section,
                                                                isAllChecked,
                                                                isSomeChecked
                                                            )}
                                                        />
                                                        <Col className="pl-4">
                                                            {this.props.permissions
                                                                .filter((x) =>
                                                                    permissionsForSection.contains(
                                                                        x.permissionId
                                                                    )
                                                                )
                                                                .map((permission, index) => {
                                                                    const label = this.getPermissionLabel(
                                                                        permission.name,
                                                                        section.stringsToRemove
                                                                    );
                                                                    return (
                                                                        <div key={index}>
                                                                            <Checkbox
                                                                                checked={
                                                                                    permission.permissionId
                                                                                        ? (this
                                                                                              .state[
                                                                                              permission
                                                                                                  .permissionId
                                                                                          ] as boolean)
                                                                                        : false
                                                                                }
                                                                                className="mb-1"
                                                                                label={label}
                                                                                name={
                                                                                    permission.permissionId
                                                                                }
                                                                                onChange={this.handleCheckboxChange(
                                                                                    label
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    );
                                                                })}
                                                        </Col>
                                                    </Col>
                                                );
                                            }
                                        )}
                                    </Row>
                                )}
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: AppStore, { match }: RouteComponentProps) => ({
    canEditRole: hasSomePermissions(state, EditRole),
    hasApiActivity: state.apiActivity[GET_PERMISSIONS_ACTION],
    hasRole: !isUndefined(state.role?.name),
    permissions: state.permissions,
    role: state.role,
    roleId: get(match, 'params.roleId'),
    showModalActivity: false,
});

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    clearPermissions: async () => dispatch(clearPermissions()),
    clearRole: async () => dispatch(clearRole()),
    getPermissions: async (roleId: string) => dispatch(getPermissions(roleId)),
    getRole: async (roleId: string) => dispatch(getRole(roleId)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(EditRolePermissionsPage));
