import { Card, CardContent } from '@mui/material';
import { toggleArchiveCustomQuestion } from 'actions/customQuestions/toggleArchiveCustomQuestion';
import { ICustomQuestion } from 'api/generated/models';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const CustomQuestionCard = ({
    customQuestion,
    onClick,
}: {
    customQuestion: ICustomQuestion;
    onClick: () => void;
}) => {
    const dispatch = useThunkDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const {
        isVisible: isConfirmModalVisible,
        openModal: openConfirmModal,
        closeModal: closeConfirmModal,
    } = useModalState();
    const onModalYesClick = async () => {
        setIsLoading(true);
        await dispatch(toggleArchiveCustomQuestion(customQuestion.id, true));
        closeConfirmModal();
        setIsLoading(false);
    };

    const archive = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);
        await dispatch(toggleArchiveCustomQuestion(customQuestion.id, false));
        setIsLoading(false);
    };
    const unArchive = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        openConfirmModal();
    };

    const orderText = customQuestion.isArchived ? '' : `${customQuestion.order}.`;

    return (
        <React.Fragment>
            {isConfirmModalVisible && (
                <ConfirmationModal
                    body={
                        <React.Fragment>
                            <p>Are you sure you want to un-archive this question?</p>
                            <br />
                            <p className="font-italic">
                                This question will be added as the last question in the order.
                            </p>
                        </React.Fragment>
                    }
                    onNoClick={closeConfirmModal}
                    onYesClick={onModalYesClick}
                    showActivity={isLoading}
                    title="Confirm"
                />
            )}
            <Card onClick={onClick} sx={{ padding: 2 }}>
                <CardContent>
                    <Row className="align-items-center">
                        <Col>
                            <span className="font-weight-bold">{orderText}</span>{' '}
                            {customQuestion.text}
                        </Col>
                        <Col xs="auto">
                            <Button
                                isLoading={isLoading}
                                onClick={customQuestion.isArchived ? unArchive : archive}
                            >
                                {customQuestion.isArchived ? 'Un-Archive' : 'Archive'}
                            </Button>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default hot(module)(CustomQuestionCard);
