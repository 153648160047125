import { Chip, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IDrug, IHouseholdMemberInfoDto, IProvider, IUser } from 'api/generated/models';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { arrayHasValue } from 'utilities/index';

type IEntityHhmAssignmentCard = {
    entity: IDrug | IProvider;
    entityIds: string[];
    entityName: string;
    form: JSX.Element;
    handleEditCancelButtonClick: (name: string) => void;
    hhms: IHouseholdMemberInfoDto[];
    isExpanded: boolean;
    isPrimaryOnly: boolean;
    removeEntity: (name: string) => void;
    user: IUser;
};

const EntityHhmAssignmentCard = ({
    entity,
    entityIds,
    entityName,
    form,
    handleEditCancelButtonClick,
    hhms,
    isExpanded,
    isPrimaryOnly,
    removeEntity,
    user,
}: IEntityHhmAssignmentCard) => {
    const [recipients, setRecipients] = useState<(IHouseholdMemberInfoDto | IUser)[]>();

    useEffect(() => {
        const peopleToAdd: (IHouseholdMemberInfoDto | IUser)[] = [];
        const primaryInNeed = entityIds?.includes(user?.userId);
        const hhmsInNeed = hhms?.filter((hhm) =>
            entityIds?.includes(hhm.householdMemberId as string)
        );
        if (primaryInNeed) {
            peopleToAdd.push(user);
        }

        if (arrayHasValue(hhmsInNeed)) {
            hhmsInNeed.forEach((hhm) => peopleToAdd.push(hhm));
        }

        setRecipients(peopleToAdd);
    }, [entityIds, hhms, user]);

    return (
        <Grid
            alignItems="stretch"
            container
            direction="column"
            gap={2}
            justifyContent="space-between"
        >
            <Accordion
                disableGutters
                elevation={0}
                expanded={isExpanded}
                sx={{
                    flexDirection: 'row-reverse',
                    width: 'auto',
                }}
            >
                <AccordionSummary
                    sx={{
                        cursor: 'default !important',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid container direction="row">
                        <Grid item>
                            <Typography alignItems="center" variant="h5">
                                {entity?.name}
                            </Typography>
                            <Stack direction="row" gap={1}>
                                {recipients?.map((member) => (
                                    <Chip
                                        color="secondary"
                                        key={member?.firstName}
                                        label={member?.firstName}
                                        variant="outlined"
                                    />
                                ))}
                            </Stack>
                        </Grid>
                        <Grid
                            alignItems="center"
                            container
                            flex={1}
                            gap={2}
                            item
                            justifyContent="flex-end"
                        >
                            {!isPrimaryOnly && arrayHasValue(entityIds) && (
                                <Button
                                    color="primary"
                                    onClick={() => handleEditCancelButtonClick(entityName)}
                                    sx={{ paddingX: 3 }}
                                    variant="text"
                                >
                                    {isExpanded ? 'Cancel' : 'Edit'}
                                </Button>
                            )}
                            <Button
                                color="error"
                                onClick={() => removeEntity(entityName)}
                                sx={{ paddingX: 3 }}
                                variant="text"
                            >
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingBottom: 3 }}>
                    <Divider sx={{ marginBottom: 4 }} />
                    {form}
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default hot(module)(EntityHhmAssignmentCard);
