import { IAddress } from 'api/generated/models';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';

const AddressValue = ({ children }: { children: ReactNode }) => (
    <p className="my-0 text-right" data-private>
        {children}
    </p>
);
const AddressInfo = ({ data }: { data: IAddress | undefined }) => {
    if (!data) {
        return <React.Fragment />;
    }
    const { addressLine1, addressLine2, city, county, state, zip } = data;
    return (
        <React.Fragment>
            <AddressValue>{addressLine1}</AddressValue>
            {addressLine2 && <AddressValue>{addressLine2}</AddressValue>}
            <AddressValue>
                {city && city + ', '}
                {state}&nbsp;
                {zip}
            </AddressValue>
            <AddressValue>{county}</AddressValue>
        </React.Fragment>
    );
};
export default hot(module)(AddressInfo);
