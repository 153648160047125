import { Box } from '@mui/material';
import { getHouseholdMembers } from 'actions/householdMember/getHouseholdMembers';
import { getPathwayDataForUser } from 'actions/pathwayBlueprint/getPathwayDataForUser';
import { listAncillaryBenefits } from 'actions/teamBenefit/listAncillaryBenefits';
import { getUserProfile } from 'actions/user/getUserProfile';
import { TaskStatuses, Tasks } from 'api/generated/enums';
import { TaskDto } from 'api/generated/models';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import AdvisementNeededPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/AdvisementNeededPage';
import KeepOrChangePage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/KeepOrChangePage';
import MedicareOnlyPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/MedicareOnlyPage';
import RecommendedBenefitsPathPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/RecommendedBenefitsPathPage';
import ShopForPlansPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/ShopForPlansPage';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities/index';

const ChooseYourPlanPage = ({ currentTask }: { currentTask: TaskDto }) => {
    const { pathwayId, pathwayPlanId } = useSelector((state: AppStore) => ({
        pathwayId: state.pathwayDataForUser?.id,
        pathwayPlanId: state.pathwayDataForUser?.planId,
    }));
    const dispatch = useThunkDispatch();
    const { isCurrent, userId, user } = useUserProps();

    const nextTask = currentTask?.childTasks?.find(
        (t) => t.taskStatusId === TaskStatuses.Incomplete
    );

    let chooseYourPlanContent = <React.Fragment />;

    if (nextTask?.taskId === Tasks.Advisement || nextTask?.taskId === Tasks.ChangeAdvisement) {
        chooseYourPlanContent = <AdvisementNeededPage currentTask={currentTask} />;
    } else if (nextTask?.taskId === Tasks.MedicareOnly) {
        chooseYourPlanContent = <MedicareOnlyPage currentTask={nextTask} />;
    } else if (nextTask?.taskId === Tasks.KeepOrChange) {
        chooseYourPlanContent = <KeepOrChangePage currentTask={nextTask} />;
    } else if (nextTask?.taskId === Tasks.RecommendedBenefitsPath && hasValue(pathwayPlanId)) {
        chooseYourPlanContent = (
            <RecommendedBenefitsPathPage currentTask={nextTask} pathwayId={pathwayId} />
        );
    } else if (nextTask?.taskId === Tasks.ShopForPlans) {
        chooseYourPlanContent = <ShopForPlansPage currentTask={nextTask} />;
    }

    useEffect(() => {
        dispatch(getUserProfile(userId, isCurrent));
        dispatch(getHouseholdMembers(userId));
        dispatch(getPathwayDataForUser(userId, user?.activeDate?.getYear()));
        dispatch(listAncillaryBenefits(userId, true));
    }, [dispatch, isCurrent, user?.activeDate, userId]);

    return (
        <Box alignItems="center" display="flex" flexDirection="column" width="100%">
            {chooseYourPlanContent}
        </Box>
    );
};

export default hot(module)(ChooseYourPlanPage);
