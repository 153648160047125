import Button from '@mui/material/Button';
import { GET_TEAM_ROLES_ACTION, getTeamRoles } from 'actions/role/getTeamRoles';
import { IRole } from 'api/generated/models';
import { EditUserRoles } from 'api/generated/permissions';
import PageSectionWrapper from 'components/PageSectionWrapper';
import ReactTable from 'components/reactTable/ReactTable';
import TableHeader from 'components/TableHeader';
import { push } from 'connected-react-router';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { ROLES_EDIT_PATH, TEAMS_ROLES_EDIT_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';
import { DEFAULT_ACTIONS_COLUMN_PROPS } from 'utilities/reactTable';

const NAME_COLUMN_ID = 'roleName';

const ActionButton = ({ row: { original } }: Cell<IRole>) => {
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { teamId } = useTeamProps();
    const { enableEditUserRoles } = useSelector((state: AppStore) => ({
        enableEditUserRoles: state.login.up.includes(EditUserRoles),
    }));
    const editRoleUrl = isTeamManagementPage
        ? TEAMS_ROLES_EDIT_PATH.replace(':teamId', teamId)
        : ROLES_EDIT_PATH;
    return (
        <div>
            {enableEditUserRoles && (
                <Button
                    component={Link}
                    to={editRoleUrl.replace(':roleId', original.roleId)}
                    variant="outlined"
                >
                    Edit
                </Button>
            )}
        </div>
    );
};

const columns: Column<IRole>[] = [
    {
        accessor: (row) => row.name,
        filter: 'text',
        Header: 'Role Name',
        id: NAME_COLUMN_ID,
    },
    {
        accessor: (row) => row.userCount,
        Header: 'User Count',
        id: 'userCount',
        maxWidth: 115,
    },
    {
        ...DEFAULT_ACTIONS_COLUMN_PROPS,
        Cell: ActionButton,
    },
];

const RolesPage = () => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { canEditUserRoles, showActivity, teamRoles } = useSelector((state: AppStore) => ({
        canEditUserRoles: hasSomePermissions(state, EditUserRoles),
        showActivity: hasApiActivity(state, GET_TEAM_ROLES_ACTION),
        teamRoles: state.teamRoles,
    }));

    const [search, setSearch] = useState('');

    if (!canEditUserRoles) {
        dispatch(push('/'));
    }

    useEffect(() => {
        dispatch(getTeamRoles(teamId));
    }, [dispatch, teamId]);

    const filters = useMemo(() => [{ id: NAME_COLUMN_ID, value: search }], [search]);

    return (
        <PageSectionWrapper>
            <TableHeader onChange={onChange(setSearch)} search={search} />
            <ReactTable
                columns={columns}
                data={teamRoles}
                filters={filters}
                loading={showActivity}
            />
        </PageSectionWrapper>
    );
};

export default hot(module)(RolesPage);
