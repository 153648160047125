import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HomeIcon from '@mui/icons-material/Home';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Logo } from 'components/Logo';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { DASHBOARD_PATH, MEMBER_SURVEY_PATH } from 'routers/routes';
import { isTeamAdminSelector } from 'selectors/role';

const MemberSurveyIntroPage = () => {
    const { isTeamAdmin } = useSelector((state: AppStore) => ({
        isTeamAdmin: isTeamAdminSelector(state),
    }));
    return (
        <Paper sx={{ textAlign: 'center' }}>
            <Logo height="70" />
            <Typography my={3} variant="h3">
                Benefits Survey
            </Typography>
            <p className="text-muted mt-0 mb-3">
                The information you provide by filling out this survey will be used to analyze and
                evaluate the best benefits options to fit your needs.
                <br />
                <br />
                Your answers will not be shared with your employer.
            </p>
            <Button
                component={Link}
                data-cy="start-survey-link"
                startIcon={<ArrowForwardIcon />}
                sx={{ m: 2 }}
                to={MEMBER_SURVEY_PATH}
                variant="outlined"
            >
                Begin Survey
            </Button>

            {isTeamAdmin && (
                <Button
                    component={Link}
                    startIcon={<HomeIcon />}
                    sx={{ m: 2 }}
                    to={DASHBOARD_PATH}
                    variant="outlined"
                >
                    Go to Dashboard
                </Button>
            )}
        </Paper>
    );
};

export default hot(module)(MemberSurveyIntroPage);
