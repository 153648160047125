import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const TricareCoverageStatusExamples = () => (
    <React.Fragment>
        <p>
            Submit one of the documents below if you need to confirm that you don’t have coverage
            through TRICARE.
        </p>
        <ul>
            <li>
                Letter or statement from TRICARE that shows the expiration or un-enrollment date of
                previous health coverage
            </li>
            <li>
                Letter or statement from TRICARE that confirms ineligibility for health coverage
            </li>
            <li>
                Letter, statement, or other document indicating a life change event (like divorce)
                that would make you or a family member ineligible for TRICARE coverage
            </li>
            <li>
                Letter or statement from TRICARE or other government agency showing that you or a
                family member are enrolled in a TRICARE program that’s not considered qualifying
                health coverage. If you send document(s) verifying enrollment in one of these
                programs, you may be able to continue your Marketplace coverage with help paying for
                coverage:
                <ul>
                    <li>TRICARE Plus</li>
                    <li>Direct care</li>
                    <li>Line-of-duty care</li>
                    <li>Transitional care for service-related conditions</li>
                    <li>
                        TRICARE coverage limited to space-available care in a facility of the
                        uniformed services for individuals excluded from TRICARE coverage for care
                        from private sector providers.
                    </li>
                </ul>
            </li>
        </ul>
        <p>
            <strong>What if I don’t have any of those documents?</strong>
        </p>
        <p>
            You can submit a letter describing when your enrollment in TRICARE coverage ended or
            that you were never enrolled in TRICARE. The Marketplace will take your letter into
            consideration.{' '}
            <ExternalLink href="https://www.healthcare.gov/downloads/letter-of-explanation-application-info.pdf">
                You can use this template
            </ExternalLink>
            .
        </p>
    </React.Fragment>
);

export default hot(module)(TricareCoverageStatusExamples);
