import { TeamBenefitTypes, UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import { IUserTeamBenefitTermDetailDto } from 'api/generated/models';
import { EditSubmittedExpense, SubmitExpense } from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import { push } from 'connected-react-router';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import SubmitExpenseModal from 'pages/benefits/SubmitExpenseModal';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import {
    REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
    TEAMS_PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
} from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { isRhSelector } from 'selectors/role';
import { isTodayBefore, maxDateString } from 'utilities/moment';

type IMemberTeamBenefitActionsProps = {
    userTeamBenefitTermDetail?: IUserTeamBenefitTermDetailDto;
};

const handleReimbursementProgramManagementToggle = (
    canEditSubmittedExpense: boolean,
    useReimbursementProgramManagement: boolean | undefined,
    hasPermissionOrIsSameUser: boolean,
    isOnReimbursementProgram: boolean,
    isBeforeBenefitCoverageStartDate: boolean
) => {
    if (hasPermissionOrIsSameUser && isOnReimbursementProgram) {
        if (
            !canEditSubmittedExpense &&
            (!useReimbursementProgramManagement || isBeforeBenefitCoverageStartDate)
        ) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

const MemberTeamBenefitActions = ({
    userTeamBenefitTermDetail,
}: IMemberTeamBenefitActionsProps) => {
    const dispatch = useThunkDispatch();
    const { teamId, useReimbursementProgramManagement } = useTeamProps();
    const { isCurrent, userId } = useUserProps();
    const { canEditSubmittedExpense, canSubmitExpense, isRh } = useSelector((state: AppStore) => ({
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        canSubmitExpense: hasSomePermissions(state, SubmitExpense),
        isRh: isRhSelector(state),
    }));

    const {
        isVisible: isSubmitExpenseModalVisible,
        openModal: openSubmitExpenseModal,
        closeModal: closeSubmitExpenseModal,
    } = useModalState();

    const hasPermissionOrIsSameUser = canSubmitExpense || isCurrent;
    const isOnReimbursementProgram =
        userTeamBenefitTermDetail?.teamBenefit?.teamBenefitType ===
            TeamBenefitTypes.ReimbursementProgram &&
        userTeamBenefitTermDetail?.statusId === UserTeamBenefitTermDetailStatuses.Enrolled;
    const isBeforeBenefitCoverageStartDate = isTodayBefore(
        (userTeamBenefitTermDetail?.coverageStartDate as string) ?? maxDateString
    );

    const actions: IActionButtonItems = [
        {
            isVisible: handleReimbursementProgramManagementToggle(
                canEditSubmittedExpense,
                useReimbursementProgramManagement,
                hasPermissionOrIsSameUser,
                isOnReimbursementProgram,
                isBeforeBenefitCoverageStartDate
            ),
            onClick: openSubmitExpenseModal,
            text: 'Submit Expense',
        },
        {
            isVisible:
                (useReimbursementProgramManagement || canEditSubmittedExpense) &&
                userTeamBenefitTermDetail?.teamBenefit?.teamBenefitTypesCarrier
                    ?.teamBenefitTypeId === TeamBenefitTypes.ReimbursementProgram,
            onClick: () =>
                dispatch(
                    push(
                        isRh
                            ? generatePath(TEAMS_PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH, {
                                  teamId,
                                  userId,
                              })
                            : generatePath(REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH)
                    )
                ),
            text: 'View Submitted Expenses',
        },
    ];

    return (
        <React.Fragment>
            {isSubmitExpenseModalVisible && (
                <SubmitExpenseModal onClose={closeSubmitExpenseModal} />
            )}
            <ActionButtons items={actions} />
        </React.Fragment>
    );
};

export default hot(module)(MemberTeamBenefitActions);
