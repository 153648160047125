import { TeamBenefitTypes } from 'api/generated/enums';
import { ITeamBenefit, ITeamBenefitWithTermDetailsDto } from 'api/generated/models';
import { EditTeamBenefit, ViewTeamBenefit } from 'api/generated/permissions';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';

export const useTeamBenefitActionButtons = (
    teamBenefit: ITeamBenefit | ITeamBenefitWithTermDetailsDto
) => {
    const { canEditTeamBenefits, canViewTeamBenefits } = useSelector((state: AppStore) => ({
        canEditTeamBenefits: hasSomePermissions(state, EditTeamBenefit),
        canViewTeamBenefits: hasSomePermissions(state, ViewTeamBenefit),
    }));

    const isComplianceMec =
        teamBenefit.teamBenefitTypesCarrier?.teamBenefitTypeId ===
        TeamBenefitTypes.ComplianceMinimumEssentialCoverage;

    const canViewOrEdit = canViewTeamBenefits || canEditTeamBenefits;
    const canManage = canViewOrEdit && !isComplianceMec;
    const canViewButNotEdit = canViewTeamBenefits && !canEditTeamBenefits;

    return { canEditTeamBenefits, canManage, canViewButNotEdit, canViewOrEdit };
};
