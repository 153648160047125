import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { HouseholdMemberTypes } from 'api/generated/enums';
import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

type IHouseholdMemberCardProps = ISurveyHouseholdMemberDto & { onClick: () => void };
const HouseholdMemberCard = ({
    dateOfBirth,
    firstName,
    householdMemberTypeId,
    lastName,
    onClick,
}: IHouseholdMemberCardProps) => {
    const isSpouse = useMemo(() => householdMemberTypeId === HouseholdMemberTypes.Spouse, [
        householdMemberTypeId,
    ]);
    return (
        <Card
            className="bg-secondary-65-lighten-hover clickable"
            data-cy="household-member-card"
            onClick={onClick}
            sx={{ padding: 0 }}
        >
            <CardContent sx={{ padding: 2 }}>
                <Row className="mx-sm-3">
                    <Col>
                        <Typography
                            color={(theme) => theme.palette.primary.main}
                            m={isSpouse ? 0 : ''}
                            variant="h3"
                        >
                            {firstName} {lastName}
                        </Typography>
                        {isSpouse && <span className="text-muted">Spouse</span>}
                    </Col>
                    <Col>
                        <Typography
                            color={(theme) => theme.palette.primary.main}
                            textAlign="right"
                            variant="h3"
                        >
                            Age: {dateOfBirth?.getAge()}
                        </Typography>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};

export default hot(module)(HouseholdMemberCard);
