import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    patchHouseholdMember,
    PATCH_HOUSEHOLD_MEMBER_ACTION,
} from 'actions/householdMember/patchHouseholdMember';
import {
    getPathwayBlueprintDataForUser,
    GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
} from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { getTaxData, GET_TAX_DATA_ACTION } from 'actions/taxData/getTaxData';
import { getUserBudget, GET_USER_BUDGET_ACTION } from 'actions/user/getUserBudget';
import {
    patchMemberVerifiedInfo,
    PATCH_MEMBER_VERIFIED_INFO_ACTION,
} from 'actions/user/patchMemberVerifiedInfo';
import { HouseholdMemberTypes } from 'api/generated/enums';
import { IMemberVerifiedInfo } from 'api/generated/models';
import { ViewPathwayBlueprints, ViewTaxData } from 'api/generated/permissions';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import useContributionProps from 'hooks/useContributionProps';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import isUndefined from 'lodash/isUndefined';
import React, { ReactNode, useCallback, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IHouseholdMemberWithAge } from 'reducers/householdMembers';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { getShouldShowMayIncludeAdditionalIncomeMessage } from 'selectors/memberVerifiedInfo';
import { stringToFloat } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { onChange } from 'utilities/forms';
import {
    calculateHouseholdIncome,
    getDisplayFirstName,
    getHouseholdMemberBelowStandardDeductionMessage,
    hasMemberType,
    isDependentAndOverStandardDeduction,
    isDependentAndUnderOrEqualToStandardDeduction,
} from 'utilities/household';
import { number, object } from 'yup';

const Sign = ({ children, variant = 'default' }: { children: ReactNode; variant: string }) => (
    <span className={`text-${variant}`}>{children}</span>
);

const Plus = () => <Sign variant="primary">+</Sign>;

const schema = object({
    deductions: number()
        .transform(stringToFloat)
        .min(0)
        .required()
        .label('Deductions'),
    employeeIncome: number()
        .transform(stringToFloat)
        .min(0)
        .required()
        .label('Employee Salary'),
});

type IEditHouseholdIncomeModalProps = {
    close: () => void;
};

const EditHouseholdIncomeModal = ({ close }: IEditHouseholdIncomeModalProps) => {
    const dispatch = useThunkDispatch();
    const userProps = useUserProps();
    const { hasAnyContributionInfo } = useContributionProps();
    const { isCurrent, memberVerifiedInfo, userId } = userProps;
    const {
        canViewPathwayBlueprints,
        canViewTaxData,
        householdMembers,
        isLoading,
        selectedYear,
        shouldShowMayIncludeAdditionalIncomeMessage,
        singleFilerStandardDeduction,
    } = useSelector((state: AppStore) => ({
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
        canViewTaxData: hasSomePermissions(state, ViewTaxData),
        householdMembers: state.householdMembers.filter(
            (x) => x.householdMemberTypeId !== HouseholdMemberTypes.Unclaimed
        ),
        isLoading: hasApiActivity(
            state,
            PATCH_HOUSEHOLD_MEMBER_ACTION,
            PATCH_MEMBER_VERIFIED_INFO_ACTION,
            GET_USER_BUDGET_ACTION,
            GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
            GET_TAX_DATA_ACTION
        ),
        selectedYear: state.profileState.selectedYear,
        shouldShowMayIncludeAdditionalIncomeMessage: getShouldShowMayIncludeAdditionalIncomeMessage(
            state,
            userProps
        ),
        singleFilerStandardDeduction: state.singleFilerStandardDeduction,
    }));
    const getInitialValue = useCallback((value) => (isUndefined(value) ? null : value), []);
    const [deductions, setDeductions] = useState(getInitialValue(memberVerifiedInfo?.deductions));
    const [additionalIncome, setAdditionalIncome] = useState(
        getInitialValue(memberVerifiedInfo?.additionalIncome)
    );
    const [employeeIncome, setEmployeeIncome] = useState(
        getInitialValue(memberVerifiedInfo?.employeeIncome)
    );
    const [members, setMembers] = useState(householdMembers);
    const { errors, validate } = useForm(schema);

    const save = useCallback(async () => {
        const { data, isValid } = await validate({
            additionalIncome,
            deductions,
            employeeIncome,
        });
        if (isValid) {
            for (const { householdMemberId, income } of members) {
                await dispatch(patchHouseholdMember(householdMemberId, +selectedYear, { income }));
            }
            await dispatch(
                patchMemberVerifiedInfo(
                    memberVerifiedInfo?.memberVerifiedInfoId,
                    userId,
                    +selectedYear,
                    data as Partial<IMemberVerifiedInfo>,
                    isCurrent
                )
            );
            await dispatch(getUserBudget(userId, +selectedYear));
            if (hasAnyContributionInfo && canViewPathwayBlueprints) {
                await dispatch(getPathwayBlueprintDataForUser(userId, +selectedYear));
            }
            if (canViewTaxData) {
                await dispatch(getTaxData(userId, +selectedYear));
            }
            close();
        }
    }, [
        additionalIncome,
        canViewPathwayBlueprints,
        canViewTaxData,
        close,
        deductions,
        dispatch,
        employeeIncome,
        hasAnyContributionInfo,
        isCurrent,
        memberVerifiedInfo?.memberVerifiedInfoId,
        members,
        selectedYear,
        userId,
        validate,
    ]);
    const onHouseholdMemberIncomeChange = (member: IHouseholdMemberWithAge) => ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        member.income = Number(value);
        setMembers([...members]);
    };
    const hasSpouse = hasMemberType(householdMembers, HouseholdMemberTypes.Spouse);
    const dependentsOverStandardDeduction = members.filter((m) =>
        isDependentAndOverStandardDeduction(m, m?.income, singleFilerStandardDeduction)
    );

    const dependentsUnderStandardDeduction = members.filter((m) =>
        isDependentAndUnderOrEqualToStandardDeduction(m, m?.income, singleFilerStandardDeduction)
    );

    return (
        <Modal onHide={close} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Edit Household Income</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="household-income-modal-form" isLoading={isLoading} onSubmit={save}>
                    {shouldShowMayIncludeAdditionalIncomeMessage && (
                        <Alert variant="danger">
                            Individual income values may include additional income beyond the salary
                        </Alert>
                    )}
                    <Stack gap={2}>
                        <CurrencyTextField
                            autoFocus
                            errors={errors?.employeeIncome}
                            label="Employee Salary"
                            onChange={onChange(setEmployeeIncome)}
                            value={employeeIncome}
                        />
                        {members.map((member) => (
                            <CurrencyTextField
                                isOptional
                                key={member.householdMemberId}
                                label={`${getDisplayFirstName(member).trim()}'s ${
                                    member.householdMemberTypeId === HouseholdMemberTypes.Spouse
                                        ? 'Salary'
                                        : 'Income'
                                }`}
                                onChange={onHouseholdMemberIncomeChange(member)}
                                placeholder={`Enter ${getDisplayFirstName(member).trim()}'s ${
                                    member.householdMemberTypeId === HouseholdMemberTypes.Spouse
                                        ? 'salary'
                                        : 'income'
                                }`}
                                value={member.income?.toString() ?? ''}
                            />
                        ))}
                        <CurrencyTextField
                            isOptional
                            label="Additional Income"
                            onChange={onChange(setAdditionalIncome)}
                            value={additionalIncome}
                        />
                        <CurrencyTextField
                            errors={errors?.deductions}
                            label="Total Deductions"
                            onChange={onChange(setDeductions)}
                            value={deductions}
                        />
                    </Stack>
                    <Typography my={3} textAlign="center" variant="h5">
                        Household Income:&nbsp;
                        {formatCurrency(
                            calculateHouseholdIncome(
                                { additionalIncome, deductions, employeeIncome },
                                members,
                                singleFilerStandardDeduction
                            )
                        )}
                    </Typography>
                    <p className="text-center">
                        Employee Salary
                        {hasSpouse && (
                            <span>
                                {' '}
                                <Plus /> Spouse Salary
                            </span>
                        )}
                        {dependentsOverStandardDeduction.map((m) => (
                            <span key={m.householdMemberId}>
                                {' '}
                                <Plus /> {getDisplayFirstName(m)}&apos;s income
                            </span>
                        ))}
                        <span>
                            {' '}
                            <Plus /> Additional Income
                        </span>{' '}
                        <Sign variant="danger">-</Sign> Deductions
                    </p>
                    {dependentsUnderStandardDeduction.length > 0 && (
                        <p className="text-center">
                            {getHouseholdMemberBelowStandardDeductionMessage(
                                dependentsUnderStandardDeduction,
                                +selectedYear
                            )}
                        </p>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={close}>Cancel</Button>
                <Button form="household-income-modal-form" isLoading={isLoading} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(EditHouseholdIncomeModal);
