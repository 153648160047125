import { IBasicSubmittedExpenseDto } from 'api/generated/models';
import React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { TEAMS_PEOPLE_PROFILE_PATH, TEAMS_PROFILE_PATH } from 'routers/routes';

export const MemberNameCell = ({
    row: {
        original: { memberName, teamId, userId },
    },
}: Cell<IBasicSubmittedExpenseDto, unknown>) => (
    <Link
        data-cy="member-name"
        onClick={(e) => e.stopPropagation()}
        to={generatePath(TEAMS_PEOPLE_PROFILE_PATH, { teamId, userId })}
    >
        {memberName}
    </Link>
);

export const TeamNameCell = ({
    row: {
        original: { teamName, teamId },
    },
}: Cell<IBasicSubmittedExpenseDto, unknown>) => (
    <Link
        data-cy="team-name"
        onClick={(e) => e.stopPropagation()}
        to={generatePath(TEAMS_PROFILE_PATH, { teamId })}
    >
        {teamName}
    </Link>
);
