import { RhManagementTypes } from 'api/generated/enums';
import { ITeamBenefit, ITeamBenefitDto } from 'api/generated/models';
import TeamBenefitCardBase, {
    ITeamBenefitField,
    eligibilityWaitingDaysField,
    groupPlanIdField,
    nextMemberEnrollmentPeriodField,
} from 'components/TeamBenefitCardBase';
import TeamBenefitActionButtons from 'components/teamBenefitActionButtons/TeamBenefitActionButtons';
import startCase from 'lodash/startCase';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';

type ITeamBenefitManagementCardProps = {
    teamBenefit: ITeamBenefit | ITeamBenefitDto;
};

const TeamBenefitManagementCard = ({ teamBenefit }: ITeamBenefitManagementCardProps) => {
    const teamBenefitInfoRows: ITeamBenefitField[] = [
        groupPlanIdField(teamBenefit),
        {
            isVisible: hasValue(teamBenefit?.nextRenewableDate),
            label: 'Next Renewal Date',
            value: formatDateForDisplay(teamBenefit?.nextRenewableDate),
        },
        nextMemberEnrollmentPeriodField(teamBenefit),
        {
            isVisible: hasValue(teamBenefit?.rhManagementDate),
            label: 'RH Management Date',
            value: formatDateForDisplay(teamBenefit?.rhManagementDate),
        },
        eligibilityWaitingDaysField(teamBenefit),
        {
            isVisible: hasValue(teamBenefit?.rhManagementTypeId),
            label: 'RH Management Type',
            value: startCase(
                RhManagementTypes[teamBenefit?.rhManagementTypeId as RhManagementTypes]
            ),
        },
    ];

    return (
        <TeamBenefitCardBase
            actions={<TeamBenefitActionButtons teamBenefit={teamBenefit} />}
            endDate={teamBenefit.endDate}
            fields={teamBenefitInfoRows}
            showRecordCreatedDate
            startDate={teamBenefit.startDate}
            teamBenefit={teamBenefit}
        />
    );
};

export default hot(module)(TeamBenefitManagementCard);
