import { saveCompletedTeamTask } from 'actions/task/saveCompletedTeamTask';
import { editTeamAddress } from 'actions/team/editTeamAddress';
import { getDefaultMemberDataYear } from 'actions/user/getDefaultMemberDataYear';
import { getUserProfiles } from 'actions/user/getUserProfiles';
import { TaskItems } from 'api/generated/enums';
import { IAddress, ITaskItem } from 'api/generated/models';
import AddressModal from 'components/AddressModal';
import { taskItemIds, taskListTypes } from 'constants/tasks';
import { RH_TEAM_LAUNCH_EMAIL } from 'constants/teams';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import ReviewLaunchDetailsModal from 'pages/dashboard/taskLists/ReviewLaunchDetailsModal';
import SomethingIsIncorrectModal from 'pages/dashboard/taskLists/SomethingIsIncorrectModal';
import TaskListContainer from 'pages/dashboard/taskLists/TaskListContainer';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import TeamInfoModal from 'pages/dashboard/taskLists/TeamInfoModal';
import TeamRosterModal from 'pages/dashboard/taskLists/TeamRosterModal';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getYearOrDefault } from 'utilities/year';

const TeamAdminLaunchTaskList = ({ completedTasks }: { completedTasks: ITaskItem[] }) => {
    const dispatch = useThunkDispatch();
    const { activeDate, teamId, teamProfile } = useTeamProps();
    const { defaultMemberDataYear, launchDetails, userProfiles } = useSelector(
        (state: AppStore) => ({
            defaultMemberDataYear: state.defaultMemberDataYear,
            launchDetails: state.launchDetails,
            userProfiles: state.userProfiles,
        })
    );

    const {
        closeModal: closeAddressModal,
        isVisible: isAddressModalVisible,
        openModal: openAddressModal,
    } = useModalState();
    const {
        closeModal: closeReviewLaunchDetailsModal,
        isVisible: isReviewLaunchDetailsModalVisible,
        openModal: openReviewLaunchDetailsModal,
    } = useModalState();
    const {
        closeModal: closeRosterModal,
        isVisible: isRosterModalVisible,
        openModal: openRosterModal,
    } = useModalState();
    const {
        closeModal: closeSomethingIsIncorrectModal,
        isVisible: isSomethingIsIncorrectModalVisible,
        openModal: openSomethingIsIncorrectModal,
    } = useModalState();
    const {
        closeModal: closeTeamInfoModal,
        isVisible: isTeamInfoModalVisible,
        openModal: openTeamInfoModal,
    } = useModalState();

    useEffect(() => {
        dispatch(getUserProfiles(teamId));
    }, [dispatch, teamId]);

    useEffect(() => {
        dispatch(getDefaultMemberDataYear());
    }, [dispatch]);

    const renderTaskItems = () => {
        const items = [];
        const enableReviewRosterTask =
            completedTasks.filter(
                (x) =>
                    x.taskItemId === taskItemIds.VerifyTeamInfo ||
                    x.taskItemId === taskItemIds.ReviewLaunchTimeline
            ).length === 2;

        items.push(
            <TaskListItem
                action={openReviewLaunchDetailsModal}
                buttonText="Review"
                complete={completedTasks.some(
                    (x) => x.taskItemId === taskItemIds.ReviewLaunchTimeline
                )}
                data-cy="review-launch-timeline"
                description="Review the details around your Launch."
                enabled
                key={taskItemIds.ReviewLaunchTimeline}
                showButton
                taskId={taskItemIds.ReviewLaunchTimeline}
                title="Review Launch Timeline"
            />
        );
        items.push(
            <TaskListItem
                action={openTeamInfoModal}
                buttonText="Verify"
                complete={completedTasks.some((x) => x.taskItemId === taskItemIds.VerifyTeamInfo)}
                data-cy="verify-your-team-info"
                description="Please make sure your team information is accurate."
                enabled
                key={taskItemIds.VerifyTeamInfo}
                showButton
                taskId={taskItemIds.VerifyTeamInfo}
                title="Verify Your Team Info"
            />
        );

        items.push(
            <TaskListItem
                action={openRosterModal}
                buttonText="Verify"
                complete={completedTasks.some((x) => x.taskItemId === taskItemIds.VerifyTeamRoster)}
                data-cy="verify-roster"
                description="Ensure employee list is accurate."
                enabled={enableReviewRosterTask}
                key={taskItemIds.VerifyTeamRoster}
                showButton
                taskId={taskItemIds.VerifyTeamRoster}
                title="Verify Roster"
            />
        );

        return items;
    };

    const handleEditAddressClick = () => {
        openAddressModal();
        closeTeamInfoModal();
    };
    const closeAddressModalOpenTeamInfoModal = () => {
        closeAddressModal();
        openTeamInfoModal();
    };
    const handleIncorrectClick = () => {
        closeReviewLaunchDetailsModal();
        closeRosterModal();
        closeTeamInfoModal();
        openSomethingIsIncorrectModal();
    };

    const saveAddress = async (address: IAddress) => {
        await dispatch(editTeamAddress(teamId, address, true));
    };
    const handleCloseReviewLaunchDetailsModal = async () => {
        await dispatch(saveCompletedTeamTask(TaskItems.ReviewLaunchTimeline, teamId));
        closeReviewLaunchDetailsModal();
    };
    const year = getYearOrDefault(activeDate, defaultMemberDataYear);

    return (
        <React.Fragment>
            {isTeamInfoModalVisible && (
                <TeamInfoModal
                    handleClose={closeTeamInfoModal}
                    handleEditAddressClick={handleEditAddressClick}
                    handleIncorrectClick={handleIncorrectClick}
                    teamProfile={teamProfile}
                />
            )}
            {isRosterModalVisible && (
                <TeamRosterModal
                    handleIncorrectClick={handleIncorrectClick}
                    onHide={closeRosterModal}
                    userProfiles={userProfiles}
                />
            )}
            {isAddressModalVisible && (
                <AddressModal
                    address={teamProfile.address}
                    handleClose={closeAddressModalOpenTeamInfoModal}
                    onHide={closeAddressModal}
                    save={saveAddress}
                    year={year}
                />
            )}
            {isReviewLaunchDetailsModalVisible && (
                <ReviewLaunchDetailsModal
                    handleClose={handleCloseReviewLaunchDetailsModal}
                    handleIncorrectClick={handleIncorrectClick}
                    launchDetails={launchDetails}
                    onHide={handleCloseReviewLaunchDetailsModal}
                />
            )}
            {isSomethingIsIncorrectModalVisible && (
                <SomethingIsIncorrectModal
                    buttonClickHandler={closeSomethingIsIncorrectModal}
                    contactEmail={RH_TEAM_LAUNCH_EMAIL}
                />
            )}
            <TaskListContainer
                completeMessage={
                    <div>
                        Great job! All launch tasks have been completed.
                        <br />
                        An RH Team Member will contact you about the next steps.
                    </div>
                }
                description="Complete these tasks to make sure your organization is on track to get launched on the Remodel Health platform."
                taskListType={taskListTypes.Team}
                teamId={teamId}
                title="Team Launch Tasks"
            >
                {renderTaskItems()}
            </TaskListContainer>
        </React.Fragment>
    );
};

export default hot(module)(TeamAdminLaunchTaskList);
