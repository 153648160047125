import { getResources } from 'actions/resources/getResources';
import { IResource } from 'api/generated/models';
import { IActionButtonItems } from 'components/ActionButtons';
import PageSectionWrapper from 'components/PageSectionWrapper';
import ResourceCards from 'components/ResourceCards';
import TableHeader from 'components/TableHeader';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import ResourceModal from 'pages/resourceManagement/ResourceModal';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';

const ResourceManagementPage = () => {
    const dispatch = useThunkDispatch();
    const [search, setSearch] = useState('');
    const {
        selectedItem: selectedResource,
        openModalWithData,
        closeModal,
        isVisible: isResourceModalVisible,
    } = useModalState<IResource>();

    useEffect(() => {
        dispatch(getResources());
    }, [dispatch]);

    const actionItems: IActionButtonItems = [
        { isVisible: true, onClick: openModalWithData(), text: 'Add Resource' },
    ];

    return (
        <React.Fragment>
            {isResourceModalVisible && (
                <ResourceModal onClose={closeModal} resource={selectedResource} />
            )}
            <PageSectionWrapper>
                <TableHeader
                    items={actionItems}
                    onChange={onChange(setSearch)}
                    search={search}
                ></TableHeader>
            </PageSectionWrapper>
            <Row>
                <ResourceCards onClick={openModalWithData} search={search} showMenu xl="6" />
            </Row>
        </React.Fragment>
    );
};

export default hot(module)(ResourceManagementPage);
