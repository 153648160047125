import React from 'react';
import { hot } from 'react-hot-loader';

const CostToJoinOtherCoverageExamples = () => (
    <p>
        A document from your spouse or parent&apos;s employer that shows the cost for you to join
        the plan.
    </p>
);

export default hot(module)(CostToJoinOtherCoverageExamples);
