import {
    IReimbursementReportUserDto,
    IReimbursementReportUserSubmittedExpenseDto,
} from 'api/generated/models';
import { ViewPayrollSystemId } from 'api/generated/permissions';
import ReactTable from 'components/reactTable/ReactTable';
import { StyledReactTable } from 'components/reactTable/StyledReactTable';
import {
    HsaReimbursementCell,
    MemberNameCell,
    TotalReimbursementCell,
    WagesReimbursementCell,
} from 'pages/reimbursementReports/ReimbursementReportCells';

import classNames from 'classnames';
import { CENTER_AND_ALIGN, CENTER_CONTENT } from 'constants/styleConstants';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Column, Row } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasValue } from 'utilities';
import { formatCurrency, formatYesOrNo } from 'utilities/format';

const MEMBER_NAME_COLUMN_ID = 'member-name';

const DEFAULT_PAGE_SIZE = 50;

const commonProps = {
    className: CENTER_CONTENT,
    headerClassName: classNames(CENTER_AND_ALIGN),
};

const ReimbursementReportTable = ({ globalFilter }: { globalFilter: string | undefined }) => {
    const {
        canViewPayrollSystemId,
        data,
        reimbursementReportYear,
        useHsaReimbursement,
    } = useSelector((state: AppStore) => ({
        canViewPayrollSystemId: hasSomePermissions(state, ViewPayrollSystemId),
        data:
            state.reimbursementReport?.reimbursementReportUsers ??
            ([] as IReimbursementReportUserDto[]),
        reimbursementReportYear: Number(
            state.reimbursementReportState.reimbursementReportSelectedYear
        ),
        useHsaReimbursement: state.reimbursementReport?.useHsaReimbursement,
    }));
    const isPayrollSystemIdVisible =
        canViewPayrollSystemId && data.some((x) => hasValue(x.payrollSystemId));

    const memberColumns = useMemo<Column<IReimbursementReportUserDto>[]>(() => {
        const cols: Column<IReimbursementReportUserDto>[] = [
            {
                accessor: (x) => x.memberName,
                Cell: MemberNameCell,
                Header: 'Member Name',
                id: MEMBER_NAME_COLUMN_ID,
                width: 75,
                ...commonProps,
            },
        ];
        if (isPayrollSystemIdVisible) {
            cols.push({
                accessor: (x) => x.payrollSystemId,
                Header: 'Payroll System ID',
                ...commonProps,
            });
        }
        if (useHsaReimbursement) {
            if (data.some((x) => x.previousYearTotalReimbursementToHsa > 0)) {
                cols.push({
                    accessor: (x) =>
                        formatCurrency(x.previousYearTotalReimbursementToHsa, {
                            preserveDecimal: true,
                        }),
                    Header: `Total Reimbursement to ${reimbursementReportYear - 1} HSA`,
                    width: 75,
                    ...commonProps,
                });
            }
            if (data.some((x) => x.reportYearTotalReimbursementToHsa > 0)) {
                cols.push({
                    accessor: (x) =>
                        formatCurrency(x.reportYearTotalReimbursementToHsa, {
                            preserveDecimal: true,
                        }),
                    Header: `Total Reimbursement to ${reimbursementReportYear} HSA`,
                    width: 75,
                    ...commonProps,
                });
            }
        }
        cols.push({
            accessor: (x) => formatCurrency(x.totalReimbursementToWages, { preserveDecimal: true }),
            Header: 'Total Reimbursement to Wages',
            width: 75,
            ...commonProps,
        });
        if (useHsaReimbursement) {
            cols.push({
                accessor: (x) => formatCurrency(x.totalReimbursement, { preserveDecimal: true }),
                Header: 'Total Reimbursement',
                width: 75,
                ...commonProps,
            });
        }
        return cols;
    }, [data, isPayrollSystemIdVisible, reimbursementReportYear, useHsaReimbursement]);

    const submittedExpenseColumns = useMemo<
        Column<IReimbursementReportUserSubmittedExpenseDto>[]
    >(() => {
        const cols: Column<IReimbursementReportUserSubmittedExpenseDto>[] = [
            {
                accessor: (x) => x.expenseId,
                Header: 'Expense ID',
                ...commonProps,
            },
            {
                accessor: (x) => x.expenseYear,
                Header: 'Expense Year',
                ...commonProps,
            },
            {
                accessor: (x) => x.expenseType?.name,
                Header: 'Expense Type',
                ...commonProps,
            },
            {
                accessor: (x) =>
                    formatCurrency(x.amountEligibleForReimbursement, { preserveDecimal: true }),
                Header: 'Amount Eligible for Reimbursement',
                ...commonProps,
            },
        ];
        if (useHsaReimbursement) {
            cols.push(
                {
                    accessor: (x) => formatYesOrNo(x.reimburseToHsa),
                    Header: 'Reimburse to HSA',
                    ...commonProps,
                },
                {
                    accessor: (x) => x,
                    Cell: HsaReimbursementCell,
                    Header: 'Reimbursement to HSA',
                    ...commonProps,
                }
            );
        }
        cols.push({
            accessor: (x) => x,
            Cell: WagesReimbursementCell,
            Header: 'Reimbursement to Wages',
            ...commonProps,
        });
        if (useHsaReimbursement) {
            cols.push({
                accessor: (x) => x,
                Cell: TotalReimbursementCell,
                Header: 'Total Reimbursement',
                ...commonProps,
            });
        }
        return cols;
    }, [useHsaReimbursement]);

    const getSubmittedExpensesTable = useCallback(
        ({ original }: Row<IReimbursementReportUserDto>) => (
            <div className="pl-4">
                <StyledReactTable
                    columns={submittedExpenseColumns}
                    data={original.submittedExpenses ?? []}
                    isPaginationEnabled={false}
                    pageSize={original.submittedExpenses?.length ?? 0}
                />
            </div>
        ),
        [submittedExpenseColumns]
    );

    return (
        <ReactTable
            canRowExpand={() => true}
            columns={memberColumns}
            data={data}
            getSubComponent={getSubmittedExpensesTable}
            globalFilter={globalFilter}
            isRowExpandingEnabled
            pageSize={DEFAULT_PAGE_SIZE}
        />
    );
};

export default hot(module)(ReimbursementReportTable);
