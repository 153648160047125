import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { ADD_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/addPathwayBlueprint';
import { EDIT_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/editPathwayBlueprint';
import { GET_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { GET_BLUEPRINT_MIGRATIONS_ACTION } from 'actions/pathwayBlueprint/getPlansForNextYearAsync';
import { IBlueprintMigrationDto } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import PathwayMarketplacePlanMigrationInputs, {
    PATHWAY_TYPE_NAMES,
} from 'pages/pathwayBlueprints/PathwayMarketplacePlanMigrationInputs';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue } from 'utilities/index';

const PathwayMigrationsContent = ({ migrations }: { migrations: IBlueprintMigrationDto }) => {
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            ADD_PATHWAY_BLUEPRINT_ACTION,
            EDIT_PATHWAY_BLUEPRINT_ACTION,
            GET_PATHWAY_BLUEPRINT_ACTION,
            GET_BLUEPRINT_MIGRATIONS_ACTION
        ),
    }));
    const ichraClassPlanMigrations = migrations.ichraClassMigrations;
    const pathwayPlanMigrations = migrations.pathwayBenchmarkMigrations;
    const recommendedPlanMigrations = migrations.pathwayRecommendedMigrations;
    const ichraClassPlanMigrationsContent = ichraClassPlanMigrations?.map((x) => {
        const planIndex = ichraClassPlanMigrations.findIndex(
            (m) => m.ichraClass?.globalId === x.ichraClass?.globalId
        );
        return (
            <PathwayMarketplacePlanMigrationInputs
                index={planIndex}
                key={`ichraClassPlanMigration-${x.ichraClass?.id}`}
                {...x}
            />
        );
    });

    const pathwayPlanMigrationsContent = pathwayPlanMigrations?.map((x) => {
        const planIndex = pathwayPlanMigrations.findIndex((m) => m.pathway?.id === x.pathway?.id);
        const recommendedPlansForPathway = recommendedPlanMigrations?.filter(
            (rpm) => rpm.pathwayRecommendedPlan?.pathwayId === x.pathway?.id
        );

        return (
            <React.Fragment key={`pathwayPlanMigration-${x.pathway?.id}`}>
                {arrayHasValue(recommendedPlansForPathway) && (
                    <Typography marginTop={4} variant="h4">
                        {x.pathway?.name}
                        {' - '}
                        <span style={{ fontStyle: 'italic' }}>
                            {PATHWAY_TYPE_NAMES?.[x.pathway?.pathwayTypeId as number]}
                        </span>
                    </Typography>
                )}
                <PathwayMarketplacePlanMigrationInputs
                    index={planIndex}
                    {...x}
                    hasMultipleRecommendedPlans={arrayHasValue(recommendedPlansForPathway)}
                />
                {recommendedPlansForPathway?.map((y) => {
                    const recPlanIndex = recommendedPlanMigrations?.findIndex(
                        (m) =>
                            m.pathwayRecommendedPlan?.globalId ===
                            y.pathwayRecommendedPlan?.globalId
                    );

                    return (
                        <PathwayMarketplacePlanMigrationInputs
                            hasMultipleRecommendedPlans={arrayHasValue(recommendedPlansForPathway)}
                            index={recPlanIndex}
                            {...y}
                            key={`recommendedPlanMigration-${y.pathwayRecommendedPlan?.id}`}
                            pathway={x.pathway}
                        />
                    );
                })}
            </React.Fragment>
        );
    });

    return (
        <Stack>
            <Skeleton
                isEnabled={isLoading}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 2,
                            height: 260,
                            sx: { mb: 3, mt: 2 },
                            width: '100%',
                        },
                    ],
                ]}
            >
                {arrayHasValue(ichraClassPlanMigrationsContent) && (
                    <React.Fragment>
                        <Divider sx={{ my: 2 }} />
                        <Typography sx={{ mb: 0 }} variant="h3">
                            ICHRA Classes
                        </Typography>
                        {ichraClassPlanMigrationsContent}
                    </React.Fragment>
                )}
                {arrayHasValue(pathwayPlanMigrationsContent) && (
                    <React.Fragment>
                        <Divider sx={{ mb: 2, mt: 4 }} />
                        <Typography sx={{ mb: 0 }} variant="h3">
                            Pathways
                        </Typography>
                        {pathwayPlanMigrationsContent}
                    </React.Fragment>
                )}
            </Skeleton>
        </Stack>
    );
};

export default hot(module)(PathwayMigrationsContent);
