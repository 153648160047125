import { Card, CardContent } from '@mui/material';
import { HrsIntegrationProviders, PaylocityIntegrationStatuses } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { GenerateHrsIntegrationKey, ViewHrsIntegrationKey } from 'api/generated/permissions';
import Button from 'components/Button';
import CopyText from 'components/CopyText';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import ProfileAttribute from 'components/ProfileAttribute';
import useTeamProps from 'hooks/useTeamProps';
import startCase from 'lodash/startCase';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';
import { enumToString, hasValue } from 'utilities/index';
import { v4 as guid } from 'uuid';
import { string } from 'yup';

const earningCodeMaxLength = 50;
const earningCodeSchema = string()
    .max(earningCodeMaxLength)
    .label('Earning Code');

const PaylocityIntegrationSection = ({ save }: { save: ISaveEditableTextField<ITeam> }) => {
    const { canGeneratePaylocityIntegrationKey, canViewPaylocityIntegrationKey } = useSelector(
        (state: AppStore) => ({
            canGeneratePaylocityIntegrationKey: hasSomePermissions(
                state,
                GenerateHrsIntegrationKey
            ),
            canViewPaylocityIntegrationKey: hasSomePermissions(state, ViewHrsIntegrationKey),
        })
    );

    const { team } = useTeamProps();
    const [paylocityIntegrationKey, setPaylocityIntegrationKey] = useState(
        team?.paylocityIntegrationKey ?? ''
    );
    const [orgId, setOrgId] = useState(team?.paylocityOrganizationId ?? '');
    const hasIntegrationKey = hasValue(paylocityIntegrationKey);
    const showIntegrationStatus = canViewPaylocityIntegrationKey && hasIntegrationKey;
    const hrsProviderName = enumToString(
        HrsIntegrationProviders,
        team?.hrsIntegrationProvider ?? HrsIntegrationProviders.NotConfigured
    );
    const [earningCode1095C, setEarningCode1095C] = useState(team?.earningCode1095C ?? '');

    const generateNewPaylocityIntegrationKey = () => {
        const newKey = guid();
        setPaylocityIntegrationKey(newKey);
        save('paylocityIntegrationKey', newKey);
    };

    const getAttributeContent = () => {
        if (!hasValue(paylocityIntegrationKey)) {
            return (
                <React.Fragment>
                    {canGeneratePaylocityIntegrationKey && (
                        <Button
                            onClick={generateNewPaylocityIntegrationKey}
                            size="small"
                            variant="outlined"
                        >
                            Generate Key
                        </Button>
                    )}
                </React.Fragment>
            );
        }

        return <CopyText text={paylocityIntegrationKey}>{paylocityIntegrationKey}</CopyText>;
    };

    return (
        <Card>
            <CardContent>
                <DashboardCardHeader header="Paylocity" />
                <hr />
                {(showIntegrationStatus || canGeneratePaylocityIntegrationKey) && (
                    <ProfileAttribute label="Key">{getAttributeContent()}</ProfileAttribute>
                )}
                {showIntegrationStatus && (
                    <React.Fragment>
                        <ProfileAttribute label="Status">
                            {startCase(
                                PaylocityIntegrationStatuses[
                                    team?.paylocityIntegrationStatus ??
                                        PaylocityIntegrationStatuses.NotConfigured
                                ]
                            )}
                        </ProfileAttribute>
                        <EditableTextAttribute
                            infoTooltip="The organization's Company ID in Paylocity"
                            label="Organization ID"
                            name="paylocityOrganizationId"
                            onChange={onChange(setOrgId)}
                            save={save}
                            validationSchema={string()
                                .trim()
                                .label('Organization ID')}
                            value={orgId}
                        />
                        <EditableTextAttribute
                            infoTooltip={`The earning code in ${hrsProviderName} that will be used for 1095-C reporting. This corresponds with the used portion of a member's Reimbursement amount.`}
                            label="1095-C Earning Code"
                            name="earningCode1095C"
                            onChange={onChange(setEarningCode1095C)}
                            save={save}
                            validationSchema={earningCodeSchema}
                            value={earningCode1095C}
                        />
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    );
};

export default hot(module)(PaylocityIntegrationSection);
