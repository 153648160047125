import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { downloadTeamBenefitFile } from 'api/downloadTeamBenefitFile';
import { IRhFileDto } from 'api/generated/models';
import Icon from 'components/Icon';
import RhFilePreviewModal from 'components/rhFile/RhFilePreviewModal';
import useModalState from 'hooks/useModalState';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { hot } from 'react-hot-loader';
import { getFileIconDataForExtension } from 'utilities/files';

const AncillaryBenefitFile = ({
    file,
    teamBenefitId,
}: {
    file: IRhFileDto;
    teamBenefitId: string | undefined;
}) => {
    const {
        isVisible: isPreviewModalVisible,
        closeModal: closePreviewModal,
        openModal: openPreviewModal,
    } = useModalState();

    const iconData = getFileIconDataForExtension(file.fileExtension ?? '');

    return (
        <React.Fragment>
            {isPreviewModalVisible && (
                <RhFilePreviewModal
                    canDownloadRhFiles
                    downloadRhFile={async () => downloadTeamBenefitFile(teamBenefitId, file.id)}
                    onClose={closePreviewModal}
                    rhFile={file}
                />
            )}
            <ListItem>
                <ListItemButton
                    onClick={openPreviewModal}
                    sx={{
                        alignItems: 'center',
                        cursor: 'pointer',
                        flexWrap: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <ListItemIcon>
                        <Icon prefix="mdi" size="2" variant={iconData.color}>
                            {iconData.icon}
                        </Icon>
                    </ListItemIcon>
                    <ListItemText data-cy={`${kebabCase(file.name)}-file-name`}>
                        {file.name}
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        </React.Fragment>
    );
};

export default hot(module)(AncillaryBenefitFile);
