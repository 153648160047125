import { AppStoreThunkDispatch, IAction } from 'actions/commonAction';
import { ISelectedPlan } from 'api/generated/models';
import { IBenefitsState } from 'pages/benefits/benefitsState';

export const SHOW_PLAN_MODAL = 'SHOW_PLAN_MODAL';
export const showPlanModal = (benefit?: ISelectedPlan) => (dispatch: AppStoreThunkDispatch) => {
    dispatch<IAction<IBenefitsState>>({
        data: {
            isPlanModalVisible: true,
            targetBenefit: benefit,
        },
        type: SHOW_PLAN_MODAL,
    });
};

export const HIDE_PLAN_MODAL = 'HIDE_PLAN_MODAL';
export const hidePlanModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch<IAction<IBenefitsState>>({
        data: {
            isPlanModalVisible: false,
            targetBenefit: undefined,
        },
        type: HIDE_PLAN_MODAL,
    });
};

export const SHOW_REMOVE_PLAN_MODAL = 'SHOW_REMOVE_PLAN_MODAL';
export const showRemovePlanModal = (benefit: ISelectedPlan) => (
    dispatch: AppStoreThunkDispatch
) => {
    dispatch<IAction<IBenefitsState>>({
        data: {
            isRemovePlanModalVisible: true,
            targetBenefit: benefit,
        },
        type: SHOW_REMOVE_PLAN_MODAL,
    });
};

export const HIDE_REMOVE_PLAN_MODAL = 'HIDE_REMOVE_PLAN_MODAL';
export const hideRemovePlanModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch<IAction<IBenefitsState>>({
        data: {
            isRemovePlanModalVisible: false,
            targetBenefit: undefined,
        },
        type: HIDE_REMOVE_PLAN_MODAL,
    });
};
