import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
    GET_HOUSEHOLD_MEMBERS_ACTION,
    getHouseholdMembers,
} from 'actions/householdMember/getHouseholdMembers';
import {
    GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT_ACTION,
    getStateBasedExchangeStatesShouldForceAdvisement,
} from 'actions/marketplacePlan/getStateBasedExchangeStatesShouldForceAdvisement';
import { getPathwayBlueprintDataForUser } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { DELETE_SELECTED_PLAN_ACTION } from 'actions/selectedPlan/deleteSelectedPlan';
import {
    GET_SELECTED_PLANS_FOR_REVIEW_ACTION,
    getSelectedPlansForReview,
} from 'actions/selectedPlan/getSelectedPlansForReview';
import {
    GET_CURRENT_USER_BUDGET_ACTION,
    getCurrentUserBudget,
} from 'actions/user/getCurrentUserBudget';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import { PlanTypeIds } from 'api/generated/enums';
import Skeleton from 'components/Skeleton';
import TeamManagementWrapper from 'components/TeamManagementWrapper';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import NoSelectedPlansMessage from 'pages/selections/NoSelectedPlansMessage';
import SelectionCards from 'pages/selections/SelectionCards';
import SelectionHeader from 'pages/selections/SelectionHeader';
import SelectionPagePriceBreakdown from 'pages/selections/SelectionPagePriceBreakdown';
import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { SHOP_PATH, TEAMS_SHOP_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { hasContents } from 'utilities';

const SelectionsPage = ({ isTeamManagementPage = false }) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { hasUser, memberVerifiedInfo, userId } = useUserProps();
    const { hasNonSpouseSelectedPlan, hasSelectedPlansForShopping, isLoading } = useSelector(
        (state: AppStore) => ({
            hasNonSpouseSelectedPlan: state.selectedPlansForReview.selectedPlans?.some(
                (x) => x.planTypeId !== PlanTypeIds.SpouseEmployer
            ),
            hasSelectedPlansForShopping: hasContents(state.selectedPlansForReview.selectedPlans),
            isLoading: hasApiActivity(
                state,
                DELETE_SELECTED_PLAN_ACTION,
                GET_CURRENT_USER_BUDGET_ACTION,
                GET_HOUSEHOLD_MEMBERS_ACTION,
                GET_SELECTED_PLANS_FOR_REVIEW_ACTION,
                GET_USER_PROFILE_ACTION,
                GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT_ACTION
            ),
        })
    );

    useEffect(() => {
        if (hasUser) {
            dispatch(getUserProfile(userId, false));
            dispatch(getHouseholdMembers(userId));
            dispatch(getCurrentUserBudget(teamId, userId));
            dispatch(getPathwayBlueprintDataForUser(userId, memberVerifiedInfo?.year));
            dispatch(getStateBasedExchangeStatesShouldForceAdvisement(userId));
            dispatch(getSelectedPlansForReview(userId));
        }
    }, [dispatch, hasUser, memberVerifiedInfo?.year, teamId, userId]);

    const shopPath = isTeamManagementPage
        ? generatePath(TEAMS_SHOP_PATH, { teamId, userId })
        : SHOP_PATH;
    let pricingSummary = <React.Fragment />;
    if (hasNonSpouseSelectedPlan) {
        pricingSummary = (
            <Skeleton count={1} height={500} isEnabled={isLoading}>
                <Row className="justify-content-center">
                    <Col>
                        <Card>
                            <CardContent data-cy="pricing-summary">
                                <Typography variant="h3">Pricing Summary</Typography>
                                <SelectionPagePriceBreakdown />
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
            </Skeleton>
        );
    }
    return (
        <TeamManagementWrapper isTeamManageMentPage={isTeamManagementPage}>
            {hasSelectedPlansForShopping ? (
                <React.Fragment>
                    <SelectionHeader shopPath={shopPath} />
                    <Row className={isTeamManagementPage ? 'p-4' : 'py-4'}>
                        <Col>
                            <Skeleton count={1} height={500} isEnabled={isLoading}>
                                <SelectionCards />
                            </Skeleton>
                        </Col>
                        <Col xs>{pricingSummary}</Col>
                    </Row>
                </React.Fragment>
            ) : (
                <NoSelectedPlansMessage shopPath={shopPath} />
            )}
        </TeamManagementWrapper>
    );
};

export default hot(module)(SelectionsPage);
