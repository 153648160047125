import { SUBMIT_SURVEY_ACTION } from 'actions/survey/submitSurvey';
import { SurveyTypes, UserStatus } from 'api/generated/enums';
import Button from 'components/Button';
import Form from 'components/Form';
import Progress from 'components/progress/Progress';
import { SurveyTabs } from 'constants/surveyTabs';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { setTabBarIndex } from 'pages/survey/surveyActions';
import React, { ReactNode, useCallback, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { isFalse } from 'utilities';

const NUMBER_OF_STEPS =
    Object.values(SurveyTabs).filter((x) => (typeof x === 'number' ? isNaN(x) : true)).length - 1;
const NUMBER_OF_RENEWING_STEPS =
    Object.values(SurveyTabs).filter(
        (x) => (typeof x === 'string' ? true : false) && x !== 'Qualitative'
    ).length - 1;
const NUMBER_OF_ICHRA_STEPS =
    Object.values(SurveyTabs).filter(
        (x) => (typeof x === 'string' ? true : false) && x !== 'Income'
    ).length - 1;
const NUMBER_OF_NONQUAL_ICHRA_STEPS =
    Object.values(SurveyTabs).filter(
        (x) => (typeof x === 'string' ? true : false) && x !== 'Income' && x !== 'Qualitative'
    ).length - 1;

type ISurveyFormWrapperProps = {
    children: ReactNode;
    hideFooter?: boolean;
    ignoreSubmit?: boolean;
    isDisabled?: boolean;
    onSubmit?: React.FormEventHandler | ((event: React.FormEvent) => Promise<boolean>);
    overrideGoBack?: () => void;
    submitText?: string;
};

const SurveyFormWrapper = ({
    children,
    hideFooter = false,
    ignoreSubmit = false,
    isDisabled = false,
    onSubmit: parentOnSubmit,
    overrideGoBack,
    submitText: overrideSubmitText,
}: ISurveyFormWrapperProps) => {
    const dispatch = useThunkDispatch();
    const { user } = useUserProps();
    const { team } = useTeamProps();
    const { apiActivity, isReviewStarted, tabIndex } = useSelector((state: AppStore) => ({
        apiActivity: hasApiActivity(state, SUBMIT_SURVEY_ACTION),
        isReviewStarted: state.surveyState.isReviewStarted,
        tabIndex: state.surveyState.tabIndex,
    }));
    const isBackButtonVisible = useMemo(() => tabIndex !== SurveyTabs.Member && !isReviewStarted, [
        isReviewStarted,
        tabIndex,
    ]);
    const goBack = useCallback(
        async () => (overrideGoBack ? overrideGoBack() : dispatch(setTabBarIndex(tabIndex - 1))),
        [dispatch, overrideGoBack, tabIndex]
    );
    const submitText = useMemo(
        () =>
            overrideSubmitText
                ? overrideSubmitText
                : isReviewStarted
                ? 'Back To Review'
                : 'Continue',
        [isReviewStarted, overrideSubmitText]
    );
    const onSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            const isValid = await parentOnSubmit?.(e);
            if (!ignoreSubmit && !isFalse(isValid)) {
                dispatch(setTabBarIndex(isReviewStarted ? SurveyTabs.Review : tabIndex + 1));
            }
        },
        [dispatch, ignoreSubmit, isReviewStarted, parentOnSubmit, tabIndex]
    );

    const calculateNumberOfSteps = () => {
        if (
            user?.surveyTypeToSend === SurveyTypes.ICHRA &&
            !team?.includeQualitativeQuestionsInSurvey
        ) {
            return NUMBER_OF_NONQUAL_ICHRA_STEPS;
        } else if (
            user?.surveyTypeToSend === SurveyTypes.ICHRA &&
            team?.includeQualitativeQuestionsInSurvey
        ) {
            return NUMBER_OF_ICHRA_STEPS;
        } else if (user?.status === UserStatus.Renewing) {
            return NUMBER_OF_RENEWING_STEPS;
        } else {
            return NUMBER_OF_STEPS;
        }
    };

    const numberOfSteps = calculateNumberOfSteps();

    return (
        <div>
            <Row
                className={`align-items-center pb-sm-5 ${
                    isBackButtonVisible ? 'justify-content-between' : 'justify-content-center'
                }`}
                noGutters
            >
                <Col>
                    {isBackButtonVisible && (
                        <Row noGutters>
                            <Button
                                ButtonClassName="text-secondary p-0 px-sm-2 py-sm-1"
                                onClick={goBack}
                                variant="text"
                            >
                                <Row className="flex-nowrap" noGutters>
                                    <i className="dripicons-arrow-thin-left mr-sm-1"></i>
                                    <span className="d-none d-sm-block">Back</span>
                                </Row>
                            </Button>
                        </Row>
                    )}
                </Col>
                <Col sm="auto" xs="10">
                    <Row className="justify-content-center mx-3 mx-sm-0" noGutters>
                        <Progress
                            current={tabIndex}
                            isReviewing={isReviewStarted}
                            total={numberOfSteps}
                        />
                    </Row>
                </Col>
                <Col></Col>
            </Row>
            <Form isLoading={apiActivity} onSubmit={onSubmit}>
                {children}
                {!hideFooter && (
                    <Row className="pt-0 mx-3 px-5 px-sm-0 text-center d-sm-flex justify-content-center">
                        <Button
                            className="col col-xl-3 col-sm-8 col-md-7 col-lg-4 mt-2"
                            disabled={isDisabled}
                            isLoading={apiActivity}
                            type="submit"
                        >
                            {submitText}
                        </Button>
                    </Row>
                )}
            </Form>
        </div>
    );
};

export default hot(module)(SurveyFormWrapper);
