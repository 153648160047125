import { IMemberQuoteInfo } from 'api/generated/models';
import EditableCurrencyAttribute from 'components/EditableCurrencyAttribute';
import useUserProps from 'hooks/useUserProps';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type IIncomeQuoteInfoProps = {
    quoteInfoCommonProps: ICommonProps<IMemberQuoteInfo>;
};
const IncomeQuoteInfo = ({ quoteInfoCommonProps }: IIncomeQuoteInfoProps) => {
    const { userProfile } = useUserProps();
    const [employeeAnnualIncome, setEmployeeAnnualIncome] = useState(
        userProfile?.employeeAnnualIncome?.toString()
    );
    return (
        <EditableCurrencyAttribute
            {...quoteInfoCommonProps}
            decimalScale={2}
            label="Employee Annual Income"
            name="employeeAnnualIncome"
            onChange={onChange(setEmployeeAnnualIncome)}
            validationSchema={string()
                .trim()
                .required()
                .label('Employee Annual Income')}
            value={employeeAnnualIncome}
        />
    );
};

export default hot(module)(IncomeQuoteInfo);
