import { AppStoreThunkDispatch } from 'actions/commonAction';
import { ReactNode } from 'react';

export const SHOW_TEAM_CONFIRMATION_MODAL = 'SHOW_TEAM_CONFIRMATION_MODAL';
export const showTeamConfirmationModal = (
    modalBody: ReactNode,
    yesClickAction: () => void,
    noClickAction: () => void,
    apiActions: string[]
) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { apiActions, modalBody, noClickAction, yesClickAction, showConfirmationModal: true },
        type: SHOW_TEAM_CONFIRMATION_MODAL,
    });
};

export const HIDE_TEAM_CONFIRMATION_MODAL = 'HIDE_TEAM_CONFIRMATION_MODAL';
export const hideTeamConfirmationModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: {
            apiActions: [],
            modalBody: '',
            noClickAction: null,
            showConfirmationModal: false,
            yesClickAction: null,
        },
        type: HIDE_TEAM_CONFIRMATION_MODAL,
    });
};
