import SingleButtonModal from 'components/SingleButtonModal';
import DoNotInclude from 'pages/survey/informationModals/DoNotInclude';
import InfoModalText from 'pages/survey/informationModals/InfoModalText';
import ThingsToInclude from 'pages/survey/informationModals/ThingsToInclude';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const CalculateIncomeInformationModal = ({
    close,
    subTitle = '',
    title = 'Calculate Income',
}: {
    close: () => void;
    subTitle?: string;
    title?: string;
}) => (
    <SingleButtonModal
        body={
            <React.Fragment>
                {subTitle && (
                    <Row className="justify-content-center">
                        <p>{subTitle}</p>
                    </Row>
                )}
                <Row>
                    <Col className="mb-4" md={{ offset: 1 }}>
                        <ThingsToInclude />
                        <InfoModalText>W-2</InfoModalText>
                        <InfoModalText>Self-Employment Income</InfoModalText>
                        <InfoModalText>Tips</InfoModalText>
                        <InfoModalText>Unemployment Income</InfoModalText>
                        <InfoModalText>Social Security Benefits (Non-Taxable) </InfoModalText>
                        <InfoModalText>Social Security Disability</InfoModalText>
                        <InfoModalText>Retirement or Pension Income</InfoModalText>
                        <InfoModalText>Alimony Received</InfoModalText>
                        <InfoModalText>Capital Gains</InfoModalText>
                        <InfoModalText>Investment Income</InfoModalText>
                        <InfoModalText>Rental or Royalty Income</InfoModalText>
                        <InfoModalText>Untaxed Foreign Income</InfoModalText>
                    </Col>
                    <Col>
                        <DoNotInclude />
                        <InfoModalText>Supplemental Security Income ( SSI )</InfoModalText>
                        <InfoModalText>Child Support</InfoModalText>
                        <InfoModalText>Veterans Disability</InfoModalText>
                        <InfoModalText>Worker’s Compensation</InfoModalText>
                        <InfoModalText>Proceeds from Loan</InfoModalText>
                        <InfoModalText>Gifts</InfoModalText>
                    </Col>
                </Row>
            </React.Fragment>
        }
        buttonClickHandler={close}
        buttonText="Close"
        size="lg"
        title={title}
    />
);

export default hot(module)(CalculateIncomeInformationModal);
