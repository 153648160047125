import { Chip } from '@mui/material';
import { IPathwayBlueprint, IPathwayUser, IUser } from 'api/generated/models';
import Tooltip from 'components/Tooltip';
import React from 'react';
import { hot } from 'react-hot-loader';

const IneligibleRecommendedPlansBadge = ({
    pathwayBlueprint,
    user,
}: {
    pathwayBlueprint: IPathwayBlueprint;
    user: IUser | undefined;
}) => {
    const pathwayUserWithIneligiblePlans = pathwayBlueprint.pathwayUsers?.find(
        (pu) => pu.userId === user?.userId
    ) as IPathwayUser;

    const ineligiblePlanNames = pathwayUserWithIneligiblePlans?.ineligibleRecommendedPlanNames;

    return (
        <Tooltip
            title={
                <span>
                    Ineligible Plan(s):
                    <ul>
                        {ineligiblePlanNames?.map((ip) => (
                            <li key={ip}>{ip}</li>
                        ))}
                    </ul>
                </span>
            }
        >
            <Chip
                color="error"
                key="ineligiblePlans"
                label="Ineligible Plan(s)"
                size="small"
                sx={{
                    backgroundColor: (theme) => theme.palette.warning.dark,
                    mb: 0.5,
                    ml: 1,
                }}
            />
        </Tooltip>
    );
};

export default hot(module)(IneligibleRecommendedPlansBadge);
