import {
    GET_FAVORITE_TEAMS_ACTION,
    getFavoriteTeams,
} from 'actions/favoriteTeams/getFavoriteTeams';
import {
    GET_LAUNCH_DETAILS_ACTION,
    getLaunchDetails,
} from 'actions/launchDetails/getLaunchDetails';
import {
    GET_COMPLETED_TEAM_TASKS_ACTION,
    getCompletedTeamTasks,
} from 'actions/task/getCompletedTeamTasks';
import {
    GET_COMPLETED_USER_TASKS_ACTION,
    getCompletedUserTasks,
} from 'actions/task/getCompletedUserTasks';
import { GET_TEAM_PROFILE_ACTION } from 'actions/team/getTeamProfile';
import { getTeamProfiles } from 'actions/team/getTeamProfiles';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import { getUserProfiles } from 'actions/user/getUserProfiles';
import Skeleton from 'components/Skeleton';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import DashboardContent from 'pages/dashboard/DashboardContent';
import { getShouldRenderTeamStatuses } from 'pages/dashboard/dashboardSelectors';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { isRhSelector, isTeamAdminSelector } from 'selectors/role';
import { isCorrectStateForLaunchDetails } from 'utilities/launch';

export const archiveAlert = {
    cardBody:
        'This team is no longer active with Remodel Health, but you can still see all of you information.',
    cardHeader: 'Team has been archived',
    isTransparent: true,
};

export const inactiveAlert = {
    cardBody:
        'You are no longer an active member with Remodel Health. But you can still view your past benefits information here.',
    cardHeader: 'You Are Currently Inactive',
    isTransparent: true,
};

const DashboardPage = () => {
    const dispatch = useThunkDispatch();
    const teamProps = useTeamProps();
    const { hasTeam, team, teamId, teamStateId } = teamProps;
    const { isCurrent, user, userId } = useUserProps();
    const {
        isLoading,
        isRh,
        isTeamAdmin,
        shouldGetFavoriteTeams,
        shouldRenderTeamStatuses,
    } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            GET_USER_PROFILE_ACTION,
            GET_TEAM_PROFILE_ACTION,
            GET_COMPLETED_USER_TASKS_ACTION,
            GET_COMPLETED_TEAM_TASKS_ACTION,
            GET_LAUNCH_DETAILS_ACTION
        ),
        isRh: isRhSelector(state),
        isTeamAdmin: isTeamAdminSelector(state),
        shouldGetFavoriteTeams:
            !hasCompletedRequest(state, GET_FAVORITE_TEAMS_ACTION) &&
            !hasApiActivity(state, GET_FAVORITE_TEAMS_ACTION),
        shouldRenderTeamStatuses: getShouldRenderTeamStatuses(state, teamProps),
    }));
    const [initialLoad, setInitialLoad] = useState(true);
    useEffect(() => {
        if (isTeamAdmin) {
            dispatch(getUserProfiles(teamId));
        }
        if (isRh && shouldGetFavoriteTeams) {
            dispatch(getFavoriteTeams());
        }
    }, [dispatch, isRh, isTeamAdmin, shouldGetFavoriteTeams, teamId]);
    useEffect(() => {
        dispatch(getCompletedUserTasks());
        dispatch(getCompletedTeamTasks(teamId));
        if (shouldRenderTeamStatuses) {
            dispatch(getTeamProfiles());
        } else {
            dispatch(getUserProfile(userId, isCurrent, Number(user?.activeDate?.getYear())));
        }
        setInitialLoad(false);
    }, [dispatch, isCurrent, shouldRenderTeamStatuses, teamId, user?.activeDate, userId]);
    useEffect(() => {
        if (hasTeam && teamId === team?.teamId && isCorrectStateForLaunchDetails(teamStateId)) {
            dispatch(getLaunchDetails(teamId));
        }
    }, [dispatch, hasTeam, team, teamId, teamStateId]);
    return (
        <Skeleton count={4} height="400px" isEnabled={isLoading || !hasTeam || initialLoad}>
            <DashboardContent />
        </Skeleton>
    );
};

export default hot(module)(DashboardPage);
