import { Card, CardContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import { getMostRecentReimbursementReport } from 'actions/reimbursementReport/getMostRecentReimbursementReport';
import { ViewTeamSharedReimbursementReports } from 'api/generated/permissions';
import Button from 'components/Button';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isEmpty from 'lodash/isEmpty';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { REIMBURSEMENT_REPORTS_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';

const QUERY_PARAM = {
    ID: 'id',
    YEAR: 'year',
} as const;

const MostRecentReimbursementReportCard = () => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { canViewTeamSharedReimbursementReports, reimbursementReport } = useSelector(
        (state: AppStore) => ({
            canViewTeamSharedReimbursementReports: hasSomePermissions(
                state,
                ViewTeamSharedReimbursementReports
            ),
            reimbursementReport: state.mostRecentReimbursementReport,
        })
    );
    const { startDate, termLabel } = reimbursementReport;
    const year = startDate?.getYear();
    const [query] = useQuery();
    query.set(QUERY_PARAM.YEAR, year?.toString());
    query.set(QUERY_PARAM.ID, reimbursementReport.globalId);

    useEffect(() => {
        if (canViewTeamSharedReimbursementReports) {
            dispatch(getMostRecentReimbursementReport(teamId));
        }
    }, [canViewTeamSharedReimbursementReports, dispatch, teamId]);

    return isEmpty(reimbursementReport) ? (
        <React.Fragment />
    ) : (
        <Card>
            <CardContent>
                <DashboardCardHeader
                    header={`Most recent Reimbursement Report: ${termLabel} ${year}`}
                />
                <hr></hr>
                <Stack alignItems="center">
                    <Link
                        className="m-2"
                        data-cy="view-most-recent-report"
                        to={`${REIMBURSEMENT_REPORTS_PATH}?${query}`}
                    >
                        <Button size="small">View Reimbursement Report</Button>
                    </Link>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default hot(module)(MostRecentReimbursementReportCard);
