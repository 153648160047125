import { addUser, ADD_USER_ACTION } from 'actions/user/addUser';
import { IUserProfile } from 'api/generated/models';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import UserForm from 'pages/addPerson/UserForm';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const AddPersonPage = () => {
    const dispatch = useThunkDispatch();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { apiError, apiErrorStatusCode } = useSelector((state: AppStore) => ({
        apiError: !!state.apiErrors[ADD_USER_ACTION.statusCode],
        apiErrorStatusCode: state.apiErrors[ADD_USER_ACTION.statusCode] as number,
    }));

    const handleSubmit = async (
        { user, address, memberQuoteInfo, yearlyUserInfo }: IUserProfile,
        isCustomer: boolean
    ) => {
        if (isCustomer) {
            await dispatch(
                addUser(
                    { address, memberQuoteInfo, user, yearlyUserInfo } as IUserProfile,
                    isTeamManagementPage
                )
            );
        } else {
            await dispatch(addUser({ user } as IUserProfile, isTeamManagementPage));
        }
    };

    return (
        <UserForm
            apiError={apiError}
            apiErrorStatusCode={apiErrorStatusCode}
            handleSubmit={handleSubmit}
        />
    );
};

export default hot(module)(AddPersonPage);
