import Paper from '@mui/material/Paper';
import {
    clearReimbursementReport,
    clearReimbursementReportYears,
    clearReimbursementReports,
} from 'actions/clear';
import {
    GET_REIMBURSEMENT_REPORT_ACTION,
    getReimbursementReport,
} from 'actions/reimbursementReport/getReimbursementReport';
import {
    LIST_REIMBURSEMENT_REPORTS_ACTION,
    listReimbursementReports,
} from 'actions/reimbursementReport/listReimbursementReports';
import { IBasicReimbursementReportDto } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import NoRowsMessage from 'components/reactTable/NoRowsMessage';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import ReimbursementReportTable from 'pages/reimbursementReports/ReimbursementReportTable';
import ReimbursementReportsPageHeader from 'pages/reimbursementReports/ReimbursementReportsPageHeader';
import { setReimbursementReportSelectedYear } from 'pages/reimbursementReports/reimbursementReportActions';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';

export const REIMBURSEMENT_REPORTS_QUERY_PARAM = {
    REIMBURSEMENT_REPORT_ID: 'id',
} as const;

const isActiveDateAfterDate = (activeDate: string | undefined, currentDate: Date) =>
    hasValue(activeDate) && currentDate < new Date(activeDate ?? '');

const ReimbursementReportsPage = () => {
    const dispatch = useThunkDispatch();
    const { activeDate, teamId } = useTeamProps();
    const { isLoading, loadedReportId, reimbursementReports, selectedYear } = useSelector(
        (state: AppStore) => ({
            isLoading: hasApiActivity(
                state,
                LIST_REIMBURSEMENT_REPORTS_ACTION,
                GET_REIMBURSEMENT_REPORT_ACTION
            ),
            loadedReportId: state.reimbursementReport?.globalId,
            reimbursementReports: state.reimbursementReports,
            selectedYear: state.reimbursementReportState.reimbursementReportSelectedYear,
        })
    );

    const [search, setSearch] = useState('');
    const [reportGlobalId, setReportGlobalId] = useState('');
    const [query, setQuery] = useQuery();
    const globalIdQueryParam = query.get(REIMBURSEMENT_REPORTS_QUERY_PARAM.REIMBURSEMENT_REPORT_ID);

    const setCurrentReportGlobalId = useCallback(
        (newReportGlobalId: string) => {
            if (hasValue(newReportGlobalId)) {
                setReportGlobalId(newReportGlobalId);
                query.set(
                    REIMBURSEMENT_REPORTS_QUERY_PARAM.REIMBURSEMENT_REPORT_ID,
                    newReportGlobalId
                );
                setQuery(query);
                if (!hasValue(selectedYear)) {
                    dispatch(
                        setReimbursementReportSelectedYear(
                            reimbursementReports
                                .find((x) => x.globalId === newReportGlobalId)
                                ?.startDate.getYear()
                                .toString() ?? ''
                        )
                    );
                }
            }
        },
        [dispatch, query, reimbursementReports, selectedYear, setQuery]
    );

    useEffect(() => {
        if (reimbursementReports.length === 0) {
            dispatch(listReimbursementReports(teamId));
        }
    }, [dispatch, reimbursementReports.length, teamId]);

    useEffect(() => {
        if (reimbursementReports.length > 0 && !hasValue(reportGlobalId)) {
            let initialReportGlobalId = '';
            if (hasValue(globalIdQueryParam)) {
                initialReportGlobalId = globalIdQueryParam;
            } else {
                const indexOfActiveDateReport = reimbursementReports.findIndex(
                    (x) =>
                        x.startDate.getYear() === activeDate?.getYear() &&
                        x.startDate.getMonth() === activeDate?.getMonth() &&
                        new Date(x.endDate) >= new Date(activeDate)
                );
                const currentDate = new Date();

                const indexOfCurrentDateReport = reimbursementReports.findIndex(
                    (x) =>
                        x.startDate.getYear() === currentDate.getFullYear() &&
                        x.startDate.getMonth() - 1 === currentDate.getMonth() &&
                        new Date(x.endDate) >= currentDate
                );
                if (
                    isActiveDateAfterDate(activeDate, currentDate) &&
                    indexOfActiveDateReport !== -1
                ) {
                    initialReportGlobalId = (reimbursementReports[
                        indexOfActiveDateReport
                    ] as IBasicReimbursementReportDto).globalId;
                } else if (indexOfCurrentDateReport !== -1) {
                    initialReportGlobalId = (reimbursementReports[
                        indexOfCurrentDateReport
                    ] as IBasicReimbursementReportDto).globalId;
                } else {
                    initialReportGlobalId = (reimbursementReports[
                        reimbursementReports.length - 1
                    ] as IBasicReimbursementReportDto).globalId;
                }
            }
            setCurrentReportGlobalId(initialReportGlobalId);
        }
    }, [
        activeDate,
        globalIdQueryParam,
        reimbursementReports,
        reportGlobalId,
        setCurrentReportGlobalId,
    ]);

    useEffect(() => {
        if (
            hasValue(reportGlobalId) &&
            reimbursementReports.length > 0 &&
            reportGlobalId !== loadedReportId
        ) {
            dispatch(getReimbursementReport(reportGlobalId));
        }
    }, [dispatch, loadedReportId, reimbursementReports.length, reportGlobalId]);

    useEffect(
        () => () => {
            dispatch(clearReimbursementReport());
            dispatch(clearReimbursementReports());
            dispatch(clearReimbursementReportYears());
        },
        [dispatch]
    );

    const reportContent =
        reimbursementReports.length === 0 ? (
            <NoRowsMessage text="Reimbursement Reports not available" />
        ) : (
            <ReimbursementReportTable globalFilter={search} />
        );

    return (
        <React.Fragment>
            <Paper sx={{ p: 2 }}>
                <ReimbursementReportsPageHeader
                    reportGlobalId={reportGlobalId}
                    setReportGlobalId={setReportGlobalId}
                />
            </Paper>
            <Paper sx={{ my: 1, p: 1 }}>
                <TextField
                    data-cy="search"
                    name="search"
                    onChange={onChange(setSearch)}
                    placeholder={"Filter Reimbursement Report results by member's first/last name"}
                    type="search"
                    value={search}
                />
            </Paper>
            <Paper sx={{ p: 2 }}>
                <Skeleton count={4} height="400px" isEnabled={isLoading} sx={{ mb: 2 }}>
                    {reportContent}
                </Skeleton>
            </Paper>
        </React.Fragment>
    );
};

export default hot(module)(ReimbursementReportsPage);
