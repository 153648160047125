import { CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT_ACTION } from 'actions/submittedExpenses/calculateSubmittedExpenseReimbursementAmount';
import { EDIT_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/editSubmittedExpense';
import {
    GET_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS,
    getSubmittedExpenseAssignableReimbursementReports,
} from 'actions/submittedExpenses/getAssignableReimbursementReports';
import { GET_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/getSubmittedExpense';
import { RESUBMIT_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/resubmitSubmittedExpense';
import { AssignableReportOptions, ExpenseStatus } from 'api/generated/enums';
import { IAssignableReportDto, ISubmittedExpenseDto } from 'api/generated/models';
import { EditLockedSubmittedExpense, EditSubmittedExpense } from 'api/generated/permissions';
import Select from 'components/Select';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';

type IReimbursementReportSelect = {
    assignedReimbursementReportOption?: AssignableReportOptions;
    errors?: string[];
    expenseStatus?: ExpenseStatus;
    onChange: (
        {
            target: { value },
        }: React.ChangeEvent<HTMLInputElement>,
        globalId?: string
    ) => void;
    reportOptions: IAssignableReportDto[];
    submittedExpense: ISubmittedExpenseDto;
};

const ReimbursementReportSelect = ({
    assignedReimbursementReportOption,
    errors,
    expenseStatus,
    onChange,
    reportOptions,
    submittedExpense,
}: IReimbursementReportSelect) => {
    const dispatch = useThunkDispatch();
    const {
        canEditLockedSubmittedExpense,
        canEditSubmittedExpense,
        hasCompletedInitialRequest,
        isLoading,
    } = useSelector((state: AppStore) => ({
        canEditLockedSubmittedExpense: hasSomePermissions(state, EditLockedSubmittedExpense),
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        hasCompletedInitialRequest: hasCompletedRequest(state, GET_SUBMITTED_EXPENSE_ACTION),
        isLoading: hasApiActivity(
            state,
            CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT_ACTION,
            GET_SUBMITTED_EXPENSE_ACTION,
            EDIT_SUBMITTED_EXPENSE_ACTION,
            RESUBMIT_SUBMITTED_EXPENSE_ACTION,
            GET_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS
        ),
    }));

    useEffect(() => {
        if (canEditSubmittedExpense && expenseStatus === ExpenseStatus.Approved) {
            dispatch(getSubmittedExpenseAssignableReimbursementReports(submittedExpense.globalId));
        }
    }, [
        canEditLockedSubmittedExpense,
        canEditSubmittedExpense,
        dispatch,
        expenseStatus,
        submittedExpense.globalId,
        submittedExpense.isLocked,
    ]);

    return (
        <React.Fragment>
            {expenseStatus?.toString() === ExpenseStatus.Approved.toString() &&
                canEditSubmittedExpense && (
                    <Select
                        data-cy="reimbursement-report-selection-offset-dropdown"
                        defaultText="Choose a Report"
                        defaultValue=""
                        disabled={submittedExpense.isLocked && !canEditLockedSubmittedExpense}
                        errors={errors}
                        isLoading={isLoading || !hasCompletedInitialRequest}
                        items={reportOptions}
                        label="Reimbursement Report"
                        name="reimbursementReportSelectionOffset"
                        onChange={onChange}
                        optionText="termLabel"
                        optionValue="assignableReportOption"
                        value={assignedReimbursementReportOption}
                    />
                )}
        </React.Fragment>
    );
};

export default hot(module)(ReimbursementReportSelect);
