import { Grid, Typography } from '@mui/material';
import { clearShoppingPathwayMarketplacePlan } from 'actions/clear';
import {
    GET_HOUSEHOLD_MEMBERS_ACTION,
    getHouseholdMembers,
} from 'actions/householdMember/getHouseholdMembers';
import {
    GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION,
    getMarketplacePlanRatesForPlans,
} from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { getPathway } from 'actions/pathwayBlueprint/getPathway';
import {
    LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION,
    listProvidersForHousehold,
} from 'actions/provider/listProvidersForHousehold';
import { completeTask } from 'actions/taskFlows/completeTask';
import { EnrollmentStatuses, PlanListing } from 'api/generated/enums';
import {
    IMarketplacePlanDto,
    IPlanInfoDto,
    ISelectedPlansDto,
    TaskDto,
} from 'api/generated/models';
import HelpContact from 'components/helpComponents/HelpContact';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { convertToPlanInfo, MAX_WIDTH } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import MultiRecommendedPlanContent from 'pages/dashboard/ichraTaskFlow/taskFlowPages/recommendedBenefitsPathPage/MultiRecommendedPlanContent';
import SingleRecommendedPlanContent from 'pages/dashboard/ichraTaskFlow/taskFlowPages/recommendedBenefitsPathPage/SingleRecommendedPlanContent';
import { ADVISEMENT_SCHEDULED_MESSAGE } from 'pages/shop/shopPageUtilities';
import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const MEDICARE_AGE = 65;

const RecommendedBenefitsPathPage = ({
    currentTask,
    pathwayId,
}: {
    currentTask: TaskDto;
    pathwayId: string;
}) => {
    const {
        hasFetchedHousehold,
        hasFetchedProviders,
        hasLoadedPlan,
        householdMembers,
        isLoadingPlan,
        plans,
        recommendedPlans,
    } = useSelector((state: AppStore) => ({
        hasFetchedHousehold: hasCompletedRequest(state, GET_HOUSEHOLD_MEMBERS_ACTION),
        hasFetchedProviders: hasCompletedRequest(state, LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION),
        hasLoadedPlan: hasCompletedRequest(
            state,
            GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION
        ),
        householdMembers: state.householdMembers,
        isLoadingPlan: hasApiActivity(state, GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION),
        plans: state.shoppingPathwayMarketplacePlan,
        recommendedPlans: state.pathway.pathwayRecommendedPlans,
    }));
    const dispatch = useThunkDispatch();
    const { userId, user, yearlyUserInfo } = useUserProps();
    const filteredPlans = plans?.filter((p) => p.id !== undefined);
    const filteredRecommendedPlans = recommendedPlans?.filter((rp) =>
        filteredPlans.some((p) => p.id === rp.planId)
    );
    const numPlans = filteredPlans?.length ?? 0;
    const hasMultipleRecommendedPlans = numPlans > 1;

    const hhmsCovered = useMemo(() => {
        const hhms: string[] = [];
        householdMembers.forEach((hhm) => {
            if (hhm.needsCoverage && hhm.dateOfBirth.getAge(user?.activeDate) < MEDICARE_AGE) {
                hhms.push(hhm.householdMemberId);
            }
        });
        return hhms;
    }, [householdMembers, user?.activeDate]);
    const isPrimaryCovered = (user?.dateOfBirth?.getAge(user?.activeDate) ?? 0) < MEDICARE_AGE;
    const individualOnly =
        (isPrimaryCovered && hhmsCovered.length === 0) ||
        (!isPrimaryCovered && hhmsCovered.length === 1);
    const excludedMembers = useMemo(() => {
        const memberIds = [];
        if (!isPrimaryCovered) {
            memberIds.push(userId);
        }
        const excludedHhms = householdMembers
            .filter((x) => !hhmsCovered.contains(x.householdMemberId))
            .map((x) => x.householdMemberId);
        memberIds.push(...excludedHhms);
        return memberIds;
    }, [householdMembers, hhmsCovered, isPrimaryCovered, userId]);

    const handleSubmit = async (plan?: IMarketplacePlanDto) => {
        if (hasValue(plan)) {
            await dispatch(
                completeTask(currentTask.globalId, {
                    isWaived: false,
                    planInfoDtos: [
                        convertToPlanInfo(
                            hhmsCovered,
                            isPrimaryCovered,
                            plan,
                            undefined,
                            user?.activeDate?.getYear()
                        ) as IPlanInfoDto,
                    ],
                } as ISelectedPlansDto)
            );
        } else {
            dispatch(completeTask(currentTask.globalId, undefined));
        }
    };

    useEffect(() => {
        if (!hasFetchedHousehold) {
            dispatch(getHouseholdMembers(userId, user?.activeDate?.getYear()));
        }
    }, [dispatch, hasFetchedHousehold, user?.activeDate, userId]);

    useEffect(() => {
        if (!hasFetchedProviders) {
            dispatch(listProvidersForHousehold(userId, user?.activeDate?.getYear()));
        }
    }, [dispatch, hasFetchedProviders, user?.activeDate, userId]);

    useEffect(() => {
        if (hasValue(pathwayId)) {
            dispatch(getPathway(pathwayId));
        }
    }, [dispatch, pathwayId]);

    useEffect(() => {
        if (
            hasValue(user) &&
            arrayHasValue(recommendedPlans) &&
            !arrayHasValue(plans) &&
            !isLoadingPlan &&
            !hasLoadedPlan
        ) {
            dispatch(clearShoppingPathwayMarketplacePlan());
            if (
                hasValue(
                    recommendedPlans.find((rp) => rp.benchmarkType === PlanListing.OffExchange)
                )
            ) {
                dispatch(
                    getMarketplacePlanRatesForPlans(
                        userId,
                        recommendedPlans
                            .filter((rp) => rp.benchmarkType === PlanListing.OffExchange)
                            .map((rp) => rp.planId as string),
                        excludedMembers,
                        user?.activeDate?.getYear() as number,
                        true,
                        false,
                        false,
                        GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION
                    )
                );
            }
            if (
                hasValue(recommendedPlans.find((rp) => rp.benchmarkType === PlanListing.OnExchange))
            ) {
                dispatch(
                    getMarketplacePlanRatesForPlans(
                        userId,
                        recommendedPlans
                            .filter((rp) => rp.benchmarkType === PlanListing.OnExchange)
                            .map((rp) => rp.planId as string),
                        excludedMembers,
                        user?.activeDate?.getYear() as number,
                        false,
                        false,
                        false,
                        GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION
                    )
                );
            }
        }
    }, [
        dispatch,
        excludedMembers,
        hasLoadedPlan,
        isLoadingPlan,
        plans,
        recommendedPlans,
        user,
        userId,
    ]);

    return (
        <Grid
            alignItems="center"
            container
            direction="column"
            item
            maxWidth={MAX_WIDTH}
            rowGap={2}
            width="100%"
        >
            {hasMultipleRecommendedPlans ? (
                <MultiRecommendedPlanContent
                    currentTask={currentTask}
                    excludedMembers={excludedMembers}
                    handleSubmit={handleSubmit}
                    isPrimaryCovered={isPrimaryCovered}
                    plans={filteredPlans}
                    recommendedPlans={filteredRecommendedPlans}
                />
            ) : (
                <SingleRecommendedPlanContent
                    currentTask={currentTask}
                    handleSubmit={handleSubmit}
                    hhmsCovered={hhmsCovered}
                    individualOnly={individualOnly}
                    isPrimaryCovered={isPrimaryCovered}
                    plan={filteredPlans[0]}
                />
            )}

            <Grid item xs={12}>
                {yearlyUserInfo?.enrollmentStatus?.value ===
                EnrollmentStatuses.AdvisementScheduled ? (
                    <Typography textAlign="center" variant="body2">
                        {ADVISEMENT_SCHEDULED_MESSAGE}
                    </Typography>
                ) : (
                    <HelpContact isIchraFlow />
                )}
            </Grid>
        </Grid>
    );
};

export default hot(module)(RecommendedBenefitsPathPage);
