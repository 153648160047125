import IncomeForm from 'pages/survey/incomeSteps/IncomeForm';
import AdditionalIncomeInformationModal from 'pages/survey/informationModals/AdditionalIncomeInformationModal';
import { IStepProps } from 'pages/survey/StepWrapper';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const AdditionalIncomeForm = ({ children, commonProps }: IStepProps) => {
    const { incomeYear } = useSelector((state: AppStore) => ({
        incomeYear: state.surveyState.incomeYear,
    }));

    return (
        <IncomeForm
            commonProps={commonProps}
            data-cy="additional-income"
            description={
                'Please list the total amount of additional income earned by you and/or any members of your household. Enter "0" if none.'
            }
            incomeProp="additionalIncome"
            infoText="What income should I include here?"
            infoTextClassName="h4 mb-3"
            isInfoTextAbove
            label="Additional Income"
            ModalToShow={AdditionalIncomeInformationModal}
            title={`Will you or anyone in your household earn bonuses or commissions from their primary job, 
                or any income from outside of their primary job in ${incomeYear}?`}
        >
            {children}
        </IncomeForm>
    );
};

export default hot(module)(AdditionalIncomeForm);
