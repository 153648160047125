import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DELETE_RESOURCE_ACTION, deleteResource } from 'actions/resources/deleteResource';
import ActionButtons from 'components/ActionButtons';
import Icon from 'components/Icon';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { openNewTab } from 'utilities/route';

const isValidUrlOrEmpty = (value: string | undefined) => {
    const pattern = /^https?:\/\//i;
    return !hasValue(value) || pattern.test(value);
};

const getThumbnailUrl = async (url: string | undefined) => {
    if (!isValidUrlOrEmpty(url)) {
        return '';
    }
    if (url?.includes('youtube.com')) {
        const videoId = new URL(url).searchParams.get('v');
        if (videoId !== null) {
            return `https://img.youtube.com/vi/${videoId}/0.jpg`;
        }
    }
    if (url?.includes('vimeo.com')) {
        const response = await fetch(`https://vimeo.com/api/oembed.json?url=${url}`);
        if (response.ok) {
            const responseData = await response.json();
            return responseData.thumbnail_url || '';
        }
    }
    return '';
};

type IResourceCardProps = {
    id?: string;
    onClick?: () => void;
    showMenu?: boolean;
    title: string | undefined;
    url: string | undefined;
};

const ResourceCard = ({ id, onClick, showMenu, title, url }: IResourceCardProps) => {
    const dispatch = useThunkDispatch();
    const [thumbnailUrl, setThumbnailUrl] = useState('');

    const { isDeleting } = useSelector((state: AppStore) => ({
        isDeleting: hasApiActivity(state, DELETE_RESOURCE_ACTION),
    }));

    useEffect(() => {
        getThumbnailUrl(url).then((u) => setThumbnailUrl(u));
    }, [url]);

    const onDeleteClick = async () => await dispatch(deleteResource(id));
    const actionItems = [
        {
            isVisible: true,
            onClick: () => openNewTab(url),
            text: 'View Content',
        },
        {
            isConfirm: true,
            isLoading: isDeleting,
            isVisible: true,
            onClick: onDeleteClick,
            text: 'Delete',
        },
    ];

    return (
        <Card
            className={`flex-grow-1 ${onClick ? 'clickable' : ''}`}
            elevation={2}
            onClick={onClick}
        >
            <CardContent className="d-flex">
                <Row className="align-items-center flex-grow-1">
                    <Col className="d-flex justify-content-center" sm="3" xs="12">
                        {!hasValue(thumbnailUrl) ? (
                            <Icon className="h-100" size="4">
                                web
                            </Icon>
                        ) : (
                            <img className="w-100" src={thumbnailUrl}></img>
                        )}
                    </Col>
                    <Col sm="9" xs="12">
                        <Row className="flex-nowrap align-items-center">
                            <Col>
                                <Typography variant="h4">{title}</Typography>
                            </Col>
                            {showMenu && (
                                <Col className="d-flex justify-content-end" xs="auto">
                                    <ActionButtons
                                        dropdownWhenLengthGreaterThan={0}
                                        isThreeDotButton
                                        items={actionItems}
                                    ></ActionButtons>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};

export default hot(module)(ResourceCard);
