import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { clearApiActivity, clearTeamBenefit, clearUserTermDetails } from 'actions/clear';
import {
    clearManageMembersPaginationParams,
    setManageMembersPaginationParams,
    setManageMembersSearch,
    setManageMembersShowOnlyInactive,
} from 'actions/manageMembersActions';
import { GET_TEAM_BENEFIT_ACTION, getTeamBenefit } from 'actions/teamBenefit/getTeamBenefit';
import {
    PAGE_USER_TERM_DETAILS_ACTION,
    pageUserTermDetails,
} from 'actions/userTeamBenefitTermDetail/pageUserTermDetails';
import {
    ContributionFrequency,
    GroupLevels,
    TeamBenefitTypes,
    UserTeamBenefitTermDetailStatuses,
} from 'api/generated/enums';
import { ITeamBenefitTermDetailDto, IUserTermDetailManageMemberDto } from 'api/generated/models';
import { EditSubmittedExpense } from 'api/generated/permissions';
import Checkbox from 'components/Checkbox';
import {
    COVERAGE_LEVEL_COLUMN_ID,
    COVERAGE_LEVEL_FILTER_ID,
    COVERAGE_LEVEL_OPTIONS,
    CoverageLevelHeader,
} from 'components/commonTableComponents/CoverageLevelHeader';
import { COMMON_HEADER_DROPDOWN_TABLE_PROPS } from 'components/headerDropdown/HeaderDropdown';
import ManageMemberActionButtons from 'components/manageMembersActionButtons/ManageMemberActionButtons';
import PageHeader from 'components/PageHeader';
import NoRowsMessage from 'components/reactTable/NoRowsMessage';
import ReactTable, { GLOBAL_SEARCH_DEBOUNCE_MILLISECONDS } from 'components/reactTable/ReactTable';
import Select from 'components/Select';
import TableHeader from 'components/TableHeader';
import TeamBenefitTermDetailsModal from 'components/teamBenefitActionButtons/TeamBenefitTermDetailsModal';
import TextField from 'components/TextField';
import { push } from 'connected-react-router';
import { MINIMUM_DATE } from 'constants/date';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import {
    CoverageLevelCell,
    CoverageStartAndEndDatesCell,
    EmployeeCostTooltipContent,
    EmployerCostTooltipContent,
    HouseholdMembersCoveredCell,
    IndicatorCell,
    ITeamBenefitMemberRowData,
    MEMBER_STATUS_OPTIONS,
    NameCell,
    StatusCell,
    StatusHeader,
    UserTeamBenefitCostCell,
} from 'pages/teamBenefitMemberManagement/TeamBenefitMemberManagementCells';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import {
    Cell,
    Column,
    Filters,
    Row as RtRow,
    SortingRule,
    TableRowProps,
    TableState,
    useAsyncDebounce,
} from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { getDisplayFirstNameWithAge } from 'utilities/household';
import { getDateOrNull } from 'utilities/moment';
import { getColumnWidth } from 'utilities/reactTable';

const HOUSEHOLD_MEMBERS_COVERED_DEFAULT_WIDTH = 110;

const MEMBERS_COVERED_PADDING_AMOUNT = 7;
const MEMBERS_COVERED_PADDING = Array(MEMBERS_COVERED_PADDING_AMOUNT).join(' ');
const STATUS_UNKNOWN = UserTeamBenefitTermDetailStatuses[UserTeamBenefitTermDetailStatuses.Unknown];
const STATUS_COLUMN_ID = 'userTeamBenefitStatus';
const STATUS_FILTER_ID = 'userTeamBenefitStatusFilter';
const INACTIVE_MEMBER_COLUMN_ID = 'inactiveMember';
const INACTIVE_MEMBER_FILTER_ID = 'inactiveMemberFilter';
const NAME_COLUMN_ID = 'userName';

const getCustomRowProps = (row: RtRow<ITeamBenefitMemberRowData>) =>
    (({
        'data-cy': row?.original?.benefit?.user?.email as string,
    } as unknown) as TableRowProps);

const getRowId = ({ benefit }: ITeamBenefitMemberRowData) => benefit?.user?.userId as string;

const formatHsaOrFsaEmployerContribution = (
    benefit: IUserTermDetailManageMemberDto | undefined
) => {
    if (
        benefit?.employerContribution === undefined ||
        benefit?.contributionFrequency === undefined
    ) {
        return '';
    }
    const frequency =
        benefit.contributionFrequency === ContributionFrequency.Monthly ? 'monthly' : 'yearly';

    return `$${benefit.employerContribution} ${frequency}`;
};

export const handleTermDisplay = (item: ITeamBenefitTermDetailDto | undefined) =>
    `${formatDateForDisplay(item?.startDate)} - ${formatDateForDisplay(item?.endDate)}`;

const initialFilters: Filters<ITeamBenefitMemberRowData> = [
    {
        id: COVERAGE_LEVEL_COLUMN_ID,
        value: COVERAGE_LEVEL_OPTIONS,
    },
    {
        id: STATUS_COLUMN_ID,
        value: MEMBER_STATUS_OPTIONS,
    },
];
const initialSortBy: SortingRule<ITeamBenefitMemberRowData>[] = [
    { desc: false, id: NAME_COLUMN_ID },
];

const hiddenColumns = [INACTIVE_MEMBER_COLUMN_ID];

const TeamBenefitMemberManagementPage = () => {
    const dispatch = useThunkDispatch();
    const params = useParams<{
        teamBenefitId?: string;
        teamBenefitTermDetailId?: string;
    }>();
    const {
        canEditSubmittedExpense,
        hasCompletedInitialRequest,
        isLoading,
        paginationParams,
        teamBenefit,
        userTermDetails,
    } = useSelector((state: AppStore) => ({
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        hasCompletedInitialRequest: hasCompletedRequest(state, PAGE_USER_TERM_DETAILS_ACTION),
        isLoading: hasApiActivity(state, GET_TEAM_BENEFIT_ACTION, PAGE_USER_TERM_DETAILS_ACTION),
        paginationParams: state.manageMembersState,
        teamBenefit: state.teamBenefit,
        userTermDetails: state.pagedUserTermDetails,
    }));
    const { teamId } = useTeamProps();
    const [showOnlyInactiveMembers, setShowOnlyInactiveMembers] = useState(false);
    const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
    const [currentTeamBenefitTermDetailId, setCurrentTeamBenefitTermDetailId] = useState(
        params.teamBenefitTermDetailId
    );
    const [pendingRequest, setPendingRequest] = useState<boolean>(false);

    const resetTableOnDataChange = useRef(true);

    const teamBenefitTermDetails = teamBenefit?.teamBenefitTermDetails as ITeamBenefitTermDetailDto[];

    useEffect(() => {
        setPendingRequest(true);
        dispatch(getTeamBenefit(params.teamBenefitId as string));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        resetTableOnDataChange.current = false;
    });

    useEffect(() => {
        dispatch(clearTeamBenefit());
        dispatch(clearManageMembersPaginationParams());
    }, [dispatch]);

    useEffect(() => {
        if (
            currentTeamBenefitTermDetailId === '0' &&
            (teamBenefit?.teamBenefitTermDetails?.length ?? 0) > 0
        ) {
            const termId = (teamBenefit?.teamBenefitTermDetails as ITeamBenefitTermDetailDto[])[0]
                ?.id;
            params.teamBenefitTermDetailId = termId ?? '0';
            dispatch(push(generatePath(TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH, params)));
            setCurrentTeamBenefitTermDetailId(termId);
        }
    }, [currentTeamBenefitTermDetailId, dispatch, params, teamBenefit.teamBenefitTermDetails]);

    const data = useMemo<ITeamBenefitMemberRowData[]>(
        () =>
            userTermDetails?.results?.map(({ user, allHouseholdMembers }) => ({
                allHouseholdMembers,
                teamBenefit,
                user,
                benefit: userTermDetails.results?.find((x) => x.userId === user?.userId),
                teamBenefitTermDetailId: params.teamBenefitTermDetailId,
            })) ?? [],
        [teamBenefit, userTermDetails, params.teamBenefitTermDetailId]
    );

    const columns: (Column<ITeamBenefitMemberRowData> & {
        TooltipContent?: React.ReactNode;
    })[] = useMemo(() => {
        const cols: (Column<ITeamBenefitMemberRowData> & {
            TooltipContent?: React.ReactNode;
        })[] = [
            {
                filter: INACTIVE_MEMBER_FILTER_ID,
                id: INACTIVE_MEMBER_COLUMN_ID,
            },
            {
                Cell: IndicatorCell,
                disableResizing: true,
                id: 'indicators',
                width: 30,
            },
            {
                accessor: ({ benefit }) => `${benefit?.user?.lastName} ${benefit?.user?.firstName}`,
                Cell: NameCell,
                Header: 'Name',
                id: NAME_COLUMN_ID,
                width: 150,
            },
            {
                accessor: ({ benefit }) => benefit?.user?.email,
                Header: 'Email',
                id: 'email',
                width: getColumnWidth(
                    data,
                    ({ benefit }) => benefit?.user?.email as string,
                    'Email'
                ),
            },
            {
                ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
                accessor: ({ benefit }) => benefit?.statusId ?? STATUS_UNKNOWN,
                Cell: StatusCell,
                className: 'overflow-visible',
                filter: STATUS_FILTER_ID,
                Header: StatusHeader,
                id: STATUS_COLUMN_ID,
                width: 150,
            },
            {
                ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
                accessor: ({ benefit }) =>
                    hasValue(benefit) && hasValue(benefit.coverageLevelId)
                        ? GroupLevels[benefit.coverageLevelId]
                        : 'z',
                Cell: CoverageLevelCell,
                filter: COVERAGE_LEVEL_FILTER_ID,
                Header: CoverageLevelHeader,
                id: COVERAGE_LEVEL_COLUMN_ID,
                width: 125,
            },
            {
                Cell: HouseholdMembersCoveredCell,
                className: 'overflow-visible',
                disableSortBy: false,
                Header: 'Household Members Covered',
                width: getColumnWidth(
                    data,
                    ({ benefit }) =>
                        benefit?.coveredHouseholdMembers
                            ?.map((hm: { householdMemberId: string }) =>
                                getDisplayFirstNameWithAge(
                                    benefit.allHouseholdMembers?.find(
                                        (h) => h.householdMemberId === hm.householdMemberId
                                    )
                                )
                            )
                            .join(', ') + MEMBERS_COVERED_PADDING,
                    '',
                    { minWidth: HOUSEHOLD_MEMBERS_COVERED_DEFAULT_WIDTH }
                ),
            },
            {
                accessor: ({ benefit }) =>
                    (getDateOrNull(benefit?.coverageEndDate)?.toDate() &&
                        getDateOrNull(benefit?.coverageStartDate)?.toDate()) ??
                    MINIMUM_DATE,
                Cell: CoverageStartAndEndDatesCell,
                Header: 'Coverage Start/End Date',
                id: 'coverageStartAndEndDate',
                sortType: 'datetime',
                width: 220,
            },
            {
                accessor: ({ benefit }) =>
                    getDateOrNull(benefit?.user?.hireDate)?.toDate() ?? MINIMUM_DATE,
                Cell: ({
                    row: {
                        original: { benefit },
                    },
                }: Cell<ITeamBenefitMemberRowData>) =>
                    formatDateForDisplay(benefit?.user?.hireDate) ?? '',
                Header: 'Member Hire Date',
                id: 'hireDate',
                sortType: 'datetime',
                width: 90,
            },
            {
                accessor: ({ benefit }) => benefit?.planCost,
                Cell: UserTeamBenefitCostCell,
                Header: 'Plan Cost',
                id: 'planCost',
                width: 90,
            },
            {
                accessor: ({ benefit }) => benefit?.employerCost,
                Cell: UserTeamBenefitCostCell,
                Header: 'Employer Cost',
                id: 'employerCost',
                TooltipContent: EmployerCostTooltipContent,
                width: 100,
            },
            {
                accessor: ({ benefit }) => benefit?.employeeCost,
                Cell: UserTeamBenefitCostCell,
                Header: 'Employee Cost',
                id: 'employeeCost',
                TooltipContent: EmployeeCostTooltipContent,
                width: 100,
            },
        ];

        if (
            [
                TeamBenefitTypes.HealthSavingsAccount,
                TeamBenefitTypes.FlexibleSavingsAccount,
            ].contains(teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId)
        ) {
            cols.push({
                accessor: ({ benefit }) => formatHsaOrFsaEmployerContribution(benefit),
                Header: 'Employer Contribution',
                id: 'employerContribution',
                width: 110,
            });
        }

        return cols;
    }, [data, teamBenefit]);

    const [tableState, setTableState] = useState<
        Partial<
            Pick<
                TableState<IUserTermDetailManageMemberDto>,
                'filters' | 'pageIndex' | 'pageSize' | 'sortBy'
            >
        >
    >({ pageIndex: 0, pageSize: 15 });

    const pageUserTermDetailsCallback = useCallback(() => {
        setPendingRequest(false);
        return pageUserTermDetails(currentTeamBenefitTermDetailId ?? '', teamId, paginationParams);
    }, [currentTeamBenefitTermDetailId, paginationParams, teamId]);

    const debouncedListUserTermDetails = useAsyncDebounce(() => {
        dispatch(pageUserTermDetailsCallback());
    }, GLOBAL_SEARCH_DEBOUNCE_MILLISECONDS);

    useEffect(
        () => {
            if (hasCompletedInitialRequest) {
                dispatch(
                    pageUserTermDetails(
                        currentTeamBenefitTermDetailId ?? '',
                        teamId,
                        paginationParams
                    )
                );
            }
            return () => {
                dispatch(clearApiActivity());
                dispatch(clearUserTermDetails());
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, params.teamBenefitTermDetailId, teamId, currentTeamBenefitTermDetailId]
    );

    const onFetchData = (state: TableState<IUserTermDetailManageMemberDto>) => {
        setTableState(state);
        dispatch(setManageMembersPaginationParams(state));
        if (hasValue(params.teamBenefitTermDetailId) && params.teamBenefitTermDetailId !== '0') {
            debouncedListUserTermDetails();
        }
    };

    const carrier = teamBenefit?.teamBenefitTypesCarrier;

    const {
        closeModal: closeTermBenefitDetailsModal,
        isVisible: isTermBenefitDetailsModalVisible,
        openModal: openTermBenefitDetailsModal,
    } = useModalState();

    const currentIndex = hasValue(teamBenefitTermDetails)
        ? teamBenefitTermDetails.findIndex((tbtd) => tbtd.id === params.teamBenefitTermDetailId)
        : 0;
    const finalIndex = hasValue(teamBenefitTermDetails) ? teamBenefitTermDetails.length - 1 : 0;

    const navigateToNewTerm = (termId: string) => {
        params.teamBenefitTermDetailId = termId;
        dispatch(push(generatePath(TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH, params)));
        setCurrentTeamBenefitTermDetailId(termId);
    };

    const changeTerm = (offset: number) => {
        const newIndex = currentIndex + offset;
        if (newIndex >= 0 && newIndex <= finalIndex) {
            const newTermId = teamBenefitTermDetails?.[currentIndex + offset]?.id;

            navigateToNewTerm(newTermId ?? (currentTeamBenefitTermDetailId as string));
        }
    };

    const searchMember = useCallback(
        (e) => {
            dispatch(setManageMembersSearch(e.target.value));
            dispatch(debouncedListUserTermDetails);
        },
        [debouncedListUserTermDetails, dispatch]
    );

    const showOnlyInactiveMembersChange = (value: boolean) => {
        dispatch(setManageMembersShowOnlyInactive(value));
        setShowOnlyInactiveMembers(value);
        dispatch(debouncedListUserTermDetails);
    };

    return (
        <React.Fragment>
            {isTermBenefitDetailsModalVisible && (
                <TeamBenefitTermDetailsModal
                    currentTermDetailId={currentTeamBenefitTermDetailId}
                    onClose={closeTermBenefitDetailsModal}
                    teamBenefit={teamBenefit}
                />
            )}
            <br />
            <PageHeader>{`Manage Members: ${carrier?.teamBenefitType?.name} - ${carrier?.teamBenefitCarrier?.name}`}</PageHeader>
            <br />
            <TableHeader
                OverrideActionButtons={
                    !!teamBenefit && (
                        <ManageMemberActionButtons
                            selectedMemberIds={selectedMemberIds}
                            teamBenefit={teamBenefit}
                            teamBenefitTermDetailId={currentTeamBenefitTermDetailId ?? ''}
                        />
                    )
                }
                OverrideLeftSide={
                    <Stack
                        alignItems="center"
                        direction={{ md: 'row', xs: 'column' }}
                        marginTop={2}
                        mb={2}
                    >
                        {(teamBenefit?.teamBenefitTermDetails?.length ?? 0) > 0 && (
                            <React.Fragment>
                                <Stack>
                                    <TextField
                                        data-cy="search"
                                        InputProps={{ sx: { minWidth: 200 } }}
                                        label="Search"
                                        name="search"
                                        onChange={searchMember}
                                        placeholder="Search by name"
                                        size="small"
                                        sx={{ minWidth: 200 }}
                                        type="search"
                                        value={paginationParams.search}
                                    />
                                </Stack>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="end"
                                    marginLeft={5}
                                    spacing={2}
                                >
                                    <React.Fragment>
                                        <IconButton
                                            aria-label="back term"
                                            data-cy="back-term"
                                            disabled={currentIndex === 0}
                                            onClick={() => changeTerm(-1)}
                                        >
                                            <ArrowBackIosNewIcon />
                                        </IconButton>
                                        <Select
                                            data-cy="term"
                                            items={teamBenefitTermDetails}
                                            name="Term"
                                            onChange={(event) =>
                                                navigateToNewTerm(event.target.value)
                                            }
                                            optionText={handleTermDisplay}
                                            optionValue="id"
                                            sx={{ minWidth: 130 }}
                                            value={currentTeamBenefitTermDetailId}
                                        />
                                        <IconButton
                                            aria-label="forward term"
                                            data-cy="forward-term"
                                            disabled={currentIndex === finalIndex}
                                            onClick={() => changeTerm(1)}
                                        >
                                            <ArrowForwardIosIcon />
                                        </IconButton>
                                    </React.Fragment>
                                </Stack>
                                <Stack alignItems="right" direction="row" marginLeft={4}>
                                    <Checkbox
                                        checked={showOnlyInactiveMembers}
                                        className="m-0 ml-3"
                                        data-cy="inactive-checkbox"
                                        label="Show Only Inactive Members"
                                        onClick={() => {
                                            resetTableOnDataChange.current = true;
                                            showOnlyInactiveMembersChange(!showOnlyInactiveMembers);
                                        }}
                                        onMouseDown={(event) => {
                                            event.stopPropagation();
                                        }}
                                    />
                                </Stack>
                            </React.Fragment>
                        )}
                    </Stack>
                }
            />
            {teamBenefit?.teamBenefitTermDetails?.length === 0 ? (
                <React.Fragment>
                    <Divider sx={{ mt: 2 }} />
                    <NoRowsMessage
                        actionText="Add benefit term details using the actions drop-down above or the button below."
                        buttonText="Manage Benefit Term Details"
                        canTakeAction={
                            teamBenefit?.teamBenefitTermDetails.length === 0 &&
                            canEditSubmittedExpense
                        }
                        onAction={openTermBenefitDetailsModal}
                        text="No benefit term data available."
                    />
                    <Divider />
                </React.Fragment>
            ) : (
                <ReactTable
                    allPossibleRowIds={userTermDetails?.allPossibleUserIds ?? []}
                    autoResetOnDataChange={resetTableOnDataChange.current}
                    checkedRowIds={selectedMemberIds}
                    className="teamBenefitManageMemberTable"
                    columns={columns}
                    data={data}
                    getCustomRowProps={getCustomRowProps}
                    getRowId={getRowId}
                    hiddenColumns={hiddenColumns}
                    hideAllPageSizeOption
                    initialFilters={initialFilters}
                    initialSortBy={initialSortBy}
                    isRowSelectionEnabled
                    loading={isLoading || pendingRequest}
                    manualPagination
                    onFetchData={onFetchData}
                    pageSize={tableState.pageSize}
                    rowsWhenNoData={tableState.pageSize}
                    setCheckedRowIds={(x: string[]) => {
                        setSelectedMemberIds(x);
                    }}
                    totalCount={userTermDetails.totalCount}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(TeamBenefitMemberManagementPage);
