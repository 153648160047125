import { Card, CardContent, Divider, Typography } from '@mui/material';
import { patchPathwayBlueprintCosts } from 'actions/pathwayBlueprint/patchPathwayBlueprintCosts';
import { IPathwayBlueprint } from 'api/generated/models';
import EditableCurrencyTextField from 'components/EditableCurrencyTextField';
import { ISaveEditableTextField } from 'components/EditableTextField';
import InformationIconTooltip from 'components/InformationIconTooltip';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type ILabelAndValueProps = {
    className?: string;
    label: string;
    tooltipTitle?: React.ReactElement;
    value: number | string;
};

const LabelAndValue = ({ className, label, tooltipTitle, value }: ILabelAndValueProps) => (
    <Row className={`${className ?? ''} m-2`}>
        <Col className="text-right" xs="6">
            <strong className="m-1">{label}</strong>
        </Col>
        <Col className="text-left align-content-center" xs="auto">
            {value}
            {tooltipTitle && <InformationIconTooltip className="ml-1" title={tooltipTitle} />}
        </Col>
    </Row>
);

const LargerLabelAndValue = styled(LabelAndValue)`
    font-size: 1.1rem;
`;

type ILabelAndCurrencyInputProps = {
    allowNegative?: boolean;
    label: string;
    name: keyof IPathwayBlueprint;
    save: (label: string) => ISaveEditableTextField<IPathwayBlueprint>;
    setValue: (value: number) => void;
    value: number | undefined;
};

const LabelAndCurrencyInput = ({
    allowNegative = false,
    label,
    name,
    save,
    setValue,
    value,
}: ILabelAndCurrencyInputProps) => (
    <Row className="m-2 align-items-center flex-nowrap">
        <Col className="text-right" xs="6">
            <strong className="m-1">{label}</strong>
        </Col>
        <div>
            <EditableCurrencyTextField
                allowNegative={allowNegative}
                isOptional
                name={name}
                onChange={onChange(setValue)}
                placeholder={`Enter ${label}`}
                save={save(label)}
                size="small"
                validationSchema={string().label(label)}
                value={value}
            />
        </div>
    </Row>
);

const TooltipText = styled.div`
    white-space: pre-line;
`;

type IAnnualEmployerCostBreakdownProps = {
    pathwayBlueprint: IPathwayBlueprint;
};

const AnnualEmployerCostBreakdown = ({ pathwayBlueprint }: IAnnualEmployerCostBreakdownProps) => {
    const dispatch = useThunkDispatch();
    const [softwareFee, setSoftwareFee] = useState(pathwayBlueprint.softwareFee);
    const [hraCost, setHraCost] = useState(pathwayBlueprint.hraCost);
    const [mecCost, setMecCost] = useState(pathwayBlueprint.mecCost);
    const [adjustments, setAdjustments] = useState(pathwayBlueprint.adjustments);

    const save = (label: string): ISaveEditableTextField<IPathwayBlueprint> => async (
        name,
        value
    ) => {
        const patch = {
            [name]: !hasValue(value) ? null : value,
        };
        await dispatch(patchPathwayBlueprintCosts(pathwayBlueprint.id, patch, label));
    };

    const annualOldRenewalCost = pathwayBlueprint?.annualOldRenewalCost;
    const showRenewalSavings = hasValue(annualOldRenewalCost) && annualOldRenewalCost !== 0;

    const annualOldCost = pathwayBlueprint?.annualOldCost;
    const showSavings = hasValue(annualOldCost) && annualOldCost !== 0;

    return (
        <Card>
            <CardContent>
                <Typography variant="h4">Annual Employer Cost Breakdown</Typography>
                {pathwayBlueprint.hasIchraPathway && (
                    <LabelAndValue
                        label="ICHRA Reimbursements"
                        value={formatCurrency(pathwayBlueprint.annualProjectedReimbursementCost, {
                            preserveDecimal: true,
                        })}
                    />
                )}
                <LabelAndValue
                    label="Employee Wage+ amounts"
                    value={formatCurrency(pathwayBlueprint.annualProjectedWageUpCost)}
                />
                <LabelAndValue
                    label="Additional FICA"
                    tooltipTitle={
                        <TooltipText>{pathwayBlueprint.additionalFicaCostEquation}</TooltipText>
                    }
                    value={formatCurrency(pathwayBlueprint.additionalFicaCost)}
                />
                <LabelAndValue
                    label="Shared Responsibility Estimate"
                    tooltipTitle={
                        <TooltipText>
                            {pathwayBlueprint.sharedResponsibilityEstimateEquation}
                        </TooltipText>
                    }
                    value={formatCurrency(pathwayBlueprint.sharedResponsibilityEstimate)}
                />
                <LabelAndCurrencyInput
                    label="Subscription Fee"
                    name="softwareFee"
                    save={save}
                    setValue={setSoftwareFee}
                    value={softwareFee}
                />
                <LabelAndCurrencyInput
                    label="HRA Cost"
                    name="hraCost"
                    save={save}
                    setValue={setHraCost}
                    value={hraCost}
                />
                <LabelAndCurrencyInput
                    label="MEC Cost"
                    name="mecCost"
                    save={save}
                    setValue={setMecCost}
                    value={mecCost}
                />
                <LabelAndCurrencyInput
                    allowNegative
                    label="Adjustments"
                    name="adjustments"
                    save={save}
                    setValue={setAdjustments}
                    value={adjustments}
                />
                <LargerLabelAndValue
                    label="Total Cost"
                    tooltipTitle={
                        <TooltipText>{pathwayBlueprint.annualEmployerCostEquation}</TooltipText>
                    }
                    value={formatCurrency(pathwayBlueprint.annualEmployerCost)}
                />
                {showSavings && (
                    <LargerLabelAndValue
                        label="Projected Annual Savings Against Current Plan"
                        tooltipTitle={
                            <TooltipText>{pathwayBlueprint.projectedSavingsEquation}</TooltipText>
                        }
                        value={formatCurrency(pathwayBlueprint.projectedSavings)}
                    />
                )}
                {showRenewalSavings && (
                    <LargerLabelAndValue
                        label="Projected Annual Savings Against Renewal Plan"
                        tooltipTitle={
                            <TooltipText>
                                {pathwayBlueprint.projectedRenewalSavingsEquation}
                            </TooltipText>
                        }
                        value={formatCurrency(pathwayBlueprint.projectedRenewalSavings)}
                    />
                )}
                <Divider />
                <LabelAndValue
                    label="Employees Eligible for Tax Credits"
                    value={pathwayBlueprint.employeesEligibleForTaxCredits}
                />
                {pathwayBlueprint.hasIchraPathway && (
                    <LabelAndValue
                        label="Employees That ICHRA is Unaffordable For"
                        value={pathwayBlueprint.pathwayUsersWithUnaffordableIchra?.length ?? 0}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default hot(module)(AnnualEmployerCostBreakdown);
