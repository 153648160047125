import Grid from '@mui/material/Grid';
import { GET_BENEFITS_ACTION } from 'actions/benefits/getBenefits';
import Skeleton from 'components/Skeleton';
import BenefitSectionHeader from 'pages/benefits/BenefitSectionHeader';
import MemberTeamBenefitCard from 'pages/benefits/MemberTeamBenefitCard';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const MemberTeamBenefits = () => {
    const { isLoading, userTeamBenefitTermDetails } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_BENEFITS_ACTION),
        userTeamBenefitTermDetails: state.memberBenefits.userTeamBenefitTermDetails,
    }));
    const teamBenefitCards = userTeamBenefitTermDetails?.map((utbtd) => (
        <Grid item key={utbtd.id} sm={6} xs={12}>
            <MemberTeamBenefitCard userTeamBenefitTermDetail={utbtd} />
        </Grid>
    ));

    if (userTeamBenefitTermDetails?.length) {
        return (
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <BenefitSectionHeader>Team Benefits</BenefitSectionHeader>
                </Grid>
                <Skeleton
                    count={1}
                    height="300px"
                    isEnabled={isLoading}
                    sx={{ mb: 3 }}
                    width="100%"
                >
                    <Grid columnSpacing={4} container item xs={12}>
                        {teamBenefitCards}
                    </Grid>
                </Skeleton>
            </Grid>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(MemberTeamBenefits);
