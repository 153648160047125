import { HouseholdMemberTypes } from 'api/generated/enums';
import HouseholdIntro from 'pages/survey/householdSteps/HouseholdIntro';
import HouseholdMemberForm from 'pages/survey/householdSteps/HouseholdMemberForm';
import SpouseForm from 'pages/survey/householdSteps/SpouseForm';
import StepWrapper, { IStepProps } from 'pages/survey/StepWrapper';
import React from 'react';
import { hot } from 'react-hot-loader';

const ClaimedHouseholdMemberForm = (commonProps: IStepProps) => (
    <HouseholdMemberForm householdMemberType={HouseholdMemberTypes.Dependent} {...commonProps} />
);
const UnclaimedHouseholdMemberForm = (commonProps: IStepProps) => (
    <HouseholdMemberForm householdMemberType={HouseholdMemberTypes.Unclaimed} {...commonProps} />
);

const stepsToRender = [SpouseForm, ClaimedHouseholdMemberForm, UnclaimedHouseholdMemberForm];

const HouseholdWrapper = () => <StepWrapper Intro={HouseholdIntro} stepsToRender={stepsToRender} />;

export default hot(module)(HouseholdWrapper);
