import Select from 'components/Select';
import TableHeader from 'components/TableHeader';
import TeamMemberActionButtons from 'components/teamMembersActionButtons/TeamMembersActionButtons';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { setPeoplePageSearch } from 'pages/people/peopleActions';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getYears } from 'utilities/year';

const PeoplePageHeader = () => {
    const dispatch = useThunkDispatch();
    const { search, selectedYear } = useSelector((state: AppStore) => ({
        search: state.peopleState.search,
        selectedYear: state.profileState.selectedYear,
    }));

    const onChange = useCallback((e) => dispatch(setPeoplePageSearch(e.target.value)), [dispatch]);
    const onSelectedYearChange = useCallback(
        ({ target: { value } }) => dispatch(setSelectedYear(value)),
        [dispatch]
    );
    const years = useMemo(() => getYears(() => 1).map((x) => ({ name: `${x}` })), []);

    return (
        <TableHeader
            onChange={onChange}
            OverrideActionButtons={<TeamMemberActionButtons />}
            search={search}
        >
            <Select
                data-cy="selected-people-page-year"
                items={years}
                name="selectedYear"
                onChange={onSelectedYearChange}
                optionText="name"
                optionValue="name"
                sx={{ ml: { sm: 2 } }}
                value={selectedYear}
            />
        </TableHeader>
    );
};

export default hot(module)(PeoplePageHeader);
