import { IUser, PathwayCalculationSummary } from 'api/generated/models';
import PageHeader from 'components/PageHeader';
import SingleButtonModal from 'components/SingleButtonModal';
import CalculationSummarySection from 'pages/pathwayBlueprint/CalculationSummarySection';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

type ICalculationSummaryProps = {
    calculationSummary?: PathwayCalculationSummary;
    title: string;
};

const CalculationSummary = ({ calculationSummary, title }: ICalculationSummaryProps) => (
    <Col sm="6" xs="12">
        <Row className="justify-content-center mb-3">
            <PageHeader variant="h4">{title}</PageHeader>
        </Row>
        {calculationSummary ? (
            calculationSummary.summarySections?.map((x, index) => (
                <div key={index}>
                    <CalculationSummarySection data={x} />
                </div>
            ))
        ) : (
            <Row className="justify-content-center">
                <p>No calculation data for {title}</p>
            </Row>
        )}
    </Col>
);

type ICalculationSummaryModalProps = {
    active?: PathwayCalculationSummary;
    isIchra: boolean;
    onClose: () => void;
    projected?: PathwayCalculationSummary;
    user: IUser | undefined;
};

const CalculationSummaryModal = ({
    active,
    isIchra,
    projected,
    onClose,
    user,
}: ICalculationSummaryModalProps) => {
    const costModel = isIchra ? 'Reimbursement' : 'Wage+';
    return (
        <SingleButtonModal
            body={
                <Row>
                    <CalculationSummary
                        calculationSummary={projected}
                        title={`Projected ${costModel}`}
                    />
                    <CalculationSummary calculationSummary={active} title={`Active ${costModel}`} />
                </Row>
            }
            buttonClickHandler={onClose}
            buttonText="Close"
            size="xl"
            title={`Calculation Summary for ${user?.displayName}`}
        />
    );
};

export default hot(module)(CalculationSummaryModal);
