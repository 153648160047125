import Autorenew from '@mui/icons-material/Autorenew';
import { ButtonProps } from '@mui/material';
import { Box } from '@mui/system';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import {
    APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT,
    applyCalculationForPathwayBlueprint,
} from 'actions/pathwayBlueprint/applyCalculationForPathwayBlueprint';
import { ASSIGN_USERS_ACTION, assignUsers } from 'actions/pathwayBlueprint/assignUsers';
import {
    CALCULATE_PATHWAY_USER_ACTION,
    calculatePathwayUser,
} from 'actions/pathwayBlueprint/calculatePathwayUser';
import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { getPathwayUser } from 'actions/pathwayBlueprint/getPathwayUser';
import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import { GroupLevels, PathwayTypes, UserInfoStatus } from 'api/generated/enums';
import { ActiveWageUp, IPathway, IPathwayUser, IUser } from 'api/generated/models';
import {
    CalculatePathwayBlueprint,
    EditPathway,
    EditUserMajorMedicalCoverageLevel,
} from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import Button from 'components/Button';
import ConditionalTooltip from 'components/ConditionalTooltip';
import DoubleBangIcon from 'components/DoubleBangIcon';
import DropDown, { IDropDownValue } from 'components/DropDown';
import InformationIconTooltip from 'components/InformationIconTooltip';
import Tooltip from 'components/Tooltip';
import UnderlinedContentWithTooltip from 'components/UnderlinedContentWithTooltip';
import UserInfoTableCell from 'components/UserInfoTableCell';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import CalculationSummaryModal from 'pages/pathwayBlueprint/CalculationSummaryModal';
import { openCustomActiveWageUpModal } from 'pages/pathwayBlueprint/customActiveWageUpModalActions';
import OtherCostsModal from 'pages/pathwayBlueprint/OtherCostsModal';
import {
    openCustomReimbursementModal,
    openCustomWageUpModal,
} from 'pages/pathwayBlueprint/pathwayCustomProjectedModalActions';
import { openCustomTotalCostModal } from 'pages/pathwayBlueprint/pathwayTotalCostModalActions';
import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { PROFILE_PATH, TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity, hasApiActivityWithParams } from 'selectors/activity';
import styled from 'styled-components';
import { enumToNameValueArray, hasValue } from 'utilities';
import { formatCurrency, formatCurrencyOptionalDecimal, formatDateAtTime } from 'utilities/format';
import {
    getActiveAndProjectedDifferentMessage,
    getIsPathwayOfType,
    isCustomReimbursementsAllowed,
    isIchraPathwayType,
    isIchraPathwayTypeWithPlanCost,
    showActiveContributionByTeamState,
} from 'utilities/pathways';
import { CustomColumnCell, CustomColumnHeader } from 'utilities/reactTable';

export type IPathwayRowData = IPathwayUser & {
    activeWageUp?: ActiveWageUp;
    disabled?: boolean;
    hasMemberVerifiedInfo?: boolean;
    majorMedicalCoverageLevel?: GroupLevels;
    memberVerifiedInfoId?: string;
    pathwayTypeId: PathwayTypes;
    pathwayYear: number;
    teamId?: string;
    user?: IUser;
};

export type IPathwayColumnFields = {
    isCostComparison?: boolean;
    pathwayId?: string;
    selection?: string[];
    setSelection?: (newSelection: string[]) => void;
};

export const StaleIcon = ({
    isStale,
    lastCalculatedDate,
    lastCalculatedFieldName,
}: {
    isStale: boolean;
    lastCalculatedDate: string | undefined;
    lastCalculatedFieldName: string;
}) => {
    if (!isStale) {
        return <React.Fragment />;
    }

    return (
        <Tooltip
            title={
                <span>
                    <div>
                        There have been changes to member data that will likely change the costs for
                        this pathway blueprint.
                    </div>
                    <br />
                    <div className="text-left">
                        Editing a pathway or assigning a user to a pathway will affect costs. Fields
                        on a member/household member that also affect costs:
                    </div>
                    <ul className="text-left">
                        <li>Date of Birth</li>
                        <li>Gender</li>
                        <li>Is Pregnant</li>
                        <li>Uses Tobacco</li>
                        <li>Parent, Spouse, or Employer Other Coverage</li>
                        <li>Coverage Level</li>
                        <li>Needs Major Medical Coverage</li>
                        <li>Filing Status</li>
                        <li>Household Income</li>
                        <li>Use Premium Tax Credits</li>
                        <li>Home Address State, County, or Zip Code</li>
                        <li>New/Removed Household Members</li>
                    </ul>

                    <div>
                        {lastCalculatedFieldName} - {formatDateAtTime(lastCalculatedDate, 'Never')}
                    </div>
                </span>
            }
        >
            <Autorenew sx={{ color: (theme) => theme.palette.yellow.main }} />
        </Tooltip>
    );
};

export const UNKNOWN = 'Unknown';
const TEXT_PRIMARY = 'text-primary';
export const AssignHeader = ({
    column: { pathwayId },
    selectedFlatRows,
}: CustomColumnHeader<IPathwayRowData, { pathwayId: string }>) => {
    const [value, setValue] = useState<string | undefined>(pathwayId ?? UNKNOWN);
    const dispatch = useThunkDispatch();
    const { isLoading, pathwayBlueprint } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, ASSIGN_USERS_ACTION),
        pathwayBlueprint: state.pathwayBlueprint,
    }));
    const pathways = useMemo(
        () => [{ id: UNKNOWN, name: UNKNOWN }, ...(pathwayBlueprint?.pathways ?? [])] as IPathway[],
        [pathwayBlueprint]
    );
    const onChange = async (newValue: IDropDownValue<IPathway>) => {
        if (newValue !== value) {
            const originalValue = value;
            setValue(undefined);
            await dispatch(
                assignUsers(
                    pathwayBlueprint.id,
                    newValue === UNKNOWN ? undefined : (newValue as string),
                    selectedFlatRows.map((x) => x.original.userId)
                )
            );
            setValue(originalValue);
        }
    };
    const selectedRows = selectedFlatRows?.length ?? 0;

    return (
        <DropDown
            dataCy="assign-header-dropdown"
            isDisabled={selectedRows === 0 || pathwayBlueprint?.isCalculating}
            isLoading={isLoading}
            items={pathways}
            onChange={onChange}
            optionTextKey="name"
            optionValueKey="id"
            text={`Assign (${selectedRows})`}
            value={value}
        />
    );
};

export const IndicatorsCell = ({
    row: {
        original: { isStale, lastCalculatedDate },
    },
}: Cell<IPathwayRowData, unknown>) => (
    <React.Fragment>
        <StaleIcon
            isStale={isStale}
            lastCalculatedDate={lastCalculatedDate}
            lastCalculatedFieldName="Last Calculation Date"
        />
    </React.Fragment>
);

export const NameCell = ({
    row: {
        original: {
            teamId,
            user: { displayName },
            userId,
        },
    },
}: Cell<{ teamId: string; user: IUser; userId: string }, unknown>) => {
    const { isTeamManagementPage } = useContext(TeamManagementContext);

    return (
        <Link
            data-cy="user-name"
            to={
                isTeamManagementPage
                    ? generatePath(TEAMS_PEOPLE_PROFILE_PATH, { teamId, userId })
                    : generatePath(PROFILE_PATH, { userId })
            }
        >
            {displayName}
        </Link>
    );
};

const UNEDITABLE_TOOLTIP =
    'This is a derived value and can only be changed by modifying the Needs Major Medical Coverage Fields on each of the members for the household.';
const GROUP_LEVEL_ITEMS = (enumToNameValueArray(GroupLevels) as unknown) as {
    name: string;
    value: number;
}[];
export const StateCell = ({
    row: {
        original: { user },
    },
}: Cell<IPathwayRowData, unknown>) => {
    const { userProfiles } = useSelector((state: AppStore) => ({
        userProfiles: state.userProfiles,
    }));
    const userProfile = userProfiles.find((up) => up.user?.userId === user?.userId);
    const stateName = userProfile?.address?.state;
    return <Box sx={{ mr: 1 }}>{stateName}</Box>;
};

export const TierCell = ({
    row: {
        original: {
            disabled,
            hasMemberVerifiedInfo,
            majorMedicalCoverageLevel,
            pathwayYear,
            userId,
        },
    },
}: Cell<IPathwayRowData, unknown>) => {
    const dispatch = useThunkDispatch();
    const { isCurrent } = useUserProps();
    const { canEditMajorMedicalCoverageLevel, pathwayBlueprintId } = useSelector(
        (state: AppStore) => ({
            canEditMajorMedicalCoverageLevel: hasSomePermissions(
                state,
                EditUserMajorMedicalCoverageLevel
            ),
            pathwayBlueprintId: state.pathwayBlueprint?.id,
        })
    );
    const [isLoading, setIsLoading] = useState(false);
    const saveYearlyUserInfo = useCallback(
        async (value: number) => {
            await dispatch(
                patchYearlyUserInfo(
                    userId,
                    pathwayYear,
                    { majorMedicalCoverageLevel: value },
                    isCurrent
                )
            );
        },
        [dispatch, userId, pathwayYear, isCurrent]
    );

    const persistChanges = useCallback(
        async (value) => {
            setIsLoading(true);
            await saveYearlyUserInfo(value);
            setIsLoading(false);
            await dispatch(getPathwayBlueprint(pathwayBlueprintId));
        },
        [dispatch, pathwayBlueprintId, saveYearlyUserInfo]
    );
    if (hasMemberVerifiedInfo) {
        const groupLevelName = GROUP_LEVEL_ITEMS.find((x) => x.value === majorMedicalCoverageLevel)
            ?.name;
        return (
            <React.Fragment>
                <Box sx={{ mr: 1 }}>{groupLevelName}</Box>
                <InformationIconTooltip title={UNEDITABLE_TOOLTIP} />
            </React.Fragment>
        );
    }

    return (
        <DropDown
            isDisabled={disabled || !canEditMajorMedicalCoverageLevel}
            isLoading={isLoading}
            items={GROUP_LEVEL_ITEMS}
            onChange={persistChanges}
            optionTextKey="name"
            optionValueKey="value"
            value={majorMedicalCoverageLevel}
        />
    );
};

const UnderlinedText = styled.span<{ isUnderlined: boolean }>`
    text-decoration: ${(props) => (props.isUnderlined ? 'underline' : 'none')};
`;

export const PlanCostCell = ({
    row: { original: pathwayUser },
    value: totalCost,
}: Cell<IPathwayRowData, number>) => {
    const { disabled, hasOtherCosts } = pathwayUser;

    const { pathwayType } = useSelector((state: AppStore) => ({
        pathwayType: state.pathwayBlueprint.pathways?.find((x) => x.id === pathwayUser.pathwayId)
            ?.pathwayTypeId,
    }));

    const hideOtherCostsButton = getIsPathwayOfType(
        pathwayType,
        PathwayTypes.Custom,
        PathwayTypes.Flat,
        PathwayTypes.IchraAgeBanded,
        PathwayTypes.IchraCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployerCost,
        PathwayTypes.IchraCustom,
        PathwayTypes.IchraFlat,
        PathwayTypes.IchraMedicare,
        PathwayTypes.Parent,
        PathwayTypes.Spouse,
        PathwayTypes.Waived
    );

    const hideOtherCostsAndShowPlanCost =
        hideOtherCostsButton && isIchraPathwayTypeWithPlanCost(pathwayType);
    const underlinedTextClassName = hasOtherCosts ? TEXT_PRIMARY : '';

    const { closeModal, isVisible, openModal } = useModalState();

    return (
        <React.Fragment>
            {isVisible && <OtherCostsModal onClose={closeModal} pathwayUser={pathwayUser} />}
            {!hideOtherCostsButton && (
                <Button
                    color={hasOtherCosts ? 'secondary' : 'primary'}
                    disabled={disabled}
                    onClick={openModal}
                >
                    <UnderlinedText isUnderlined={hasOtherCosts}>
                        {formatCurrencyOptionalDecimal(totalCost)}
                    </UnderlinedText>
                </Button>
            )}
            {hideOtherCostsAndShowPlanCost ? (
                <UnderlinedText className={underlinedTextClassName} isUnderlined={hasOtherCosts}>
                    {formatCurrencyOptionalDecimal(totalCost)}
                </UnderlinedText>
            ) : (
                ''
            )}
        </React.Fragment>
    );
};

const getCustomCell = (
    customValueKey: keyof IPathwayUser,
    customNoteKey: keyof IPathwayUser,
    openCustomModal: (
        pathwayUser: IPathwayRowData | IPathwayUser
    ) => (dispatch: AppStoreThunkDispatch) => void,
    preserveDecimal: boolean
    // eslint-disable-next-line react/display-name
) => ({ row: { original: pathwayUser }, value }: Cell<IPathwayRowData, number>) => {
    const dispatch = useThunkDispatch();
    const { canEditPathway } = useSelector((state: AppStore) => ({
        canEditPathway: hasSomePermissions(state, EditPathway),
    }));
    const customValue = pathwayUser[customValueKey] as number;
    const isCustom = hasValue(customValue);
    const allowsCustomReimbursements = isCustomReimbursementsAllowed(pathwayUser?.pathwayTypeId);
    const onClick = useCallback(() => dispatch(openCustomModal(pathwayUser)), [
        dispatch,
        pathwayUser,
    ]);
    const className = canEditPathway ? undefined : TEXT_PRIMARY;
    const textToShow = useMemo(
        () => (
            <React.Fragment>
                {isCustom ? (
                    <u className={className}>
                        {formatCurrency(customValue, {
                            preserveDecimal,
                            emptyIfNaN: true,
                        })}
                    </u>
                ) : (
                    formatCurrency(value, { preserveDecimal, emptyIfNaN: true })
                )}
            </React.Fragment>
        ),
        [className, isCustom, customValue, value]
    );
    let color: ButtonProps['color'] = 'primary';
    let colorClass = '';
    if (isCustom) {
        color = 'secondary';
        colorClass = TEXT_PRIMARY;
    }

    return (
        <React.Fragment>
            <ConditionalTooltip isDisabled={!isCustom} title={pathwayUser[customNoteKey] as string}>
                {canEditPathway && allowsCustomReimbursements ? (
                    <Button color={color} disabled={pathwayUser.disabled} onClick={onClick}>
                        {textToShow}
                    </Button>
                ) : (
                    <UnderlinedText className={colorClass} isUnderlined={isCustom}>
                        {textToShow}
                    </UnderlinedText>
                )}
            </ConditionalTooltip>
        </React.Fragment>
    );
};

export const ProjectedReimbursementCell = getCustomCell(
    'customProjectedReimbursement',
    'customProjectedReimbursementNote',
    openCustomReimbursementModal,
    true
);

export const CustomPlanCostCell = getCustomCell(
    'customPlanCost',
    'customPlanCostNote',
    openCustomTotalCostModal,
    true
);
export const ProjectedWageUpCell = getCustomCell(
    'customProjectedWageUp',
    'customProjectedWageUpNote',
    openCustomWageUpModal,
    false
);

export const PathwayWageUpActionButtons = ({
    column: { isCostComparison },
    row: {
        original: {
            activeWageUp,
            calculationSummary,
            customProjectedReimbursement,
            customProjectedWageUp,
            disabled,
            fullYearCalculationSummary,
            id,
            pathwayBlueprintId,
            pathwayTypeId,
            projectedReimbursement,
            projectedWageUp,
            user,
        },
    },
}: CustomColumnCell<IPathwayRowData, unknown, { isCostComparison?: boolean }>) => {
    const dispatch = useThunkDispatch();
    const {
        canRecalculate,
        isLoadingRecalculate,
        isLoadingSetActiveContribution,
        isPathwayActive,
    } = useSelector((state: AppStore) => ({
        canRecalculate: hasSomePermissions(state, CalculatePathwayBlueprint),
        isLoadingRecalculate: hasApiActivityWithParams(state, CALCULATE_PATHWAY_USER_ACTION, {
            pathwayUserId: id,
        }),
        isLoadingSetActiveContribution: hasApiActivityWithParams(
            state,
            APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT,
            {
                pathwayUserId: id,
            }
        ),
        isPathwayActive: state.pathwayBlueprint.isActive,
    }));

    const { teamStateId } = useTeamProps();

    const { isVisible, openModal, closeModal } = useModalState();

    const isIchra = isIchraPathwayType(pathwayTypeId);

    const projectedCalculationSummary = isCostComparison
        ? fullYearCalculationSummary
        : calculationSummary;

    const hasProjectedContribution =
        hasValue(projectedWageUp) ||
        hasValue(customProjectedWageUp) ||
        hasValue(projectedReimbursement) ||
        hasValue(customProjectedReimbursement);
    const hasCalculationSummary =
        !!projectedCalculationSummary || !!activeWageUp?.calculationSummary;

    const showSetActiveContribution =
        showActiveContributionByTeamState(teamStateId) &&
        hasProjectedContribution &&
        !isCostComparison &&
        isPathwayActive;

    const recalculateOnClick = useCallback(async () => {
        await dispatch(calculatePathwayUser(id));
        await dispatch(getPathwayBlueprint(pathwayBlueprintId));
    }, [dispatch, id, pathwayBlueprintId]);
    const setActiveContribution = useCallback(async () => {
        await dispatch(applyCalculationForPathwayBlueprint(pathwayBlueprintId, [id]));
        await dispatch(getPathwayUser(id));
    }, [dispatch, id, pathwayBlueprintId]);

    const items = useMemo(
        () =>
            [
                {
                    dataCy: 're-calculate-pathway-user',
                    isConfirm: true,
                    isLoading: isLoadingRecalculate,
                    isVisible: canRecalculate,
                    onClick: recalculateOnClick,
                    text: 'Recalculate',
                },
                {
                    dataCy: 'set-active-contribution',
                    isConfirm: true,
                    isLoading: isLoadingSetActiveContribution,
                    isVisible: showSetActiveContribution,
                    onClick: setActiveContribution,
                    text: `Set As Active ${isIchra ? 'Reimbursement' : 'Wage+'}`,
                },
                {
                    dataCy: 'view-calculation-summary',
                    isVisible: hasCalculationSummary,
                    onClick: openModal,
                    text: 'View Calculation Summary',
                },
            ] as IActionButtonItems,
        [
            canRecalculate,
            hasCalculationSummary,
            isIchra,
            isLoadingRecalculate,
            isLoadingSetActiveContribution,
            openModal,
            recalculateOnClick,
            setActiveContribution,
            showSetActiveContribution,
        ]
    );

    return (
        <React.Fragment>
            {isVisible && (
                <CalculationSummaryModal
                    active={activeWageUp?.calculationSummary}
                    isIchra={isIchra}
                    onClose={closeModal}
                    projected={projectedCalculationSummary}
                    user={user}
                />
            )}
            <ActionButtons
                ButtonProps={{ xsClassName: 'mb-2' }}
                dataCy="pathway-wage-up-action-button"
                disabled={disabled}
                items={items}
            />
        </React.Fragment>
    );
};

export const AssignCell = ({
    row: {
        original: { disabled, pathwayId, userId },
    },
}: Cell<IPathwayRowData, unknown>) => {
    const [value, setValue] = useState<string | undefined>(pathwayId ?? UNKNOWN);
    const dispatch = useThunkDispatch();
    const { isLoading, pathwayBlueprint } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, ASSIGN_USERS_ACTION),
        pathwayBlueprint: state.pathwayBlueprint,
    }));
    const pathways = useMemo(
        () => [{ id: UNKNOWN, name: UNKNOWN }, ...(pathwayBlueprint?.pathways ?? [])] as IPathway[],
        [pathwayBlueprint]
    );
    const onChange = async (newValue: IDropDownValue<IPathway>) => {
        if (newValue !== value) {
            const originalValue = value;
            setValue(undefined);
            await dispatch(
                assignUsers(
                    pathwayBlueprint.id,
                    newValue === UNKNOWN ? undefined : (newValue as string),
                    [userId]
                )
            );
            setValue(originalValue);
        }
    };
    return (
        <DropDown
            isDisabled={disabled}
            isLoading={isLoading}
            items={pathways}
            onChange={onChange}
            optionTextKey="name"
            optionValueKey="id"
            text="Assign"
            value={value}
        />
    );
};

export const EmployeeCostCell = ({
    row: {
        original: { pathwayTypeId },
    },
    value,
}: Cell<IPathwayRowData, number>) => {
    const isIchra = isIchraPathwayType(pathwayTypeId);
    return <span>{formatCurrency(value, { emptyIfNaN: true, preserveDecimal: isIchra })}</span>;
};

const ContentContainer = ({
    children,
    canViewCustom,
    disabled,
    onClick,
}: {
    canViewCustom: boolean;
    children: ReactNode;
    disabled: boolean | undefined;
    onClick: React.MouseEventHandler | undefined;
}) => {
    if (canViewCustom) {
        return disabled ? (
            <u className={TEXT_PRIMARY}>{children}</u>
        ) : (
            <Button color="secondary" disabled={disabled} onClick={onClick}>
                {children}
            </Button>
        );
    }
    return <span>{children}</span>;
};

type IActiveContributionCellProps = {
    activeContribution?: number;
    contributionNote: string | undefined;
    contributionType: string;
    customContribution?: number;
    disabled: boolean | undefined;
    hasError: boolean;
    hasMismatchedContributions: boolean;
    isActiveCustom?: boolean;
    onClick?: () => void;
    preserveDecimal?: boolean;
    projectedContribution?: number;
};

const ActiveContributionCell = ({
    activeContribution,
    contributionNote,
    contributionType,
    customContribution,
    disabled,
    hasError,
    hasMismatchedContributions,
    isActiveCustom = false,
    onClick,
    preserveDecimal = false,
    projectedContribution,
}: IActiveContributionCellProps) => {
    const { canEditPathway, isPathwayActive } = useSelector((state: AppStore) => ({
        canEditPathway: hasSomePermissions(state, EditPathway),
        isPathwayActive: state.pathwayBlueprint.isActive,
    }));

    const areProjectedAndActiveDifferent =
        (hasValue(customContribution) && customContribution !== activeContribution) ||
        (!hasValue(customContribution) && projectedContribution !== activeContribution);
    const canViewCustom = isActiveCustom && canEditPathway;
    const className = canViewCustom ? undefined : TEXT_PRIMARY;
    const TextContainer = canViewCustom
        ? ({ children }: { children: ReactNode }) => <u className={className}>{children}</u>
        : 'span';

    const showDoubleBang =
        canEditPathway &&
        isPathwayActive &&
        (areProjectedAndActiveDifferent || hasMismatchedContributions) &&
        !hasError;

    return (
        <ContentContainer canViewCustom={canViewCustom} disabled={disabled} onClick={onClick}>
            <Row className="flex-nowrap" noGutters>
                {showDoubleBang && (
                    <Tooltip title={getActiveAndProjectedDifferentMessage(contributionType)}>
                        <DoubleBangIcon className={!hasValue(activeContribution) ? '' : 'mr-1'} />
                    </Tooltip>
                )}
                <ConditionalTooltip isDisabled={!canViewCustom} title={contributionNote as string}>
                    <TextContainer>
                        {formatCurrency(activeContribution, {
                            preserveDecimal,
                            emptyIfNaN: true,
                        })}
                    </TextContainer>
                </ConditionalTooltip>
            </Row>
        </ContentContainer>
    );
};

export const ActiveWageUpCell = ({
    row: {
        original: {
            activeWageUp,
            customProjectedWageUp,
            hasError,
            hasMismatchedContributions,
            projectedWageUp,
            disabled,
        },
        original,
    },
}: Cell<IPathwayRowData, unknown>) => {
    const dispatch = useThunkDispatch();
    const onClick = useCallback(() => dispatch(openCustomActiveWageUpModal(original)), [
        dispatch,
        original,
    ]);

    return (
        <ActiveContributionCell
            activeContribution={activeWageUp?.wageUp}
            contributionNote={activeWageUp?.note}
            contributionType="Wage+"
            customContribution={customProjectedWageUp}
            disabled={disabled}
            hasError={hasError}
            hasMismatchedContributions={hasMismatchedContributions}
            isActiveCustom={activeWageUp?.isCustom}
            onClick={onClick}
            projectedContribution={projectedWageUp}
        />
    );
};

export const ActiveReimbursementCell = ({
    row: {
        original: {
            activeWageUp,
            customProjectedReimbursement,
            hasError,
            hasMismatchedContributions,
            projectedReimbursement,
        },
    },
}: Cell<IPathwayRowData, unknown>) => (
    <ActiveContributionCell
        activeContribution={activeWageUp?.reimbursement}
        contributionNote={activeWageUp?.reimbursementNote}
        contributionType="Reimbursement"
        customContribution={customProjectedReimbursement}
        disabled
        hasError={hasError}
        hasMismatchedContributions={hasMismatchedContributions}
        isActiveCustom={activeWageUp?.isReimbursementCustom}
        preserveDecimal
        projectedContribution={projectedReimbursement}
    />
);

export const PlanCostHeader = ({
    column: { isIchra },
}: CustomColumnHeader<IPathwayRowData, { isIchra: boolean }>) => (
    <UnderlinedContentWithTooltip
        title={
            isIchra
                ? 'The total plan costs, NOT grossed up for taxes.'
                : 'The taxed up total plan costs'
        }
    >
        Total Plan Cost
    </UnderlinedContentWithTooltip>
);

export const PathwayTableInfoCell = ({
    row: { original },
}: Cell<IPathwayRowData, UserInfoStatus>) => (
    <UserInfoTableCell
        includeBasicInfoToolTipText={
            original.user?.userInfoStatus !== UserInfoStatus.Basic ||
            ![PathwayTypes.Waived, PathwayTypes.Custom, PathwayTypes.IchraCustom].includes(
                original.pathwayTypeId
            )
        }
        user={original.user}
    />
);
