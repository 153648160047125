import { Card, CardContent } from '@mui/material';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { saveCompletedTeamTask } from 'actions/task/saveCompletedTeamTask';
import { saveCompletedUserTask } from 'actions/task/saveCompletedUserTask';
import { TaskItems } from 'api/generated/enums';
import { taskListTypes } from 'constants/tasks';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { safeGetArray } from 'utilities';

type ITaskListContainerProps = DispatchProps & {
    children: JSX.Element | JSX.Element[];
    completeMessage: JSX.Element | string;
    description: string;
    isCompletedOverride?: boolean;
    taskListType: string;
    teamId: string;
    title: string;
};

type IChildrenProps = {
    complete: boolean;
    markComplete: (taskId: TaskItems, taskType: string) => void;
};

class TaskListContainer extends React.PureComponent<ITaskListContainerProps> {
    markComplete = (taskId: TaskItems, taskType: string) => {
        if (taskType === undefined || taskType === null) {
            taskType = this.props.taskListType;
        }

        if (taskType === taskListTypes.Team) {
            this.props.completeTeamTask(taskId, this.props.teamId);
        } else if (taskType === taskListTypes.User) {
            this.props.completeUserTask(taskId);
        }
    };

    override render() {
        // If an element has just one child, then children is just an object,
        // otherwise it's an array, this converts the single object to an array
        const children: React.FunctionComponentElement<IChildrenProps>[] = safeGetArray(
            this.props.children
        )?.reduce((elements, item) => {
            if (item) {
                const element = React.cloneElement(item, {
                    markComplete: this.markComplete,
                } as IChildrenProps);
                elements.push(element);
            }
            return elements;
        }, []);

        return (
            <Card sx={{paddingBottom: 0}}>
                <CardContent>
                    {(this.props.isCompletedOverride || children.every((x) => x.props.complete)) &&
                        this.props.completeMessage && (
                            <div
                                className="alert alert-success mb-3"
                                data-cy="task-list-complete-message"
                            >
                                {this.props.completeMessage}
                            </div>
                        )}
                    <DashboardCardHeader
                        description={this.props.description}
                        header={this.props.title}
                    />

                    {children}
                </CardContent>
            </Card>
        );
    }
}

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    completeTeamTask: async (taskId: TaskItems, teamId: string) =>
        dispatch(saveCompletedTeamTask(taskId, teamId)),
    completeUserTask: async (taskId: TaskItems) => dispatch(saveCompletedUserTask(taskId)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(connect(null, mapDispatchToProps)(TaskListContainer));
