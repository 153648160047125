import { boolean, object, string } from 'yup';

const ONE = 1;
const ONE_HUNDRED = 100;

//schema for personal info form
export const personalInfoSchema = object({
    birthdate: string()
        .required()
        .isValidDate()
        .label('Date of Birth'),
    firstName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('First Name'),
    gender: string()
        .required()
        .max(ONE)
        .min(ONE)
        .label('Gender'),
    lastName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('Last Name'),
    phone: string()
        .required()
        .isValidPhoneNumber()
        .label('Phone Number'),
    preferredName: string()
        .nullable()
        .trim()
        .max(ONE_HUNDRED)
        .label('Preferred Name'),
    usesTobacco: boolean()
        .typeError('Tobacco Use is a required field')
        .required()
        .label('Tobacco Use'),
});

// schema for household member form
export const householdMembersSchema = object({
    birthdate: string()
        .required()
        .isValidDate()
        .label('Date of Birth'),
    firstName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('First Name'),
    gender: string()
        .required()
        .max(ONE)
        .min(ONE)
        .label('Gender'),
    lastName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('Last Name'),
    preferredName: string()
        .nullable()
        .trim()
        .max(ONE_HUNDRED)
        .label('Preferred Name'),
    usesTobacco: boolean()
        .typeError('Tobacco Use is a required field')
        .required()
        .label('Tobacco Use'),
});
