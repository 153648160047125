import DiagonalStrikethroughLine from 'components/DiagonalStrikethroughLine';
import React, { ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export type IColumnRowNameValue = {
    'data-cy'?: string;
    footer?: ReactNode;
    name: string;
    strikeThrough?: boolean;
    value: number | string | undefined;
    valueClassName?: string;
};

export const RowNameValue = ({
    data,
    noMargin = false,
    rowClassName = '',
    valueClassName = '',
}: {
    data: IColumnRowNameValue;
    noMargin?: boolean;
    rowClassName?: string;
    valueClassName?: string;
}) => (
    <Row className={rowClassName}>
        <Col className="text-right text-muted align-self-center" xs="6">
            <p className={noMargin ? 'm-0' : ''}>{data.name}</p>
        </Col>
        <Col className="text-left align-self-center" data-cy={data['data-cy']} xs="6">
            <p className={(noMargin ? 'm-0 ' : '') + valueClassName}>
                <DiagonalStrikethroughLine isEnabled={data.strikeThrough}>
                    {data.value}
                </DiagonalStrikethroughLine>
            </p>
        </Col>
        {!!data.footer && <Col xs={{ offset: 6, span: 6 }}>{data.footer}</Col>}
    </Row>
);

export const ColumnRowNameValue = ({ data }: { data: IColumnRowNameValue[][] }) => (
    <Row>
        {data.map((column, columnIndex) => (
            <Col key={columnIndex} lg={12 / data.length} xs="12">
                {column.map((columnData) => (
                    <RowNameValue
                        data={columnData}
                        key={columnData.name}
                        valueClassName={columnData.valueClassName}
                    />
                ))}
            </Col>
        ))}
    </Row>
);
