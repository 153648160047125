import SelectionCard from 'pages/selections/SelectionCard';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const SelectionCards = () => {
    const { selectedPlansForShopping } = useSelector((state: AppStore) => ({
        selectedPlansForShopping: state.selectedPlansForReview.selectedPlans,
    }));
    return (
        <React.Fragment>
            {selectedPlansForShopping?.map((plan) => (
                <SelectionCard key={plan.selectedPlanId} selectedPlan={plan} />
            ))}
        </React.Fragment>
    );
};

export default hot(module)(SelectionCards);
