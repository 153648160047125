import { EDIT_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/editSubmittedExpense';
import { GET_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/getSubmittedExpense';
import { ISubmittedExpenseDto } from 'api/generated/models';
import { EditLockedSubmittedExpense, EditSubmittedExpense } from 'api/generated/permissions';
import Select from 'components/Select';
import Typography from 'components/Typography';
import { SubmittedExpenseLabelAndValue } from 'pages/reimbursementExpenseManagement/SubmittedExpenseModal';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';

type IReimburseToHsa = {
    handleReimburseToHsaChange: ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => void;
    reimburseToHsa: boolean | undefined;
    reimburseToHsaError: string[] | undefined;
    submittedExpense: ISubmittedExpenseDto | undefined;
};

const ReimburseToHsa = ({
    handleReimburseToHsaChange,
    reimburseToHsa,
    reimburseToHsaError,
    submittedExpense,
}: IReimburseToHsa) => {
    const { canEditLockedSubmittedExpense, canEditSubmittedExpense, isLoading } = useSelector(
        (state: AppStore) => ({
            canEditLockedSubmittedExpense: hasSomePermissions(state, EditLockedSubmittedExpense),
            canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
            isLoading: hasApiActivity(
                state,
                GET_SUBMITTED_EXPENSE_ACTION,
                EDIT_SUBMITTED_EXPENSE_ACTION
            ),
        })
    );

    return (
        <React.Fragment>
            {canEditSubmittedExpense ? (
                <React.Fragment>
                    <Typography alignSelf="center" fontWeight="bold">
                        Reimburse to HSA
                    </Typography>
                    <Select
                        disabled={submittedExpense?.isLocked && !canEditLockedSubmittedExpense}
                        errors={reimburseToHsaError}
                        items={yesOrNo}
                        onChange={handleReimburseToHsaChange}
                        optionText="text"
                        optionValue="value"
                        required
                        value={reimburseToHsa}
                    />
                </React.Fragment>
            ) : (
                <SubmittedExpenseLabelAndValue
                    isLoading={isLoading}
                    label="Reimburse to HSA"
                    value={submittedExpense?.reimburseToHsa ? 'Yes' : 'No'}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(ReimburseToHsa);
