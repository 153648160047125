import useUserProps from 'hooks/useUserProps';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { getShouldShowMayIncludeAdditionalIncomeMessage } from 'selectors/memberVerifiedInfo';

const MayIncludeAdditionalIncomeMessage = () => {
    const userProps = useUserProps();
    const { shouldShowMayIncludeAdditionalIncomeMessage } = useSelector((state: AppStore) => ({
        shouldShowMayIncludeAdditionalIncomeMessage: getShouldShowMayIncludeAdditionalIncomeMessage(
            state,
            userProps
        ),
    }));
    return shouldShowMayIncludeAdditionalIncomeMessage ? (
        <span className="text-danger">This may include additional income beyond the salary</span>
    ) : (
        <React.Fragment />
    );
};
export default hot(module)(withRouter(MayIncludeAdditionalIncomeMessage));
