import Typography from '@mui/material/Typography';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import Skeleton from 'components/Skeleton';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';

const IchraFlowHouseholdMemberSummary = () => {
    const { householdFromState, isLoadingHousehold, userProfile } = useSelector(
        (state: AppStore) => ({
            hasLoadedUser: hasCompletedRequest(state, GET_USER_PROFILE_ACTION),
            householdFromState: state.householdMembers,
            isLoadingHousehold: hasApiActivity(state, GET_HOUSEHOLD_ACTION),
            userProfile: state.current.userProfile,
        })
    );
    const household = householdFromState.filter((hhm) => hhm.needsCoverage);
    const hhmLength = household?.length ?? 0;

    return (
        <Skeleton isEnabled={isLoadingHousehold} sx={{ marginTop: 0, marginX: 'auto' }} width="60%">
            <Typography fontStyle="italic" marginX="auto" variant="body2">
                {hhmLength > 0 ? (
                    <React.Fragment>
                        {userProfile.user?.firstName}
                        {hhmLength === 1 ? ' and ' : ', '}
                        {household?.map((member) => member.firstName).joinSerialComma()}
                    </React.Fragment>
                ) : (
                    <React.Fragment>{userProfile.user?.firstName} only</React.Fragment>
                )}
            </Typography>
        </Skeleton>
    );
};

export default hot(module)(IchraFlowHouseholdMemberSummary);
