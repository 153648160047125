import { getActiveQuestions } from 'actions/customQuestions/getActiveQuestions';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import CustomQuestionForm from 'pages/survey/qualitativeSteps/CustomQuestionForm';
import FeedbackForm from 'pages/survey/qualitativeSteps/FeedbackForm';
import OtherBenefitsForm from 'pages/survey/qualitativeSteps/OtherBenefitsForm';
import QualitativeIntro from 'pages/survey/qualitativeSteps/QualitativeIntro';
import RankCoverageForm from 'pages/survey/qualitativeSteps/RankCoverageForm';
import StepWrapper, { IStepProps } from 'pages/survey/StepWrapper';
import { getShowQualitativeOpenEndedQuestions } from 'pages/survey/surveySelectors';
import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const stepsToRender = [RankCoverageForm, OtherBenefitsForm];
const QualitativeWrapper = () => {
    const dispatch = useThunkDispatch();
    const teamProps = useTeamProps();
    const showQualitativeOpenEndedQuestions = getShowQualitativeOpenEndedQuestions(teamProps);
    const { user } = useUserProps();
    const { customQuestions } = useSelector((state: AppStore) => ({
        customQuestions: state.customQuestions,
    }));
    useEffect(() => {
        dispatch(getActiveQuestions(user?.teamId));
    }, [dispatch, user?.teamId]);

    const steps = useMemo(() => {
        const stepsToAdd = [];
        if (showQualitativeOpenEndedQuestions) {
            stepsToAdd.push(FeedbackForm);
            customQuestions.forEach((q) =>
                stepsToAdd.push((stepProps: IStepProps) => (
                    <CustomQuestionForm customQuestion={q} key={q.id} {...stepProps} />
                ))
            );
        }
        return stepsToRender.concat(stepsToAdd);
    }, [customQuestions, showQualitativeOpenEndedQuestions]);

    return <StepWrapper Intro={QualitativeIntro} stepsToRender={steps} />;
};

export default hot(module)(QualitativeWrapper);
