import Grid from '@mui/material/Grid';
import PageHeader from 'components/PageHeader';
import React from 'react';
import { hot } from 'react-hot-loader';

type IPaymentsHeaderProps = {
    button: React.ReactElement;
};

const PaymentsHeader = ({ button }: IPaymentsHeaderProps) => (
    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
        <PageHeader variant="h4">Payments Info</PageHeader>
        {button}
    </Grid>
);

export default hot(module)(PaymentsHeader);
