import { Theme } from '@mui/material';
import Alert from '@mui/material/Alert';
import { SxProps } from '@mui/system';
import React from 'react';
import { hot } from 'react-hot-loader';

const PrepopulatedAlert = ({ sx }: { sx?: SxProps<Theme> }) => (
    <Alert data-cy="prepopulated-alert" severity="warning" sx={sx}>
        We&apos;ve pre-populated this section with information you&apos;ve given us in the past.
        Please check this data for accuracy and change it if needed.
    </Alert>
);

export default hot(module)(PrepopulatedAlert);
