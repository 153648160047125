import kebabCase from 'lodash/kebabCase';
import MultiSelectButton from 'pages/survey/multiSelectButtons/MultiSelectButton';
import { IMultiSelectButtonsProps, Item } from 'pages/survey/multiSelectButtons/MultiSelectButtons';
import React from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

type IDragItem = Item & { text?: string };

type PropsToExclude =
    | 'hideCheckmark'
    | 'isInRow'
    | 'onSelection'
    | 'optionalDisplayProp'
    | 'preferredName';

type IDraggableMultiSelectButtonsProps<T extends IDragItem> = Omit<
    IMultiSelectButtonsProps<T>,
    PropsToExclude
> & {
    onDragEnd: OnDragEndResponder;
};
const DraggableMultiSelectButtons = <T extends IDragItem>({
    options: collection,
    displayProp = 'text',
    isSelectedProp = 'isSelected',
    onDragEnd,
}: IDraggableMultiSelectButtonsProps<T>) => (
    <Row className="justify-content-center">
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="mutli-select-button">
                {(provided) => (
                    <Col
                        className="row"
                        md="7"
                        {...provided.droppableProps}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ref={provided.innerRef as any}
                    >
                        {collection.map((x, index) => (
                            <Draggable
                                draggableId={kebabCase(`${x[displayProp]}`)}
                                index={index}
                                key={x.text}
                            >
                                {(draggableProvided) => (
                                    <MultiSelectButton
                                        ColProps={{
                                            ref: draggableProvided.innerRef,
                                            ...draggableProvided.draggableProps,
                                            ...draggableProvided.dragHandleProps,
                                        }}
                                        enableDragAndDrop
                                        index={index}
                                        isSelectedProp={isSelectedProp}
                                        key={x.text}
                                        option={x}
                                    >
                                        <Row className="align-items-center" noGutters>
                                            <Col>
                                                {x[displayProp]}
                                                {x.isRequired && ' - primary member required.'}
                                            </Col>
                                        </Row>
                                    </MultiSelectButton>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Col>
                )}
            </Droppable>
        </DragDropContext>
    </Row>
);

export default hot(module)(DraggableMultiSelectButtons);
