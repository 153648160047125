import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import {
    generatePayrollReport,
    GENERATE_PAYROLL_REPORT_ACTION,
} from 'actions/payroll/generatePayrollReport';
import Select from 'components/Select';
import { MONTHS } from 'constants/date';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { getYears } from 'utilities/year';

const years = getYears(() => 1).map((x) => ({ name: `${x}` }));

type IGeneratePayrollReportModal = {
    close: (year?: string, month?: number) => void;
    initialMonth: number;
    initialYear: string;
};

const GeneratePayrollReportModal = ({
    close,
    initialMonth,
    initialYear,
}: IGeneratePayrollReportModal) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { isGeneratingReport } = useSelector((state: AppStore) => ({
        isGeneratingReport: hasApiActivity(state, GENERATE_PAYROLL_REPORT_ACTION),
    }));

    const [year, setYear] = useState(initialYear);
    const [month, setMonth] = useState(initialMonth);

    useEffect(() => {
        setYear(initialYear);
        setMonth(initialMonth);
    }, [initialYear, initialMonth]);

    const generateReport = async () => {
        await dispatch(generatePayrollReport(teamId, +year, month));
        close(year, +month);
    };

    const cancel = () => close();

    return (
        <Dialog fullWidth maxWidth="xs" onClose={cancel} open>
            <DialogTitle>Generate Report</DialogTitle>
            <DialogContent dividers>
                <Stack gap={2} pt={1}>
                    <Select
                        data-cy="year"
                        items={years}
                        label="Year"
                        name="selectedYear"
                        onChange={onChange(setYear)}
                        optionText="name"
                        optionValue="name"
                        value={year}
                    />
                    <Select
                        data-cy="month"
                        items={MONTHS}
                        label="Month"
                        name="month"
                        onChange={onChange(setMonth)}
                        optionText="name"
                        optionValue="value"
                        value={month}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} variant="text">
                    Close
                </Button>
                <LoadingButton
                    data-cy="generate-button"
                    loading={isGeneratingReport}
                    onClick={generateReport}
                    variant="text"
                >
                    Generate
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(GeneratePayrollReportModal);
