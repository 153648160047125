import { GET_HOUSEHOLD_ELIGIBILITY_ACTION } from 'actions/user/getHouseholdEligibility';
import { HouseholdEligibilityStatus, OtherCoverageEligibilities } from 'api/generated/enums';
import { IUser } from 'api/generated/models';
import ActivityIndicator from 'components/ActivityIndicator';
import useUserProps from 'hooks/useUserProps';
import isUndefined from 'lodash/isUndefined';
import React, { useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IHouseholdMemberWithAge } from 'reducers/householdMembers';
import { hasCompletedRequest } from 'selectors/activity';
import { getDisplayFirstName } from 'utilities/household';
import { getEligibilityData } from 'utilities/householdEligibility';

const ALERT_COLORS: { [status in HouseholdEligibilityStatus]?: React.CSSProperties } = {
    [HouseholdEligibilityStatus.Medicare]: { backgroundColor: '#F0F6FF', borderColor: '#297EFF' },
    [HouseholdEligibilityStatus.Medicaid]: { backgroundColor: '#F3FBFB', borderColor: '#009688' },
    [HouseholdEligibilityStatus.CHIP]: { backgroundColor: '#F9F5FD', borderColor: '#B56CFF' },
    [HouseholdEligibilityStatus.Other]: { backgroundColor: '#f1f3fa', borderColor: '#98a6ad' },
    [HouseholdEligibilityStatus.UnsupportedStateBasedExchangeIneligible]: {
        backgroundColor: '#f1f3fa',
        borderColor: '#98a6ad',
    },
};

type IEligibilityData = {
    name?: string;
    status?: HouseholdEligibilityStatus;
};

const EligibilityAlert = ({
    eligibility,
    firstNames,
    descriptiveText,
}: {
    descriptiveText: string;
    eligibility: IEligibilityData;
    firstNames: string;
}) => {
    const style: React.CSSProperties = {
        border: '1px solid',
        ...ALERT_COLORS[eligibility.status as HouseholdEligibilityStatus],
    };
    return (
        <Alert className="text-secondary" style={style}>
            <i className="dripicons-flag text-default mr-2" />
            {firstNames}
            {descriptiveText}
        </Alert>
    );
};

const HouseholdEligibility = () => {
    const { memberVerifiedInfo, user, yearlyUserInfo } = useUserProps();
    const { isLoaded, householdEligibility, householdMembers } = useSelector((state: AppStore) => ({
        householdEligibility: state.householdEligibility,
        householdMembers: state.householdMembers,
        isLoaded: hasCompletedRequest(state, GET_HOUSEHOLD_ELIGIBILITY_ACTION),
    }));
    const currentContribution = user?.activeWageUps?.find(
        (x) => x.year === memberVerifiedInfo?.year
    );
    const isInIchraPathway = currentContribution?.wasSetFromIchraPathway ?? false;
    const getFirstNameOfMember = useCallback(
        (memberId) => {
            let member: IHouseholdMemberWithAge | IUser | undefined = householdMembers.find(
                (x) => x.householdMemberId === memberId
            );
            if (!member) {
                member = user;
            }
            return member ? member.firstName : '';
        },
        [householdMembers, user]
    );
    const getFirstNames = useCallback(
        <T,>(members: T[], idKey: keyof T) =>
            members.map((memberData) => getFirstNameOfMember(memberData[idKey])),

        [getFirstNameOfMember]
    );

    if (isLoaded) {
        const combinedStatus = householdEligibility.reduce(
            (previous, current) => previous | current.eligibilityStatus,
            HouseholdEligibilityStatus.None
        );
        const eligibilityData = getEligibilityData(combinedStatus) as IEligibilityData[];
        const eligibilityAlerts = eligibilityData
            .filter(
                (hhe) =>
                    hhe.status !==
                    HouseholdEligibilityStatus.UnsupportedStateBasedExchangeIneligible
            )
            .map((eligibility) => {
                const memberEligibilitiesInStatus = householdEligibility.filter(
                    (memberEligibility) =>
                        memberEligibility.eligibilityStatus &
                        (eligibility.status as HouseholdEligibilityStatus)
                );
                const firstNames = getFirstNames(
                    memberEligibilitiesInStatus,
                    'memberId'
                ).joinSerialComma();
                const areIs = memberEligibilitiesInStatus.length > 1 ? 'are' : 'is';
                const hasSpouseOtherCoverage = !!(
                    (memberVerifiedInfo?.otherCoverageEligibilities as OtherCoverageEligibilities) &
                    OtherCoverageEligibilities.SpouseEmployer
                );
                if (
                    !isInIchraPathway &&
                    eligibility.status === HouseholdEligibilityStatus.Other &&
                    !hasSpouseOtherCoverage
                ) {
                    return (
                        <EligibilityAlert
                            descriptiveText={` ${areIs} marked as eligible for other coverage`}
                            eligibility={eligibility}
                            firstNames={firstNames}
                            key={eligibility.status}
                        />
                    );
                } else if (
                    (eligibility.status === HouseholdEligibilityStatus.Other && isInIchraPathway) ||
                    (eligibility.status === HouseholdEligibilityStatus.Other &&
                        hasSpouseOtherCoverage)
                ) {
                    return <React.Fragment key={eligibility.status}></React.Fragment>;
                }

                return (
                    <EligibilityAlert
                        descriptiveText={` may be eligible for ${eligibility.name}`}
                        eligibility={eligibility}
                        firstNames={firstNames}
                        key={eligibility.status}
                    />
                );
            });
        const membersNotNeedCoverage: IHouseholdMemberWithAge[] = [];
        householdMembers.forEach((member) => {
            if (!isUndefined(member.needsCoverage) && !member.needsCoverage) {
                membersNotNeedCoverage.push(member);
            }
        });
        const primaryDoesNotNeedCoverage = !yearlyUserInfo?.needsMajorMedicalCoverage;
        if (membersNotNeedCoverage.length || primaryDoesNotNeedCoverage) {
            const firstNames = getFirstNames(membersNotNeedCoverage, 'householdMemberId');
            if (primaryDoesNotNeedCoverage) {
                firstNames.unshift(getDisplayFirstName(user));
            }
            const areMoreThanOne =
                (primaryDoesNotNeedCoverage && membersNotNeedCoverage.length >= 1) ||
                membersNotNeedCoverage.length > 1;
            const areIs = areMoreThanOne ? 'are' : 'is';
            eligibilityAlerts.push(
                <EligibilityAlert
                    descriptiveText={` ${areIs} marked as not needing major medical coverage`}
                    eligibility={{}}
                    firstNames={firstNames.joinSerialComma()}
                    key="no-need"
                />
            );
        }
        if (eligibilityAlerts.length) {
            return (
                <div>
                    {eligibilityAlerts}
                    <hr className="my-3" />
                </div>
            );
        }
        return <React.Fragment />;
    }
    return <ActivityIndicator />;
};

export default hot(module)(HouseholdEligibility);
