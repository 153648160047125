import { getHouseholdMembers } from 'actions/householdMember/getHouseholdMembers';
import { TaskItems } from 'api/generated/enums';
import { ITaskItem } from 'api/generated/models';
import { taskListTypes } from 'constants/tasks';
import useThunkDispatch from 'hooks/useThunkDispatch';
import AcceptServicesTask from 'pages/dashboard/taskLists/member/tasks/AcceptServicesTask';
import EnterSocialSecurityTask from 'pages/dashboard/taskLists/member/tasks/EnterSocialSecurityTask';
import ReviewMemberInfoTask from 'pages/dashboard/taskLists/member/tasks/ReviewMemberInfoTask';
import TaskListContainer from 'pages/dashboard/taskLists/TaskListContainer';
import React, { useCallback, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

type IMemberMemberLaunchTaskListProps = {
    completedTasks: ITaskItem[];
    teamId: string;
};

const MemberMemberLaunchTaskList = ({
    completedTasks,
    teamId,
}: IMemberMemberLaunchTaskListProps) => {
    const dispatch = useThunkDispatch();
    const { userId } = useSelector((state: AppStore) => ({
        userId: state.current.userProfile?.user?.userId,
    }));

    useEffect(() => {
        if (userId) {
            dispatch(getHouseholdMembers(userId));
        }
    }, [dispatch, userId]);

    const isTaskComplete = useCallback(
        (taskId) => completedTasks.some((x) => x.taskItemId === taskId),
        [completedTasks]
    );
    const acceptRHServicesComplete = useMemo(() => isTaskComplete(TaskItems.AcceptRHServices), [
        isTaskComplete,
    ]);
    const reviewMemberInfoComplete = useMemo(() => isTaskComplete(TaskItems.ReviewMemberInfo), [
        isTaskComplete,
    ]);
    const enterSsnComplete = useMemo(() => isTaskComplete(TaskItems.EnterSocialSecurityNumbers), [
        isTaskComplete,
    ]);
    return (
        <TaskListContainer
            completeMessage="You've completed your setup tasks and are ready for your benefits options."
            description="Let's get started..."
            taskListType={taskListTypes.User}
            teamId={teamId}
            title="Welcome to Remodel Health!"
        >
            <AcceptServicesTask
                complete={acceptRHServicesComplete}
                enabled
                key={TaskItems.AcceptRHServices}
            />
            <ReviewMemberInfoTask
                acceptRHServicesTask={TaskItems.AcceptRHServices}
                complete={reviewMemberInfoComplete}
                completedTasks={completedTasks}
                enabled={acceptRHServicesComplete}
                key={TaskItems.ReviewMemberInfo}
            />
            <EnterSocialSecurityTask
                acceptRHServicesTask={TaskItems.AcceptRHServices}
                complete={enterSsnComplete}
                completedTasks={completedTasks}
                enabled={reviewMemberInfoComplete}
                key={TaskItems.EnterSocialSecurityNumbers}
            />
        </TaskListContainer>
    );
};

export default hot(module)(MemberMemberLaunchTaskList);
