import { Tasks } from 'api/generated/enums';
import { TaskDto } from 'api/generated/models';
import AddressComponent from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/AddressComponent';
import React from 'react';
import { hot } from 'react-hot-loader';

const PrimaryAddressSection = ({ tasks }: { tasks: TaskDto[] }) => {
    const task = tasks.find((t) => t.taskId === Tasks.PrimaryAddressInfo);
    const taskId = task?.globalId as string;

    return <AddressComponent address={task?.response} taskId={taskId} />;
};

export default hot(module)(PrimaryAddressSection);
