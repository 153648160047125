import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { COMPLETE_TASK_ACTION, completeTask } from 'actions/taskFlows/completeTask';
import { EDIT_TASK_STATUS_ACTION } from 'actions/taskFlows/editTaskStatus';
import { TaskStatuses } from 'api/generated/enums';
import { TaskDto } from 'api/generated/models';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import {
    IchraFlowResponseType,
    defaultHandlePrevious,
    findPreviousSiblingTask,
    getSupportedEnrollmentUserFlow,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import WaiveIchraModal from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/WaiveIchraModal';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

type IIchraFlowButtonsProps = {
    currentTaskId: string;
    displayAsRow?: boolean;
    forwardButtonTextOverride?: string;
    handleNext?: () => void;
    handlePrevious?: () => void;
    hideContinueButton?: boolean;
    isDisabled?: boolean;
    isIchraOfferTask?: boolean;
    isLoading?: boolean;
    redirectToDashboard?: boolean;
    showPreviousButton?: boolean;
    showWaiveButton?: boolean;
};
const IchraFlowButtons = ({
    currentTaskId,
    displayAsRow,
    forwardButtonTextOverride,
    handleNext,
    handlePrevious,
    hideContinueButton,
    isDisabled,
    isLoading,
    redirectToDashboard,
    showPreviousButton,
    showWaiveButton,
}: IIchraFlowButtonsProps) => {
    const dispatch = useThunkDispatch();
    const { userFlow, isSubmitting } = useSelector((state: AppStore) => ({
        isSubmitting: hasApiActivity(state, COMPLETE_TASK_ACTION, EDIT_TASK_STATUS_ACTION),
        userFlow: getSupportedEnrollmentUserFlow(state.userFlows),
    }));
    const showLoadingActivity = !!isLoading;
    const { isVisible, openModal, closeModal } = useModalState();
    const previousTaskId = findPreviousSiblingTask(
        currentTaskId,
        userFlow?.tasks?.filter(
            (it) => ![TaskStatuses.Skipped, TaskStatuses.Overridden].contains(it.taskStatusId)
        ) as TaskDto[]
    )?.globalId as string;

    const defaultHandleNext = async () => {
        const response: IchraFlowResponseType = undefined;
        await dispatch(completeTask(currentTaskId, response));
    };

    const callHandlePrevious = async () => defaultHandlePrevious(dispatch, previousTaskId);

    return (
        <Stack direction={displayAsRow ? 'row' : 'column'}>
            {(showPreviousButton || !hideContinueButton) && (
                <Grid
                    alignSelf="center"
                    container
                    gap={4}
                    justifyContent="center"
                    marginBottom={displayAsRow ? 0 : 3}
                    marginRight={displayAsRow ? 2 : 0}
                    marginTop={displayAsRow ? 0 : 3}
                >
                    {showPreviousButton && (
                        <Skeleton
                            isEnabled={showLoadingActivity}
                            structure={[
                                [
                                    {
                                        columnProps: { alignItems: 'center' },
                                        count: 1,
                                        height: 45,
                                        width: 100,
                                    },
                                ],
                            ]}
                        >
                            <Button
                                isLoading={isSubmitting}
                                onClick={handlePrevious ?? callHandlePrevious}
                                variant="outlined"
                            >
                                Previous
                            </Button>
                        </Skeleton>
                    )}
                    {!hideContinueButton && (
                        <Skeleton
                            isEnabled={showLoadingActivity}
                            structure={[
                                [
                                    {
                                        columnProps: { alignItems: 'center' },
                                        count: 1,
                                        height: 45,
                                        width: 100,
                                    },
                                ],
                            ]}
                        >
                            <Button
                                disabled={isDisabled}
                                isLoading={isSubmitting}
                                onClick={handleNext ?? defaultHandleNext}
                                variant="contained"
                            >
                                {forwardButtonTextOverride ?? 'Continue'}
                            </Button>
                        </Skeleton>
                    )}
                </Grid>
            )}
            {showWaiveButton && (
                <Grid alignItems="flex-end" alignSelf="center" item>
                    <Skeleton
                        isEnabled={showLoadingActivity}
                        structure={[
                            [
                                {
                                    columnProps: { alignItems: 'center' },
                                    count: 1,
                                    height: 45,
                                    width: 120,
                                },
                            ],
                        ]}
                    >
                        <Button
                            color="warning"
                            isLoading={isSubmitting}
                            onClick={openModal}
                            variant="outlined"
                        >
                            Waive&nbsp;ICHRA
                        </Button>
                    </Skeleton>
                </Grid>
            )}
            {isVisible && (
                <WaiveIchraModal
                    currentTaskGlobalId={currentTaskId}
                    onClose={closeModal}
                    redirectToDashboard={redirectToDashboard}
                />
            )}
        </Stack>
    );
};
export default hot(module)(IchraFlowButtons);
