import { IAction } from 'actions/commonAction';
import { SET_ICHRA_FLOW_HAS_ERRORS } from 'pages/dashboard/ichraTaskFlow/ichraFlowActions';

const initialState = {
    hasErrors: false,
};

export type IIchraFlowState = {
    hasErrors: boolean;
};

export const ichraFlowState = (state = initialState, action: IAction<IIchraFlowState>) => {
    switch (action.type) {
        case SET_ICHRA_FLOW_HAS_ERRORS:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};
