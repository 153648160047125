import ConfirmationModal from 'components/ConfirmationModal';
import React from 'react';
import { hot } from 'react-hot-loader';

const VerificationReminderModal = ({
    close,
    name,
    sendReminder,
    showActivity,
}: {
    close: () => void;
    name: string | undefined;
    sendReminder: () => void;
    showActivity: boolean;
}) => {
    const body = `Are you sure you want to send a verification documents reminder to ${name}?`;
    return (
        <ConfirmationModal
            body={body}
            onNoClick={close}
            onYesClick={sendReminder}
            showActivity={showActivity}
            title="Confirm"
        />
    );
};

export default hot(module)(VerificationReminderModal);
