import { AppStoreThunkDispatch } from 'actions/commonAction';
import {
    HouseholdMemberDto,
    IDrug,
    IHouseholdMemberDto,
    ISurveyHouseholdMemberDto,
    IUserProfile,
} from 'api/generated/models';
import { SurveyTabs } from 'constants/surveyTabs';
import {
    IMemberCoverageSelection,
    ISurveyHouseholdMember,
    ISurveyState,
} from 'pages/survey/surveyState';
import { AppStore } from 'reducers/appReducer';
import { ISurveyIdeonProvider } from 'reducers/surveyIdeonProviders';
import { hasContents, RecursivePartial } from 'utilities';
import { calculateHouseholdIncome } from 'utilities/household';

export const SET_TAB_BAR_INDEX = 'SET_TAB_BAR_INDEX';
export const setTabBarIndex = (tabIndex: number) => async (dispatch: AppStoreThunkDispatch) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const action: any = {
        tabIndex,
        type: SET_TAB_BAR_INDEX,
    };
    if (tabIndex === SurveyTabs.Review) {
        action.isReviewStarted = true;
    }
    dispatch(action);
};

export const SET_IS_REVIEW_STARTED = 'SET_IS_REVIEW_STARTED';
export const setIsReviewStarted = (data: boolean) => async (dispatch: AppStoreThunkDispatch) =>
    dispatch({
        data,
        type: SET_IS_REVIEW_STARTED,
    });

export const SAVE_MEMBER_FORM = 'SAVE_MEMBER_FORM';
export const saveMemberForm = (data: Partial<ISurveyState['member']>) => async (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data,
        type: SAVE_MEMBER_FORM,
    });

export const SAVE_SURVEY_USER_PROFILE = 'SAVE_SURVEY_USER_PROFILE';
export const saveSurveyUserProfile = (data: IUserProfile) => async (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data,
        type: SAVE_SURVEY_USER_PROFILE,
    });

export const SAVE_INCOME_FORM = 'SAVE_INCOME_FORM';
export const saveIncomeForm = (data: Partial<ISurveyState['income']>) => async (
    dispatch: AppStoreThunkDispatch,
    getState: () => AppStore
) => {
    const {
        surveyState: {
            household: { members },
            income,
        },
        singleFilerStandardDeduction,
    } = getState();
    members?.forEach((member) => {
        member.income = Number(income[member.householdMemberId]);
    });
    const newData = { ...income, ...data };
    dispatch({
        data: {
            ...newData,
            householdIncome: calculateHouseholdIncome(
                newData,
                members,
                singleFilerStandardDeduction
            ),
        },
        type: SAVE_INCOME_FORM,
    });
};

export type ISurveyCoverage = Partial<ISurveyState['coverage']>;
export const SAVE_COVERAGE_FORM = 'SAVE_COVERAGE_FORM';
export const saveCoverageForm = (data: ISurveyCoverage) => async (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data,
        type: SAVE_COVERAGE_FORM,
    });

export const SAVE_CONTACT_FORM = 'SAVE_CONTACT_FORM';
export const saveContactForm = (data: Partial<ISurveyState['contact']>) => async (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data,
        type: SAVE_CONTACT_FORM,
    });

export const SAVE_QUALITATIVE = 'SAVE_QUALITATIVE';
export const saveQualitative = (data: RecursivePartial<ISurveyState['qualitative']>) => async (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data,
        type: SAVE_QUALITATIVE,
    });

export const SAVE_HOUSEHOLD = 'SAVE_HOUSEHOLD';
export const saveHousehold = (data: RecursivePartial<ISurveyState['household']>) => async (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data,
        type: SAVE_HOUSEHOLD,
    });

export const SAVE_HOUSEHOLD_MEMBERS = 'SAVE_HOUSEHOLD_MEMBERS';
export const saveHouseholdMembers = (
    data: (HouseholdMemberDto | ISurveyHouseholdMemberDto)[]
) => async (dispatch: AppStoreThunkDispatch) =>
    dispatch({
        data,
        type: SAVE_HOUSEHOLD_MEMBERS,
    });

export const SAVE_HOUSEHOLD_MEMBER = 'SAVE_HOUSEHOLD_MEMBER';
export const saveHouseholdMember = (data: Partial<ISurveyHouseholdMember>) => (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data,
        type: SAVE_HOUSEHOLD_MEMBER,
    });

const removeMemberIdFromDrugOrProviderList = <T extends IDrug | ISurveyIdeonProvider>(
    selectedList: T[],
    householdMemberId: string
) =>
    selectedList
        .map((x) => {
            x.entityIds = x.entityIds?.filter((e) => e !== householdMemberId);
            return x;
        })
        .filter((x) => hasContents(x.entityIds));

export const REMOVE_HOUSEHOLD_MEMBER = 'REMOVE_HOUSEHOLD_MEMBER';
export const removeHouseholdMember = ({
    householdMemberId,
}: Pick<IHouseholdMemberDto, 'householdMemberId'>) => (
    dispatch: AppStoreThunkDispatch,
    getState: () => AppStore
) => {
    const { coverage } = (getState() as AppStore).surveyState;
    if (coverage.selectedDrugs.length > 0 || coverage.selectedProviders.length > 0) {
        const newSelectedProviders = removeMemberIdFromDrugOrProviderList(
            coverage.selectedProviders,
            householdMemberId
        );
        const newSelectedDrugs = removeMemberIdFromDrugOrProviderList(
            coverage.selectedDrugs,
            householdMemberId
        );
        dispatch(
            saveCoverageForm({
                selectedDrugs: newSelectedDrugs,
                selectedProviders: newSelectedProviders,
            })
        );
    }

    dispatch({
        householdMemberId,
        type: REMOVE_HOUSEHOLD_MEMBER,
    });
};

export const removeHouseholdMembers = (householdMembers: ISurveyHouseholdMemberDto[]) => (
    dispatch: AppStoreThunkDispatch
) => householdMembers.forEach((h) => dispatch(removeHouseholdMember(h)));

export const saveMemberCoverageSelection = (
    memberCoverageSelections: IMemberCoverageSelection[]
) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => {
    const { household, member } = getState().surveyState;
    const householdMembers = household?.members;

    householdMembers?.forEach((hhm) => {
        const hhmCoverageSelection = memberCoverageSelections.find(
            (m) => m.householdMemberId === hhm.householdMemberId
        );
        hhm = { ...hhm, ...hhmCoverageSelection };
        dispatch(saveHouseholdMember(hhm));
    });

    const memberCoverageSelection = memberCoverageSelections.find(
        (m) => m.householdMemberId === member.userId
    );
    const updatedMember = { ...member, ...memberCoverageSelection };

    dispatch(saveMemberForm(updatedMember));
};
