import { Paper } from '@mui/material';
import { downloadBulkEnrollmentFile } from 'actions/selectedPlan/downloadBulkEnrollmentFile';
import Button from 'components/Button';
import PageHeader from 'components/PageHeader';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { downloadFileFromApi } from 'utilities/rhFile';

const BulkEnrollmentManagementPage = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const onDownloadClick = useCallback(async (carrier: string, state: string) => {
        setIsDownloading(true);
        await downloadFileFromApi(
            async () => downloadBulkEnrollmentFile(carrier, state),
            'Failed to download enrollment info file'
        );
        setIsDownloading(false);
    }, []);

    return (
        <Paper>
            <Col>
                <Row className="justify-content-space-between">
                    <PageHeader variant="h4">Bulk Enrollment Downloads</PageHeader>
                </Row>
                <hr />
                <Col className="mb-3">
                    <Button
                        isLoading={isDownloading}
                        onClick={async () => onDownloadClick('HSA', '')}
                        size="small"
                        variant="outlined"
                    >
                        HSA Insurance (PA & RI)
                    </Button>
                </Col>
                <Col className="mb-3">
                    <Button
                        isLoading={isDownloading}
                        onClick={async () => onDownloadClick('Highmark BlueCross BlueShield', 'PA')}
                        size="small"
                        variant="outlined"
                    >
                        Highmark BlueCross BlueShield (PA)
                    </Button>
                </Col>
                <Col className="mb-3">
                    <Button
                        isLoading={isDownloading}
                        onClick={async () => onDownloadClick('UPMC', 'PA')}
                        size="small"
                        variant="outlined"
                    >
                        UPMC (PA)
                    </Button>
                </Col>
            </Col>
        </Paper>
    );
};

export default hot(module)(BulkEnrollmentManagementPage);
