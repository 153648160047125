import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const MarriageStatusExamples = () => (
    <React.Fragment>
        <p>Examples of documents you may submit include:</p>
        <ul>
            <li>Marriage certificate showing who got married and the date of the marriage.</li>
            <li>Marriage license showing who got married and the date of the marriage.</li>
            <li>
                Official public record of marriage showing who got married and the date of the
                marriage.
            </li>
            <li>
                Marriage affidavit or affidavit of support that’s signed and dated by the person who
                officiated the marriage or the official witness of the marriage.
            </li>
            <li>A religious document showing who got married and the date of the marriage.</li>
        </ul>
        <p>
            For a full list of documents you can submit, visit{' '}
            <ExternalLink href="https://www.healthcare.gov/help/prove-marriage">
                healthcare.gov/help/prove-marriage.
            </ExternalLink>
        </p>
    </React.Fragment>
);

export default hot(module)(MarriageStatusExamples);
