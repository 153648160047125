import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveCoverageForm } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isTrueOrFalse } from 'utilities';

const FaithBasedForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { hasFaithBasedSharingInterest } = useSelector((state: AppStore) => ({
        hasFaithBasedSharingInterest: state.surveyState.coverage.hasFaithBasedSharingInterest,
    }));
    const onSelection = useCallback(
        (isYes) => {
            dispatch(saveCoverageForm({ hasFaithBasedSharingInterest: isYes }));
        },
        [dispatch]
    );
    return (
        <SurveyFormWrapper
            {...commonProps}
            isDisabled={!isTrueOrFalse(hasFaithBasedSharingInterest)}
        >
            {children}
            <FormTitle description="Faith-based options include healthcare sharing programs such as Medi-Share.">
                Is your household interested in Faith-based options?
            </FormTitle>
            <YesOrNoSelect onSelection={onSelection} value={hasFaithBasedSharingInterest} />
        </SurveyFormWrapper>
    );
};

export default hot(module)(FaithBasedForm);
