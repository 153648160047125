import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';
import MultiSelectButtons, { Item } from 'pages/survey/multiSelectButtons/MultiSelectButtons';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { isTrue, isTrueOrFalse } from 'utilities';

type IYesNoWhoProps<T extends Item> = {
    isSelectedProp: keyof T;
    onSelection: ({
        isInvalid,
        isYes,
        options,
    }: {
        isInvalid: boolean;
        isYes: boolean | undefined;
        options: T[];
    }) => void;
    options: T[];
};

const YesNoWho = <T extends Item>({
    isSelectedProp = 'isSelected',
    options,
    onSelection: parentOnSelection,
}: IYesNoWhoProps<T>) => {
    const [isYes, setIsYes] = useState<boolean>();
    const getIsInvalid = useCallback(
        (newOptions, newIsYes) => {
            const anySelected = some(newOptions, (x) => isTrue(x[isSelectedProp]));
            return isUndefined(newIsYes) || (newIsYes && !anySelected);
        },
        [isSelectedProp]
    );
    const clearAutoFocus = useCallback(() => {
        options.forEach((option) => delete option.autoFocus);
    }, [options]);
    useEffect(() => {
        const areAnyNotSet = some(
            options,
            (x) => !isTrueOrFalse((x[isSelectedProp] as unknown) as boolean | string)
        );
        const anySelected = some(options, (x) =>
            isTrue((x[isSelectedProp] as unknown) as boolean | string)
        );
        const initialValue = areAnyNotSet ? undefined : anySelected;
        setIsYes(initialValue);
        const lastOption = options[options.length - 1];
        if (lastOption) {
            lastOption.autoFocus = true;
        }
        parentOnSelection({
            isInvalid: getIsInvalid(options, initialValue),
            isYes: initialValue,
            options: [...options],
        });
        return () => clearAutoFocus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onYesNoSelection = useCallback(
        (newIsYes) => {
            options.forEach((option) => {
                ((option as unknown) as Record<keyof T, boolean>)[isSelectedProp] = false;
            });
            setIsYes(newIsYes);
            parentOnSelection({
                isInvalid: getIsInvalid(options, newIsYes),
                isYes: newIsYes,
                options: [...options],
            });
        },
        [getIsInvalid, isSelectedProp, parentOnSelection, options]
    );
    const onSelection = useCallback(
        (option, index) => {
            option[isSelectedProp] = !option[isSelectedProp];
            options[index] = option;
            parentOnSelection({
                isYes,
                isInvalid: getIsInvalid(options, isYes),
                options: [...options],
            });
        },
        [getIsInvalid, isSelectedProp, isYes, parentOnSelection, options]
    );
    return (
        <React.Fragment>
            <YesOrNoSelect onSelection={onYesNoSelection} value={isYes} />
            {isYes && (
                <div>
                    <Row className="justify-content-center">
                        <Col className="text-muted mb-2 mt-4 px-1" md="7">
                            <Col>Select Who</Col>
                        </Col>
                    </Row>
                    <MultiSelectButtons
                        isInRow={options.length > 1}
                        isSelectedProp={isSelectedProp}
                        onSelection={onSelection}
                        options={options}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default hot(module)(YesNoWho);
