import { addTeamRhFiles } from 'actions/rhFile/addTeamRhFiles';
import { deleteTeamRhFile } from 'actions/rhFile/deleteTeamRhFile';
import { getRhFilesForTeam, GET_RH_FILES_FOR_TEAM_ACTION } from 'actions/rhFile/getRhFilesForTeam';
import { renameTeamRhFile } from 'actions/rhFile/renameTeamRhFile';
import { toggleTeamRhFileArchived } from 'actions/rhFile/toggleTeamRhFileArchived';
import { toggleTeamRhFileShared } from 'actions/rhFile/toggleTeamRhFileShared';
import { downloadTeamFile } from 'api/downloadTeamFile';
import { CategoryIds } from 'api/generated/enums';
import {
    ArchiveTeamRhFile,
    CreateTeamRhFile,
    DeleteTeamRhFile,
    DownloadTeamRhFile,
    EditTeamRhFile,
    ShareTeamRhFiles,
} from 'api/generated/permissions';
import RhFiles from 'components/rhFile/RhFiles';
import Skeleton from 'components/Skeleton';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const TeamRhFilesPage = () => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { isLoading, permissions, rhFiles } = useSelector((state: AppStore) => ({
        isLoading: state.apiActivity[GET_RH_FILES_FOR_TEAM_ACTION],
        permissions: {
            canArchiveRhFiles: state.login.up.includes(ArchiveTeamRhFile),
            canCreateRhFiles: state.login.up.includes(CreateTeamRhFile),
            canDeleteRhFiles: state.login.up.includes(DeleteTeamRhFile),
            canDownloadRhFiles: state.login.up.includes(DownloadTeamRhFile),
            canEditRhFiles: state.login.up.includes(EditTeamRhFile),
            canShareRhFiles: state.login.up.includes(ShareTeamRhFiles),
        },
        rhFiles: state.rhFiles,
    }));

    useEffect(() => {
        dispatch(getRhFilesForTeam(teamId, CategoryIds.General, false));
    }, [dispatch, teamId]);

    const getIsUnSharableAndText = useCallback(
        (createdByUser) => {
            const isUnSharable = createdByUser.teamId === teamId;
            return {
                isUnSharable,
                sharedTooltipText: isUnSharable
                    ? 'This file was uploaded by a member on this team and therefore cannot be unshared.'
                    : 'This file is shared with this team.',
            };
        },
        [teamId]
    );
    return isLoading ? (
        <Row>
            <Skeleton
                count={1}
                height="105px"
                Wrapper={({ children }) => <div className="col-12 mb-3">{children}</div>}
            />
            <div className="w-100"></div>
            <Skeleton
                count={4}
                height="214px"
                Wrapper={({ children }) => <div className="col-sm-6 col-lg-3 mb-2">{children}</div>}
            />
        </Row>
    ) : (
        <RhFiles
            addRhFiles={(files, toastSuccessMessage) =>
                addTeamRhFiles(teamId, files, toastSuccessMessage)
            }
            data={rhFiles}
            deleteRhFile={deleteTeamRhFile}
            downloadRhFile={async ({ id }) => downloadTeamFile(teamId, id)}
            getIsUnSharableAndText={getIsUnSharableAndText}
            permissions={permissions}
            renameRhFile={(renamedRhFile) => renameTeamRhFile(teamId, renamedRhFile)}
            toggleRhFileArchived={toggleTeamRhFileArchived}
            toggleRhFileShared={({ id }) => toggleTeamRhFileShared(teamId, id)}
        />
    );
};

export default hot(module)(TeamRhFilesPage);
