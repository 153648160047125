import { IMarketplacePlanDto, ISelectedPlan } from 'api/generated/models';
import {
    CLEAR_BENEFITS_SELECTION_STATE,
    SET_PLAN_FOR_BENEFIT_SELECTION,
    SET_REQUESTED_WAGE_UP,
} from 'pages/dashboard/medicalBenefitSelection/benefitSelectionActions';
import basicReducer from 'reducers/basicReducer';

const initialState = {
    isAboveApprovalCap: false,
    plan: undefined as IMarketplacePlanDto | ISelectedPlan | undefined,
    requestedWageUp: '',
};

export type IBenefitSelectionState = typeof initialState;

export const benefitSelectionState = basicReducer(
    initialState,
    SET_PLAN_FOR_BENEFIT_SELECTION,
    SET_REQUESTED_WAGE_UP,
    CLEAR_BENEFITS_SELECTION_STATE
);
