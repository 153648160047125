import Typography from '@mui/material/Typography';
import { HouseholdMemberTypes } from 'api/generated/enums';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasContents } from 'utilities';
import { formatCurrency } from 'utilities/format';
import {
    getDisplayFirstName,
    getHouseholdMemberBelowStandardDeductionMessage,
    hasMemberType,
    isDependentAndOverStandardDeduction,
    isDependentAndUnderOrEqualToStandardDeduction,
} from 'utilities/household';

const Plus = () => <span className="text-primary">+</span>;

const IncomeConfirmationForm = ({ children, commonProps }: IStepProps) => {
    const {
        householdIncome,
        householdMembers,
        income,
        singleFilerStandardDeduction,
        year,
    } = useSelector((state: AppStore) => ({
        householdIncome: state.surveyState.income.householdIncome,
        householdMembers: state.surveyState.household.members,
        income: state.surveyState.income,
        singleFilerStandardDeduction: state.singleFilerStandardDeduction,
        year: state.surveyState.incomeYear,
    }));
    const dependentsOverStandardDeduction = householdMembers?.filter((m) =>
        isDependentAndOverStandardDeduction(
            m,
            Number(income[m.householdMemberId]),
            singleFilerStandardDeduction
        )
    );

    const dependentsUnderStandardDeduction = householdMembers?.filter((m) =>
        isDependentAndUnderOrEqualToStandardDeduction(
            m,
            Number(income[m.householdMemberId]),
            singleFilerStandardDeduction
        )
    );
    const hasSpouse = useMemo(() => hasMemberType(householdMembers, HouseholdMemberTypes.Spouse), [
        householdMembers,
    ]);
    const hasUnclaimed = useMemo(
        () => hasMemberType(householdMembers, HouseholdMemberTypes.Unclaimed),
        [householdMembers]
    );

    return (
        <SurveyFormWrapper {...commonProps} submitText="Yes">
            {children}
            <FormTitle>Verify Total Household Income</FormTitle>
            <Typography fontWeight="normal" mb={6} textAlign="center" variant="h3">
                <p>
                    You’ve entered <strong>{formatCurrency(householdIncome)}</strong> for total
                    household income.
                </p>
                <p>Is this correct?</p>
            </Typography>
            <Row className="justify-content-center">
                <Col md="7">
                    <div>How is this calculated?</div>
                    <div className="text-muted">
                        My Income{' '}
                        {hasSpouse && (
                            <span>
                                <Plus /> Spouse Income{' '}
                            </span>
                        )}
                        {dependentsOverStandardDeduction?.map((m) => (
                            <span key={m.householdMemberId}>
                                <Plus /> {getDisplayFirstName(m)}&apos;s income{' '}
                            </span>
                        ))}
                        <span>
                            <Plus /> Additional Income{' '}
                        </span>
                        <span className="text-danger">&minus;</span> Deductions{' '}
                    </div>
                    {hasContents(dependentsUnderStandardDeduction) && (
                        <div className="mt-1 text-muted">
                            <em>
                                {getHouseholdMemberBelowStandardDeductionMessage(
                                    dependentsUnderStandardDeduction,
                                    year
                                )}
                            </em>
                        </div>
                    )}
                    {hasUnclaimed && (
                        <div className="mt-1 text-muted">
                            <em>
                                Note: Income from household members not claimed on your taxes is not
                                included in total household income.
                            </em>
                        </div>
                    )}
                </Col>
            </Row>
        </SurveyFormWrapper>
    );
};

export default hot(module)(IncomeConfirmationForm);
