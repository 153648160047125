import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { getExpenseTypes } from 'actions/expenseTypes/getExpenseTypes';
import { clearMemberReimbursementUtilizations } from 'actions/submittedExpenses/clearMemberReimbursementUtilizations';
import { getMemberReimbursementUtilizations } from 'actions/submittedExpenses/getMemberReimbursementUtilizations';
import PageHeader from 'components/PageHeader';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import ExpenseTypeUtilization from 'pages/reimbursementExpenseManagement/ExpenseTypeUtilization';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

const ReimbursementUtilizationSection = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { expenseTypes, memberReimbursementUtilizations, selectedYear } = useSelector(
        (state: AppStore) => ({
            expenseTypes: state.expenseTypes,
            memberReimbursementUtilizations: state.memberReimbursementUtilizations,
            selectedYear: state.profileState.selectedYear,
        })
    );
    useEffect(() => {
        dispatch(getExpenseTypes());
    }, [dispatch]);
    useEffect(() => {
        if (hasValue(selectedYear)) {
            dispatch(getMemberReimbursementUtilizations(userId, selectedYear));
        }
    }, [dispatch, selectedYear, userId]);
    useEffect(
        () => () => {
            dispatch(clearMemberReimbursementUtilizations());
        },
        [dispatch]
    );

    useEffect(
        () => () => {
            dispatch(clearMemberReimbursementUtilizations());
        },
        [dispatch]
    );

    const utilizationContent = memberReimbursementUtilizations.map((utilization, index) => {
        const expenseType = expenseTypes.find((x) => x.id === utilization.expenseTypeId);
        return (
            <ExpenseTypeUtilization
                index={index}
                key={utilization.expenseTypeId}
                title={expenseType?.name}
                utilization={utilization}
            ></ExpenseTypeUtilization>
        );
    });

    return (
        <React.Fragment>
            {memberReimbursementUtilizations.length > 0 && (
                <Stack pt={2}>
                    <PageHeader variant="h4">Reimbursement Program Summary</PageHeader>
                    <Stack mt={2}>
                        <Divider />
                    </Stack>
                    {utilizationContent}
                    <Stack mt={2}>
                        <Divider />
                    </Stack>
                </Stack>
            )}
        </React.Fragment>
    );
};

export default hot(module)(ReimbursementUtilizationSection);
