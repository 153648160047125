import { SurveyTypes } from 'api/generated/enums';
import { IMemberQuoteInfo, IMemberVerifiedInfo, ITaxDataDto, IUser } from 'api/generated/models';
import { EditUserBudget, ViewTaxData, ViewUserWageIncrease } from 'api/generated/permissions';
import PageHeader from 'components/PageHeader';
import { teamStateIds } from 'constants/teamStateIds';
import useContributionProps from 'hooks/useContributionProps';
import useUserProps from 'hooks/useUserProps';
import ContributionProfileAttributes from 'pages/profile/contributionProfileAttributes/ContributionProfileAttributes';
import IncomeAdminInfo from 'pages/profile/IncomeAdminInfo';
import IncomeQuoteInfo from 'pages/profile/IncomeQuoteInfo';
import IncomeVerifiedInfo from 'pages/profile/IncomeVerifiedInfo';
import PreviousCostsInfo from 'pages/profile/PreviousCostsInfo';
import { ICommonProps } from 'pages/profile/ProfilePage';
import TotalTaxRateAttribute from 'pages/profile/TotalTaxRateAttribute';
import UserBudgetProfileAttribute from 'pages/profile/UserBudgetProfileAttribute';
import React, { ReactNode, useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasValue } from 'utilities';

const SHOW_LEGACY_BUDGET_END_YEAR = 2021;

export const PerLabel = ({ children, unit }: { children: ReactNode; unit: ReactNode }) => (
    <span>
        {children} <small>per {unit}</small>
    </span>
);

type IIncomeInfoProps = {
    canViewMviEmployeeAnnualIncome: boolean;
    canViewPreviousCosts: boolean;
    canViewVerifiedInfo: boolean;
    hasMemberQuoteInfo: boolean | undefined;
    hasMemberVerifiedInfo: boolean | undefined;
    hasOnlyBasicInfo: boolean | undefined;
    isCurrent: boolean;
    memberQuoteInfo: IMemberQuoteInfo | undefined;
    memberVerifiedInfo: IMemberVerifiedInfo | undefined;
    quoteInfoCommonProps: ICommonProps<IMemberQuoteInfo>;
    teamStateId: number;
    user: IUser | undefined;
    verifiedInfoCommonProps: ICommonProps<IMemberVerifiedInfo>;
};

const handleShowTaxData = (
    hasMemberQuoteInfo: boolean | undefined,
    hasMemberVerifiedInfo: boolean | undefined,
    canViewTaxData: boolean,
    taxData: ITaxDataDto,
    hasCompletedIchraSurvey: boolean
) =>
    (hasMemberQuoteInfo || hasMemberVerifiedInfo) &&
    canViewTaxData &&
    hasValue(taxData) &&
    !hasCompletedIchraSurvey;

const IncomeInfo = ({
    canViewMviEmployeeAnnualIncome,
    canViewVerifiedInfo,
    canViewPreviousCosts,
    hasMemberQuoteInfo,
    hasMemberVerifiedInfo,
    hasOnlyBasicInfo,
    memberQuoteInfo,
    quoteInfoCommonProps,
    teamStateId,
    user,
    verifiedInfoCommonProps,
}: IIncomeInfoProps) => {
    const { isCurrent, memberVerifiedInfo } = useUserProps();
    const {
        canEditUserBudget,
        canViewTaxData,
        canViewUserContribution,
        showUserBudgetData,
        taxData,
    } = useSelector((state: AppStore) => {
        const selectedYear = +state.profileState.selectedYear;
        return {
            canEditUserBudget: state.login.up.includes(EditUserBudget),
            canViewTaxData: hasSomePermissions(state, ViewTaxData),
            canViewUserContribution: hasSomePermissions(state, ViewUserWageIncrease) || isCurrent,
            showUserBudgetData:
                teamStateId !== teamStateIds.Prospect &&
                teamStateId !== teamStateIds.Unknown &&
                selectedYear <= SHOW_LEGACY_BUDGET_END_YEAR,
            taxData: state.taxData,
        };
    });
    const { hasActiveContribution } = useContributionProps();
    const WrapperWithHeader = useCallback(
        ({ children }) => (
            <React.Fragment>
                <PageHeader variant="h4">Income</PageHeader>
                <hr />
                {children}
            </React.Fragment>
        ),
        []
    );
    const showContributionInfo = canViewUserContribution && hasActiveContribution;
    const hasContributionAndBasicInfo = hasActiveContribution && hasOnlyBasicInfo;
    const hasCompletedIchraSurvey = memberVerifiedInfo?.surveyTypeCompleted === SurveyTypes.ICHRA;
    const showTaxData = handleShowTaxData(
        hasMemberQuoteInfo,
        hasMemberVerifiedInfo,
        canViewTaxData,
        taxData,
        hasCompletedIchraSurvey
    );
    const hasNoMemberVerifiedInfoToDisplay = !hasMemberVerifiedInfo || !canViewVerifiedInfo;
    if (!hasMemberQuoteInfo && hasNoMemberVerifiedInfoToDisplay && !hasContributionAndBasicInfo) {
        return <React.Fragment />;
    }
    let incomeInfo;
    if (hasMemberQuoteInfo) {
        incomeInfo = <IncomeQuoteInfo quoteInfoCommonProps={quoteInfoCommonProps} />;
    }
    if (canViewMviEmployeeAnnualIncome) {
        incomeInfo = <IncomeAdminInfo verifiedInfoCommonProps={verifiedInfoCommonProps} />;
    }
    if (hasMemberVerifiedInfo && canViewVerifiedInfo) {
        incomeInfo = <IncomeVerifiedInfo verifiedInfoCommonProps={verifiedInfoCommonProps} />;
    }
    return (
        <WrapperWithHeader>
            {incomeInfo}
            {showContributionInfo && <ContributionProfileAttributes />}
            {!showContributionInfo && showUserBudgetData && (
                <UserBudgetProfileAttribute readonly={!canEditUserBudget} user={user} />
            )}
            {showTaxData && <TotalTaxRateAttribute />}
            {canViewPreviousCosts && hasMemberQuoteInfo && (
                <PreviousCostsInfo
                    memberQuoteInfo={memberQuoteInfo}
                    quoteInfoCommonProps={quoteInfoCommonProps}
                />
            )}
        </WrapperWithHeader>
    );
};

export default hot(module)(IncomeInfo);
