import UndoIcon from '@mui/icons-material/Undo';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import {
    patchPayrollReportUserSnapshot,
    PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION,
} from 'actions/payroll/patchPayrollReportUserSnapshot';
import { IPayrollReportUserSnapshot, IPayrollReportUserSnapshotDto } from 'api/generated/models';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import TextArea from 'components/TextArea';
import Tooltip from 'components/Tooltip';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { getNoteLastModifiedBySubText } from 'pages/payrollReports/PayrollReportAdjustmentModal';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue, stringToFloat } from 'utilities';
import { onChange } from 'utilities/forms';
import { isNoteRequired } from 'utilities/payrollReports';
import { number, object, string } from 'yup';

const NOTE_MAX_LENGTH = 1000;
export enum EmployerContributionTypes {
    Hsa,
    Fsa,
}

const getContributionData = (
    contributionType: EmployerContributionTypes,
    payrollReportUserSnapshot: IPayrollReportUserSnapshotDto | undefined
) => {
    if (contributionType === EmployerContributionTypes.Hsa) {
        return {
            employerContribution: payrollReportUserSnapshot?.hsaEmployerContribution,
            patchPropertyBasedOnContributionType: 'customHsaEmployerContribution',
            title: 'HSA',
        };
    } else {
        return {
            employerContribution: payrollReportUserSnapshot?.fsaEmployerContribution,
            patchPropertyBasedOnContributionType: 'customFsaEmployerContribution',
            title: 'FSA',
        };
    }
};

type IPayrollReportCustomEmployerContributionModal = {
    close: () => void;
    contributionType: EmployerContributionTypes;
    contributionValue?: number;
    payrollReportUserSnapshot: IPayrollReportUserSnapshotDto | undefined;
};

const PayrollReportCustomEmployerContributionModal = ({
    close,
    contributionType,
    contributionValue: initialContribution,
    payrollReportUserSnapshot,
}: IPayrollReportCustomEmployerContributionModal) => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION),
    }));

    const [contributionAmount, setContributionAmount] = useState(initialContribution);
    const [note, setNote] = useState<string | undefined>(payrollReportUserSnapshot?.note ?? '');
    useEffect(() => {
        setNote(payrollReportUserSnapshot?.note);
    }, [payrollReportUserSnapshot?.note]);

    const {
        employerContribution,
        patchPropertyBasedOnContributionType,
        title,
    } = getContributionData(contributionType, payrollReportUserSnapshot);
    const isCustom = employerContribution !== Number(contributionAmount);
    const noteRequired = isNoteRequired({
        ...payrollReportUserSnapshot,
        [patchPropertyBasedOnContributionType]: contributionAmount,
    });

    const schema = object({
        contributionAmount: number()
            .transform(stringToFloat)
            .min(0)
            .required()
            .label(title),
        note: string()
            .trim()
            .max(NOTE_MAX_LENGTH)
            .isRequiredWhen(noteRequired)
            .label('Member Note'),
    });
    const { errors, validate } = useForm(schema);

    const subtext = getNoteLastModifiedBySubText(payrollReportUserSnapshot);
    const onSubmit = async () => {
        const { isValid } = await validate({ contributionAmount, note });
        if (isValid) {
            const patch: Partial<IPayrollReportUserSnapshot> = {
                note,
                [patchPropertyBasedOnContributionType]: isCustom ? contributionAmount : null,
            };
            await dispatch(
                patchPayrollReportUserSnapshot(
                    payrollReportUserSnapshot?.id,
                    patch,
                    `Custom ${title} Employer Contribution`
                )
            );
            close();
        }
    };
    return (
        <Dialog fullWidth onClose={close} open>
            <DialogTitle>Edit {title} Employer Contribution & Note</DialogTitle>
            <DialogContent dividers>
                <Form
                    id="edit-custom-employer-contribution-modal-form"
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                >
                    <Stack alignItems="center" gap={2}>
                        <CurrencyTextField
                            allowNegative
                            autoFocus
                            data-cy="employer-contribution-currency-field"
                            errors={errors?.contributionAmount}
                            InputProps={{
                                endAdornment: !isCustom ? (
                                    <React.Fragment />
                                ) : (
                                    <InputAdornment position="end">
                                        <Tooltip title="Reset to calculated value">
                                            <IconButton
                                                data-cy="reset-icon-button"
                                                edge="end"
                                                onClick={() =>
                                                    setContributionAmount(employerContribution)
                                                }
                                            >
                                                <UndoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                            label={title}
                            name="contributionAmount"
                            onChange={onChange(setContributionAmount)}
                            placeholder={`Enter ${title}`}
                            value={contributionAmount}
                        />
                        <TextArea
                            data-cy="contribution-note-field"
                            errors={errors?.note}
                            isOptional={!noteRequired}
                            label="Member Note"
                            maxLength={NOTE_MAX_LENGTH}
                            name="note"
                            onChange={onChange(setNote)}
                            placeholder={`Add an explanation for why a custom ${title} employer contribution is required`}
                            value={note}
                        />
                        {hasValue(subtext) && <div className="text-muted">{subtext}</div>}
                    </Stack>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="text">
                    Cancel
                </Button>
                <LoadingButton
                    form="edit-custom-employer-contribution-modal-form"
                    loading={isLoading}
                    type="submit"
                    variant="text"
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default hot(module)(PayrollReportCustomEmployerContributionModal);
