import Typography from '@mui/material/Typography';
import { clearCounties, clearSurvey } from 'actions/clear';
import {
    getSurveyInfoForUser,
    GET_SURVEY_INFO_FOR_USER_ACTION,
} from 'actions/survey/getSurveyInfoForUser';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import ActivityIndicator from 'components/ActivityIndicator';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import MemberSurveyComplete from 'pages/survey/MemberSurveyComplete';
import Survey from 'pages/survey/Survey';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { MEMBER_SURVEY_PATH, TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';

const MemberSurveyPage = () => {
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const [query] = useQuery();
    const isSurveyCompleted = query.has('complete');
    const { teamId } = useTeamProps();
    const { hasUser, user, userId } = useUserProps();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_USER_PROFILE_ACTION, GET_SURVEY_INFO_FOR_USER_ACTION),
    }));
    const dispatch = useThunkDispatch();
    const clear = useCallback(() => {
        dispatch(clearCounties());
        dispatch(clearSurvey());
    }, [dispatch]);
    useEffect(() => {
        clear();
        dispatch(getSurveyInfoForUser(userId));
        return () => clear();
    }, [clear, dispatch, userId]);
    const redirectUrl = useMemo(
        () =>
            isTeamManagementPage
                ? generatePath(TEAMS_PEOPLE_PROFILE_PATH, { teamId, userId })
                : `${MEMBER_SURVEY_PATH}?complete`,
        [isTeamManagementPage, teamId, userId]
    );
    if (isSurveyCompleted) {
        return <MemberSurveyComplete />;
    } else {
        return !isLoading && hasUser ? (
            <React.Fragment>
                {isTeamManagementPage && (
                    <Typography variant="h3">Survey for {user?.displayName}</Typography>
                )}
                <Survey
                    redirectUrl={redirectUrl}
                    subTitle={
                        <span>
                            Please review the information and <strong>Submit</strong> below.
                        </span>
                    }
                    title="Review and Submit"
                />
            </React.Fragment>
        ) : (
            <ActivityIndicator />
        );
    }
};

export default hot(module)(MemberSurveyPage);
