import { Card, CardContent } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import {
    GET_TEAM_MEMBERS_STATS_ACTION,
    getTeamMembersStats,
} from 'actions/stats/getTeamMembersStats';
import { EnrollmentStatuses, TeamStateIds } from 'api/generated/enums';
import { ITeamMembersStats } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import TeamMemberActionButtons from 'components/teamMembersActionButtons/TeamMembersActionButtons';
import { push } from 'connected-react-router';
import { QUOTE_INFO_STATUS, VERIFIED_INFO_STATUS } from 'constants/userInfoStatus';
import device from 'current-device';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import { ENROLLMENT_STATUS_PARAM, INFO_PARAM } from 'pages/people/PeopleTableCells';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import {
    Bar,
    BarChart,
    Cell,
    Label,
    LabelList,
    LabelProps,
    Pie,
    PieChart,
    ResponsiveContainer,
    Text,
    Tooltip,
    XAxis,
    XAxisProps,
} from 'recharts';
import { AppStore } from 'reducers/appReducer';
import { PEOPLE_PATH, TEAMS_PEOPLE_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';

const COLORS = ['#5cc2a7', '#ffcc66']; // ! use scss variables instead of hard-coded

const isDesktop = device.desktop();
const EVEN_INDEX = 2;
const X_AXIS_TICK_Y_OFFSET = 35;
const CustomizedAxisTick: XAxisProps['tick'] = ({ x, y, payload: { index, value } }) => {
    const yOffset = index % EVEN_INDEX && !isDesktop ? X_AXIS_TICK_Y_OFFSET : 0;
    return (
        <Text
            textAnchor="middle"
            verticalAnchor="start"
            width={75}
            x={x}
            y={(y as number) + yOffset}
        >
            {value}
        </Text>
    );
};

const GAUGE_LABEL_Y_OFFSET = 45;
const GaugeLabel: LabelProps['content'] = ({ offset, value, viewBox: { cx, cy }, width }) => (
    <switch>
        <foreignObject
            height={100}
            width={110}
            x={cx - width - offset}
            y={cy - GAUGE_LABEL_Y_OFFSET}
        >
            <Typography
                color={(theme) => theme.palette.secondary.main}
                textAlign="center"
                variant="h3"
            >
                {value}
            </Typography>
        </foreignObject>
        <text x={cx - width - offset} y={cy}>
            {value}
        </text>
    </switch>
);

const ONE_HUNDRED = 100;
const LAUNCH_STATUS_BAR_CHART_ORDER_AND_GROUPING = [
    {
        name: 'Waived',
        statuses: [EnrollmentStatuses.Waived],
    },
    { name: 'Ready For Launch', statuses: [EnrollmentStatuses.ReadyForLaunch] },
    {
        name: 'Invited To Launch/Awaiting Task List',
        statuses: [EnrollmentStatuses.InvitedToLaunch, EnrollmentStatuses.AwaitingTaskList],
    },
    { name: 'Benefits Selection', statuses: [EnrollmentStatuses.BenefitsSelection] },
    {
        name: 'Advisement',
        statuses: [
            EnrollmentStatuses.AdvisementNeeded,
            EnrollmentStatuses.AdvisementScheduled,
            EnrollmentStatuses.PendingDecision,
            EnrollmentStatuses.PendingWageUpRequest,
        ],
    },
    { name: 'Pending Application', statuses: [EnrollmentStatuses.PendingApplication] },
    { name: 'Enrolled', statuses: [EnrollmentStatuses.Enrolled] },
];
const RENEWING_STATUS_BAR_CHART_ORDER_AND_GROUPING = [
    {
        name: 'Waived',
        statuses: [EnrollmentStatuses.Waived],
    },
    { name: 'Ready For Renewal', statuses: [EnrollmentStatuses.ReadyForRenewal] },
    {
        name: 'Invited to Renewal/Awaiting Task List',
        statuses: [EnrollmentStatuses.InvitedToRenewal, EnrollmentStatuses.AwaitingTaskList],
    },
    { name: 'Benefits Selection', statuses: [EnrollmentStatuses.BenefitsSelection] },
    {
        name: 'Advisement',
        statuses: [
            EnrollmentStatuses.AdvisementNeeded,
            EnrollmentStatuses.AdvisementScheduled,
            EnrollmentStatuses.PendingDecision,
        ],
    },
    { name: 'Pending Application', statuses: [EnrollmentStatuses.PendingApplication] },
    { name: 'Enrolled', statuses: [EnrollmentStatuses.Enrolled] },
];
const TeamMembersWidget = () => {
    const dispatch = useThunkDispatch();
    const theme = useTheme();
    const { activeDate, hasTeamIdUrlParam, teamId, teamStateId } = useTeamProps();
    const { isLoading, stats } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_TEAM_MEMBERS_STATS_ACTION),
        profileStateYear: state.profileState?.selectedYear,
        stats: state.stats,
    }));
    const [dashboardYear, setDashboardYear] = useState(
        activeDate?.getYear().toString() ?? new Date().getUTCFullYear().toString()
    );
    const dashboardYearNumber = hasValue(dashboardYear) ? Number(dashboardYear) : undefined;

    useEffect(() => {
        setDashboardYear(activeDate?.getYear()?.toString() as string);
    }, [activeDate]);

    useEffect(() => {
        dispatch(getTeamMembersStats(teamId, dashboardYearNumber));
        dispatch(setSelectedYear(dashboardYear));
    }, [dashboardYear, dashboardYearNumber, dispatch, teamId]);

    const showLaunchBarChart = useMemo(
        () =>
            [TeamStateIds.MemberLaunch, TeamStateIds.Customer].includes(teamStateId) ||
            (teamStateId === TeamStateIds.Renewing &&
                dashboardYearNumber !== activeDate?.getYear()),
        [activeDate, dashboardYearNumber, teamStateId]
    );
    const showRenewingBarChart = useMemo(
        () =>
            teamStateId === TeamStateIds.Renewing && activeDate?.getYear() === dashboardYearNumber,
        [activeDate, dashboardYearNumber, teamStateId]
    );

    const showBarChart = showLaunchBarChart || showRenewingBarChart;
    const getBarChartData = useCallback(
        (
            grouping: {
                name: string;
                statuses: EnrollmentStatuses[];
            }[]
        ) =>
            hasValue(stats.statusCount)
                ? grouping.map(({ name, statuses }) => ({
                      name,
                      value: statuses.reduce(
                          (acc: number, status) =>
                              acc +
                              (stats.statusCount?.[
                                  EnrollmentStatuses[
                                      status as number
                                  ] as keyof ITeamMembersStats['statusCount']
                              ] ?? 0),
                          0
                      ),
                  }))
                : [],
        [stats.statusCount]
    );
    const launchBarChartData = getBarChartData(LAUNCH_STATUS_BAR_CHART_ORDER_AND_GROUPING);
    const renewingBarChartData = getBarChartData(RENEWING_STATUS_BAR_CHART_ORDER_AND_GROUPING);
    const gaugeData = useMemo(
        () => [
            {
                name: '# Verified Info',
                queryParam: VERIFIED_INFO_STATUS,
                value: stats.countVerified,
            },
            { name: '# Quote Info', queryParam: QUOTE_INFO_STATUS, value: stats.countQuote },
        ],
        [stats.countQuote, stats.countVerified]
    );
    const gaugeLabel = useMemo(
        () =>
            `${Math.round(
                (stats.countVerified / (stats.countQuote + stats.countVerified)) * ONE_HUNDRED
            )}% Verified Data`,
        [stats.countQuote, stats.countVerified]
    );
    const navigateToPeoplePage = useCallback(
        (search) =>
            dispatch(
                push({
                    search,
                    pathname: hasTeamIdUrlParam
                        ? generatePath(TEAMS_PEOPLE_PATH, { teamId })
                        : PEOPLE_PATH,
                })
            ),
        [dispatch, hasTeamIdUrlParam, teamId]
    );
    const barClicked = useCallback(
        ({ activeLabel }) => {
            const clickedBarStatuses = showLaunchBarChart
                ? LAUNCH_STATUS_BAR_CHART_ORDER_AND_GROUPING.find((x) => x.name === activeLabel)
                      ?.statuses ?? []
                : RENEWING_STATUS_BAR_CHART_ORDER_AND_GROUPING.find((x) => x.name === activeLabel)
                      ?.statuses ?? [];
            const statusStrings = clickedBarStatuses
                .map((x) => startCase(EnrollmentStatuses[x]))
                .join(',');
            const search = new URLSearchParams();
            search.set(ENROLLMENT_STATUS_PARAM, statusStrings);
            navigateToPeoplePage(search.toString());
        },
        [navigateToPeoplePage, showLaunchBarChart]
    );
    const pieClicked = useCallback(
        (_, index) => {
            const clickedGaugeSlice = gaugeData[index]?.queryParam;
            const search = new URLSearchParams();
            search.set(INFO_PARAM, clickedGaugeSlice as string);
            navigateToPeoplePage(search.toString());
        },
        [gaugeData, navigateToPeoplePage]
    );
    const OverrideActionButtons = useCallback(() => <TeamMemberActionButtons />, []);
    return (
        <React.Fragment>
            <Card className={hasTeamIdUrlParam ? 'mt-3' : undefined}>
                <CardContent>
                    <DashboardCardHeader
                        header="Team Members"
                        includeYearDropDown
                        onChange={onChange(setDashboardYear)}
                        OverrideActionButtons={
                            hasTeamIdUrlParam ? undefined : OverrideActionButtons
                        }
                        selectedYear={dashboardYear}
                    />
                    <hr />
                    <Skeleton count={1} height={350} isEnabled={isLoading}>
                        <Row>
                            <Col xs="12">
                                <ResponsiveContainer height={200}>
                                    <PieChart>
                                        <Tooltip />
                                        <Pie
                                            cursor="pointer"
                                            cy="66%"
                                            data={gaugeData}
                                            dataKey="value"
                                            endAngle={0}
                                            innerRadius={60}
                                            onClick={pieClicked}
                                            outerRadius={80}
                                            startAngle={180}
                                        >
                                            {gaugeData.map((_entry, index) => (
                                                <Cell fill={COLORS[index]} key={`cell-${index}`} />
                                            ))}
                                            <Label
                                                content={GaugeLabel}
                                                position="center"
                                                value={gaugeLabel}
                                                width={50}
                                            />
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>
                            {showBarChart && (
                                <Col xs="12">
                                    <ResponsiveContainer height={200}>
                                        <BarChart
                                            // This is a hack to get around bad typing in rechart library tracking issue here: https://github.com/recharts/recharts/issues/2484
                                            {...{ cursor: 'pointer' }}
                                            data={
                                                showLaunchBarChart
                                                    ? launchBarChartData
                                                    : renewingBarChartData
                                            }
                                            margin={{
                                                bottom: 45,
                                                left: 15,
                                                right: 15,
                                                top: 15,
                                            }}
                                            onClick={barClicked}
                                        >
                                            <XAxis
                                                dataKey="name"
                                                interval={0}
                                                minTickGap={15}
                                                tick={CustomizedAxisTick}
                                            />
                                            <Tooltip />
                                            <Bar dataKey="value" fill={theme.palette.primary.main}>
                                                <LabelList dataKey="value" position="top" />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Col>
                            )}
                        </Row>
                    </Skeleton>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default hot(module)(TeamMembersWidget);
