import React from 'react';
import { hot } from 'react-hot-loader';

const CitizenshipVerificationExamples = () => (
    <ul>
        <li>U.S. passport</li>
        <li>Certificate of Naturalization (N-550/N-570)</li>
        <li>Certificate of Citizenship (N-560/N-561)</li>
        <li>
            State-issued enhanced driver&apos;s license (available in Michigan, New York, Vermont,
            and Washington)
        </li>
        <li>
            Document from federally recognized Indian tribe that includes your name and the name of
            the federally recognized Indian tribe that issued the document, and shows your
            membership, enrollment, or affiliation with the tribe. Documents you can provide
            include:
            <ul>
                <li>A tribal enrollment card</li>
                <li>A Certificate of Degree of Indian Blood</li>
                <li>A tribal census document</li>
                <li>Documents on tribal letterhead signed by a tribal official</li>
            </ul>
        </li>
    </ul>
);

export default hot(module)(CitizenshipVerificationExamples);
