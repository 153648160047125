import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from 'components/Tooltip';
import useUserProps from 'hooks/useUserProps';
import React from 'react';
import { hot } from 'react-hot-loader';

const HrsIntegrationStatus = () => {
    const { user } = useUserProps();
    const isHrsAssociateIdSet = user?.isHrsAssociateIdSet === true;
    const toolTipText = isHrsAssociateIdSet
        ? 'Member has been successfully mapped to their HR provider'
        : 'Member has not been mapped to their HR provider. Ensure their configured email address matches their email address configured in their HR provider';
    return (
        <Tooltip title={toolTipText}>
            {isHrsAssociateIdSet ? <CheckIcon color="success" /> : <CloseIcon color="warning" />}
        </Tooltip>
    );
};
export default hot(module)(HrsIntegrationStatus);
