import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Tasks } from 'api/generated/enums';
import {
    IHouseholdMembersDto,
    IPersonalInfoDto,
    IPreferredProvidersDto,
    IPrescriptionsDto,
    IStringResponseDto,
    TaskDto,
} from 'api/generated/models';
import useUserProps from 'hooks/useUserProps';
import SummaryRowItem from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummaryRowItem';
import SummarySectionHeader from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummarySectionHeader';
import React from 'react';
import { hot } from 'react-hot-loader';
import { arrayHasValue, hasValue } from 'utilities/index';

const CoverageSection = ({ tasks }: { tasks: TaskDto[] }) => {
    const { userId } = useUserProps();
    const personalInfoTask = tasks.find((t) => t.taskId === Tasks.PersonalInfo);
    const personalInfoChunk = personalInfoTask?.response as IPersonalInfoDto;

    const hhmsTask = tasks.find((t) => t.taskId === Tasks.HouseholdMembers);
    const hhms = (hhmsTask?.response as IHouseholdMembersDto).householdMembers;

    const providersTask = tasks.find((t) => t.taskId === Tasks.PreferredProviders);
    const providersChunk = providersTask?.response as IPreferredProvidersDto;

    const rxTask = tasks.find((t) => t.taskId === Tasks.Prescriptions);
    const rxChunk = rxTask?.response as IPrescriptionsDto;

    const conditionsTask = tasks.find((t) => t.taskId === Tasks.CurrentHealthConditions);
    const conditionsChunk = conditionsTask?.response as IStringResponseDto;

    const getHhms = (entityIds?: string[]) => {
        let hhmsString = '';

        entityIds?.forEach((hhmId, index) => {
            if (hhmId === userId) {
                hhmsString += personalInfoChunk?.firstName;
                if (index !== entityIds.length - 1) {
                    hhmsString += ', ';
                }
            } else {
                const hhmName = hhms?.find((h) => h.householdMemberId === hhmId)?.firstName;
                hhmsString += hhmName;
                if (index !== entityIds.length - 1) {
                    hhmsString += ', ';
                }
            }
        });

        return hhmsString;
    };

    return (
        <Grid columnSpacing={12} container item rowSpacing={2} xs={12}>
            <Grid item xs={12}>
                <SummarySectionHeader hideEdit label="Coverage" />
                <Divider sx={{ sm: { mb: 1 }, xs: { mb: 0 } }} />
            </Grid>
            <Grid container direction="column" item mb={2} md={6} rowGap={1} xs={12}>
                <SummarySectionHeader
                    label="Preferred Providers"
                    taskId={providersTask?.globalId}
                    textVariantOverride="body1"
                />
                {arrayHasValue(providersChunk.providers) ? (
                    providersChunk.providers?.map((p) => (
                        <SummaryRowItem
                            content={getHhms(p.entityIds)}
                            key={p.nationalProviderId}
                            label={p.name as string}
                        />
                    ))
                ) : (
                    <Typography variant="body1">No preferred providers</Typography>
                )}
            </Grid>
            <Grid container direction="column" item mb={2} md={6} rowGap={1} xs={12}>
                <SummarySectionHeader
                    label="Prescriptions"
                    taskId={rxTask?.globalId}
                    textVariantOverride="body1"
                />
                {arrayHasValue(rxChunk.drugs) ? (
                    rxChunk.drugs?.map((d) => (
                        <SummaryRowItem
                            content={getHhms(d.entityIds)}
                            key={d.drugId}
                            label={d.name as string}
                        />
                    ))
                ) : (
                    <Typography variant="body1">No prescriptions</Typography>
                )}
            </Grid>
            <Grid container direction="column" item mb={2} md={6} rowGap={1} xs={12}>
                <SummarySectionHeader
                    label="Conditions"
                    taskId={conditionsTask?.globalId}
                    textVariantOverride="body1"
                />
                <Typography variant="body1">
                    {hasValue(conditionsChunk.value)
                        ? conditionsChunk.value
                        : 'No current health conditions'}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default hot(module)(CoverageSection);
