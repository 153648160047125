import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { IRhFile } from 'api/generated/models';
import TeamBenefitFile from 'pages/teamBenefits/TeamBenefitFile';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const TeamBenefitFilesList = ({
    files,
    teamBenefitId,
}: {
    files: IRhFile[];
    teamBenefitId: string | undefined;
}) => {
    const listItems = files.map((f) => (
        <TeamBenefitFile file={f} key={f.id} teamBenefitId={teamBenefitId} />
    ));
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Typography
                        color={(theme) => theme.palette.muted.main}
                        mb={0}
                        pb={1}
                        sx={{ borderBottom: 1, borderColor: '#98a6ad' }}
                        variant="h4"
                    >
                        Files
                    </Typography>
                </Col>
            </Row>
            <List>{listItems}</List>
        </React.Fragment>
    );
};

export default hot(module)(TeamBenefitFilesList);
