import { Card, CardContent } from '@mui/material';
import { TeamStateIds } from 'api/generated/enums';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Link } from 'react-router-dom';

const rhCareEmail = 'care@remodelhealth.com';
const rhLaunchEmail = 'launch@remodelhealth.com';

const launchTeamStateIds = [
    TeamStateIds.Prospect,
    TeamStateIds.Proposal,
    TeamStateIds.Inactive,
    TeamStateIds.RhLaunch,
    TeamStateIds.TeamAdminLaunch,
    TeamStateIds.MemberLaunch,
];

type IIssueCardProp = { teamStateId: TeamStateIds };
const IssueCard = ({ teamStateId }: IIssueCardProp) => {
    const rhEmail = launchTeamStateIds.includes(teamStateId) ? rhLaunchEmail : rhCareEmail;
    return (
        <Card>
            <CardContent>
                There is an issue with your account setup. Please reach out to{' '}
                <Link data-cy="mail-to-email" to={`mailto:${rhEmail}`}>
                    {rhEmail}
                </Link>{' '}
                and one of our team members will work to get this resolved.
            </CardContent>
        </Card>
    );
};

export default hot(module)(IssueCard);
