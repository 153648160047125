import { saveCompletedUserTask } from 'actions/task/saveCompletedUserTask';
import { completeMemberTaskList } from 'actions/user/completeMemberTaskList';
import {
    editSocialSecurityNumbers,
    IHouseholdSocialSecurityRequests,
} from 'actions/user/editSocialSecurityNumbers';
import { getUserProfile } from 'actions/user/getUserProfile';
import { TaskItems } from 'api/generated/enums';
import { ITaskItem } from 'api/generated/models';
import EnterSocialSecurityModal from 'components/EnterSocialSecurityModal';
import { taskListTypes } from 'constants/tasks';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import { shouldShowSocialSecurityTaskSelector } from 'pages/dashboard/taskListSelectors';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

type IEnterSocialSecurityTask = {
    acceptRHServicesTask: TaskItems;
    complete: boolean;
    completedTasks: ITaskItem[];
    enabled: boolean;
    taskItemId?: TaskItems;
};

const EnterSocialSecurityTask = ({
    acceptRHServicesTask,
    complete,
    enabled,
    taskItemId: taskToComplete = TaskItems.EnterSocialSecurityNumbers,
}: IEnterSocialSecurityTask) => {
    const dispatch = useThunkDispatch();
    const [showSsnModal, setShowSsnModal] = useState(false);
    const { memberVerifiedInfo, userId, year } = useUserProps();
    const { shouldShowTask } = useSelector((state: AppStore) => ({
        shouldShowTask: shouldShowSocialSecurityTaskSelector(
            acceptRHServicesTask,
            state,
            memberVerifiedInfo,
            year
        ),
    }));

    const showModal = useCallback(() => setShowSsnModal(true), []);
    const hideModal = useCallback(() => setShowSsnModal(false), []);
    const save = useCallback(
        async (socialSecurityRequests: IHouseholdSocialSecurityRequests) => {
            await dispatch(editSocialSecurityNumbers(userId, year, socialSecurityRequests));
            setShowSsnModal(false);
            await dispatch(saveCompletedUserTask(taskToComplete));
            if (
                [
                    TaskItems.EnterSocialSecurityNumbers,
                    TaskItems.EnterSocialSecurityNumbers_Renewing,
                ].includes(taskToComplete)
            ) {
                await dispatch(completeMemberTaskList(userId, year));
                await dispatch(getUserProfile(userId, true));
            }
        },
        [dispatch, taskToComplete, userId, year]
    );

    if (shouldShowTask || complete) {
        return (
            <TaskListItem
                action={showModal}
                buttonText="Add"
                complete={complete}
                data-cy="enter-social-security-task"
                description="We use social security numbers for healthcare applications."
                enabled={enabled}
                showButton={!complete}
                showButtonWhenComplete
                taskId={taskToComplete}
                taskType={taskListTypes.User}
                title="Enter Social Security Numbers"
            >
                {showSsnModal && (
                    <EnterSocialSecurityModal
                        onCancelClick={hideModal}
                        onHide={hideModal}
                        onSaveClick={save}
                    />
                )}
            </TaskListItem>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(EnterSocialSecurityTask);
