import { Savings } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';

const HsaEligibleChip = ({ isHsaEligible }: { isHsaEligible: boolean }) => {
    const label = isHsaEligible ? (
        <Typography fontSize="small" sx={{ alignItems: 'center', display: 'flex' }}>
            <Savings color="secondary" fontSize="small" />
            &nbsp;<span>HSA Eligible</span>
        </Typography>
    ) : (
        <Typography
            color={(theme) => theme.palette.grey[400]}
            fontSize="small"
            sx={{ alignItems: 'center', display: 'flex' }}
        >
            <Savings color="disabled" fontSize="small" />
            &nbsp;<span style={{ textDecoration: 'line-through' }}>HSA Eligible</span>
        </Typography>
    );
    return <Chip label={label} size="small" />;
};

export default hot(module)(HsaEligibleChip);
