import useDrugAutocomplete from 'components/creatableAutocomplete/useDrugAutocomplete';
import { UPDATE_SCROLL_GRADIENT_INDICATOR } from 'components/ScrollGradientIndicator';
import useThunkDispatch from 'hooks/useThunkDispatch';
import ListForm from 'pages/survey/coverageSteps/ListForm';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveCoverageForm } from 'pages/survey/surveyActions';
import React, { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import EventEmitter from 'utilities/eventEmitter';

const PrescriptionsForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { selectedDrugs } = useSelector((state: AppStore) => ({
        selectedDrugs: { ...state.surveyState.coverage }.selectedDrugs,
    }));
    const description = (
        <p>
            It’s important you add this information so we can find a plan that meets your needs.
            <br />
            <br />
            Whether specific prescriptions are covered can change, so we cannot guarantee their
            coverage. As best practice, we recommend checking with the plan carrier directly for
            prescription coverage.
        </p>
    );

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [selectedDrugs]);

    const save = useCallback(
        (newSelectedDrugs) => {
            dispatch(
                saveCoverageForm({
                    selectedDrugs: newSelectedDrugs,
                })
            );
        },
        [dispatch]
    );
    const creatableAutocompleteProps = useDrugAutocomplete({});
    return (
        <ListForm
            commonProps={commonProps}
            CreatableAutocompleteProps={creatableAutocompleteProps}
            description={description}
            keyProperty="rxcui"
            save={save}
            selectedData={selectedDrugs}
            title={
                <span>
                    List any <span className="text-primary">prescriptions</span> you want covered
                    for you or anyone in your household.
                </span>
            }
        >
            {children}
        </ListForm>
    );
};

export default hot(module)(PrescriptionsForm);
