import Stack from '@mui/material/Stack';
import { GET_PAYROLL_REPORTS_FOR_YEAR_ACTION } from 'actions/payroll/getPayrollReportsForYear';
import { REFRESH_PAYROLL_REPORT_ACTION } from 'actions/payroll/refreshPayrollReport';
import { HrsIntegrationProviders } from 'api/generated/enums';
import { IPayrollReportDto } from 'api/generated/models';
import IconTooltip from 'components/IconTooltip';
import { toOrdinal } from 'number-to-words';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { isCurrentOrFutureMonth } from 'utilities/dateTime';
import { getMonthName } from 'utilities/month';

type IDeductionDeadlineDayProps = {
    deadlineDay: number | undefined;
    hrisProvider: HrsIntegrationProviders | undefined;
    payrollReport: IPayrollReportDto | undefined;
};

const DeductionDeadlineDay = ({
    deadlineDay,
    payrollReport,
    hrisProvider,
}: IDeductionDeadlineDayProps) => {
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            GET_PAYROLL_REPORTS_FOR_YEAR_ACTION,
            REFRESH_PAYROLL_REPORT_ACTION
        ),
    }));

    const payrollSharedOrLocked = payrollReport?.isLocked ?? payrollReport?.isShared;
    const payrollCurrentOrInFuture =
        payrollReport?.month &&
        !payrollSharedOrLocked &&
        isCurrentOrFutureMonth(payrollReport.month, payrollReport.year);
    const showDeductionWarning =
        deadlineDay && payrollCurrentOrInFuture && hrisProvider === HrsIntegrationProviders.Finch;

    const month = showDeductionWarning && getMonthName(payrollReport?.month);
    const day = showDeductionWarning && toOrdinal(deadlineDay);

    return (
        <React.Fragment>
            {!isLoading && showDeductionWarning && (
                <Stack alignItems="center" direction="column" justifyContent="center">
                    <span style={{ marginBottom: '1.25rem' }}>
                        Set deductions by {month} {day}{' '}
                        <IconTooltip
                            icon="warning"
                            title={`Deductions can be set  until ${month} ${day}`}
                            variant="warning"
                        />
                    </span>
                </Stack>
            )}
        </React.Fragment>
    );
};

export default hot(module)(DeductionDeadlineDay);
