import SingleButtonModal from 'components/SingleButtonModal';
import React from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';

const RankCoverageInformationModal = ({ close }: { close: () => void }) => (
    <SingleButtonModal
        body={
            <Col>
                <div>
                    <strong className="text-secondary">A Name-Brand Carrier</strong> - A well-known
                    health insurance carrier like United Healthcare or Anthem.
                </div>
                <div>
                    <strong className="text-secondary">Copays</strong> - The amount you pay for
                    things like doctor visits or prescriptions.
                </div>
                <div>
                    <strong className="text-secondary">Low deductible</strong> - The amount you are
                    responsible to pay before the plan begins to pay for medical services.
                </div>
                <div>
                    <strong className="text-secondary">Low max out of pocket</strong> - The most you
                    would have to pay for medical services in one year.
                </div>
                <div>
                    <strong className="text-secondary">Monthly Cost For The Plan</strong> - The
                    amount you would pay each month for the plan.
                </div>
                <div>
                    <strong className="text-secondary">My Doctors In Network</strong> - Whether the
                    doctors you currently see are part of the plan&apos;s network of providers with
                    which it has negotiated a discount.
                </div>
            </Col>
        }
        buttonClickHandler={close}
        buttonText="Close"
        size="lg"
        title="Rank Coverage Definitions"
    />
);

export default hot(module)(RankCoverageInformationModal);
