import { clearPagedTeams, clearTeam, clearUsers } from 'actions/clear';
import {
    GET_FAVORITE_TEAMS_ACTION,
    getFavoriteTeams,
} from 'actions/favoriteTeams/getFavoriteTeams';
import {
    LIST_PAGED_TEAM_PROFILES_ACTION,
    listPagedTeamProfiles,
} from 'actions/team/listPagedTeamProfiles';
import { TeamStateIds } from 'api/generated/enums';
import { ITeamProfile } from 'api/generated/models';
import { CreateTeam } from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import PageSectionWrapper from 'components/PageSectionWrapper';
import TableHeader from 'components/TableHeader';
import { COMMON_HEADER_DROPDOWN_TABLE_PROPS } from 'components/headerDropdown/HeaderDropdown';
import ReactTable from 'components/reactTable/ReactTable';
import { ARCHIVED_TEAM_STATE, teamStateNames } from 'constants/teamStateIds';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { clearPeoplePagePaginationParams, setPeoplePageSearch } from 'pages/people/peopleActions';
import { setSelectedYear } from 'pages/profile/profileActions';
import {
    LocationCell,
    TeamActionsCell,
    TeamNameCell,
    TeamStatusHeader,
} from 'pages/teams/TeamsTableCells';
import {
    SET_TEAMS_PAGE_SEARCH,
    clearTeamsPagePaginationParams,
    setTeamsPagePaginationParams,
    setTeamsPageSearch,
} from 'pages/teams/teamsActions';
import { ITeamsState } from 'pages/teams/teamsState';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import {
    Cell,
    Column,
    FilterTypes,
    Row,
    TableRowProps,
    TableState,
    useAsyncDebounce,
} from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_ADD_PATH } from 'routers/routes';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { DEFAULT_ACTIONS_COLUMN_PROPS } from 'utilities/reactTable';

const TEAM_NAME_COLUMN_ID = 'teamName';
const TEAM_STATUS_COLUMN_ID = 'teamStatus';
const TEAM_STATUS_FILTER_ID = 'teamStatusFilter';
const DEBOUNCE_MILLISECONDS = 750;

export const FADED_OPACITY = 0.5;
const getRowProps = (row: Row<ITeamProfile>): TableRowProps => ({
    key: row.id,
    style: {
        opacity: row?.original.team?.isArchived ? FADED_OPACITY : 1,
    },
});

const initialFilters = [
    { id: TEAM_NAME_COLUMN_ID, value: '' },
    {
        id: TEAM_STATUS_COLUMN_ID,
        value: teamStateNames.filter((teamState) => teamState !== ARCHIVED_TEAM_STATE),
    },
];

const initialSortBy = [{ desc: false, id: TEAM_NAME_COLUMN_ID }];

const filterTypes: FilterTypes<ITeamProfile> = {
    [TEAM_STATUS_FILTER_ID]: (rows, _, filterValue) =>
        rows.filter(
            ({ original: { team } }) =>
                (!team?.isArchived &&
                    filterValue.includes(teamStateNames[(team?.teamStateId ?? NaN) - 1])) ||
                (team?.isArchived && filterValue.includes(ARCHIVED_TEAM_STATE))
        ),
};

const TeamsPage = () => {
    const dispatch = useThunkDispatch();
    const [pendingRequest, setPendingRequest] = useState<boolean>(false);
    const {
        enableCreateTeam,
        isRh,
        modalBody,
        onModalNoClick,
        onModalYesClick,
        paginationParams,
        search,
        shouldGetFavoriteTeams,
        showActivity,
        showConfirmationModal,
        showModalActivity,
        teamsProfiles,
    } = useSelector((state: AppStore) => ({
        enableCreateTeam: state.login.up.includes(CreateTeam),
        isRh: isRhSelector(state),
        modalBody: state.teamState.modalBody,
        onModalNoClick: state.teamState.noClickAction,
        onModalYesClick: state.teamState.yesClickAction,
        paginationParams: state.teamsState,
        search: state.teamsState.search,
        shouldGetFavoriteTeams:
            !hasCompletedRequest(state, GET_FAVORITE_TEAMS_ACTION) &&
            !hasApiActivity(state, GET_FAVORITE_TEAMS_ACTION),
        showActivity: hasApiActivity(state, LIST_PAGED_TEAM_PROFILES_ACTION, SET_TEAMS_PAGE_SEARCH),
        showConfirmationModal: state.teamState.showConfirmationModal,
        showModalActivity: hasApiActivity(state, ...state.teamState.apiActions),
        teamsProfiles: state.pagedTeamProfiles,
    }));
    const onSearchChange = useCallback((e) => dispatch(setTeamsPageSearch(e.target.value)), [
        dispatch,
    ]);
    const listPagedProfiles = useCallback((paginationParamsInternal: ITeamsState) => {
        setPendingRequest(false);
        return listPagedTeamProfiles(paginationParamsInternal);
    }, []);
    const debounceListPageProfiles = useAsyncDebounce(() => {
        dispatch(listPagedProfiles(paginationParams));
    }, DEBOUNCE_MILLISECONDS);

    useEffect(() => {
        setPendingRequest(true);
        debounceListPageProfiles();

        return () => {
            dispatch(clearPagedTeams());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceListPageProfiles, dispatch, search]);
    useEffect(() => {
        if (isRh && shouldGetFavoriteTeams) {
            dispatch(getFavoriteTeams());
        }
        dispatch(clearTeam());
        dispatch(clearUsers());
        dispatch(setPeoplePageSearch(''));
        dispatch(clearPeoplePagePaginationParams());
        dispatch(setSelectedYear(''));
    }, [dispatch, isRh, shouldGetFavoriteTeams]);
    useEffect(() => {
        dispatch(clearTeamsPagePaginationParams());
    }, [dispatch]);

    const onFetchData = (state: TableState<ITeamProfile>) => {
        dispatch(setTeamsPagePaginationParams(state));
        setPendingRequest(true);
        debounceListPageProfiles();
    };
    const columns: Column<ITeamProfile>[] = useMemo(
        () => [
            {
                accessor: (tp) => tp.team?.name,
                Cell: TeamNameCell,
                filter: 'text',
                Header: 'Team Name',
                id: TEAM_NAME_COLUMN_ID,
                width: 160,
            },
            {
                ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
                accessor: (tp) => tp.team?.teamStateId,
                Cell: ({ value }: Cell<ITeamProfile, TeamStateIds>) => teamStateNames[value - 1],
                filter: TEAM_STATUS_FILTER_ID,
                Header: TeamStatusHeader,
                id: TEAM_STATUS_COLUMN_ID,
                width: 120,
            },
            {
                accessor: (tp) => tp.address?.state,
                Cell: LocationCell,
                Header: 'Location',
                id: 'teamAddressState',
                width: 150,
            },
            {
                accessor: (tp: ITeamProfile) => tp.createdBy,
                Cell: ({ value }: Cell<ITeamProfile, string>) => value,
                Header: 'Created By',
                id: 'teamCreatedBy',
                width: 100,
            },
            {
                ...DEFAULT_ACTIONS_COLUMN_PROPS,
                Cell: TeamActionsCell,
                disableResizing: true,
                width: 120,
            },
        ],
        []
    );

    return (
        <PageSectionWrapper>
            <TableHeader
                items={
                    [
                        {
                            dataCy: 'add-team',
                            isVisible: enableCreateTeam,
                            link: { to: TEAMS_ADD_PATH },
                            text: 'Add Team',
                        },
                    ] as IActionButtonItems
                }
                onChange={onSearchChange}
                search={search}
            />
            <ReactTable
                columns={columns}
                data={teamsProfiles.results ?? []}
                filterTypes={filterTypes}
                getCustomRowProps={getRowProps}
                hideAllPageSizeOption
                initialFilters={initialFilters}
                initialSortBy={initialSortBy}
                loading={showActivity || pendingRequest}
                manualPagination
                onFetchData={onFetchData}
                pageSize={paginationParams.pageSize}
                rowsWhenNoData={paginationParams.pageSize}
                totalCount={teamsProfiles.totalCount}
            />
            {showConfirmationModal && (
                <ConfirmationModal
                    body={modalBody}
                    onNoClick={onModalNoClick}
                    onYesClick={onModalYesClick}
                    showActivity={showModalActivity}
                    title="Confirm"
                />
            )}
        </PageSectionWrapper>
    );
};

export default hot(module)(TeamsPage);
