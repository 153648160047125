import Stack from '@mui/material/Stack';
import {
    addCustomQuestion,
    ADD_CUSTOM_QUESTION_ACTION,
} from 'actions/customQuestions/addCustomQuestion';
import { editCustomQuestion } from 'actions/customQuestions/editCustomQuestion';
import { ICustomQuestion } from 'api/generated/models';
import Button from 'components/Button';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Form from 'components/Form';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

const MAX_QUESTION_TEXT_LENGTH = 4000;
const schema = object({
    text: string()
        .trim()
        .required()
        .max(MAX_QUESTION_TEXT_LENGTH)
        .label('Question Text'),
});

const getOrderOptions = (amount: number) =>
    [...Array(amount).keys()].map((x) => ({ value: x + 1 }));

type ICustomQuestionModalProps = {
    customQuestion?: ICustomQuestion;
    onClose: () => void;
    teamId: string;
};

const CustomQuestionModal = ({ customQuestion, onClose, teamId }: ICustomQuestionModalProps) => {
    const dispatch = useThunkDispatch();
    const { numberOfQuestions, isSaving } = useSelector((state: AppStore) => ({
        isSaving: hasApiActivity(state, ADD_CUSTOM_QUESTION_ACTION),
        numberOfQuestions: state.customQuestions.filter((q) => !q.isArchived).length,
    }));
    const [text, setText] = useState(customQuestion?.text ?? '');
    const [order, setOrder] = useState(customQuestion?.order ?? numberOfQuestions + 1);
    const { errors, validate } = useForm(schema);

    const submit = async () => {
        const { isValid } = await validate({ text });
        if (isValid) {
            const q: ICustomQuestion = customQuestion
                ? { ...customQuestion }
                : (({} as unknown) as ICustomQuestion);
            q.text = text;
            q.order = order;
            q.teamId = teamId;
            const action = q.id ? editCustomQuestion(q) : addCustomQuestion(q);
            await dispatch(action);
            onClose();
        }
    };

    const orderOptionsMax = customQuestion ? numberOfQuestions : numberOfQuestions + 1;

    const textArea = (
        <TextArea
            autoFocus
            disabled={customQuestion?.hasAnswers}
            errors={errors?.text}
            label="Question Text"
            name="text"
            onChange={onChange(setText)}
            placeholder="Enter question text"
            value={text}
        />
    );

    return (
        <Modal onHide={onClose} scrollable show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Custom Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="custom-question-modal-form" onSubmit={submit}>
                    <Stack gap={2}>
                        <ConditionalTooltip
                            isDisabled={!customQuestion?.hasAnswers}
                            title="Unable to edit question text as it has already received answers"
                        >
                            {textArea}
                        </ConditionalTooltip>
                        {!customQuestion?.isArchived && (
                            <Select
                                items={getOrderOptions(orderOptionsMax)}
                                label="Question Order"
                                onChange={onChange(setOrder)}
                                optionText="value"
                                optionValue="value"
                                value={order}
                            />
                        )}
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button disabled={isSaving} onClick={onClose}>
                    Cancel
                </Button>
                <Button form="custom-question-modal-form" isLoading={isSaving} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(CustomQuestionModal);
