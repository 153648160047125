import {
    CLEAR_PEOPLE_PAGE_PAGINATION_PARAMS,
    SET_PEOPLE_PAGE_PAGINATION_PARAMS,
    SET_PEOPLE_PAGE_SEARCH,
} from 'pages/people/peopleActions';
import { Ordering } from 'api/generated/models';
import { IAction } from 'actions/commonAction';
import { EnrollmentStatuses, UserInfoStatus, UserStatus } from 'api/generated/enums';

export type IPeopleState = {
    enrollmentStatusFilters: EnrollmentStatuses[];
    orderings: Ordering[];
    page: number;
    pageSize: number;
    search: string;
    userInfoStatusFilters: UserInfoStatus[];
    userStatusFilters: UserStatus[];
};

const initialState: IPeopleState = {
    enrollmentStatusFilters: [],
    orderings: [],
    page: 0,
    pageSize: 10,
    search: '',
    userInfoStatusFilters: [],
    userStatusFilters: [],
};

export const peopleState = (state = initialState, action: IAction<IPeopleState>): IPeopleState => {
    switch (action.type) {
        case SET_PEOPLE_PAGE_SEARCH:
        case SET_PEOPLE_PAGE_PAGINATION_PARAMS:
            return { ...state, ...action.data };
        case CLEAR_PEOPLE_PAGE_PAGINATION_PARAMS:
            return initialState;
        default:
            return state;
    }
};
