import { AddressTypes } from 'api/generated/enums';
import { IAddress } from 'api/generated/models';
import AddressModal from 'components/AddressModal';
import AddressInfo from 'pages/profile/AddressInfo';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { RouteComponentProps, withRouter } from 'react-router';
import { hasValue } from 'utilities';

type IEditableAddressAttribute = RouteComponentProps & {
    address: IAddress | undefined;
    deleteAddress?: (addressId: string, type: AddressTypes) => Promise<void>;
    isHouseholdMember?: boolean;
    readonly: boolean | undefined;
    saveAddress: (address: IAddress, type: AddressTypes) => Promise<void>;
    type: AddressTypes;
    year: number;
};

const EditableAddressAttribute = ({
    address,
    deleteAddress: parentDeleteAddress,
    readonly,
    saveAddress,
    type,
    year,
}: IEditableAddressAttribute) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const hideModal = useCallback(() => setIsModalVisible(false), []);
    const showModal = useCallback(() => setIsModalVisible(true), []);
    const save = useCallback(async (newAddress) => saveAddress(newAddress, type), [
        saveAddress,
        type,
    ]);
    const deleteAddress = useCallback(async (addressId) => parentDeleteAddress?.(addressId, type), [
        parentDeleteAddress,
        type,
    ]);

    const label = `${AddressTypes[type]} Address`;
    const addressLine2String = address?.addressLine2 ? address.addressLine2 + '\r\n' : '';
    const addressCopyText = hasValue(address)
        ? `${address.addressLine1}\r\n${addressLine2String}${address.city + ', '}${address.state} ${
              address.zip
          }\r\n${address.county}`
        : '';
    return (
        <React.Fragment>
            {isModalVisible && (
                <AddressModal
                    address={address}
                    canDelete={!!parentDeleteAddress}
                    deleteAddress={deleteAddress}
                    handleClose={hideModal}
                    save={save}
                    type={type}
                    year={year}
                />
            )}
            <EditableProfileAttribute
                content={
                    address || readonly ? (
                        <AddressInfo data={address} />
                    ) : (
                        <span className="text-muted">Set {label}</span>
                    )
                }
                label={label}
                onClick={showModal}
                readonly={readonly}
                textToCopy={addressCopyText}
            />
        </React.Fragment>
    );
};
export default hot(module)(withRouter(EditableAddressAttribute));
