import { Card, CardContent } from '@mui/material';
import Title from 'components/Title';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const AdvisementScheduledCard = () => (
    <Card>
        <CardContent>
            <Row className="align-items-center justify-content-space-between px-2">
                <Col>
                    <Title>Your Advisement Has Been Scheduled</Title>
                    <p className="my-0 text-muted">
                        Check your confirmation email from Calendly to see advisement date and time.
                    </p>
                </Col>
            </Row>
        </CardContent>
    </Card>
);

export default hot(module)(AdvisementScheduledCard);
