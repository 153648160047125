import { clearAncillaryBenefits } from 'actions/clear';
import {
    GET_PATHWAY_DATA_FOR_USER_ACTION,
    getPathwayDataForUser,
} from 'actions/pathwayBlueprint/getPathwayDataForUser';
import { LIST_VISIBLE_FLOWS_ACTION, listVisibleFlows } from 'actions/taskFlows/listVisibleFlows';
import { listAncillaryBenefits } from 'actions/teamBenefit/listAncillaryBenefits';
import {
    EnrollmentStatuses,
    PathwayTypes,
    PathwayUserTags,
    RenewalDecisions,
    SurveyTypes,
    TaskItems,
    TeamStateIds,
    UserStatus,
} from 'api/generated/enums';
import { IActiveWageUp, ITaskItem, IUser, YearlyUserInfoDto } from 'api/generated/models';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import AdvisementScheduledCard from 'pages/dashboard/AdvisementScheduledCard';
import AlertInfoCard from 'pages/dashboard/AlertInfoCard';
import { archiveAlert, inactiveAlert } from 'pages/dashboard/DashboardPage';
import EnrolledCard from 'pages/dashboard/EnrolledCard';
import EnrollmentDeadlinePassedCard from 'pages/dashboard/EnrollmentDeadlinePassedCard';
import PendingApplicationCard from 'pages/dashboard/PendingApplicationCard';
import PendingWageUpRequestCard from 'pages/dashboard/PendingWageUpRequestCard';
import ScheduleAdvisementCard from 'pages/dashboard/ScheduleAdvisementCard';
import ShopPlansCard from 'pages/dashboard/ShopPlansCard';
import WaivedCard from 'pages/dashboard/WaivedCard';
import AncillarySelectionContent from 'pages/dashboard/ancillaryBenefitSelection/AncillarySelectionContent';
import { completedTasksSelector } from 'pages/dashboard/dashboardSelectors';
import IchraEnrollmentTaskList from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskList';
import { getSupportedEnrollmentUserFlow } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import BenefitSelectionContent from 'pages/dashboard/medicalBenefitSelection/BenefitSelectionContent';
import {
    getIsViewActiveBenefitsVisible,
    shouldShowSocialSecurityTaskSelector,
} from 'pages/dashboard/taskListSelectors';
import IssueCard from 'pages/dashboard/taskLists/IssueCard';
import MemberMemberLaunchTaskList from 'pages/dashboard/taskLists/member/MemberMemberLaunchTaskList';
import MemberRenewalTaskList from 'pages/dashboard/taskLists/member/MemberRenewalTaskList';
import React, { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { isTeamAdminSelector } from 'selectors/role';
import { arrayHasValue, hasAnyFlag, hasValue, isFalse, isTrue } from 'utilities';
import { isTodayAfter } from 'utilities/moment';
import { isAnyWageUpPathwayType, isIchraPathwayType } from 'utilities/pathways';

const getIsScheduleAdvisementCardVisible = (
    pathwayType: PathwayTypes,
    yearlyUserInfo: YearlyUserInfoDto | undefined,
    activeWageUp: IActiveWageUp | undefined,
    user: IUser | undefined
) => {
    if (yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.AdvisementNeeded) {
        return true;
    }
    const isInBenefitsSelection =
        yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.BenefitsSelection;
    const isInPathway = ([PathwayTypes.Medicare, PathwayTypes.Medicaid, PathwayTypes.Custom] as (
        | PathwayTypes
        | undefined
    )[]).includes(pathwayType);
    const hasTagForWageUp = hasAnyFlag(
        activeWageUp?.tags ?? 0,
        PathwayUserTags.Medicaid,
        PathwayUserTags.Medicare,
        PathwayUserTags.MedicareHousehold,
        PathwayUserTags.CHIP,
        PathwayUserTags.Unclaimed,
        PathwayUserTags.MedicaidGap,
        PathwayUserTags.LeftMedicaidGap
    );
    const hasTagForReimbursement = hasAnyFlag(
        activeWageUp?.tags ?? 0,
        PathwayUserTags.Medicare,
        PathwayUserTags.MedicareHousehold
    );
    const hasTag = isIchraPathwayType(pathwayType) ? hasTagForReimbursement : hasTagForWageUp;
    const shoppingNotAllowedForFlatPathway =
        ([PathwayTypes.Flat, PathwayTypes.IchraFlat] as (PathwayTypes | undefined)[]).includes(
            pathwayType
        ) && !yearlyUserInfo?.allowShopping;
    return (
        isInBenefitsSelection &&
        (isInPathway || hasTag || shoppingNotAllowedForFlatPathway) &&
        user?.status === UserStatus.Launching
    );
};

const getIsShopCardVisible = (
    yearlyUserInfo: YearlyUserInfoDto | undefined,
    completedTasks: ITaskItem[] | undefined,
    hasWageUpPathway: boolean
) => {
    if (hasWageUpPathway) {
        const isInBenefitsSelectionAndChangeDecision =
            yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.BenefitsSelection &&
            yearlyUserInfo?.renewalDecision === RenewalDecisions.Change;
        const isKeepOrChangeTaskCompleted = completedTasks?.some(
            (x) => x.taskItemId === TaskItems.KeepOrChangeBenefits_Renewing
        );
        if (isInBenefitsSelectionAndChangeDecision && !isKeepOrChangeTaskCompleted) {
            return false;
        }
    }

    const noAcceptedPathwayAndNoRenewalDecision =
        isFalse(yearlyUserInfo?.acceptedPathway) && !hasValue(yearlyUserInfo?.renewalDecision);

    if (
        ![
            EnrollmentStatuses.BenefitsSelection,
            EnrollmentStatuses.AdvisementScheduled,
            EnrollmentStatuses.AdvisementNeeded,
            EnrollmentStatuses.PendingDecision,
        ].contains(yearlyUserInfo?.enrollmentStatus?.value)
    ) {
        return false;
    }
    return (
        noAcceptedPathwayAndNoRenewalDecision ||
        ([RenewalDecisions.Change, RenewalDecisions.ChoseNonPathwayPlan].contains(
            yearlyUserInfo?.renewalDecision
        ) &&
            completedTasks?.some((x) => x.taskItemId === TaskItems.ReviewMemberInfo_Renewing) &&
            isTrue(yearlyUserInfo?.allowShopping))
    );
};

const areTasksComplete = (taskIds: TaskItems[], completedTasks: ITaskItem[]) => {
    let completed = true;
    taskIds.forEach((taskId) => {
        completed = completed && completedTasks.some((x) => x.taskItemId === taskId);
    });

    return completed;
};

const MemberContent = () => {
    const dispatch = useThunkDispatch();
    const {
        enrollmentDeadline,
        hasTeamIdUrlParam,
        isTeamArchived,
        teamId,
        teamStateId,
        useIchraLaunchFlow,
    } = useTeamProps();
    const {
        activeWageUp,
        isUserInactive,
        memberVerifiedInfo,
        user,
        userId,
        yearlyUserInfo,
        year,
    } = useUserProps();
    const isLaunching = user?.status === UserStatus.Launching;
    const isRenewing = user?.status === UserStatus.Renewing;
    const isWaived = user?.status === UserStatus.Waived;
    const isIchra = memberVerifiedInfo?.surveyTypeCompleted === SurveyTypes.ICHRA;
    const {
        completedTasks,
        hasAncillaryBenefits,
        hasPathway,
        hasWageUpPathway,
        isLoading,
        isScheduleAdvisementCardVisible,
        isViewActiveBenefitsVisible,
        shouldRenderTeamAdminContent,
        shouldShowSocialSecurityTask,
        useRestrategizedRenewalFlow,
        userFlows,
    } = useSelector((state: AppStore) => ({
        completedTasks: completedTasksSelector(state),
        hasAncillaryBenefits: state.ancillaryBenefits?.length > 0,
        hasPathway: hasValue(state.pathwayDataForUser?.id),
        hasWageUpPathway: isAnyWageUpPathwayType(state.pathwayDataForUser?.pathwayType),
        isInWaivedPathway: state.pathwayDataForUser?.pathwayType === PathwayTypes.Waived,
        isLoading: hasApiActivity(
            state,
            GET_PATHWAY_DATA_FOR_USER_ACTION,
            LIST_VISIBLE_FLOWS_ACTION
        ),
        isScheduleAdvisementCardVisible: getIsScheduleAdvisementCardVisible(
            state.pathwayDataForUser.pathwayType,
            yearlyUserInfo,
            activeWageUp,
            user
        ),
        isViewActiveBenefitsVisible: getIsViewActiveBenefitsVisible(state),
        shouldRenderTeamAdminContent: !hasTeamIdUrlParam && isTeamAdminSelector(state),
        shouldShowSocialSecurityTask: shouldShowSocialSecurityTaskSelector(
            isLaunching ? TaskItems.AcceptRHServices : TaskItems.AcceptRHServices_Renewing,
            state,
            memberVerifiedInfo,
            year
        ),
        useRestrategizedRenewalFlow: state.pathwayDataForUser.useRestrategizedRenewalFlow,
        userFlows: state.userFlows,
    }));

    useEffect(() => {
        dispatch(getPathwayDataForUser(userId, user?.activeDate?.getYear()));
        dispatch(listVisibleFlows(userId));
    }, [dispatch, user?.activeDate, userId]);

    const hasCompletedMemberLaunchSetupTasks = useCallback(() => {
        const taskItems = [TaskItems.AcceptRHServices, TaskItems.ReviewMemberInfo];
        if (shouldShowSocialSecurityTask) {
            taskItems.push(TaskItems.EnterSocialSecurityNumbers);
        }
        return areTasksComplete(taskItems, completedTasks);
    }, [completedTasks, shouldShowSocialSecurityTask]);
    const hasCompletedMemberRenewalSetupTasks = useCallback(() => {
        const taskItems = [
            TaskItems.AcceptRHServices_Renewing,
            TaskItems.ReviewMemberInfo_Renewing,
        ];
        if (shouldShowSocialSecurityTask) {
            taskItems.push(TaskItems.EnterSocialSecurityNumbers_Renewing);
        }
        return areTasksComplete(taskItems, completedTasks);
    }, [completedTasks, shouldShowSocialSecurityTask]);

    const showAncillarySelection =
        ((hasCompletedMemberLaunchSetupTasks() && isLaunching) ||
            (hasCompletedMemberRenewalSetupTasks() && isRenewing)) &&
        ![EnrollmentStatuses.Waived, EnrollmentStatuses.Enrolled].contains(
            yearlyUserInfo?.enrollmentStatus?.value
        );
    useEffect(() => {
        dispatch(listAncillaryBenefits(userId, true));
        return () => {
            dispatch(clearAncillaryBenefits());
        };
    }, [dispatch, userId]);

    const showMemberLaunchTaskList =
        isLaunching &&
        [
            EnrollmentStatuses.AwaitingTaskList,
            EnrollmentStatuses.BenefitsSelection,
            EnrollmentStatuses.AdvisementNeeded,
            EnrollmentStatuses.AdvisementScheduled,
            EnrollmentStatuses.PendingDecision,
            EnrollmentStatuses.PendingApplication,
        ].contains(yearlyUserInfo?.enrollmentStatus?.value) &&
        ![
            TeamStateIds.Prospect,
            TeamStateIds.Proposal,
            TeamStateIds.RhLaunch,
            TeamStateIds.TeamAdminLaunch,
            TeamStateIds.ReadyForMemberLaunch,
        ].includes(teamStateId);
    const showIchraUserTaskFlow =
        useIchraLaunchFlow &&
        arrayHasValue(userFlows) &&
        getSupportedEnrollmentUserFlow(userFlows)?.isVisible === true;
    const showRenewalTaskList =
        (isRenewing &&
            [
                EnrollmentStatuses.ReadyForRenewal,
                EnrollmentStatuses.InvitedToRenewal,
                EnrollmentStatuses.AwaitingTaskList,
                EnrollmentStatuses.BenefitsSelection,
                EnrollmentStatuses.AdvisementNeeded,
                EnrollmentStatuses.AdvisementScheduled,
                EnrollmentStatuses.PendingDecision,
                EnrollmentStatuses.PendingApplication,
            ].contains(yearlyUserInfo?.enrollmentStatus?.value)) ||
        (isWaived && yearlyUserInfo?.enrollmentStatus?.value !== EnrollmentStatuses.Waived);
    const showShopCard = getIsShopCardVisible(yearlyUserInfo, completedTasks, hasWageUpPathway);
    const shouldShowWaivedCard =
        yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.Waived;

    const isLaunchingOrRenewing = isLaunching || isRenewing;
    const hasActiveContribution =
        hasValue(activeWageUp) &&
        [TeamStateIds.MemberLaunch, TeamStateIds.Customer, TeamStateIds.Renewing].includes(
            teamStateId
        );

    const advisementNeededOrAdvisementScheduled = [
        EnrollmentStatuses.AdvisementScheduled,
        EnrollmentStatuses.AdvisementNeeded,
    ].contains(yearlyUserInfo?.enrollmentStatus?.value);

    const showIssueCard =
        !advisementNeededOrAdvisementScheduled &&
        isLaunchingOrRenewing &&
        (!hasPathway || !hasActiveContribution || (isIchra && hasWageUpPathway));

    const showEnrollmentDeadlinePassed =
        hasValue(enrollmentDeadline) &&
        isTodayAfter(enrollmentDeadline, 'day') &&
        [
            EnrollmentStatuses.ReadyForLaunch,
            EnrollmentStatuses.InvitedToLaunch,
            EnrollmentStatuses.AwaitingTaskList,
            EnrollmentStatuses.PendingDecision,
            EnrollmentStatuses.BenefitsSelection,
            EnrollmentStatuses.ReadyForRenewal,
            EnrollmentStatuses.InvitedToRenewal,
        ].contains(yearlyUserInfo?.enrollmentStatus?.value);

    const content = [];
    if (!isLoading) {
        if (showIssueCard) {
            return <IssueCard teamStateId={teamStateId} />;
        }
        if (isTeamArchived) {
            return !shouldRenderTeamAdminContent ? (
                <AlertInfoCard alerts={[archiveAlert]} />
            ) : (
                <React.Fragment />
            );
        }
        if (isUserInactive) {
            return <AlertInfoCard alerts={[inactiveAlert]} />;
        }
        if (yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.Enrolled) {
            return <EnrolledCard />;
        }

        if (showEnrollmentDeadlinePassed) {
            return <EnrollmentDeadlinePassedCard />;
        }
        if (
            yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.PendingApplication &&
            !(isRenewing && isViewActiveBenefitsVisible)
        ) {
            content.unshift(<PendingApplicationCard key="pendingApplicationCard" />);
        }
        if (showIchraUserTaskFlow) {
            content.push(
                <IchraEnrollmentTaskList
                    key="memberLaunchTaskList"
                    teamId={teamId}
                    userId={userId}
                />
            );
        }
        if (showMemberLaunchTaskList && !showIchraUserTaskFlow) {
            content.push(
                <MemberMemberLaunchTaskList
                    completedTasks={completedTasks}
                    key="memberLaunchTaskList"
                    teamId={teamId}
                />
            );
        }
        if (showRenewalTaskList && !showIchraUserTaskFlow) {
            content.push(
                <MemberRenewalTaskList
                    completedTasks={completedTasks}
                    key="memberRenewalTaskList"
                    teamId={teamId}
                />
            );
        }
        if (yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.PendingWageUpRequest) {
            content.push(<PendingWageUpRequestCard key="pendingWageUpRequestCard" />);
        }
        if (yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.AdvisementScheduled) {
            content.push(<AdvisementScheduledCard key="advisementScheduledCard" />);
        } else if (!showIchraUserTaskFlow) {
            if (isScheduleAdvisementCardVisible) {
                content.push(<ScheduleAdvisementCard key="scheduledAdvisementCard" />);
            } else if (
                [
                    EnrollmentStatuses.BenefitsSelection,
                    EnrollmentStatuses.PendingDecision,
                    EnrollmentStatuses.PendingApplication,
                ].contains(yearlyUserInfo?.enrollmentStatus?.value) &&
                (isLaunching || (isRenewing && useRestrategizedRenewalFlow))
            ) {
                content.push(<BenefitSelectionContent key="benefitSelectionContent" />);
            } else if (shouldShowWaivedCard) {
                content.push(<WaivedCard key="waivedCard" />);
            }
            if (hasAncillaryBenefits && showAncillarySelection) {
                content.push(<AncillarySelectionContent key="ancillarySelectionContent" />);
            }
            if (showShopCard) {
                content.push(<ShopPlansCard key="shopPlansCard" />);
            }
        }
    }
    return <React.Fragment>{content}</React.Fragment>;
};

export default hot(module)(withRouter(MemberContent));
