import useTheme from '@mui/material/styles/useTheme';
import { clearApiActivity, clearSubmittedExpenses } from 'actions/clear';
import {
    LIST_SUBMITTED_EXPENSES_ACTION,
    listSubmittedExpenses,
} from 'actions/submittedExpenses/listSubmittedExpenses';
import { ExpenseStatus } from 'api/generated/enums';
import { IBasicSubmittedExpenseDto } from 'api/generated/models';
import { ViewSubmittedExpenses } from 'api/generated/permissions';
import ReactTable, { IReactTableProps } from 'components/reactTable/ReactTable';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import startCase from 'lodash/startCase';
import {
    MemberNameCell,
    TeamNameCell,
} from 'pages/reimbursementExpenseManagement/ReimbursementExpenseManagementCells';
import SubmittedExpenseModal, {
    SUBMITTED_EXPENSE_MODAL_PARAM,
} from 'pages/reimbursementExpenseManagement/SubmittedExpenseModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Column, TableState, useAsyncDebounce } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';

const MEMBER_NAME_COLUMN_ID = 'member-name';
const TEAM_NAME_COLUMN_ID = 'team-name';
const FADED_OPACITY = 0.5;

type IReimbursementExpenseManagementTableProps = {
    location: 'global' | 'team' | 'user';
    search: string;
    showArchived: boolean;
    showMemberNameColumn: boolean;
    showTeamNameColumn: boolean;
};

const handleYearChange = (
    selectedYear: string,
    teamId: string | undefined,
    userId: string | undefined,
    isRh: boolean
) => {
    if (hasValue(selectedYear) && (!isRh || hasValue(teamId) || hasValue(userId))) {
        return Number(selectedYear);
    } else {
        return undefined;
    }
};

const ReimbursementExpenseManagementTable = ({
    location,
    search,
    showArchived,
    showMemberNameColumn,
    showTeamNameColumn,
}: IReimbursementExpenseManagementTableProps) => {
    const dispatch = useThunkDispatch();
    const theme = useTheme();
    const { userId } = useUserProps();
    const { hasTeamIdUrlParam, teamId } = useTeamProps();

    const isGlobal = location === 'global';
    const isTeamPage = location === 'team';

    const {
        hasCompletedInitialRequest,
        isLoading,
        isRh,
        selectedYear,
        submittedExpenses,
    } = useSelector((state: AppStore) => ({
        canViewSubmittedExpenses: hasSomePermissions(state, ViewSubmittedExpenses),
        hasCompletedInitialRequest: hasCompletedRequest(state, LIST_SUBMITTED_EXPENSES_ACTION),
        isLoading: hasApiActivity(state, LIST_SUBMITTED_EXPENSES_ACTION),
        isRh: isRhSelector(state),
        selectedYear: state.profileState.selectedYear,
        submittedExpenses: state.submittedExpenses,
    }));
    const year = handleYearChange(selectedYear, teamId, userId, isRh);

    const showTable = !hasTeamIdUrlParam || (hasTeamIdUrlParam && hasValue(selectedYear));

    const {
        closeModal: closeSubmittedExpenseModal,
        isVisible: isSubmittedExpenseVisible,
        openModalWithData: openSubmittedExpenseModalWithData,
    } = useModalState<IBasicSubmittedExpenseDto>({
        key: SUBMITTED_EXPENSE_MODAL_PARAM,
        selectedItemKeyForValue: 'globalId',
    });

    const [hoveredRow, setHoveredRow] = useState<string>();
    const [tableState, setTableState] = useState<
        Partial<Pick<TableState<IBasicSubmittedExpenseDto>, 'pageIndex' | 'pageSize'>>
    >({ pageSize: 50 });

    const getSubmittedExpenses = useCallback(
        (pageIndex: number | undefined, pageSize: number | undefined) =>
            listSubmittedExpenses(
                isGlobal ? undefined : teamId,
                isGlobal || isTeamPage ? undefined : userId,
                isGlobal ? undefined : year,
                pageIndex,
                pageSize,
                search,
                showArchived
            ),
        [isGlobal, isTeamPage, search, showArchived, teamId, userId, year]
    );

    const debouncedListSubmittedExpenses = useAsyncDebounce(() => {
        const { pageIndex, pageSize } = tableState;
        dispatch(getSubmittedExpenses(pageIndex, pageSize));
    }, 500);
    useEffect(() => {
        if (hasCompletedInitialRequest) {
            debouncedListSubmittedExpenses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, showArchived, search, year]);

    useEffect(
        () => () => {
            dispatch(clearApiActivity());
            dispatch(clearSubmittedExpenses());
        },
        [dispatch]
    );

    const onFetchData = (state: TableState<IBasicSubmittedExpenseDto>) => {
        setTableState(state);
        const { pageIndex, pageSize } = state;
        dispatch(getSubmittedExpenses(pageIndex, pageSize));
    };

    const columns = useMemo<Column<IBasicSubmittedExpenseDto>[]>(() => {
        const cols: Column<IBasicSubmittedExpenseDto>[] = [];
        if (showMemberNameColumn) {
            cols.push({
                accessor: ({ memberName }) => memberName,
                Cell: MemberNameCell,
                disableSortBy: true,
                Header: 'Member Name',
                id: MEMBER_NAME_COLUMN_ID,
            });
        }
        if (showTeamNameColumn) {
            cols.push({
                accessor: ({ teamName }) => teamName,
                Cell: TeamNameCell,
                disableSortBy: true,
                filter: 'text',
                Header: 'Team Name',
                id: TEAM_NAME_COLUMN_ID,
            });
        }
        cols.push(
            {
                accessor: ({ submittedDate }) => formatDateForDisplay(submittedDate),
                disableSortBy: true,
                Header: 'Date Submitted',
            },
            {
                accessor: ({ dateOfService }) => formatDateForDisplay(dateOfService),
                disableSortBy: true,
                Header: 'Date of Service',
            },
            {
                accessor: ({ expenseType }) => expenseType,
                disableSortBy: true,
                Header: 'Expense Type',
            },
            {
                accessor: ({ expenseStatus }) => startCase(ExpenseStatus[expenseStatus]),
                disableSortBy: true,
                Header: 'Status',
            },
            {
                accessor: ({ expenseId }) => expenseId,
                disableSortBy: true,
                Header: 'Expense ID',
            }
        );

        return cols;
    }, [showMemberNameColumn, showTeamNameColumn]);

    const getCustomRowProps: IReactTableProps<IBasicSubmittedExpenseDto>['getCustomRowProps'] = (
        rowInfo
    ) => ({
        key: rowInfo.id,
        onClick: openSubmittedExpenseModalWithData(rowInfo.original),
        onMouseEnter: () => {
            setHoveredRow(rowInfo.id);
        },
        onMouseLeave: () => {
            setHoveredRow(undefined);
        },
        style: {
            background: rowInfo.id === hoveredRow ? theme.palette.grey[200] : 'white',
            cursor: 'pointer',
            opacity: rowInfo?.original.isArchived ? FADED_OPACITY : 1,
        },
    });

    return (
        <React.Fragment>
            {isSubmittedExpenseVisible && (
                <SubmittedExpenseModal onClose={closeSubmittedExpenseModal} />
            )}
            {showTable && (
                <ReactTable
                    columns={columns}
                    data={submittedExpenses.results ?? []}
                    data-cy="reimbursement-expense-management-table"
                    getCustomRowProps={getCustomRowProps}
                    hideAllPageSizeOption
                    loading={isLoading || !hasCompletedInitialRequest}
                    manualPagination
                    onFetchData={onFetchData}
                    pageSize={tableState.pageSize}
                    rowsWhenNoData={tableState.pageSize}
                    totalCount={submittedExpenses.totalCount}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(ReimbursementExpenseManagementTable);
