import TextArea from 'components/TextArea';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveQualitative } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

const FeedbackForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { feedback } = useSelector((state: AppStore) => ({
        feedback: state.surveyState.qualitative.feedback,
    }));
    const { anyFeedback, improveHealthInsurance, likeCurrentInsurance } = feedback;

    const onChange = useCallback(
        async ({ target: { name, value } }) =>
            dispatch(saveQualitative({ feedback: { ...feedback, [name]: value } })),
        [dispatch, feedback]
    );
    const isDisabled = [likeCurrentInsurance, improveHealthInsurance, anyFeedback].some(
        (x) => !hasValue(x)
    );
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled}>
            {children}
            <FormTitle>Let us know what you think about your benefits</FormTitle>
            <Row className="justify-content-center">
                <Col md="7">
                    <TextArea
                        autoFocus
                        className="px-1 mt-4"
                        data-cy="like-current-health-insurance"
                        InputLabelProps={{ shrink: true }}
                        label="Like Current Health Insurance"
                        minRows={3}
                        name="likeCurrentInsurance"
                        onChange={onChange}
                        placeholder="What do you like about your current health insurance?"
                        value={likeCurrentInsurance}
                    />
                </Col>
                <Col md="7">
                    <TextArea
                        className="px-1 mt-4"
                        data-cy="improve-health-insurance"
                        InputLabelProps={{ shrink: true }}
                        label="Improve Current Health Insurance"
                        minRows={3}
                        name="improveHealthInsurance"
                        onChange={onChange}
                        placeholder="What would you like to see improved about your current health insurance?"
                        value={improveHealthInsurance}
                    />
                </Col>
                <Col md="7">
                    <TextArea
                        className="px-1 my-4"
                        data-cy="any-feedback"
                        InputLabelProps={{ shrink: true }}
                        label="Your Benefits"
                        minRows={3}
                        name="anyFeedback"
                        onChange={onChange}
                        placeholder="Please provide any feedback you think would be helpful."
                        value={anyFeedback}
                    />
                </Col>
            </Row>
        </SurveyFormWrapper>
    );
};

export default hot(module)(FeedbackForm);
