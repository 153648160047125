import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const PeaceCorpsCoverageStatus = () => (
    <React.Fragment>
        <p>
            Submit this document if you need to confirm that you don’t have coverage through the
            Peace Corps:
        </p>
        <ul>
            <li>
                Letter from the Peace Corps with the expiration date for any previous health
                coverage or a letter showing that you never had this type of coverage
            </li>
        </ul>
        <p>
            <strong>If you don’t have this document</strong>, you can submit a letter describing
            that you’re no longer eligible for or enrolled in health coverage through the Peace
            Corps, or that you were never eligible for or enrolled in health coverage through the
            Peace Corps. The Marketplace will take your letter into consideration.{' '}
            <ExternalLink href="https://www.healthcare.gov/downloads/letter-of-explanation-application-info.pdf">
                You can use this template
            </ExternalLink>
            .
        </p>
    </React.Fragment>
);

export default hot(module)(PeaceCorpsCoverageStatus);
