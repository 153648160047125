import { HouseholdMemberTypes } from 'api/generated/enums';
import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import SpouseSameEmployerForm from 'pages/survey/coverageSteps/SpouseSameEmployerForm';
import HouseholdIntro from 'pages/survey/householdSteps/HouseholdIntro';
import HouseholdMemberForm from 'pages/survey/householdSteps/HouseholdMemberForm';
import SpouseForm from 'pages/survey/householdSteps/SpouseForm';
import StepWrapper, { IStepProps } from 'pages/survey/StepWrapper';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasMemberType } from 'utilities/household';

const IchraHouseholdMemberForm = (commonProps: IStepProps) => (
    <HouseholdMemberForm householdMemberType={HouseholdMemberTypes.IchraChild} {...commonProps} />
);

const getStepsToRender = (householdMembers: ISurveyHouseholdMemberDto[] | undefined) => {
    const hasSpouse = hasMemberType(householdMembers, HouseholdMemberTypes.Spouse);
    const forms = [SpouseForm, IchraHouseholdMemberForm];
    if (hasSpouse) {
        forms.push(SpouseSameEmployerForm);
    }
    return forms;
};

const HouseholdWrapper = () => {
    const { householdMembers } = useSelector((state: AppStore) => ({
        householdMembers: state.surveyState.household.members,
    }));
    const stepsToRender = useMemo(() => getStepsToRender(householdMembers), [householdMembers]);
    return <StepWrapper Intro={HouseholdIntro} stepsToRender={stepsToRender} />;
};

export default hot(module)(HouseholdWrapper);
