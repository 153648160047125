import { IMemberVerifiedInfo } from 'api/generated/models';
import EditableCurrencyAttribute from 'components/EditableCurrencyAttribute';
import useUserProps from 'hooks/useUserProps';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type IIncomeAdminInfoProps = {
    verifiedInfoCommonProps: ICommonProps<IMemberVerifiedInfo>;
};
const IncomeAdminInfo = ({ verifiedInfoCommonProps }: IIncomeAdminInfoProps) => {
    const { userProfile } = useUserProps();
    const [employeeIncome, setEmployeeIncome] = useState(
        userProfile?.employeeAnnualIncome?.toString()
    );
    return (
        <EditableCurrencyAttribute
            {...verifiedInfoCommonProps}
            label="Employee Salary"
            name="employeeIncome"
            onChange={onChange(setEmployeeIncome)}
            validationSchema={string()
                .trim()
                .required()
                .label('Employee Salary')}
            value={employeeIncome}
        />
    );
};

export default hot(module)(IncomeAdminInfo);
