import Box from '@mui/material/Box';
import {
    ADD_HOUSEHOLD_MEMBER_ACTION,
    addHouseholdMember,
} from 'actions/householdMember/addHouseholdMember';
import { getPathwayBlueprintDataForUser } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { getTaxData } from 'actions/taxData/getTaxData';
import { getYearlyUserInfo } from 'actions/user/getYearlyUserInfo';
import { HouseholdMemberTypes, OtherCoverageEligibilities, SurveyTypes } from 'api/generated/enums';
import { IHouseholdMemberDto } from 'api/generated/models';
import { ViewPathwayBlueprints, ViewTaxData } from 'api/generated/permissions';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import Select from 'components/Select';
import TextField from 'components/TextField';
import GENDERS from 'constants/genders';
import useContributionProps from 'hooks/useContributionProps';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { genderList, yesOrNo } from 'reducers/options';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isTrue, stringToFloat, toUndefinedOrBoolean } from 'utilities';
import { onChange } from 'utilities/forms';
import { boolean, number, object, string } from 'yup';

const ONE_HUNDRED = 100;
const schema = (isIchraSurvey: boolean, gender: string, isUnclaimed: boolean) =>
    object({
        dateOfBirth: string()
            .required()
            .isValidDate()
            .label('Date of Birth'),
        firstName: string()
            .trim()
            .required()
            .max(ONE_HUNDRED)
            .label('First Name'),
        gender: string()
            .required()
            .label('Gender'),
        hasReportableIncome: boolean()
            .transform(toUndefinedOrBoolean)
            .label('Has Reportable Income')
            .isRequiredWhen(!isIchraSurvey && !isUnclaimed),
        income: number()
            .transform(stringToFloat)
            .min(0)
            .label('Income'),
        isEligibleForOtherCoverage: string()
            .label('Eligible Other Coverage')
            .isRequiredWhen(!isIchraSurvey),
        isPregnant: boolean()
            .transform(toUndefinedOrBoolean)
            .label('Is Pregnant')
            .isRequiredWhen(!isIchraSurvey && gender === GENDERS.FEMALE),
        lastName: string()
            .trim()
            .required()
            .max(ONE_HUNDRED)
            .label('Last Name'),
        needsCoverage: boolean()
            .transform(toUndefinedOrBoolean)
            .required()
            .label('Needs Major Medical Coverage'),
        usesTobacco: boolean()
            .transform(toUndefinedOrBoolean)
            .required()
            .label('Tobacco User'),
    });

type IHouseholdMemberModalProps = {
    handleClose: (
        shouldOpenIncomeModal?: React.MouseEvent<HTMLButtonElement, MouseEvent> | boolean
    ) => void;
    householdMemberTypeId: HouseholdMemberTypes | undefined;
};

const HouseholdMemberModal = ({
    handleClose,
    householdMemberTypeId,
}: IHouseholdMemberModalProps) => {
    const dispatch = useThunkDispatch();
    const isSpouse = householdMemberTypeId === HouseholdMemberTypes.Spouse;
    const isUnclaimed = householdMemberTypeId === HouseholdMemberTypes.Unclaimed;
    const isIchraChild = householdMemberTypeId === HouseholdMemberTypes.IchraChild;
    const { user, userId } = useUserProps();
    const isIchraSurvey = user?.surveyTypeToSend === SurveyTypes.ICHRA;
    const { hasAnyContributionInfo } = useContributionProps();
    const { canViewPathwayBlueprints, canViewTaxData, isLoading, selectedYear } = useSelector(
        (state: AppStore) => ({
            canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
            canViewTaxData: hasSomePermissions(state, ViewTaxData),
            isLoading: hasApiActivity(state, ADD_HOUSEHOLD_MEMBER_ACTION),
            selectedYear: +state.profileState.selectedYear,
        })
    );

    const [dateOfBirth, setDateOfBirth] = useState('');
    const [firstName, setFirstName] = useState('');
    const [gender, setGender] = useState('');
    const [hasReportableIncome, setHasReportableIncome] = useState(isUnclaimed ? 'false' : '');
    const [income, setIncome] = useState('');
    const [isEligibleForOtherCoverage, setIsEligibleForOtherCoverage] = useState('');
    const [isPregnant, setIsPregnant] = useState('');
    const [lastName, setLastName] = useState('');
    const [needsCoverage, setNeedsCoverage] = useState('');
    const [usesTobacco, setUsesTobacco] = useState('');
    const { errors, validate } = useForm(schema(isIchraSurvey, gender, isUnclaimed));

    const handleIncome = () => (
        <React.Fragment>
            {isUnclaimed ? (
                <CurrencyTextField
                    errors={errors?.income}
                    label="Income"
                    onChange={onChange(setIncome)}
                    value={income}
                />
            ) : (
                <Select
                    {...commonYesNoProps(setHasReportableIncome)}
                    errors={errors?.hasReportableIncome}
                    label="Has reportable income?"
                    name="hasReportableIncome"
                    value={hasReportableIncome}
                />
            )}
        </React.Fragment>
    );

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            let otherCoverageEligibilities = OtherCoverageEligibilities.None;
            if (isTrue(isEligibleForOtherCoverage)) {
                otherCoverageEligibilities = OtherCoverageEligibilities.DependentEmployer;
                if (isSpouse) {
                    otherCoverageEligibilities = OtherCoverageEligibilities.Parent;
                }
            }
            const { data, isValid } = await validate({
                dateOfBirth,
                firstName,
                gender,
                householdMemberTypeId,
                lastName,
                otherCoverageEligibilities,
                userId,
                hasReportableIncome: (hasReportableIncome as unknown) as boolean | undefined,
                income: (income as unknown) as number | undefined,
                isEligibleForOtherCoverage: (isEligibleForOtherCoverage as unknown) as string,
                isPregnant: (isPregnant as unknown) as boolean | undefined,
                needsCoverage: (needsCoverage as unknown) as boolean,
                usesTobacco: (usesTobacco as unknown) as boolean,
                year: selectedYear,
            });
            if (isValid) {
                await dispatch(
                    addHouseholdMember(
                        (data as Partial<IHouseholdMemberDto>) as IHouseholdMemberDto
                    )
                );
                handleClose(isTrue(hasReportableIncome));
                if (hasAnyContributionInfo && canViewPathwayBlueprints) {
                    await dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
                }
                if (canViewTaxData) {
                    dispatch(getTaxData(userId, selectedYear));
                }
                await dispatch(getYearlyUserInfo(userId, selectedYear));
            }
        },
        [
            isEligibleForOtherCoverage,
            validate,
            dateOfBirth,
            firstName,
            gender,
            householdMemberTypeId,
            lastName,
            userId,
            hasReportableIncome,
            income,
            isPregnant,
            needsCoverage,
            usesTobacco,
            selectedYear,
            isSpouse,
            dispatch,
            handleClose,
            hasAnyContributionInfo,
            canViewPathwayBlueprints,
            canViewTaxData,
        ]
    );

    const commonYesNoProps = useCallback(
        (setState) => ({
            defaultText: '--',
            defaultValue: '',
            items: yesOrNo,
            onChange: onChange(setState),
            optionText: 'text' as const,
            optionValue: 'value' as const,
        }),
        []
    );
    const text = useMemo(() => {
        if (isSpouse) {
            return 'Add Spouse';
        } else if (isUnclaimed) {
            return 'Add Unclaimed Household Member';
        } else if (isIchraChild) {
            return 'Add Child';
        } else {
            return 'Add Dependent';
        }
    }, [isIchraChild, isSpouse, isUnclaimed]);

    const isFemale = gender === GENDERS.FEMALE && !isIchraSurvey;
    return (
        <Modal onHide={handleClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>{text}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="household-member-modal-form"
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gap: 2,
                            gridTemplateColumns: { sm: '1fr 1fr' },
                        }}
                    >
                        <TextField
                            autoFocus
                            errors={errors?.firstName}
                            label="First Name"
                            name="firstName"
                            onChange={onChange(setFirstName)}
                            placeholder="Enter a first name"
                            value={firstName}
                        />
                        <TextField
                            errors={errors?.lastName}
                            label="Last Name"
                            name="lastName"
                            onChange={onChange(setLastName)}
                            placeholder="Enter a last name"
                            value={lastName}
                        />
                        <DateTextField
                            errors={errors?.dateOfBirth}
                            label="Date of Birth"
                            onChange={onChange(setDateOfBirth)}
                            value={dateOfBirth}
                        />
                        <Select
                            defaultText="Select a gender"
                            defaultValue=""
                            errors={errors?.gender}
                            items={genderList}
                            label="Gender"
                            name="gender"
                            onChange={onChange(setGender)}
                            optionText="value"
                            optionValue="id"
                            value={gender}
                        />
                        {!isIchraSurvey && (
                            <Select
                                {...commonYesNoProps(setIsEligibleForOtherCoverage)}
                                errors={errors?.isEligibleForOtherCoverage}
                                label={
                                    isSpouse ? 'Parent Other Coverage' : 'Employer Other Coverage'
                                }
                                name="isEligibleForOtherCoverage"
                                value={isEligibleForOtherCoverage}
                            />
                        )}
                        <Select
                            {...commonYesNoProps(setUsesTobacco)}
                            errors={errors?.usesTobacco}
                            helperText="Has used tobacco four or more times per week for the past six months."
                            label="Tobacco User"
                            name="usesTobacco"
                            value={usesTobacco}
                        />
                        <Select
                            {...commonYesNoProps(setNeedsCoverage)}
                            errors={errors?.needsCoverage}
                            helperText="Whether this member of the household needs major medical benefits managed via Remodel Health."
                            label="Needs Major Medical Coverage"
                            name="needsCoverage"
                            value={needsCoverage}
                        />
                        {isFemale && (
                            <Select
                                {...commonYesNoProps(setIsPregnant)}
                                errors={errors?.isPregnant}
                                helperText="If you're pregnant you cannot be denied coverage due to the Affordable Care Act."
                                label="Is Pregnant"
                                name="isPregnant"
                                value={isPregnant}
                            />
                        )}
                        {!isIchraSurvey && handleIncome()}
                    </Box>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={handleClose}>Close</Button>
                <Button form="household-member-modal-form" isLoading={isLoading} type="submit">
                    {text}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(HouseholdMemberModal);
