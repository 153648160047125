import { editTeamAddress } from 'actions/team/editTeamAddress';
import { getDefaultMemberDataYear } from 'actions/user/getDefaultMemberDataYear';
import { AddressTypes } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import EditablePhoneNumberAttribute from 'components/EditablePhoneNumberAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import ExternalLink from 'components/ExternalLink';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import EditableAddressAttribute from 'pages/profile/EditableAddressAttribute';
import ProfileSectionHeader from 'pages/teamProfile/ProfileSectionHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { getYearOrDefault } from 'utilities/year';
import { string } from 'yup';

const getFooterWithExternalLink = (link: string) => {
    if (!hasValue(link)) {
        return <React.Fragment />;
    }

    return <ExternalLink href={link}>{link}</ExternalLink>;
};

const FirstSection = ({
    readonly,
    save,
}: {
    readonly: boolean;
    save: ISaveEditableTextField<ITeam>;
}) => {
    const dispatch = useThunkDispatch();
    const {
        activeDate,
        hasTeamIdUrlParam,
        teamAddress,
        teamId,
        teamName: initialTeamName,
        legalName: initialLegalName,
        primaryPhone: initialPrimaryPhone,
        secondaryPhone: initialSecondaryPhone,
        website: initialWebsite,
        hbaLink: initialHbaLink,
    } = useTeamProps();
    const { defaultMemberDataYear, isRhOnly } = useSelector((state: AppStore) => ({
        defaultMemberDataYear: state.defaultMemberDataYear,
        isRhOnly: isRhSelector(state),
    }));
    const [teamName, setTeamName] = useState(initialTeamName ?? '');
    const [legalName, setLegalName] = useState(initialLegalName ?? '');
    const [primaryPhone, setPrimaryPhone] = useState(initialPrimaryPhone ?? '');
    const [secondaryPhone, setSecondaryPhone] = useState(initialSecondaryPhone ?? '');
    const [website, setWebsite] = useState(initialWebsite ?? '');
    const [hbaLink, setHbaLink] = useState(initialHbaLink ?? '');
    const saveAddress = useCallback(
        async (address) => {
            await dispatch(editTeamAddress(teamId, address, !hasTeamIdUrlParam));
        },
        [dispatch, hasTeamIdUrlParam, teamId]
    );

    useEffect(() => {
        dispatch(getDefaultMemberDataYear());
    }, [dispatch]);

    const year = getYearOrDefault(activeDate, defaultMemberDataYear);
    return (
        <React.Fragment>
            <ProfileSectionHeader headerText="Team Contact Info" />
            <EditableTextAttribute
                label="Team Name"
                name="name"
                onChange={onChange(setTeamName)}
                placeholder="Enter a team name"
                readonly={readonly}
                save={save}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Team Name')}
                value={teamName}
            />
            <EditableTextAttribute
                isOptional
                label="Legal Name"
                name="legalName"
                onChange={onChange(setLegalName)}
                placeholder="Enter a legal name"
                readonly={readonly}
                save={save}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Legal Name')}
                value={legalName}
            />
            <EditableAddressAttribute
                address={teamAddress}
                readonly={readonly}
                saveAddress={saveAddress}
                type={AddressTypes.Mailing}
                year={year}
            />
            <EditablePhoneNumberAttribute
                isOptional
                label="Primary Phone"
                name="primaryPhoneNumber"
                onChange={onChange(setPrimaryPhone)}
                placeholder="Enter a primary phone number"
                readonly={readonly}
                save={save}
                validationSchema={string()
                    .isValidPhoneNumber(false)
                    .label('Primary Phone')}
                value={primaryPhone}
            />
            <EditablePhoneNumberAttribute
                isOptional
                label="Secondary Phone"
                name="secondaryPhoneNumber"
                onChange={onChange(setSecondaryPhone)}
                placeholder="Enter a secondary phone number"
                readonly={readonly}
                save={save}
                validationSchema={string()
                    .isValidPhoneNumber(false)
                    .label('Secondary Phone')}
                value={secondaryPhone}
            />
            <EditableTextAttribute
                isOptional
                label="Website"
                name="website"
                onChange={onChange(setWebsite)}
                placeholder="Enter a website"
                readonly={readonly}
                save={save}
                validationSchema={string()
                    .trim()
                    .url()
                    .label('Website')}
                value={website}
            />
            {isRhOnly && (
                <EditableTextAttribute
                    footer={getFooterWithExternalLink(hbaLink)}
                    isOptional
                    label="HBA Link"
                    name="hbaLink"
                    onChange={onChange(setHbaLink)}
                    placeholder="Enter a HBA Link"
                    save={save}
                    validationSchema={string()
                        .trim()
                        .url()
                        .label('HBA Link')}
                    value={hbaLink}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(FirstSection);
