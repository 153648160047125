import { SurveyTypes } from 'api/generated/enums';
import useUserProps from 'hooks/useUserProps';
import StepIntro from 'pages/survey/StepIntro';
import React from 'react';
import { hot } from 'react-hot-loader';

const CoverageIntro = ({ onSubmit }: { onSubmit: React.FormEventHandler }) => {
    const { user } = useUserProps();
    const ichraContent =
        user?.surveyTypeToSend === SurveyTypes.ICHRA
            ? ' This information will not be shared with your employer.'
            : '';
    return (
        <StepIntro
            description={
                'We will ask about your doctors, prescriptions, and health conditions so we can better understand your household needs and preferences.' +
                ichraContent
            }
            onSubmit={onSubmit}
            title="Help us understand your coverage needs."
        />
    );
};

export default hot(module)(CoverageIntro);
