import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import {
    HouseholdMemberTypes,
    OfferLevels,
    TeamBenefitTypes,
    UserTeamBenefitTermDetailStatuses,
} from 'api/generated/enums';
import { IAncillaryBenefitDto, ISelectAncillaryBenefitRequest } from 'api/generated/models';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Typography from 'components/Typography';
import useUserProps from 'hooks/useUserProps';
import startCase from 'lodash/startCase';
import AncillaryBenefitFile from 'pages/dashboard/ancillaryBenefitSelection/AncillaryBenefitFile';
import { getStandardAncillaryCost } from 'pages/dashboard/ancillaryBenefitSelection/AncillarySelectionUtilities';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IHouseholdMemberWithAge } from 'reducers/householdMembers';
import { formatCurrency } from 'utilities/format';
import { getDisplayFirstName } from 'utilities/household';

type IAncillaryDisplayItemProps = {
    ancillary: IAncillaryBenefitDto;
    ancillaryDecisions: ISelectAncillaryBenefitRequest[];
    handleAncillaryDecisionsChange: (
        requests: ISelectAncillaryBenefitRequest[],
        ancillaryId: string,
        type: TeamBenefitTypes,
        newStatus: UserTeamBenefitTermDetailStatuses
    ) => void;
    isReadonly: boolean;
};

const getOfferedHouseholdMembers = (
    offerLevel: OfferLevels,
    householdMembers: IHouseholdMemberWithAge[]
) => {
    switch (offerLevel) {
        case OfferLevels.EmployeeSpouse:
            return householdMembers.filter(
                (hhm) => hhm.householdMemberTypeId === HouseholdMemberTypes.Spouse
            );
        case OfferLevels.EmployeeSpouseChildren:
            return householdMembers;
        default:
            return [];
    }
};
export const getHouseholdMemberDataForAncillarySelection = (
    allHouseholdMembers: IHouseholdMemberWithAge[],
    ancillary: IAncillaryBenefitDto
) => {
    const offeredHouseholdMembers = getOfferedHouseholdMembers(
        ancillary.offerLevel,
        allHouseholdMembers
    );
    let initiallyCoveredForOfferLevelIds: string[] = [];
    switch (ancillary.statusId) {
        case UserTeamBenefitTermDetailStatuses.Offered:
            initiallyCoveredForOfferLevelIds = offeredHouseholdMembers.map(
                (x) => x.householdMemberId
            );
            break;
        case UserTeamBenefitTermDetailStatuses.Enrolled:
        case UserTeamBenefitTermDetailStatuses.Submitted:
            initiallyCoveredForOfferLevelIds =
                ancillary.initiallyCoveredHouseholdMemberIds?.filter((id) =>
                    offeredHouseholdMembers.some((hhm) => hhm.householdMemberId === id)
                ) ?? [];
            break;
        case UserTeamBenefitTermDetailStatuses.Waived:
            initiallyCoveredForOfferLevelIds = [];
            break;
        default:
            break;
    }
    return { initiallyCoveredForOfferLevelIds, offeredHouseholdMembers };
};

const getLifeInsuranceAncillaryCost = (
    userId: string,
    costData: Record<string, number>,
    coveredMembers: IHouseholdMemberWithAge[]
) => {
    let cost = costData[userId] ?? 0;
    coveredMembers.forEach((hhm) => {
        cost += costData[hhm.householdMemberId] ?? 0;
    });

    return cost;
};

const getAncillaryCost = (
    ancillary: IAncillaryBenefitDto,
    offeredHouseholdMembers: IHouseholdMemberWithAge[],
    coveredMemberIds: string[]
) => {
    const costData = ancillary.costData as Record<string, number>;
    const coveredMembers = offeredHouseholdMembers.filter((x) =>
        coveredMemberIds?.contains(x.householdMemberId)
    );
    return ancillary.teamBenefitType === startCase(TeamBenefitTypes[TeamBenefitTypes.LifeInsurance])
        ? getLifeInsuranceAncillaryCost(ancillary.userId, costData, coveredMembers)
        : getStandardAncillaryCost(costData, coveredMembers);
};

const AncillaryDisplayItem = ({
    ancillary,
    ancillaryDecisions,
    handleAncillaryDecisionsChange,
    isReadonly,
}: IAncillaryDisplayItemProps) => {
    const { user } = useUserProps();
    const { allHouseholdMembers } = useSelector((state: AppStore) => ({
        allHouseholdMembers: state.householdMembers,
    }));
    const ancillaryDecision = ancillaryDecisions?.find(
        (x) => x.userTermDetailId === ancillary.userTermDetailId
    ) as ISelectAncillaryBenefitRequest;
    const {
        initiallyCoveredForOfferLevelIds,
        offeredHouseholdMembers,
    } = getHouseholdMemberDataForAncillarySelection(allHouseholdMembers, ancillary);
    const canShowCost = ancillary.canShowCost ?? false;
    const [coveredIds, setCoveredIds] = useState<string[]>(initiallyCoveredForOfferLevelIds);
    const [status, setStatus] = useState<UserTeamBenefitTermDetailStatuses>(ancillary.statusId);
    const [otherAncillaryOfSameTypeSelected, setOtherAncillaryOfSameTypeSelected] = useState<
        boolean
    >(false);
    const [enabled, setEnabled] = useState(
        ancillary.statusId !== UserTeamBenefitTermDetailStatuses.Waived && !isReadonly
    );
    const [cost, setCost] = useState(
        canShowCost
            ? getAncillaryCost(ancillary, offeredHouseholdMembers, initiallyCoveredForOfferLevelIds)
            : undefined
    );

    const waivedCost = canShowCost
        ? getAncillaryCost(ancillary, offeredHouseholdMembers, initiallyCoveredForOfferLevelIds)
        : undefined;

    const isSelected = [
        UserTeamBenefitTermDetailStatuses.Submitted,
        UserTeamBenefitTermDetailStatuses.Enrolled,
    ].contains(status);
    const isWaived = status === UserTeamBenefitTermDetailStatuses.Waived;

    const costStyle = isWaived ? { textDecoration: 'line-through' } : {};

    const handleCoveredIdsChange = useCallback(
        (newIds: string[]) => {
            if (canShowCost) {
                setCost(getAncillaryCost(ancillary, offeredHouseholdMembers, newIds));
            }
            if (ancillaryDecision) {
                ancillaryDecision.householdMemberIds = newIds;
            }
            handleAncillaryDecisionsChange(
                ancillaryDecisions,
                ancillary.userTermDetailId,
                ancillary.teamBenefitTypeId,
                ancillary.statusId
            );
            setCoveredIds(newIds);
        },
        [
            ancillary,
            ancillaryDecision,
            ancillaryDecisions,
            canShowCost,
            handleAncillaryDecisionsChange,
            offeredHouseholdMembers,
        ]
    );

    const handleStatusChange = useCallback(
        (newStatus: UserTeamBenefitTermDetailStatuses) => {
            if (ancillaryDecision) {
                ancillaryDecision.statusId = newStatus;
            }
            handleAncillaryDecisionsChange(
                ancillaryDecisions,
                ancillary.userTermDetailId,
                ancillary.teamBenefitTypeId,
                newStatus
            );
            setStatus(newStatus);
        },
        [ancillary, ancillaryDecision, ancillaryDecisions, handleAncillaryDecisionsChange]
    );

    const handleDecisionClick = useCallback(
        (targetStatus: UserTeamBenefitTermDetailStatuses) => {
            if (targetStatus === status && !otherAncillaryOfSameTypeSelected) {
                handleStatusChange(UserTeamBenefitTermDetailStatuses.Offered);
                handleCoveredIdsChange(initiallyCoveredForOfferLevelIds);
                setEnabled(true);
            } else {
                if (targetStatus === UserTeamBenefitTermDetailStatuses.Waived) {
                    handleCoveredIdsChange([]);
                    setEnabled(false);
                } else if (
                    status === UserTeamBenefitTermDetailStatuses.Waived &&
                    [
                        UserTeamBenefitTermDetailStatuses.Submitted,
                        UserTeamBenefitTermDetailStatuses.Enrolled,
                    ].contains(targetStatus)
                ) {
                    handleCoveredIdsChange(initiallyCoveredForOfferLevelIds);
                    setEnabled(true);
                }
                handleStatusChange(targetStatus);
            }
        },
        [
            handleCoveredIdsChange,
            handleStatusChange,
            initiallyCoveredForOfferLevelIds,
            otherAncillaryOfSameTypeSelected,
            status,
        ]
    );

    const handleOtherAncillaryOfSameTypeSelected = useCallback(() => {
        ancillaryDecision.householdMemberIds = [];
        setOtherAncillaryOfSameTypeSelected(true);
        setEnabled(false);
        setCost(
            getAncillaryCost(ancillary, offeredHouseholdMembers, initiallyCoveredForOfferLevelIds)
        );
    }, [ancillary, ancillaryDecision, initiallyCoveredForOfferLevelIds, offeredHouseholdMembers]);

    const onCheckBoxClick = (householdMemberId: string) => {
        const newCoveredIds = coveredIds?.includes(householdMemberId)
            ? coveredIds.filter((id) => id !== householdMemberId)
            : [...coveredIds, householdMemberId];
        handleCoveredIdsChange(newCoveredIds);
    };

    const householdMemberCheckBoxes = offeredHouseholdMembers?.map((x) => (
        <React.Fragment key={x.householdMemberId}>
            <Checkbox
                checked={coveredIds?.includes(x.householdMemberId) && !isWaived}
                disabled={!enabled}
                label={getDisplayFirstName(x)}
                onClick={() => onCheckBoxClick(x.householdMemberId)}
            ></Checkbox>
        </React.Fragment>
    ));

    useEffect(() => {
        if (
            ancillaryDecisions?.some(
                (ar) =>
                    ar.userTermDetailId !== ancillaryDecision?.userTermDetailId &&
                    ar.teamBenefitType === ancillaryDecision?.teamBenefitType &&
                    [
                        UserTeamBenefitTermDetailStatuses.Enrolled,
                        UserTeamBenefitTermDetailStatuses.Submitted,
                    ].contains(ar.statusId)
            )
        ) {
            handleOtherAncillaryOfSameTypeSelected();
        } else if (
            ancillaryDecisions?.some(
                (ar) =>
                    ar.userTermDetailId === ancillaryDecision?.userTermDetailId &&
                    status === UserTeamBenefitTermDetailStatuses.Waived
            )
        ) {
            setEnabled(false);
            setOtherAncillaryOfSameTypeSelected(false);
        } else {
            setEnabled(true);
            setOtherAncillaryOfSameTypeSelected(false);
            if (canShowCost) {
                setCost(getAncillaryCost(ancillary, offeredHouseholdMembers, coveredIds));
            }
            if (ancillaryDecision) {
                ancillaryDecision.householdMemberIds = coveredIds;
            }
        }
    }, [
        ancillary,
        ancillaryDecision,
        ancillaryDecision?.teamBenefitType,
        ancillaryDecision?.userTermDetailId,
        ancillaryDecisions,
        canShowCost,
        coveredIds,
        handleCoveredIdsChange,
        handleOtherAncillaryOfSameTypeSelected,
        initiallyCoveredForOfferLevelIds,
        offeredHouseholdMembers,
        status,
    ]);

    useEffect(() => {
        setCoveredIds(initiallyCoveredForOfferLevelIds);
        setStatus(ancillary.statusId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ancillary]);

    return (
        <Card
            raised
            sx={{
                mb: 2,
                pb: 1.5,
            }}
        >
            <CardHeader
                subheader={ancillary.planName ?? ''}
                title={ancillary.carrierName}
                titleTypographyProps={{ mb: 0, variant: 'h4' }}
            />
            <CardContent>
                <Stack spacing={1}>
                    {ancillary?.rhFiles?.length != 0 && (
                        <Stack>
                            <Typography fontWeight="bold">Benefit Files:</Typography>
                            <List disablePadding>
                                {ancillary?.rhFiles?.map((file) => (
                                    <AncillaryBenefitFile
                                        file={file}
                                        key={file.id}
                                        teamBenefitId={ancillary.teamBenefitId}
                                    />
                                )) ?? <React.Fragment />}
                            </List>
                        </Stack>
                    )}
                    <Stack
                        alignItems={{ sm: 'center', xs: 'flex-start' }}
                        direction={{ sm: 'row', xs: 'column' }}
                        flexWrap="wrap"
                        paddingBottom={{ sm: 0, xs: 2 }}
                    >
                        <Typography fontWeight="bold">For:</Typography>
                        <Checkbox
                            checked={status !== UserTeamBenefitTermDetailStatuses.Waived}
                            disabled
                            label={getDisplayFirstName(user)}
                        ></Checkbox>
                        {householdMemberCheckBoxes}
                    </Stack>
                    {canShowCost && (
                        <Typography className={isWaived ? 'text-muted' : ''} style={costStyle}>
                            <strong>Monthly Cost:</strong>
                            &nbsp;&nbsp;
                            {formatCurrency(isWaived ? waivedCost : cost, {
                                preserveDecimal: true,
                            })}
                        </Typography>
                    )}
                </Stack>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                <ConditionalTooltip
                    isDisabled={!otherAncillaryOfSameTypeSelected || isReadonly}
                    title="You can only select one benefit of this type. To change your selection, de-select the one you've chosen and then you can pick a different option."
                >
                    <Button
                        color={isSelected ? 'secondary' : 'inherit'}
                        disabled={isReadonly || otherAncillaryOfSameTypeSelected}
                        onClick={() =>
                            handleDecisionClick(UserTeamBenefitTermDetailStatuses.Submitted)
                        }
                        size="small"
                        variant={isSelected ? 'contained' : 'outlined'}
                    >
                        Select{isSelected && 'ed'}
                    </Button>
                </ConditionalTooltip>
                <Button
                    color={isWaived ? 'primary' : 'inherit'}
                    disabled={isReadonly}
                    disableElevation
                    onClick={() => handleDecisionClick(UserTeamBenefitTermDetailStatuses.Waived)}
                    size="small"
                    variant={isWaived ? 'contained' : 'outlined'}
                >
                    Waive{isWaived && 'd'}
                </Button>
            </CardActions>
        </Card>
    );
};
export default hot(module)(AncillaryDisplayItem);
