import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const LossOfHealthCoverageExamples = () => (
    <React.Fragment>
        <p>Examples of documents you may submit include a letter, a notice, or a bill:</p>
        <ul>
            <li>
                From your insurance company, like a letter stating the date that your health
                coverage ended or will end, or a bill showing the date that your coverage ended or
                will end.
            </li>
            <li>
                From an employer, like a letter stating the date that coverage from an employer
                ended or will end. This letter can be about coverage that you had, or that you had
                through a family member.
            </li>
            <li>
                About COBRA coverage, like a letter from a former employer or health insurance
                company showing an offer of COBRA coverage, or stating the date that your COBRA
                coverage ended or will end, or stating the date that the employer stopped or will
                stop contributing to the cost of COBRA.
            </li>
            <li>
                About other health care programs, like a letter or notice from a government health
                program stating the date that coverage ended or will end. This letter might come
                from programs like Medicaid, the Children’s Health Insurance Program (CHIP),
                TRICARE, Veterans Affairs (VA), Peace Corps, or Medicare.
            </li>
        </ul>
        <p>
            For a full list of documents you can submit, visit{' '}
            <ExternalLink href="https://www.healthcare.gov/help/prove-coverage-loss">
                healthcare.gov/help/prove-coverage-loss
            </ExternalLink>
        </p>
    </React.Fragment>
);

export default hot(module)(LossOfHealthCoverageExamples);
