import SingleButtonModal from 'components/SingleButtonModal';
import DoNotInclude from 'pages/survey/informationModals/DoNotInclude';
import InfoModalText from 'pages/survey/informationModals/InfoModalText';
import ThingsToInclude from 'pages/survey/informationModals/ThingsToInclude';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const CalculateDeductionsInformationModal = ({ close }: { close: () => void }) => (
    <SingleButtonModal
        body={
            <Row>
                <Col className="mb-4" md={{ offset: 1 }}>
                    <ThingsToInclude />
                    <InfoModalText>Housing Allowance if Minister</InfoModalText>
                    <InfoModalText>Business Deductions</InfoModalText>
                    <InfoModalText>Student Loan Interest</InfoModalText>
                    <InfoModalText>IRA Contributions</InfoModalText>
                    <InfoModalText>Educator Expenses</InfoModalText>
                    <InfoModalText>HSA Personal Contributions</InfoModalText>
                </Col>
                <Col>
                    <DoNotInclude />
                    <InfoModalText>Charitable Contributions</InfoModalText>
                    <InfoModalText>Child / Dependent Care Expenses</InfoModalText>
                    <InfoModalText>Child Support Payments</InfoModalText>
                    <InfoModalText>HSA Deposits</InfoModalText>
                    <InfoModalText>Medical Expenses</InfoModalText>
                    <InfoModalText>Mortgage Interest</InfoModalText>
                    <InfoModalText>Property Taxes</InfoModalText>
                    <InfoModalText>State Taxes</InfoModalText>
                    <InfoModalText>HSA Employer Contributions</InfoModalText>
                </Col>
            </Row>
        }
        buttonClickHandler={close}
        buttonText="Close"
        size="lg"
        title="Calculate Deductions"
    />
);

export default hot(module)(CalculateDeductionsInformationModal);
