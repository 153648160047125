import { AppStoreThunkDispatch } from 'actions/commonAction';
import axios from 'axios';
import { AppStore } from 'reducers/appReducer';

export const SET_CURRENT_REPORT_GLOBAL_ID = 'SET_CURRENT_REPORT_GLOBAL_ID';
export const setCurrentReportGlobalId = (currentReportGlobalId: string) => (
    dispatch: AppStoreThunkDispatch,
    getState: () => AppStore
) => {
    const { reimbursementReportState } = getState();
    reimbursementReportState?.cancelTokenSource?.cancel();
    dispatch({
        data: { currentReportGlobalId },
        type: SET_CURRENT_REPORT_GLOBAL_ID,
    });
};

export const SET_REIMBURSEMENT_REPORT_SELECTED_YEAR = 'SET_REIMBURSEMENT_REPORT_SELECTED_YEAR';
export const setReimbursementReportSelectedYear = (reimbursementReportSelectedYear: string) => (
    dispatch: AppStoreThunkDispatch,
    getState: () => AppStore
) => {
    const { reimbursementReportState } = getState();
    reimbursementReportState?.cancelTokenSource?.cancel();
    dispatch({
        data: { reimbursementReportSelectedYear, cancelTokenSource: axios.CancelToken.source() },
        type: SET_REIMBURSEMENT_REPORT_SELECTED_YEAR,
    });
};
