import { IAction } from 'actions/commonAction';
import { APPLY_SHOPPING_COVERAGE_CHANGES } from 'actions/householdShoppingCoverage/applyShoppingCoverageChanges';
import { IHouseholdMemberDto } from 'api/generated/models';
import {
    CLEAR_SHOPPING_STATE_ACTION,
    SET_HAS_ACKNOWLEDGED_PROVIDER_DISCLAIMER_ACTION,
    SET_SHOPPING_SELECTED_PROVIDERS_ACTION,
    UPDATE_SHOPPING_MEMBERS_AND_GET_RATES,
} from 'pages/shop/shoppingActions';
import { IIdeonProviderWithSource } from 'reducers/ideonProviders';
import { hasValue } from 'utilities/index';

export type IStringBoolDictionary = Record<string, boolean>;

export type IShoppingState = {
    excludedMembers: string[];
    hasAcknowledgedProviderDisclaimer: boolean;
    isPrimaryCovered: boolean;
    members: IStringBoolDictionary;
    selectedHouseholdMembers: string[];
    selectedProviders: IIdeonProviderWithSource[];
    selectedYear: number;
};

const initialState: IShoppingState = {
    excludedMembers: [],
    hasAcknowledgedProviderDisclaimer: false,
    isPrimaryCovered: false,
    members: {},
    selectedHouseholdMembers: [],
    selectedProviders: [],
    selectedYear: 0,
};

export const shoppingState = (
    state = initialState,
    action: IAction<IShoppingState>
): IShoppingState => {
    switch (action.type) {
        case UPDATE_SHOPPING_MEMBERS_AND_GET_RATES:
        case SET_SHOPPING_SELECTED_PROVIDERS_ACTION:
            return {
                ...state,
                ...action.data,
            };
        case APPLY_SHOPPING_COVERAGE_CHANGES: {
            const { householdMembers } = (action.data as unknown) as {
                householdMembers: Record<string, IHouseholdMemberDto>;
            };
            const needsCoverageEntries = Object.entries(householdMembers).map(([id, hhm]) => ({
                id,
                needsCoverage: hhm?.needsCoverage,
            }));
            let { excludedMembers, members, selectedHouseholdMembers } = state;
            for (const { id, needsCoverage } of needsCoverageEntries) {
                if (hasValue(id)) {
                    members = { ...members, [id]: needsCoverage };
                    if (needsCoverage) {
                        excludedMembers = excludedMembers.filter((x) => x !== id);
                        selectedHouseholdMembers = [...selectedHouseholdMembers, id];
                    } else {
                        excludedMembers = [...excludedMembers, id];
                        selectedHouseholdMembers = selectedHouseholdMembers.filter((x) => x !== id);
                    }
                }
            }

            return {
                ...state,
                excludedMembers,
                members,
                selectedHouseholdMembers,
            };
        }
        case CLEAR_SHOPPING_STATE_ACTION:
            return initialState;
        case SET_HAS_ACKNOWLEDGED_PROVIDER_DISCLAIMER_ACTION:
            return { ...state, hasAcknowledgedProviderDisclaimer: true };
        default:
            return state;
    }
};
