import { getTeamRelationships } from 'actions/team/getTeamRelationships';
import {
    transitionToCustomer,
    TRANSITION_TO_CUSTOMER_ACTION,
} from 'actions/team/transitionToTeamCustomer';
import { RelationshipTypeIds } from 'api/generated/enums';
import Button from 'components/Button';
import useThunkDispatch from 'hooks/useThunkDispatch';
import some from 'lodash/some';
import HandoffToCustomerSuccessModal from 'pages/dashboard/HandoffToCustomerSuccessModal';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const HandoffToCustomerSuccess = ({ teamId }: { teamId: string }) => {
    const dispatch = useThunkDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { hasCustomerRep, isLoading } = useSelector((state: AppStore) => ({
        hasCustomerRep: some(
            state.teamRelationships,
            (x) =>
                x.relationshipType?.teamRelationshipTypeId ===
                RelationshipTypeIds.CustomerSuccessRepresentative
        ),
        isLoading: hasApiActivity(state, TRANSITION_TO_CUSTOMER_ACTION),
    }));
    useEffect(() => {
        dispatch(getTeamRelationships(teamId));
    }, [dispatch, teamId]);
    const attemptTransition = useCallback(() => {
        if (hasCustomerRep) {
            dispatch(transitionToCustomer(teamId));
        } else {
            setIsModalVisible(true);
        }
    }, [dispatch, hasCustomerRep, teamId]);
    const closeModal = useCallback(() => setIsModalVisible(false), []);
    return (
        <React.Fragment>
            {isModalVisible && (
                <HandoffToCustomerSuccessModal onClose={closeModal} teamId={teamId} />
            )}
            <Button
                data-cy="hand-off-to-customer-success"
                isLoading={isLoading}
                onClick={attemptTransition}
                xsClassName="mb-4"
            >
                Hand this team off to Customer Success
            </Button>
        </React.Fragment>
    );
};

export default hot(module)(HandoffToCustomerSuccess);
