import { Grid } from '@mui/material';
import { COMPLETE_TASK_ACTION } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION, listVisibleFlows } from 'actions/taskFlows/listVisibleFlows';
import { getHousehold } from 'actions/user/getHousehold';
import { TaskStatuses } from 'api/generated/enums';
import { TaskDto } from 'api/generated/models';
import ActivityIndicator from 'components/ActivityIndicator';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import IchraEnrollmentTaskFlow from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskFlow';
import IchraEnrollmentTaskFlowContent from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskFlowContent';
import { getSupportedEnrollmentUserFlow } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const IchraEnrollmentTaskFlowContainer = () => {
    const dispatch = useThunkDispatch();
    const { isCurrent, userId } = useUserProps();
    const { isLoading, ichraFlow } = useSelector((state: AppStore) => ({
        ichraFlow: getSupportedEnrollmentUserFlow(state.userFlows),
        isLoading: hasApiActivity(state, COMPLETE_TASK_ACTION, LIST_VISIBLE_FLOWS_ACTION),
    }));
    const [currentTask, setCurrentTask] = useState<TaskDto>();
    const [currentParentTask, setCurrentParentTask] = useState<TaskDto>();
    const ichraTasks = ichraFlow?.tasks;

    useEffect(() => {
        dispatch(listVisibleFlows(userId));
        dispatch(getHousehold(userId, isCurrent));
    }, [dispatch, isCurrent, userId]);

    useEffect(() => {
        const currentParentTaskToComplete = ichraTasks?.find((task) =>
            [TaskStatuses.Incomplete, TaskStatuses.Acknowledged].includes(task.taskStatusId)
        );

        let currentTaskToComplete = currentParentTaskToComplete;
        if (currentParentTaskToComplete?.taskStatusId === TaskStatuses.Acknowledged) {
            currentTaskToComplete = currentParentTaskToComplete?.childTasks?.find(
                (task) => task.taskStatusId === TaskStatuses.Incomplete
            );
        }

        setCurrentTask(currentTaskToComplete);
        setCurrentParentTask(currentParentTaskToComplete);
    }, [ichraTasks]);

    return (
        <Grid container direction={{ md: 'row', xs: 'column' }} spacing={4}>
            <Grid item width={{ md: '20rem !important', xs: '100%' }}>
                <IchraEnrollmentTaskFlow
                    currentFlow={ichraFlow}
                    currentParentTask={currentParentTask as TaskDto}
                />
            </Grid>
            <Grid item width={{ md: 'calc(100% - 20rem)', xs: '100%' }}>
                <IchraEnrollmentTaskFlowContent
                    currentFlow={ichraFlow}
                    currentTask={currentTask as TaskDto}
                />
            </Grid>
            {isLoading && <ActivityIndicator />}
        </Grid>
    );
};

export default hot(module)(IchraEnrollmentTaskFlowContainer);
