import { Grid, Typography } from '@mui/material';
import {
    LIST_DRUGS_FOR_HOUSEHOLD_ACTION,
    listDrugsForHousehold,
} from 'actions/drug/listDrugsForHousehold';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { Tasks } from 'api/generated/enums';
import {
    Drug,
    IDrug,
    IHouseholdMemberInfoDto,
    IPrescriptionsDto,
    IUser,
    TaskDto,
} from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import CreatableAutocomplete from 'components/creatableAutocomplete/CreatableAutocomplete';
import useDrugAutocomplete from 'components/creatableAutocomplete/useDrugAutocomplete';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import EntityHhmAssignmentCard from 'pages/dashboard/ichraTaskFlow/EntityHhmAssignmentCard';
import EntityHhmAssignmentForm from 'pages/dashboard/ichraTaskFlow/EntityHhmAssignmentForm';
import { ICHRA_LAUNCH_FLOW_INDEX } from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskList';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
    filterNonChunkHhmData,
    getPreviousTask,
    ichraFlowSkeletonRowProps,
    skeletonStructure,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import {
    searchContentCheck,
    setName,
} from 'pages/dashboard/ichraTaskFlow/taskFlowPages/PreferredProvidersPage';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const INDEX_DOES_NOT_EXIST = -1;

const PrescriptionsPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { user, userId } = useUserProps();
    const { drugsFromState, isLoading, userFlows } = useSelector((state: AppStore) => ({
        drugsFromState: state.householdDrugs,
        isLoading: hasApiActivity(
            state,
            LIST_DRUGS_FOR_HOUSEHOLD_ACTION,
            LIST_VISIBLE_FLOWS_ACTION
        ),
        userFlows: state.userFlows,
    }));
    const ichraTasks = userFlows[ICHRA_LAUNCH_FLOW_INDEX]?.tasks;

    const [hhmTask, setHhmTask] = useState<TaskDto>();
    const [drugName, setDrugName] = useState('');
    const [drug, setDrug] = useState<IDrug>();
    const [drugs, setDrugs] = useState<IDrug[]>(currentTask.response?.drugs ?? []);
    const [autocompleteSearchText, setAutoCompleteSearchText] = useState<string>('');
    const [shouldClearAutocompleteSearchText, setShouldClearAutocompleteSearchText] = useState(
        false
    );

    const isPrimaryOnly = !arrayHasValue(hhmTask?.response.householdMembers);
    const hhms = hhmTask?.response.householdMembers as IHouseholdMemberInfoDto[];

    const onPrescriptionChange = useCallback(
        (_, value) => {
            const name = setName(value);
            const isRecognizedDrug = hasValue(value.drugId);
            if (hasValue(name)) {
                if (!isRecognizedDrug) {
                    value.name = name;
                    if (hasValue(value.inputValue)) {
                        delete value.inputValue;
                    }
                }
                const newDrug = value;
                const drugsToAdd = [...(drugs ?? [])];
                const existingDrugIndex = drugsToAdd.findIndex((dta) =>
                    hasValue(newDrug.fullName)
                        ? dta.fullName === newDrug.fullName
                        : dta.name === newDrug.name
                );
                if (isPrimaryOnly) {
                    existingDrugIndex === INDEX_DOES_NOT_EXIST &&
                        drugsToAdd.unshift({ ...newDrug, entityIds: [user?.userId] });
                } else if (hasValue(newDrug)) {
                    let existingDrug;
                    if (existingDrugIndex === INDEX_DOES_NOT_EXIST) {
                        drugsToAdd.unshift({ ...newDrug, entityIds: [] });
                    } else {
                        [existingDrug] = drugsToAdd.splice(existingDrugIndex, 1);
                        drugsToAdd.unshift(existingDrug as IDrug);
                    }
                    setDrug(existingDrug ?? newDrug);
                    setDrugName(newDrug.fullName ?? newDrug.name);
                }
                setDrugs(drugsToAdd);
            }
            setShouldClearAutocompleteSearchText(true);
            if (isRecognizedDrug) {
                setAutoCompleteSearchText('');
            }
        },
        [drugs, isPrimaryOnly, user?.userId]
    );
    const onAutocompleteSearchTextChange = useCallback(
        (_, value) => {
            if (shouldClearAutocompleteSearchText) {
                setAutoCompleteSearchText('');
                setShouldClearAutocompleteSearchText(false);
            } else {
                setAutoCompleteSearchText(searchContentCheck.test(value) ? value : '');
            }
        },
        [shouldClearAutocompleteSearchText]
    );

    const handleSubmit = async () => {
        const prescriptionsToSave: IPrescriptionsDto = { drugs: drugs as Drug[] };
        dispatch(completeTask(currentTask.globalId, prescriptionsToSave));
    };

    const handleEditCancelButtonClick = (fullName: string) => {
        if (fullName === drugName) {
            setDrugName('');
        } else {
            const drugToEdit =
                drugs?.find((d) => d.fullName === fullName || d.name === fullName) ?? {};
            setDrug(drugToEdit as IDrug);
            setDrugName(fullName);
        }
    };

    const handleDrugRemoval = (fullName: string) => {
        setDrugs((existingDrugs) =>
            existingDrugs?.filter(
                (d) => hasValue(d) && d.fullName !== fullName && d.name !== fullName
            )
        );
        setDrugName('');
    };

    const handleRecipientChange = (entityIds: string[]) => {
        const drugIndex =
            drugs?.findIndex((d) => d.fullName === drugName || d.name === drugName) ?? 0;
        setDrugs((prevDrugs) => {
            const updatedDrugs = [...prevDrugs];
            updatedDrugs[drugIndex] = {
                ...updatedDrugs[drugIndex],
                entityIds,
                drugId: updatedDrugs[drugIndex]?.drugId as string,
            };
            return updatedDrugs;
        });
        setDrugName('');
    };

    useEffect(() => {
        const householdTask = getPreviousTask(
            ichraTasks as TaskDto[],
            Tasks.HouseholdMembers,
            Tasks.EnterHouseholdInfo
        );
        setHhmTask(householdTask);
    }, [ichraTasks]);

    const creatableAutocompleteProps = useDrugAutocomplete({});

    const prescriptionForm = (
        <EntityHhmAssignmentForm
            entity={drug as IDrug}
            handleRecipientChange={handleRecipientChange}
            hhms={hhms}
            key={drug?.fullName}
            prompt={`Who is ${hasValue(drug) && drug?.name} for?`}
            user={user as IUser}
        />
    );

    const taskHasResponse = hasValue(currentTask?.response);

    useEffect(() => {
        if (!taskHasResponse) {
            dispatch(listDrugsForHousehold(user?.userId, user?.activeDate?.getYear()));
        }
    }, [currentTask.response, dispatch, taskHasResponse, user?.activeDate, user?.userId]);

    useEffect(() => {
        const allDrugs: IDrug[] = [];
        const entityIds = [userId];
        hhms?.forEach((hhm) => entityIds.push(hhm.householdMemberId as string));
        if (!taskHasResponse) {
            filterNonChunkHhmData(drugsFromState, entityIds).forEach((drugFromState) => {
                const existingDrug = allDrugs.filter(
                    (al) => al.fullName === drugFromState.fullName
                );
                if (arrayHasValue(existingDrug)) {
                    const drugIndex = allDrugs.findIndex(
                        (ad) => ad.fullName === drugFromState.fullName
                    );
                    if (
                        !allDrugs[drugIndex]?.entityIds?.includes(drugFromState.entityId as string)
                    ) {
                        allDrugs[drugIndex]?.entityIds?.push(drugFromState.entityId as string);
                    }
                } else {
                    drugFromState.entityIds = [drugFromState.entityId as string];
                    allDrugs.push(drugFromState);
                }
            });
        } else {
            const drugsFromTask = (currentTask.response as IPrescriptionsDto).drugs as Drug[];
            drugsFromTask.forEach((d) => {
                if (entityIds.some((ei) => d.entityIds?.includes(ei))) {
                    allDrugs.push(d);
                }
            });
        }
        setDrugs(allDrugs);
    }, [currentTask.response, drugsFromState, hhms, taskHasResponse, userId]);

    return (
        <Grid container direction="column" gap={4} maxWidth={MAX_WIDTH} width="100%">
            <IchraFlowTitle title="Prescriptions" />
            <Skeleton
                isEnabled={isLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={skeletonStructure}
            >
                <Typography>
                    List any prescriptions you&apos;d want to be covered for you or anyone in your
                    household. We ask about prescriptions to help provide ongoing support and
                    advisement services.
                </Typography>
                <Typography pb={2}>
                    Whether specific prescriptions are covered can change, so we cannot guarantee
                    their coverage. As best practice, we recommend checking with the plan carrier
                    directly for prescription coverage.
                </Typography>
                <CreatableAutocomplete
                    {...creatableAutocompleteProps}
                    disabled={hasValue(drugName)}
                    inputValue={autocompleteSearchText}
                    multiple={undefined}
                    onChange={onPrescriptionChange}
                    onInputChange={onAutocompleteSearchTextChange}
                />

                {arrayHasValue(drugs) &&
                    drugs.map((d) => (
                        <EntityHhmAssignmentCard
                            entity={d}
                            entityIds={d?.entityIds as string[]}
                            entityName={(d.fullName ?? d.name) as string}
                            form={prescriptionForm}
                            handleEditCancelButtonClick={handleEditCancelButtonClick}
                            hhms={hhms}
                            isExpanded={drugName === d?.fullName || drugName === d?.name}
                            isPrimaryOnly={isPrimaryOnly}
                            key={d?.fullName}
                            removeEntity={handleDrugRemoval}
                            user={user as IUser}
                        />
                    ))}
                <Grid item>
                    <IchraFlowButtons
                        currentTaskId={currentTask.globalId}
                        handleNext={handleSubmit}
                        isLoading={isLoading}
                        showPreviousButton
                    />
                </Grid>
            </Skeleton>
        </Grid>
    );
};

export default hot(module)(PrescriptionsPage);
