import Stack from '@mui/material/Stack';
import { EDIT_USER_BUDGET_ACTION } from 'actions/user/addOrEditUserBudget';
import { IUser, IUserBudget } from 'api/generated/models';
import BudgetModalFooter from 'components/BudgetModalFooter';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import TextArea from 'components/TextArea';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { allFalse, hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { invalidCurrencyValue, onChange } from 'utilities/forms';

type IEditBudgetModalProps = {
    close: () => void;
    isNetAmount: boolean;
    save: ({ note, value }: { note: string | undefined; value: number | undefined }) => void;
    user: IUser | undefined;
    userBudget: IUserBudget;
};

const EditBudgetModal = ({
    close,
    isNetAmount = false,
    save,
    user,
    userBudget,
}: IEditBudgetModalProps) => {
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, EDIT_USER_BUDGET_ACTION),
    }));
    const amount = useMemo(
        () => (isNetAmount ? userBudget.amount : userBudget.supplementalWageIncrease),
        [isNetAmount, userBudget.amount, userBudget.supplementalWageIncrease]
    );
    const [value, setValue] = useState(userBudget.isCustom ? amount : undefined);
    const [note, setNote] = useState(userBudget.isCustom ? userBudget.note : '');
    const [invalidProps, setInvalidProps] = useState({
        note: false,
        value: false,
    });
    const isValid = useCallback(() => {
        const newInvalidProps = {
            note: !hasValue(note),
            value: invalidCurrencyValue(value),
        };
        setInvalidProps(newInvalidProps);
        return allFalse(newInvalidProps);
    }, [value, note]);
    const submit = useCallback(() => {
        if (isValid()) {
            save({ note, value });
        }
    }, [isValid, save, note, value]);
    const footer = useMemo(
        () =>
            userBudget.isCustom && (
                <div className="p-2">
                    <BudgetModalFooter budget={userBudget} hideHr />
                </div>
            ),
        [userBudget]
    );
    const formText = useMemo(() => (isNetAmount ? 'after' : 'before'), [isNetAmount]);
    const label = useMemo(() => (isNetAmount ? 'Budget' : 'Wage+'), [isNetAmount]);
    return (
        <Modal onHide={close} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>
                    {user?.firstName}&apos;s {label}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="edit-budget-modal" isLoading={isLoading} onSubmit={submit}>
                    <Stack gap={2}>
                        <CurrencyTextField
                            autoFocus
                            error={invalidProps.value}
                            helperText={
                                invalidProps.value
                                    ? `${label} is required`
                                    : `The amount received ${formText} taxes are taken out`
                            }
                            label={label}
                            onChange={onChange(setValue)}
                            placeholder={formatCurrency(amount)}
                            value={value}
                        />
                        <TextArea
                            data-cy="budget-modal-note"
                            error={invalidProps.note}
                            helperText={invalidProps.note ? 'Note is required' : ''}
                            label="Note"
                            minRows={3}
                            onChange={onChange(setNote)}
                            placeholder="Add Note"
                            value={note}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={close}>Close</Button>
                <Button form="edit-budget-modal" isLoading={isLoading} type="submit">
                    Save
                </Button>
            </Modal.Footer>
            {footer}
        </Modal>
    );
};

export default hot(module)(EditBudgetModal);
