import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { IReimbursementSummary } from 'api/generated/models';
import LabelAndValue from 'components/LabelAndValue';
import SectionSummary from 'pages/payrollReports/ReimbursementSummaryModel/SectionSummary';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

const aCharCode = 97;
function* idGenerator() {
    let i = 0;
    while (true) {
        yield String.fromCharCode(aCharCode + i);
        i++;
    }
}

type IReimbursementSummaryModal = {
    close: () => void;
    reimbursementSummary: IReimbursementSummary | undefined;
};

const ReimbursementSummaryModal = ({ close, reimbursementSummary }: IReimbursementSummaryModal) => {
    const generator = idGenerator();
    return (
        <Dialog fullWidth maxWidth="sm" onClose={close} open>
            <DialogTitle>Withholding / Contribution Calculation Summary</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <LabelAndValue
                        label="Reimbursement Amount"
                        value={formatCurrency(reimbursementSummary?.reimbursement, {
                            preserveDecimal: true,
                        })}
                        variant="h3"
                    />
                    <SectionSummary
                        section={reimbursementSummary?.postTaxWithholding}
                        superscriptIdGenerator={generator}
                        title="Post-Tax Withholding"
                    />
                    <SectionSummary
                        isContribution
                        section={reimbursementSummary?.taxFreeReimbursements}
                        superscriptIdGenerator={generator}
                        title="Tax-Free Reimbursement"
                    />
                    <SectionSummary
                        section={reimbursementSummary?.preTaxWithholding}
                        superscriptIdGenerator={generator}
                        title="Pre-Tax Withholding"
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="text">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(ReimbursementSummaryModal);
