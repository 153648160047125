import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveHouseholdMember } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import YesNoWho from 'pages/survey/YesNoWho';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import React, { useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { nameof, toUndefinedOrBoolean } from 'utilities';
import { getDisplayFirstName, isEmployedAndNotSameEmployer } from 'utilities/household';

const DESCRIPTION_SUFFIX =
    'job offers health benefits such as Anthem, BlueCross BlueShield, UnitedHealth or a similar carrier.';

const EmployerOtherCoverageForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { householdMembers } = useSelector((state: AppStore) => ({
        householdMembers: state.surveyState.household.members?.filter(isEmployedAndNotSameEmployer),
    }));
    const initialDisabled = some(householdMembers, (x) => isUndefined(x.hasEmployerOtherCoverage));
    const [isDisabled, setIsDisabled] = useState(initialDisabled);
    const onSelection = ({
        isInvalid,
        options,
    }: {
        isInvalid: boolean;
        isYes: boolean | undefined;
        options: ISurveyHouseholdMemberDto[];
    }) => {
        options.forEach((member) => dispatch(saveHouseholdMember(member)));
        setIsDisabled(isInvalid);
    };
    const onYesNoSelection = (isYes: boolean | string) => {
        dispatch(
            saveHouseholdMember({
                ...householdMembers?.[0],
                hasEmployerOtherCoverage: toUndefinedOrBoolean(isYes),
            })
        );
        setIsDisabled(false);
    };
    const onlyOne = householdMembers?.length === 1;
    const displayFirstName = getDisplayFirstName(householdMembers?.[0]);
    const description = useMemo(() => {
        if (onlyOne) {
            return `If ${displayFirstName}'s ${DESCRIPTION_SUFFIX}`;
        }
        return `If a household member's ${DESCRIPTION_SUFFIX}`;
    }, [displayFirstName, onlyOne]);
    const formTitle = useMemo(() => {
        if (onlyOne) {
            return `Does ${displayFirstName}'s job offer health coverage?`;
        }
        return 'Do any of your household members have a job that offers health coverage?';
    }, [displayFirstName, onlyOne]);

    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled}>
            {children}
            <FormTitle description={description}>{formTitle}</FormTitle>
            {onlyOne ? (
                <YesOrNoSelect
                    onSelection={onYesNoSelection}
                    value={householdMembers?.[0]?.hasEmployerOtherCoverage}
                />
            ) : (
                <YesNoWho
                    isSelectedProp={nameof<ISurveyHouseholdMemberDto>('hasEmployerOtherCoverage')}
                    onSelection={onSelection}
                    options={householdMembers ?? []}
                />
            )}
        </SurveyFormWrapper>
    );
};

export default hot(module)(EmployerOtherCoverageForm);
