import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
    SEND_PASSWORD_RESET_EMAIL_ACTION,
    sendPasswordResetEmail,
} from 'actions/user/sendPasswordResetEmail';
import Form from 'components/Form';
import FormButton from 'components/FormButton';
import TextField from 'components/TextField';
import UnauthenticatedPageHeader from 'components/UnauthenticatedPageHeader';
import { push } from 'connected-react-router';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { LOGIN_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

const MAX_EMAIL_LENGTH = 256;
const schema = object({
    email: string()
        .trim()
        .email()
        .required()
        .max(MAX_EMAIL_LENGTH)
        .label('Email'),
});

const ForgotPasswordForm = () => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, SEND_PASSWORD_RESET_EMAIL_ACTION),
    }));
    const [body, setBody] = useState(
        "Enter your email address and we'll send you an email with instructions to reset your password."
    );
    const [buttonText, setButtonText] = useState('Send Password Reset Email');
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [title, setTitle] = useState('Forgot Your Password?');
    const { errors, validate } = useForm(schema);

    const handleSubmit = async () => {
        if (!emailSent) {
            const { isValid } = await validate({ email });
            if (isValid) {
                await dispatch(sendPasswordResetEmail(email));
                setBody(
                    `If an account for ${email} exists, you will receive an email with a link to reset your password`
                );
                setButtonText('Return to Login');
                setEmailSent(true);
                setTitle('Check Your Email!');
            }
        } else {
            dispatch(push(LOGIN_PATH));
        }
    };

    return (
        <Card>
            <UnauthenticatedPageHeader />
            <CardContent className="p-4">
                <div className="text-center">
                    <Typography
                        color="grey.900"
                        fontWeight="bold"
                        mt={0}
                        textAlign="center"
                        variant="h4"
                    >
                        {title}
                    </Typography>
                    <p className="text-muted">{body}</p>
                </div>
                <Form isLoading={isLoading} onSubmit={handleSubmit}>
                    {!emailSent && (
                        <TextField
                            errors={errors?.email}
                            label="Email"
                            name="email"
                            onChange={onChange(setEmail)}
                            placeholder="Enter your email"
                            type="email"
                            value={email}
                        />
                    )}
                    <FormButton className="mt-3" isLoading={isLoading} label={buttonText} />
                </Form>
            </CardContent>
        </Card>
    );
};

export default hot(module)(ForgotPasswordForm);
