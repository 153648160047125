import MultiSelectButton from 'pages/survey/multiSelectButtons/MultiSelectButton';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

export type Item = {
    autoFocus?: boolean;
    firstName?: string;
    isRequired?: boolean;
    isSelected?: boolean;
};
export type IMultiSelectButtonsProps<T extends Item> = {
    displayProp?: keyof T;
    hideCheckmark?: boolean;
    isInRow?: boolean;
    isSelectedProp?: keyof T;
    onSelection: (item: T, index: number) => void;
    optionalDisplayProp?: keyof T;
    options: T[];
};
const MultiSelectButtons = <T extends Item>({
    options,
    displayProp = 'firstName',
    hideCheckmark = false,
    isInRow = false,
    isSelectedProp = 'isSelected',
    onSelection,
}: IMultiSelectButtonsProps<T>) => (
    <Row className="justify-content-center">
        <Col className="row" md="7">
            {options.map((option, index) => {
                const showCheckmark = option[isSelectedProp] && !hideCheckmark;
                return (
                    <MultiSelectButton
                        index={index}
                        isInRow={isInRow}
                        isSelectedProp={isSelectedProp}
                        key={index}
                        onSelection={onSelection}
                        option={option}
                        showCheckmark={showCheckmark}
                    >
                        {option[displayProp]}
                        {option.isRequired && ' - primary member required.'}
                    </MultiSelectButton>
                );
            })}
        </Col>
    </Row>
);

export default hot(module)(MultiSelectButtons);
