import Select from 'components/Select';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveIncomeForm } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const FilingStatusForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { filingStatus, filingStatusList } = useSelector((state: AppStore) => ({
        filingStatus: state.surveyState.income.filingStatus,
        filingStatusList: state.options.filingStatusOptions,
    }));
    const onChange = useCallback(
        async ({ target: { value } }) => dispatch(saveIncomeForm({ filingStatus: value })),
        [dispatch]
    );
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={filingStatus === ''}>
            {children}
            <FormTitle>What is your filing status?</FormTitle>
            <Row className="justify-content-center pb-3">
                <Col md="7">
                    <Select
                        autoFocus
                        data-cy="filing-status"
                        defaultText="Select Filing Status"
                        defaultValue=""
                        items={filingStatusList}
                        onChange={onChange}
                        optionText="name"
                        optionValue="filingStatusId"
                        value={filingStatus}
                    />
                </Col>
            </Row>
        </SurveyFormWrapper>
    );
};

export default hot(module)(FilingStatusForm);
