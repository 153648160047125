import { AppStoreThunkDispatch } from 'actions/commonAction';
import { addTeam, ADD_TEAM_ACTION } from 'actions/team/addTeam';
import { TeamStateIds } from 'api/generated/enums';
import TeamForm from 'pages/addTeam/TeamForm';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

type AddTeamPageProps = DispatchProps & StateProps;

class AddTeamPage extends React.PureComponent<AddTeamPageProps> {
    handleSubmit = async (
        _teamId: string,
        name: string,
        isCustomer: boolean | undefined,
        isPartner: boolean,
        isAdvisor: boolean | undefined,
        addressLine1: string,
        addressLine2: string,
        city: string,
        state: string,
        zip: string,
        county: string,
        countyFips: string,
        email: string,
        teamStateId: TeamStateIds | undefined
    ) => {
        await this.props.addTeam(
            name,
            isCustomer,
            isPartner,
            isAdvisor,
            addressLine1,
            addressLine2,
            city,
            state,
            zip,
            county,
            countyFips,
            email,
            teamStateId
        );
    };

    override render() {
        return <TeamForm {...this.props} handleSubmit={this.handleSubmit} />;
    }
}

const mapStateToProps = (state: AppStore) => ({
    apiError: !!state.apiErrors[ADD_TEAM_ACTION.statusCode],
    apiErrorMessage: state.apiErrors[ADD_TEAM_ACTION.errorMessage] as string,
    apiErrorStatusCode: state.apiErrors[ADD_TEAM_ACTION.statusCode] as number,
});

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    addTeam: async (
        name: string,
        isCustomer: boolean | undefined,
        isPartner: boolean,
        isAdvisor: boolean | undefined,
        addressLine1: string,
        addressLine2: string,
        city: string,
        state: string,
        zip: string,
        county: string,
        countyFips: string,
        email: string,
        teamStateId: TeamStateIds | undefined
    ) =>
        dispatch(
            addTeam(
                name,
                isCustomer,
                isPartner,
                isAdvisor,
                addressLine1,
                addressLine2,
                city,
                state,
                zip,
                county,
                countyFips,
                email,
                teamStateId
            )
        ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(AddTeamPage));
