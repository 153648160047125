import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import {
    PATCH_MEMBER_QUOTE_INFO_ACTION,
    patchMemberQuoteInfo,
} from 'actions/user/patchMemberQuoteInfo';
import { PathwayTypes } from 'api/generated/enums';
import { IActiveWageUp, IPathwayUser, IUser } from 'api/generated/models';
import CurrencyTextField from 'components/CurrencyTextField';
import Skeleton from 'components/Skeleton';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivityWithParams } from 'selectors/activity';
import { stringToFloat } from 'utilities';
import { onChange, onEnterPress } from 'utilities/forms';
import { number, object } from 'yup';

export type IPathwayComparisonRowData = IPathwayUser & {
    activeWageUp?: IActiveWageUp;
    disabled?: boolean;
    pathwayTypeId?: PathwayTypes;
    teamId?: string;
    user?: IUser;
    year?: number;
};

const isLoadingSelector = (userId: string) => (state: AppStore) => ({
    isLoading:
        hasApiActivityWithParams(state, PATCH_MEMBER_QUOTE_INFO_ACTION, {
            userId,
        }) ||
        hasApiActivityWithParams(state, GET_USER_PROFILE_ACTION, {
            userId,
        }),
});

const currentEmployerCostCellSchema = object({
    currentEmployerCost: number()
        .transform(stringToFloat)
        .required()
        .min(0)
        .label('Current Employer Cost'),
});

export const CurrentEmployerCostCell = ({
    row: { original },
    value,
}: Cell<IPathwayComparisonRowData, number>) => {
    const dispatch = useThunkDispatch();
    const { pathwayBlueprintId, userId, year } = original;
    const { isLoading } = useSelector(isLoadingSelector(userId));

    const [currentEmployerCost, setCurrentEmployerCost] = useState(value);
    const { errors, setErrors, validate } = useForm(currentEmployerCostCellSchema);

    const save = async () => {
        if (value === currentEmployerCost) {
            setErrors(null);
            return;
        }
        const { isValid } = await validate({ currentEmployerCost });
        if (isValid) {
            await dispatch(
                patchMemberQuoteInfo(
                    userId,
                    year,
                    { previousEmployerPremium: currentEmployerCost },
                    false
                )
            );
            await dispatch(getPathwayBlueprint(pathwayBlueprintId));
        }
    };

    return (
        <Skeleton count={1} height="54px" isEnabled={isLoading} width="130px">
            <CurrencyTextField
                errors={errors?.currentEmployerCost}
                id={`${userId}-er-cost`}
                name="currentEmployerCost"
                onBlur={save}
                onChange={onChange(setCurrentEmployerCost)}
                onKeyPress={onEnterPress(save)}
                placeholder="Enter Current Employer Cost"
                value={currentEmployerCost}
            />
        </Skeleton>
    );
};

const renewalEmployerCostCellSchema = object({
    renewalEmployerCost: number()
        .transform(stringToFloat)
        .required()
        .min(0)
        .label('Renewal Employer Cost'),
});

export const RenewalEmployerCostCell = ({
    row: { original },
    value,
}: Cell<IPathwayComparisonRowData, number>) => {
    const dispatch = useThunkDispatch();
    const { pathwayBlueprintId, userId, year } = original;
    const { isLoading } = useSelector(isLoadingSelector(userId));

    const [renewalEmployerCost, setRenewalEmployerCost] = useState(value);
    const { errors, setErrors, validate } = useForm(renewalEmployerCostCellSchema);

    const save = async () => {
        if (value === renewalEmployerCost) {
            setErrors(null);
            return;
        }
        const { isValid } = await validate({ renewalEmployerCost });
        if (isValid) {
            await dispatch(
                patchMemberQuoteInfo(
                    userId,
                    year,
                    { previousRenewalEmployerPremium: renewalEmployerCost },
                    false
                )
            );
            await dispatch(getPathwayBlueprint(pathwayBlueprintId));
        }
    };

    return (
        <Skeleton count={1} height="54px" isEnabled={isLoading} width="130px">
            <CurrencyTextField
                errors={errors?.renewalEmployerCost}
                id={`${userId}-ee-cost`}
                name="renewalEmployerCost"
                onBlur={save}
                onChange={onChange(setRenewalEmployerCost)}
                onKeyPress={onEnterPress(save)}
                placeholder="Enter Renewal Employer Cost"
                value={renewalEmployerCost}
            />
        </Skeleton>
    );
};

const currentEmployeeCostCellSchema = object({
    currentEmployeeCost: number()
        .transform(stringToFloat)
        .required()
        .min(0)
        .label('Current Employee Cost'),
});

export const CurrentEmployeeCostCell = ({
    row: { original },
    value,
}: Cell<IPathwayComparisonRowData, number>) => {
    const dispatch = useThunkDispatch();
    const { pathwayBlueprintId, userId, year } = original;
    const { isLoading } = useSelector(isLoadingSelector(userId));

    const [currentEmployeeCost, setCurrentEmployeeCost] = useState(value);
    const { errors, setErrors, validate } = useForm(currentEmployeeCostCellSchema);

    const save = async () => {
        if (value === currentEmployeeCost) {
            setErrors(null);
            return;
        }
        const { isValid } = await validate({ currentEmployeeCost });
        if (isValid) {
            await dispatch(
                patchMemberQuoteInfo(
                    userId,
                    year,
                    { previousEmployeePremium: currentEmployeeCost },
                    false
                )
            );
            await dispatch(getPathwayBlueprint(pathwayBlueprintId));
        }
    };

    return (
        <Skeleton count={1} height="54px" isEnabled={isLoading} width="130px">
            <CurrencyTextField
                errors={errors?.currentEmployeeCost}
                id={`${userId}-ee-cost`}
                name="currentEmployeeCost"
                onBlur={save}
                onChange={onChange(setCurrentEmployeeCost)}
                onKeyPress={onEnterPress(save)}
                placeholder="Enter Current Employee Cost"
                value={currentEmployeeCost}
            />
        </Skeleton>
    );
};

const renewalEmployeeCostCellSchema = object({
    renewalEmployeeCost: number()
        .transform(stringToFloat)
        .required()
        .min(0)
        .label('Renewal Employee Cost'),
});

export const RenewalEmployeeCostCell = ({
    row: { original },
    value,
}: Cell<IPathwayComparisonRowData, number>) => {
    const dispatch = useThunkDispatch();
    const { pathwayBlueprintId, userId, year } = original;
    const { isLoading } = useSelector(isLoadingSelector(userId));

    const [renewalEmployeeCost, setRenewalEmployeeCost] = useState(value);
    const { errors, setErrors, validate } = useForm(renewalEmployeeCostCellSchema);

    const save = async () => {
        if (value === renewalEmployeeCost) {
            setErrors(null);
            return;
        }
        const { isValid } = await validate({ renewalEmployeeCost });
        if (isValid) {
            await dispatch(
                patchMemberQuoteInfo(
                    userId,
                    year,
                    { previousRenewalEmployeePremium: renewalEmployeeCost },
                    false
                )
            );
            await dispatch(getPathwayBlueprint(pathwayBlueprintId));
        }
    };

    return (
        <Skeleton count={1} height="54px" isEnabled={isLoading} width="130px">
            <CurrencyTextField
                errors={errors?.renewalEmployeeCost}
                id={`${userId}-ee-cost`}
                name="renewalEmployeeCost"
                onBlur={save}
                onChange={onChange(setRenewalEmployeeCost)}
                onKeyPress={onEnterPress(save)}
                placeholder="Enter Renewal Employee Cost"
                value={renewalEmployeeCost}
            />
        </Skeleton>
    );
};
