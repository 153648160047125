import { HouseholdMemberTypes } from 'api/generated/enums';
import StepIntro from 'pages/survey/StepIntro';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasMemberType } from 'utilities/household';

const IncomeIntro = ({ onSubmit }: { onSubmit: React.FormEventHandler }) => {
    const { hasUnclaimed, incomeYear } = useSelector((state: AppStore) => ({
        hasUnclaimed: hasMemberType(
            state.surveyState.household.members,
            HouseholdMemberTypes.Unclaimed
        ),
        incomeYear: state.surveyState.incomeYear,
    }));

    return (
        <StepIntro
            description={
                <span>
                    We need to add income for each member of the household to help determine your
                    healthcare options.
                    {hasUnclaimed && (
                        <React.Fragment>
                            <br />
                            First we&apos;ll ask about income for your taxable household.
                            <br />
                            Then we&apos;ll ask about income for the household members you will not
                            be claiming on your taxes for {incomeYear}.
                        </React.Fragment>
                    )}
                </span>
            }
            onSubmit={onSubmit}
            title="Your household income"
        />
    );
};

export default hot(module)(IncomeIntro);
