import React from 'react';
import { hot } from 'react-hot-loader';

const ThingsToInclude = () => (
    <p className="text-primary">
        <i className="dripicons-checkmark" /> Things to Include
    </p>
);

export default hot(module)(ThingsToInclude);
