import { AppStoreThunkDispatch } from 'actions/commonAction';
import { addTeamNote } from 'actions/teamNote/addTeamNote';
import { deleteTeamNote } from 'actions/teamNote/deleteTeamNote';
import { editTeamNote } from 'actions/teamNote/editTeamNote';
import { getTeamNotes } from 'actions/teamNote/getTeamNotes';
import { toggleArchiveTeamNote } from 'actions/teamNote/toggleArchiveTeamNote';
import { INote, Note as NoteModel } from 'api/generated/models';
import {
    ArchiveTeamNote,
    CreateTeamNote,
    DeleteTeamNote,
    EditTeamNote,
} from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import Checkbox from 'components/Checkbox';
import Note from 'components/note/Note';
import NoteModal from 'components/note/NoteModal';
import { hideNoteModal, showNoteModal } from 'components/note/notesActions';
import PageSectionWrapper from 'components/PageSectionWrapper';
import TableHeader from 'components/TableHeader';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const TeamNotesPage = () => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const {
        canArchiveNotes,
        canCreateNote,
        canDeleteNotes,
        canEditNotes,
        noteToEdit,
        teamNotes,
    } = useSelector((state: AppStore) => ({
        canArchiveNotes: state.login.up.includes(ArchiveTeamNote),
        canCreateNote: state.login.up.includes(CreateTeamNote),
        canDeleteNotes: state.login.up.includes(DeleteTeamNote),
        canEditNotes: state.login.up.includes(EditTeamNote),
        noteToEdit: state.notesState.noteToEdit,
        teamNotes: state.teamNotes,
    }));
    const [showArchivedNotes, setShowArchivedNotes] = useState(false);
    useEffect(() => {
        dispatch(getTeamNotes(teamId));
    }, [dispatch, teamId]);

    const closeNoteModal = useCallback(() => dispatch(hideNoteModal()), [dispatch]);
    const items = useMemo(
        () =>
            [
                {
                    isVisible: canCreateNote,
                    onClick: () => dispatch(showNoteModal({} as INote)),
                    text: 'Add Note',
                },
            ] as IActionButtonItems,
        [canCreateNote, dispatch]
    );
    const [totalArchivedNotes, totalActiveNotes] = useMemo(
        () => partition(teamNotes, (x) => x.isArchived).map((x) => x.length),
        [teamNotes]
    );
    const selectedNotes = useMemo(
        () => (showArchivedNotes ? totalArchivedNotes : totalActiveNotes),
        [showArchivedNotes, totalActiveNotes, totalArchivedNotes]
    );
    const notesToRender = useMemo(
        () =>
            orderBy(
                teamNotes.filter(
                    (x) =>
                        (showArchivedNotes && x.isArchived) || (!showArchivedNotes && !x.isArchived)
                ),
                ['createdDate'],
                ['desc']
            ).map((note, index) => (
                <Note
                    canArchiveNotes={canArchiveNotes}
                    canDeleteNotes={canDeleteNotes}
                    deleteNote={deleteTeamNote}
                    key={index}
                    note={note}
                    toggleArchiveNote={toggleArchiveTeamNote}
                />
            )),
        [teamNotes, showArchivedNotes, canArchiveNotes, canDeleteNotes]
    );
    const label = useMemo(() => `Showing ${selectedNotes} Note${selectedNotes !== 1 ? 's' : ''}`, [
        selectedNotes,
    ]);
    useEffect(() => {
        if (showArchivedNotes && totalArchivedNotes === 0) {
            setShowArchivedNotes(false);
        }
    }, [showArchivedNotes, totalArchivedNotes]);
    const addOrEditNote = useCallback(
        (
            apiMethod: (
                note: NoteModel
            ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>
        ) => (note: NoteModel) => apiMethod(new NoteModel({ ...note, teamId })),
        [teamId]
    );
    return (
        <React.Fragment>
            {noteToEdit && (
                <NoteModal
                    addNote={addOrEditNote(addTeamNote)}
                    canEditNotes={canEditNotes}
                    editNote={addOrEditNote(editTeamNote)}
                    note={noteToEdit}
                    onClose={closeNoteModal}
                />
            )}
            <PageSectionWrapper>
                <TableHeader
                    items={items}
                    label={
                        <span>
                            <Row className="align-items-center">
                                <span>{label}</span>
                                {canArchiveNotes && Number(totalArchivedNotes) > 0 && (
                                    <Checkbox
                                        checked={showArchivedNotes}
                                        className="m-0 ml-3"
                                        label={`View Archived (${totalArchivedNotes})`}
                                        onClick={() => setShowArchivedNotes(!showArchivedNotes)}
                                    />
                                )}
                            </Row>
                        </span>
                    }
                />
            </PageSectionWrapper>

            <Row>{notesToRender}</Row>
        </React.Fragment>
    );
};

export default hot(module)(TeamNotesPage);
