import Grid from '@mui/material/Grid';
import { ISelectedPlan } from 'api/generated/models';
import useUserProps from 'hooks/useUserProps';
import BenefitCard from 'pages/benefits/BenefitCard';
import React from 'react';
import { hot } from 'react-hot-loader';

const BenefitCards = ({ selectedPlans }: { selectedPlans: ISelectedPlan[] }) => {
    const { user } = useUserProps();
    return (
        <Grid container item spacing={4} xs={12}>
            {selectedPlans?.map((benefit) => (
                <BenefitCard benefit={benefit} key={benefit.selectedPlanId} user={user} />
            ))}
        </Grid>
    );
};

export default hot(module)(BenefitCards);
