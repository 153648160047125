import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
    LIST_CUSTOM_QUESTIONS_ACTION,
    listCustomQuestions,
} from 'actions/customQuestions/listCustomQuestions';
import { ICustomQuestion } from 'api/generated/models';
import { IActionButtonItems } from 'components/ActionButtons';
import Checkbox from 'components/Checkbox';
import PageHeader from 'components/PageHeader';
import PageSectionWrapper from 'components/PageSectionWrapper';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { orderBy, partition } from 'lodash';
import CustomQuestionCard from 'pages/teamHba/CustomQuestionCard';
import CustomQuestionModal from 'pages/teamHba/CustomQuestionModal';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const TeamHbaPage = () => {
    const dispatch = useThunkDispatch();
    const { teamId, teamName } = useTeamProps();
    const { customQuestions, isLoadingQuestions } = useSelector((state: AppStore) => ({
        customQuestions: state.customQuestions,
        isLoadingQuestions: hasApiActivity(state, LIST_CUSTOM_QUESTIONS_ACTION),
    }));
    const [showArchivedQuestions, setShowArchivedQuestions] = useState(false);
    const {
        selectedItem: selectedQuestion,
        openModalWithData,
        closeModal,
        isVisible: isQuestionModalVisible,
    } = useModalState<ICustomQuestion>();

    useEffect(() => {
        dispatch(listCustomQuestions(teamId));
    }, [dispatch, teamId]);

    const [totalArchivedQuestions, totalActiveQuestions] = useMemo(
        () => partition(customQuestions, (x) => x.isArchived).map((x) => x.length),
        [customQuestions]
    );

    const items = [
        {
            isVisible: true,
            onClick: openModalWithData(),
            text: 'Add Custom Question',
        },
    ] as IActionButtonItems;

    const selectedQuestions = showArchivedQuestions ? totalArchivedQuestions : totalActiveQuestions;
    const questionsToRender = useMemo(() => {
        let content: ReactNode = <Typography>There are no custom questions.</Typography>;
        const filteredCustomQuestions = orderBy(
            customQuestions.filter(
                (x) =>
                    (showArchivedQuestions && x.isArchived) ||
                    (!showArchivedQuestions && !x.isArchived)
            ),
            ['order', 'archivedDate'],
            ['asc', 'desc']
        );
        if (filteredCustomQuestions.length > 0) {
            content = filteredCustomQuestions.map((question) => (
                <CustomQuestionCard
                    customQuestion={question}
                    key={question.id}
                    onClick={openModalWithData(question)}
                />
            ));
        }
        return content;
    }, [customQuestions, openModalWithData, showArchivedQuestions]);

    const label = `Showing ${selectedQuestions} Question${selectedQuestions !== 1 ? 's' : ''}`;
    useEffect(() => {
        if (showArchivedQuestions && totalArchivedQuestions === 0) {
            setShowArchivedQuestions(false);
        }
    }, [showArchivedQuestions, totalArchivedQuestions]);

    return (
        <React.Fragment>
            <PageSectionWrapper>
                <TableHeader
                    items={items}
                    label={
                        <Row className="align-items-center">
                            <Col className="text-left">
                                <PageHeader variant="h4">Custom HBA Questions</PageHeader>
                                <p className="mb-0">
                                    These questions will be added on to the end of the HBA for{' '}
                                    {teamName} team members
                                </p>
                            </Col>
                            <hr />
                            {label}
                            {Number(totalArchivedQuestions) > 0 && (
                                <Checkbox
                                    checked={showArchivedQuestions}
                                    className="m-0 ml-3"
                                    label={`View Archived (${totalArchivedQuestions})`}
                                    onClick={() => setShowArchivedQuestions(!showArchivedQuestions)}
                                />
                            )}
                        </Row>
                    }
                />
            </PageSectionWrapper>
            {isQuestionModalVisible && (
                <CustomQuestionModal
                    customQuestion={selectedQuestion}
                    onClose={closeModal}
                    teamId={teamId}
                />
            )}

            <Card>
                <CardContent>
                    <Col>
                        <Skeleton
                            count={3}
                            height="100px"
                            isEnabled={isLoadingQuestions}
                            Wrapper={({ children }) => (
                                <div className="col-12 mb-3">{children}</div>
                            )}
                        >
                            {questionsToRender}
                        </Skeleton>
                    </Col>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default hot(module)(TeamHbaPage);
