import { submitSurvey } from 'actions/survey/submitSurvey';
import { HouseholdMemberTypes, SurveyTypes, TeamStateIds, UserStatus } from 'api/generated/enums';
import VerifiedInfoSummary from 'components/verifiedInfoSummary/VerifiedInfoSummary';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import FormTitle from 'pages/survey/FormTitle';
import { ISurveyFormProps } from 'pages/survey/Survey';
import { setTabBarIndex } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import {
    HAS_DEPENDENTS_PROPERTY_NAME,
    HAS_ICHRA_PROPERTY_NAME,
    HAS_UNCLAIMED_PROPERTY_NAME,
} from 'pages/survey/surveyState';
import React, { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const SurveyReview = ({ isIchraPathway, redirectUrl, title, subTitle }: ISurveyFormProps) => {
    const dispatch = useThunkDispatch();
    const [query] = useQuery();
    const { user } = useUserProps();
    const { team } = useTeamProps();
    const { survey } = useSelector((state: AppStore) => ({
        survey: state.surveyState,
    }));
    const isRenewal =
        user?.status === UserStatus.Renewing ||
        (team?.teamStateId === TeamStateIds.Renewing && user?.status === UserStatus.Waived);
    const isIchra = isIchraPathway || user?.surveyTypeToSend === SurveyTypes.ICHRA;
    const includeQualitativeQuestions = survey.includeQualitativeQuestionsInSurvey;
    const hideQualitativeQuestions = isRenewal || !includeQualitativeQuestions;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (isIchra && isRenewal) {
            survey.income.filingStatus = '';
            survey.income.additionalIncome = undefined;
            survey.income.deductions = undefined;
            survey.income.employeeIncome = undefined;
            survey.income.householdIncome = undefined;
            survey.coverage.hasFaithBasedSharingInterest = '';
            survey.coverage.hasParentOtherCoverage = undefined;
            survey.member.isPregnant = undefined;
            survey.member.otherCoverageSource = undefined;
            survey.household[HAS_ICHRA_PROPERTY_NAME] = true;
            survey.household[HAS_DEPENDENTS_PROPERTY_NAME] = false;
            survey.household[HAS_UNCLAIMED_PROPERTY_NAME] = false;
            survey.household.members?.forEach((m) => {
                m.isPregnant = undefined;
                m.income = undefined;
                m.isEmployed = undefined;
                m.employerName = undefined;
                m.householdMemberTypeId =
                    m.householdMemberTypeId !== HouseholdMemberTypes.Spouse
                        ? HouseholdMemberTypes.IchraChild
                        : HouseholdMemberTypes.Spouse;
            });
        }
    }, [isIchra, isRenewal, survey]);

    const onSubmit = useCallback(async () => {
        const token = query.get('t');
        await dispatch(
            submitSurvey(user, survey, token as string, redirectUrl, isIchra as boolean)
        );
    }, [dispatch, isIchra, query, redirectUrl, survey, user]);
    const editSection = useCallback(
        (index) => {
            dispatch(setTabBarIndex(index));
        },
        [dispatch]
    );
    return (
        <SurveyFormWrapper onSubmit={onSubmit} submitText="Submit">
            <div className="mx-sm-7 mx-2">
                <FormTitle description={subTitle}>{title}</FormTitle>
                <VerifiedInfoSummary
                    data={survey}
                    editSection={editSection}
                    hideQualitative={hideQualitativeQuestions}
                    isIchra={isIchra}
                />
            </div>
        </SurveyFormWrapper>
    );
};

export default hot(module)(SurveyReview);
