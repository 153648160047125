import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import TextArea from 'components/TextArea';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveHouseholdMember } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { getDisplayFirstName, isEmployedAndNotSameEmployer } from 'utilities/household';

const employerNameMinCharacters = 2;

const EmployerNameForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { membersEmployed } = useSelector((state: AppStore) => ({
        membersEmployed: state.surveyState.household.members?.filter(isEmployedAndNotSameEmployer),
    }));
    const onEmployerNameChange = (member: ISurveyHouseholdMemberDto) => (employerName: string) =>
        dispatch(saveHouseholdMember({ ...member, employerName }));
    const isDisabled = membersEmployed?.some(
        (h) => !hasValue(h.employerName) || h.employerName.length < employerNameMinCharacters
    );
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled}>
            {children}
            <FormTitle
                description="You marked one or more members as being employed. Please tell us who they
                are currently employed with."
            >
                Employer Name
            </FormTitle>
            {membersEmployed?.map((member, index) => (
                <Row className="justify-content-center" key={member.householdMemberId}>
                    <Col md="7">
                        <TextArea
                            autoFocus={index === 0}
                            className="px-1 mt-4"
                            data-cy={`household-employer-name-${index}`}
                            label={`${getDisplayFirstName(member)}'s Employer Name`}
                            maxLength={500}
                            minRows={2}
                            onChange={onChange(onEmployerNameChange(member))}
                            value={member.employerName}
                        />
                    </Col>
                </Row>
            ))}
        </SurveyFormWrapper>
    );
};

export default hot(module)(EmployerNameForm);
