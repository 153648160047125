import { TeamStateIds } from 'api/generated/enums';
import { EditUserVerifiedInfo } from 'api/generated/permissions';
import { AppStore } from 'reducers/appReducer';
import { ITeamProps, IUserProps } from 'selectors';

export const getIsInProspect = ({ teamStateId }: ITeamProps) =>
    teamStateId === TeamStateIds.Prospect;

export const getIsOwnInfoAndTeamIsProspect = (teamProps: ITeamProps, { isCurrent }: IUserProps) =>
    isCurrent && getIsInProspect(teamProps);

export const getCanEditVerifiedInfo = (
    state: AppStore,
    teamProps: ITeamProps,
    userProps: IUserProps
) => {
    const isOwnInfoAndTeamIsProspect = getIsOwnInfoAndTeamIsProspect(teamProps, userProps);
    const isOtherVerifiedInfoAndHasEditPermission =
        !userProps.isCurrent && state.login.up.includes(EditUserVerifiedInfo);

    return isOwnInfoAndTeamIsProspect || isOtherVerifiedInfoAndHasEditPermission;
};
