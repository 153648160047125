import { Paper } from '@mui/material';
import {
    GET_TEAM_BENEFIT_TYPES_ACTION,
    getTeamBenefitTypes,
} from 'actions/teamBenefitType/getTeamBenefitTypes';
import ActivityIndicator from 'components/ActivityIndicator';
import PageHeader from 'components/PageHeader';
import useThunkDispatch from 'hooks/useThunkDispatch';
import TeamBenefitTypes from 'pages/teamBenefitsManagement/TeamBenefitTypes';
import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const TeamBenefitsManagementPage = () => {
    const dispatch = useThunkDispatch();
    const { showActivity } = useSelector((state: AppStore) => ({
        showActivity: hasApiActivity(state, GET_TEAM_BENEFIT_TYPES_ACTION),
    }));

    useEffect(() => {
        dispatch(getTeamBenefitTypes());
    }, [dispatch]);

    return (
        <Paper>
            <Col>
                <Row className="justify-content-space-between">
                    <PageHeader variant="h4">Team Benefit Types</PageHeader>
                </Row>
                <hr />

                <TeamBenefitTypes />
            </Col>
            {showActivity && <ActivityIndicator />}
        </Paper>
    );
};

export default hot(module)(TeamBenefitsManagementPage);
