import { clearState } from 'actions/clear';
import { getSurveyUserProfile } from 'actions/survey/getSurveyUserProfile';
import ActivityIndicator from 'components/ActivityIndicator';
import useQuery from 'hooks/useQuery';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import BenefitsSurveyComplete from 'pages/survey/BenefitsSurveyComplete';
import BenefitsSurveyIntro from 'pages/survey/BenefitsSurveyIntro';
import Survey from 'pages/survey/Survey';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { BENEFITS_SURVEY_PATH } from 'routers/routes';

const BenefitsSurveyPage = () => {
    const dispatch = useThunkDispatch();
    const [query] = useQuery();
    const email = query.get('email');
    const token = query.get('t');
    const isSurveyCompleted = query.has('complete');
    const [isIntroPage, setIsIntroPage] = useState(true);
    const { hasUser } = useUserProps();
    useEffect(() => {
        if (!isSurveyCompleted) {
            dispatch(clearState());
            dispatch(getSurveyUserProfile(token as string, email as string));
        }
    }, [dispatch, email, isSurveyCompleted, token]);
    if (isSurveyCompleted) {
        return <BenefitsSurveyComplete />;
    }
    const content = hasUser ? (
        <Survey
            redirectUrl={`${BENEFITS_SURVEY_PATH}?complete`}
            subTitle={
                <span>
                    Please review the information and <strong>Submit</strong> below.
                </span>
            }
            title="Review and Submit"
        />
    ) : (
        <ActivityIndicator />
    );
    return isIntroPage ? <BenefitsSurveyIntro onClick={() => setIsIntroPage(false)} /> : content;
};

export default hot(module)(BenefitsSurveyPage);
