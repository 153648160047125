import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { clearGetInfoToSubmitExpense } from 'actions/clear';
import {
    GET_INFO_TO_SUBMIT_EXPENSE_ACTION,
    getUserInfoToSubmitExpense,
} from 'actions/submittedExpenses/getUserInfoToSubmitExpense';
import { SUBMIT_EXPENSE_ACTION, submitExpense } from 'actions/submittedExpenses/submitExpense';
import CurrencyTextField from 'components/CurrencyTextField';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import Select from 'components/Select';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import FileInput from 'components/fileInput/FileInput';
import { VALID_UPLOAD_FILE_EXTENSIONS } from 'constants/fileExtensions';
import { SUBMIT_EXPENSE_HSA_KEY } from 'constants/pactSafeGroups';
import { ExpenseTypeIds } from 'constants/reimbursementProgramExpenseTypes';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { IS_DEV_MODE, stringToFloat, toBoolean } from 'utilities';
import { convertFilesToFileAndName } from 'utilities/files';
import { formatCurrency } from 'utilities/format';
import { onAllPactSafe, onChange } from 'utilities/forms';
import { isValidDate } from 'utilities/moment';
import PactSafe, { PACTSAFE_ACCESS_ID } from 'utilities/pactSafe';
import { boolean, mixed, number, object, string } from 'yup';

const ONE_CENT = 0.01;
const MAX_AMOUNT = 9_999_999.99;
const MAX_EXPENSE_SOURCE_LENGTH = 200;
export const getExpenseSourceLabel = (expenseTypeName: string) =>
    expenseTypeName === 'Medical'
        ? 'Physician, Provider, or Organization'
        : 'Agency or Organization';
type ISubmitExpenseModal = {
    onClose: () => void;
    onSubmit?: () => void;
};

const schema = (expenseSourceLabel: ReturnType<typeof getExpenseSourceLabel>) =>
    object({
        coveredMemberId: string()
            .required()
            .label('Expense For'),
        dateOfService: string()
            .required()
            .isValidDate()
            .label('Date of Service'),
        expenseAmount: number()
            .required()
            .transform(stringToFloat)
            .min(
                ONE_CENT,
                `Expense Amount must be greater than or equal to ${formatCurrency(ONE_CENT, {
                    includeDollarSign: true,
                    preserveDecimal: true,
                })}`
            )
            .max(
                MAX_AMOUNT,
                `Expense Amount must be less than or equal to ${formatCurrency(MAX_AMOUNT, {
                    includeDollarSign: true,
                    preserveDecimal: true,
                })}`
            )
            .label('Expense Amount'),
        expenseSource: string()
            .required()
            .label(expenseSourceLabel)
            .max(
                MAX_EXPENSE_SOURCE_LENGTH,
                `${expenseSourceLabel} value must be less than or equal to ${MAX_EXPENSE_SOURCE_LENGTH} characters`
            ),
        expenseTypeId: number()
            .required()
            .label('Expense Type'),
        files: mixed()
            .isOneOrMoreFiles()
            .label('Files'),
        isRecurringExpense: boolean()
            .transform(toBoolean)
            .required(),
        reimburseToHsa: boolean()
            .transform(toBoolean)
            .required()
            .label('Use HSA Reimbursement'),
    });

const SubmitExpenseModal = ({ onClose, onSubmit: parentOnSubmit }: ISubmitExpenseModal) => {
    const dispatch = useThunkDispatch();
    const { user, userId } = useUserProps();
    const {
        allowRecurringExpenses,
        email,
        expenseTypes,
        householdMembers,
        isAtHsaContributionLimit,
        isEligibleForReimbursementProgram,
        isHsaEligible,
        isLoading,
        isRh,
        useHsaReimbursement,
    } = useSelector((state: AppStore) => ({
        allowRecurringExpenses: state.userInfoToSubmitExpense.allowRecurring,
        email: state.current.userProfile.user?.email,
        expenseTypes: state.userInfoToSubmitExpense?.expenseTypes ?? [],
        householdMembers: state.userInfoToSubmitExpense?.householdMembers ?? [],
        isAtHsaContributionLimit: state.userInfoToSubmitExpense.isAtHsaContributionLimit,
        isEligibleForReimbursementProgram:
            state.userInfoToSubmitExpense?.isEligibleForReimbursementProgram,
        isHsaEligible: state.userInfoToSubmitExpense.isHsaEligible,
        isLoading: hasApiActivity(state, SUBMIT_EXPENSE_ACTION, GET_INFO_TO_SUBMIT_EXPENSE_ACTION),
        isRh: isRhSelector(state),
        useHsaReimbursement: state.userInfoToSubmitExpense.useHsaForReimbursement,
    }));
    const [coveredMemberId, setCoveredMemberId] = useState<string>('');
    const [dateOfService, setDateOfService] = useState<string>('');
    const [expenseTypeId, setExpenseTypeId] = useState<number>();
    const [expenseAmount, setExpenseAmount] = useState<number>();
    const [files, setFiles] = useState<File | File[] | undefined>();
    const [expenseSource, setExpenseSource] = useState<string>('');
    const [canReimburseToHsa, setCanReimburseToHsa] = useState<boolean>();
    const [reimburseToHsa, setReimburseToHsa] = useState<boolean>(false);
    const [hasAgreed, setHasAgreed] = useState(false);
    const [isRecurringExpense, setIsRecurring] = useState<boolean>(false);

    const isMedicalExpenseType = Number(expenseTypeId) === ExpenseTypeIds.Medical;
    const isMemberMedicalFile = !isRh && isMedicalExpenseType;
    const isMemberNotMedicalFile = !isRh && !isMedicalExpenseType;

    useEffect(
        () => () => {
            dispatch(clearGetInfoToSubmitExpense());
        },
        [dispatch]
    );

    useEffect(() => {
        if (isValidDate(dateOfService)) {
            setCanReimburseToHsa(useHsaReimbursement && isHsaEligible);
        }
    }, [dateOfService, expenseTypes, isHsaEligible, useHsaReimbursement]);

    useEffect(() => {
        if (isValidDate(dateOfService)) {
            const fetchHsaEligibility = async () => {
                dispatch(getUserInfoToSubmitExpense(userId, dateOfService));
            };
            fetchHsaEligibility();
        } else {
            setReimburseToHsa(false);
        }
    }, [dateOfService, dispatch, userId]);

    const coveredMemberOptions = useMemo(() => {
        const options = householdMembers.map((hhm) => ({
            id: hhm.householdMemberId,
            name: hhm.firstName,
        }));
        options.unshift({ id: userId, name: user?.firstName });
        return options;
    }, [householdMembers, user?.firstName, userId]);
    const expenseSourceLabel = useMemo(
        () =>
            getExpenseSourceLabel(
                expenseTypes.filter((et) => et.id === Number(expenseTypeId))[0]?.name as string
            ),
        [expenseTypeId, expenseTypes]
    );

    const { errors, validate } = useForm(schema(expenseSourceLabel));

    const onSubmit = async () => {
        const { isValid } = await validate({
            coveredMemberId,
            dateOfService,
            expenseAmount,
            expenseSource,
            expenseTypeId,
            files,
            isRecurringExpense,
            reimburseToHsa,
        });
        if (isValid) {
            const filesAndNames = convertFilesToFileAndName(files);
            await dispatch(
                submitExpense(
                    userId,
                    coveredMemberId,
                    expenseTypeId as number,
                    expenseAmount as number,
                    expenseSource,
                    dateOfService,
                    filesAndNames,
                    isRecurringExpense,
                    reimburseToHsa
                )
            );
            if (!isRh) {
                PactSafe.agreeToContract(SUBMIT_EXPENSE_HSA_KEY);
            }
            onClose();
            parentOnSubmit?.();
        }
    };

    const dateOfServiceIneligibleText = isValidDate(dateOfService) && !isLoading && (
        <Typography>
            Member is not eligible for a reimbursement program on the specified Date of Service.
        </Typography>
    );

    const hsaReimbursementOptions = [
        {
            text:
                'Yes, I would like my reimbursement to be deducted pre-tax and deposited into my Health Savings Account (HSA)',
            value: true,
        },
        {
            text: 'No, I would like my reimbursement to go to my taxable wages',
            value: false,
        },
    ];

    return (
        <Dialog fullWidth maxWidth="sm" onClose={onClose} open>
            <DialogTitle>Submit Expense</DialogTitle>
            <DialogContent dividers>
                <Form id="submit-expense-modal-form" isLoading={isLoading} onSubmit={onSubmit}>
                    <Stack gap={2}>
                        <DateTextField
                            errors={errors?.dateOfService}
                            label="What was the date of service for this expense?"
                            onChange={onChange(setDateOfService)}
                            placeholder="Enter Date of Service"
                            value={dateOfService}
                        />
                        {isEligibleForReimbursementProgram ? (
                            <React.Fragment>
                                <Select
                                    data-cy="submit-expense-covered-member-dropdown"
                                    defaultText="--"
                                    errors={errors?.coveredMemberId}
                                    items={coveredMemberOptions}
                                    label="Who is the expense for?"
                                    name="coveredMemberId"
                                    onChange={onChange(setCoveredMemberId)}
                                    optionText="name"
                                    optionValue="id"
                                    value={coveredMemberId}
                                />
                                <Select
                                    data-cy="expense-type-dropdown"
                                    defaultText="--"
                                    errors={errors?.expenseTypeId}
                                    items={expenseTypes}
                                    label="What type of expense are you submitting?"
                                    name="expenseType"
                                    onChange={onChange(setExpenseTypeId)}
                                    optionText="name"
                                    optionValue="id"
                                    value={expenseTypeId}
                                />
                                {allowRecurringExpenses && (
                                    <Select
                                        errors={errors?.isRecurringExpense}
                                        helperText="If you select yes and this is approved, the expense will be reimbursed on a monthly basis."
                                        items={yesOrNo}
                                        label="Is this a recurring expense?"
                                        onChange={onChange(setIsRecurring)}
                                        optionText="text"
                                        optionValue="value"
                                        value={isRecurringExpense}
                                    />
                                )}
                                <CurrencyTextField
                                    data-cy="expense-amount-field"
                                    errors={errors?.expenseAmount}
                                    label="Expense Amount"
                                    min={ONE_CENT}
                                    name="expenseAmount"
                                    onChange={onChange(setExpenseAmount)}
                                    placeholder="Enter expense amount"
                                    value={expenseAmount}
                                />
                                <TextField
                                    data-cy="expense-source-field"
                                    errors={errors?.expenseSource}
                                    label={expenseSourceLabel}
                                    name="expenseSource"
                                    onChange={onChange(setExpenseSource)}
                                    placeholder={`Enter in ${expenseSourceLabel}`}
                                    value={expenseSource}
                                />
                                {canReimburseToHsa && isMedicalExpenseType && (
                                    <Select
                                        disabled={isAtHsaContributionLimit}
                                        errors={errors?.reimburseToHsa}
                                        helperText={
                                            isAtHsaContributionLimit
                                                ? "You've reached your HSA contribution limit for the year, so you can no longer put your reimbursement into your HSA"
                                                : ''
                                        }
                                        items={hsaReimbursementOptions}
                                        label="Do you want your reimbursement to go to your HSA?"
                                        onChange={onChange(setReimburseToHsa)}
                                        optionText="text"
                                        optionValue="value"
                                        value={reimburseToHsa}
                                    />
                                )}
                                <div>
                                    <br />
                                    <FileInput
                                        accept={VALID_UPLOAD_FILE_EXTENSIONS}
                                        data-cy="file-input-field"
                                        errors={errors?.files}
                                        fileData={files}
                                        isMultiple
                                        label="Expense Files"
                                        onChange={setFiles}
                                    />
                                </div>
                                {isMemberMedicalFile && (
                                    <Typography className="align-self-center" fontSize="12px">
                                        For medical expenses, you must submit an Explanation of
                                        Benefits from your carrier.
                                    </Typography>
                                )}
                                {isMemberNotMedicalFile && (
                                    <Typography className="align-self-center" fontSize="12px">
                                        Should be a receipt or document that shows the expense with
                                        the amount.
                                    </Typography>
                                )}
                                {!isRh && (
                                    <div>
                                        <br />
                                        <PSClickWrap
                                            accessId={PACTSAFE_ACCESS_ID}
                                            disableSending
                                            groupKey={SUBMIT_EXPENSE_HSA_KEY}
                                            onAll={onAllPactSafe(setHasAgreed)}
                                            signerId={email}
                                            testMode={IS_DEV_MODE}
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        ) : (
                            dateOfServiceIneligibleText
                        )}
                    </Stack>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text">
                    Close
                </Button>
                <LoadingButton
                    data-cy="submit-expense-form-submit-button"
                    disabled={!hasAgreed && !isRh}
                    form="submit-expense-modal-form"
                    loading={isLoading}
                    type="submit"
                    variant="text"
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(SubmitExpenseModal);
