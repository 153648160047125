import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { addExpenseType } from 'actions/expenseTypes/addExpenseTypes';
import { GET_EXPENSE_TYPES_ACTION, getExpenseTypes } from 'actions/expenseTypes/getExpenseTypes';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

const EXPENSE_TYPE_NAME_MAX_CHARACTERS = 100;

const ExpenseTypeModal = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useThunkDispatch();
    const { expenseTypes, isLoading } = useSelector((state: AppStore) => ({
        expenseTypes: state.expenseTypes,
        isLoading: hasApiActivity(state, GET_EXPENSE_TYPES_ACTION),
    }));
    const [expenseTypeName, setExpenseTypeName] = useState<string>();

    const schema = () =>
        object({
            expenseTypeName: string()
                .required()
                .max(EXPENSE_TYPE_NAME_MAX_CHARACTERS)
                .label('Expense Type Name')
                .test(
                    'expense type duplicate validation',
                    () => true,
                    (typeName, testContext) => {
                        const name = typeName as string;
                        if (expenseTypeNames.contains(name)) {
                            return testContext.createError({
                                message: 'Expense Type Name already in use.',
                            });
                        }
                        return true;
                    }
                ),
        });
    const expenseTypeNames = expenseTypes.map((x) => x.name) as string[];
    const { errors, validate } = useForm(schema());

    useEffect(() => {
        dispatch(getExpenseTypes());
    }, [dispatch]);

    const submitForm = async () => {
        const { isValid } = await validate({
            expenseTypeName,
        });
        if (hasValue(expenseTypeName) && isValid) {
            await dispatch(addExpenseType(expenseTypeName.trim()));
            onClose();
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" onClose={onClose} open>
            <DialogTitle>Add Expense Type</DialogTitle>
            <DialogContent dividers>
                <Form id="expense-type-modal-form" isLoading={isLoading} onSubmit={submitForm}>
                    <Stack gap={2} spacing={4}>
                        <Typography variant="body2">
                            After adding a new Expense Type it will become available for use when
                            creating a new Reimbursement Program Team Benefit for a Team. Created
                            Expense Types will only have Flat Annual Reimbursement Amounts. After
                            creating an Expense Type it cannot be changed or deleted.
                        </Typography>
                        <TextField
                            autoFocus
                            data-cy="expense-type-name"
                            errors={errors?.expenseTypeName}
                            isLoading={isLoading}
                            label="Expense Type Name"
                            name="name"
                            onChange={onChange(setExpenseTypeName)}
                            placeholder="Enter Expense Type Name"
                            value={expenseTypeName}
                        />
                    </Stack>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text">
                    Close
                </Button>
                <Button
                    disabled={!hasValue(expenseTypeName)}
                    form="expense-type-modal-form"
                    isLoading={isLoading}
                    type="submit"
                    variant="text"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(ExpenseTypeModal);
