import { IPathwayUser } from 'api/generated/models';
import { IPathwayRowData } from 'pages/pathwayBlueprint/PathwayTableCells';
import basicReducer from 'reducers/basicReducer';
import {
    CLOSE_CUSTOM_PLAN_COST_MODAL,
    OPEN_CUSTOM_PLAN_COST_MODAL,
} from 'pages/pathwayBlueprint/pathwayTotalCostModalActions';

export type IPathwayModalState = {
    isCustomPlanCostModalVisible: false;
    pathwayUser: IPathwayRowData | IPathwayUser;
};
const initialState: IPathwayModalState = {
    isCustomPlanCostModalVisible: false,
    pathwayUser: ({} as unknown) as IPathwayRowData | IPathwayUser,
};
export const customPlanCostModalState = basicReducer(
    initialState,
    CLOSE_CUSTOM_PLAN_COST_MODAL,
    OPEN_CUSTOM_PLAN_COST_MODAL);
