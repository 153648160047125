import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { EDIT_USER_BUDGET_ACTION } from 'actions/user/addOrEditUserBudget';
import Button from 'components/Button';
import Form from 'components/Form';
import Select from 'components/Select';
import TextField from 'components/TextField';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { decimalToPercentageString } from 'utilities';
import { onChange } from 'utilities/forms';

const editableFicaStartYear = 2021;

const ficaRates = [
    { name: '0%', value: 0 },
    { name: '7.65%', value: 0.0765 },
    { name: '15.30%', value: 0.153 },
];

type IEditBudgetModalProps = {
    close: () => void;
    save: (ficaTaxRate: number) => void;
};

const EditTaxesModal = ({ close, save }: IEditBudgetModalProps) => {
    const { isLoading, taxData } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, EDIT_USER_BUDGET_ACTION),
        lastModifiedByUser: state.user,
        taxData: state.taxData,
    }));

    const [ficaTaxRate, setFicaTaxRate] = useState(taxData?.ficaTaxRate);

    const submit = useCallback(() => {
        save(ficaTaxRate);
    }, [save, ficaTaxRate]);

    const totalTaxRate = useMemo(() => {
        if (
            taxData.federalTaxRate === undefined ||
            ficaTaxRate === undefined ||
            taxData.stateTaxRate === undefined
        ) {
            return undefined;
        }
        return taxData.federalTaxRate + Number(ficaTaxRate) + taxData.stateTaxRate;
    }, [ficaTaxRate, taxData]);
    const canEdit = taxData.year >= editableFicaStartYear;
    return (
        <Modal onHide={close} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Tax Rates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="edit-taxes-modal" isLoading={isLoading} onSubmit={submit}>
                    <Stack gap={2}>
                        <TextField
                            disabled
                            label="Federal Tax Rate"
                            value={decimalToPercentageString(taxData.federalTaxRate)}
                        />
                        <Select
                            disabled={!canEdit}
                            items={ficaRates}
                            label="FICA Tax Rate"
                            onChange={onChange(setFicaTaxRate)}
                            optionText="name"
                            optionValue="value"
                            value={ficaTaxRate}
                        />
                        {!canEdit && (
                            <p className="text-danger">
                                FICA Tax Rate cannot be edited for budgets prior to 2021
                            </p>
                        )}
                        <TextField
                            disabled
                            label="State Tax Rate"
                            value={decimalToPercentageString(taxData.stateTaxRate)}
                        />
                        <Typography my={3} textAlign="center" variant="h5">
                            Total Tax Rate:&nbsp; {decimalToPercentageString(totalTaxRate)}
                        </Typography>
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={close}>Close</Button>
                {canEdit && (
                    <Button form="edit-taxes-modal" isLoading={isLoading} type="submit">
                        Save
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(EditTaxesModal);
