import { Card, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
    clearCounties,
    clearGetCountiesError,
    clearHouseholdMembers,
    clearUser,
    clearUserApiErrors,
    clearUserProfile
} from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { getStates } from 'actions/getStates';
import { getTeamProfile } from 'actions/team/getTeamProfile';
import { ADD_USER_ACTION } from 'actions/user/addUser';
import { EDIT_USER_ACTION } from 'actions/user/editUser';
import { getDefaultMemberDataYear } from 'actions/user/getDefaultMemberDataYear';
import { GroupLevels, TeamStateIds } from 'api/generated/enums';
import {
    Address,
    IAddress,
    IMemberQuoteInfo,
    IUser,
    IUserProfile,
    IYearlyUserInfoDto,
    MemberQuoteInfo,
    User,
    YearlyUserInfoDto
} from 'api/generated/models';
import Form from 'components/Form';
import FormButton from 'components/FormButton';
import PageHeader from 'components/PageHeader';
import AddressInput from 'components/addressInput/AddressInput';
import {
    clearAddressInputs, validateAndCheckMatchTeamAddress,
} from 'components/addressInput/addressInputActions';
import { IAddressInputs } from 'components/addressInput/addressInputState';
import HTTP_STATUS from 'constants/responseStatuses';
import isUndefined from 'lodash/isUndefined';
import moment from 'moment';
import BasicInfoFormUI from 'pages/addPerson/BasicInfoFormUI';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { getTeamProps, getUserProps } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { hasValue, stringToFloat, stringToInt } from 'utilities';
import { formatDateForApi, formatDateForDisplay } from 'utilities/format';
import { IFormErrors, formatErrors, validate } from 'utilities/forms';
import { getYearOrDefault, getYears } from 'utilities/year';
import { ValidationError, number, object, string } from 'yup';
import MemberQuoteInfoFormUI from './MemberQuoteInfoFormUI';

const MAX_EMAIL_LENGTH = 256;
const ONE_HUNDRED = 100;
const userSchema = object({
    email: string()
        .trim()
        .required()
        .email()
        .max(MAX_EMAIL_LENGTH)
        .label('Email'),
    employeeAnnualIncome: number()
        .required()
        .transform(stringToFloat)
        .min(0)
        .label('Employee Annual Income'),
    employeeDateOfBirth: string()
        .required()
        .isValidDate()
        .label('Employee Date of Birth'),
    firstName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('First Name'),
    lastName: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('Last Name'),
    phone: string()
        .required()
        .isValidPhoneNumber()
        .label('Phone'),
    spouseDateOfBirth: string()
        .isValidDate(false)
        .label('Spouse Date of Birth'),
});
const IS_NOT_CUSTOMER_OR_RENEWING = '$isNotCustomerOrRenewing';
const maxActiveDate = `01/01/${Math.max(...getYears(() => 2)).toString()}`;
const minActiveDate = `12/31/${(Math.min(...getYears()) - 1).toString()}`;
const memberQuoteInfoSchema = object({
    activeDate: string()
        .when('$isInProspect', {
            is: false,
            then: string().required(),
        })
        .isValidDate(false)
        .isBefore({
            message: (_, baseMessage) => `${baseMessage} ${maxActiveDate}`,
            momentValue: moment(maxActiveDate)
        })
        .isAfter({
            message: (_, baseMessage) => `${baseMessage} ${minActiveDate}`,
            momentValue: moment(minActiveDate)
        })
        .label('Active Date'),
    dependentsOver20: number()
        .transform(stringToInt)
        .when(IS_NOT_CUSTOMER_OR_RENEWING, {
            is: true,
            then: (schema) => schema.required(),
        })
        .label('Dependents 21 and Over'),
    dependentsUnder21: number()
        .transform(stringToInt)
        .when(IS_NOT_CUSTOMER_OR_RENEWING, {
            is: true,
            then: (schema) => schema.required(),
        })
        .label('Dependents Under 21'),
    majorMedicalCoverageLevel: string()
        .required()
        .label('Major Medical Coverage Level'),
    previousEmployeePremium: number()
        .transform(stringToFloat)
        .when(IS_NOT_CUSTOMER_OR_RENEWING, {
            is: true,
            then: number()
                .required()
                .min(0),
        })
        .label('Previous Employee Premium'),
    previousEmployerPremium: number()
        .transform(stringToFloat)
        .when(IS_NOT_CUSTOMER_OR_RENEWING, {
            is: true,
            then: number()
                .required()
                .min(0),
        })
        .label('Previous Employer Premium'),
    previousGroupLevelId: string().label('Previous Group Coverage Level'),
});

type IUserFormProps = DispatchProps &
    RouteComponentProps &
    StateProps & {
        apiError: boolean;
        apiErrorStatusCode: number;
        handleSubmit: (userProfile: IUserProfile, isCustomer: boolean) => void;
        isEditMode?: boolean;
    };
export type IUserFormState = {
    activeDate?: string;
    dependentsOver20?: string;
    dependentsUnder21?: string;
    email?: string;
    employeeAnnualIncome?: string;
    employeeDateOfBirth?: string;
    firstName?: string;
    lastName?: string;
    majorMedicalCoverageLevel?: string;
    memberQuoteInfoErrors: IFormErrors<typeof memberQuoteInfoSchema>;
    phone?: string;
    previousEmployeePremium?: string;
    previousEmployerPremium?: string;
    previousGroupLevelId?: string;
    shouldPopulateForm: boolean;
    spouseDateOfBirth?: string;
    userErrors: IFormErrors<typeof userSchema>;
};
const initialState: IUserFormState = {
    activeDate: '',
    dependentsOver20: '',
    dependentsUnder21: '',
    email: '',
    employeeDateOfBirth: '',
    firstName: '',
    lastName: '',
    majorMedicalCoverageLevel: '',
    memberQuoteInfoErrors: null,
    phone: '',
    previousGroupLevelId: '',
    shouldPopulateForm: true,
    spouseDateOfBirth: '',
    userErrors: null,
};

class UserForm extends React.PureComponent<IUserFormProps> {
    override state: IUserFormState = initialState;

    static getDerivedStateFromProps(props: IUserFormProps, state: IUserFormState) {
        if (props.isEditMode && state.shouldPopulateForm && props.hasUser) {
            state.shouldPopulateForm = false;
            state.activeDate = props.userProfile.user?.activeDate;
            state.firstName = props.userProfile.user?.firstName;
            state.lastName = props.userProfile.user?.lastName;
            state.email = props.userProfile.user?.email;
            state.phone = props.userProfile.user?.phone;
            state.employeeDateOfBirth =  formatDateForDisplay(props.userProfile.user?.dateOfBirth);
            state.previousGroupLevelId = props.userProfile.user?.previousGroupLevelId.toString();

            if (!isUndefined(props.userProfile?.memberQuoteInfo)) {
                state.majorMedicalCoverageLevel = props.userProfile.yearlyUserInfo?.majorMedicalCoverageLevel.toString();
                state.spouseDateOfBirth = formatDateForDisplay(
                    props.userProfile.memberQuoteInfo.spouseDateOfBirth
                );
                state.employeeAnnualIncome = props.userProfile.memberQuoteInfo.employeeAnnualIncome.toString();
                state.dependentsUnder21 = props.userProfile.memberQuoteInfo.dependentsUnder21.toString();
                state.dependentsOver20 = props.userProfile.memberQuoteInfo.dependentsOver20.toString();
                state.previousEmployeePremium = props.userProfile.memberQuoteInfo.previousEmployeePremium.toString();
                state.previousEmployerPremium = props.userProfile.memberQuoteInfo.previousEmployerPremium.toString();
            }
        }

        if (props.apiError && props.apiErrorStatusCode === HTTP_STATUS.CONFLICT) {
            state.userErrors = {
                ...state.userErrors,
                email: ['Email address already in use.', ...(state.userErrors?.email ?? [])],
            };
        }
        return state;
    }

    override componentDidMount() {
        this.props.clearAddressInputs();
        this.props.getStates();
        this.props.getDefaultMemberDataYear();
    }

    override componentWillUnmount() {
        this.props.clearUser();
        this.props.clearUserProfile();
        this.props.clearHouseholdMembers();
        this.props.clearCounties();
        this.props.clearAddressInputs();
    }

    onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { name, value } }) => {
        if (name === 'email' && this.props.apiError) {
            this.props.clearUserApiErrors();
            this.setState({
                userErrors: { ...(this.state.userErrors ?? {}), email: null },
            });
        }
        this.setState({
            [name]: value,
        });
    };
    async validateAll() {
        const { addressInputs, isInProspect, isNotCustomerOrRenewing, teamProfile } = this.props;
        const {
            activeDate,
            dependentsOver20,
            dependentsUnder21,
            email,
            employeeAnnualIncome,
            employeeDateOfBirth,
            firstName,
            majorMedicalCoverageLevel,
            lastName,
            phone,
            previousEmployeePremium,
            previousEmployerPremium,
            previousGroupLevelId,
            spouseDateOfBirth,
        } = this.state;
        let isValid = true;
        try {
            await validate(userSchema, {
                email,
                employeeDateOfBirth,
                firstName,
                lastName,
                phone,
                spouseDateOfBirth,
                employeeAnnualIncome: (employeeAnnualIncome as unknown) as number,
            });
            this.setState({ userErrors: null });
        } catch (errors) {
            this.setState({ userErrors: formatErrors(errors as ValidationError) });
            isValid = false;
        }
        try {
            await validate(
                memberQuoteInfoSchema,
                { 
                    activeDate,
                    majorMedicalCoverageLevel,
                    previousGroupLevelId,
                    dependentsOver20: (dependentsOver20 as unknown) as number,
                    dependentsUnder21: (dependentsUnder21 as unknown) as number,
                    previousEmployeePremium: (previousEmployeePremium as unknown) as number,
                    previousEmployerPremium: (previousEmployerPremium as unknown) as number,
                },
                { context: { isInProspect, isNotCustomerOrRenewing } }
            );
            this.setState({ memberQuoteInfoErrors: null });
        } catch (errors) {
            this.setState({ memberQuoteInfoErrors: formatErrors(errors as ValidationError) });
            isValid = false;
        }
        const isValidAddress = await this.props.validateAddressInputs(addressInputs, teamProfile.address);
        return isValidAddress && isValid;
    }

    handleSubmit = async () => {
        const isValid = await this.validateAll();
        if (isValid) {
            const user = new User({
                activeDate: this.state.activeDate,
                dateOfBirth: formatDateForApi(this.state.employeeDateOfBirth),
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phone: this.state.phone,
                previousGroupLevelId: hasValue(this.state.previousGroupLevelId)
                    ? Number(this.state.previousGroupLevelId)
                    : GroupLevels.UK,
                teamId: this.props.teamId,
            } as IUser);
            const address = new Address({
                ...this.props.addressInputs,
            } as IAddress);
            const memberQuoteInfo = new MemberQuoteInfo({
                employeeAnnualIncome: Number(this.state.employeeAnnualIncome),
                spouseDateOfBirth: formatDateForApi(this.state.spouseDateOfBirth),
            } as IMemberQuoteInfo);
            if (this.props.isNotCustomerOrRenewing) {
                memberQuoteInfo.dependentsUnder21 = Number(this.state.dependentsUnder21);
                memberQuoteInfo.dependentsOver20 = Number(this.state.dependentsOver20);
                memberQuoteInfo.previousEmployeePremium = Number(this.state.previousEmployeePremium);
                memberQuoteInfo.previousEmployerPremium = Number(this.state.previousEmployerPremium);
            }

            const yearlyUserInfo = new YearlyUserInfoDto({
                majorMedicalCoverageLevel: Number(this.state.majorMedicalCoverageLevel),
            } as IYearlyUserInfoDto);

            this.props.handleSubmit(
                { address, memberQuoteInfo, user, yearlyUserInfo } as IUserProfile,
                this.props.isCustomer
            );
        }
    };

    override render() {
        const {
            defaultMemberDataYear,
            isLoading,
            isCustomer,
            isEditMode,
            isInProspect,
            teamStateId,
            userProfile,
        } = this.props;
        const {
            activeDate,
            dependentsOver20,
            dependentsUnder21,
            email,
            employeeAnnualIncome,
            employeeDateOfBirth,
            firstName,
            majorMedicalCoverageLevel,
            lastName,
            memberQuoteInfoErrors,
            phone,
            previousEmployeePremium,
            previousEmployerPremium,
            previousGroupLevelId,
            spouseDateOfBirth,
            userErrors,
        } = this.state;
        const gridTemplateColumns = isCustomer ? { md: '1fr 1fr 1fr' } : null;
        const year = getYearOrDefault(activeDate, defaultMemberDataYear);
        return (
            <Card>
                <CardContent className="p-4">
                    <PageHeader variant="h4">
                        {isEditMode ? 'Edit Member' : 'Add New Member'}
                    </PageHeader>
                    <hr />
                    <Form isLoading={isLoading} onSubmit={this.handleSubmit}>
                        <Box
                            sx={{
                                gridTemplateColumns,
                                display: 'grid',
                                gap: 2,
                            }}
                        >
                            <Stack gap={2}>
                                <BasicInfoFormUI
                                    email={email}
                                    employeeAnnualIncome={employeeAnnualIncome}
                                    employeeDateOfBirth={employeeDateOfBirth}
                                    errors={userErrors}
                                    firstName={firstName}
                                    lastName={lastName}
                                    onChange={this.onChange}
                                    phone={phone}
                                    spouseDateOfBirth={spouseDateOfBirth}
                                />
                            </Stack>
                            {isCustomer && (
                                <React.Fragment>
                                    <Stack gap={2}> 
                                        <AddressInput
                                            address={isEditMode ? userProfile?.address : undefined}
                                            useSingleColumn
                                            year={year}
                                        />
                                    </Stack>
                                    <Stack gap={2}>
                                        <MemberQuoteInfoFormUI
                                            activeDate={activeDate}
                                            dependentsOver20={dependentsOver20}
                                            dependentsUnder21={dependentsUnder21}
                                            errors={memberQuoteInfoErrors}
                                            isInProspect={isInProspect}
                                            majorMedicalCoverageLevel={majorMedicalCoverageLevel}
                                            onChange={this.onChange}
                                            previousEmployeePremium={previousEmployeePremium}
                                            previousEmployerPremium={previousEmployerPremium}
                                            previousGroupLevelId={previousGroupLevelId}
                                            teamStateId={teamStateId}
                                        />
                                    </Stack>
                                </React.Fragment>
                            )}
                        </Box>
                        <FormButton
                            className="mt-2"
                            isLoading={isLoading}
                            label={isEditMode ? 'Save Changes' : 'Add New Member'}
                        />
                    </Form>
                </CardContent>
            </Card>
        );
    }
}

const mapStateToProps = (state: AppStore, { match }: { match: RouteComponentProps['match'] }) => {
    const props = {
        ...getTeamProps(state, match.params),
        ...getUserProps(state, match.params),
        addressInputs: state.addressInputState.addressInputs,
        defaultMemberDataYear: state.defaultMemberDataYear,
        isInProspect: false,
        isLoading: hasApiActivity(state, ADD_USER_ACTION, EDIT_USER_ACTION),
        isNotCustomerOrRenewing: false,
        teamProfile: state.teamProfile
    };

    if (props.hasTeam) {
        props.isInProspect = props.teamStateId === TeamStateIds.Prospect;
        props.isNotCustomerOrRenewing = ![TeamStateIds.Customer, TeamStateIds.Renewing].includes(
            props.teamStateId
        );
    }

    return props;
};

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    clearAddressInputs: async () => dispatch(clearAddressInputs()),
    clearCounties: async () => dispatch(clearCounties()),
    clearCountyError: async () => dispatch(clearGetCountiesError()),
    clearHouseholdMembers: async () => dispatch(clearHouseholdMembers()),
    clearUser: async () => dispatch(clearUser()),
    clearUserApiErrors: async () => dispatch(clearUserApiErrors()),
    clearUserProfile: async () => dispatch(clearUserProfile()),
    getDefaultMemberDataYear: async () => dispatch(getDefaultMemberDataYear()),
    getStates: async () => dispatch(getStates()),
    getTeamProfile: async (teamId: string, isCurrent: boolean) =>
        dispatch(getTeamProfile(teamId, isCurrent)),
    validateAddressInputs: async (addressInputs: IAddressInputs, teamAddress: Address|undefined) =>
        dispatch(validateAndCheckMatchTeamAddress(addressInputs, teamAddress, false)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserForm)));
