import { Card, CardContent } from '@mui/material';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import IfYouHaveQuestionsFooter from 'pages/dashboard/IfYouHaveQuestionsFooter';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';

const PendingWageUpRequestCard = () => (
    <Card>
        <CardContent>
            <Row className="align-items-center justify-content-space-between px-2">
                <Col>
                    <DashboardCardHeader header="Wage+ Request Submitted!" />
                    <hr />
                    <p>
                        If you haven&apos;t already, please upload any relevant documentation that
                        will support your Wage+ Request.
                    </p>
                    <p>
                        Our team is reviewing your Wage+ request to go on a spouse/parent employer
                        plan, and will reach out to you if they have any questions.
                    </p>
                    <p>A formal email will be sent to you when your request is approved.</p>
                    <IfYouHaveQuestionsFooter />
                </Col>
            </Row>
        </CardContent>
    </Card>
);

export default hot(module)(PendingWageUpRequestCard);
