import { Paper } from '@mui/material';
import {
    CREATE_OR_PATCH_MEDICAID_DETAILS_ACTION,
    createOrPatchMedicaidDetails,
} from 'actions/medicaid/createOrPatchMedicaidDetails.ts';
import { createOrPatchMedicaidRenewalDetails } from 'actions/medicaid/createOrPatchRenewalDetails';
import {
    GET_MEDICAID_DETAILS_ACTION,
    getMedicaidDetails,
} from 'actions/medicaid/getMedicaidDetails';
import { IMedicaidDetails } from 'api/generated/models';
import { EditMedicaidDetails } from 'api/generated/permissions';
import { INLINE_EDIT_TOOLTIP_TITLE } from 'components/EditableAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import PageHeader from 'components/PageHeader';
import TextAreaModal from 'components/TextAreaModal';
import Tooltip from 'components/Tooltip';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import DetailsSection from 'pages/medicaidDetails/DetailsSection';
import RenewalSection from 'pages/medicaidDetails/RenewalSection';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import styled from 'styled-components';
import { hasValue } from 'utilities';

const NoteContainer = styled.div`
    min-height: 200px;
    white-space: pre-wrap;
`;

const MedicaidDetailsPage = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { isLoading, isNoteSaving, note, readonly } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_MEDICAID_DETAILS_ACTION),
        isNoteSaving: hasApiActivity(state, CREATE_OR_PATCH_MEDICAID_DETAILS_ACTION),
        note: state.medicaid.note,
        readonly: !state.login.up.includes(EditMedicaidDetails),
    }));
    useEffect(() => {
        dispatch(getMedicaidDetails(userId));
    }, [dispatch, userId]);
    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = useCallback(() => setShowModal(false), []);
    const saveDetails: ISaveEditableTextField<IMedicaidDetails> = useCallback(
        async (name, value) => {
            await dispatch(createOrPatchMedicaidDetails(userId, { [name]: value }));
        },
        [dispatch, userId]
    );
    const saveRenewalDetails = useCallback(
        async (name, value, year) => {
            await dispatch(createOrPatchMedicaidRenewalDetails(userId, year, { [name]: value }));
        },
        [dispatch, userId]
    );
    const saveNote = useCallback(
        async (text: string | undefined) => {
            await dispatch(createOrPatchMedicaidDetails(userId, { note: text }));
            handleCloseModal();
        },
        [dispatch, handleCloseModal, userId]
    );
    let notePreviewText = !hasValue(note) ? 'Click here to add notes' : note;
    if (readonly && !hasValue(note)) {
        notePreviewText = 'No notes added';
    }
    return (
        <React.Fragment>
            {showModal && (
                <TextAreaModal
                    label="Note"
                    onHide={handleCloseModal}
                    placeholder="Enter a note"
                    save={saveNote}
                    showActivity={isNoteSaving}
                    title="Add Medicaid Notes"
                    value={note}
                />
            )}
            <Paper>
                <Col>
                    {isLoading ? (
                        <Skeleton count={3} />
                    ) : (
                        <React.Fragment>
                            <DetailsSection readonly={readonly} save={saveDetails} />
                            <RenewalSection readonly={readonly} save={saveRenewalDetails} />
                            <Row className="mt-2">
                                <Col xs="12">
                                    <PageHeader variant="h4">Notes</PageHeader>
                                    <hr />
                                    <Tooltip title={INLINE_EDIT_TOOLTIP_TITLE}>
                                        <NoteContainer
                                            className="clickable"
                                            onClick={handleOpenModal}
                                        >
                                            {notePreviewText}
                                        </NoteContainer>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </Col>
            </Paper>
        </React.Fragment>
    );
};

export default hot(module)(MedicaidDetailsPage);
