import SingleButtonModal from 'components/SingleButtonModal';
import DoNotInclude from 'pages/survey/informationModals/DoNotInclude';
import InfoModalText from 'pages/survey/informationModals/InfoModalText';
import ThingsToInclude from 'pages/survey/informationModals/ThingsToInclude';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const AdditionalIncomeInformationModal = ({
    close,
    title = 'Additional Income to Include',
}: {
    close: () => void;
    title?: string;
}) => (
    <SingleButtonModal
        body={
            <Row>
                <Col className="mb-4" md={{ offset: 1 }}>
                    <ThingsToInclude />
                    <InfoModalText>Bonuses From Primary Job</InfoModalText>
                    <InfoModalText>Commissions From Primary Job</InfoModalText>
                    <InfoModalText>Any Income From a Second Job</InfoModalText>
                    <InfoModalText>Self-Employment Income</InfoModalText>
                    <InfoModalText>Tips</InfoModalText>
                    <InfoModalText>Unemployment Income</InfoModalText>
                    <InfoModalText>Social Security Benefits (Non-Taxable) </InfoModalText>
                    <InfoModalText>Social Security Disability</InfoModalText>
                    <InfoModalText>Retirement or Pension Income</InfoModalText>
                    <InfoModalText>Alimony Received</InfoModalText>
                    <InfoModalText>Capital Gains</InfoModalText>
                    <InfoModalText>Investment Income</InfoModalText>
                    <InfoModalText>Rental or Royalty Income</InfoModalText>
                    <InfoModalText>Untaxed Foreign Income</InfoModalText>
                </Col>
                <Col>
                    <DoNotInclude />
                    <InfoModalText>
                        Economic Impact/Stimulus Payments (from the IRS as a result of the COVID-19
                        emergency)
                    </InfoModalText>
                    <InfoModalText>Supplemental Security Income ( SSI )</InfoModalText>
                    <InfoModalText>Child Support</InfoModalText>
                    <InfoModalText>Veterans Disability</InfoModalText>
                    <InfoModalText>Worker’s Compensation</InfoModalText>
                    <InfoModalText>Proceeds from Loan</InfoModalText>
                    <InfoModalText>Gifts</InfoModalText>
                </Col>
            </Row>
        }
        buttonClickHandler={close}
        buttonText="Close"
        size="lg"
        title={title}
    />
);

export default hot(module)(AdditionalIncomeInformationModal);
