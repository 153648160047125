import { IAction } from 'actions/commonAction';
import { ISelectedPlan } from 'api/generated/models';
import {
    HIDE_PLAN_MODAL,
    HIDE_REMOVE_PLAN_MODAL,
    SHOW_PLAN_MODAL,
    SHOW_REMOVE_PLAN_MODAL,
} from 'pages/benefits/benefitsActions';

const initialState = {
    isPlanModalVisible: false,
    isRemovePlanModalVisible: false,
    targetBenefit: undefined as ISelectedPlan | undefined,
};
export type IBenefitsState = Partial<typeof initialState>;

export const benefitsState = (state = initialState, action: IAction<IBenefitsState>) => {
    switch (action.type) {
        case SHOW_PLAN_MODAL:
        case HIDE_PLAN_MODAL:
        case HIDE_REMOVE_PLAN_MODAL:
        case SHOW_REMOVE_PLAN_MODAL:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};
