import { IHouseholdMember, IUser } from 'api/generated/models';
import CreatableAutocomplete, {
    ICreatableAutocompleteProps,
} from 'components/creatableAutocomplete/CreatableAutocomplete';
import some from 'lodash/some';
import HouseholdListCards, { IListItem } from 'pages/survey/coverageSteps/HouseholdListCards';
import SelectWhoModal, { ISelectableMember } from 'pages/survey/coverageSteps/SelectWhoModal';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import { ISurveyHouseholdMember } from 'pages/survey/surveyState';
import React, { useCallback, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import titleCase from 'titleize';
import { hasMatchingId } from 'utilities/household';

const getIdsFromMembers = (
    members: (Pick<IHouseholdMember, 'householdMemberId'> | Pick<IUser, 'userId'>)[]
) => members.map((x) => (x as IHouseholdMember)?.householdMemberId ?? (x as IUser)?.userId);

type IListFormProps<T> = IStepProps & {
    CreatableAutocompleteProps: ICreatableAutocompleteProps<T>;
    description: React.ReactNode | string;
    keyProperty: keyof T;
    save: <T>(selectedData: T[]) => void;
    selectedData: T[];
    title: JSX.Element;
};

const ListForm = <T extends IListItem>({
    children,
    commonProps,
    CreatableAutocompleteProps,
    description,
    keyProperty,
    save,
    selectedData,
    title,
}: IListFormProps<T>) => {
    const [isEdit, setIsEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedListItem, setSelectedListItem] = useState<T | undefined>();
    const [selectedListItemIndex, setSelectedListItemIndex] = useState<number | undefined>();
    const { householdMembers, member } = useSelector((state: AppStore) => ({
        householdMembers: state.surveyState.household.members,
        member: state.surveyState.member,
    }));
    const [members, setMembers] = useState([member, ...(householdMembers ?? [])] as ({
        isSelected?: boolean;
    } & (
        | IUser
        | (ISurveyHouseholdMember & {
              hasDependentEmployerOther: boolean;
              id: string;
          })
    ))[]);
    const onClose = useCallback(() => {
        setIsEdit(false);
        setShowModal(false);
    }, []);
    const saveSelectedDataAndClose = useCallback(
        (newSelectedData: T[]) => {
            save([...newSelectedData]);
            onClose();
        },
        [onClose, save]
    );
    const onRemove = useCallback(() => {
        if (selectedListItemIndex !== undefined) {
            selectedData.splice(selectedListItemIndex, 1);
            saveSelectedDataAndClose(selectedData);
        }
    }, [saveSelectedDataAndClose, selectedListItemIndex, selectedData]);
    const onSubmit = useCallback(
        (selectWhoMembers: ISelectableMember[]) => {
            const entityIds = getIdsFromMembers(selectWhoMembers.filter((x) => x.isSelected));
            if (selectedListItemIndex !== undefined && selectedData[selectedListItemIndex]) {
                (selectedData[selectedListItemIndex] as T).entityIds = entityIds;
            } else {
                selectedData.push({
                    ...selectedListItem,
                    entityIds,
                } as T);
            }
            saveSelectedDataAndClose(selectedData);
        },
        [saveSelectedDataAndClose, selectedListItem, selectedListItemIndex, selectedData]
    );
    const isOnlyPrimary = useMemo(() => members.length === 1, [members.length]);
    const onChange: ICreatableAutocompleteProps<T>['onChange'] = useCallback(
        (_, values) => {
            const newListItem = values[values.length - 1];
            if (isOnlyPrimary) {
                save([...selectedData, { ...newListItem, entityIds: getIdsFromMembers(members) }]);
            } else {
                setSelectedListItemIndex(undefined);
                setSelectedListItem(newListItem);
                setShowModal(true);
            }
        },
        [isOnlyPrimary, members, save, selectedData]
    );
    const onCardClick = useCallback(
        (listItem: T, index: number) => () => {
            if (isOnlyPrimary) {
                selectedData.splice(index, 1);
                save([...selectedData]);
            } else {
                setIsEdit(true);
                const membersUpdated = members.map((x) => {
                    if (some(listItem.entityIds, (y) => hasMatchingId(y, x))) {
                        x.isSelected = true;
                    }
                    return x;
                });
                setMembers([...membersUpdated]);
                setSelectedListItemIndex(index);
                setSelectedListItem(listItem);
                setShowModal(true);
            }
        },
        [isOnlyPrimary, members, save, selectedData]
    );
    return (
        <SurveyFormWrapper {...commonProps}>
            {children}
            {showModal && (
                <SelectWhoModal
                    isEdit={isEdit}
                    members={members}
                    onClose={onClose}
                    onRemove={onRemove}
                    onSubmit={onSubmit}
                    title={`Who is ${titleCase(selectedListItem?.name ?? '')} for?`}
                ></SelectWhoModal>
            )}
            <FormTitle description={description}>{title}</FormTitle>
            <Row className="justify-content-center">
                <Col md="7">
                    <CreatableAutocomplete
                        data-cy="thing-search"
                        {...CreatableAutocompleteProps}
                        helperText=""
                        onChange={onChange}
                        value={[]}
                    />
                    {isOnlyPrimary && selectedData.length > 0 && (
                        <Row className="justify-content-center mt-3">
                            <span className="text-muted">Click card to remove</span>
                        </Row>
                    )}
                    <HouseholdListCards
                        keyProperty={keyProperty}
                        members={members}
                        onClick={onCardClick}
                        things={selectedData}
                    />
                </Col>
            </Row>
        </SurveyFormWrapper>
    );
};

export default hot(module)(ListForm);
