import { IMemberQuoteInfo } from 'api/generated/models';
import EditableDateAttribute from 'components/EditableDateAttribute';
import { ICommonProps } from 'pages/profile/ProfilePage';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { formatDateForDisplay } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

type ISpouseDateOfBirthProps = {
    memberQuoteInfo: IMemberQuoteInfo | undefined;
    quoteInfoCommonProps: ICommonProps<IMemberQuoteInfo>;
};

const SpouseDateOfBirth = ({ memberQuoteInfo, quoteInfoCommonProps }: ISpouseDateOfBirthProps) => {
    const [spouseDateOfBirth, setSpouseDateOfBirth] = useState(
        memberQuoteInfo?.spouseDateOfBirth === undefined
            ? ''
            : formatDateForDisplay(memberQuoteInfo.spouseDateOfBirth)
    );
    return (
        <EditableDateAttribute
            {...quoteInfoCommonProps}
            isOptional
            label="Spouse Date of Birth"
            name="spouseDateOfBirth"
            onChange={onChange(setSpouseDateOfBirth)}
            validationSchema={string()
                .isValidDate(false)
                .label('Spouse Date of Birth')}
            value={spouseDateOfBirth}
        />
    );
};

export default hot(module)(SpouseDateOfBirth);
