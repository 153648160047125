import {
    IReimbursementReportUserDto,
    IReimbursementReportUserSubmittedExpenseDto,
    ISubmittedExpense,
} from 'api/generated/models';
import { AccessOtherTeams } from 'api/generated/permissions';
import InformationIconTooltip from 'components/InformationIconTooltip';
import Typography from 'components/Typography';
import useTeamProps from 'hooks/useTeamProps';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { PEOPLE_PROFILE_PATH, TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';

export const MemberNameCell = ({
    row: {
        original: { memberName, userId },
    },
}: Cell<IReimbursementReportUserDto, unknown>) => {
    const { teamId } = useTeamProps();
    const { canAccessOtherTeams } = useSelector((state: AppStore) => ({
        canAccessOtherTeams: hasSomePermissions(state, AccessOtherTeams),
    }));
    const teamsProfilePath = canAccessOtherTeams
        ? generatePath(TEAMS_PEOPLE_PROFILE_PATH, { teamId, userId })
        : generatePath(PEOPLE_PROFILE_PATH, { userId });
    return (
        <Link data-cy="member-name" onClick={(e) => e.stopPropagation()} to={teamsProfilePath}>
            {memberName}
        </Link>
    );
};

export const WagesReimbursementCell = ({
    value,
}: Cell<ISubmittedExpense, IReimbursementReportUserSubmittedExpenseDto>) => (
    <React.Fragment>
        <Typography mr={1} variant="body1">
            {formatCurrency(value.reimbursementToWages, { preserveDecimal: true })}
        </Typography>
        {hasValue(value?.wagesReimbursementSummary) && (
            <InformationIconTooltip title={value?.wagesReimbursementSummary ?? ''} />
        )}
    </React.Fragment>
);

export const HsaReimbursementCell = ({
    value,
}: Cell<ISubmittedExpense, IReimbursementReportUserSubmittedExpenseDto>) => (
    <React.Fragment>
        <Typography mr={1} variant="body1">
            {formatCurrency(value.reimbursementToHsa, { preserveDecimal: true })}
        </Typography>
        {hasValue(value?.hsaReimbursementSummary) && (
            <InformationIconTooltip title={value?.hsaReimbursementSummary ?? ''} />
        )}
    </React.Fragment>
);

export const TotalReimbursementCell = ({
    value,
}: Cell<ISubmittedExpense, IReimbursementReportUserSubmittedExpenseDto>) => {
    const summary = `Total Reimbursement = Reimbursement to HSA + Reimbursement to Wages\n\n${value.totalReimbursement} = ${value.reimbursementToHsa} + ${value.reimbursementToWages}\n\nTotal Reimbursement: ${value.totalReimbursement}`;
    return (
        <React.Fragment>
            <Typography mr={1} variant="body1">
                {formatCurrency(value.totalReimbursement, { preserveDecimal: true })}
            </Typography>
            <InformationIconTooltip title={summary} />
        </React.Fragment>
    );
};
