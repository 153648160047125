import { saveCompletedUserTask } from 'actions/task/saveCompletedUserTask';
import { completeMemberTaskList } from 'actions/user/completeMemberTaskList';
import { getUserProfile } from 'actions/user/getUserProfile';
import { SurveyTypes, TaskItems, TeamStateIds, UserStatus } from 'api/generated/enums';
import { ITaskItem } from 'api/generated/models';
import { push } from 'connected-react-router';
import { taskListTypes } from 'constants/tasks';
import { RH_TEAM_CARE_EMAIL, RH_TEAM_LAUNCH_EMAIL } from 'constants/teams';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { isWaived } from 'pages/dashboard/dashboardSelectors';
import ReviewMemberInfoModal from 'pages/dashboard/taskLists/member/tasks/ReviewMemberInfoModal';
import VerifyInfoModal from 'pages/dashboard/taskLists/member/tasks/VerifyInfoModal';
import SomethingIsIncorrectModal from 'pages/dashboard/taskLists/SomethingIsIncorrectModal';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import { needSomeHouseholdSsnSelector } from 'pages/dashboard/taskListSelectors';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { MEMBER_RENEWAL_SURVEY_PATH, MEMBER_SURVEY_INTRO_PATH } from 'routers/routes';

type IReviewMemberInfoTaskProps = {
    acceptRHServicesTask: TaskItems;
    actionButtonText?: string;
    complete: boolean;
    completedTasks: ITaskItem[];
    description?: string;
    enabled: boolean;
    isIchraPathway?: boolean;
    isRenewal?: boolean;
    title?: string;
};

const ReviewMemberInfoTask = ({
    acceptRHServicesTask,
    complete,
    completedTasks,
    enabled,
    title,
    description,
    actionButtonText,
    isRenewal,
    isIchraPathway = false,
}: IReviewMemberInfoTaskProps) => {
    const dispatch = useThunkDispatch();
    const { hasMemberVerifiedInfo, memberVerifiedInfo, user, userId, userProfile } = useUserProps();
    const { teamStateId } = useTeamProps();
    const { isUserWaived, needSomeHouseholdSsn } = useSelector((state: AppStore) => ({
        isUserWaived: isWaived(state),
        needSomeHouseholdSsn: needSomeHouseholdSsnSelector(state, memberVerifiedInfo),
    }));
    const {
        closeModal: closeMemberInfoModal,
        isVisible: isMemberInfoModalVisible,
        openModal: openMemberInfoModal,
    } = useModalState();
    const {
        closeModal: closeSomethingIsIncorrectModal,
        isVisible: isSomethingIsIncorrectVisible,
        openModal: openSomethingIsIncorrectModal,
    } = useModalState();
    const {
        closeModal: closeVerifyInfoModal,
        isVisible: isVerifyInfoModalVisible,
        openModal: openVerifyInfoModal,
    } = useModalState();

    const isIchraSurvey = user?.surveyTypeToSend === SurveyTypes.ICHRA;
    const task = isRenewal ? TaskItems.ReviewMemberInfo_Renewing : TaskItems.ReviewMemberInfo;
    const acceptRHServicesComplete = useMemo(
        () => completedTasks.some((x) => x.taskItemId === acceptRHServicesTask),
        [completedTasks, acceptRHServicesTask]
    );
    const isSSNTaskShown = useMemo(
        () =>
            needSomeHouseholdSsn &&
            acceptRHServicesComplete &&
            !isUserWaived &&
            memberVerifiedInfo?.year,
        [acceptRHServicesComplete, isUserWaived, memberVerifiedInfo, needSomeHouseholdSsn]
    );
    const isLastLaunchTask = !isRenewal && !isSSNTaskShown;
    const handleCompleteSubmitClick = async () => {
        closeMemberInfoModal();
        const taskToSave = isRenewal
            ? TaskItems.ReviewMemberInfo_Renewing
            : TaskItems.ReviewMemberInfo;
        await dispatch(saveCompletedUserTask(taskToSave));
        if (isLastLaunchTask) {
            await dispatch(completeMemberTaskList(userId, userProfile.year));
            await dispatch(getUserProfile(userId, true));
        }
    };
    const handleIncorrectClick = () => {
        closeMemberInfoModal();
        openSomethingIsIncorrectModal();
    };
    const navigateToSurvey = () => dispatch(push(MEMBER_SURVEY_INTRO_PATH));
    const navigateToRenewalSurvey = () => dispatch(push(MEMBER_RENEWAL_SURVEY_PATH));
    const onRenewalVerifyInfoModal = () => {
        closeVerifyInfoModal();
        navigateToRenewalSurvey();
    };

    let action: () => void = navigateToSurvey;
    let buttonText = actionButtonText ?? 'Complete';
    let titleDisplay = title ?? 'Complete Health Benefits Analysis';
    let descriptionDisplay =
        description ?? 'Please complete the health benefits analysis before moving forward.';
    if (hasMemberVerifiedInfo && !isRenewal) {
        action = openMemberInfoModal;
        buttonText = 'Review';
        descriptionDisplay = 'Please ensure your personal information is accurate.';
        titleDisplay = 'Review Your Information';
    }

    if (hasMemberVerifiedInfo && isRenewal) {
        action = openVerifyInfoModal;
    }

    const somethingIsIncorrectEmail =
        [TeamStateIds.Customer, TeamStateIds.Renewing].includes(teamStateId) &&
        user?.status === UserStatus.Launching
            ? RH_TEAM_CARE_EMAIL
            : RH_TEAM_LAUNCH_EMAIL;

    return (
        <TaskListItem
            action={action}
            buttonText={buttonText}
            complete={complete}
            data-cy="review-member-info-task"
            description={descriptionDisplay}
            enabled={enabled}
            showButton={enabled}
            taskId={task}
            taskType={taskListTypes.User}
            title={titleDisplay}
        >
            {isMemberInfoModalVisible && (
                <ReviewMemberInfoModal
                    handleClose={handleCompleteSubmitClick}
                    handleIncorrectClick={handleIncorrectClick}
                    isIchra={isIchraSurvey}
                    onHide={closeMemberInfoModal}
                />
            )}
            {isSomethingIsIncorrectVisible && (
                <SomethingIsIncorrectModal
                    buttonClickHandler={closeSomethingIsIncorrectModal}
                    contactEmail={somethingIsIncorrectEmail}
                />
            )}
            {isVerifyInfoModalVisible && (
                <VerifyInfoModal
                    isIchra={isIchraPathway || isIchraSurvey}
                    onClose={closeVerifyInfoModal}
                    onSubmit={onRenewalVerifyInfoModal}
                />
            )}
        </TaskListItem>
    );
};

export default hot(module)(ReviewMemberInfoTask);
