import { getAllEnrollmentVerificationForUser } from 'actions/enrollmentVerification/getAllEnrollmentVerificationForUser';
import {
    sendEnrollmentVerificationReminder,
    SEND_ENROLLMENT_VERIFICATION_REMINDER_ACTION,
} from 'actions/enrollmentVerification/sendEnrollmentVerificationReminder';
import { EnrollmentVerificationStatuses } from 'api/generated/enums';
import { IEnrollmentVerification } from 'api/generated/models';
import {
    CreateEnrollmentVerification,
    SendEnrollmentVerificationReminder,
} from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import PageSectionWrapper from 'components/PageSectionWrapper';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import UserNameEntityHeader from 'components/UserNameEntityHeader';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import orderBy from 'lodash/orderBy';
import EnrollmentVerification from 'pages/verificationDocuments/EnrollmentVerification';
import EnrollmentVerificationModal from 'pages/verificationDocuments/EnrollmentVerificationModal';
import VerificationReminderModal from 'pages/verificationDocuments/VerificationReminderModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const VerificationDocumentsPage = () => {
    const dispatch = useThunkDispatch();
    const { hasUser, user, userId } = useUserProps();
    const { canAdd, canSendReminder, enrollmentVerifications, isSendingReminder } = useSelector(
        (state: AppStore) => ({
            canAdd: state.login.up.includes(CreateEnrollmentVerification),
            canSendReminder: state.login.up.includes(SendEnrollmentVerificationReminder),
            enrollmentVerifications: orderBy(state.enrollmentVerifications, ['status', 'dueDate']),
            isSendingReminder: hasApiActivity(state, SEND_ENROLLMENT_VERIFICATION_REMINDER_ACTION),
        })
    );
    const [entityToEdit, setEntityToEdit] = useState<IEnrollmentVerification | undefined>();
    const [showEnrollmentVerificationModal, setShowEnrollmentVerificationModal] = useState(false);
    const [isVerificationReminderModalVisible, setIsVerificationReminderModalVisible] = useState(
        false
    );

    useEffect(() => {
        dispatch(getAllEnrollmentVerificationForUser(userId));
    }, [dispatch, userId]);

    const onCloseVerificationModal = useCallback(() => {
        setShowEnrollmentVerificationModal(false);
        setEntityToEdit(undefined);
    }, []);
    const onShowVerificationModal = useCallback((data?: IEnrollmentVerification) => {
        setShowEnrollmentVerificationModal(true);
        setEntityToEdit(data);
    }, []);

    const closeVerificationReminderModal = useCallback(
        () => setIsVerificationReminderModalVisible(false),
        []
    );
    const showVerificationReminderModal = useCallback(
        () => setIsVerificationReminderModalVisible(true),
        []
    );
    const sendVerificationReminder = useCallback(async () => {
        await dispatch(sendEnrollmentVerificationReminder(userId));
        closeVerificationReminderModal();
    }, [dispatch, userId, closeVerificationReminderModal]);

    const items = useMemo(
        () =>
            [
                {
                    isVisible: canAdd,
                    onClick: () => onShowVerificationModal(),
                    text: 'Add',
                },
                {
                    isVisible:
                        canSendReminder &&
                        enrollmentVerifications.some(
                            (ev) => ev.status === EnrollmentVerificationStatuses.Needed
                        ),
                    onClick: showVerificationReminderModal,
                    text: 'Send Reminder',
                },
            ] as IActionButtonItems,
        [
            canAdd,
            canSendReminder,
            enrollmentVerifications,
            onShowVerificationModal,
            showVerificationReminderModal,
        ]
    );
    const label = useMemo(
        () =>
            `Showing ${enrollmentVerifications.length} Verification Document${
                enrollmentVerifications.length !== 1 ? 's' : ''
            }`,
        [enrollmentVerifications.length]
    );
    return (
        <React.Fragment>
            {showEnrollmentVerificationModal && (
                <EnrollmentVerificationModal
                    data={entityToEdit}
                    onClose={onCloseVerificationModal}
                />
            )}

            {isVerificationReminderModalVisible && (
                <VerificationReminderModal
                    close={closeVerificationReminderModal}
                    name={user?.displayName}
                    sendReminder={sendVerificationReminder}
                    showActivity={isSendingReminder}
                />
            )}

            <PageSectionWrapper>
                <TableHeader
                    items={items}
                    label={
                        <Row className="align-items-center">
                            <Col className="text-left">
                                <UserNameEntityHeader entity="Verification Documents" />
                                <span>{label}</span>
                            </Col>
                        </Row>
                    }
                />
            </PageSectionWrapper>

            <Row>
                <Skeleton
                    count={2}
                    height="478px"
                    isEnabled={!hasUser}
                    Wrapper={({ children }) => (
                        <div className="col-12 col-lg-6 mb-2">{children}</div>
                    )}
                >
                    {enrollmentVerifications.map((entity) => (
                        <Col key={entity.id} lg="6">
                            <EnrollmentVerification
                                data={entity}
                                onEditClick={() => onShowVerificationModal(entity)}
                            />
                        </Col>
                    ))}
                </Skeleton>
            </Row>
        </React.Fragment>
    );
};

export default hot(module)(VerificationDocumentsPage);
