import { ARCHIVE_TEAM_ACTION, archiveTeam } from 'actions/team/archiveTeam';
import { DELETE_TEAM_ACTION, deleteTeam } from 'actions/team/deleteTeam';
import { ITeam } from 'api/generated/models';
import { ArchiveTeam, DeleteTeam } from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import { teamStateIds } from 'constants/teamStateIds';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { hideTeamConfirmationModal, showTeamConfirmationModal } from 'pages/teams/teamActions';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';

const teamStatesForDelete = [teamStateIds.Prospect, teamStateIds.Proposal];

type ITeamTableActionButtonProps = RouteComponentProps & {
    team: ITeam | undefined;
};

const TeamTableActionButtons = ({ team }: ITeamTableActionButtonProps) => {
    const dispatch = useThunkDispatch();
    const { canDeleteTeams, canArchiveTeams } = useSelector((state: AppStore) => ({
        canArchiveTeams: state.login.up.includes(ArchiveTeam),
        canDeleteTeams:
            state.login.up.includes(DeleteTeam) && teamStatesForDelete.contains(team?.teamStateId),
    }));
    const onConfirmationNoClick = useCallback(() => {
        dispatch(hideTeamConfirmationModal());
    }, [dispatch]);
    const onDeleteTeam = useCallback(async () => {
        await dispatch(deleteTeam(team?.teamId));
        onConfirmationNoClick();
    }, [dispatch, team?.teamId, onConfirmationNoClick]);
    const onArchiveTeam = useCallback(async () => {
        await dispatch(archiveTeam(team?.teamId, !team?.isArchived));
        onConfirmationNoClick();
    }, [dispatch, team?.teamId, team?.isArchived, onConfirmationNoClick]);
    const handleDeleteTeamClick = useCallback(() => {
        dispatch(
            showTeamConfirmationModal(
                `Are you sure you want to delete ${team?.name}? This cannot be undone`,
                onDeleteTeam,
                onConfirmationNoClick,
                [DELETE_TEAM_ACTION]
            )
        );
    }, [dispatch, team?.name, onDeleteTeam, onConfirmationNoClick]);
    const handleArchiveTeamClick = useCallback(() => {
        dispatch(
            showTeamConfirmationModal(
                `Are you sure you want to ${team?.isArchived ? 'unarchive' : 'archive'} ${
                    team?.name
                }?`,
                onArchiveTeam,
                onConfirmationNoClick,
                [ARCHIVE_TEAM_ACTION]
            )
        );
    }, [dispatch, team?.isArchived, team?.name, onArchiveTeam, onConfirmationNoClick]);
    const items = useMemo(
        () =>
            [
                {
                    isVisible: canDeleteTeams,
                    onClick: handleDeleteTeamClick,
                    text: 'Delete',
                    variant: 'outline-danger',
                },
                {
                    isVisible: canArchiveTeams,
                    onClick: handleArchiveTeamClick,
                    text: team?.isArchived ? 'Unarchive' : 'Archive',
                },
            ] as IActionButtonItems,
        [
            canDeleteTeams,
            handleDeleteTeamClick,
            canArchiveTeams,
            handleArchiveTeamClick,
            team?.isArchived,
        ]
    );
    return <ActionButtons items={items} />;
};

export default hot(module)(withRouter(TeamTableActionButtons));
