import { AppStoreThunkDispatch } from 'actions/commonAction';
import { addPathwayBlueprint } from 'actions/pathwayBlueprint/addPathwayBlueprint';
import { duplicatePathwayBlueprint } from 'actions/pathwayBlueprint/duplicatePathwayBlueprint';
import { editPathwayBlueprint } from 'actions/pathwayBlueprint/editPathwayBlueprint';
import {
    PathwayInputTypes,
    PathwayTypes,
    TopLineReimbursementStrategies,
} from 'api/generated/enums';
import { IPathway, IPathwayBlueprint, IPathwayRecommendedPlan } from 'api/generated/models';
import {
    IPathwayBlueprintModalState,
    schema,
} from 'pages/pathwayBlueprints/pathwayBlueprintModalState';
import { AppStore } from 'reducers/appReducer';
import { formatErrors, validate } from 'utilities/forms';
import { getPathwayInput } from 'utilities/pathways';
import { ValidationError } from 'yup';
import { TypeOfShape } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';

export const SET_PATHWAY_BLUEPRINT_INPUTS_ACTION = 'SET_PATHWAY_BLUEPRINT_INPUTS';
export const setPathwayBlueprintInput = (data: Partial<IPathwayBlueprintModalState>) => (
    dispatch: AppStoreThunkDispatch
) => dispatch({ data, type: SET_PATHWAY_BLUEPRINT_INPUTS_ACTION });

export const ADD_PATHWAY_BLUEPRINT_MODAL_STATE_ACTION = 'ADD_PATHWAY_BLUEPRINT_MODAL_STATE';
export const addPathwayToPathwayBlueprintModalState = (data: IPathway | undefined) => (
    dispatch: AppStoreThunkDispatch
) => dispatch({ data, type: ADD_PATHWAY_BLUEPRINT_MODAL_STATE_ACTION });

export const CLEAR_PATHWAY_BLUEPRINT_MODAL_STATE_ACTION = 'CLEAR_PATHWAY_BLUEPRINT_MODAL_STATE';
export const clearPathwayBlueprintModalState = () => (dispatch: AppStoreThunkDispatch) =>
    dispatch({ type: CLEAR_PATHWAY_BLUEPRINT_MODAL_STATE_ACTION });

const PATHWAY_TYPES_WITH_MARKETPLACE_PLAN = [
    PathwayTypes.MarketplaceCappedEmployeeCost,
    PathwayTypes.MarketplaceCappedEmployerCost,
    PathwayTypes.IchraAgeBanded,
    PathwayTypes.IchraCappedEmployeeCost,
    PathwayTypes.IchraCappedEmployerCost,
];
export const savePathwayBlueprint = (
    teamId: string,
    isDuplicate: boolean | undefined,
    isEdit: boolean,
    isProjection: boolean
) => async (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => {
    const { pathwayBlueprintModalState } = getState();
    const { name, wageUpFloor, year, pathways, id, ichraClasses } = pathwayBlueprintModalState;

    try {
        const pathwaysWithPlansToMigrate = pathways?.filter((x) =>
            PATHWAY_TYPES_WITH_MARKETPLACE_PLAN.includes(x.pathwayTypeId)
        );

        const plans = pathwaysWithPlansToMigrate?.map((x) => ({
            marketplaceCarrier: getPathwayInput(x, PathwayInputTypes.MarketplaceCarrier),
            planId: getPathwayInput(x, PathwayInputTypes.MarketplacePlanId),
        }));

        const recommendedPlans: TypeOfShape<{
            marketplaceCarrier: RequiredStringSchema<string | undefined>;
            planId: RequiredStringSchema<string | undefined>;
        }>[] = [];
        pathwaysWithPlansToMigrate?.flatMap((x) =>
            (x.pathwayRecommendedPlans as IPathwayRecommendedPlan[]).map((y) => {
                if (y.order > 1) {
                    recommendedPlans.push({
                        marketplaceCarrier: y.carrierId,
                        planId: y.planId,
                    });
                }
            })
        );

        const ichraClassPlans = ichraClasses
            ?.filter((ic) =>
                [
                    TopLineReimbursementStrategies.CoverageLevelPercentage,
                    TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
                ].contains(ic.topLineReimbursementStrategyId)
            )
            .map((x) => ({
                marketplaceCarrier: x.benchmarkCarrier,
                planId: x.benchmarkPlanId,
            }));

        const pathwayBlueprintToSave: Partial<IPathwayBlueprint> = await validate(schema, {
            ichraClasses,
            ichraClassPlans,
            isProjection,
            name,
            pathways,
            plans,
            recommendedPlans,
            teamId,
            wageUpFloor,
            id: isEdit ? id : null,
            year: Number(year),
        });
        if (isDuplicate) {
            await dispatch(
                duplicatePathwayBlueprint(pathwayBlueprintToSave.id, pathwayBlueprintToSave.name)
            );
        } else if (isEdit) {
            await dispatch(editPathwayBlueprint(pathwayBlueprintToSave));
        } else {
            await dispatch(addPathwayBlueprint(pathwayBlueprintToSave));
        }
        dispatch(setPathwayBlueprintInput({ errors: null }));
        return true;
    } catch (errors) {
        dispatch(setPathwayBlueprintInput({ errors: formatErrors(errors as ValidationError) }));
        return false;
    }
};
