import { Card, CardContent } from '@mui/material';
import {
    ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION,
    addEnrollmentVerificationRhFile,
} from 'actions/enrollmentVerification/addEnrollmentVerificationRhFile';
import { getAllEnrollmentVerificationForUser } from 'actions/enrollmentVerification/getAllEnrollmentVerificationForUser';
import { EnrollmentVerificationStatuses, EnrollmentVerificationTypes } from 'api/generated/enums';
import FileUploadModal from 'components/FileUploadModal';
import { push } from 'connected-react-router';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import sortBy from 'lodash/sortBy';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import EnrollmentVerificationItem from 'pages/dashboard/verificationDocuments/EnrollmentVerificationItem';
import ValidDocumentsModal from 'pages/dashboard/verificationDocuments/ValidDocumentsModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { VERIFICATION_DOCUMENTS_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { getEnrollmentVerificationTypeText } from 'utilities/verificationDocuments';

const VerificationDocumentsNeeded = () => {
    const dispatch = useThunkDispatch();
    const { user, userId } = useUserProps();
    const { enrollmentVerifications, isFileUploadLoading } = useSelector((state: AppStore) => ({
        enrollmentVerifications: state.enrollmentVerifications.filter(
            (ev) => ev.status !== EnrollmentVerificationStatuses.Verified
        ),
        isFileUploadLoading: hasApiActivity(state, ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION),
    }));

    const [selectedEnrollmentVerificationId, setSelectedEnrollmentVerificationId] = useState<
        string | undefined
    >();
    const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(false);

    const [isValidDocumentsModalVisible, setIsValidDocumentsModalVisible] = useState(false);
    const [validDocumentsType, setValidDocumentsType] = useState<
        EnrollmentVerificationTypes | undefined
    >();

    const showFileUploadModal = useCallback((id: string) => {
        setSelectedEnrollmentVerificationId(id);
        setIsFileUploadModalVisible(true);
    }, []);
    const hideFileUploadModal = useCallback(() => {
        setSelectedEnrollmentVerificationId(undefined);
        setIsFileUploadModalVisible(false);
    }, []);

    const showValidDocumentsModal = useCallback((type: EnrollmentVerificationTypes) => {
        setValidDocumentsType(type);
        setIsValidDocumentsModalVisible(true);
    }, []);
    const hideValidDocumentsModal = useCallback(() => {
        setValidDocumentsType(undefined);
        setIsValidDocumentsModalVisible(false);
    }, []);

    const onFileSubmit = useCallback(
        (documentsToAdd, successTooltipMessage) =>
            addEnrollmentVerificationRhFile(
                selectedEnrollmentVerificationId,
                documentsToAdd,
                successTooltipMessage
            ),
        [selectedEnrollmentVerificationId]
    );

    const navigateToVerificationDocuments = useCallback(
        () => dispatch(push(VERIFICATION_DOCUMENTS_PATH)),
        [dispatch]
    );

    useEffect(() => {
        dispatch(getAllEnrollmentVerificationForUser(userId));
    }, [dispatch, userId]);

    const enrollmentVerificationItems = useMemo(
        () =>
            sortBy(enrollmentVerifications, [
                'year',
                (ev) => getEnrollmentVerificationTypeText(ev.type),
            ]).map((ev) => (
                <EnrollmentVerificationItem
                    enrollmentVerification={ev}
                    key={ev.id}
                    navigateToVerificationDocuments={navigateToVerificationDocuments}
                    showUploadModal={showFileUploadModal}
                    showValidDocumentsModal={showValidDocumentsModal}
                    user={user}
                />
            )),
        [
            enrollmentVerifications,
            navigateToVerificationDocuments,
            showFileUploadModal,
            showValidDocumentsModal,
            user,
        ]
    );

    if (enrollmentVerifications.length === 0) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            {isFileUploadModalVisible && (
                <FileUploadModal
                    isLoading={isFileUploadLoading}
                    onHide={hideFileUploadModal}
                    onSubmit={onFileSubmit}
                />
            )}
            {isValidDocumentsModalVisible && (
                <ValidDocumentsModal
                    onHide={hideValidDocumentsModal}
                    verificationType={validDocumentsType}
                />
            )}
            <Card>
                <CardContent>
                    <DashboardCardHeader
                        description="We need documents from you to complete your enrollment."
                        header="Verification Documents Needed"
                    />
                    {enrollmentVerificationItems}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default hot(module)(VerificationDocumentsNeeded);
