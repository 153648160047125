import { Grid, Typography } from '@mui/material';
import {
    LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION,
    listProvidersForHousehold,
} from 'actions/provider/listProvidersForHousehold';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { ProviderSource, Tasks } from 'api/generated/enums';
import {
    IAddressDto,
    IHouseholdMemberInfoDto,
    IIdeonProvider,
    IPreferredProvidersDto,
    IProvider,
    IUser,
    Provider,
    TaskDto,
} from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import CreatableAutocomplete from 'components/creatableAutocomplete/CreatableAutocomplete';
import useIdeonProviderAutocomplete from 'components/creatableAutocomplete/useIdeonProviderAutocomplete';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import EntityHhmAssignmentCard from 'pages/dashboard/ichraTaskFlow/EntityHhmAssignmentCard';
import EntityHhmAssignmentForm from 'pages/dashboard/ichraTaskFlow/EntityHhmAssignmentForm';
import { ICHRA_LAUNCH_FLOW_INDEX } from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskList';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
    filterNonChunkHhmData,
    getPreviousTask,
    ichraFlowSkeletonRowProps,
    skeletonStructure,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';
import { convertIdeonProviderToProvider } from 'utilities/providerSearch';

const FALLBACK_PROVIDER_SEARCH_ZIP = '46037';
const INDEX_DOES_NOT_EXIST = -1;
export const getProviderUniqueKey = (provider: IProvider) =>
    `${provider?.name ?? ''}${provider?.nationalProviderId ?? ''}`;
export const searchContentCheck = /^(?=.*[A-Za-z0-9])[A-Za-z0-9.\- ]*$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setName = (value: any) => {
    if (Array.isArray(value)) {
        return undefined;
    } else if (hasValue(value?.inputValue)) {
        return value.inputValue;
    } else if (hasValue(value?.name)) {
        return value.name;
    } else {
        return value?.presentationName;
    }
};

const PreferredProvidersPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { user, userId } = useUserProps();
    const { providersFromState, isLoading, userFlows } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION,
            LIST_VISIBLE_FLOWS_ACTION
        ),
        providersFromState: state.householdProviders,
        userFlows: state.userFlows,
    }));
    const ichraTasks = userFlows[ICHRA_LAUNCH_FLOW_INDEX]?.tasks;
    const [providerUniqueKey, setProviderUniqueKey] = useState('');
    const [provider, setProvider] = useState<IProvider>();
    const [providers, setProviders] = useState<IProvider[]>(currentTask.response?.providers ?? []);
    const [autocompleteSearchText, setAutoCompleteSearchText] = useState<string>('');
    const [shouldClearAutocompleteSearchText, setShouldClearAutocompleteSearchText] = useState(
        false
    );
    const hhmTask = getPreviousTask(
        ichraTasks as TaskDto[],
        Tasks.HouseholdMembers,
        Tasks.EnterHouseholdInfo
    );
    const zip = (getPreviousTask(
        ichraTasks as TaskDto[],
        Tasks.PrimaryAddressInfo,
        Tasks.EnterHouseholdInfo
    )?.response as IAddressDto)?.zip;
    const hhms = hhmTask?.response.householdMembers as IHouseholdMemberInfoDto[];
    const isPrimaryOnly = !arrayHasValue(hhms);

    const onProviderChange = useCallback(
        (_, value) => {
            const name = setName(value);
            const isCustom =
                searchContentCheck.test(value.inputValue) ?? searchContentCheck.test(value.name);
            if (hasValue(name)) {
                if (isCustom) {
                    value.name = name;
                    if (hasValue(value.inputValue)) {
                        delete value.inputValue;
                    }
                }
                const newProvider = convertIdeonProviderToProvider(
                    value as IIdeonProvider
                ) as IProvider;
                const providersToAdd = [...(providers ?? [])];
                const existingProviderIndex = providersToAdd.findIndex(
                    (pta) => getProviderUniqueKey(pta) === getProviderUniqueKey(newProvider)
                );

                if (isPrimaryOnly) {
                    existingProviderIndex === INDEX_DOES_NOT_EXIST &&
                        providersToAdd.unshift({
                            ...newProvider,
                            entityIds: [userId],
                        });
                } else if (hasValue(newProvider)) {
                    let existingProvider;
                    if (existingProviderIndex === INDEX_DOES_NOT_EXIST) {
                        providersToAdd.unshift({ ...newProvider, entityIds: [] });
                    } else {
                        [existingProvider] = providersToAdd.splice(existingProviderIndex, 1);
                        providersToAdd.unshift(existingProvider as IProvider);
                    }

                    setProvider(existingProvider ?? newProvider);
                    setProviderUniqueKey(getProviderUniqueKey(newProvider));
                }
                setProviders(providersToAdd);
                setShouldClearAutocompleteSearchText(true);
            }
            if (!isCustom) {
                setAutoCompleteSearchText('');
            }
        },
        [isPrimaryOnly, providers, userId]
    );
    const onAutocompleteSearchTextChange = useCallback(
        (_, value) => {
            if (shouldClearAutocompleteSearchText) {
                setAutoCompleteSearchText('');
                setShouldClearAutocompleteSearchText(false);
            } else {
                setAutoCompleteSearchText(searchContentCheck.test(value) ? value : '');
            }
        },
        [shouldClearAutocompleteSearchText]
    );
    const handleSubmit = async () => {
        const providersToSave: IPreferredProvidersDto = { providers: providers as Provider[] };
        dispatch(completeTask(currentTask.globalId, providersToSave));
    };
    const handleEditCancelButtonClick = (editProviderUniqueKey: string) => {
        if (editProviderUniqueKey === providerUniqueKey) {
            setProviderUniqueKey('');
        } else {
            const providerToEdit =
                providers?.find((p) => getProviderUniqueKey(p) === editProviderUniqueKey) ?? {};
            setProvider(providerToEdit as IProvider);
            setProviderUniqueKey(editProviderUniqueKey);
        }
    };
    const handleProviderRemoval = (removeProviderUniqueKey: string) => {
        setProviders((existingProviders) =>
            existingProviders?.filter(
                (p) => hasValue(p) && getProviderUniqueKey(p) !== removeProviderUniqueKey
            )
        );
        setProviderUniqueKey('');
    };
    const handleRecipientChange = (entityIds: string[]) => {
        const providerIndex =
            providers?.findIndex((p) => getProviderUniqueKey(p) === providerUniqueKey) ?? 0;
        setProviders((prevProviders) => {
            const updatedProviders = [...prevProviders];

            updatedProviders[providerIndex] = {
                distance: 0,
                source: ProviderSource.Ideon,
                ...updatedProviders[providerIndex],
                entityIds,
                providerId: updatedProviders[providerIndex]?.providerId as string,
            };
            return updatedProviders;
        });
        setProviderUniqueKey('');
    };
    const creatableAutocompleteProps = useIdeonProviderAutocomplete({
        year: user?.activeDate?.getYear() as number,
        zipCode: zip ?? FALLBACK_PROVIDER_SEARCH_ZIP,
    });
    const preferredProvidersForm = (
        <EntityHhmAssignmentForm
            entity={provider as IProvider}
            handleRecipientChange={handleRecipientChange}
            hhms={hhms}
            key={getProviderUniqueKey(provider as IProvider)}
            prompt={`Who is ${hasValue(provider?.name) && provider?.name} for?`}
            user={user as IUser}
        />
    );
    const taskHasResponse = hasValue(currentTask?.response);

    useEffect(() => {
        if (!taskHasResponse) {
            dispatch(listProvidersForHousehold(userId, user?.activeDate?.getYear()));
        }
    }, [currentTask.response, dispatch, taskHasResponse, user?.activeDate, userId]);
    useEffect(() => {
        const allProviders: IProvider[] = [];
        const entityIds = [userId];
        hhms?.forEach((hhm) => entityIds.push(hhm.householdMemberId as string));
        if (!taskHasResponse) {
            filterNonChunkHhmData(providersFromState, entityIds).forEach((providerFromState) => {
                const existingProvider = allProviders.filter(
                    (al) => getProviderUniqueKey(al) === getProviderUniqueKey(providerFromState)
                );
                if (arrayHasValue(existingProvider)) {
                    const providerIndex = allProviders.findIndex(
                        (ap) => getProviderUniqueKey(ap) === getProviderUniqueKey(providerFromState)
                    );
                    if (
                        !allProviders[providerIndex]?.entityIds?.includes(
                            providerFromState.entityId as string
                        )
                    ) {
                        allProviders[providerIndex]?.entityIds?.push(
                            providerFromState.entityId as string
                        );
                    }
                } else {
                    providerFromState.entityIds = [providerFromState.entityId as string];
                    allProviders.push(providerFromState);
                }
            });
        } else {
            const providersFromTask = (currentTask.response as IPreferredProvidersDto)
                .providers as IProvider[];
            providersFromTask.forEach((p) => {
                if (entityIds.some((ei) => p.entityIds?.includes(ei))) {
                    allProviders.push(p);
                }
            });
        }
        setProviders(allProviders);
    }, [providersFromState, hhms, userId, currentTask.response, taskHasResponse]);

    return (
        <Grid container direction="column" gap={4} maxWidth={MAX_WIDTH} width="100%">
            <IchraFlowTitle title="Preferred Providers" />
            <Skeleton
                isEnabled={isLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={skeletonStructure}
            >
                <Typography>
                    Add any doctors or hospitals that are important to your household. We ask for
                    preferred providers to help provide ongoing support and advisement services.
                </Typography>
                <Typography pb={2}>
                    Whether specific doctors or hospitals are covered changes often, so we cannot
                    guarantee their coverage. As best practice, we recommend calling your doctor to
                    see if they accept the plan.
                </Typography>
                <CreatableAutocomplete
                    {...creatableAutocompleteProps}
                    disabled={hasValue(providerUniqueKey)}
                    inputValue={autocompleteSearchText}
                    multiple={undefined}
                    onChange={onProviderChange}
                    onInputChange={onAutocompleteSearchTextChange}
                />
                {arrayHasValue(providers) &&
                    providers.map((p) => (
                        <EntityHhmAssignmentCard
                            entity={p}
                            entityIds={p?.entityIds as string[]}
                            entityName={getProviderUniqueKey(p)}
                            form={preferredProvidersForm}
                            handleEditCancelButtonClick={handleEditCancelButtonClick}
                            hhms={hhms}
                            isExpanded={getProviderUniqueKey(p) === providerUniqueKey}
                            isPrimaryOnly={isPrimaryOnly}
                            key={getProviderUniqueKey(p)}
                            removeEntity={handleProviderRemoval}
                            user={user as IUser}
                        />
                    ))}
            </Skeleton>
            <Grid item>
                <IchraFlowButtons
                    currentTaskId={currentTask.globalId}
                    handleNext={handleSubmit}
                    isLoading={isLoading}
                    showPreviousButton
                />
            </Grid>
        </Grid>
    );
};
export default hot(module)(PreferredProvidersPage);
