import { Grid, Typography } from '@mui/material';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { getTeamProfile } from 'actions/team/getTeamProfile';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import { IAddressDto } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import AddressInput from 'components/addressInput/AddressInput';
import {
    clearAddressInputs,
    updateAddressInputs,
    validateAndCheckMatchTeamAddress,
} from 'components/addressInput/addressInputActions';
import { IAddressInputs } from 'components/addressInput/addressInputState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
    checkIsMissingValue,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities/index';

const PrimaryAddressPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, user, userId, year } = useUserProps();
    const { addressInputs, isLoading, userProfile } = useSelector((state: AppStore) => ({
        addressInputs: state.addressInputState.addressInputs,
        isLoading: hasApiActivity(state, GET_USER_PROFILE_ACTION, LIST_VISIBLE_FLOWS_ACTION),
        userProfile: state.userProfile,
    }));
    const [initialChunk, setInitialChunk] = useState<IAddressDto>(currentTask.response);
    const [chunk, setChunk] = useState<IAddressDto>(currentTask.response);
    const { teamId, teamProfile } = useTeamProps();

    useEffect(() => {
        if (!hasValue(initialChunk)) {
            if (!hasValue(userProfile.user)) {
                dispatch(getUserProfile(userId, false));
            } else if (
                checkIsMissingValue([
                    userProfile.address?.addressLine1,
                    userProfile.address?.city,
                    userProfile.address?.county,
                    userProfile.address?.countyFips,
                    userProfile.address?.state,
                    userProfile.address?.zip,
                ])
            ) {
                const newChunk = {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    county: '',
                    countyFips: '',
                    state: '',
                    zip: '',
                };
                setInitialChunk(newChunk as IAddressDto);
                setChunk(newChunk as IAddressDto);
                dispatch(updateAddressInputs(newChunk as IAddressInputs));
            } else {
                const newChunk = {
                    addressLine1: userProfile.address?.addressLine1 ?? '',
                    addressLine2: userProfile.address?.addressLine2 ?? '',
                    city: userProfile.address?.city ?? '',
                    county: userProfile.address?.county ?? '',
                    countyFips: userProfile.address?.countyFips ?? '',
                    state: userProfile.address?.state ?? '',
                    zip: userProfile.address?.zip ?? '',
                };
                setInitialChunk(newChunk as IAddressDto);
                setChunk(newChunk as IAddressDto);
                dispatch(updateAddressInputs(newChunk as IAddressInputs));
            }
        }
    }, [chunk, user, userProfile, dispatch, userId, isCurrent, initialChunk]);

    useEffect(
        () => () => {
            dispatch(clearAddressInputs());
            dispatch(getTeamProfile(teamId));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleSubmit = async () => {
        const isValid = await dispatch(
            validateAndCheckMatchTeamAddress(addressInputs, teamProfile.address, false)
        );

        if (isValid) {
            dispatch(completeTask(currentTask.globalId, addressInputs));
        }
    };
    return (
        <Grid container direction="column" gap={4} maxWidth={MAX_WIDTH}>
            <IchraFlowTitle title="Primary Address" />
            <Typography marginX="auto" variant="body1">
                This is where you reside for the majority of the year. It&apos;s important for this
                to be correct so that we can show you the right plan options. Do not use a P.O. Box.
            </Typography>
            <Skeleton
                isEnabled={isLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 3,
                            height: 55,
                            sx: { mb: 4 },
                        },
                        {
                            columnProps: { flex: 1 },
                            count: 3,
                            height: 55,
                            sx: { mb: 4 },
                        },
                    ],
                ]}
            >
                <AddressInput address={chunk} extraSpacing year={year} />
            </Skeleton>
            <Grid item>
                <IchraFlowButtons
                    currentTaskId={currentTask.globalId}
                    handleNext={handleSubmit}
                    isLoading={isLoading}
                    showPreviousButton
                />
            </Grid>
        </Grid>
    );
};

export default hot(module)(PrimaryAddressPage);
