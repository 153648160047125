import { UserStatus } from 'api/generated/enums';
import BoldHeaderText from 'components/BoldHeaderText';
import useUserProps from 'hooks/useUserProps';
import IncomeForm from 'pages/survey/incomeSteps/IncomeForm';
import { IStepProps } from 'pages/survey/StepWrapper';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const AnnualIncomeForm = ({ children, commonProps }: IStepProps) => {
    const { user } = useUserProps();
    const { incomeYear } = useSelector((state: AppStore) => ({
        incomeYear: state.surveyState.incomeYear,
    }));
    const incomeFormTitle = (
        <React.Fragment>
            What is your annual <BoldHeaderText>base</BoldHeaderText> salary from your employer for{' '}
            {incomeYear}
            {user?.status === UserStatus.Renewing ? ' excluding your Remodel Health Wage+' : ''}?
        </React.Fragment>
    );
    const incomeDescription =
        'This should be just your base salary. Do not include expected commissions or bonuses.';
    return (
        <IncomeForm
            commonProps={commonProps}
            data-cy="employee-income"
            description={incomeDescription}
            hideInfoText
            incomeProp="employeeIncome"
            label="My Salary"
            title={incomeFormTitle}
        >
            {children}
        </IncomeForm>
    );
};

export default hot(module)(AnnualIncomeForm);
