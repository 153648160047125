import { HealthInsuranceParts } from 'api/generated/enums';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import InfoText from 'pages/survey/InfoText';
import DraggableMultiSelectButtons from 'pages/survey/multiSelectButtons/DraggableMultiSelectButtons';
import RankCoverageInformationModal from 'pages/survey/qualitativeSteps/RankCoverageInformationModal';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveQualitative } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import { IOptionWithText } from 'pages/survey/surveyState';
import React from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities/index';

const RankCoverageForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { options } = useSelector((state: AppStore) => ({
        options: state.surveyState.qualitative.rankCoverage,
    }));
    const { openModal, closeModal, isVisible } = useModalState();
    const onDragEnd: OnDragEndResponder = (result) => {
        if (!result.destination) {
            return;
        }
        const rankCoverage = Array.from(options);
        const [removedOption] = rankCoverage.splice(result.source.index, 1);
        rankCoverage.splice(
            result.destination.index,
            0,
            removedOption as IOptionWithText<HealthInsuranceParts>
        );
        if (!removedOption?.isSelected) {
            rankCoverage.forEach((o) => (o.isSelected = true));
        }
        dispatch(saveQualitative({ rankCoverage }));
    };
    const collection = hasValue(options)
        ? options.map((option, index) => {
              option.text = option.text?.replace(
                  /\d\. | \(most important\)| \(least important\)/g,
                  ''
              );
              if (option.isSelected) {
                  option.text = `${index + 1}. ${option.text}`;
                  if (index === 0) {
                      option.text += ' (most important)';
                  } else if (index === options.length - 1) {
                      option.text += ' (least important)';
                  }
              }
              return option;
          })
        : [];
    const isDisabled = hasValue(options) ? options.some((o) => !o.isSelected) : false;
    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled}>
            {isVisible && <RankCoverageInformationModal close={closeModal} />}
            {children}
            <FormTitle
                description={
                    <span>
                        Drag and drop to reorder.
                        <div>Most important at the top and least important at the bottom.</div>
                    </span>
                }
            >
                Please rank the different parts of a health insurance plan that are most important
                to you.
            </FormTitle>
            <InfoText className="text-center mb-3 h4" onClick={openModal}>
                What do these terms mean?
            </InfoText>
            <DraggableMultiSelectButtons onDragEnd={onDragEnd} options={collection} />
        </SurveyFormWrapper>
    );
};

export default hot(module)(RankCoverageForm);
