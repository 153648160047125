import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { HouseholdMemberTypes } from 'api/generated/enums';
import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import { UPDATE_SCROLL_GRADIENT_INDICATOR } from 'components/ScrollGradientIndicator';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import YesOrNoSelect from 'pages/survey/YesOrNoSelect';
import HouseholdMemberCard from 'pages/survey/householdSteps/HouseholdMemberCard';
import HouseholdMemberModal from 'pages/survey/householdSteps/HouseholdMemberModal';
import PrepopulatedAlert from 'pages/survey/householdSteps/PrepopulatedAlert';
import { removeHouseholdMembers, saveHousehold } from 'pages/survey/surveyActions';
import {
    HAS_DEPENDENTS_PROPERTY_NAME,
    HAS_ICHRA_PROPERTY_NAME,
    HAS_UNCLAIMED_PROPERTY_NAME,
    ISurveyHouseholdMember,
} from 'pages/survey/surveyState';
import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasValue, ichraCheckThroughRenewal, isTrue } from 'utilities';
import EventEmitter from 'utilities/eventEmitter';
import {
    getAllDependentChildren,
    getAllIchraChildren,
    getAllMembersOfType,
    hasMemberType,
} from 'utilities/household';

const handleMembersToDisplay = (
    isIchraChild: boolean,
    isDependent: boolean,
    householdMembers: (ISurveyHouseholdMemberDto & { income?: number | undefined })[] | undefined,
    householdMemberType: HouseholdMemberTypes
) => {
    if (isIchraChild) {
        return getAllIchraChildren(householdMembers ?? []);
    } else if (isDependent) {
        return getAllDependentChildren(householdMembers ?? []);
    } else {
        return getAllMembersOfType(householdMembers ?? [], householdMemberType);
    }
};

const getPrepopluatedMembers = (
    prepopulatedHouseholdMemberIds: string[] | undefined,
    householdMembers:
        | (ISurveyHouseholdMemberDto & {
              income?: number | undefined;
          })[]
        | undefined,
    householdMemberType: HouseholdMemberTypes,
    isIchraChild: boolean,
    isDependent: boolean
) =>
    prepopulatedHouseholdMemberIds?.some((x) =>
        householdMembers?.some((hhm) => {
            if (isIchraChild) {
                return (
                    hhm.householdMemberTypeId !== HouseholdMemberTypes.Spouse &&
                    hhm.householdMemberId === x
                );
            } else if (isDependent) {
                return (
                    hhm.householdMemberTypeId !== HouseholdMemberTypes.Spouse &&
                    hhm.householdMemberTypeId !== HouseholdMemberTypes.Unclaimed &&
                    hhm.householdMemberId === x
                );
            } else {
                return (
                    hhm.householdMemberTypeId === householdMemberType && hhm.householdMemberId === x
                );
            }
        })
    );

const HouseholdMemberForm = ({
    commonProps,
    children,
    householdMemberType,
}: IStepProps & { householdMemberType: HouseholdMemberTypes }) => {
    const history = useHistory();
    const dispatch = useThunkDispatch();
    const { isInIchraPathway, user } = useUserProps();
    const [householdMember, setHouseholdMember] = useState({} as ISurveyHouseholdMember);
    const [isEdit, setIsEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const isIchra = ichraCheckThroughRenewal(history, isInIchraPathway, user?.surveyTypeToSend);
    const isIchraChild = householdMemberType === HouseholdMemberTypes.IchraChild;
    const isDependent = householdMemberType === HouseholdMemberTypes.Dependent;

    const handleYesOrNoSelectionPropName = () => {
        if (isIchra) {
            return HAS_ICHRA_PROPERTY_NAME;
        } else {
            if (householdMemberType === HouseholdMemberTypes.Dependent) {
                return HAS_DEPENDENTS_PROPERTY_NAME;
            } else {
                return HAS_UNCLAIMED_PROPERTY_NAME;
            }
        }
    };

    const yesOrNoSelectionPropName = handleYesOrNoSelectionPropName();
    const {
        hasHouseholdMemberType,
        householdMembers,
        incomeYear,
        prepopulatedHouseholdMemberIds,
        surveyYesNoSelection,
    } = useSelector((state: AppStore) => ({
        hasHouseholdMemberType: hasMemberType(
            state.surveyState.household.members,
            householdMemberType
        ),
        householdMembers: state.surveyState.household.members,
        incomeYear: state.surveyState.incomeYear,
        prepopulatedHouseholdMemberIds: state.surveyState.household.prepopulatedHouseholdMemberIds,
        surveyYesNoSelection: state.surveyState.household[yesOrNoSelectionPropName],
    }));

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [householdMembers]);

    useEffect(() => {
        if (isIchraChild) {
            householdMembers?.map((x) => {
                x.needsCoverage = true;
            });
        }
    }, [householdMembers, isIchraChild]);

    const isPrepopulated = getPrepopluatedMembers(
        prepopulatedHouseholdMemberIds,
        householdMembers,
        householdMemberType,
        isIchraChild,
        isDependent
    );

    const handleModalClose = useCallback(() => setShowModal(false), []);
    const handleAdd = useCallback((householdMemberTypeId) => {
        setShowModal(true);
        setHouseholdMember({
            householdMemberTypeId,
        } as ISurveyHouseholdMember);
        setIsEdit(false);
    }, []);
    const handleEdit = useCallback((householdMemberToEdit) => {
        setShowModal(true);
        setHouseholdMember(householdMemberToEdit);
        setIsEdit(true);
    }, []);
    const onSelection = useCallback(
        (isYes) => {
            dispatch(saveHousehold({ [yesOrNoSelectionPropName]: isYes }));
            if (!isYes) {
                dispatch(
                    removeHouseholdMembers(
                        householdMembers?.filter(
                            (x) => x.householdMemberTypeId === householdMemberType
                        ) ?? []
                    )
                );
            }
        },
        [dispatch, householdMemberType, householdMembers, yesOrNoSelectionPropName]
    );

    const membersToDisplay = handleMembersToDisplay(
        isIchraChild,
        isDependent,
        householdMembers,
        householdMemberType
    );

    const MemberCards = useCallback(
        () => (
            <React.Fragment>
                {membersToDisplay.map((householdMemberToRender, index) => (
                    <HouseholdMemberCard
                        {...householdMemberToRender}
                        key={index}
                        onClick={() => handleEdit(householdMemberToRender)}
                    />
                ))}
            </React.Fragment>
        ),
        [handleEdit, membersToDisplay]
    );

    const subtitleText = isIchraChild ? (
        <span>Add any children that will need to be covered on your health insurance plan.</span>
    ) : (
        <span>
            Add household members that you <strong>will {!isDependent && 'not'}</strong> claim on
            your taxes. Do not add your spouse here.
        </span>
    );
    const titleSubtext = <span>{subtitleText}</span>;

    const handleTitleText = () => {
        switch (householdMemberType) {
            case HouseholdMemberTypes.IchraChild:
                return 'Do you have children that need medical coverage?';
            case HouseholdMemberTypes.Dependent:
                return `Will you claim any dependents on your taxes for ${incomeYear}?`;
            default:
                return `Are there members of your household that are on your current health plan, but you won't be claiming them on your taxes in ${incomeYear}?`;
        }
    };

    const handleButtonText = () => {
        if (isIchraChild) {
            return 'Child';
        } else if (isDependent) {
            return 'Dependent';
        } else {
            return 'Unclaimed household member';
        }
    };
    const yesNoSelection = surveyYesNoSelection || isPrepopulated;

    return (
        <SurveyFormWrapper
            {...commonProps}
            isDisabled={
                !hasValue(yesNoSelection) ||
                (isTrue(yesNoSelection) && !hasHouseholdMemberType && !isPrepopulated)
            }
        >
            {children}
            {showModal && (
                <HouseholdMemberModal
                    handleClose={handleModalClose}
                    householdMember={householdMember}
                    isEdit={isEdit}
                />
            )}
            <div>
                <FormTitle description={titleSubtext}>{handleTitleText()}</FormTitle>
                <Row>
                    <Col>
                        <YesOrNoSelect onSelection={onSelection} value={yesNoSelection} />
                    </Col>
                </Row>
                {yesNoSelection && (
                    <React.Fragment>
                        <Stack
                            alignItems="stretch"
                            direction="column"
                            justifyContent="flex-start"
                            spacing={2}
                        >
                            {isPrepopulated && <PrepopulatedAlert />}
                            <MemberCards />
                            <Button
                                data-cy="add-household-member"
                                onClick={() => handleAdd(householdMemberType)}
                                sx={{
                                    alignSelf: 'center',
                                    width: { lg: '50%', xs: '100%' },
                                }}
                                variant="outlined"
                            >
                                <AddIcon /> {handleButtonText()}
                            </Button>
                        </Stack>
                    </React.Fragment>
                )}
            </div>
        </SurveyFormWrapper>
    );
};

export default hot(module)(HouseholdMemberForm);
