import TextArea from 'components/TextArea';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { IStepProps } from 'pages/survey/StepWrapper';
import { saveMemberCoverageSelection } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import { IMemberCoverageSelection } from 'pages/survey/surveyState';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { getDisplayFirstName } from 'utilities/household';

const otherCoverageSourceMinCharacters = 3;

const OtherCoverageSourceForm = ({ children, commonProps }: IStepProps) => {
    const dispatch = useThunkDispatch();
    const { householdMembers, primaryMember } = useSelector((state: AppStore) => ({
        householdMembers: state.surveyState.household.members?.filter((h) => !h.needsCoverage),
        primaryMember: { ...state.surveyState.member },
    }));

    const [memberCoverageSelections, setMemberCoverageSelections] = useState<
        IMemberCoverageSelection[]
    >(() => {
        const selections: IMemberCoverageSelection[] = [
            ...(householdMembers?.filter((h) => !h.needsCoverage) ?? []),
        ];
        if (!primaryMember.needsCoverage) {
            selections.unshift({ householdMemberId: primaryMember.userId, ...primaryMember });
        }
        return selections;
    });

    const onCoverageSourceChange = (member: IMemberCoverageSelection) => (
        otherCoverageSource: string
    ) => {
        const updatedSelections = memberCoverageSelections.map((m) => {
            if (m.householdMemberId === member.householdMemberId) {
                m.otherCoverageSource = otherCoverageSource;
            }
            return m;
        });
        setMemberCoverageSelections(updatedSelections);
    };

    const isDisabled = memberCoverageSelections.some(
        (h: IMemberCoverageSelection) =>
            !hasValue(h.otherCoverageSource) ||
            h.otherCoverageSource.length < otherCoverageSourceMinCharacters
    );

    const onSubmit = useCallback(() => {
        dispatch(saveMemberCoverageSelection(memberCoverageSelections));

        commonProps.onSubmit();
    }, [commonProps, dispatch, memberCoverageSelections]);

    return (
        <SurveyFormWrapper {...commonProps} isDisabled={isDisabled} onSubmit={onSubmit}>
            {children}
            <FormTitle
                description="You marked one or more members as not needing coverage. Please tell us how they
                are currently getting coverage."
            >
                Coverage Source
            </FormTitle>
            {memberCoverageSelections.map((member, index) => (
                <Row className="justify-content-center" key={member.householdMemberId}>
                    <Col md="7">
                        <TextArea
                            autoFocus={index === 0}
                            className="px-1 mt-4"
                            data-cy={`household-coverage-source-${index}`}
                            label={`${getDisplayFirstName(member)}'s Other Coverage Source`}
                            maxLength={500}
                            minRows={2}
                            onChange={onChange(onCoverageSourceChange(member))}
                            value={member.otherCoverageSource}
                        />
                    </Col>
                </Row>
            ))}
        </SurveyFormWrapper>
    );
};

export default hot(module)(OtherCoverageSourceForm);
