import { Card, CardContent } from '@mui/material';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import IfYouHaveQuestionsFooter from 'pages/dashboard/IfYouHaveQuestionsFooter';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';

const PendingApplicationCard = () => (
    <Card>
        <CardContent>
            <Row className="align-items-center justify-content-space-between px-2">
                <Col>
                    <DashboardCardHeader header="Benefit Selections Submitted!" />
                    <hr />
                    <p>
                        Our licensed advisors are reviewing your selection and will reach out to you
                        if they have any questions!
                    </p>
                    <p>
                        A formal email will be sent to you when your plan has been approved and
                        formally submitted for enrollment.
                    </p>
                    <IfYouHaveQuestionsFooter />
                </Col>
            </Row>
        </CardContent>
    </Card>
);

export default hot(module)(PendingApplicationCard);
