import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import {
    ADD_ENROLLMENT_VERIFICATION_ACTION,
    addEnrollmentVerification,
} from 'actions/enrollmentVerification/addEnrollmentVerification';
import {
    PATCH_ENROLLMENT_VERIFICATION_ACTION,
    patchEnrollmentVerification,
} from 'actions/enrollmentVerification/patchEnrollmentVerification';
import {
    GET_HOUSEHOLD_MEMBERS_ACTION,
    getHouseholdMembers,
} from 'actions/householdMember/getHouseholdMembers';
import { EnrollmentVerificationStatuses, EnrollmentVerificationTypes } from 'api/generated/enums';
import { IEnrollmentVerification } from 'api/generated/models';
import Button from 'components/Button';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import HouseholdMemberInput from 'components/householdMemberInput/HouseholdMemberInput';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import startCase from 'lodash/startCase';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { enumToNameValueArray, stringToInt } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { DISPLAY_DATE_FORMAT } from 'utilities/moment';
import { VERIFICATION_TYPE_ITEMS } from 'utilities/verificationDocuments';
import { getYears } from 'utilities/year';
import { number, object, string } from 'yup';

const VERIFICATION_STATUS_ITEMS = enumToNameValueArray(EnrollmentVerificationStatuses, {
    formatName: startCase,
});

const schema = object({
    dueDate: string()
        .required()
        .isValidDate()
        .label('Due Date'),
    receivedDate: string()
        .when('status', {
            is: (x: string) =>
                [
                    EnrollmentVerificationStatuses.Received,
                    EnrollmentVerificationStatuses.Submitted,
                    EnrollmentVerificationStatuses.Verified,
                ].includes(parseInt(x)),
            then: (statusSchema) => statusSchema.required(),
        })
        .isValidDate(false)
        .label('Date Received'),
    status: number()
        .transform(stringToInt)
        .required()
        .label('Status'),
    submittedDate: string()
        .when('status', {
            is: (x: string) =>
                [
                    EnrollmentVerificationStatuses.Submitted,
                    EnrollmentVerificationStatuses.Verified,
                ].includes(parseInt(x)),
            then: (statusSchema) => statusSchema.required(),
        })
        .isValidDate(false)
        .label('Date Submitted'),
    type: number()
        .transform(stringToInt)
        .required()
        .label('Type'),
    verifiedDate: string()
        .when('status', {
            is: (x: string) => [EnrollmentVerificationStatuses.Verified].includes(parseInt(x)),
            then: (statusSchema) => statusSchema.required(),
        })
        .isValidDate(false)
        .label('Date Verified'),
    year: string()
        .required()
        .label('Year'),
});

const getValue = (value: string | undefined) => value ?? '';

const EnrollmentVerificationModal = ({
    data,
    onClose,
}: {
    data?: IEnrollmentVerification;
    onClose: () => void;
}) => {
    const dispatch = useThunkDispatch();
    const isEdit = !!data;
    const [enrollmentVerification, setEnrollmentVerification] = useState({
        dueDate: getValue(formatDateForDisplay(data?.dueDate)),
        receivedDate: getValue(formatDateForDisplay(data?.receivedDate)),
        selectedHouseholdMemberIds: [] as string[],
        status: data?.status ?? EnrollmentVerificationStatuses.Needed,
        submittedDate: getValue(formatDateForDisplay(data?.submittedDate)),
        type: data?.type ?? (('' as unknown) as EnrollmentVerificationTypes),
        verifiedDate: getValue(formatDateForDisplay(data?.verifiedDate)),
        year: getValue(data?.year?.toString()),
    });
    const { errors, validate } = useForm(schema);
    const { userId } = useUserProps();
    const { householdMembersForYear, isLoading, isLoadingHouseholdMembers } = useSelector(
        (state: AppStore) => ({
            householdMembersForYear: [state.userProfile.user, ...state.householdMembers],
            isLoading: hasApiActivity(
                state,
                GET_HOUSEHOLD_MEMBERS_ACTION,
                ADD_ENROLLMENT_VERIFICATION_ACTION,
                PATCH_ENROLLMENT_VERIFICATION_ACTION
            ),
            isLoadingHouseholdMembers: hasApiActivity(state, GET_HOUSEHOLD_MEMBERS_ACTION),
        })
    );

    useEffect(() => {
        const year = Number(enrollmentVerification.year);
        if (year > 0) {
            dispatch(getHouseholdMembers(userId, year));
        }
    }, [dispatch, userId, enrollmentVerification.year]);
    useEffect(() => {
        if (isEdit) {
            const selectedHouseholdMemberIds =
                data.householdMembers?.map((x) => x.householdMemberId) ?? [];
            if (data.includePrimary) {
                selectedHouseholdMemberIds.unshift(userId);
            }
            setEnrollmentVerification((oldState) => ({ ...oldState, selectedHouseholdMemberIds }));
        }
    }, [data, isEdit, userId]);

    const onChange = useCallback(({ target: { name, value } }) => {
        if (name === 'status') {
            const today = moment().format(DISPLAY_DATE_FORMAT);
            switch (parseInt(value)) {
                case EnrollmentVerificationStatuses.Received: {
                    setEnrollmentVerification((oldState) => ({
                        ...oldState,
                        receivedDate: today,
                        status: value,
                    }));
                    break;
                }
                case EnrollmentVerificationStatuses.Submitted: {
                    setEnrollmentVerification((oldState) => ({
                        ...oldState,
                        status: value,
                        submittedDate: today,
                    }));
                    break;
                }
                case EnrollmentVerificationStatuses.Verified: {
                    setEnrollmentVerification((oldState) => ({
                        ...oldState,
                        status: value,
                        verifiedDate: today,
                    }));
                    break;
                }
                default: {
                    setEnrollmentVerification((oldState) => ({ ...oldState, [name]: value }));
                }
            }
        } else {
            setEnrollmentVerification((oldState) => ({ ...oldState, [name]: value }));
        }
    }, []);
    const onSelectedHouseholdMemberIdsChange = useCallback(
        (newSelectedMemberIds) =>
            setEnrollmentVerification((oldState) => ({
                ...oldState,
                selectedHouseholdMemberIds: newSelectedMemberIds,
            })),
        []
    );
    const onYearChange = useCallback(
        (e) => {
            onChange(e);
            onSelectedHouseholdMemberIdsChange([]);
        },
        [onChange, onSelectedHouseholdMemberIdsChange]
    );
    const includePrimary = useMemo(
        () => enrollmentVerification.selectedHouseholdMemberIds?.includes(userId),
        [enrollmentVerification.selectedHouseholdMemberIds, userId]
    );
    const isBeyondNeededStatus = [
        EnrollmentVerificationStatuses.Received,
        EnrollmentVerificationStatuses.Submitted,
        EnrollmentVerificationStatuses.Verified,
    ].includes(parseInt((enrollmentVerification.status as unknown) as string));
    const isReceivedDateRequired = useMemo(
        () =>
            [
                EnrollmentVerificationStatuses.Received,
                EnrollmentVerificationStatuses.Submitted,
                EnrollmentVerificationStatuses.Verified,
            ].includes(parseInt((enrollmentVerification.status as unknown) as string)),
        [enrollmentVerification.status]
    );
    const isSubmittedDateRequired = useMemo(
        () =>
            [
                EnrollmentVerificationStatuses.Submitted,
                EnrollmentVerificationStatuses.Verified,
            ].includes(parseInt((enrollmentVerification.status as unknown) as string)),
        [enrollmentVerification.status]
    );
    const isVerifiedDateRequired = useMemo(
        () =>
            [EnrollmentVerificationStatuses.Verified].includes(
                parseInt((enrollmentVerification.status as unknown) as string)
            ),
        [enrollmentVerification.status]
    );
    const submit = useCallback(async () => {
        let householdMemberIds = enrollmentVerification.selectedHouseholdMemberIds;
        if (includePrimary) {
            householdMemberIds = householdMemberIds?.filter((x) => x !== userId);
        }
        const year = parseInt(enrollmentVerification.year);
        if (isEdit) {
            const {
                status,
                type,
                dueDate,
                receivedDate,
                submittedDate,
                verifiedDate,
            } = enrollmentVerification;
            await dispatch(
                patchEnrollmentVerification(data?.id, {
                    dueDate,
                    householdMemberIds,
                    includePrimary,
                    receivedDate,
                    status,
                    submittedDate,
                    type,
                    verifiedDate,
                    year,
                })
            );
        } else {
            await dispatch(
                addEnrollmentVerification({
                    ...enrollmentVerification,
                    householdMemberIds,
                    includePrimary,
                    userId,
                    year,
                })
            );
        }
    }, [data, dispatch, enrollmentVerification, includePrimary, isEdit, userId]);
    const onSubmit = useCallback(async () => {
        const { isValid } = await validate(enrollmentVerification);
        if (isValid) {
            await submit();
            onClose();
        }
    }, [enrollmentVerification, onClose, submit, validate]);
    const items = useMemo(() => getYears(() => 1).map((x) => ({ name: `${x}` })), []);
    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit' : 'Add'} Enrollment Verification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="enrollment-verification-modal-form"
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                >
                    <Stack spacing={2}>
                        <Select
                            autoFocus
                            data-cy="verification-year"
                            defaultText="Select a Year"
                            defaultValue=""
                            errors={errors?.year}
                            items={items}
                            label="Year"
                            name="year"
                            onChange={onYearChange}
                            optionText="name"
                            optionValue="name"
                            value={enrollmentVerification.year}
                        />
                        <Skeleton count={5} height="54px" isEnabled={isLoadingHouseholdMembers}>
                            <Collapse in={!!enrollmentVerification.year}>
                                <Stack spacing={2}>
                                    <Select
                                        data-cy="verification-status"
                                        errors={errors?.status}
                                        items={VERIFICATION_STATUS_ITEMS}
                                        label="Status"
                                        name="status"
                                        onChange={onChange}
                                        optionText="name"
                                        optionValue="value"
                                        value={enrollmentVerification.status}
                                    />
                                    <Select
                                        data-cy="verification-type"
                                        defaultText="Choose a Verification Type"
                                        defaultValue=""
                                        errors={errors?.type}
                                        items={VERIFICATION_TYPE_ITEMS}
                                        label="Type"
                                        name="type"
                                        onChange={onChange}
                                        optionText="name"
                                        optionValue="value"
                                        value={enrollmentVerification.type}
                                    />
                                    <HouseholdMemberInput
                                        householdMembers={householdMembersForYear}
                                        onChange={onSelectedHouseholdMemberIdsChange}
                                        selectedHouseholdMembers={
                                            enrollmentVerification.selectedHouseholdMemberIds
                                        }
                                    />
                                    <DateTextField
                                        data-cy="due-date"
                                        errors={errors?.dueDate}
                                        label="Due Date"
                                        name="dueDate"
                                        onChange={onChange}
                                        value={enrollmentVerification.dueDate}
                                    />
                                    {isBeyondNeededStatus && (
                                        <React.Fragment>
                                            <DateTextField
                                                data-cy="received-date"
                                                errors={errors?.receivedDate}
                                                isOptional={!isReceivedDateRequired}
                                                label="Date Received"
                                                name="receivedDate"
                                                onChange={onChange}
                                                value={enrollmentVerification.receivedDate}
                                            />

                                            <DateTextField
                                                data-cy="submitted-date"
                                                errors={errors?.submittedDate}
                                                isOptional={!isSubmittedDateRequired}
                                                label="Date Submitted"
                                                name="submittedDate"
                                                onChange={onChange}
                                                value={enrollmentVerification.submittedDate}
                                            />

                                            <DateTextField
                                                data-cy="verified-date"
                                                errors={errors?.verifiedDate}
                                                isOptional={!isVerifiedDateRequired}
                                                label="Date Verified"
                                                name="verifiedDate"
                                                onChange={onChange}
                                                value={enrollmentVerification.verifiedDate}
                                            />
                                        </React.Fragment>
                                    )}
                                </Stack>
                            </Collapse>
                        </Skeleton>
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Close</Button>
                <Button
                    disabled={enrollmentVerification.selectedHouseholdMemberIds.length === 0}
                    form="enrollment-verification-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(EnrollmentVerificationModal);
