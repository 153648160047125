import { getMarketplacePlanRatesForPlans } from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getOffExchangeMarketplacePlanRatesForUser';
import { GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getOnExchangeMarketplacePlanRatesForUser';
import {
    GET_MEDISHARE_PLANS_FOR_USER_ACTION,
    getMediSharePlansAndRatesForUser,
} from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { IMarketplacePlanDto } from 'api/generated/models';
import Button from 'components/Button';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import isEmpty from 'lodash/isEmpty';
import { IPlan } from 'pages/shop/PlanCard';
import React, { useCallback, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasCompletedRequest } from 'selectors/activity';

const ErrorPlanCard = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { errorPlans, excludedMembers, selectedYear } = useSelector((state: AppStore) => {
        const props = {
            ...state.shoppingState,
            errorPlans: [] as IPlan[],
        };
        if (hasCompletedRequest(state, GET_MEDISHARE_PLANS_FOR_USER_ACTION)) {
            props.errorPlans = state.mediSharePlans
                .filter((x) => x.hasError)
                .map((x) => ({ ...x, isMediShare: true }));
        }
        if (
            hasCompletedRequest(state, GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION) ||
            hasCompletedRequest(state, GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION)
        ) {
            props.errorPlans = props.errorPlans.concat(
                state.marketplacePlans
                    .filter((x) => x.hasError)
                    .map((x) => ({ ...x, isMarketplace: true }))
            );
        }
        return props;
    });

    const [isReloadingPlans, setIsReloadingPlans] = useState(false);

    const reloadErrorPlans = useCallback(async () => {
        setIsReloadingPlans(true);
        if (errorPlans.some((x) => x.isMarketplace)) {
            const onExchangePlanIds = errorPlans
                .filter((x) => x.isMarketplace && !isEmpty((x as IMarketplacePlanDto).exchange))
                .map((x) => (x as IMarketplacePlanDto).id as string);
            if (onExchangePlanIds.length > 0) {
                await dispatch(
                    getMarketplacePlanRatesForPlans(
                        userId,
                        onExchangePlanIds,
                        excludedMembers,
                        selectedYear,
                        false
                    )
                );
            }
            const offExchangePlanIds = errorPlans
                .filter((x) => x.isMarketplace && isEmpty((x as IMarketplacePlanDto).exchange))
                .map((x) => (x as IMarketplacePlanDto).id as string);
            if (offExchangePlanIds.length > 0) {
                await dispatch(
                    getMarketplacePlanRatesForPlans(
                        userId,
                        offExchangePlanIds,
                        excludedMembers,
                        selectedYear,
                        true
                    )
                );
            }
        }
        if (errorPlans.some((x) => x.isMediShare)) {
            await dispatch(
                getMediSharePlansAndRatesForUser(userId, selectedYear, false, excludedMembers)
            );
        }
        setIsReloadingPlans(false);
    }, [dispatch, errorPlans, excludedMembers, selectedYear, userId]);

    if (!isEmpty(errorPlans) || isReloadingPlans) {
        return (
            <Alert className="d-flex align-items-center justify-content-between" variant="danger">
                Unable to load details for {errorPlans.length} plans.
                <Button color="error" isLoading={isReloadingPlans} onClick={reloadErrorPlans}>
                    {isReloadingPlans ? 'Loading' : `Reload ${errorPlans.length} Plans`}
                </Button>
            </Alert>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(ErrorPlanCard);
