import { Card, CardContent } from '@mui/material';
import CalendlyModalButton from 'components/calendly/CalendlyModalButton';
import Title from 'components/Title';
import { useSetUserAdvisementScheduled } from 'hooks/useSetUserAdvisementScheduled';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const ScheduleAdvisementCard = () => {
    const setUserAdvisementScheduled = useSetUserAdvisementScheduled();

    return (
        <Card>
            <CardContent>
                <Row className="align-items-center justify-content-space-between px-2">
                    <Col>
                        <Title>Schedule Advisement</Title>
                        <p className="my-0 text-muted">
                            Schedule a call with a benefits advisor to talk through your options
                        </p>
                    </Col>
                    <Col md="auto">
                        <CalendlyModalButton
                            buttonLabel="Schedule"
                            onAdvisementScheduled={setUserAdvisementScheduled}
                            size="small"
                            variant="outlined"
                        ></CalendlyModalButton>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};

export default hot(module)(ScheduleAdvisementCard);
