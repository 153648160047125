import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { ExpenseStatus } from 'api/generated/enums';
import { IRhFileDto, ISubmittedExpenseDto } from 'api/generated/models';
import { EditLockedSubmittedExpense, EditSubmittedExpense } from 'api/generated/permissions';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import FileInput from 'components/fileInput/FileInput';
import { VALID_UPLOAD_FILE_EXTENSIONS } from 'constants/fileExtensions';
import SubmittedExpenseFile from 'pages/reimbursementExpenseManagement/submittedExpenseModal/SubmittedExpenseFile';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';

const SubmittedExpenseFiles = ({
    checkForChanges,
    existingFiles,
    fileIdsToDelete,
    isLoading,
    newFiles,
    setFilesToBeDeleted,
    setNewFileData,
    submittedExpense,
}: {
    checkForChanges: () => void;
    existingFiles: IRhFileDto[] | undefined;
    fileIdsToDelete: string[];
    isLoading: boolean;
    newFiles: File | File[] | undefined;
    setFilesToBeDeleted: (fileId: string[]) => void;
    setNewFileData: (file: File | File[] | undefined) => void;
    submittedExpense: ISubmittedExpenseDto;
}) => {
    const { canEditLockedExpense, canEditSubmittedExpense } = useSelector((state: AppStore) => ({
        canEditLockedExpense: hasSomePermissions(state, EditLockedSubmittedExpense),
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
    }));
    const showFileInput =
        (!submittedExpense.isLocked || canEditLockedExpense) &&
        (canEditSubmittedExpense ||
            submittedExpense.expenseStatus === ExpenseStatus.InformationNeeded);
    const onIconButtonClick = (file: File | IRhFileDto) => {
        const fileId = (file as IRhFileDto)?.id;
        if (fileId) {
            if (fileIdsToDelete.contains(fileId)) {
                // deciding NOT to delete the file
                setFilesToBeDeleted(fileIdsToDelete.filter((x) => x !== fileId));
            } else {
                // deleting existing file
                setFilesToBeDeleted([...fileIdsToDelete, fileId]);
            }
        }
        if (!fileId) {
            // removing new file from upload list
            if (Array.isArray(newFiles)) {
                setNewFileData(newFiles.filter((x) => x !== (file as File)));
            } else {
                setNewFileData(undefined);
            }
        }
    };
    useEffect(() => {
        checkForChanges();
    }, [checkForChanges, fileIdsToDelete, newFiles]);
    const newFileData: File[] = (newFiles as File[]) ?? [];
    const haveExistingFiles = (existingFiles?.length ?? 0) > 0;
    const haveNewFiles = newFileData.length > 0;
    const showMiddleDivider = haveExistingFiles && haveNewFiles;
    return (
        <Stack>
            <Typography fontWeight="bold">Files</Typography>
            <List disablePadding>
                <Skeleton height={59.5} isEnabled={isLoading} sx={{ mb: 2 }}>
                    {existingFiles?.map((file) => (
                        <React.Fragment key={file.id}>
                            <SubmittedExpenseFile
                                enableIconButton={
                                    canEditSubmittedExpense &&
                                    file.createdByUser?.userId !== submittedExpense.userId
                                }
                                file={file}
                                isDeleting={fileIdsToDelete.contains(file?.id)}
                                isLoading={isLoading}
                                onIconButtonClick={onIconButtonClick}
                                showIconButton={
                                    (canEditSubmittedExpense && !submittedExpense.isLocked) ||
                                    canEditLockedExpense
                                }
                                submittedExpense={submittedExpense}
                            />
                        </React.Fragment>
                    ))}
                    {showMiddleDivider && <Divider />}
                    {newFileData.map((file) => (
                        <React.Fragment key={`${file.name}${file.lastModified.toString()}`}>
                            <SubmittedExpenseFile
                                enableIconButton
                                file={file}
                                isLoading={isLoading}
                                isNewFile
                                onIconButtonClick={onIconButtonClick}
                                showIconButton
                                submittedExpense={submittedExpense}
                            />
                        </React.Fragment>
                    ))}
                </Skeleton>
            </List>
            {showFileInput && (
                <FileInput
                    accept={VALID_UPLOAD_FILE_EXTENSIONS}
                    buttonLabel="Add Files"
                    disabled={isLoading}
                    fileData={newFileData}
                    hideFiles
                    isMultiple
                    onChange={setNewFileData}
                />
            )}
        </Stack>
    );
};

export default hot(module)(SubmittedExpenseFiles);
