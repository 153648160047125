import { Card, CardContent } from '@mui/material';
import Button from 'components/Button';
import Title from 'components/Title';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { Link } from 'react-router-dom';
import { MY_BENEFITS_PATH } from 'routers/routes';

const EnrolledCard = () => (
    <Card>
        <CardContent>
            <Row className="align-items-center justify-content-space-between px-2">
                <Col>
                    <Title>You&apos;re all set!</Title>
                    <p className="my-0 text-muted">
                        We&apos;ve enrolled you in your selected benefits.
                    </p>
                </Col>
                <Col md="auto">
                    <Link to={MY_BENEFITS_PATH}>
                        <Button data-cy="my-benefits" size="small">
                            My Benefits
                        </Button>
                    </Link>
                </Col>
            </Row>
        </CardContent>
    </Card>
);

export default hot(module)(EnrolledCard);
