import { SAVE_COMPLETED_TEAM_TASK_ACTION } from 'actions/task/saveCompletedTeamTask';
import { ILaunchDetails } from 'api/generated/models';
import Button from 'components/Button';
import Form from 'components/Form';
import LaunchDetailsTable from 'components/launchDetails/LaunchDetailsTable';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

type IReviewLaunchDetailsModalProps = StateProps & {
    canEditLaunchDetails?: boolean;
    handleClose: () => void;
    handleIncorrectClick?: () => void;
    launchDetails: ILaunchDetails;
    onHide: () => void;
};

class ReviewLaunchDetailsModal extends React.PureComponent<IReviewLaunchDetailsModalProps> {
    handleSubmit = () => {
        this.props.handleClose();
    };

    override render() {
        const {
            launchDetails,
            canEditLaunchDetails,
            handleIncorrectClick,
            onHide,
            showActivity,
        } = this.props;
        return (
            <Modal data-cy="review-launch-details-modal" onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title>Review Launch Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="review-launch-details-modal"
                        isLoading={showActivity}
                        onSubmit={this.handleSubmit}
                    >
                        <LaunchDetailsTable
                            canEdit={canEditLaunchDetails}
                            launchDetails={launchDetails}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer className="centered">
                    {handleIncorrectClick && (
                        <Button data-cy="somethings-not-right" onClick={handleIncorrectClick}>
                            Something&apos;s not right...
                        </Button>
                    )}
                    <Button
                        data-cy="looks-good"
                        form="review-launch-details-modal"
                        isLoading={showActivity}
                        type="submit"
                    >
                        {handleIncorrectClick ? 'Looks good!' : 'Close'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state: AppStore) => ({
    showActivity: hasApiActivity(state, SAVE_COMPLETED_TEAM_TASK_ACTION),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default hot(module)(connect(mapStateToProps)(ReviewLaunchDetailsModal));
