import Stack from '@mui/material/Stack';
import { listSubmittedExpenses } from 'actions/submittedExpenses/listSubmittedExpenses';
import { IUserTeamBenefitTermDetailDto } from 'api/generated/models';
import {
    EditSubmittedExpense,
    SubmitExpense,
    ViewSubmittedExpenses,
} from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import Checkbox from 'components/Checkbox';
import PageHeader from 'components/PageHeader';
import PageSectionWrapper from 'components/PageSectionWrapper';
import Select from 'components/Select';
import TableHeader from 'components/TableHeader';
import useModalState from 'hooks/useModalState';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import SubmitExpenseModal from 'pages/benefits/SubmitExpenseModal';
import { setSelectedYear } from 'pages/profile/profileActions';
import ExpenseTypeModal from 'pages/reimbursementExpenseManagement/ExpenseTypeModal';
import ReimbursementExpenseManagementTable from 'pages/reimbursementExpenseManagement/ReimbursementExpenseManagementTable';
import ReimbursementUtilizationSection from 'pages/reimbursementExpenseManagement/ReimbursementUtilizationSection';
import UploadSubmittedExpenseModal from 'pages/reimbursementExpenseManagement/UploadSubmittedExpenseModal';
import useReimbursementManagementPageContext from 'pages/reimbursementExpenseManagement/useReimbursementManagementPageContext';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { isTodayBefore, maxDateString } from 'utilities/moment';
import { getYears } from 'utilities/year';

const items = getYears(() => 1).map((x) => ({ name: `${x}` }));

type IReimbursementExpenseManagementPageProps = RouteComponentProps & {
    userTeamBenefitTermDetail?: IUserTeamBenefitTermDetailDto;
};

const ReimbursementExpenseManagementPage = ({
    userTeamBenefitTermDetail,
}: IReimbursementExpenseManagementPageProps) => {
    const dispatch = useThunkDispatch();

    const {
        canEditSubmittedExpense,
        canSubmitExpense,
        canViewSubmittedExpenses,
        selectedYear,
    } = useSelector((state: AppStore) => ({
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        canSubmitExpense: hasSomePermissions(state, SubmitExpense),
        canViewSubmittedExpenses: hasSomePermissions(state, ViewSubmittedExpenses),
        selectedYear: state.profileState.selectedYear,
    }));

    const location = useLocation();

    const [query, setQuery] = useQuery();
    const year = query.get('year');

    const { hasTeamIdUrlParam, team } = useTeamProps();
    const { hasUserIdUrlParam, isCurrent, user } = useUserProps();
    const {
        isGlobal,
        isMemberPage,
        reimbursementPageContext,
    } = useReimbursementManagementPageContext(
        location.pathname,
        hasTeamIdUrlParam,
        hasUserIdUrlParam
    );

    const isBeforeBenefitCoverageStartDate = isTodayBefore(
        (userTeamBenefitTermDetail?.coverageStartDate as string) ?? maxDateString
    );

    const {
        isVisible: isSubmitExpenseModalVisible,
        openModal: openSubmitExpenseModal,
        closeModal: closeSubmitExpenseModal,
    } = useModalState();

    const [showArchivedExpenses, setShowArchivedExpenses] = useState(false);

    const {
        closeModal: closeAddExpenseTypeModal,
        isVisible: isAddExpenseTypeModalVisible,
        openModal: openAddExpenseTypeModal,
    } = useModalState();

    const {
        closeModal: closeUploadSubmittedExpensesModal,
        isVisible: isUploadSubmittedExpensesModalVisible,
        openModal: openUploadSubmittedExpensesModal,
    } = useModalState();

    const [search, setSearch] = useState('');
    const onSelectedYearChange = useCallback(
        ({ target: { value } }) => {
            query.set('year', value);
            setQuery(query);
            dispatch(setSelectedYear(value));
        },
        [dispatch, query, setQuery]
    );

    const showYearDropdown = !isGlobal;
    const showSearchBar = (canEditSubmittedExpense || canViewSubmittedExpenses) && !isMemberPage;
    const showAction = isGlobal && canEditSubmittedExpense;

    useEffect(() => {
        if (hasValue(year) && showYearDropdown) {
            dispatch(setSelectedYear(year));
        } else if (!hasValue(selectedYear) && hasValue(team)) {
            const yearToUpdate = query.get('year') ?? new Date().getUTCFullYear().toString();
            dispatch(setSelectedYear(yearToUpdate));
        }
    }, [dispatch, showYearDropdown, selectedYear, team, query, year]);

    const actions: IActionButtonItems = [
        {
            dataCy: 'submit-expense',
            isVisible:
                isMemberPage &&
                (canSubmitExpense || isCurrent) &&
                !isBeforeBenefitCoverageStartDate,
            onClick: openSubmitExpenseModal,
            text: 'Submit Expense',
        },
        {
            dataCy: 'add-expense-type',
            isVisible: showAction,
            onClick: openAddExpenseTypeModal,
            text: 'Add New Expense Type',
        },
        {
            isVisible: showAction,
            onClick: openUploadSubmittedExpensesModal,
            text: 'Import',
        },
    ];

    const getHeaderText = () => {
        let text = '';
        if (!hasValue(user)) {
            return text;
        }
        if (hasUserIdUrlParam) {
            text = user?.firstName + "'s ";
        } else if (isMemberPage) {
            text = 'My ';
        }
        return text;
    };

    const onSubmit = async () =>
        dispatch(listSubmittedExpenses(team?.teamId, user?.userId, Number(selectedYear)));

    return (
        <PageSectionWrapper>
            <TableHeader
                dropdownWhenLengthGreaterThan={1}
                items={actions}
                label={
                    <PageHeader minWidth="300px" textAlign="left" variant="h4">
                        {getHeaderText() ?? ''}Submitted Expenses
                    </PageHeader>
                }
                onChange={onChange(setSearch)}
                search={showSearchBar ? search : undefined}
            >
                <React.Fragment>
                    {showYearDropdown && (
                        <Select
                            data-cy="selected-year"
                            items={items}
                            name="selectedYear"
                            onChange={onSelectedYearChange}
                            optionText="name"
                            optionValue="name"
                            sx={{ flexBasis: '50%' }}
                            value={selectedYear}
                        />
                    )}
                    {canEditSubmittedExpense && (
                        <Stack minWidth="200px">
                            <Checkbox
                                checked={showArchivedExpenses}
                                className="m-0 ml-3"
                                label="Show Archived"
                                onClick={() => setShowArchivedExpenses(!showArchivedExpenses)}
                            />
                        </Stack>
                    )}
                </React.Fragment>
            </TableHeader>
            {isMemberPage && <ReimbursementUtilizationSection />}
            <ReimbursementExpenseManagementTable
                location={reimbursementPageContext}
                search={search}
                showArchived={showArchivedExpenses}
                showMemberNameColumn={!isMemberPage}
                showTeamNameColumn={isGlobal}
            ></ReimbursementExpenseManagementTable>
            {isAddExpenseTypeModalVisible && (
                <ExpenseTypeModal onClose={closeAddExpenseTypeModal} />
            )}
            {isUploadSubmittedExpensesModalVisible && (
                <UploadSubmittedExpenseModal onClose={closeUploadSubmittedExpensesModal} />
            )}
            {isSubmitExpenseModalVisible && (
                <SubmitExpenseModal onClose={closeSubmitExpenseModal} onSubmit={onSubmit} />
            )}
        </PageSectionWrapper>
    );
};

export default hot(module)(ReimbursementExpenseManagementPage);
