import {
    GET_ACTIVE_QUESTIONS_ACTION,
    getActiveQuestions,
} from 'actions/customQuestions/getActiveQuestions';
import {
    LIST_DRUGS_FOR_HOUSEHOLD_ACTION,
    listDrugsForHousehold,
} from 'actions/drug/listDrugsForHousehold';
import { GET_HOUSEHOLD_MEMBERS_ACTION } from 'actions/householdMember/getHouseholdMembers';
import {
    LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION,
    listProvidersForHousehold,
} from 'actions/provider/listProvidersForHousehold';
import {
    GET_CUSTOM_QUESTION_ANSWERS_ACTION,
    getCustomQuestionAnswers,
} from 'actions/user/getCustomQuestionAnswers';
import {
    GET_QUALITATIVE_ANSWERS_ACTION,
    getQualitativeAnswers,
} from 'actions/user/getQualitativeAnswers';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { UserStatus } from 'api/generated/enums';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import VerifiedInfoSummary from 'components/verifiedInfoSummary/VerifiedInfoSummary';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { verifiedInfoSelector } from 'pages/survey/surveySelectors';
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

type IReviewMemberInfoModalProps = {
    handleClose: () => void;
    handleIncorrectClick: () => void;
    isIchra: boolean;
    onHide: () => void;
};

const ReviewMemberInfoModal = ({
    handleClose,
    handleIncorrectClick,
    isIchra,
    onHide,
}: IReviewMemberInfoModalProps) => {
    const dispatch = useThunkDispatch();
    const { includeFaithBasedQuestionInSurvey, teamProfile } = useTeamProps();
    const { memberVerifiedInfo, showActivity, teamId, verifiedInfo } = useSelector(
        (state: AppStore) => ({
            memberVerifiedInfo: state.current.userProfile?.memberVerifiedInfo,
            showActivity: hasApiActivity(
                state,
                GET_USER_PROFILE_ACTION,
                GET_HOUSEHOLD_MEMBERS_ACTION,
                LIST_DRUGS_FOR_HOUSEHOLD_ACTION,
                LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION,
                GET_ACTIVE_QUESTIONS_ACTION,
                GET_CUSTOM_QUESTION_ANSWERS_ACTION,
                GET_QUALITATIVE_ANSWERS_ACTION
            ),
            teamId: state.current.userProfile?.user?.teamId,
            verifiedInfo: verifiedInfoSelector(state),
        })
    );
    const { userId, year } = memberVerifiedInfo ?? {};
    const { user } = useUserProps();
    const isRenewal = user?.status === UserStatus.Renewing;
    const includeQualitativeQuestions = teamProfile.team?.includeQualitativeQuestionsInSurvey;
    const hideQualitativeQuestions = isRenewal || !includeQualitativeQuestions;
    useEffect(() => {
        if (userId && year) {
            dispatch(getActiveQuestions(teamId));
            dispatch(getCustomQuestionAnswers(userId));
            dispatch(getQualitativeAnswers(userId));
            dispatch(listDrugsForHousehold(userId, year));
            dispatch(listProvidersForHousehold(userId, year));
        }
    }, [dispatch, teamId, userId, year]);
    return (
        <Modal data-cy="review-member-info-modal" onHide={onHide} scrollable show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Review Member Info</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Skeleton count={4} height="260px" isEnabled={showActivity} sx={{ mb: 2 }}>
                    <VerifiedInfoSummary
                        data={verifiedInfo}
                        hideQualitative={hideQualitativeQuestions}
                        includeFaithBasedQuestionOverride={includeFaithBasedQuestionInSurvey}
                        isIchra={isIchra}
                    />
                </Skeleton>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button data-cy="something-is-incorrect-button" onClick={handleIncorrectClick}>
                    Something is incorrect
                </Button>
                <Button onClick={handleClose} type="submit">
                    Looks good!
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(ReviewMemberInfoModal);
