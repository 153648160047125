import Typography from '@mui/material/Typography';
import {
    GET_MEDISHARE_PLANS_FOR_USER_ACTION,
    getMediSharePlansAndRatesForUser,
} from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { ISelectedPlan } from 'api/generated/models';
import MajorMedicalBenefitCostButton from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCostButton';
import ParagraphWithLineBreaks from 'components/ParagraphWithLineBreaks';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { setPlanForBenefitSelection } from 'pages/dashboard/medicalBenefitSelection/benefitSelectionActions';
import PlanCard, { IPlan } from 'pages/shop/PlanCard';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { convertUrlsToHyperlinks, hasValue } from 'utilities';

const HealthcareSharingPathwayPlanDetails = () => {
    const dispatch = useThunkDispatch();
    const { user, userId, year } = useUserProps();
    const { isLoading, isPlanLoaded, pathwayDataForUser, plan } = useSelector(
        (state: AppStore) => ({
            isLoading: hasApiActivity(state, GET_MEDISHARE_PLANS_FOR_USER_ACTION),
            isPlanLoaded: hasCompletedRequest(state, GET_MEDISHARE_PLANS_FOR_USER_ACTION),
            pathwayDataForUser: state.pathwayDataForUser,
            plan: {
                isMediShare: true,
                ...state.mediSharePlans?.find((p) => p.ahp === state.pathwayDataForUser?.planId),
            },
        })
    );
    const [haveStoredPlan, setHaveStoredPlan] = useState(false);
    useEffect(() => {
        if (pathwayDataForUser?.planId && user?.activeDate && !isPlanLoaded) {
            dispatch(getMediSharePlansAndRatesForUser(userId, year, true, []));
        }
    }, [dispatch, isPlanLoaded, pathwayDataForUser, user, userId, year]);
    useEffect(() => {
        if (isPlanLoaded && !haveStoredPlan) {
            dispatch(setPlanForBenefitSelection((plan as unknown) as ISelectedPlan));
            setHaveStoredPlan(true);
        }
    }, [dispatch, haveStoredPlan, isPlanLoaded, plan]);
    return (
        <Skeleton count={1} height="300px" isEnabled={isLoading}>
            <Col>
                <Row className="justify-content-center mb-2">
                    Based on your household information, this pathway would be a great fit for you.
                </Row>
                {isPlanLoaded && (
                    <React.Fragment>
                        <div>
                            {pathwayDataForUser?.displayNameOverride ? (
                                <Typography textAlign="center" variant="h3">
                                    {pathwayDataForUser?.displayNameOverride}
                                </Typography>
                            ) : (
                                <PlanCard hideCost hideSelectPlan plan={plan as IPlan} />
                            )}
                        </div>
                        {hasValue(pathwayDataForUser?.extraBenefitText) && (
                            <Row className="justify-content-center text-start mx-3">
                                <ParagraphWithLineBreaks
                                    dangerouslySetInnerHTML={{
                                        __html: convertUrlsToHyperlinks(
                                            pathwayDataForUser?.extraBenefitText
                                        ),
                                    }}
                                />
                            </Row>
                        )}
                        <Row className="justify-content-center">
                            <MajorMedicalBenefitCostButton
                                majorMedicalBenefitCost={plan.majorMedicalBenefitCost}
                            />
                        </Row>
                    </React.Fragment>
                )}
            </Col>
        </Skeleton>
    );
};

export default hot(module)(HealthcareSharingPathwayPlanDetails);
