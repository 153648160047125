import { GET_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { IPathway, IPathwayBlueprint, Pathway } from 'api/generated/models';
import {
    ADD_PATHWAY_BLUEPRINT_MODAL_STATE_ACTION,
    CLEAR_PATHWAY_BLUEPRINT_MODAL_STATE_ACTION,
    SET_PATHWAY_BLUEPRINT_INPUTS_ACTION,
} from 'pages/pathwayBlueprints/pathwayBlueprintModalActions';
import { stringToInt } from 'utilities';
import { IFormErrors } from 'utilities/forms';
import { array, number, object, string } from 'yup';

export type IPathwayBlueprintModalInvalidPlans = {
    marketplaceCarrier?: boolean;
    marketplacePlanId?: boolean;
    pathwayId?: string;
};

const ONE_HUNDRED = 100;
export const schema = object({
    ichraClassPlans: array().of(
        object({
            marketplaceCarrier: string()
                .required()
                .label('Benchmark Carrier'),
            planId: string()
                .required()
                .label('Benchmark Plan'),
        })
    ),
    name: string()
        .trim()
        .required()
        .max(ONE_HUNDRED)
        .label('Name'),
    plans: array().of(
        object({
            marketplaceCarrier: string()
                .required()
                .label('Benchmark Carrier'),
            planId: string()
                .required()
                .label('Benchmark Plan'),
        })
    ),
    recommendedPlans: array().of(
        object({
            marketplaceCarrier: string()
                .required()
                .label('Recommended Carrier'),
            planId: string()
                .required()
                .label('Recommended Plan'),
        })
    ),
    wageUpFloor: number()
        .transform(stringToInt)
        .required()
        .min(0)
        .label('Wage+ Floor'),
    year: number()
        .transform(stringToInt)
        .required()
        .moreThan(0)
        .label('Year'),
});
export type IPathwayBlueprintModalState = IPathwayBlueprint & {
    errors: IFormErrors<typeof schema>;
};

const initialState: Partial<IPathwayBlueprintModalState> = {
    errors: null,
    pathways: [],
    wageUpFloor: 25,
};

export const pathwayBlueprintModalState = (
    state = initialState,
    action: { data: IPathway | IPathwayBlueprintModalState; type: string }
): Partial<IPathwayBlueprintModalState> => {
    switch (action.type) {
        case ADD_PATHWAY_BLUEPRINT_MODAL_STATE_ACTION:
            state.pathways?.push(action.data as Pathway);
            return { ...state };
        case SET_PATHWAY_BLUEPRINT_INPUTS_ACTION:
        case GET_PATHWAY_BLUEPRINT_ACTION.success:
            return {
                ...state,
                ...action.data,
            };
        case CLEAR_PATHWAY_BLUEPRINT_MODAL_STATE_ACTION:
            return initialState;
    }
    return state;
};
