import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const IncomeVerificationExamples = () => (
    <React.Fragment>
        <p>
            You told us your yearly household income on your application. The documents you submit
            to the Marketplace to confirm your income must show a yearly income amount that closely
            matches the yearly income amount you entered on your application. For example, if you
            have a different job than you had last year, send the Marketplace recent pay stubs from
            your new job, instead of last year&apos;s tax return or W2. Read{' '}
            <ExternalLink href="https://marketplace.cms.gov/outreach-and-education/household-income-data-matching-issues.pdf">
                &quot;The Consumer Guide to Annual Income data matching issues&quot;
            </ExternalLink>{' '}
            for more information on how to resolve income-related data matching issues. The Guide
            includes worksheets that can help you choose the right document to submit.
        </p>
        <p>Here are the documents you can submit to confirm your yearly income:</p>
        <ul>
            <li>
                1040 federal or state tax return. <strong>Note</strong>: It must contain first and
                last name, income amount, and tax year. Starting with 2018 tax returns, if you file
                Schedule 1, you must submit it with your 1040. Schedule 1 shows additional income
                and adjustments, like capital gains, unemployment compensation, student loan
                interest, or self-employment tax.
            </li>
            <li>
                Wages and tax statement (W-2 and/ or 1099, including 1099 MISC, 1099G, 1099R,
                1099SSA, 1099DIV, 1099SS, 1099INT). <strong>Note</strong>: It must contain your
                first and last name, income amount, year, and employer name (if applicable).
            </li>
            <li>
                Pay stub. <strong>Note</strong>: It must contain your first and last name, income
                amount, pay period or frequency of pay with the date of payment. If a pay stub
                includes overtime, please indicate average overtime amount per paycheck.
            </li>
            <li>
                Self-employment ledger documentation (can be a Schedule C, the most recent quarterly
                or year-to-date profit and loss statement, or a self-employment ledger).{' '}
                <strong>Note</strong>: It must contain your first and last name, company name, and
                income amount. If you’re submitting a self-employment ledger, include the dates
                covered by the ledger, and the net income from profit/loss.
            </li>
            <li>
                Social Security Administration Statements (Social Security Benefits Letter).
                <strong>Note</strong>: It must contain first and last name, benefit amount, and
                frequency of pay.
            </li>
            <li>
                Unemployment Benefits Letter. <strong>Note</strong>: It must contain your first and
                last name, source/agency, benefits amount, and duration (start and end date, if
                applicable).
            </li>
        </ul>
        <p>Documents to confirm self-employment income</p>
        <ul>
            <li>1040 SE with Schedule C, F, or SE (for self-employment income)</li>
            <li>1065 Schedule K1 with Schedule E</li>
            <li>Tax return</li>
            <li>Bookkeeping records</li>
            <li>
                Receipts for <strong>all</strong> allowable expenses
            </li>
            <li>Signed time sheets and receipt of payroll, if you have employees</li>
            <li>Most recent quarterly or year-to-date profit and loss statement</li>
        </ul>
        <p>Documents to confirm unearned income</p>
        <ul>
            <li>Annuity statement</li>
            <li>Statement of pension distribution from any government or private source</li>
            <li>Worker’s compensation letter</li>
            <li>Prizes, settlements, and awards, including court-ordered awards letter</li>
            <li>Proof of gifts and contributions</li>
            <li>Proof of inheritances in cash or property</li>
            <li>Proof of strike pay and other benefits from unions</li>
            <li>
                Sales receipts or other proof of money received from the sale, exchange, or
                replacement of things you own
            </li>
            <li>Interests and dividends income statement</li>
            <li>Loan statement showing loan proceeds</li>
            <li>Royalty income statement or 1099-MISC</li>
            <li>Proof of bonus/incentive payments</li>
            <li>Proof of severance pay</li>
            <li>Pay stub indicating sick pay</li>
            <li>Letter, deposit, or other proof of deferred compensation payments</li>
            <li>Pay stub indicating substitute/assistant pay</li>
            <li>Pay stub indicating vacation pay</li>
            <li>Proof of residuals</li>
            <li>Letter, deposit, or other proof of travel/business reimbursement pay</li>
        </ul>
    </React.Fragment>
);

export default hot(module)(IncomeVerificationExamples);
