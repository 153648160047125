import { dispatchWrapper } from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { ITeamProfile } from 'api/generated/models';
import { TableState } from 'react-table';
import { transformFiltersToEnumArray, transformSortByToOrderingArray } from 'utilities/teams';

export const SET_TEAMS_PAGE_SEARCH = 'SET_TEAMS_PAGE_SEARCH';
export const setTeamsPageSearch = (search: string) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { search },
        type: SET_TEAMS_PAGE_SEARCH,
    });
};

export const SET_TEAMS_PAGE_PAGINATION_PARAMS = 'SET_TEAMS_PAGE_PAGINATION_PARAMS';
export const setTeamsPagePaginationParams = (state: TableState<ITeamProfile>) => (
    dispatch: AppStoreThunkDispatch
) => {
    const { includeArchived, teamStateFilters } = transformFiltersToEnumArray(state.filters);
    const orderings = transformSortByToOrderingArray(state.sortBy ?? []);
    dispatch({
        data: {
            includeArchived,
            orderings,
            teamStateFilters,
            page: state.pageIndex,
            pageSize: state.pageSize,
        },
        type: SET_TEAMS_PAGE_PAGINATION_PARAMS,
    });
};

export const CLEAR_TEAMS_PAGE_PAGINATION_PARAMS = 'CLEAR_TEAMS_PAGE_PAGINATION_PARAMS';
export const clearTeamsPagePaginationParams = () =>
    dispatchWrapper(CLEAR_TEAMS_PAGE_PAGINATION_PARAMS);
