import { Card, CardContent } from '@mui/material';
import { teamStateIds } from 'constants/teamStateIds';
import useTeamProps from 'hooks/useTeamProps';
import AlertInfoCard from 'pages/dashboard/AlertInfoCard';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import { archiveAlert } from 'pages/dashboard/DashboardPage';
import { completedTasksSelector } from 'pages/dashboard/dashboardSelectors';
import TeamAdminLaunchTaskList from 'pages/dashboard/taskLists/TeamAdminLaunchTaskList';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const RhLaunchAlertCard = () => (
    <AlertInfoCard
        cardBody="A Remodel Health team member will reach out to you to go over next steps."
        cardHeader="What's Next?"
    />
);

const TeamAdminContent = () => {
    const { isTeamArchived, teamStateId } = useTeamProps();
    const { completedTasks } = useSelector((state: AppStore) => ({
        completedTasks: completedTasksSelector(state),
    }));
    if (isTeamArchived) {
        return <AlertInfoCard alerts={[archiveAlert]} />;
    }
    switch (teamStateId) {
        case teamStateIds.Prospect:
            return (
                <Card>
                    <CardContent>
                        <DashboardCardHeader header="Welcome to Remodel Health!" />
                    </CardContent>
                </Card>
            );
        case teamStateIds.RhLaunch:
            return <RhLaunchAlertCard />;
        case teamStateIds.TeamAdminLaunch:
            return <TeamAdminLaunchTaskList completedTasks={completedTasks} />;
        case teamStateIds.ReadyForMemberLaunch:
            return (
                <AlertInfoCard
                    cardBody="A Remodel Health team member will be reviewing your team and sending invitations to all members to create their accounts."
                    cardHeader="Member Sign ups"
                />
            );
        default:
            return <React.Fragment />;
    }
};

export default hot(module)(TeamAdminContent);
